
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifyValidatable } from '@/application/types';
import { DialogWidth } from '@/helpers/data';
import { useFacilityReservationsManagementStore } from '../store';
import { UpdateNameOfFacilityReservationActivityAsManagerCommand, FacilityReservationActivity } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    name: FormField<string|null>;
  };
}

@Component
export default class UpdateNameOfFacilityReservationActivityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Prop({ type: Object, required: true })
  readonly facilityReservationActivity!: FacilityReservationActivity;

  @Ref()
  readonly formElement?: VuetifyValidatable;

  readonly dialogMaxWidth = DialogWidth.small;

  form: Form | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (this.formElement) {
        this.formElement.resetValidation();
      }
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        name: {
          value: this.facilityReservationActivity?.name || null,
          rules: [
            requiredRule(),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateNameOfFacilityReservationActivityAsManagerCommand = {
      facilityReservationActivityId: this.facilityReservationActivity.facilityReservationActivityId,
      name: this.form!.fields.name.value!,
    };
    this.store.updateNameOfFacilityReservationActivity(command)
      .then(() => showSuccessMessage('Der Name der Aktivität wurde angepasst'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
