
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { generateTimeRangeSelectItems } from '@/helpers/form-helpers';
import { requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifySelectItem, VuetifyValidatable } from '@/application/types';
import { DialogWidth } from '@/helpers/data';
import { useFacilityReservationsManagementStore } from '../store';
import { FacilityReservationActivity, UpdateTimeOptionIntervalRangeOfFacilityReservationActivityAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    minTimeOptionIntervals: FormField<number|null>;
    maxTimeOptionIntervals: FormField<number|null>;
  };
}

@Component
export default class UpdateTimeOptionIntervalRangeOfFacilityReservationActivityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Prop({ type: Object, required: true })
  readonly facilityReservationActivity!: FacilityReservationActivity;

  @Ref()
  readonly formElement?: VuetifyValidatable;

  @Ref()
  readonly minTimeOptionIntervalsSelect?: VuetifyValidatable;

  @Ref()
  readonly maxTimeOptionIntervalsSelect?: VuetifyValidatable;

  readonly dialogMaxWidth = DialogWidth.small;

  form: Form | null = null;
  isDialogVisible = false;

  get timeOptionIntervalsSelectItems(): VuetifySelectItem<number>[] {
    if (!this.store.currentFacility) {
      return [];
    }

    return generateTimeRangeSelectItems(
      this.store.currentFacility.openingHours.timeFrom,
      this.store.currentFacility.openingHours.timeTo,
      this.store.currentFacility.facilityReservationConfiguration.timeOptionInterval
    );
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (this.formElement) {
        this.formElement.resetValidation();
      }
    });
  }

  minSameOrBeforeMax(min: number | null) {
    if (min === null || this.form!.fields.maxTimeOptionIntervals.value === null) {
      return true;
    }

    return min <= this.form!.fields.maxTimeOptionIntervals.value || 'Minimale Buchungszeit darf nicht größer als die Maximale sein';
  }

  maxSameOrAfterMin(max: number | null) {
    if (max === null || this.form!.fields.minTimeOptionIntervals.value === null) {
      return true;
    }

    return max >= this.form!.fields.minTimeOptionIntervals.value || 'Maximale Buchungszeit darf nicht kleiner als die Minimale sein';
  }

  minValueChanged() {
    // Guarantee element since form obviously exists.
    this.maxTimeOptionIntervalsSelect!.validate();
  }

  maxValueChanged() {
    // Guarantee element since form obviously exists.
    this.minTimeOptionIntervalsSelect!.validate();
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        minTimeOptionIntervals: {
          value: this.facilityReservationActivity?.minTimeOptionIntervals || 0,
          rules: [
            requiredRule(),
            this.minSameOrBeforeMax,
          ],
        },
        maxTimeOptionIntervals: {
          value: this.facilityReservationActivity?.maxTimeOptionIntervals || 0,
          rules: [
            requiredRule(),
            this.maxSameOrAfterMin,
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateTimeOptionIntervalRangeOfFacilityReservationActivityAsManagerCommand = {
      facilityReservationActivityId: this.facilityReservationActivity.facilityReservationActivityId,
      minTimeOptionIntervals: this.form!.fields.minTimeOptionIntervals.value!,
      maxTimeOptionIntervals: this.form!.fields.maxTimeOptionIntervals.value!,
    };
    this.store.updateTimeOptionIntervalRangeOfFacilityReservationActivity(command)
      .then(() => showSuccessMessage('Die Buchungszeit wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
