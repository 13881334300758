
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { CalendarTimestamp } from 'vuetify';
import { showErrorMessage, showErrorResponse } from '@/application/snackbar/service';
import { Moment, Time } from '@/types';
import { moment } from '@/helpers';
import { MovementDirection, movementDirection, movementFromStartAndEnd, TouchMovement, TouchPoint, touchPointFromEvent } from '@/helpers/touch';
import { scrollIntoViewWithOffset, safeAreaInsetTop } from '@/helpers/scrolling';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { CalendarEvent, CalendarRangeType, CalendarType, DismissBookingNoticeCommand, Facility, FacilityReservationActivity } from '../types';
import { useBookingCalendarStore } from '../store';
import { calculateTimeThroughScrollPosition, calendarRangeTypeFromCalendarViewPreferenceAndResolution } from '../helper';
import ReserveFacilityDialog from './reserve-facility-dialog.vue';
import NoHorseDialog from './no-horse-dialog.vue';
import ReservationNotPossibleInPastDialog from './reservation-not-possible-in-past-dialog.vue';
import NoHorseActivitiesInFacilityDialog from './no-horse-activities-in-facility-dialog.vue';
import ReservationNotPossibleYetDialog from './reservation-not-possible-yet-dialog.vue';
import UpdateFacilityReservationDialog from './update-facility-reservation-dialog.vue';
import FacilityReservationEvent from './facility-reservation-event.vue';
import FacilityBlockerEvent from './facility-blocker-event.vue';
import RidingLessonEvent from './riding-lesson-event.vue';
import CalendarNavigation from './calendar-navigation.vue';

@Component({
  components: {
    UpdateFacilityReservationDialog,
    CalendarNavigation,
    ReserveFacilityDialog,
    NoHorseDialog,
    ReservationNotPossibleInPastDialog,
    ReservationNotPossibleYetDialog,
    FacilityReservationEvent,
    FacilityBlockerEvent,
    RidingLessonEvent,
    NoHorseActivitiesInFacilityDialog,
  },
})
export default class FacilityCalendar extends Vue {

  readonly store = useBookingCalendarStore();
  readonly myStableStore = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly currentFacility!: Facility;

  @Ref()
  readonly reserveFacilityDialog!: HTMLFormElement;

  @Ref()
  readonly noHorseDialog!: HTMLFormElement;

  @Ref()
  readonly reservationNotPossibleInPastDialog!: HTMLFormElement;

  @Ref()
  readonly reservationNotPossibleYetDialog!: HTMLFormElement;

  @Ref()
  readonly noHorseActivitiesInFacilityDialog!: HTMLFormElement;

  @Ref()
  readonly updateFacilityReservationDialog!: UpdateFacilityReservationDialog;

  readonly calendarType: CalendarType = CalendarType.FACILITY_CALENDAR;

  readonly menuBarSize = 60;
  readonly tableHeaderSize = 86;

  readonly weekdays = [1, 2, 3, 4, 5, 6, 0];

  facilityReservationTriggeredThroughNavigation: CalendarEvent | null = null;

  latestTouchStart: TouchPoint | null = null;

  scrollClass: string | null = null;

  get bookingNotice(): string | null {
    return this.currentFacility.facilityReservationConfiguration?.bookingNotice ?? null;
  }

  get isBookingNoticeShown(): boolean {
    return this.bookingNotice !== null
      && !this.currentFacility.facilityReservationConfiguration!.wasBookingNoticeDismissed;
  }

  get allowBookingFrom(): number {
    return this.currentFacility.openingHours.timeFrom.hour;
  }

  get amountIntervalsPerDay(): number {
    let lastHour = this.currentFacility.openingHours.timeTo.hour === 0
      ? 24
      : this.currentFacility.openingHours.timeTo.hour;

    if (this.currentFacility.openingHours.timeTo.minute > 0) {
      lastHour++;
    }

    return lastHour - this.allowBookingFrom;
  }

  get intervalHeight(): number {
    if (!this.currentFacility.facilityReservationConfiguration) {
      return 100;
    }

    /**
     * Intervals on the calendar always represents an hour. Because of that we have to calculate the height of the interval by the amount of
     * potential event bookings within this hour.
     * In general every event has two lines of information:
     *   1. Horse and horse activity
     *   2. Time range
     * If the time option interval of the facility is 15 minutes or less, it will calculate the minimum height of an event entry to a single
     * line layout and cut of the time range of the event. We do this because otherwise the height of the whole calendar will be super long.
     * If the time option interval of the facility is more than 15 minutes, it will calculate the minimum height of an event entry to a two
     * line layout.
     * As it's possible to switch the duration of an interval, we will check if there is any existing booking in the current time frame (day
     * or week) which is below the current interval. If that's the case, we use this as a basis for the calculation instead of the current
     * interval.
     * When we don't have any horse activities for bookings (and only one for riding lesson), we use a fixed value.
     */
    const heightForSingleLine = 26;
    const heightForTwoLines = 44;

    const minHorseActivitiesTimeOptionIntervals = this.currentFacility.facilityReservationActivities.length > 0
      ? this.currentFacility.facilityReservationActivities
        .reduce((result, horseActivity) => (
          result === -1 || result > horseActivity.minTimeOptionIntervals ? horseActivity.minTimeOptionIntervals : result
        ), -1)
      : 1;

    const minEventLength = this.store.calendarEventsByFacility!
      .filter((calendarEvent) => {
        // Return all calendar events for week, but only the relevant ones if set to day
        if (this.store.currentCalendarRangeType === CalendarRangeType.week) {
          return true;
        }

        return this.store.currentRangeFrom?.isSame(calendarEvent.from, 'day') ?? false;
      })
      .reduce((result, calendarEvent) => (
        result === -1 || result > calendarEvent.to.diff(calendarEvent.from, 'minutes')
          ? calendarEvent.to.diff(calendarEvent.from, 'minutes')
          : result
      ), -1);

    const currentTimeIntervalLength = minEventLength === -1
      || this.currentFacility.facilityReservationConfiguration!.timeOptionInterval <= minEventLength
      ? this.currentFacility.facilityReservationConfiguration!.timeOptionInterval
      : minEventLength;

    const heightBasis = currentTimeIntervalLength > 15 ? heightForTwoLines : heightForSingleLine;

    const calendarIntervalMinutes = 60;
    const intervalsPerCalendarInterval = calendarIntervalMinutes / currentTimeIntervalLength;
    const minNeededIntervalsForHorseActivity = intervalsPerCalendarInterval / minHorseActivitiesTimeOptionIntervals;

    return minNeededIntervalsForHorseActivity * heightBasis;
  }

  get lastPossibleBookingDate(): Moment {
    let lastPossibleDay = moment().add(this.currentFacility.facilityReservationConfiguration!.bookingInAdvance, 'days');
    const currentTime = Time.fromDate(moment());

    if (currentTime.isSameOrAfter(this.currentFacility.facilityReservationConfiguration!.timeToGrantAccessForNewDayInAdvance)) {
      lastPossibleDay = lastPossibleDay.add(1, 'day');
    }

    return lastPossibleDay;
  }

  get facilityReservationActivitiesForCurrentFacility(): FacilityReservationActivity[] {
    return this.currentFacility.facilityReservationActivities || [];
  }

  get calendarStart(): string {
    return this.store.currentRangeFrom?.format('YYYY-MM-DD') ?? '';
  }

  get calendarEnd(): string {
    return this.store.currentRangeTo?.format('YYYY-MM-DD') ?? '';
  }

  get calendarClasses(): Record<string, true> {
    const classes: Record<string, true> = {};

    if (this.scrollClass) {
      classes[this.scrollClass] = true;
    }
    if (this.store.currentCalendarRangeType) {
      classes[`range-${this.store.currentCalendarRangeType.toLowerCase()}`] = true;
    }

    return classes;
  }

  mounted(): void {
    const calendarRangeType = calendarRangeTypeFromCalendarViewPreferenceAndResolution();
    if (this.$route.params.date) {
      const date = moment(this.$route.params.date);
      const rangeFrom = calendarRangeType === CalendarRangeType.day
        ? date.startOf('day')
        : date.isoWeekday(1).startOf('day');
      const rangeTo = calendarRangeType === CalendarRangeType.day
        ? rangeFrom.add(1, 'day')
        : rangeFrom.add(7, 'days');

      this.store.initializeTimeRangeAndCalendarTypeForFacility(rangeFrom, rangeTo, calendarRangeType)
        .then(() => {
          const facilityReservationId = this.$route.params.facilityReservationId;
          if (facilityReservationId) {
            const calendarEvent = this.store.calendarEventsByFacility
              .find((calendarEvent) => calendarEvent.id === facilityReservationId);

            if (!calendarEvent) {
              showErrorMessage('Die Reservierung konnte nicht gefunden werden. Ggf. wurde sie storniert.');
              return;
            }

            this.facilityReservationTriggeredThroughNavigation = calendarEvent;
            this.$nextTick(() => {
              // Result is something like "54px"
              const safeAreaInset = safeAreaInsetTop();
              const marginTop = 16;
              const offset = this.menuBarSize + this.tableHeaderSize + marginTop + safeAreaInset;

              scrollIntoViewWithOffset(`#calendar-event\\:${calendarEvent.id}`, offset);

              if (this.$route.params.action === 'update') {
                this.updateFacilityReservationDialog.show();
              }
            });
          }

          const ridingLessonId = this.$route.params.ridingLessonId;
          if (ridingLessonId) {
            const calendarEvent = this.store.calendarEventsByFacility
              .find((calendarEvent) => calendarEvent.id === ridingLessonId);

            if (!calendarEvent) {
              showErrorMessage('Die Unterrichtsstunde konnte nicht gefunden werden. Ggf. wurde sie abgesagt.');
              return;
            }

            this.$nextTick(() => {
              // Result is something like "54px"
              const safeAreaInset = safeAreaInsetTop();
              const marginTop = 16;
              const offset = this.menuBarSize + this.tableHeaderSize + marginTop + safeAreaInset;

              scrollIntoViewWithOffset(`#calendar-event\\:${calendarEvent.id}`, offset);
            });
          }

        })
        .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
    } else if (
      !this.store.currentRangeFrom
      || !this.store.currentRangeTo
      || !this.store.currentCalendarRangeType
    ) {
      const rangeFrom = calendarRangeType === CalendarRangeType.day
        ? moment().startOf('day')
        : moment().isoWeekday(1).startOf('day');
      const rangeTo = calendarRangeType === CalendarRangeType.day
        ? rangeFrom.add(1, 'day')
        : rangeFrom.add(7, 'days');

      this.store.initializeTimeRangeAndCalendarTypeForFacility(rangeFrom, rangeTo, calendarRangeType)
        .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
    } else {
      this.store.getCalendarEventsForFacility()
        .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
    }

    this.store.getPreferredIntervals()
      .catch((error) => showErrorMessage(error));

    window.addEventListener('resize', this.updateCalendarRangeTypeOnResize);
    window.addEventListener('visibilitychange', this.visibilityChanged);
    window.addEventListener('scroll', this.toggleStickyHeader);
  }

  destroyed(): void {
    window.removeEventListener('resize', this.updateCalendarRangeTypeOnResize);
    window.removeEventListener('visibilitychange', this.visibilityChanged);
    window.removeEventListener('scroll', this.toggleStickyHeader);
  }

  toggleStickyHeader(): void {
    const calendar = document.getElementsByClassName('v-calendar-daily')[0];
    if (!calendar) {
      return;
    }

    const distanceToTopOfHeader = calendar.getBoundingClientRect().top;

    this.scrollClass = distanceToTopOfHeader <= this.menuBarSize + safeAreaInsetTop()
      ? 'sticky-header'
      : null;
  }

  navigateToTodayInCalendar(): void {
    const rangeFrom = this.store.currentCalendarRangeType === CalendarRangeType.day
      ? moment().startOf('day')
      : moment().isoWeekday(1).startOf('day');
    const rangeTo = this.store.currentCalendarRangeType === CalendarRangeType.day
      ? rangeFrom.add(1, 'day')
      : rangeFrom.add(7, 'days');

    this.store.updateCurrentRangeForFacility(rangeFrom, rangeTo)
      .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
  }

  navigateToPreviousCalendarTimeFrame(): void {
    this.store.moveCalendarRangeBackwardsForFacility()
      .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
  }

  navigateToNextCalendarTimeFrame(): void {
    this.store.moveCalendarRangeForwardForFacility()
      .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
  }

  onDismissBookingNoticeClicked(): void {
    const command: DismissBookingNoticeCommand = {
      facilityId: this.$route.params.id,
    };
    this.store.dismissBookingNotice(command)
      .catch((error) => showErrorResponse(error));
  }

  visibilityChanged(): void {
    // Update calendar events when reopening the app
    if (document.visibilityState !== 'hidden') {
      this.store.getCalendarEventsForFacility()
        .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
    }
  }

  calendarTimeSlotClicked(date: CalendarTimestamp): void {
    if (this.facilityReservationActivitiesForCurrentFacility.length === 0) {
      this.noHorseActivitiesInFacilityDialog.showDialog();
      return;
    }

    if (this.myStableStore.ownAndSharedHorses.length === 0) {
      this.noHorseDialog.showDialog();
      return;
    }

    if (moment(`${date.date} ${date.time}`, 'YYYY-MM-DD HH:mm', 'Europe/Berlin').isBefore(moment())) {
      this.reservationNotPossibleInPastDialog.showDialog();
      return;
    }

    if (moment(date.date).isAfter(this.lastPossibleBookingDate)) {
      this.reservationNotPossibleYetDialog.showDialog();
      return;
    }

    this.reserveFacilityDialog!.showDialogWith(moment(`${date.date} ${date.time}`));
  }

  updateCalendarRangeTypeOnResize(): void {
    const calendarRangeType = calendarRangeTypeFromCalendarViewPreferenceAndResolution();

    if (calendarRangeType !== this.store.currentCalendarRangeType) {
      this.store.updateCalendarTypeForFacility(calendarRangeType)
        .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
    }
  }

  isReserveFacilityDisabled(date: string): boolean {
    if (!this.currentFacility.facilityReservationConfiguration
      || this.facilityReservationActivitiesForCurrentFacility.length === 0
      || this.myStableStore.ownAndSharedHorses.length === 0
    ) {
      return true;
    }

    const now = moment();
    const today = now.startOf('day');
    const dateMoment = moment(date);

    const currentTime = Time.fromDate(now);
    if (dateMoment.isSame(now, 'date')
      && currentTime.isAfter(this.currentFacility.openingHours.timeTo)
    ) {
      return true;
    }

    return dateMoment.isBefore(today)
      || dateMoment.isAfter(this.lastPossibleBookingDate);
  }

  isFacilityReservationEvent(event: CalendarEvent): boolean {
    return event.type === 'FACILITY_RESERVATION';
  }

  isFacilityBlockerEvent(event: CalendarEvent): boolean {
    return event.type === 'FACILITY_BLOCKER';
  }

  isRidingLessonEvent(event: CalendarEvent): boolean {
    return event.type === 'RIDING_LESSON';
  }

  /**
   * The reserve dialog is opened with the start time. This start time is calculated depending on the scroll position within the calendar.
   * When the reservation is on the current date and the time calculated through the scroll position is already passed, the start time is
   * taken from the closest current time.
   */
  reserveClicked(date: string): void {
    const now = moment();
    const dateMoment = moment(date);

    const isDateToday = dateMoment.isSame(now, 'date');
    const currentTime = Time.fromDate(now);
    if (isDateToday
        && currentTime.isAfter(this.currentFacility.openingHours.timeTo)
    ) {
      this.reservationNotPossibleInPastDialog.showDialog();
      return;
    }

    if (dateMoment.isAfter(this.lastPossibleBookingDate)) {
      this.reservationNotPossibleYetDialog.showDialog();
      return;
    }

    const calendar = document.getElementsByClassName('v-calendar-daily')[0]!;
    const closestToScrollPosition = calculateTimeThroughScrollPosition(
      calendar,
      this.menuBarSize,
      this.tableHeaderSize,
      this.store.currentFacility!.facilityReservationConfiguration!.timeOptionInterval,
      this.store.currentFacility!.openingHours,
    );

    const closestToCurrentTime = currentTime.closestPointInTimeFrameAndInterval(
      this.currentFacility!.openingHours,
      this.currentFacility.facilityReservationConfiguration!.timeOptionInterval,
    );

    const timeToStartBookingAt = isDateToday && closestToScrollPosition.isBefore(currentTime)
      ? closestToCurrentTime
      : closestToScrollPosition;

    const momentToStartReservationAt = dateMoment
      .hour(timeToStartBookingAt.hour)
      .minute(timeToStartBookingAt.minute)
      .second(0);

    this.reserveFacilityDialog!.showDialogWith(momentToStartReservationAt);
  }

  touchStarted(event: any, original: TouchEvent): void {
    this.latestTouchStart = touchPointFromEvent(original);
  }

  touchEnded(event: any, original: TouchEvent): void {
    if (this.latestTouchStart === null) {
      // It's possible that the touch started outside the calendar and ended inside of it.
      return;
    }

    const end: TouchPoint = touchPointFromEvent(original);

    const movement = movementFromStartAndEnd(this.latestTouchStart, end);

    this.handleTouchMovement(movement);
  }

  handleTouchMovement(movement: TouchMovement): void {
    const direction = movementDirection(movement);

    switch (direction) {
      case MovementDirection.LEFT:
        this.onTouchLeft();
        break;
      case MovementDirection.RIGHT:
        this.onTouchRight();
        break;
      default: break;
    }
  }

  onPreviousButtonClicked(): void {
    this.navigateToPreviousCalendarTimeFrame();
  }

  onTodayButtonClicked(): void {
    this.navigateToTodayInCalendar();
  }

  onNextButtonClicked(): void {
    this.navigateToNextCalendarTimeFrame();
  }

  onTouchLeft(): void {
    this.navigateToNextCalendarTimeFrame();
  }

  onTouchRight(): void {
    this.navigateToPreviousCalendarTimeFrame();
  }

  onCalendarRangeTypeToggleClicked(): void {
    const newCalendarRangeType = this.store.currentCalendarRangeType === CalendarRangeType.day
      ? CalendarRangeType.week
      : CalendarRangeType.day;
    this.store.updateCalendarTypeForFacility(newCalendarRangeType)
      .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
  }

  onPreferredCalendarViewWasUpdated(): void {
    const calendarRangeType = calendarRangeTypeFromCalendarViewPreferenceAndResolution();

    if (calendarRangeType !== this.store.currentCalendarRangeType) {
      this.store.updateCalendarTypeForFacility(calendarRangeType)
        .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
    }
  }

  registeredForRidingLesson(): void {
    this.store.getCalendarEventsForFacility()
      .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
  }

  ridingLessonRegistrationCanceled(): void {
    this.store.getCalendarEventsForFacility()
      .catch((error) => this.showErrorResponseOrRedirectToRoot(error));
  }

  showErrorResponseOrRedirectToRoot(error: any): void {
    if (error?.response?.status === 404) {
      this.$router.push({ name: 'booking-calendars/root' });
    } else {
      showErrorResponse(error);
    }
  }

}
