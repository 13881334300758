
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { ActionStatus } from '@/application/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { useFacilityReservationsManagementStore } from '../store';

@Component
export default class FacilityList extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name' },
  ];

  get isLinkInfoVisible(): boolean {
    return this.store.facilities.length > 0;
  }

  get noDataText(): string {
    return this.store.loadFacilitiesStatus === ActionStatus.Failed
      ? 'Die Anlagen konnten nicht geladen werden.'
      : 'Du hast noch keine Anlagen erstellt.';
  }

  mounted(): void {
    this.store.getFacilitiesOverview()
      .catch((error) => showErrorResponse(error));
  }

}
