
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { CustomFieldValues, Language, Link, OrganizationId, PhoneNumber } from '@/types';
import { UpdateAdditionalMasterDataOfOrganizationAsManagerCommand } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  landlinePhoneNumber: FormControl<PhoneNumber>;
  mobilePhoneNumber: FormControl<PhoneNumber>;
  language: FormControl<Language>;
  website: FormControl<Link>;
  customFields: FormControl<CustomFieldValues>;
  comment: FormControl<string>;
}

@Component
export default class UpdateAdditionalMasterDataOfOrganizationDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: String, required: true })
  readonly organizationId!: OrganizationId;

  @Prop({ type: String, default: null })
  readonly landlinePhoneNumber!: PhoneNumber | null;

  @Prop({ type: String, default: null })
  readonly mobilePhoneNumber!: PhoneNumber | null;

  @Prop({ type: String, default: null })
  readonly language!: Language | null;

  @Prop({ type: String, default: null })
  readonly website!: string | null;

  @Prop({ type: Array, default: null })
  readonly customFields!: CustomFieldValues | null;

  @Prop({ type: String, default: null })
  readonly comment!: string | null;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.store.getCustomFields()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => {
            showErrorResponse(error);
            this.closeDialog();
          });
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        landlinePhoneNumber: {
          label: 'Festnetz-Telefonnummer',
          value: this.landlinePhoneNumber,
        },
        mobilePhoneNumber: {
          label: 'Mobil-Telefonnummer',
          value: this.mobilePhoneNumber,
        },
        language: {
          label: 'Sprache',
          value: this.language,
          isRequired: true,
        },
        website: {
          label: 'Website',
          value: this.website,
        },
        customFields: {
          label: 'Spezifische Daten',
          value: this.customFields,
        },
        comment: {
          label: 'Kommentar',
          value: this.comment,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateAdditionalMasterDataOfOrganizationAsManagerCommand = {
      organizationId: this.organizationId,
      landlinePhoneNumber: formValues.landlinePhoneNumber,
      mobilePhoneNumber: formValues.mobilePhoneNumber,
      language: formValues.language!,
      website: formValues.website,
      customFields: formValues.customFields,
      comment: formValues.comment,
    };

    this.store.updateAdditionalMasterDataOfOrganization(command)
      .then(() => showSuccessMessage('Die weiteren Stammdaten wurden angepasst.'))
      .then(() => this.$emit('organization-updated'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
