
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, FormControlValue } from '@/components/form';
import { UserId, FarmManagerPermission } from '@/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { useRiderTaskManagementStore } from '../store';
import { AssignedTask } from '../types';
import CompleteTaskDialog from './complete-task-dialog.vue';
import AssignTaskDialog from './assign-task-dialog.vue';
import CreateAssignedTaskDialog from './create-assigned-task-dialog.vue';
import UpdateExecutionDateOfTaskDialog from './update-execution-date-of-task-dialog.vue';

interface Controls extends FormControls {
  user: FormControl<UserId>;
}

@Component({
  components: {
    CreateAssignedTaskDialog,
    AssignTaskDialog,
    CompleteTaskDialog,
    UpdateExecutionDateOfTaskDialog,
  },
})
export default class AssignedTasks extends Vue {

  readonly store = useRiderTaskManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Bearbeitungsdatum', value: 'executionDate' },
    { text: 'Auftraggeber', value: 'user' },
    { text: 'Beschreibung', value: 'description' },
    { text: 'Zugewiesen an', value: 'assignedTo' },
    { text: 'Zugewiesen am', value: 'assignedAt' },
    { text: 'Kommentar an Reiter', value: 'commentToRiderOnAssignment' },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 132 },
  ];

  form: Form<Controls> | null = null;

  get isUpdateExecutionDateVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE);
  }

  get isAssignTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE);
  }

  get isCreateAssignedTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE);
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.store.assignedTasksTotalCount === null
      || this.store.assignedTasks.length >= this.store.assignedTasksTotalCount;
  }

  get noDataText(): string {
    return this.store.assignedTasksFilteredByUser
      ? 'Von diesen Benutzer wurden noch keine Aufgaben zugewiesen.'
      : 'Es gibt keine zugewiesene Aufgaben.';
  }

  mounted(): void {
    this.store.getAssignedTasks()
      .catch((error) => showErrorResponse(error));

    this.store.getUsersWithTasks()
      .catch((error) => showErrorResponse(error));

    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        user: {
          label: 'Benutzer',
          value: null,
          afterTransformationAndValidation: (value: FormControlValue<UserId>) => {
            this.store.filterAssignedTasksByUser(value)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  isCompleteTaskVisible(task: AssignedTask): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE)
      || task.assignedTo?.userId === this.authenticationStore.user!.id;
  }

  onLoadMoreClicked(): void {
    this.store.increaseAssignedTasksLimit()
      .catch((error) => showErrorResponse(error));
  }

  taskAssigned(): void {
    this.store.getAssignedTasks()
      .catch((error) => showErrorResponse(error));
  }

  taskCompleted(): void {
    this.store.getAssignedTasks()
      .catch((error) => showErrorResponse(error));
  }

}
