
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { formatDate, formatTime } from '@/helpers';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { Month, FarmManagerPermission, PersonId, NameOrderType } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { moment } from '@/helpers';
import { useLedgerStore } from '@/private/management/ledger/ledger-configuration/store';
import { usePaymentReceiptStore } from '../store';
import { MarkBankTransferForMonthAsNotPayedAsManagerCommand, MarkBankTransferForMonthAsPayedAsManagerCommand, MarkDirectDebitForMonthAsNotPayedAsManagerCommand, MarkDirectDebitForMonthAsPayedAsManagerCommand, PersonWithPaymentReceipts } from '../types';
import UpdatePaymentReceiptNoteDialog from './update-payment-receipt-note-dialog.vue';

interface Controls extends FormControls {
  selectedMonth: FormControl<Month>;
  selectedPerson: FormControl<PersonId>;
  nameOrderType: FormControl<NameOrderType>;
}

@Component({
  components: {
    UpdatePaymentReceiptNoteDialog,
  },
})
export default class PaymentReceipts extends Vue {

  readonly store = usePaymentReceiptStore();
  readonly ledgerStore = useLedgerStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Person', value: 'person' },
    { text: 'Per Überweisung', value: 'bankTransferAmount', align: 'end' },
    { text: 'Per Lastschrift', value: 'directDebitAmount', align: 'end' },
    { text: 'Notiz', value: 'note' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  form: Form<Controls> | null = null;

  get isProcessing(): boolean {
    return this.store.isMarkBankTransferForMonthAsPayedProcessing
      || this.store.isMarkDirectDebitForMonthAsPayedProcessing
      || this.store.isMarkBankTransferForMonthAsNotPayedProcessing
      || this.store.isMarkDirectDebitForMonthAsNotPayedProcessing
      || this.store.isUpdatePaymentReceiptNoteForMonthProcessing;
  }

  get isActionDisabled(): boolean {
    return this.store.isGetUsersWithPaymentReceiptsProcessing
      || this.isProcessing;
  }

  get disabledTooltipForMarkBankTransferAsPayed(): string {
    return this.store.isGetUsersWithPaymentReceiptsProcessing
    || this.isProcessing
      ? 'Prozess wird gerade durchgeführt'
      : 'Keine Kosten per Überweisung';
  }

  get disabledTooltipForMarkDirectDebitAsPayed(): string {
    return this.store.isGetUsersWithPaymentReceiptsProcessing
    || this.isProcessing
      ? 'Prozess wird gerade durchgeführt'
      : 'Keine Kosten per Lastschrift';
  }

  get users(): PersonWithPaymentReceipts[] {
    if (this.store.personsWithPaymentReceipts === null) {
      return [];
    }

    return this.store.personsWithPaymentReceipts.persons;
  }

  get isUpdatedAtVisible(): boolean {
    return this.store.personsWithPaymentReceipts !== null && this.form !== null;
  }

  get translatedSelectedMonth(): string {
    if (this.form === null) {
      return '';
    }

    const formValues = getFormValues(this.form);
    return moment(formValues.selectedMonth).format('MMMM');
  }

  get updatedAtHTML(): string {
    if (this.store.personsWithPaymentReceipts === null
      || this.form === null
    ) {
      return '';
    }

    // eslint-disable-next-line max-len
    const updatedAtHTML = `Die Abrechnung für ${this.translatedSelectedMonth} wurde zuletzt <strong>am ${formatDate(this.store.personsWithPaymentReceipts!.lastUpdatedAt)} um ${formatTime(this.store.personsWithPaymentReceipts!.lastUpdatedAt)} Uhr</strong> aktualisiert.`;

    const formValues = getFormValues(this.form);
    return moment().isSame(formValues.selectedMonth!, 'month')
      ? `${updatedAtHTML} Die Daten werden ungefähr alle 15 Minuten aktualisiert.`
      : updatedAtHTML;
  }

  get isUpdatePaymentReceiptNoteVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  mounted(): void {
    const initialMonth = moment().subtract(1, 'month').format('YYYY-MM') as Month;

    this.form = this.buildForm(initialMonth);

    this.store.updateSelectedMonth(initialMonth)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(initialMonth: Month): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        selectedMonth: {
          label: 'Monat',
          value: initialMonth,
          afterTransformationAndValidation: (month) => this.store.updateSelectedMonth(month!)
            .catch((error) => showErrorResponse(error)),
        },
        selectedPerson: {
          label: 'Person',
          value: null,
          afterTransformationAndValidation: (person) => this.store.updateSelectedPerson(person)
            .catch((error) => showErrorResponse(error)),
        },
        nameOrderType: {
          label: 'Namensreihenfolge und -darstellung',
          value: this.store.selectedNameOrderType,
          afterTransformationAndValidation: (nameOrderType) => this.store.updateNameOrderType(nameOrderType!)
            .catch((error) => showErrorResponse(error)),
        },
      },
    });
  }

  isMarkBankTransferAsPayedVisible(userWithPaymentReceipts: PersonWithPaymentReceipts): boolean {
    return !userWithPaymentReceipts.wasPaymentReceivedForBankTransfer
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  isMarkBankTransferAsNotPayedVisible(userWithPaymentReceipts: PersonWithPaymentReceipts): boolean {
    return userWithPaymentReceipts.wasPaymentReceivedForBankTransfer
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  isMarkDirectDebitAsPayedVisible(userWithPaymentReceipts: PersonWithPaymentReceipts): boolean {
    return !userWithPaymentReceipts.wasPaymentReceivedForDirectDebit
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  isMarkDirectDebitAsNotPayedVisible(userWithPaymentReceipts: PersonWithPaymentReceipts): boolean {
    return userWithPaymentReceipts.wasPaymentReceivedForDirectDebit
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  isDateAllowed(month: Month): boolean {
    return moment(month).isSameOrBefore(moment(), 'month');
  }

  isMarkBankTransferAsPayedDisabled(userWithPaymentReceipts: PersonWithPaymentReceipts): boolean {
    return this.store.isGetUsersWithPaymentReceiptsProcessing
      || this.isProcessing
      || userWithPaymentReceipts.bankTransferAmount === 0;
  }

  isMarkDirectDebitAsPayedDisabled(userWithPaymentReceipts: PersonWithPaymentReceipts): boolean {
    return this.store.isGetUsersWithPaymentReceiptsProcessing
      || this.isProcessing
      || userWithPaymentReceipts.directDebitAmount === 0;
  }

  markBankTransferAsPayedClicked(userWithPaymentReceipts: PersonWithPaymentReceipts): void {
    const command: MarkBankTransferForMonthAsPayedAsManagerCommand = {
      personId: userWithPaymentReceipts.person.personId,
      month: this.store.selectedMonth!,
    };
    this.store.markBankTransferForMonthAsPayed(command)
      .catch((error) => showErrorResponse(error));
  }

  markBankTransferAsNotPayedClicked(userWithPaymentReceipts: PersonWithPaymentReceipts): void {
    const command: MarkBankTransferForMonthAsNotPayedAsManagerCommand = {
      personId: userWithPaymentReceipts.person.personId,
      month: this.store.selectedMonth!,
    };
    this.store.markBankTransferForMonthAsNotPayed(command)
      .catch((error) => showErrorResponse(error));
  }

  markDirectDebitAsPayedClicked(userWithPaymentReceipts: PersonWithPaymentReceipts): void {
    const command: MarkDirectDebitForMonthAsPayedAsManagerCommand = {
      personId: userWithPaymentReceipts.person.personId,
      month: this.store.selectedMonth!,
    };
    this.store.markDirectDebitForMonthAsPayed(command)
      .catch((error) => showErrorResponse(error));
  }

  markDirectDebitAsNotPayedClicked(userWithPaymentReceipts: PersonWithPaymentReceipts): void {
    const command: MarkDirectDebitForMonthAsNotPayedAsManagerCommand = {
      personId: userWithPaymentReceipts.person.personId,
      month: this.store.selectedMonth!,
    };
    this.store.markDirectDebitForMonthAsNotPayed(command)
      .catch((error) => showErrorResponse(error));
  }

}
