
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DataTableHeader } from 'vuetify';
import { SortableEvent } from 'sortablejs';
import { ActionStatus } from '@/application/types';
import { sortArray } from '@/directives/vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FacilityReservationActivityId, FarmManagerPermission } from '@/types';
import { formatTime } from '@/helpers';
import CreateFacilityReservationActivityDialog from './create-facility-reservation-activity-dialog.vue';
import UpdateNameOfFacilityReservationActivityDialog from './update-name-of-facility-reservation-activity-dialog.vue';
import UpdateTimeOptionIntervalRangeOfFacilityReservationActivityDialog from './update-time-option-interval-range-of-facility-reservation-activity-dialog.vue';
import UpdateColorOfFacilityReservationActivityDialog from './update-color-of-facility-reservation-activity-dialog.vue';
import UpdateRequiredSpacesOfFacilityReservationActivityDialog from './update-required-spaces-of-facility-reservation-activity-dialog.vue';
import UpdateTimeBasedRestrictionsOfFacilityReservationActivityDialog from './update-time-based-restrictions-of-facility-reservation-activity-dialog.vue';
import EnableFacilityReservationActivityDialog from './enable-facility-reservation-activity-dialog.vue';
import DisableFacilityReservationActivityDialog from './disable-facility-reservation-activity-dialog.vue';
import UpdateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityDialog from './update-is-rider-able-to-reserve-multiple-parallel-activities-of-facility-reservation-activity-dialog.vue';
import { useFacilityReservationsManagementStore } from '../store';
import { FacilityReservationActivity, UpdateOrderOfFacilityReservationActivitiesOfFacilityAsManagerCommand } from '../types';

@Component({
  methods: { formatTime },
  components: {
    CreateFacilityReservationActivityDialog,
    UpdateNameOfFacilityReservationActivityDialog,
    UpdateTimeOptionIntervalRangeOfFacilityReservationActivityDialog,
    UpdateColorOfFacilityReservationActivityDialog,
    UpdateRequiredSpacesOfFacilityReservationActivityDialog,
    UpdateTimeBasedRestrictionsOfFacilityReservationActivityDialog,
    EnableFacilityReservationActivityDialog,
    DisableFacilityReservationActivityDialog,
    UpdateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityDialog,
  },
})
export default class FacilityReservationActivities extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  readonly tableHeadersForBooking: DataTableHeader[] = [
    { text: 'Name', value: 'name' },
    { text: 'Reservierungszeit', value: 'timeOptionIntervalRange', sortable: false },
    { text: 'Farbe im Kalender', value: 'color', sortable: false },
    { text: 'Platzbedarf', value: 'requiredSpaces', sortable: false },
    { text: 'Einschränkungen', value: 'restrictions', sortable: false },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 130, sortable: false },
  ];

  readonly colors = {
    'green-5': 'Normales Grün',
    'yellow-5': 'Normales Gelb',
    'red-5': 'Normales Rot',
    'green-8': 'Helles Grün',
    'yellow-8': 'Helles Gelb',
    'red-8': 'Helles Rot',
    'green-3': 'Dunkles Grün',
    'yellow-3': 'Dunkles Gelb',
    'red-3': 'Dunkles Rot',
  };

  readonly facilityReservationActivityMenuList: Record<FacilityReservationActivityId, boolean> = {};

  facilityReservationActivities: FacilityReservationActivity[] = [];

  get noDataTextForBooking(): string {
    return this.store.loadFacilitiesStatus === ActionStatus.Failed
      ? 'Die Aktivitäten konnten nicht geladen werden.'
      : 'Du hast noch keine Aktivitäten angelegt.';
  }

  get isCreateFacilityReservationActivityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get isUpdateHorseActivityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get isMoveHorseActivityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  mounted(): void {
    watch(() => this.store.currentFacility, (facility) => {
      if (facility === null) {
        return;
      }

      this.facilityReservationActivities = facility.facilityReservationActivities;
    }, { deep: true, immediate: true })
  }

  isEnableHorseActivityVisible(horseActivity: FacilityReservationActivity): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE)
      && !horseActivity.isEnabled;
  }

  isDisableHorseActivityVisible(horseActivity: FacilityReservationActivity): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE)
      && horseActivity.isEnabled;
  }

  getFormattedTimeOptionIntervalRange(horseActivity: FacilityReservationActivity): string {
    if (horseActivity.minTimeOptionIntervals === horseActivity.maxTimeOptionIntervals) {
      // eslint-disable-next-line max-len
      return `${this.store.currentFacility!.facilityReservationConfiguration.timeOptionInterval * horseActivity.maxTimeOptionIntervals} Minuten`;
    }

    return `${this.store.currentFacility!.facilityReservationConfiguration.timeOptionInterval * horseActivity.minTimeOptionIntervals} -
      ${this.store.currentFacility!.facilityReservationConfiguration.timeOptionInterval * horseActivity.maxTimeOptionIntervals} Minuten`;
  }

  getItemClass(item: FacilityReservationActivity): string {
    if (!item.isEnabled) {
      return 'disabled';
    }

    return '';
  }

  sortedForBooking(event: SortableEvent): void {
    sortArray(this.facilityReservationActivities, event);

    const sortedIdsOfFacilityReservationActivities = this.facilityReservationActivities
      .map((horseActivity) => horseActivity.facilityReservationActivityId);
    const command: UpdateOrderOfFacilityReservationActivitiesOfFacilityAsManagerCommand = {
      facilityId: this.store.currentFacilityId!,
      sortedIdsOfFacilityReservationActivities,
    };
    this.store.updateOrderOfFacilityReservationActivitiesOfFacility(command)
      .then(() => showSuccessMessage('Die Reihenfolge wurde aktualisiert.'))
      .catch((error) => showErrorResponse(error));
  }

  hideMenu(facilityReservationActivity: FacilityReservationActivity): void {
    this.facilityReservationActivityMenuList[facilityReservationActivity.facilityReservationActivityId] = false;
  }

}
