
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { MONTH_FORMAT } from '@/helpers/data';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, mountFormControl, wasValidationSuccessful, isFieldShownAsContainingAnError, labelWithRequiredIndicator } from '@/components/form';
import { Month } from '@/types';
import { moment } from '@/helpers';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class MonthFormControl extends Vue implements FormControlComponent<Month> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Month>;

  @Prop({ type: Function, default: null })
  readonly isMonthAllowed!: ((date: Month) => boolean) | null;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  isMenuVisible = false;

  internalValue: string | null = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  get formattedTextFieldValue(): string {
    if (this.internalValue !== null
      && this.internalValue.length > 0
    ) {
      return moment(this.internalValue).format(MONTH_FORMAT);
    }

    return '';
  }

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  dateChanged(): void {
    if (this.internalValue === null) {
      this.internalValue = '';
    }

    internalValuesChanged(this);

    this.isMenuVisible = false;
  }

  textFieldListeners(on: any): any {
    return {
      ...on,
      'click:clear': this.clearButtonClicked,
    };
  }

  allowedDates(date: string): boolean {
    return this.isMonthAllowed !== null
      ? this.isMonthAllowed(moment(date).format('YYYY-MM') as Month)
      : true;
  }

  clearButtonClicked(): void {
    this.internalValue = '';

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<Month> {
    return this.internalValue !== null
      && this.internalValue.length > 0
      ? this.internalValue as Month
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
