
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { Month, FarmManagerPermission } from '@/types';
import { moment } from '@/helpers';
import { useLedgerStore } from '../store';
import { CreditNote, RevokeCreditNoteAsManagerCommand } from '../types';
import AddCreditNoteDialog from './add-credit-note-dialog.vue';
import AddCreditNotesDialog from './add-credit-notes-dialog.vue';

interface Controls extends FormControls {
  selectedMonth: FormControl<Month>;
}

@Component({
  components: {
    AddCreditNoteDialog,
    AddCreditNotesDialog,
  },
})
export default class CreditNotesAdminComponent extends Vue {

  readonly store = useLedgerStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Person', value: 'person' },
    { text: 'Pferd', value: 'horse' },
    { text: 'Beschreibung', value: 'description' },
    { text: 'Kosten', value: 'costs' },
    { text: 'Rückgebucht am', value: 'canceledAt' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  form: Form<Controls> | null = null;

  isAddMenuVisible = false;

  get isAddCreditNoteVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  mounted(): void {
    this.form = this.buildForm();

    const formValues = getFormValues(this.form);

    this.store.updateSelectedMonthForCreditNotes(formValues.selectedMonth!)
      .catch((error) => showErrorResponse(error));
  }

  isRevokeCreditNoteVisible(creditNote: CreditNote): boolean {
    return !creditNote.canceledAt
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        selectedMonth: {
          label: 'Monat',
          value: moment().startOf('month').format('YYYY-MM') as Month,
          isRequired: true,
          afterTransformationAndValidation: (value) => {
            if (value) {
              this.store.updateSelectedMonthForCreditNotes(value)
                .catch((error) => showErrorResponse(error));
            }
          },
        },
      },
    });
  }

  async revokeCreditNoteAction(creditNote: CreditNote): Promise<void> {
    const command: RevokeCreditNoteAsManagerCommand = {
      creditNoteId: creditNote.creditNoteId,
      settlingOnPersonId: creditNote.person.personId,
      revokedAt: moment(),
    };

    return this.store.revokeCreditNote(command)
      .then(() => showSuccessMessage('Die Gutschrift wurden zurückgebucht.'))
      .catch((error) => showErrorResponse(error));
  }

  hideAddMenu(): void {
    this.isAddMenuVisible = false;
  }

}
