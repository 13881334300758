
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FarmTaskId } from '@/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { useFarmTaskManagementStore } from '../store';
import { OutdatedTasks } from '../types';
import { taskNoteForRecentOrOutDatedTask } from '../helper';
import DuplicateTaskDialog from './duplicate-task-dialog.vue';
import DeleteTaskDialog from './delete-task-dialog.vue';

@Component({
  components: {
    DeleteTaskDialog,
    DuplicateTaskDialog,
  },
  methods: {
    taskNoteForRecentOrOutDatedTask,
  },
})
export default class OutdatedTasksList extends Vue {

  readonly store = useFarmTaskManagementStore();

  @Prop({ type: Object, required: true })
  readonly outdatedTasks!: OutdatedTasks;

  readonly taskMenuList: Record<FarmTaskId, boolean> = {};

  get isUpdateTaskVisible(): boolean {
    return this.isDuplicateTaskVisible
      || this.isDeleteTaskVisible;
  }

  get isDuplicateTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isDeleteTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.outdatedTasks.tasks.length >= this.outdatedTasks.totalCount;
  }

  getTasksAfterDuplicate(): void {
    this.store.getRecentTasks()
      .catch((error) => showErrorResponse(error));
  }

  getTasksAfterDelete(): void {
    this.store.getOutdatedTasks()
      .catch((error) => showErrorResponse(error));
  }

  onLoadMoreClicked(): void {
    this.store.increaseLimitForOutdatedTasks()
      .catch((error) => showErrorResponse(error));
  }

}
