
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/authentication/store';
import { showErrorResponse } from '@/application/snackbar/service';
import { isFeatureVisibleForRiders } from '@/application/authentication/helper';
import { FeatureVisibleForRiders } from '@/types';
import PaddockChart from './paddock-chart.vue';
import LatestOwnersChartComponent from './latest-owners-chart.vue';
import LatestHorsesChart from './latest-horses-chart.vue';
import NewsOverviewChartComponent from './news-overview-chart.vue';
import OwnerActivityChartComponent from './owner-activity-chart.vue';
import HorseOverviewChartComponent from './horse-overview-chart.vue';
import { useAnalyticsStore } from '../store';

@Component({
  components: {
    PaddockChart,
    LatestOwnersChartComponent,
    LatestHorsesChart,
    NewsOverviewChartComponent,
    OwnerActivityChartComponent,
    HorseOverviewChartComponent,
  },
})
export default class AnalyticsChartsOverview extends Vue {

  readonly store = useAnalyticsStore();
  readonly authenticationStore = useAuthenticationStore();

  get areChartsVisible(): boolean {
    return this.store.analytics !== null;
  }

  get isPenStatusVisible(): boolean {
    return isFeatureVisibleForRiders(FeatureVisibleForRiders.PEN_STATUS);
  }

  mounted(): void {
    this.store.getFarmAnalytics()
      .catch((error) => showErrorResponse(error));
  }

}
