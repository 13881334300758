import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { DeleteOwnAccountCommand, Profile, UpdateEmergencyContactOfUserCommand, UpdatePasswordOfUserCommand, UpdatePhoneNumberCommand, UpdateUserEmailAddressCommand, UpdateUserNameCommand } from './types';

// -- Queries

export function getProfile(query: FarmAndUserProperties): Promise<Profile> {
  const url = `${apiUrl}/api/profile/get-profile-query`;
  return performApiRequest<Profile>({ url, method: 'POST', data: query });
}

// -- Commands

export function updateUserName(command: UpdateUserNameCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/profile/update-user-name-workflow`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateUserEmailAddress(command: UpdateUserEmailAddressCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/profile/update-user-email-address-workflow`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updatePhoneNumber(command: UpdatePhoneNumberCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/profile/update-phone-number-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateEmergencyContactOfUser(command: UpdateEmergencyContactOfUserCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/profile/update-emergency-contact-of-user-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updatePasswordOfUser(command: UpdatePasswordOfUserCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/profile/update-password-of-user-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function acceptNewsletter(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/profile/accept-newsletter-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function rejectNewsletter(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/profile/reject-newsletter-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteOwnAccount(command: DeleteOwnAccountCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/profile/delete-own-account-workflow`;
  return performApiRequest({ url, method: 'POST', data: command });
}
