
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/authentication/store';
import { useFreeJumpingFreeRunningStore } from '../store';
import CreateFreeJumpingFreeRunningEventRegistrationDialogComponent from './create-free-jumping-free-running-event-registration-dialog.vue';
import DeleteFreeJumpingFreeRunningEventRegistrationDialogComponent from './delete-free-jumping-free-running-event-registration-dialog.vue';
import { Event } from '../types';

@Component({
  components: {
    CreateFreeJumpingFreeRunningEventRegistrationDialogComponent,
    DeleteFreeJumpingFreeRunningEventRegistrationDialogComponent,
  },
})
export default class FreeJumpingFreeRunningEvent extends Vue {

  readonly store = useFreeJumpingFreeRunningStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly event!: Event;

}
