
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Time } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, notCheckedWhenValueIsSetRule } from '@/components/form';
import { useFacilityReservationsManagementStore } from '../store';
import { FacilityReservationActivity, UpdateTimeBasedRestrictionsOfFacilityReservationActivityAsManagerCommand } from '../types';

interface Controls extends FormControls {
  reservationOnPreviousDayNotLaterThan: FormControl<Time>;
  isRiderAbleToUpdateStartedReservations: FormControl<boolean>;
  isRiderAbleToCancelStartedReservations: FormControl<boolean>;
}

@Component
export default class UpdateTimeBasedRestrictionsOfFacilityReservationActivityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Prop({ type: Object, required: true })
  readonly facilityReservationActivity!: FacilityReservationActivity;

  readonly dialogMaxWidth = DialogWidth.large;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        reservationOnPreviousDayNotLaterThan: {
          label: 'Späteste Uhrzeit zur Reservierung am Folgetag',
          value: this.facilityReservationActivity.reservationOnPreviousDayNotLaterThan,
          validateFormControlsOnInput: [
            'isRiderAbleToUpdateStartedReservations',
            'isRiderAbleToCancelStartedReservations',
          ],
        },
        isRiderAbleToUpdateStartedReservations: {
          label: 'Ein Reiter kann eine bereits gestartete Reservierung noch anpassen',
          value: this.facilityReservationActivity.isRiderAbleToUpdateStartedReservations,
          rules: [],
        },
        isRiderAbleToCancelStartedReservations: {
          label: 'Ein Reiter kann eine bereits gestartete Reservierung noch stornieren',
          value: this.facilityReservationActivity.isRiderAbleToCancelStartedReservations,
          rules: [],
        },
      },
    });

    form.controls.isRiderAbleToUpdateStartedReservations.rules!.push(notCheckedWhenValueIsSetRule(
      form.controls.reservationOnPreviousDayNotLaterThan,
      'Die späteste Uhrzeit zur Reservierung am Folgetag darf nicht gesetzt sein, wenn ein Reiter eine bereits gestartete Reservierung noch anpassen können soll'
    ));

    form.controls.isRiderAbleToCancelStartedReservations.rules!.push(notCheckedWhenValueIsSetRule(
      form.controls.reservationOnPreviousDayNotLaterThan,
      'Die späteste Uhrzeit zur Reservierung am Folgetag darf nicht gesetzt sein, wenn ein Reiter eine bereits gestartete Reservierung noch stornieren können soll'
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateTimeBasedRestrictionsOfFacilityReservationActivityAsManagerCommand = {
      facilityReservationActivityId: this.facilityReservationActivity.facilityReservationActivityId,
      reservationOnPreviousDayNotLaterThan: formValues.reservationOnPreviousDayNotLaterThan,
      isRiderAbleToUpdateStartedReservations: formValues.isRiderAbleToUpdateStartedReservations!,
      isRiderAbleToCancelStartedReservations: formValues.isRiderAbleToCancelStartedReservations!,
    };

    this.store.updateTimeBasedRestrictionsOfFacilityReservationActivity(command)
      .then(() => showSuccessMessage('Die zeitlichen Einschränkungen wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
