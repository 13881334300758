
import { Component, Vue } from 'vue-property-decorator';
import { Date } from '@/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { usePenManagementStore } from '../store';
import { CreatePenStatusEntryAsManagerCommand, UpdatePenStatusEntryAsManagerCommand } from '../types';

@Component
export default class TrackTodaysPaddockStatus extends Vue {

  readonly store = usePenManagementStore();

  get paddockIsUsedToday(): boolean {
    return !!this.store.todaysPaddockStatus
      && this.store.todaysPaddockStatus.wasPenUsed;
  }

  get paddockIsUnusedToday(): boolean {
    return !!this.store.todaysPaddockStatus
      && !this.store.todaysPaddockStatus.wasPenUsed;
  }

  mounted(): void {
    this.store.getPenStatusEntries()
      .catch((error) => showErrorResponse(error));
  }

  setPaddockStatus(used: boolean): void {
    if (this.store.todaysPaddockStatus) {
      const command: UpdatePenStatusEntryAsManagerCommand = {
        penStatusEntryId: this.store.todaysPaddockStatus.penStatusEntryId,
        date: this.store.todaysPaddockStatus.date,
        wasPenUsed: used,
      };

      this.store.updatePenStatusEntry(command)
        .then(() => showSuccessMessage('Die Änderungen wurden gespeichert.'))
        .catch((error) => showErrorResponse(error));

      return;
    }

    const command: CreatePenStatusEntryAsManagerCommand = {
      date: Date.today(),
      wasPenUsed: used,
    };

    this.store.createPenStatusEntry(command)
      .then(() => showSuccessMessage('Der Koppelstatus wurde veröffentlicht.'))
      .catch((error) => showErrorResponse(error));
  }

}
