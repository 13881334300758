
import { Component, Prop, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { Moment } from '@/types';
import { moment } from '@/helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useDashboardStore } from '../store';
import { CancelRidingLessonRegistrationCommand, RidingLesson } from '../types';

@Component
export default class RidingLessonCard extends Vue {

  readonly store = useDashboardStore();

  @Prop({ type: Object, required: true })
  readonly ridingLesson!: RidingLesson;

  isUpdateMenuVisible = false;

  dayLabel(from: Moment): string {
    const now = moment();
    if (now.isSame(from, 'day')) {
      return 'Heute';
    }

    if (now.add(1, 'day').isSame(from, 'day')) {
      return 'Morgen';
    }

    if (now.add(2, 'day').isSame(from, 'day')) {
      return 'Übermorgen';
    }

    return from.format('DD.MM');
  }

  showInCalendarClicked(ridingLesson: RidingLesson): void {
    if (ridingLesson.isRidingLessonPlanVisible) {
      navigate({
        name: 'booking-calendars/riding-lesson-plan',
        params: {
          date: ridingLesson.timeFrame.momentFrom.format('YYYY-MM-DD'),
          ridingLessonId: ridingLesson.ridingLessonId,
        },
      });
    } else {
      navigate({
        name: 'booking-calendars/facility-calendar-riding-lesson',
        params: {
          id: ridingLesson.facilityId,
          date: ridingLesson.timeFrame.momentFrom.format('YYYY-MM-DD'),
          ridingLessonId: ridingLesson.ridingLessonId,
          action: 'view',
        },
      });
    }

  }

  async cancelRegistrationAction(): Promise<void> {
    const command: CancelRidingLessonRegistrationCommand = {
      ridingLessonRegistrationId: this.ridingLesson.ridingLessonRegistrationId,
    };

    return this.store.cancelRidingLessonRegistration(command)
      .then(() => showSuccessMessage('Abmeldung erfolgreich.'))
      .catch((error) => showErrorResponse(error));
  }

  hideMenu(): void {
    this.isUpdateMenuVisible = false;
  }

}
