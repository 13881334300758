
import { Vue, Component } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse } from '@/application/snackbar/service';
import { formatName } from '@/helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalStringRule } from '@/components/form';
import { useProfileStore } from '../store';
import { DeleteOwnAccountCommand } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
  currentPassword: FormControl<string>;
}

@Component
export default class DeleteOwnAccountDialog extends Vue {

  readonly store = useProfileStore();

  readonly size = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Voller Name',
          value: null,
          isRequired: true,
          rules: [
            identicalStringRule(formatName(this.store.profile!.name)),
          ],
        },
        currentPassword: {
          label: 'Aktuelles Passwort',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: DeleteOwnAccountCommand = {
      name: formValues.name!,
      currentPassword: formValues.currentPassword!,
    };

    this.store.deleteOwnAccount(command)
      .then(() => this.closeDialog())
      .then(() => this.$router.push({ name: 'logout' }))
      .catch((error) => showErrorResponse(error));
  }

  cancelled(): void {
    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
