
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';

@Component
export default class DiscardChangesDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  public show(): void {
    this.isDialogVisible = true;
  }

  discardChanges(): void {
    this.isDialogVisible = false;
    this.$emit('discard-changes');
  }

  goBack(): void {
    this.isDialogVisible = false;
    this.$emit('go-back');
  }

}
