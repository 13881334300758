
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { canBeParsedAsNumber, convertStringToNumber } from '@/helpers';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlValue, FormFunctions, FormControlComponent, internalValuesChanged, mountFormControl, InternalValueRules, wasValidationSuccessful, InternalValueRule, labelWithRequiredIndicator, isFieldShownAsContainingAnError } from '@/components/form';
import { formatNumber } from '@/helpers/stateful-format';

function numberStringRule(message = 'Der Wert muss eine gültige Zahl sein'): InternalValueRule<string> {
  return (value) => {
    if (!value) {
      return true;
    }

    return canBeParsedAsNumber(value)
      ? true
      : message;
  };
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class NumberFormControl extends Vue implements FormControlComponent<number> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<number>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  readonly formControlId = createFormControlId();

  readonly internalFieldRules: InternalValueRules = [
    numberStringRule(),
  ];

  messages: string[] = [];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  internalValue: string | null = '';

  formattedValueAsString = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  get valueAsString(): string {
    return this.isFocused
      ? this.internalValue ?? ''
      : this.formattedValueAsString;
  }

  set valueAsString(updatedValue: string) {
    this.internalValue = updatedValue;
  }

  mounted(): void {
    mountFormControl(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
    this.formatValueAsString();
  }

  valueAsStringChanged(): void {
    if (this.internalValue === null) {
      this.internalValue = '';
    }

    internalValuesChanged(this);
  }

  formatValueAsString(): void {
    const isValueInvalid = numberStringRule()(this.internalValue ?? '') !== true;

    // If the value is invalid or empty, the value won't get formatted
    if (!this.internalValue || isValueInvalid) {
      this.formattedValueAsString = this.internalValue ?? '';
      return;
    }

    this.formattedValueAsString = formatNumber(this.formControl.value);
    this.internalValue = formatNumber(this.formControl.value, false);
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    this.messages = [
      ...errorMessagesForInternalRules(this.internalFieldRules, this.internalValue),
    ];

    return wasValidationSuccessful(this.messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value !== null
      ? formatNumber(this.formControl.value, false)
      : '';

    this.formattedValueAsString = this.formControl.value !== null
      ? formatNumber(this.formControl.value)
      : '';
  }

  formValueFromInternalValues(): FormControlValue<number> {
    const isInternalValueANumber = (this.internalValue ?? '').trim() !== ''
      && numberStringRule()(this.internalValue ?? '') === true;

    return isInternalValueANumber
      ? convertStringToNumber(this.internalValue ?? '')
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
