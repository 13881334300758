import { render, staticRenderFns } from "./loading-bar.vue?vue&type=template&id=c57b5b8c&"
import script from "./loading-bar.vue?vue&type=script&lang=ts&"
export * from "./loading-bar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports