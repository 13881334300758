
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { RegistrationCode } from '@/types';
import { DialogWidth } from '@/helpers/data';
import { useUserManagementStore } from '../store';
import { RegistrationConfiguration, UpdateRegistrationConfigurationAsManagerCommand } from '../types';

interface Controls extends FormControls {
  isHorsesViewVisibleForNewRiders: FormControl<boolean>;
  isHorsesViewVisibleForNewFarmManagers: FormControl<boolean>;
  registrationCode: FormControl<RegistrationCode>;
}

@Component
export default class UpdateRegistrationConfigurationDialog extends Vue {

  readonly store = useUserManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true})
  readonly registrationConfiguration!: RegistrationConfiguration;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        isHorsesViewVisibleForNewRiders: {
          label: 'Neue Reiter sehen die Pferdeansicht',
          value: this.registrationConfiguration.isHorsesViewVisibleForNewRiders,
        },
        isHorsesViewVisibleForNewFarmManagers: {
          label: 'Neue Hofverwalter sehen die Pferdeansicht',
          value: this.registrationConfiguration.isHorsesViewVisibleForNewFarmManagers,
        },
        registrationCode: {
          label: 'Registrierungscode',
          value: this.registrationConfiguration.registrationCode,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateRegistrationConfigurationAsManagerCommand = {
      isHorsesViewVisibleForNewRiders: formValues.isHorsesViewVisibleForNewRiders!,
      isHorsesViewVisibleForNewFarmManagers: formValues.isHorsesViewVisibleForNewFarmManagers!,
      registrationCode: formValues.registrationCode!,
    };

    this.store.updateRegistrationConfiguration(command)
      .then(() => showSuccessMessage('Einstellungen wurden erfolgreich gespeichert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
