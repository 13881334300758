
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/authentication/store';
import { useAppStore } from '@/application/app/store';
import { isFeatureEnabled, isFeatureVisibleForRiders } from '@/application/authentication/helper';
import { Feature, FeatureVisibleForRiders } from '@/types';
import { usePinboardStore } from '../store';

@Component
export default class Pinboard extends Vue {

  readonly store = usePinboardStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly appStore = useAppStore();

  get isUserOnRootPage(): boolean {
    return this.$route.name === 'news-list'
      || this.$route.name === 'event-list'
      || this.$route.name === 'survey-list';
  }

  get areTabsVisible(): boolean {
    let visibleTabs = 0;
    if (this.isNewsVisible) {
      visibleTabs++;
    }
    if (this.isEventsVisible) {
      visibleTabs++;
    }
    if (this.isSurveysVisible) {
      visibleTabs++;
    }

    return this.isUserOnRootPage
      && visibleTabs >= 2;
  }

  get isNewsVisible(): boolean {
    return isFeatureVisibleForRiders(FeatureVisibleForRiders.NEWS);
  }

  get isEventsVisible(): boolean {
    return isFeatureVisibleForRiders(FeatureVisibleForRiders.EVENTS);
  }

  get isSurveysVisible(): boolean {
    return isFeatureEnabled(Feature.SURVEYS);
  }

}
