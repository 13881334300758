
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { paymentMethodTranslations } from '@/helpers/translations';
import { DataListItem } from '@/components/design-system';
import { useBoxPlanManagementStore } from '../store';
import UpdateBoxPlanPaymentMethodDialog from './update-box-plan-payment-method-dialog.vue';

@Component({
  components: {
    UpdateBoxPlanPaymentMethodDialog,
  },
})
export default class BoxPlanSettlement extends Vue {

  readonly store = useBoxPlanManagementStore();

  get dataItems(): DataListItem[] {
    if (!this.store.boxPlanPaymentConfiguration) {
      return [];
    }

    return [
      {
        key: 'paymentMethod',
        label: 'Bezahlmethode',
        value: paymentMethodTranslations[this.store.boxPlanPaymentConfiguration!.paymentMethod],
      },
    ];
  }

  get isUpdateBoxPlanPaymentMethodVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  mounted(): void {
    this.store.getBoxPlanPaymentConfiguration()
      .catch((error) => showErrorResponse(error));
  }

}
