
import { Component, Vue } from 'vue-property-decorator';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import HorseSelection from './horse-selection.vue';
import { usePaddockPlanManagementStore } from '../store';
import { AddHorseToTimeRangeAsManagerCommand, PaddockPlanHorseAssignment, PaddockPlanPaddock, PaddockTimeRangeWithAssignments, RemoveHorseFromTimeRangeAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    search: FormField<string|null>;
  }
}

@Component({
  components: {
    HorseSelection,
  },
})
export default class PaddockPlan extends Vue {

  readonly store = usePaddockPlanManagementStore();

  form: Form | null = null;

  get hasValidConfiguration(): boolean {
    return this.store.paddockPlan !== null
      && this.store.paddockPlan.paddocks.length > 0
      && this.store.paddockPlan.paddocks[0].timeRanges.length > 0;
  }

  get isHorseSelectionVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PADDOCK_PLAN_WRITE);
  }

  get isRemoveHorseFromPaddockVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PADDOCK_PLAN_WRITE)
      && !this.store.idOfSelectedHorse;
  }

  get isAddHorseToTimeRangeDisabled(): boolean {
    return !this.store.idOfSelectedHorse
      || !!this.store.idOfPaddockPlanTimeRangeInProgress;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        search: {
          value: null,
          rules: [],
        },
      },
    };
  }

  addHorseToTimeRange(paddock: PaddockPlanPaddock, timeRange: PaddockTimeRangeWithAssignments): void {
    const command: AddHorseToTimeRangeAsManagerCommand = {
      paddockPlanPaddockId: paddock.paddockPlanPaddockId,
      paddockPlanTimeRangeId: timeRange.paddockPlanTimeRangeId,
      horseId: this.store.idOfSelectedHorse!,
    };

    this.store.addHorseToTimeRange(command)
      .then(() => showSuccessMessage('Das Pferd wurde der Koppel zugewiesen.'))
      .catch((error) => showErrorResponse(error));
  }

  removeHorseFromPaddockAction(paddockAssignment: PaddockPlanHorseAssignment): Promise<void> {
    const command: RemoveHorseFromTimeRangeAsManagerCommand = {
      paddockPlanAssignmentId: paddockAssignment.paddockPlanAssignmentId,
      horseId: paddockAssignment.horse.horseId,
    };

    return this.store.removeHorseFromTimeRange(command)
      .then(() => showSuccessMessage('Das Pferd wurde von der Koppel entfernt.'))
      .catch((error) => showErrorResponse(error));
  }

  isPaddockVisibleOnPrint(paddock: PaddockPlanPaddock): boolean {
    return paddock.timeRanges.some((timeRange) => timeRange.paddockPlanAssignments.length > 0);
  }

  isTimeRangeVisibleOnPrint(timeRange: PaddockTimeRangeWithAssignments): boolean {
    return timeRange.paddockPlanAssignments.length > 0;
  }

  namesForTimeRange(timeRange: PaddockTimeRangeWithAssignments): string {
    return timeRange.paddockPlanAssignments
      .map((assignment) => assignment.horse.name)
      .join(', ');
  }

}
