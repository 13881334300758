
import { Component, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { maxNumberRule, minNumberRule, positiveNumberRule, requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifySelectItem, VuetifyValidatable } from '@/application/types';
import { generateDefaultTimesForCurrentDaySelectItems } from '@/helpers/form-helpers';
import { DialogWidth } from '@/helpers/data';
import { Time } from '@/types';
import { uuid } from '@/helpers';
import { useFacilityManagementStore } from '../store';
import { CreateFacilityAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    name: FormField<string|null>;
    openingFrom: FormField<Time>;
    openingTo: FormField<Time>;
    spaces: FormField<number|null>;
  };
}

// Creates an array of numbers from 5 to 60 with an interval of 5
const selectIntervalMinutes = 15;
const selectDefaultStartTime = new Time(7, 0, 0);
const selectDefaultEndTime = new Time(22, 0, 0);
const defaultSpaces = 1;

@Component
export default class CreateFacilityDialog extends Vue {

  readonly store = useFacilityManagementStore();

  @Ref()
  readonly allowBookingFromSelect?: VuetifyValidatable;

  @Ref()
  readonly allowBookingToSelect?: VuetifyValidatable;

  readonly dialogMaxWidth = DialogWidth.large;

  readonly timesSelectItems: VuetifySelectItem<Time>[] = generateDefaultTimesForCurrentDaySelectItems(selectIntervalMinutes);

  isDialogVisible = false;

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        name: {
          value: null,
          rules: [
            requiredRule(),
          ],
        },
        openingFrom: {
          value: selectDefaultStartTime,
          rules: [
            requiredRule(),
            this.startBeforeEndRule,
          ],
        },
        openingTo: {
          value: selectDefaultEndTime,
          rules: [
            requiredRule(),
            this.endAfterStartRule,
          ],
        },
        spaces: {
          value: defaultSpaces,
          rules: [
            requiredRule(),
            positiveNumberRule(0),
            minNumberRule(1),
            maxNumberRule(10),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const facilityId = uuid();
    const command: CreateFacilityAsManagerCommand = {
      facilityId,
      name: this.form!.fields.name.value!,
      openingHours: {
        timeFrom: this.form!.fields.openingFrom.value,
        timeTo: this.form!.fields.openingTo.value,
      },
      spaces: this.form!.fields.spaces.value!,
    };

    this.store.createFacility(command)
      .then(() => showSuccessMessage('Neue Anlage wurde erstellt'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  startBeforeEndRule(startTime: Time) {
    return this.form!.fields.openingTo.value.isMidnight
      || startTime.isBefore(this.form!.fields.openingTo.value)
      || 'Startzeit muss vor der Endzeit liegen';
  }

  endAfterStartRule(endTime: Time) {
    return endTime.isMidnight
      || endTime.isAfter(this.form!.fields.openingFrom.value)
      || 'Endzeit muss nach der Startzeit liegen';
  }

  onAllowBookFromChange(): void {
    // Guarantee element since form obviously exists.
    this.allowBookingToSelect!.validate();
  }

  onAllowBookToChange(): void {
    // Guarantee element since form obviously exists.
    this.allowBookingFromSelect!.validate();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
