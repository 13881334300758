
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { HorseId, FarmManagerPermission } from '@/types';
import { formatDate } from '@/helpers';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { useMedicalRecordsManagementStore } from '../store';
import { HorseWithMedicalRecord } from '../types';
import CreateHorseWeightEntryDialog from './create-horse-weight-entry-dialog.vue';
import CreateHorseDrugEntryDialog from './create-horse-drug-entry-dialog.vue';
import CreateHorseFarrierAppointmentDialog from './create-horse-farrier-appointment-dialog.vue';
import CreateHorseWormingTreatmentDialog from './create-horse-worming-treatment-dialog.vue';
import CreateHorseWormingTreatmentForMultipleHorsesDialog from './create-horse-worming-treatment-for-multiple-horses-dialog.vue';
import HorseWeightHistoryDialog from './horse-weight-history-dialog.vue';
import HorseDrugHistoryDialog from './horse-drug-history-dialog.vue';
import HorseFarrierAppointmentHistoryDialog from './horse-farrier-appointment-history-dialog.vue';
import HorseWormingTreatmentHistoryDialog from './horse-worming-treatment-history-dialog.vue';

interface Controls extends FormControls {
  horse: FormControl<HorseId>;
}

@Component({
  methods: { formatDate },
  components: {
    CreateHorseWeightEntryDialog,
    CreateHorseDrugEntryDialog,
    CreateHorseFarrierAppointmentDialog,
    CreateHorseWormingTreatmentDialog,
    CreateHorseWormingTreatmentForMultipleHorsesDialog,
    HorseWeightHistoryDialog,
    HorseDrugHistoryDialog,
    HorseFarrierAppointmentHistoryDialog,
    HorseWormingTreatmentHistoryDialog,
  },
})
export default class MedicalRecords extends Vue {

  readonly store = useMedicalRecordsManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Pferd', value: 'horse' },
    { text: 'Letztes Gewicht', value: 'latestWeight' },
    { text: 'Aktuelle Medikamente', value: 'drugs' },
    { text: 'Letzter Hufschmied-Termin', value: 'latestFarrierAppointment' },
    { text: 'Letzte Wurmkur', value: 'latestWormingTreatment' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  readonly horseDetailsMenuList: Record<HorseId, boolean> = {};
  readonly horseUpdateMenuList: Record<HorseId, boolean> = {};

  form: Form<Controls> | null = null;

  get horses(): HorseWithMedicalRecord[] {
    return this.store.medicalRecords?.horses ?? [];
  }

  get isUpdateHorseVisible(): boolean {
    return this.isCreateHorseWeightEntryVisible
      || this.isCreateHorseDrugEntryVisible
      || this.isCreateHorseFarrierAppointmentVisible
      || this.isCreateHorseWormingTreatmentVisible;
  }

  get isCreateHorseWeightEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.MEDICAL_RECORDS_WRITE);
  }

  get isCreateHorseDrugEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.MEDICAL_RECORDS_WRITE);
  }

  get isCreateHorseFarrierAppointmentVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.MEDICAL_RECORDS_WRITE);
  }

  get isCreateHorseWormingTreatmentVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.MEDICAL_RECORDS_WRITE);
  }

  get isCreateWormingTreatmentForMultipleHorsesVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.MEDICAL_RECORDS_WRITE);
  }

  mounted(): void {
    this.store.getHorses()
      .then(() => {
        this.form = this.buildForm(this.store.idOfHorseToFilter);
      })
      .catch((error) => showErrorResponse(error));

    this.store.getMedicalRecords()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(idOfHorseToFilter: HorseId | null): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        horse: {
          label: 'Pferd',
          value: idOfHorseToFilter,
          afterTransformationAndValidation: (value) => {
            this.store.updateIdOfHorseToFilter(value)
              .catch((error) => showErrorResponse(error));
          }
        },
      },
    });
  }

  areDetailsVisible(horse: HorseWithMedicalRecord): boolean {
    return this.isHorseWeightHistoryVisible(horse)
      || this.isHorseDrugHistoryVisible(horse)
      || this.isHorseFarrierAppointmentHistoryVisible(horse)
      || this.isHorseWormingTreatmentHistoryVisible(horse);
  }

  isHorseWeightHistoryVisible(horse: HorseWithMedicalRecord): boolean {
    return horse.weightHistory.length > 0;
  }

  isHorseDrugHistoryVisible(horse: HorseWithMedicalRecord): boolean {
    return horse.drugHistory.length > 0;
  }

  isHorseFarrierAppointmentHistoryVisible(horse: HorseWithMedicalRecord): boolean {
    return horse.farrierAppointmentHistory.length > 0;
  }

  isHorseWormingTreatmentHistoryVisible(horse: HorseWithMedicalRecord): boolean {
    return horse.wormingTreatmentHistory.length > 0;
  }

}
