
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission, SurveyId } from '@/types';
import { formatDate } from '@/helpers';
import { navigate } from '@/helpers/navigation-helpers';
import { useSurveyManagementStore } from '../store';
import { DeleteSurveyAsManagerCommand, SurveyForList } from '../types';
import CreateSurveyDialog from './create-survey-dialog.vue';
import DuplicateSurveyDialog from './duplicate-survey-dialog.vue';

@Component({
  components: {
    CreateSurveyDialog,
    DuplicateSurveyDialog,
  },
})
export default class SurveyList extends Vue {

  readonly store = useSurveyManagementStore();

  readonly updateMenuList: Record<SurveyId, boolean> = {};

  get isCreateSurveyVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.SURVEYS_WRITE);
  }

  get isUpdateVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.SURVEYS_WRITE);
  }

  mounted(): void {
    this.store.getSurveys()
      .catch((error) => showErrorResponse(error));
  }

  surveyClicked(survey: SurveyForList): void {
    navigate({ name: 'manage-worming-treatments/worming-treatment-details', params: { id: survey.surveyId } })
  }

  title(survey: SurveyForList): string {
    return survey.date
      ? `${survey.title} am ${formatDate(survey.date)}`
      : survey.title;
  }

  async deleteSurveyAction(survey: SurveyForList): Promise<void> {
    const command: DeleteSurveyAsManagerCommand = {
      surveyId: survey.surveyId,
    };

    return this.store.deleteSurvey(command)
      .then(() => showSuccessMessage('Umfrage wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
