
import { Component, Vue } from 'vue-property-decorator';
import { ActionStatus } from '@/application/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { moment } from '@/helpers';

@Component
export default class AllowImpersonation extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  public accessUntilDate = '';

  get isAllowImpersonationSuccessful(): boolean {
    return this.authenticationStore.allowImpersonationStatus === ActionStatus.Successful;
  }

  get isAllowImpersonationFailed(): boolean {
    return this.authenticationStore.allowImpersonationStatus === ActionStatus.Failed;
  }

  mounted(): void {
    this.accessUntilDate = moment().add(7, 'days').format('DD.MM.YYYY');
    this.authenticationStore.allowImpersonation(this.$route.params.token)
      .catch((error) => showErrorResponse(error));
  }

}
