
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { DeleteFacilityBlockerAsManagerCommand, FacilityBlocker } from '../types';
import { useFacilityManagementStore } from '../store';

@Component
export default class DeleteFacilityBlockerDialog extends Vue {

  readonly store = useFacilityManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly facilityBlocker!: FacilityBlocker;

  isDialogVisible = false;

  deleteFacilityBlockerConfirmed(): void {
    const command: DeleteFacilityBlockerAsManagerCommand = {
      facilityBlockerId: this.facilityBlocker.facilityBlockerId,
    };

    this.store.deleteFacilityBlocker(command)
      .then(() => showSuccessMessage('Die Sperrzeit wurde gelöscht'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
