
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { InvoiceId } from '@/types';
import { formatDate } from '@/helpers';
import { formatCurrency } from '@/helpers/stateful-format';
import PdfDialog from '@/components/design-system/pdf-dialog/pdf-dialog.vue';
import { downloadFile } from '@/helpers/file-download-helper';
import { GetInvoicePDFAsManagerQuery, Invoice } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

@Component
export default class PersonInvoiceList extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly menuList: Record<InvoiceId, boolean> = {};

  mounted(): void {
    this.store.getInvoicesForPerson()
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetInvoicesForCurrentPerson()
      .catch((error) => showErrorResponse(error));
  }

  description(invoice: Invoice): string {
    return formatCurrency(invoice.totalAmount);
  }

  note(invoice: Invoice): string {
    return `Erstellt am ${formatDate(invoice.invoiceDate)}.`;
  }

  invoiceClicked(invoice: Invoice): void {
    (this.$refs[`pdfDialog-${invoice.invoiceId}`] as PdfDialog).show();
  }

  downloadInvoiceClicked(invoice: Invoice): void {
    const query: GetInvoicePDFAsManagerQuery = {
      invoiceId: invoice.invoiceId,
    };

    const invoiceFileName = `Rechnung-${invoice.invoiceNumber}.pdf`;

    this.store.getInvoicePDFAsFarmManager(query)
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, invoiceFileName))
      .catch((error) => showErrorResponse(error));
  }

}
