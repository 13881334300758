
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, requiredWhenControlIsNotFilledRule } from '@/components/form';
import { useLedgerStore } from '../store';
import { FarmLegalInformation, UpdateFarmLegalInformationAsManagerCommand } from '../types';

interface Controls extends FormControls {
  taxNumber: FormControl<string>;
  vatNumber: FormControl<string>;
  registerNumber: FormControl<string>;
  registrationCourt: FormControl<string>;
}

@Component
export default class UpdateFarmLegalInformationDialog extends Vue {

  readonly store = useLedgerStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly farmLegalInformation!: FarmLegalInformation;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm({
      submitted: this.submitted,
      controls: {
        taxNumber: {
          label: 'Steuernummer',
          value: this.farmLegalInformation.taxNumber,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'vatNumber',
          ],
        },
        vatNumber: {
          label: 'Umsatzsteuer ID',
          value: this.farmLegalInformation.vatNumber,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'taxNumber',
          ],
        },
        registerNumber: {
          label: 'Registernummer',
          value: this.farmLegalInformation.registerNumber,
          rules: [
            maxLengthRule(255),
          ],
        },
        registrationCourt: {
          label: 'Registergericht',
          value: this.farmLegalInformation.registrationCourt,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });

    form.controls.taxNumber.rules!.push(requiredWhenControlIsNotFilledRule(
      form.controls.vatNumber,
      'Steuernummer muss definiert werden, wenn die Umsatzsteuer ID nicht definiert ist'
    ));
    form.controls.vatNumber.rules!.push(requiredWhenControlIsNotFilledRule(
      form.controls.taxNumber,
      'Umsatzsteuer ID muss definiert werden, wenn die Steuernummer nicht definiert ist'
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFarmLegalInformationAsManagerCommand = {
      farmLegalInformation: {
        taxNumber: formValues.taxNumber,
        vatNumber: formValues.vatNumber,
        registerNumber: formValues.registerNumber,
        registrationCourt: formValues.registrationCourt,
      },
    };

    this.store.updateFarmLegalInformation(command)
      .then(() => showSuccessMessage('Die rechtlichen Informationen wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
