
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Address, Country } from '@/types';
import { VuetifySelectItem } from '@/application/types';
import { countryTranslation } from '@/helpers/translations';
import { maxLengthRule } from '@/components/form/internal-rules';
import { useAuthenticationStore } from '@/application/authentication/store';

const postalCountryLengthMap: Record<Country, number> = {
  [Country.de]: 5,
  [Country.at]: 4,
  [Country.ch]: 4,
  [Country.fr]: 5,
  [Country.it]: 5,
};

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class AddressFormControl extends Vue implements FormControlComponent<Address> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Address>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: String, default: null })
  readonly infoText!: string | null;

  readonly formControlId = createFormControlId();

  readonly authenticationStore = useAuthenticationStore();

  street: string = '';
  postal: string = '';
  city: string = '';
  country: Country = Country.de;

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  isValid = false;

  get countryItems(): VuetifySelectItem<Country>[] {
    return Object.values(Country).map((country) => ({
      text: countryTranslation[country],
      value: country,
    }));
  }

  mounted(): void {
    mountFormControl(this);
  }

  requiredWhenAnyFieldIsFilledRule(): InternalValueRule<any> {
    return () => (
          this.street.trim().length === 0
          && this.postal.trim().length === 0
          && this.city.trim().length === 0
      ) || (
      this.street.trim().length > 0
        && this.postal.trim().length > 0
        && this.city.trim().length > 0
    )
      ? true
      : 'Wenn ein Feld gefüllt ist, müssen alle gefüllt werden';
  }

  postalLengthDependingOnCountryRule(): InternalValueRule<string> {
    return (value) => value.trim().length === 0
      || value.trim().length === postalCountryLengthMap[this.country]
      ? true
      : `Die PLZ in ${countryTranslation[this.country]} muss ${postalCountryLengthMap[this.country]} Zeichen lang sein`;
  }

  streetChanged(): void {
    internalValuesChanged(this);
  }

  postalCodeChanged(): void {
    internalValuesChanged(this);
  }

  cityChanged(): void {
    internalValuesChanged(this);
  }

  countryChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(
        [
          this.requiredWhenAnyFieldIsFilledRule(),
        ],
        null,
      ),
      ...errorMessagesForInternalRules(
        [
          maxLengthRule(100, 'Die Straße inkl. Hausnummer darf nicht länger als 100 Zeichen sein'),
        ],
        this.street,
      ),
      ...errorMessagesForInternalRules(
        [
          this.postalLengthDependingOnCountryRule(),
        ],
        this.postal,
      ),
      ...errorMessagesForInternalRules(
        [
          maxLengthRule(50, 'Die Stadt / der Ort darf nicht länger als 50 Zeichen sein'),
        ],
        this.city,
      ),
    ];

    this.isValid = messages.length === 0;

    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    if (this.formControl.value === null) {
      this.street = '';
      this.postal = '';
      this.city = '';
      this.country = this.authenticationStore.user?.farm?.defaultCountryForAddresses ?? Country.de;
    } else {
      this.street = this.formControl.value.street;
      this.postal = this.formControl.value.postal;
      this.city = this.formControl.value.city;
      this.country = this.formControl.value.country;
    }
  }

  formValueFromInternalValues(): FormControlValue<Address> {
    if (!this.isValid) {
      return null;
    }

    return this.street.trim().length > 0
      && this.postal.trim().length > 0
      && this.city.trim().length > 0
      ? {
          street: this.street.trim(),
          postal: this.postal.trim(),
          city: this.city.trim(),
          country: this.country,
        }
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
