
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { useFeedingPlanStore } from '../store';
import { FeedingPlanEntry, FeedingTime, FeedingType } from '../types';

type DynamicFeedingSlot = { item: string, feedingType: FeedingType };

@Component
export default class FeedingPlanTable extends Vue {

  readonly store = useFeedingPlanStore();

  @Prop({ type: String, required: true })
  readonly feedingTime!: FeedingTime;

  get tableHeaders(): DataTableHeader[] {
    const headers: DataTableHeader[] = [
      { text: 'Pferd', value: 'horse' },
    ];

    if (this.store.feedingPlan) {
      this.store.feedingPlan.feedingTypes.forEach((feedingType) => {
        headers.push({ text: feedingType.name, value: feedingType.type });
      });
    }

    headers.push({ text: 'Kraftfutter Hinweis', value: 'concentratedFeedingNotice', width: 400 });

    return headers;
  }

  get dynamicFeedingSlots(): DynamicFeedingSlot[] {
    if (!this.store.feedingPlan) {
      return [];
    }

    return this.store.feedingPlan.feedingTypes.map((feedingType) => (
      {
        item: `item.${feedingType.type}`,
        feedingType,
      }
    ));
  }

  get entries(): FeedingPlanEntry[] {
    return this.store.feedingPlan
      ? this.store.feedingPlan.entries
      : [];
  }

  getFeedingAmount(feedingType: FeedingType, feedingPlanEntry: FeedingPlanEntry): number {
    const feedingForType = feedingPlanEntry.feedings.find((feeding) => feeding.type === feedingType.type);
    // Type might not be included in entry if it wasn't part of the update
    if (!feedingForType) {
      return 0;
    }

    if (this.feedingTime === 'morning') {
      return feedingForType.morning;
    }
    if (this.feedingTime === 'noon') {
      return feedingForType.noon;
    }
    if (this.feedingTime === 'evening') {
      return feedingForType.evening;
    }

    return 0;
  }

}
