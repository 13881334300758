import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { FarmServiceId, HorseId, Moment, Month, UserId } from '@/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { getFarmServiceOverviewFilterDataAsManager, getFarmServiceUsagesForDay, getFarmServiceUsagesForMonth, updateHiddenFarmServices } from './service';
import { FarmServiceOverviewFilterData, FarmServiceUsagesForDay, FarmServiceUsagesForMonth, GetFarmServiceUsagesForDayAsManagerQuery, GetFarmServiceUsagesForMonthAsManagerQuery, UpdateHiddenFarmServicesAsManagerCommand } from './types';

interface FarmServiceOverviewState {
  farmServiceOverviewFilterData: FarmServiceOverviewFilterData | null;

  selectedDate: Moment | null;
  selectedMonth: Month | null;
  selectedUserId: UserId | null;
  selectedHorseId: HorseId | null;
  selectedFarmServiceId: FarmServiceId | null;
  farmServiceOverviewForDay: FarmServiceUsagesForDay | null;
  farmServiceUsagesForMonth: FarmServiceUsagesForMonth | null;

  getFarmServiceOverviewFilterDataAsManagerStatus: ActionStatus;
  getFarmServiceUsagesForDayStatus: ActionStatus;
  getFarmServiceUsagesForMonthStatus: ActionStatus;
  updateHiddenFarmServicesStatus: ActionStatus;
}

function initialState(): FarmServiceOverviewState {
  return {
    farmServiceOverviewFilterData: null,

    selectedDate: null,
    selectedMonth: null,
    selectedUserId: null,
    selectedHorseId: null,
    selectedFarmServiceId: null,
    farmServiceOverviewForDay: null,
    farmServiceUsagesForMonth: null,

    getFarmServiceOverviewFilterDataAsManagerStatus: ActionStatus.None,
    getFarmServiceUsagesForDayStatus: ActionStatus.None,
    getFarmServiceUsagesForMonthStatus: ActionStatus.None,
    updateHiddenFarmServicesStatus: ActionStatus.None,
  };
}

export const useFarmServiceOverviewStore = defineStore('farmServiceOverview', {
  state: (): FarmServiceOverviewState => initialState(),
  getters: {
    isGetHiddenFarmServicesProcessing: (state: FarmServiceOverviewState): boolean =>
      state.getFarmServiceOverviewFilterDataAsManagerStatus === ActionStatus.InProgress,
    isGetFarmServiceUsagesForDayProcessing: (state: FarmServiceOverviewState): boolean =>
      state.getFarmServiceUsagesForDayStatus === ActionStatus.InProgress,
    isGetFarmServiceUsagesForMonthProcessing: (state: FarmServiceOverviewState): boolean =>
      state.getFarmServiceUsagesForMonthStatus === ActionStatus.InProgress,
    isUpdateHiddenFarmServicesProcessing: (state: FarmServiceOverviewState): boolean =>
      state.updateHiddenFarmServicesStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSelectedDate(selectedDate: Moment): Promise<void> {
      this.selectedDate = selectedDate;

      return this.getFarmServiceUsagesForDay();
    },

    updateSelectedMonth(selectedMonth: Month): Promise<void> {
      this.selectedMonth = selectedMonth;

      return this.getFarmServiceUsagesForMonth();
    },

    updateSelectedUser(userId: UserId | null): Promise<void> {
      this.selectedUserId = userId;

      return this.getFarmServiceUsagesForMonth();
    },

    updateSelectedHorse(horseId: HorseId | null): Promise<void> {
      this.selectedHorseId = horseId;

      return this.getFarmServiceUsagesForMonth();
    },

    updateSelectedFarmService(farmServiceId: FarmServiceId | null): Promise<void> {
      this.selectedFarmServiceId = farmServiceId;

      return this.getFarmServiceUsagesForMonth();
    },

    // -- Queries

    getFarmServiceOverviewFilterData(): Promise<void> {
      const { getFarmServiceOverviewFilterDataAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmServiceOverviewFilterDataAsManagerStatus,
        () => getFarmServiceOverviewFilterDataAsManager(attachFarmAndUserProperties({}))
          .then((farmServiceOverviewFilterData) => {
            this.farmServiceOverviewFilterData = farmServiceOverviewFilterData;
          })
      );
    },

    getFarmServiceUsagesForDay(): Promise<void> {
      const query: GetFarmServiceUsagesForDayAsManagerQuery = {
        date: this.selectedDate!,
      };
      const { getFarmServiceUsagesForDayStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmServiceUsagesForDayStatus,
        () => getFarmServiceUsagesForDay(attachFarmAndUserProperties(query))
          .then((farmServiceOverview) => {
            this.farmServiceOverviewForDay = farmServiceOverview;
          })
      );
    },

    getFarmServiceUsagesForMonth(): Promise<void> {
      const query: GetFarmServiceUsagesForMonthAsManagerQuery = {
        month: this.selectedMonth!,
        filteredByUser: this.selectedUserId,
        filteredByHorse: this.selectedHorseId,
        filteredByFarmService: this.selectedFarmServiceId,
      };
      const { getFarmServiceUsagesForMonthStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmServiceUsagesForMonthStatus,
        () => getFarmServiceUsagesForMonth(attachFarmAndUserProperties(query))
          .then((farmServiceUsagesForMonth) => {
            this.farmServiceUsagesForMonth = farmServiceUsagesForMonth;
          })
      );
    },

    // -- Commands

    updateHiddenFarmServices(command: UpdateHiddenFarmServicesAsManagerCommand): Promise<void> {
      const { updateHiddenFarmServicesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHiddenFarmServicesStatus,
        () => updateHiddenFarmServices(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServiceOverviewFilterData())
          .then(async () => {
            const actions = [];
            if (this.selectedDate !== null) {
              actions.push(this.getFarmServiceUsagesForDay());
            }
            if (this.selectedMonth !== null) {
              actions.push(this.getFarmServiceUsagesForMonth());
            }

            return Promise.all(actions).then(() => Promise.resolve());
          })
      );
    },

  },
});
