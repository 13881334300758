
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { EmailAddress } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useProfileStore } from '../store';
import { Profile, UpdateUserEmailAddressCommand } from '../types';

interface Controls extends FormControls {
  emailAddress: FormControl<EmailAddress>;
}

@Component
export default class UpdateUserEmailAddressDialog extends Vue {

  readonly store = useProfileStore();

  @Prop({ type: Object, required: true })
  readonly profile!: Profile;

  readonly size = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        emailAddress: {
          label: 'E-Mail-Adresse',
          value: this.profile.emailAddress,
          isRequired: true,
        },
      }
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateUserEmailAddressCommand = {
      emailAddress: formValues.emailAddress!,
    };

    this.store.updateUserEmailAddress(command)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  cancelled(): void {
    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
