
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalStringRule } from '@/components/form';
import { Name, PersonId } from '@/types';
import { formatName } from '@/helpers';
import { ArchivePersonAsManagerWorkflow } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  fullName: FormControl<string>;
  reason: FormControl<string>;
}

@Component({
  methods: { formatName }
})
export default class ArchivePersonDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: String, required: true })
  readonly personId!: PersonId;

  @Prop({ type: Object, required: true })
  readonly name!: Name;

  @Prop({ type: Boolean, required: true })
  readonly isConnectedToUser!: boolean;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid
      || this.isConnectedToUser;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        fullName: {
          label: 'Name der Person',
          value: null,
          isRequired: true,
          rules: [
            identicalStringRule(formatName(this.name), 'Muss mit dem Namen der Person übereinstimmen'),
          ],
        },
        reason: {
          label: 'Begründung',
          value: null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: ArchivePersonAsManagerWorkflow = {
      personId: this.personId,
      fullNameOfPerson: formValues.fullName!,
      reason: formValues.reason,
    };

    this.store.archivePerson(command)
      .then(() => showSuccessMessage('Die Person wurde archiviert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
