
import { Component, Vue } from 'vue-property-decorator';
import { showErrorMessage, showErrorResponse } from '@/application/snackbar/service';
import { usePinboardStore } from '../store';
import EventDetails from './event-details.vue';

@Component({
  components: {
    EventDetails,
  },
})
export default class EventDetailsLoadingWrapper extends Vue {

  readonly store = usePinboardStore();

  get isLoadingBarVisible(): boolean {
    return this.store.events.length === 0
      && this.store.isGetEventsProcessing;
  }

  mounted(): void {
    this.store.updateCurrentEvent(this.$route.params.eventId)
      .then(() => {
        if (!this.store.currentEvent) {
          showErrorMessage('Die Veranstaltung existiert nicht mehr. Ggf. liegt sie in der Vergangenheit oder wurde in der Zwischenzeit gelöscht.');
          this.$router.push({ name: 'event-list' });
        }
      })
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetCurrentEvent()
      .catch((error) => showErrorResponse(error));
  }

}
