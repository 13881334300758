
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Date } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, mountFormControl, wasValidationSuccessful, isFieldShownAsContainingAnError, labelWithRequiredIndicator } from '@/components/form';
import { formattedDatePickerValueAsDate } from '@/helpers';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class DatePickerFormControl extends Vue implements FormControlComponent<Date> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Date>;

  @Prop({ type: Function, default: null })
  readonly isDateAllowed!: ((date: Date) => boolean) | null;

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  @Prop({ type: String, default: null })
  readonly infoText!: string | null;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  isMenuVisible = false;

  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  get formattedTextFieldValue(): string {
    return formattedDatePickerValueAsDate(this.internalValue);
  }

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  dateChanged(): void {
    if (this.internalValue === null) {
      this.internalValue = '';
    }

    internalValuesChanged(this);
    this.focused();
    this.blurred();

    this.isMenuVisible = false;
  }

  textFieldListeners(on: any): any {
    return {
      ...on,
      'click:clear': this.clearButtonClicked,
    };
  }

  allowedDates(date: string): boolean {
    return this.isDateAllowed !== null
      ? this.isDateAllowed(new Date(date))
      : true;
  }

  clearButtonClicked(): void {
    this.internalValue = '';

    internalValuesChanged(this);
    this.blurred();
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value.date;
  }

  formValueFromInternalValues(): FormControlValue<Date> {
    return this.internalValue.length > 0
      ? new Date(this.internalValue)
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
