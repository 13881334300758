
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useAppStore } from '@/application/app/store';
import { showErrorResponse } from '@/application/snackbar/service';
import { FarmManagerPermission, Feature, OrganizationChangeHistory, OrganizationChangeHistoryEntry } from '@/types';
import { languageTranslation, organizationChangeTypeTranslations } from '@/helpers/translations';
import { formatAddress, formatCustomFieldValue, formatInvoiceAddressForOrganization, formatName, formatDate, formatTime, formatBoolean } from '@/helpers';
import { doesAuthenticatedUserHavePermission, isFeatureEnabled } from '@/application/authentication/helper';
import { DataListItem } from '@/components/design-system';
import { useCustomerMasterDataManagementStore } from '../store';
import { CustomField, Organization } from '../types';
import UpdateNameOfOrganizationDialog from './update-name-of-organization-dialog.vue';
import UpdateAssignedPersonsOfOrganizationDialog from './update-assigned-persons-of-organization-dialog.vue';
import UpdateAddressOfOrganizationDialog from './update-address-of-organization-dialog.vue';
import UpdateAdditionalMasterDataOfOrganizationDialog from './update-additional-master-data-of-organization-dialog.vue';
import UpdateInvoiceAddressOfOrganizationDialog from './update-invoice-address-of-organization-dialog.vue';

@Component({
  methods: { formatName },
  components: {
    UpdateInvoiceAddressOfOrganizationDialog,
    UpdateAdditionalMasterDataOfOrganizationDialog,
    UpdateAddressOfOrganizationDialog,
    UpdateAssignedPersonsOfOrganizationDialog,
    UpdateNameOfOrganizationDialog,
  },
})
export default class OrganizationDetails extends Vue {

  readonly store = useCustomerMasterDataManagementStore();
  readonly appStore = useAppStore();

  @Prop({ type: Object, required: true })
  readonly organization!: Organization;

  @Prop({ type: Array, required: true })
  readonly customFieldsForOrganization!: CustomField[];

  get dataItems(): DataListItem[] {
    let items: DataListItem[] = [
      {
        key: 'name',
        label: 'Name',
        value: this.organization.name,
      },
      {
        key: 'address',
        label: 'Adresse',
        value: this.organization.address
          ? formatAddress(this.organization.address)
          : '-',
      },
    ];

    if (isFeatureEnabled(Feature.LEDGER_INVOICING)) {
      items.push({
        key: 'invoiceAddress',
        label: 'Rechnungsadresse',
        value: this.organization.invoiceAddress
          ? formatInvoiceAddressForOrganization(this.organization.invoiceAddress)
          : '-',
      });
    }

    const customFieldItems = this.customFieldsForOrganization.map((customField) => {
      const customFieldValue = this.organization.customFields
        ? this.organization.customFields.find((customFieldValue) => customFieldValue.customFieldId === customField.customFieldId) ?? null
        : null;

      return customFieldValue
        ? {
          key: customField.customFieldId,
          label: customField.label,
          value: formatCustomFieldValue(customFieldValue, customField.options),
        }
        : {
          key: customField.customFieldId,
          label: customField.label,
          value: '-',
        };
    });

    const archivedItems = this.organization.isArchived
      ? [
        {
          key: 'isArchived',
          label: 'Archiviert',
          value: formatBoolean(this.organization.isArchived),
        },
        {
          key: 'reasonForArchiving',
          label: 'Begründung für Archivierung',
          value: this.organization.reasonForArchiving ?? '-',
        },
      ]
      : [];

    items = [
      ...items,
      {
        key: 'assignedPersons',
        label: 'Zugewiesene Personen',
        value: '',
        customTemplateValue: this.organization.assignedPersons,
      },
      {
        key: 'landlinePhoneNumber',
        label: 'Festnetz-Telefonnummer',
        value: this.organization.landlinePhoneNumber ?? '-',
      },
      {
        key: 'mobilePhoneNumber',
        label: 'Mobil-Telefonnummer',
        value: this.organization.mobilePhoneNumber ?? '-',
      },
      {
        key: 'language',
        label: 'Sprache',
        value: languageTranslation[this.organization.language],
      },
      {
        key: 'website',
        label: 'Website',
        value: this.organization.website ?? '-',
      },
      ...customFieldItems,
      {
        key: 'comment',
        label: 'Kommentar',
        value: this.organization.comment ?? '-',
      },
      ...archivedItems,
    ];

    items.map((item: DataListItem) => item.meta = this.organization);

    return items;
  }

  get reversedChangeHistory(): OrganizationChangeHistory {
    return this.organization.changeHistory.reverse();
  }

  mounted(): void {
    watch(() => this.organization, (organization) => {
      const title = `Kundenstammdaten - ${organization.name}`
      this.appStore.updateTitle(title)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });
  }

  changeHistoryTitle(changeHistoryEntry: OrganizationChangeHistoryEntry): string {
    return organizationChangeTypeTranslations[changeHistoryEntry.type];
  }

  changeHistoryDescription(changeHistoryEntry: OrganizationChangeHistoryEntry): string {
    return `am ${formatDate(changeHistoryEntry.changedAt)} um ${formatTime(changeHistoryEntry.changedAt)} Uhr von ${formatName(changeHistoryEntry.nameOfUser)}`;
  }

  isUpdateNameOfOrganizationVisible(key: string): boolean {
    return key === 'name'
      && !this.organization.isArchived
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  isUpdateAddressOfOrganizationVisible(key: string): boolean {
    return key === 'address'
      && !this.organization.isArchived
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  isUpdateInvoiceAddressOfOrganizationVisible(key: string): boolean {
    return key === 'invoiceAddress'
      && !this.organization.isArchived
      && isFeatureEnabled(Feature.LEDGER_INVOICING)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  isUpdateAdditionalMasterDataOfOrganizationVisible(key: string): boolean {
    if (!doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE)
      || this.organization.isArchived
    ) {
      return false;
    }

    const additionalMasterDataKeys = [
      'landlinePhoneNumber',
      'mobilePhoneNumber',
      'website',
      'language',
      'comment',
    ];
    this.customFieldsForOrganization.forEach((customField) => additionalMasterDataKeys.push(customField.customFieldId));

    return additionalMasterDataKeys.includes(key);
  }

  isUpdateAssignedPersonsOfOrganizationVisible(key: string): boolean {
    return key === 'assignedPersons'
      && !this.organization.isArchived
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  organizationUpdated(): void {
    this.store.getOrganization()
      .catch((error) => showErrorResponse(error));
  }

}
