
import { Component, Prop, Vue } from 'vue-property-decorator';
import { vaccinationStatusTranslation } from '@/helpers/translations';
import { VaccinationStatus } from '@/types';
import CreateVaccinationDialog from './create-vaccination-dialog.vue';
import { useMyStableStore } from '../store';
import { IllnessWithStatus, VaccinationCertificate } from '../types';

@Component({
  components: {
    CreateVaccinationDialog,
  },
})
export default class VaccinationCertificateView extends Vue {

  readonly store = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly vaccinationCertificate!: VaccinationCertificate;

  readonly vaccinationStatusTranslation = vaccinationStatusTranslation;

  readonly cssClassMap: Record<VaccinationStatus, string> = {
    [VaccinationStatus.NOT_VACCINATED]: 'not-vaccinated',
    [VaccinationStatus.VACCINATED]: 'vaccinated',
    [VaccinationStatus.EXPIRES_SOON]: 'expires-soon',
    [VaccinationStatus.EXPIRED]: 'expired',
  };

  get isCreateVaccinationVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

  doesIllnessHaveVaccination(illnessWithStatus: IllnessWithStatus): boolean {
    return illnessWithStatus.status === VaccinationStatus.VACCINATED;
  }

  vaccine(vaccineId: string): string {
    const vaccine = this.vaccinationCertificate.vaccines.find((vaccine) => vaccine.vaccineId === vaccineId) || null;
    if (vaccine === null) {
      throw new Error('Invalid vaccine');
    }

    return vaccine.name;
  }

  areActionsVisible(illnessWithStatus: IllnessWithStatus): boolean {
    return this.store.isCurrentHorseOwn
      || illnessWithStatus.history.length > 0;
  }

  isShowHistoryButtonVisible(illnessWithStatus: IllnessWithStatus): boolean {
    return illnessWithStatus.history.length > 0;
  }

}
