import { OpUnitType, QUnitType } from 'dayjs';
import { PhoneNumber, UserRole, Date, Moment, TimeConfigurationType } from '@/types';
import { countDecimals } from '@/helpers';
import { formatNumber } from '@/helpers/stateful-format';
import { FormControl, FormControlRule, FormControlValue } from './types';

export function requiredRule(message?: string): FormControlRule<FormControlValue<unknown>> {
  return (value) => (
    value !== null
  ) ? true
    : message
    ?? 'Dieses Feld muss gefüllt sein';
}

export function minLengthRule(min: number, message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
      || value.length >= min
  ) ? true
    : message
      || `Der Wert muss aus mindestens ${min} Zeichen bestehen`;
}

export function maxLengthRule(max: number, message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
      || value.length <= max
  ) ? true
    : message
      || `Der Wert darf aus maximal ${max} Zeichen bestehen`;
}

export function minNumberRule(min: number, customMessage?: string): FormControlRule<FormControlValue<number>> {
  const message = customMessage || `Die Zahl darf nicht kleiner als ${formatNumber(min)} sein`;
  return (value) => (
    value === null
    || value >= min
  ) ? true
    : message;
}

export function maxNumberRule(max: number, customMessage?: string): FormControlRule<FormControlValue<number>> {
  const message = customMessage || `Die Zahl darf nicht größer als ${formatNumber(max)} sein`;
  return (value) => (
    value === null
    || value <= max
  ) ? true
    : message;
}

export function maxDecimalsNumberRule(decimals: number, customMessage?: string): FormControlRule<FormControlValue<number>> {
  const message = customMessage ?? decimals > 0
    ? `Die Zahl darf maximal ${formatNumber(decimals)} Nachkommastellen haben`
    : 'Die Zahl darf keine Nachkommastellen haben';
  return (value) => (
    value === null
    || countDecimals(value) <= decimals
  ) ? true
    : message;
}

export function oneOfCheckboxesRequiredRule(
  formControls: FormControl<boolean>[],
  message = 'Mindestens eine der Optionen muss gewählt werden'
): FormControlRule<FormControlValue<boolean>> {
  return (value) => (
    value === true
    || formControls.some((formControl) => formControl.value === true)
  ) ? true
    : message;
}

export function onlyOneOfCheckboxesMustBeCheckedRule(
  formControls: FormControl<boolean>[],
  message = 'Maximal eine der Optionen darf gewählt werden'
): FormControlRule<FormControlValue<boolean>> {
  return () => formControls.filter((formControl) => formControl.value === true).length <= 1
    ? true
    : message;
}

export function checkboxOnlyAllowedWhenCheckboxIsCheckedRule(
  formControl: FormControl<boolean>,
  message = 'Maximal eine der Optionen darf gewählt werden'
): FormControlRule<FormControlValue<boolean>> {
  return (value) => !value
    || formControl.value === true
    ? true
    : message;
}

export function requiredWhenCheckboxIsCheckedRule(
  formControl: FormControl<boolean>,
  message = 'Dieses Feld muss gefüllt sein'
): FormControlRule<FormControlValue<any>> {
  return (value) => (
    formControl.value
      ? value !== null
      : true
  ) ? true
    : message;
}

export function requiredWhenControlIsFilledRule(
  formControl: FormControl<any>,
  message = 'Dieses Feld muss gefüllt sein'
): FormControlRule<FormControlValue<any>> {
  return (value) => (
    formControl.value !== null
      ? value !== null
      : true
  ) ? true
    : message;
}

export function requiredWhenControlIsNotFilledRule(
  formControl: FormControl<any>,
  message = 'Dieses Feld muss gefüllt sein'
): FormControlRule<FormControlValue<any>> {
  return (value) => (
    formControl.value === null
      ? value !== null
      : true
  ) ? true
    : message;
}

export function notCheckedWhenValueIsSetRule(
  formControl: FormControl<any>,
  message = 'Diese Option kann nicht gewählt werden'
): FormControlRule<FormControlValue<boolean>> {
  return (value) => (
    formControl.value === null
    || value !== true
  ) ? true
    : message;
}

export function requiredWhenTimeConfigurationTypeIsSelectedRule(
  formControl: FormControl<TimeConfigurationType>,
  matchedType: TimeConfigurationType,
  message = 'Dieses Feld muss gefüllt sein'
): FormControlRule<FormControlValue<any>> {
  return (value) => formControl.value !== matchedType
  || (
    formControl.value === matchedType
    && value !== null
  )
    ? true
    : message;
}

export function requiredWhenUserRoleIsSelectedRule(
  formControl: FormControl<UserRole>,
  matchedUserRole: UserRole,
  message = 'Dieses Feld muss gefüllt sein'
): FormControlRule<FormControlValue<any>> {
  return (value) => formControl.value !== matchedUserRole
    || (
      formControl.value === matchedUserRole
      && value !== null
    )
    ? true
    : message;
}

export function maxDistanceInDaysRule(
  formControl: FormControl<Moment>,
  maxDays: number,
  customMessage?: string
): FormControlRule<FormControlValue<any>> {
  const message = customMessage ?? `Der Zeitraum darf maximal ${maxDays} Tage betragen`;

  return (value) => {
    if (!value
      || !formControl.value
    ) {
      return true;
    }
    const diffDays = value.diff(formControl.value, 'day') < 0
      ? value.diff(formControl.value, 'day') * -1
      : value.diff(formControl.value, 'day');

    return diffDays <= maxDays || message;
  };
}

export function maxDistanceDateRule(
  formControl: FormControl<Date>,
  maxDays: number,
  customMessage?: string
): FormControlRule<FormControlValue<Date>> {
  const message = customMessage ?? `Der Zeitraum darf maximal ${maxDays} Tage betragen`;

  return (value) => {
    if (!value
      || !formControl.value
    ) {
      return true;
    }
    const diffDays = value.diff(formControl.value) < 0
      ? value.diff(formControl.value) * -1
      : value.diff(formControl.value);

    return diffDays <= maxDays || message;
  };
}

export function beforeMomentRule(fromControl: FormControl<Moment>, message?: string): FormControlRule<FormControlValue<Moment>> {
  return (value) => (
    value === null
    || fromControl.value === null
    || value.isBefore(fromControl.value)
  ) ? true
    : message
    || `Das Datum muss sich vor "${fromControl.label}" befinden`;
}

export function afterMomentRule(fromControl: FormControl<Moment>, message?: string): FormControlRule<FormControlValue<Moment>> {
  return (value) => (
    value === null
    || fromControl.value === null
    || value.isAfter(fromControl.value)
  ) ? true
    : message
    || `Das Datum muss sich nach "${fromControl.label}" befinden`;
}

export function beforeDateRule(fromControl: FormControl<Date>, message?: string): FormControlRule<FormControlValue<Date>> {
  return (value) => (
    value === null
    || fromControl.value === null
    || value.isBefore(fromControl.value)
  ) ? true
    : message
    || `Das Datum muss sich vor "${fromControl.label}" befinden`;
}

export function notBeforeDateRule(fromControl: FormControl<Date>, message?: string): FormControlRule<FormControlValue<Date>> {
  return (value) => (
    value === null
    || fromControl.value === null
    || value.isNotBefore(fromControl.value)
  ) ? true
    : message
    || `Das Datum darf sich nicht vor "${fromControl.label}" befinden`;
}

export function afterDateRule(fromControl: FormControl<Date>, message?: string): FormControlRule<FormControlValue<Date>> {
  return (value) => (
    value === null
    || fromControl.value === null
    || value.isAfter(fromControl.value)
  ) ? true
    : message
    || `Das Datum muss sich nach "${fromControl.label}" befinden`;
}

export function notAfterDateRule(fromControl: FormControl<Date>, message?: string): FormControlRule<FormControlValue<Date>> {
  return (value) => (
    value === null
    || fromControl.value === null
    || value.isNotAfter(fromControl.value)
  ) ? true
    : message
    || `Das Datum darf sich nicht nach "${fromControl.label}" befinden`;
}

export function maxDistanceRule(
  formControl: FormControl<Moment>,
  distance: number,
  distanceUnit: QUnitType | OpUnitType,
  message: string
): FormControlRule<FormControlValue<Moment>> {
  return (value) => (
    value === null
    || formControl.value === null
    || (
      value.isAfter(formControl.value)
        ? value.diff(formControl.value, distanceUnit) <= distance
        : formControl.value.diff(value, distanceUnit) <= distance
    )
  ) ? true
    : message;
}

export function identicalStringRule(identicalValue: string, message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value === identicalValue
  ) ? true
    : message
    || `In dem Feld muss "${identicalValue}" stehen`;
}

export function notIdenticalToAnyOfStringsRule(identicalValues: string[], message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || !identicalValues.some((identicalValue) => value === identicalValue)
  ) ? true
    : message
    || `Dieser Wert ist nicht erlaubt`;
}

export function identicalPasswordFormControlRule(
  formControl: FormControl<string>,
  message?: string
): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value === formControl.value
  ) ? true
    : message
    ?? `Das Passwort muss mit "${formControl.label}" übereinstimmen`;
}

export function registrationCodeRule(
  message?: string
): FormControlRule<FormControlValue<string>> {
  const regex = /^[a-züäö0-9\-.]*$/;
  return (value) => (
    value === null
    || regex.test(value)
  ) ? true
    : message
    ?? 'Der Registrierungscode darf nur Kleinbuchstaben, Strich und Punkt enthalten';
}

export function csvCompliantRule(message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || !value.match('[,;"\']')
  ) ? true
    : message
    || 'Darf keines der folgenden Zeichen enthalten: \' " , ;';
}

export function phoneNumberRule(message?: string): FormControlRule<FormControlValue<PhoneNumber>> {
  const phoneNumberRegex = /^(?:([+][0-9]{1,2})+[ .-]*)?([(]{1}[0-9]{1,6}[)])?([0-9 .-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/;
  return (value) => (
    value === null
    || phoneNumberRegex.test(value)
  ) ? true
    : message
    || 'Die Telefonnummer ist nicht gültig';
}

export function passwordRule(message?: string): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value.length >= 8
  ) ? true
    : message
    || 'Das Passwort muss mindestens 8 Zeichen lang sein';
}

export function identicalStringFormControlRule(
  formControl: FormControl<string>,
  message?: string
): FormControlRule<FormControlValue<string>> {
  return (value) => (
    value === null
    || value === formControl.value
  ) ? true
    : message
    || `Der Text muss mit "${formControl.label}" übereinstimmen`;
}

export function minCountRule(
  minCount: number,
  message?: string
): FormControlRule<FormControlValue<unknown[]>> {
  return (value) => (
    value === null
    || value.length >= minCount
  ) ? true
    : message
    || `Es müssen mindestens "${minCount}" Elemente enthalten sein`;
}

export function minCountWhenCheckboxIsCheckedRule(
  minCount: number,
  formControl: FormControl<boolean>,
  message?: string
): FormControlRule<FormControlValue<unknown[]>> {
  return (value) => (
    formControl.value !== true
    || (value !== null
      && value.length >= minCount
    )
  ) ? true
    : message
    || `Es müssen mindestens "${minCount}" Elemente enthalten sein`;
}

export function maxCountRule(
  maxCount: number,
  message?: string
): FormControlRule<FormControlValue<unknown[]>> {
  return (value) => (
    value === null
    || value.length <= maxCount
  ) ? true
    : message
    || `Es dürfen maximal "${maxCount}" Elemente enthalten sein`;
}

export function mandatoryCheckboxRule(
  message = 'Diese Option muss ausgewählt sein'
): FormControlRule<FormControlValue<boolean>> {
  return (value) => value === null
    || value === true
    || message;
}

export function forbiddenCheckboxRule(
  message = 'Diese Option darf nicht ausgewählt sein'
): FormControlRule<FormControlValue<boolean>> {
  return (value) => value === null
    || !value
    || message;
}

export function forbiddenCheckboxWhenCheckboxIsCheckedRule(
  formControl: FormControl<boolean>,
  message = 'Diese Option darf nicht ausgewählt sein'
): FormControlRule<FormControlValue<boolean>> {
  return (value) => value !== true
    || (formControl.value !== true)
    || message;
}

export function forbiddenWhenFormControlIsNotFilledRule(
  formControl: FormControl<any>,
  message = `Kann nicht ausgefüllt werden, wenn das Feld ${formControl.label} nicht gefüllt ist`
): FormControlRule<FormControlValue<any>> {
  return (value) => formControl.value !== null
    || value === null
    ? true
    : message;
}
