
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { useRidingLessonManagementStore } from '../store';
import { UpdateNameOfRidingLessonTypeAsManagerCommand, RidingLessonType } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
}

@Component
export default class UpdateNameOfRidingLessonTypeDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  @Prop({ type: Object, required: true })
  readonly ridingLessonType!: RidingLessonType;

  readonly dialogMaxWidth = DialogWidth.small;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: this.ridingLessonType.name,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateNameOfRidingLessonTypeAsManagerCommand = {
      ridingLessonTypeId: this.ridingLessonType.ridingLessonTypeId,
      name: formValues.name!,
    };
    this.store.updateNameOfRidingLessonType(command)
      .then(() => showSuccessMessage('Der Name der Unterrichtsart wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
