import { render, staticRenderFns } from "./farm-task-repetition-form-control.vue?vue&type=template&id=59b991a9&scoped=true&"
import script from "./farm-task-repetition-form-control.vue?vue&type=script&lang=ts&"
export * from "./farm-task-repetition-form-control.vue?vue&type=script&lang=ts&"
import style0 from "./farm-task-repetition-form-control.vue?vue&type=style&index=0&id=59b991a9&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59b991a9",
  null
  
)

export default component.exports