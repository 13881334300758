import { Booking, Settlement, SettlementCategory } from './types';

export function categoriesFromSettlement(settlement: Settlement): SettlementCategory[] {
  const settlementCategories: SettlementCategory[] = [];

  const boxPlanCosts = settlement.boxPlan
    && settlement.boxPlan.bookings.reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0)
    || 0;
  if (boxPlanCosts > 0) {
    settlementCategories.push({
      name: 'Einstellplatz',
      grossTotalCosts: boxPlanCosts,
    });
  }

  const horseWalkerBookingCosts = settlement.horseWalker
    && settlement.horseWalker.bookings.reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0)
    || 0;

  const farmServiceSubscriptionBookingCosts = settlement.farmServiceSubscription
    && settlement.farmServiceSubscription.bookings.reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0)
    || 0;

  const beddingBookingCosts = settlement.bedding
    && settlement.bedding.bookings.reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0)
    || 0;

  const farmServiceBookingCosts = settlement.farmService
    && settlement.farmService.bookings
      .filter((booking) => booking.details.farmServiceBookingType === 'subscription')
      .reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0)
    || 0;

  const subscriptionCosts = horseWalkerBookingCosts + farmServiceSubscriptionBookingCosts + beddingBookingCosts + farmServiceBookingCosts;

  if (subscriptionCosts > 0) {
    settlementCategories.push({
      name: 'Abos',
      grossTotalCosts: subscriptionCosts,
    });
  }

  const oneOffCosts = settlement.farmService
    && settlement.farmService.bookings
      .filter((booking) => booking.details.farmServiceBookingType === 'one-off')
      .reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0)
    || 0;

  if (oneOffCosts > 0) {
    settlementCategories.push({
      name: 'Einmalbuchungen',
      grossTotalCosts: oneOffCosts,
    });
  }

  const customBookingCosts = settlement.customBooking
    && settlement.customBooking.bookings.reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0)
    || 0;
  if (customBookingCosts > 0) {
    settlementCategories.push({
      name: 'Sonderkosten',
      grossTotalCosts: customBookingCosts,
    });
  }

  const creditNoteCosts = settlement.creditNote
    && settlement.creditNote.bookings.reduce((sum: number, b: Booking) => sum + b.grossSellingPrice * -1, 0)
    || 0;
  if (creditNoteCosts > 0) {
    settlementCategories.push({
      name: 'Gutschriften',
      grossTotalCosts: creditNoteCosts,
    });
  }

  const feedingCosts = settlement.feeding
    && settlement.feeding.bookings.reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0)
    || 0;
  if (feedingCosts > 0) {
    settlementCategories.push({
      name: 'Fütterung',
      grossTotalCosts: feedingCosts,
    });
  }

  return settlementCategories;
}
