
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Strain, Time, TimeFrame, Date, HorseId, Link } from '@/types';
import { formatName, momentTimeFrameFromDateAndTimeFrame } from '@/helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useAuthenticationStore } from '@/application/authentication/store';
import { CreateHorseTrainingSessionCommand } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  date: FormControl<Date>;
  timeFrame: FormControl<TimeFrame>;
  rider: FormControl<string>;
  facility: FormControl<string>;
  activity: FormControl<string>;
  strainOnHorse: FormControl<Strain>;
  externalLink: FormControl<Link>;
  notes: FormControl<string>;
}

@Component
export default class CreateHorseTrainingSessionDialog extends Vue {

  readonly store = useMyStableStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: String, required: true })
  readonly horseId!: HorseId;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const initialRider = formatName(this.authenticationStore.user!.name);
    const initialTimeFrame: TimeFrame = {
      timeFrom: Time.fromString('08:00:00'),
      timeTo: Time.fromString('09:00:00'),
    };

    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        date: {
          label: 'Bewegt am',
          value: Date.today(),
          isRequired: true,
        },
        timeFrame: {
          label: 'Zeitraum',
          value: initialTimeFrame,
          isRequired: true,
        },
        rider: {
          label: 'Reiter',
          value: initialRider,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        facility: {
          label: 'Anlage',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        activity: {
          label: 'Aktivität',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        strainOnHorse: {
          label: 'Belastung für das Pferd',
          value: Strain.MINIMAL_STRAIN,
          isRequired: true,
        },
        externalLink: {
          label: 'Link zu weiterführenden Informationen',
          value: null,
        },
        notes: {
          label: 'Notizen',
          value: null,
        },
      },
    });
  }

  isDateAllowed(date: Date): boolean {
    const today = Date.today();
    const sevenDaysAgo = Date.today().subtract(7);

    return this.store.isCurrentHorseOwn
      ? date.isBeforeOrEqualTo(today)
      : date.isBeforeOrEqualTo(today)
        && date.isAfterOrEqualTo(sevenDaysAgo);
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const trainingTimeFrame = momentTimeFrameFromDateAndTimeFrame(formValues.date!, formValues.timeFrame!);

    const command: CreateHorseTrainingSessionCommand = {
      horseId: this.horseId,
      trainingTimeFrame,
      facility: formValues.facility!,
      activity: formValues.activity!,
      rider: formValues.rider!,
      strainOnHorse: formValues.strainOnHorse!,
      externalLink: formValues.externalLink,
      notes: formValues.notes,
    };

    this.store.createHorseTrainingSession(command)
      .then(() => showSuccessMessage('Training wurde eingetragen.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
