
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import CompleteTaskDialog from './complete-task-dialog.vue';
import { useRiderTaskManagementStore } from '../store';
import { OverdueTask } from '../types';

@Component({
  components: {
    CompleteTaskDialog,
  },
})
export default class OverdueTasks extends Vue {

  readonly store = useRiderTaskManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  mounted(): void {
    this.store.getOverdueTasks()
      .catch((error) => showErrorResponse(error));
  }

  isTaskClickable(task: OverdueTask): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE)
      || (
        !!task.assignedTo
        && task.assignedTo.userId === this.authenticationStore.user!.id
      );
  }

  taskClicked(task: OverdueTask): void {
    (this.$refs[`completeTaskDialog-${task.riderTaskId}`] as CompleteTaskDialog).show();
  }

  taskCompleted(): void {
    this.store.getOverdueTasks()
      .catch((error) => showErrorResponse(error));
  }

}
