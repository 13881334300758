
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { MimeType } from '@/types';
import { useFarmManagementStore } from '../store';
import { Farm, UpdateFarmLogoAsAdminCommand } from '../types';

interface Controls extends FormControls {
  logo: FormControl<Blob>;
}

@Component
export default class UpdateFarmLogoDialog extends Vue {

  readonly store = useFarmManagementStore();

  @Prop({ type: Object, required: true })
  readonly farm!: Farm;

  readonly size = DialogWidth.large;

  readonly MimeType = MimeType;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        logo: {
          label: 'Logo',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFarmLogoAsAdminCommand = {
      body: {
        targetFarmId: this.farm.farmId,
      },
      files: {
        logoFile: formValues.logo!,
      },
    };

    this.store.updateFarmLogo(command)
      .then(() => showSuccessMessage('Das Logo wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
