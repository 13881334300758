
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Date } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { CreateHorseWormingTreatmentCommand } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  date: FormControl<Date>;
  drugName: FormControl<string>;
  comment: FormControl<string>;
}

@Component
export default class CreateHorseWormingTreatmentDialog extends Vue {

  readonly store = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        date: {
          label: 'Datum',
          value: Date.today(),
          isRequired: true,
        },
        drugName: {
          label: 'Wurmkur-Medikament',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        comment: {
          label: 'Kommentar',
          value: null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateHorseWormingTreatmentCommand = {
      horseId: this.store.currentHorseId!,
      date: formValues.date!,
      drugName: formValues.drugName!,
      comment: formValues.comment,
    };

    this.store.createHorseWormingTreatment(command)
      .then(() => showSuccessMessage('Die Wurmkur wurde eingetragen.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  isDateAllowed(date: Date): boolean {
    return date.isBeforeOrEqualTo(Date.today());
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
