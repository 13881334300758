
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { useRiderTaskManagementStore } from '../store';
import { NewTask } from '../types';
import AssignTaskDialog from './assign-task-dialog.vue';
import CompleteTaskDialog from './complete-task-dialog.vue';
import RejectTaskDialog from './reject-task-dialog.vue';

@Component({
  components: {
    AssignTaskDialog,
    CompleteTaskDialog,
    RejectTaskDialog,
  }
})
export default class NewTaskDetailsDialog extends Vue {

  readonly store = useRiderTaskManagementStore();

  @Prop({ type: Object, required: true })
  readonly task!: NewTask;

  readonly size = DialogWidth.large;

  isDialogVisible = false;

  taskAssigned(): void {
    this.$emit('task-assigned');
    this.closeDialog();
  }

  taskCompleted(): void {
    this.$emit('task-completed');
    this.closeDialog();
  }

  taskRejected(): void {
    this.$emit('task-rejected');
    this.closeDialog();
  }

  public show(): void {
    this.isDialogVisible = true;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
