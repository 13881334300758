
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { TokenStorage } from '@/application/authentication/token-storage';
import { useAdminNotificationBannerStore } from '../store';
import { AdminNotification, MarkAdminNotificationAsSeenCommand } from '../types';

@Component
export default class AdminNotificationBanner extends Vue {

  readonly store = useAdminNotificationBannerStore();
  readonly authenticationStore = useAuthenticationStore();

  get areAdminNotificationBannersVisible(): boolean {
    return this.authenticationStore.wasInitialAuthenticationAttempted
      && this.areUnseenNotificationsRelevantForUser
      && this.store.adminNotifications.length > 0;
  }

  get areUnseenNotificationsRelevantForUser(): boolean {
    return this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin
      && !TokenStorage.isImpersonatedUser();
  }

  mounted(): void {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState !== 'hidden') {
        if (this.areUnseenNotificationsRelevantForUser) {
          this.updateUnseenAdminNotifications();
        }
      }
    });
  }

  updateUnseenAdminNotifications(): void {
    this.store.getUnseenAdminNotifications()
      .catch((error) => showErrorResponse(error));
  }

  adminNotificationClicked(adminNotification: AdminNotification): void {
    const command: MarkAdminNotificationAsSeenCommand = {
      adminNotificationId: adminNotification.adminNotificationId,
    };
    this.store.markAdminNotificationAsSeen(command)
      .catch((error) => showErrorResponse(error));
  }

}
