
import { Component, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { vaccinationWaitPeriodTranslation } from '@/helpers/translations';
import UpdateVaccinationNoticeDialog from './update-vaccination-notice-dialog.vue';
import { useMyStableStore } from '../store';
import { DeleteVaccinationCommand, IllnessWithStatus, Vaccination } from '../types';

@Component({
  components: {
    UpdateVaccinationNoticeDialog,
  },
})
export default class VaccinationHistory extends Vue {

  readonly store = useMyStableStore();

  readonly vaccinationWaitPeriodTranslation = vaccinationWaitPeriodTranslation;

  get isVaccinationHistoryVisible(): boolean {
    return this.store.vaccinationCertificate !== null;
  }

  get illnessWithStatus(): IllnessWithStatus {
    const illnessId = this.$route.params.illnessId;

    return this.store.vaccinationCertificate!.illnessesWithStatus
      .find((illnessWithStatus) => illnessWithStatus.illness.illnessId === illnessId)!;
  }

  get isUpdateVaccinationNoticeVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

  get isDeleteVaccinationNoticeVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

  vaccine(vaccineId: string): string {
    const vaccine = this.store.vaccinationCertificate!.vaccines.find((vaccine) => vaccine.vaccineId === vaccineId) || null;
    if (vaccine === null) {
      throw new Error('Invalid vaccine');
    }

    return vaccine.name;
  }

  backClicked(): void {
    navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
  }

  async deleteVaccinationAction(vaccination: Vaccination): Promise<void> {
    const command: DeleteVaccinationCommand = {
      vaccinationId: vaccination.vaccinationId,
    };

    return this.store.deleteVaccination(command)
      .then(() => showSuccessMessage('Impfung wurde gelöscht.'))
      .then(() => {
        if (this.illnessWithStatus.history.length === 0) {
          navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
        }
      })
      .catch((error) => showErrorResponse(error));
  }

}
