
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { MimeType, Moment } from '@/types';
import { NewsEntry, UpdateNewsEntryAsManagerCommand } from '../types';
import { useNewsManagementStore } from '../store';

interface Controls extends FormControls {
  date: FormControl<Moment>;
  title: FormControl<string>;
  content: FormControl<string>;
  image: FormControl<Blob>;
  attachment: FormControl<File>;
}

@Component
export default class UpdateNewsEntryDialog extends Vue {

  readonly store = useNewsManagementStore();

  @Prop({ type: Object, required: true })
  readonly newsEntry!: NewsEntry;

  readonly size = DialogWidth.large;

  readonly MimeType = MimeType;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        date: {
          label: 'Datum zur Sortierung der Neuigkeiten',
          value: this.newsEntry.date,
          isRequired: true,
        },
        title: {
          label: 'Was ist die Neuigkeit?',
          value: this.newsEntry.title,
          isRequired: true,
        },
        content: {
          label: 'Beschreibung',
          value: this.newsEntry.content,
          isRequired: true,
        },
        image: {
          label: 'Bild',
          value: null,
        },
        attachment: {
          label: 'Anhang',
          value: null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateNewsEntryAsManagerCommand = {
      body: {
        newsEntryId: this.newsEntry.newsEntryId,
        date: formValues.date!,
        title: formValues.title!,
        content: formValues.content!,
      },
      files: {
        attachment: formValues.attachment,
        image: formValues.image,
      },
    };

    this.store.updateEntry(command)
      .then(() => showSuccessMessage('Die Nachricht wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
