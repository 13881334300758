
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { MimeType } from '@/types';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useFarmDocumentsManagementStore } from '../store';
import { FarmDocument, UpdateDocumentFileAsManagerCommand } from '../types';

interface Controls extends FormControls {
  document: FormControl<File>;
}

@Component
export default class UpdateFarmDocumentFileDialog extends Vue {

  readonly store = useFarmDocumentsManagementStore();

  @Prop({ type: Object, required: true })
  readonly farmDocument!: FarmDocument;

  readonly size = DialogWidth.large;

  readonly MimeType = MimeType;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        document: {
          label: 'Dokument',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateDocumentFileAsManagerCommand = {
      body: {
        farmDocumentId: this.farmDocument.farmDocumentId,
      },
      files: {
        document: formValues.document!,
      },
    };

    this.store.updateDocumentFile(command)
      .then(() => showSuccessMessage('Das Dokument wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
