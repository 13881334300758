
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import PdfDialog from '@/components/design-system/pdf-dialog/pdf-dialog.vue';
import { useFarmDocumentsStore } from '../store';
import { FarmDocument } from '../types';

@Component
export default class FarmDocumentList extends Vue {

  readonly store = useFarmDocumentsStore();

  mounted(): void {
    this.store.getFarmDocuments()
      .catch((error) => showErrorResponse(error));
  }

  documentClicked(farmDocument: FarmDocument): void {
    (this.$refs[`pdfDialog-${farmDocument.farmDocumentId}`] as PdfDialog).show();
  }

}
