import Vue from 'vue';
import IconButtonComponent from '@/components/icon-button.vue';
import IconMenuComponent from '@/components/icon-menu.vue';
import FilterBar from '@/components/filter-bar.vue';
import NumberField from '@/components/number-field.vue';
import NumberInput from '@/components/number-input.vue';
import TimeInput from '@/components/time-input.vue';
import YearMonthPicker from '@/components/year-month-picker.vue';
import TextInput from '@/components/text-input.vue';
import TextareaField from '@/components/textarea-field.vue';
import TextareaInput from '@/components/textarea-input.vue';

Vue.component('icon-button', IconButtonComponent);
Vue.component('icon-menu', IconMenuComponent);
Vue.component('filter-bar', FilterBar);

Vue.component('year-month-picker', YearMonthPicker); // 2
Vue.component('a-time-input', TimeInput); // 3
Vue.component('textarea-input', TextareaInput); // 8
Vue.component('number-input', NumberInput); // 18
Vue.component('text-input', TextInput); // 26

Vue.component('number-field', NumberField); // -- In 2 inputs
Vue.component('textarea-field', TextareaField); // -- Only in input
