
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { VaccinationWaitPeriod, VaccineId, Date } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { CreateVaccinationCommand, Illness, Vaccine } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  vaccine: FormControl<VaccineId>;
  vaccinationDate: FormControl<Date>;
  nextVaccinationAfter: FormControl<VaccinationWaitPeriod>;
  notice: FormControl<string>;
}

@Component
export default class CreateVaccinationDialog extends Vue {

  readonly store = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly illness!: Illness;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get relevantVaccines(): Vaccine[] {
    return this.store.vaccinationCertificate!.vaccines
      .filter((vaccine) => vaccine.illnessId === this.illness.illnessId);
  }

  get isDialogDisabled(): boolean {
    return this.store.vaccinationCertificate === null;
  }

  get isVaccineAlertVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.vaccine === null;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        vaccine: {
          label: 'Impfstoff',
          value: this.relevantVaccines[0].vaccineId,
          isRequired: true,
        },
        vaccinationDate: {
          label: 'Impfung durchgeführt am',
          value: Date.today(),
          isRequired: true,
        },
        nextVaccinationAfter: {
          label: 'Nächste Impfung',
          value: VaccinationWaitPeriod.FOUR_WEEKS,
          isRequired: true,
        },
        notice: {
          label: 'Notiz',
          value: null,
        },
      },
    });
  }

  isVaccinationDateAllowed(date: Date): boolean {
    const today = Date.today();

    return date.isBeforeOrEqualTo(today);
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateVaccinationCommand = {
      horseId: this.store.currentHorseId!,
      illnessId: this.illness.illnessId,
      vaccineId: formValues.vaccine!,
      vaccinationDate: formValues.vaccinationDate!,
      nextVaccinationAfter: formValues.nextVaccinationAfter!,
      notice: formValues.notice,
    };

    this.store.createVaccination(command)
      .then(() => showSuccessMessage('Impfung wurde eingetragen.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
