
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { formatCurrency } from '@/helpers/stateful-format';
import { DialogWidth } from '@/helpers/data';
import { UserId, Month } from '@/types';
import { moment } from '@/helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, maxLengthRule, minCountRule, minNumberRule } from '@/components/form';
import { calculateTaxAmount, convertDecimalToCurrency, parsePercentageWithOneDecimalPlace } from '@/helpers/money-helper';
import { useLedgerStore } from '../store';
import { AddCreditNotesAsManagerWorkflow } from '../types';

interface Controls extends FormControls {
  settlingOnUserIds: FormControl<UserId[]>;
  grossSellingPrice: FormControl<number>;
  taxRate: FormControl<number>;
  settlingAt: FormControl<Month>;
  description: FormControl<string>;
}

@Component
export default class AddCreditNotesDialog extends Vue {

  readonly store = useLedgerStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isPricingAlertVisible(): boolean {
    const formValues = getFormValues(this.form!);

    return formValues.grossSellingPrice !== null
      && formValues.taxRate !== null;
  }

  get pricingAlertText(): string {
    const formValues = getFormValues(this.form!);

    if (formValues.grossSellingPrice === null
      || formValues.taxRate === null
    ) {
      return '';
    }

    const grossSellingPriceInCents = convertDecimalToCurrency(formValues.grossSellingPrice);
    const taxRatePerThousand = parsePercentageWithOneDecimalPlace(formValues.taxRate);

    const taxAmount = calculateTaxAmount(grossSellingPriceInCents, taxRatePerThousand);

    const netSellingPrice = convertDecimalToCurrency(formValues.grossSellingPrice) - taxAmount;

    return `Der Verkaufspreis enthält ${formatCurrency(taxAmount)} Steuern auf den Nettopreis von ${formatCurrency(netSellingPrice)}`;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (!isDialogVisible) {
        this.form = null;
        return;
      }

      this.store.getHorseOwners()
        .then(() => {
          this.form = this.buildForm();
        })
        .catch((error) => showErrorResponse(error));
    });
  }

  buildForm(): Form<Controls> {
    const settlingAtValue = this.store.selectedMonthForCreditNotes
      && moment(this.store.selectedMonthForCreditNotes!, 'YYYY-MM', 'Europe/Berlin').isSameOrAfter(moment(), 'month')
      ? this.store.selectedMonthForCreditNotes
      : moment().format('YYYY-MM') as Month;

    return constructForm<Controls>({
      submitted: this.formSubmitted,
      controls: {
        settlingOnUserIds: {
          label: 'Einsteller',
          value: null,
          isRequired: true,
          rules: [
            minCountRule(1, 'Es muss mindestens ein Benutzer ausgewählt werden.'),
          ],
        },
        grossSellingPrice: {
          label: 'Verkaufspreis (Brutto)',
          value: null,
          isRequired: true,
          rules: [
            minNumberRule(0.01),
            maxDecimalsNumberRule(2),
          ],
        },
        taxRate: {
          label: 'Steuersatz in Prozent',
          value: null,
          isRequired: true,
          rules: [
            minNumberRule(0),
            maxDecimalsNumberRule(1),
          ],
        },
        settlingAt: {
          label: 'Buchungsmonat',
          value: settlingAtValue,
          isRequired: true,
        },
        description: {
          label: 'Beschreibung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  isMonthAllowed(month: Month): boolean {
    const startOfThisMonth = moment().startOf('month');

    return moment(month).isSameOrAfter(startOfThisMonth);
  }

  formSubmitted(): void {
    const formValues = getFormValues(this.form!);

    const grossSellingPriceInCents = convertDecimalToCurrency(formValues.grossSellingPrice!);
    const taxRatePerThousand = parsePercentageWithOneDecimalPlace(formValues.taxRate!);

    const taxAmount = calculateTaxAmount(grossSellingPriceInCents, taxRatePerThousand);

    const netSellingPrice = grossSellingPriceInCents - taxAmount;

    const command: AddCreditNotesAsManagerWorkflow = {
      settlingOnUserIds: formValues.settlingOnUserIds!,
      description: formValues.description!,
      grossSellingPrice: grossSellingPriceInCents,
      netSellingPrice: netSellingPrice,
      taxRate: taxRatePerThousand,
      taxAmount: taxAmount,
      settlingAt: formValues.settlingAt!,
    };

    this.store.addCreditNotes(command)
      .then(() => showSuccessMessage('Die Gutschriften wurden erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
