
import { Component, Vue } from 'vue-property-decorator';
import { useFarmTaskManagementStore } from '@/private/management/farm-tasks/store';
import { showErrorResponse } from '@/application/snackbar/service';

@Component
export default class FarmTaskTabs extends Vue {

  readonly store = useFarmTaskManagementStore();

  mounted(): void {
    this.store.getFarmManagersForAssignment()
      .catch((error) => showErrorResponse(error));

      this.store.getHorses()
      .catch((error) => showErrorResponse(error));
  }

}
