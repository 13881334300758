import { render, staticRenderFns } from "./strain-form-control.vue?vue&type=template&id=08e8d95e&scoped=true&"
import script from "./strain-form-control.vue?vue&type=script&lang=ts&"
export * from "./strain-form-control.vue?vue&type=script&lang=ts&"
import style0 from "./strain-form-control.vue?vue&type=style&index=0&id=08e8d95e&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e8d95e",
  null
  
)

export default component.exports