
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { Date, EventId, Persona, FarmManagerPermission } from '@/types';
import { useEventManagementStore } from '../store';
import { DeleteEventAsManagerCommand, DeleteEventAttachmentAsManagerCommand, DeleteEventImageAsManagerCommand, DeleteEventRegistrationAsManagerCommand, DuplicateEventAsManagerCommand, EventEntry, EventRegistration, MarkEventAsHiddenAsManagerCommand } from '../types';
import CreateEventDialog from './create-event-dialog.vue';
import UpdateEventDialog from './update-event-dialog.vue';
import CopyExternalEventLinkDialog from './copy-external-event-link-dialog.vue';
import MarkEventAsVisibleDialog from './mark-event-as-visible-dialog.vue';
import { uuid } from '@/helpers';

@Component({
  components: {
    CreateEventDialog,
    UpdateEventDialog,
    CopyExternalEventLinkDialog,
    MarkEventAsVisibleDialog,
  },
})
export default class EventsAdminComponent extends Vue {

  readonly store = useEventManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Datum', value: 'date' },
    { text: 'Titel', value: 'title' },
    { text: 'Persona', value: 'visibleForPersonas' },
    { text: 'Gruppen', value: 'visibleForGroups', width: 110 },
    { text: 'Anmeldungen', value: 'registrations' },
    { text: 'Status', value: 'status' },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 288 },
  ];

  readonly registrationTableHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name' },
    { text: 'Pferd', value: 'horse' },
    { text: 'Kommentar', value: 'comment' },
    { text: 'Angemeldet am', value: 'registeredAt', width: 220 },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 50 },
  ];

  readonly eventMenuList: Record<EventId, boolean> = {};

  expanded: EventEntry[] = [];

  get isCreateEventVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  get isUpdateEventVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  get isDeleteEventVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  get isCloneEventVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  get isDeleteEventRegistrationVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  get isDeleteEventAttachmentVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  get isDeleteEventImageVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  mounted(): void {
    this.store.getEvents()
      .catch((error) => showErrorResponse(error));
  }

  getVisibleForPersona(event: EventEntry): string {
    if (!event.visibilityRestrictedForPersona) {
      return 'Alle';
    }

    if (event.visibilityRestrictedForPersona === Persona.WITH_HORSE) {
      return 'Einsteller';
    }

    if (event.visibilityRestrictedForPersona === Persona.RIDING_PARTICIPATION) {
      return 'Reitbeteiligung';
    }

    return '';
  }

  getVisibleForGroups(event: EventEntry): string {
    if (!event.namesOfGroupsForWhichTheEventIsVisible) {
      return 'Alle';
    }

    return event.namesOfGroupsForWhichTheEventIsVisible.join(', ');
  }

  async duplicateEventAction(eventEntry: EventEntry): Promise<void> {
    const newEventId = uuid();

    const command: DuplicateEventAsManagerCommand = {
      eventId: eventEntry.eventId,
      newEventId,
    };

    return this.store.duplicateEvent(command)
      .then(() => showSuccessMessage('Die Veranstaltung wurde dupliziert.'))
      .then(() => {
        this.$nextTick(() => {
          (this.$refs[`update-event-dialog-${newEventId}`] as UpdateEventDialog).show();
        });
      })
      .catch((error) => showErrorResponse(error));
  }

  async deleteEventEntryAction(eventEntry: EventEntry): Promise<void> {
    const command: DeleteEventAsManagerCommand = {
      eventId: eventEntry.eventId,
    };

    return this.store.deleteEvent(command)
      .then(() => showSuccessMessage('Die Veranstaltung wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  async deleteRegistrationAction(registration: EventRegistration): Promise<void> {
    const command: DeleteEventRegistrationAsManagerCommand = {
      eventRegistrationId: registration.eventRegistrationId,
    };

    return this.store.deleteEventRegistration(command)
      .then(() => showSuccessMessage('Die Registrierung wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  isDeleteAlertShown(eventEntry: EventEntry): boolean {
    return eventEntry.date.isAfterOrEqualTo(Date.today()) || false;
  }

  async markEventAsHiddenAction(eventEntry: EventEntry): Promise<void> {
    const command: MarkEventAsHiddenAsManagerCommand = {
      eventId: eventEntry.eventId,
    };

    return this.store.markEventAsHidden(command)
      .then(() => showSuccessMessage('Die Veranstaltung wurde versteckt.'))
      .catch((error) => showErrorResponse(error));
  }

  isUpdateEventDisabled(eventEntry: EventEntry): boolean {
    return eventEntry.date.isBefore(Date.today());
  }

  isMarkEventAsVisibleVisible(eventEntry: EventEntry): boolean {
    return eventEntry.isHidden
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  isMarkEventAsHiddenVisible(eventEntry: EventEntry): boolean {
    return !eventEntry.isHidden
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  isShowExpandedVisible(eventEntry: EventEntry): boolean {
    return !this.expanded.some((currentEventEntry) => currentEventEntry.eventId === eventEntry.eventId);
  }

  isHideExpandedVisible(eventEntry: EventEntry): boolean {
    return this.expanded.some((currentEventEntry) => currentEventEntry.eventId === eventEntry.eventId);
  }

  toggleExpand(eventEntry: EventEntry): void {
    if (this.expanded.some((currentEventEntry) => currentEventEntry.eventId === eventEntry.eventId)) {
      this.expanded = this.expanded.filter((entry) => entry.eventId !== eventEntry.eventId);
    } else {
      this.expanded.push(eventEntry);
    }
  }

  isEventAttachmentDeleteDisabled(event: EventEntry): boolean {
    return event.attachmentUrl === null;
  }

  async deleteAttachmentAction(event: EventEntry): Promise<void> {
    const command: DeleteEventAttachmentAsManagerCommand = {
      eventId: event.eventId,
    };

    return this.store.deleteEventAttachment(command)
      .then(() => showSuccessMessage('Anhang gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  isEventImageDeleteDisabled(event: EventEntry): boolean {
    return !event.image;
  }

  async deleteImageAction(event: EventEntry): Promise<void> {
    const command: DeleteEventImageAsManagerCommand = {
      eventId: event.eventId,
    };

    return this.store.deleteEventImage(command)
      .then(() => showSuccessMessage('Bild gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
