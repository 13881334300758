
import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifyValidatable } from '@/application/types';
import { DialogWidth } from '@/helpers/data';
import { UpdateHorseNameAsManagerWorkflow, Horse } from '../types';
import { useHorseManagementStore } from '../store';

interface Form extends StrictFormDefinition {
  fields: {
    name: FormField<string|null>;
    nickName: FormField<string|null>;
  };
}

@Component
export default class UpdateHorseNameDialog extends Vue {

  readonly store = useHorseManagementStore();

  @Prop({ type: Object, required: true })
  readonly horse!: Horse;

  @Ref()
  readonly formElement!: VuetifyValidatable;

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        name: {
          value: this.horse.name,
          rules: [
            requiredRule(),
          ],
        },
        nickName: {
          value: this.horse.nickName,
          rules: [],
        },
      },
    };
  }

  onSubmit(): void {
    /**
     * We have events where the horse name and potentially also the nickName has trailing whitespaces.
     * Therefore, we trim them here until the events are adjusted (Ticket #862).
     */
    const command: UpdateHorseNameAsManagerWorkflow = {
      horseId: this.horse.horseId,
      name: this.form!.fields.name.value!,
      nickName: this.form!.fields.nickName.value,
    };

    this.store.updateHorseName(command)
      .then(() => showSuccessMessage('Der Name des Pferdes wurde aktualisiert'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
