
import { Component, Vue } from 'vue-property-decorator';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { useBoxPlanManagementStore } from '../store';
import { DeleteBoxDTO, DeleteBoxRowDTO, DeleteStableDTO, UpdateBoxPlanAsManagerWorkflow } from '../types';
import CreateBoxRowDialog from './create-box-row-dialog.vue';
import UpdateBoxRowDetailsDialog from './update-box-row-details-dialog.vue';
import CreateStableDialog from './create-stable-dialog.vue';
import UpdateStableDetailsDialog from './update-stable-details-dialog.vue';
import UpdateBoxNumberDialog from './update-box-number-dialog.vue';
import CreateBoxDialog from './create-box-dialog.vue';

@Component({
  components: {
    CreateStableDialog,
    UpdateStableDetailsDialog,
    CreateBoxRowDialog,
    UpdateBoxRowDetailsDialog,
    CreateBoxDialog,
    UpdateBoxNumberDialog,
  },
})
export default class ConfigureBoxPlan extends Vue {

  readonly store = useBoxPlanManagementStore();

  isNativeApplication = isNativeApplication;

  get isTaxRateWarningVisible(): boolean {
    return this.store.stables
      .some((stable) => stable.boxRows
        .some((boxRow) => boxRow.taxRate === null));
  }

  get isSubmitDisabled(): boolean {
    return this.store.stables
      .some((stable) => stable.boxRows
        .some((boxRow) => boxRow.taxRate === null));
  }

  get isCreateStableOnTopVisible(): boolean {
    return this.store.stables.length === 0
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isCreateStableOnBottomVisible(): boolean {
    return this.store.stables.length > 0
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isUpdateBoxPlanVisible(): boolean {
    return this.store.stables.length > 0
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isUpdateStableDetailsVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isDeleteStableVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isCreateBoxRowVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isUpdateBoxRowDetailsVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isDeleteBoxRowVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isCreateBoxVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isUpdateBoxNumberVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  get isDeleteBoxVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_WRITE);
  }

  deleteBoxRowConfirmed(stableIndex: number, boxRowIndex: number) {
    const dto: DeleteBoxRowDTO = {
      stableIndex,
      boxRowIndex,
    };
    this.store.deleteBoxRow(dto)
      .catch((error) => showErrorResponse(error));
  }

  deleteBoxConfirmed(stableIndex: number, boxRowIndex: number, boxIndex: number) {
    const dto: DeleteBoxDTO = {
      stableIndex,
      boxRowIndex,
      boxIndex,
    };
    this.store.deleteBox(dto)
      .catch((error) => showErrorResponse(error));
  }

  updateBoxPlanClicked(): void {
    const command: UpdateBoxPlanAsManagerWorkflow = {
      stables: this.store.stables,
    };
    this.store.updateBoxPlan(command)
      .then(() => showSuccessMessage('Der Boxenplan wurde angepasst.'))
      .catch((error) => showErrorResponse(error));
  }

  deleteStableConfirmed(stableIndex: number): void {
    const dto: DeleteStableDTO = {
      stableIndex,
    };
    this.store.deleteStable(dto)
      .catch((error) => showErrorResponse(error));
  }

}
