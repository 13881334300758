
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField } from '@/application/types';
import { MONTH_FORMAT } from '@/helpers/data';
import { Moment, Month } from '@/types';
import { moment } from '@/helpers';

@Component
export default class YearMonthPicker extends Vue {

  @Prop({ type: Object, required: true })
  readonly formField!: FormField<Month|null>;

  @Prop({ type: String, required: true })
  readonly label!: string;

  @Prop({ type: Function, required: false, default: null })
  readonly isDateAllowed!: ((date: Moment) => boolean) | null;

  isMenuVisible = false;

  get formattedTextFieldValue(): string {
    if (this.formField.value !== null) {
      return moment(this.formField.value).format(MONTH_FORMAT);
    }

    return '';
  }

  mounted(): void {
    watch(() => this.formField.value, () => {
      this.$emit('change', this.formField.value);

      this.isMenuVisible = false;
    });
  }

  allowedDates(date: string): boolean {
    return this.isDateAllowed !== null
      ? this.isDateAllowed(moment(date))
      : true;
  }

  clearButtonClicked(): void {
    this.formField.value = null;
  }

}
