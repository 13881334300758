
import { Component, Vue } from 'vue-property-decorator';
import CancelFacilityReservationDialog from '@/private/rider/booking-calendars/components/cancel-facility-reservation-dialog.vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { useDashboardStore } from '../store';
import FacilityReservationCard from './facility-reservation-card.vue';
import RidingLessonCard from './riding-lesson-card.vue';

@Component({
  components: {
    CancelFacilityReservationDialog,
    FacilityReservationCard,
    RidingLessonCard,
  },
})
export default class NextAppointments extends Vue {

  readonly store = useDashboardStore();

  get isNoFacilityReservationsVisible(): boolean {
    return this.store.nextAppointments !== null
      && this.store.nextAppointments.length === 0;
  }

  mounted(): void {
    this.store.getNextAppointments()
      .catch((error) => showErrorResponse(error));
  }

}
