
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { RiderTaskId, FarmManagerPermission } from '@/types';
import AssignTaskDialog from './assign-task-dialog.vue';
import CompleteTaskDialog from './complete-task-dialog.vue';
import RejectTaskDialog from './reject-task-dialog.vue';
import NewTaskDetailsDialog from './new-task-details-dialog.vue';
import { useRiderTaskManagementStore } from '../store';
import { NewTask } from '../types';

@Component({
  components: {
    AssignTaskDialog,
    CompleteTaskDialog,
    RejectTaskDialog,
    NewTaskDetailsDialog,
  },
})
export default class NewTasks extends Vue {

  readonly store = useRiderTaskManagementStore();

  readonly taskMenuList: Record<RiderTaskId, boolean> = {};

  get isUpdateMenuVisible(): boolean {
    return this.isAssignTaskVisible
    || this.isCompleteTaskVisible
    || this.isRejectTaskVisible;
  }

  get isAssignTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE);
  }

  get isCompleteTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE);
  }

  get isRejectTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE);
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.store.newTasksTotalCount === null
      || this.store.newTasks.length >= this.store.newTasksTotalCount;
  }

  get noDataText(): string {
    return this.store.newTasksFilteredByUser
      ? 'Für diesen Benutzer wurden alle Aufgaben bearbeitet.'
      : 'Alle Aufgaben wurden bearbeitet.';
  }

  mounted(): void {
    this.store.getNewTasks()
      .catch((error) => showErrorResponse(error));

    this.store.getUsersWithTasks()
      .catch((error) => showErrorResponse(error));
  }

  onLoadMoreClicked(): void {
    this.store.increaseNewTasksLimit()
      .catch((error) => showErrorResponse(error));
  }

  taskClicked(task: NewTask): void {
    (this.$refs[`newTasksDetailsDialog-${task.riderTaskId}`] as NewTaskDetailsDialog).show();
  }

  taskAssigned(): void {
    this.$emit('task-assigned');
    this.store.getNewTasks()
      .catch((error) => showErrorResponse(error));
  }

  taskCompleted(): void {
    this.$emit('task-completed');
    this.store.getNewTasks()
      .catch((error) => showErrorResponse(error));
  }

  taskRejected(): void {
    this.$emit('task-rejected');
    // The new tasks are already loaded through the store
  }

}
