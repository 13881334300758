
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FarmManagerPermission, FeedProtocolMonitoringPausedUntil } from '@/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { useFeedProtocolManagementStore } from '../store';
import { LatestSignalForHorse, PauseMonitoringForHorseAsManagerCommand, UnpauseMonitoringForHorseAsManagerCommand, SignalStatus } from '../types';
import UpdateEquineNumberDialog from './update-equine-number-dialog.vue';

@Component({
  components: {
    UpdateEquineNumberDialog,
  },
})
export default class FeedProtocolOverview extends Vue {

  readonly store = useFeedProtocolManagementStore();

  readonly tableHeadersForLatestSignals: DataTableHeader[] = [
    { text: 'Pferd', value: 'horse' },
    { text: 'Letztes Mal in Station', value: 'horseLeftStationLastestAt' },
    { text: 'Status', value: 'signalStatus' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  readonly tableHeadersForHorsesWithoutSignals: DataTableHeader[] = [
    { text: 'Pferd', value: 'horse' },
    { text: 'Lebensnummer', value: 'equineNumber' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  readonly tableHeadersForHorsesWithoutEquineNumber: DataTableHeader[] = [
    { text: 'Pferd', value: 'horse' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  get isUpdateEquineNumberVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_WRITE);
  }

  mounted(): void {
    this.store.getFeedProtocolOverview()
      .catch((error) => showErrorResponse(error));
  }

  signalStatusClass(signalStatus: SignalStatus): string {
    switch (signalStatus) {
      case SignalStatus.OK:
        return 'ok';
      case SignalStatus.OVERDUE:
        return 'overdue';
      case SignalStatus.MONITORING_PAUSED:
      case SignalStatus.MONITORING_PAUSED_UNTIL_NEXT_FEEDING:
        return 'monitoring-paused';
      default:
        throw new Error('Invalid signal status');
    }
  }

  signalStatusMessage(signalStatus: SignalStatus): string {
    switch (signalStatus) {
      case SignalStatus.OK:
        return 'Alles in Ordnung.';
      case SignalStatus.OVERDUE:
        return 'Seit mehr als 6 Stunden keine Meldung mehr eingegangen!';
      case SignalStatus.MONITORING_PAUSED:
        return 'Überwachung pausiert.';
      case SignalStatus.MONITORING_PAUSED_UNTIL_NEXT_FEEDING:
        return 'Überwachung bis zur nächsten Fütterung pausiert.';
      default:
        throw new Error('Invalid signal status');
    }
  }

  isPauseMonitoringForHorseVisible(latestSignalForHorse: LatestSignalForHorse): boolean {
    return latestSignalForHorse.signalStatus !== SignalStatus.MONITORING_PAUSED
      && latestSignalForHorse.signalStatus !== SignalStatus.MONITORING_PAUSED_UNTIL_NEXT_FEEDING
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_WRITE);
  }

  isUnpauseMonitoringForHorseVisible(latestSignalForHorse: LatestSignalForHorse): boolean {
    return (
      latestSignalForHorse.signalStatus === SignalStatus.MONITORING_PAUSED
        || latestSignalForHorse.signalStatus === SignalStatus.MONITORING_PAUSED_UNTIL_NEXT_FEEDING
    ) && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_WRITE);
  }

  pauseMonitoringForHorseAction(latestSignalForHorse: LatestSignalForHorse): Promise<void> {
    const command: PauseMonitoringForHorseAsManagerCommand = {
      horseId: latestSignalForHorse.horseId,
      pausedUntil: FeedProtocolMonitoringPausedUntil.UNPAUSED,
    };

    return this.store.pauseMonitoringForHorse(command)
      .then(() => showSuccessMessage('Die Überwachung wurde pausiert.'))
      .catch((error) => showErrorResponse(error));
  }

  pauseMonitoringUntilNextFeedingForHorseAction(latestSignalForHorse: LatestSignalForHorse): Promise<void> {
    const command: PauseMonitoringForHorseAsManagerCommand = {
      horseId: latestSignalForHorse.horseId,
      pausedUntil: FeedProtocolMonitoringPausedUntil.NEXT_FEEDING,
    };

    return this.store.pauseMonitoringForHorse(command)
      .then(() => showSuccessMessage('Die Überwachung wurde bis zur nächsten Fütterung pausiert.'))
      .catch((error) => showErrorResponse(error));
  }

  unpauseMonitoringForHorse(latestSignalForHorse: LatestSignalForHorse): void {
    const command: UnpauseMonitoringForHorseAsManagerCommand = {
      horseId: latestSignalForHorse.horseId,
    };

    this.store.unpauseMonitoringForHorse(command)
      .then(() => showSuccessMessage('Die Überwachung wurde aktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

}
