
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GerblhofConcentratedFeedingUpdate, GerblhofConcentratedFeedType, GerblhofFeedingUpdate } from '../types';

@Component
export default class ActiveFeedingInformation extends Vue {

  @Prop({ type: Object, default: null })
  readonly activeFeeding!: GerblhofFeedingUpdate | null;

  @Prop({ type: Object, default: null })
  readonly activeConcentratedFeedingUpdate!: GerblhofConcentratedFeedingUpdate | null;

  @Prop({ type: Array, default: [] })
  readonly concentratedFeedTypes!: GerblhofConcentratedFeedType[];

  getConcentratedFeedingTypeById(id: string): string {
    const concentratedFeedType = this.concentratedFeedTypes
      .find((type) => type.concentratedFeedId === id);
    return concentratedFeedType && concentratedFeedType.name || '';
  }

}
