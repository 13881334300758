import { Location } from 'vue-router';
import { ActionPerformed, PushNotifications, RegistrationError, Token } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import * as Sentry from '@sentry/vue';
import { AcceptPushNotificationsForDevice, RejectPushNotificationsForDeviceCommand } from '@/application/authentication/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { navigate } from '@/helpers/navigation-helpers';
import { MarkNotificationAsSeenCommand } from '@/private/rider/dashboard/types';
import { useDashboardStore } from '@/private/rider/dashboard/store';
import { hasAuthenticationInLocalStorage } from '@/helpers/local-storage-helper';
import { moment } from './moment';
import { isEventCanceledPushNotification, isEventPublishedPushNotification, isFacilityReservationCanceledDueToDisabledActivityPushNotification, isFacilityReservationCanceledDueToFacilityBlockerPushNotification, isFacilityReservationCanceledDueToReducedOpeningHoursPushNotification, isFacilityReservationCanceledDueToRidingLessonPushNotification, isFeedProtocolMissingSignalPushNotification, isFreeJumpingFreeRidingEventCanceledPushNotification, isImpersonationConfirmedPushNotification, isImpersonationRequestedPushNotification, isInvitedAsRideSharePushNotification, isLaborServiceEntryApprovedPushNotification, isLaborServiceEntryCreatedPushNotification, isLaborServiceEntryRejectedPushNotification, isNewsEntryPublishedPushNotification, isRidingLessonRescheduledPushNotification, isRidingLessonTypeUpdatedPushNotification, isSurveyAnswerDeletedPushNotification, isSurveyCreatedPushNotification, isTaskAssignedPushNotification, isTaskAssignedToYouPushNotification, isTaskAssignedWithExecutionDateUpdatePushNotification, isTaskCompletedPushNotification, isTaskCreatedAssignedPushNotification, isTaskCreatedPushNotification, isTaskRejectedPushNotification, isTaskWithdrawnPushNotification, isUserDeletedOwnAccountPushNotification, isVaccinationExpiresSoonPushNotification, PushNotification } from './types';

async function pushNotificationPermissionIsGranted(): Promise<void> {
  const deviceId = await Device.getId();
  // @ts-ignore Fallback for capacitor 4, can be removed after 01.10.2023
  const deviceIdentifier = deviceId.identifier ?? deviceId.uuid;

  const user = useAuthenticationStore().user;
  // This might happen when a user is logged out automatically.
  if (user === null) {
    return;
  }

  /**
   * We register the push notifications and through that updated the push notification configuration when
   * - there is none of the device yet.
   * - the previous configuration was rejected.
   * - the previous configuration was updated longer then 2 weeks ago.
   */
  const twoWeeksAgo = moment().subtract(2, 'weeks');
  const pushNotificationConfigurationForDevice = user.pushNotificationConfigurations
    .find((pushNotificationConfiguration) => pushNotificationConfiguration.deviceIdentifier === deviceIdentifier) || null;
  if (pushNotificationConfigurationForDevice === null
    || !pushNotificationConfigurationForDevice.isAccepted
    || pushNotificationConfigurationForDevice.updatedAt.isBefore(twoWeeksAgo)
  ) {
    await PushNotifications.register();
  }
}

async function pushNotificationPermissionIsRejected(): Promise<void> {
  const deviceId = await Device.getId();
  const deviceInfo = await Device.getInfo();

  // @ts-ignore Fallback for capacitor 4, can be removed after 01.10.2023
  const deviceIdentifier = deviceId.identifier ?? deviceId.uuid;

  const authenticationStore = useAuthenticationStore();
  const user = authenticationStore.user;
  // This might happen when a user is logged out automatically.
  if (user === null) {
    return;
  }

  /**
   * We updated the push notification configuration when
   * - there is none of the device yet.
   * - the previous configuration was accepted.
   * - the previous configuration was updated longer then 2 weeks ago.
   */
  const twoWeeksAgo = moment().subtract(2, 'weeks');
  const pushNotificationConfigurationForDevice = user.pushNotificationConfigurations
    .find((pushNotificationConfiguration) => pushNotificationConfiguration.deviceIdentifier === deviceIdentifier) || null;

  if (pushNotificationConfigurationForDevice === null
    || pushNotificationConfigurationForDevice.isAccepted
    || pushNotificationConfigurationForDevice.updatedAt.isBefore(twoWeeksAgo)
  ) {
    const command: RejectPushNotificationsForDeviceCommand = {
      userId: user.id,
      farmId: user.farm!.id,
      // @ts-ignore Fallback for capacitor 4, can be removed after 01.10.2023
      deviceIdentifier: deviceId.identifier ?? deviceId.uuid,
      operatingSystem: deviceInfo.operatingSystem,
    };

    await authenticationStore.rejectPushNotificationsForDevice(command)
      .catch((error) => showErrorResponse(error));
  }
}

async function registrationSuccess(token: Token): Promise<void> {
  const deviceId = await Device.getId();
  const deviceInfo = await Device.getInfo();
  const authenticationStore = useAuthenticationStore();
  const user = authenticationStore.user;
  // This might happen when a user is logged out automatically.
  if (user === null) {
    return;
  }

  const command: AcceptPushNotificationsForDevice = {
    userId: user.id,
    farmId: user.farm!.id,
    pushNotificationToken: token.value,
    // @ts-ignore Fallback for capacitor 4, can be removed after 01.10.2023
    deviceIdentifier: deviceId.identifier ?? deviceId.uuid,
    operatingSystem: deviceInfo.operatingSystem,
  };

  await authenticationStore.acceptPushNotificationsForDevice(command)
    .catch((error) => showErrorResponse(error));
}

function registrationError(error: RegistrationError): void {
  // Only devices with Google Play can use the push notifications.
  if (error?.error === 'MISSING_INSTANCEID_SERVICE') {
    return;
  }

  Sentry.captureEvent({
    level: 'error',
    message: 'Push notifications not registered',
    extra: {
      error,
    },
  });
}

async function pushNotificationReceived(): Promise<void> {
  if (document.visibilityState !== 'hidden') {
    await useAuthenticationStore().getAuthentication()
      .catch((error) => showErrorResponse(error));
  }
}

async function pushNotificationActionPerformed(notificationAction: ActionPerformed): Promise<void> {
  const notification = notificationAction.notification.data;

  if (notification.data?.notificationId) {
    if (hasAuthenticationInLocalStorage()) {
      const dashboardStore = useDashboardStore();
      const command: MarkNotificationAsSeenCommand = {
        notificationId: notification.data.notificationId,
      };
      dashboardStore.markNotificationAsSeen(command)
        .then(() => dashboardStore.getNotificationStatus())
        .catch((error) => showErrorResponse(error));
    }

    const redirectedRoute = locationForNotification(notification);
    if (redirectedRoute !== null) {
      navigate(redirectedRoute);
    }
  }
}

function locationForNotification(notification: PushNotification): Location | null {
  if (isNewsEntryPublishedPushNotification(notification)) {
    return { name: 'news-details', params: { newsEntryId: notification.newsId } };
  }
  if (isEventPublishedPushNotification(notification)) {
    return { name: 'event-details', params: { eventId: notification.eventId } };
  }
  if (isEventCanceledPushNotification(notification)) {
    return { name: 'event-list' };
  }
  if (isTaskCreatedPushNotification(notification)) {
    return { name: 'rider-task-management-new-tasks' };
  }
  if (isTaskAssignedPushNotification(notification)) {
    return { name: 'task-list' };
  }
  if (isTaskAssignedWithExecutionDateUpdatePushNotification(notification)) {
    return { name: 'task-list' };
  }
  if (isTaskAssignedToYouPushNotification(notification)) {
    return { name: 'control-panel' };
  }
  if (isTaskRejectedPushNotification(notification)) {
    return { name: 'task-list' };
  }
  if (isTaskCompletedPushNotification(notification)) {
    return { name: 'task-list' };
  }
  if (isTaskWithdrawnPushNotification(notification)) {
    return { name: 'rider-task-management-withdrawn-tasks' };
  }
  if (isTaskCreatedAssignedPushNotification(notification)) {
    return { name: 'control-panel' };
  }
  if (isImpersonationRequestedPushNotification(notification)) {
    return { name: 'allow-impersonation', params: { token: notification.token } };
  }
  if (isImpersonationConfirmedPushNotification(notification)) {
    return { name: 'user-management/manage-users/user-table' };
  }
  if (isVaccinationExpiresSoonPushNotification(notification)) {
    return { name: 'horse-vaccination-certificate', params: { id: notification.horseId } };
  }
  if (isFacilityReservationCanceledDueToFacilityBlockerPushNotification(notification)) {
    return { name: 'booking-calendars/facility-calendar', params: { id: notification.facilityId } };
  }
  if (isFacilityReservationCanceledDueToReducedOpeningHoursPushNotification(notification)) {
    return { name: 'booking-calendars/facility-calendar', params: { id: notification.facilityId } };
  }
  if (isFacilityReservationCanceledDueToDisabledActivityPushNotification(notification)) {
    return { name: 'booking-calendars/facility-calendar', params: { id: notification.facilityId } };
  }
  if (isFacilityReservationCanceledDueToRidingLessonPushNotification(notification)) {
    return { name: 'booking-calendars/facility-calendar', params: { id: notification.facilityId } };
  }
  if (isFreeJumpingFreeRidingEventCanceledPushNotification(notification)) {
    return { name: 'booking-calendars/free-jumping-free-running' };
  }
  if (isRidingLessonRescheduledPushNotification(notification)) {
    return { name: 'booking-calendars/facility-calendar', params: { id: notification.facilityId } };
  }
  if (isRidingLessonTypeUpdatedPushNotification(notification)) {
    return { name: 'booking-calendars/facility-calendar', params: { id: notification.facilityId } };
  }
  if (isInvitedAsRideSharePushNotification(notification)) {
    return { name: 'my-stable/horses' };
  }
  if (isLaborServiceEntryCreatedPushNotification(notification)) {
    return { name: 'labor-services-management-unhandled-time-entries' };
  }
  if (isLaborServiceEntryApprovedPushNotification(notification)) {
    return { name: 'labor-service' };
  }
  if (isLaborServiceEntryRejectedPushNotification(notification)) {
    return { name: 'labor-service' };
  }
  if (isUserDeletedOwnAccountPushNotification(notification)) {
    return { name: 'user-management/manage-users/user-table' };
  }
  if (isFeedProtocolMissingSignalPushNotification(notification)) {
    return { name: 'feed-protocol-overview' };
  }
  if (isSurveyCreatedPushNotification(notification)) {
    return { name: 'survey-list', params: { surveyId: notification.surveyId } };
  }
  if (isSurveyAnswerDeletedPushNotification(notification)) {
    return { name: 'survey-list', params: { surveyId: notification.surveyId } };
  }

  return null;
}

/**
 * Requests permission for push notification on the current device.
 * In the first time on iOS devices you it asks you for permission.
 * For Android devices the default is push notifications granted.
 * But as you can also change the permission within your device settings,
 * we need to ensure that the permission hasn't changed.
 */
export function requestPermissionForPushNotifications(): void {
  PushNotifications.requestPermissions()
    .then(async (permissionStatus) => {
      if (permissionStatus.receive === 'granted') {
        await pushNotificationPermissionIsGranted();
      } else {
        await pushNotificationPermissionIsRejected();
      }
    })
    .catch((error) => Sentry.captureException(error));
}

export function listenToPushNotificationRegistration() {
  PushNotifications.addListener('registration', registrationSuccess);
  PushNotifications.addListener('registrationError', registrationError);
}

export function listenToPushNotificationAction() {
  PushNotifications.addListener('pushNotificationReceived', pushNotificationReceived);
  PushNotifications.addListener('pushNotificationActionPerformed', pushNotificationActionPerformed);
}

export function removeAllPushNotificationListeners() {
  PushNotifications.removeAllListeners()
    .catch((error) => Sentry.captureException(error));
}
