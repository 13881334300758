import { Command, CommandWithFiles, Query } from '@/application/types';
import { Country, Currency, Feature, Locale, Name, RegistrationCode, SortDirection } from '@/types';

export interface FarmResponse {
  totalCount: number;
  farms: Farm[];
}

export interface Farm {
  farmId: string;
  name: string;
  alias: string;
  currency: Currency;
  locale: Locale;
  defaultCountryForAddresses: Country;
  enabledFeatures: Feature[];
  logoHash: string;
}

// -- Queries

export interface GetFarmsAsAdminQuery extends Query {
  search: string | null;
  features: Feature[] | null;
  limit: number;
  sortBy: GetFarmsQuerySortBy;
  sortDirection: SortDirection;
}

export enum GetFarmsQuerySortBy {
  NAME = 'NAME',
  NUMBER_OF_USERS = 'NUMBER_OF_USERS',
}

// -- Commands

export interface CreateFarmAsAdminWorkflow extends Command {
  name: string;
  registrationCode: RegistrationCode;
  currency: Currency;
  locale: Locale;
  defaultCountryForAddresses: Country;
  enabledFeatures: Feature[];
  isDemoDataGenerationRequested: boolean;
  farmManagerUserId: string;
  farmManagerEmailAddress: string;
  farmManagerName: Name;
}

export interface UpdateFarmAsAdminCommand extends Command {
  targetFarmId: string;
  name: string;
  currency: Currency;
  locale: Locale;
  defaultCountryForAddresses: Country;
  enabledFeatures: Feature[];
}

export interface DeleteFarmAsAdminCommand extends Command {
  targetFarmId: string;
}

export interface UpdateFarmLogoAsAdminCommand extends CommandWithFiles {
  body: {
    targetFarmId: string
  };
  files: {
    logoFile: Blob;
  }
}
