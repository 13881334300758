
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { uuid } from '@/helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useLedgerStore } from '../store';
import { AddConcentratedFeedTypeAsManagerCommand } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
}

@Component
export default class AddConcentratedFeedingTypeDialog extends Vue {

  readonly store = useLedgerStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Kraftfutter-Typ',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: AddConcentratedFeedTypeAsManagerCommand = {
      concentratedFeedId: uuid(),
      name: formValues.name!,
    };

    this.store.addConcentratedFeedType(command)
      .then(() => showSuccessMessage('Der Kraftfutter-Typ wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
