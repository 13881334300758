
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { maxLengthRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { constructForm, FormControl, Form, getFormValues, FormControls, oneOfCheckboxesRequiredRule } from '@/components/form';
import { Facility, SetUpRidingLessonsForFacilityAsManagerCommand } from '../types';
import { useRidingLessonManagementStore } from '../store';

interface Controls extends FormControls {
  isRegistrationForOneselfAllowed: FormControl<boolean>;
  isRegistrationOnBehalfOfAnotherPersonAllowed: FormControl<boolean>;
  explanationForCommentFieldForRegistration: FormControl<string>;
}

@Component
export default class SetUpRidingLessonsForFacilityDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        isRegistrationForOneselfAllowed: {
          label: 'Ein Benutzer darf sich selbst für die Unterrichtsstunden anmelden',
          value: true,
          rules: [],
          validateFormControlsOnInput: [
            'isRegistrationOnBehalfOfAnotherPersonAllowed',
          ],
        },
        isRegistrationOnBehalfOfAnotherPersonAllowed: {
          label: 'Ein Benutzer darf sich im Namen einer anderen Person für die Unterrichtsstunden anmelden',
          value: false,
          rules: [],
          validateFormControlsOnInput: [
            'isRegistrationForOneselfAllowed',
          ],
        },
        explanationForCommentFieldForRegistration: {
          label: 'Erklärung für das Kommentarfeld bei der Anmeldung',
          value: null,
          rules: [
            maxLengthRule(255, 'Die Erklärung darf maximal 255 Zeichen lang sein'),
          ],
        },
      },
    });

    form.controls.isRegistrationForOneselfAllowed.rules!.push(oneOfCheckboxesRequiredRule(
      [form.controls.isRegistrationOnBehalfOfAnotherPersonAllowed],
      'Die Registrierung muss entweder für sich selbst oder für andere Personen erlaubt sein'
    ));
    form.controls.isRegistrationOnBehalfOfAnotherPersonAllowed.rules!.push(oneOfCheckboxesRequiredRule(
      [form.controls.isRegistrationForOneselfAllowed],
      'Die Registrierung muss entweder für sich selbst oder für andere Personen erlaubt sein'
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: SetUpRidingLessonsForFacilityAsManagerCommand = {
      facilityId: this.facility.facilityId,
      isRegistrationForOneselfAllowed: formValues.isRegistrationForOneselfAllowed!,
      isRegistrationOnBehalfOfAnotherPersonAllowed: formValues.isRegistrationOnBehalfOfAnotherPersonAllowed!,
      explanationForCommentFieldForRegistration: formValues.explanationForCommentFieldForRegistration,
    };

    this.store.setUpRidingLessonsForFacility(command)
      .then(() => showSuccessMessage('Reitunterricht wurde für die Anlage eingerichtet.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
