
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { maxLengthRule, positiveNumberRule, requiredRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { convertDecimalToCurrencyValue, parsePercentageWithOneDecimalPlaceForField } from '@/helpers/form-helpers';
import { calculateTaxAmount } from '@/helpers/money-helper';
import { formatCurrency } from '@/helpers/stateful-format';
import { useBoxPlanManagementStore } from '../store';
import { Stable, BoxRow, UpdateBoxRowDetailsDTO } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    name: FormField<string|null>;
    grossSellingPrice: FormField<number|null>;
    taxRate: FormField<number|null>;
  },
}

@Component
export default class UpdateBoxRowDetailsDialog extends Vue {

  readonly store = useBoxPlanManagementStore();

  @Prop({ type: Object, required: true })
  readonly stable!: Stable;

  @Prop({ type: Object, required: true })
  readonly boxRow!: BoxRow;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  get isPricingAlertVisible(): boolean {
    return this.arePricingFieldsFilled;
  }

  get arePricingFieldsFilled(): boolean {
    return this.form!.fields.grossSellingPrice.value !== null
      && this.form!.fields.taxRate.value !== null;
  }

  get netSellingPrice(): number {
    if (!this.arePricingFieldsFilled) {
      return 0;
    }

    return convertDecimalToCurrencyValue(this.form!.fields.grossSellingPrice) - this.taxAmount;
  }

  get taxAmount(): number {
    if (!this.arePricingFieldsFilled) {
      return 0;
    }

    const grossSellingPriceInCents = convertDecimalToCurrencyValue(this.form!.fields.grossSellingPrice);
    const taxRatePerThousand = parsePercentageWithOneDecimalPlaceForField(this.form!.fields.taxRate);

    return calculateTaxAmount(grossSellingPriceInCents, taxRatePerThousand);
  }

  get pricingAlertText(): string {
    // eslint-disable-next-line max-len
    return `Der Verkaufspreis enthält ${formatCurrency(this.taxAmount)} Steuern auf den Nettopreis von ${formatCurrency(this.netSellingPrice)}`;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        name: {
          value: this.boxRow.name,
          rules: [
            requiredRule(),
            maxLengthRule(255),
          ],
        },
        grossSellingPrice: {
          value: this.boxRow.grossSellingPrice / 100,
          rules: [
            requiredRule(),
            positiveNumberRule(2),
          ],
        },
        taxRate: {
          value: this.boxRow.taxRate
            ? this.boxRow.taxRate / 10
            : null,
          rules: [
            requiredRule(),
            positiveNumberRule(2),
          ],
        },
      },
    };
  }

  formSubmitted(): void {
    const dto: UpdateBoxRowDetailsDTO = {
      stableId: this.stable.stableId,
      boxRowId: this.boxRow.boxRowId,
      name: this.form!.fields.name.value!,
      grossSellingPrice: convertDecimalToCurrencyValue(this.form!.fields.grossSellingPrice),
      netSellingPrice: this.netSellingPrice,
      taxRate: parsePercentageWithOneDecimalPlaceForField(this.form!.fields.taxRate),
      taxAmount: this.taxAmount,
    };

    this.store.updateBoxRowDetails(dto)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
