
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isFeatureEnabled } from '@/application/authentication/helper';
import { Feature } from '@/types';
import DeleteHorseDialog from './delete-horse-dialog.vue';
import { downloadFile } from '@/helpers/file-download-helper';
import { showErrorResponse } from '@/application/snackbar/service';
import { formatTime } from '@/helpers';
import { formatCurrency } from '@/helpers/stateful-format';
import { useAppStore } from '@/application/app/store';
import { useMyStableStore } from '../store';
import { ListItem, Horse } from '../types';
import UpdateHorseNameDialog from './update-horse-name-dialog.vue';
import UpdateHorseImageDialog from './update-horse-image-dialog.vue';
import UpdateHorseContactsDialog from './update-horse-contacts-dialog.vue';

@Component({
  components: {
    DeleteHorseDialog,
    UpdateHorseNameDialog,
    UpdateHorseImageDialog,
    UpdateHorseContactsDialog,
  },
})
export default class HorseDetails extends Vue {

  readonly store = useMyStableStore();
  readonly appStore = useAppStore();

  @Prop({ type: Object, required: true })
  readonly horse!: Horse;

  isUpdateMenuVisible = false;

  get isCostsPerYearVisible(): boolean {
    return isFeatureEnabled(Feature.BOX_PLAN)
      && this.horse.costsPerMonth !== null;
  }

  get isBoxLocationVisible(): boolean {
    return isFeatureEnabled(Feature.BOX_PLAN)
      && this.horse.boxLocation !== null;
  }

  get arePaddockAssignmentsVisible(): boolean {
    return isFeatureEnabled(Feature.PADDOCK_PLAN)
      && this.horse.paddockAssignments.length > 0;
  }

  get listItems(): ListItem[] {
    const listItems: ListItem[] = [];

    if (this.horse.emergencyContactName
      || this.horse.emergencyContactPhoneNumber
    ) {
      listItems.push({
        title: this.horse.emergencyContactName,
        description: this.horse.emergencyContactPhoneNumber,
        typeDescription: 'Notfallkontakt',
        typeIcon: 'light-emergency-on',
      });
    }

    if (this.horse.veterinarianName
      || this.horse.veterinarianPhone
    ) {
      listItems.push({
        title: this.horse.veterinarianName,
        description: this.horse.veterinarianPhone,
        typeDescription: 'Tierarzt',
        typeIcon: 'stethoscope',
      });
    }

    if (this.horse.farrierName
      || this.horse.farrierPhone
    ) {
      listItems.push({
        title: this.horse.farrierName,
        description: this.horse.farrierPhone,
        typeDescription: 'Hufschmied',
        typeIcon: 'magnet',
      });
    }

    if (this.isCostsPerYearVisible) {
      listItems.push({
        title: formatCurrency(this.horse.costsPerMonth),
        description: null,
        typeDescription: 'Monatlicher Einstellpreis',
        typeIcon: 'money-check',
      });
    }

    if (this.isBoxLocationVisible) {
      listItems.push({
        title: this.horse.boxLocation!,
        description: null,
        typeDescription: 'Einstellplatz',
        typeIcon: 'house-night',
      });
    }

    if (this.arePaddockAssignmentsVisible) {
      listItems.push({
        title: this.horse.paddockAssignments.length === 1
          ? 'Auf 1 Koppel'
          : `Auf ${this.horse.paddockAssignments.length} Koppeln`,
        description: this.horse.paddockAssignments
          .map((paddockAssignment) => `Von ${formatTime(paddockAssignment.timeFrame.timeFrom)} Uhr bis ${formatTime(paddockAssignment.timeFrame.timeTo)} Uhr auf Koppel ${paddockAssignment.paddockName}`)
          .map((description) => `<span class="d-block">${description}</span>`)
          .join(''),
        typeDescription: 'Koppel',
        typeIcon: 'location-dot',
      });
    }

    return listItems;
  }

  mounted(): void {
    this.appStore.updateTitle(`${this.store.currentHorse!.name} - Details`)
      .catch((error) => showErrorResponse(error));
  }

  generateBoxSign(): void {
    const fileName = `Boxenschild-${this.store.currentHorse!.name}.pdf`;

    this.store.getBoxSign()
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, fileName))
      .catch((error) => showErrorResponse(error));
  }

  hideUpdateMenu(): void {
    this.isUpdateMenuVisible = false;
  }

}
