
import { Component, Vue } from 'vue-property-decorator';
import { Date } from '@/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { timeConfigurationTranslation } from '@/helpers/translations';
import { useFarmTaskManagementStore } from '../store';
import { MarkTaskAsCompletedAsManagerCommand, TaskAssignedToMe } from '../types';

interface Controls extends FormControls {
  executionDate: FormControl<Date>;
}

@Component({
  methods: { timeConfigurationTranslation }
})
export default class MyTasks extends Vue {

  readonly store = useFarmTaskManagementStore();

  form: Form<Controls> | null = null;

  get emptyListDescription(): string {
    if (!this.store.executionDateForMyTasks) {
      return 'Bitte wähle ein Bearbeitungsdatum aus.';
    }

    return `${this.store.executionDateForMyTasks.formattedLabel('Am')} stehen keine Aufgaben für dich an.`;
  }

  get isTaskClickable(): boolean {
    return !!this.store.executionDateForMyTasks
      && this.store.executionDateForMyTasks.isBeforeOrEqualTo(Date.today());
  }

  mounted(): void {
    const initialExecutionDate = Date.today();

    this.form = this.buildForm(initialExecutionDate);

    this.store.updateExecutionDateForMyTasks(initialExecutionDate)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(initialExecutionDate: Date): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        executionDate: {
          label: 'Bearbeitungsdatum',
          value: initialExecutionDate,
          afterTransformationAndValidation: (executionDate) => {
            this.store.updateExecutionDateForMyTasks(executionDate!)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  taskClicked(task: TaskAssignedToMe): void {
    const command: MarkTaskAsCompletedAsManagerCommand = {
      farmTaskId: task.farmTaskId,
      executionDate: Date.today(),
    };

    this.store.markTaskAsCompleted(command)
      .then(() => this.store.getMyTasks())
      .then(() => showSuccessMessage('Die Aufgabe wurde als erledigt markiert.'))
      .catch((error) => showErrorResponse(error));
  }

}
