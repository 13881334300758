
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { DataListItem } from '@/components/design-system';
import { formatBoolean } from '@/helpers';
import { useFeedProtocolManagementStore } from '../store';
import { DeleteStationAsManagerCommand, Station } from '../types';
import CreateStationDialog from './create-station-dialog.vue';
import UpdateStationDialog from './update-station-dialog.vue';

@Component({
  components: {
    CreateStationDialog,
    UpdateStationDialog,
  },
})
export default class FeedProtocolConfiguration extends Vue {

  readonly store = useFeedProtocolManagementStore();

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'arePushNotificationsEnabled',
        label: 'Benachrichtigungen aktiviert',
        value: formatBoolean(this.store.feedProtocolConfiguration!.arePushNotificationsEnabled),
      },
    ];
  }

  get areNoStationsConfiguredYet(): boolean {
    return this.store.feedProtocolConfiguration !== null
      && this.store.feedProtocolConfiguration.stations.length === 0;
  }

  get isCreateStationVisible(): boolean {
    return this.store.feedProtocolConfiguration !== null
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_WRITE);
  }

  get isUpdateStationVisible(): boolean {
    return this.store.feedProtocolConfiguration !== null
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_WRITE);
  }

  get isDeleteStationVisible(): boolean {
    return this.store.feedProtocolConfiguration !== null
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_WRITE);
  }

  get isEnablePushNotificationsForFeedProtocolVisible(): boolean {
    return this.store.feedProtocolConfiguration !== null
      && !this.store.feedProtocolConfiguration.arePushNotificationsEnabled
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_WRITE);
  }

  get isDisablePushNotificationsForFeedProtocolVisible(): boolean {
    return this.store.feedProtocolConfiguration !== null
      && this.store.feedProtocolConfiguration.arePushNotificationsEnabled
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_WRITE);
  }

  mounted(): void {
    this.store.getFeedProtocolConfiguration()
      .catch((error) => showErrorResponse(error));
  }

  async deleteStationAction(station: Station): Promise<void> {
    const command: DeleteStationAsManagerCommand = {
      feedProtocolStationId: station.feedProtocolStationId,
    };

    return this.store.deleteStation(command)
      .then(() => showSuccessMessage('Station wurde mit allen Einträgen gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  async enablePushNotificationsAction(): Promise<void> {
    return this.store.enablePushNotifications()
      .then(() => showSuccessMessage('Benachrichtigungen wurden aktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

  async disablePushNotificationsAction(): Promise<void> {
    return this.store.disablePushNotifications()
      .then(() => showSuccessMessage('Benachrichtigungen wurden deaktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

}
