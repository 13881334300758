
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition, VuetifyValidatable } from '@/application/types';
import { minNumberRule, positiveNumberRule, requiredRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { LaborServiceSettingsByUserGroup, UpdateSettingsByUserGroupAsManagerCommand } from '../types';
import { useLaborServicesManagementStore } from '../store';

interface Form extends StrictFormDefinition {
  fields: {
    annualNumberOfHours: FormField<number|null>;
  },
}

@Component
export default class UpdateSettingsByUserGroupDialog extends Vue {

  readonly store = useLaborServicesManagementStore();

  @Prop({ type: Object, required: true })
  readonly laborServiceSettingsByUserGroup!: LaborServiceSettingsByUserGroup;

  readonly size = DialogWidth.small;

  @Ref()
  readonly formElement?: VuetifyValidatable;

  isDialogVisible = false;

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (this.formElement) {
        this.formElement.resetValidation();
      }
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        annualNumberOfHours: {
          value: this.laborServiceSettingsByUserGroup.annualNumberOfHours,
          rules: [
            requiredRule(),
            positiveNumberRule(2),
            minNumberRule(0),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateSettingsByUserGroupAsManagerCommand = {
      userGroupId: this.laborServiceSettingsByUserGroup.userGroupId,
      annualNumberOfHours: this.form!.fields.annualNumberOfHours.value!,
    };

    this.store.updateSettingsByUserGroup(command)
      .then(() => showSuccessMessage('Die Einstellungen wurden angepasst'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
