
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DataOptions, DataTableHeader } from 'vuetify';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { showErrorResponse } from '@/application/snackbar/service';
import { featureTranslation } from '@/helpers/translations';
import { Feature, sortDirection } from '@/types';
import { Farm, GetFarmsQuerySortBy } from '../types';
import { useFarmManagementStore } from '../store';
import CreateFarmDialog from './create-farm-dialog.vue';
import UpdateFarmDialog from './update-farm-dialog.vue';
import DeleteFarmDialog from './delete-farm-dialog.vue';
import UpdateFarmLogoDialog from './update-farm-logo-dialog.vue';

interface Controls extends FormControls {
  search: FormControl<string>;
  features: FormControl<Feature[]>;
}

@Component({
  components: {
    CreateFarmDialog,
    UpdateFarmDialog,
    DeleteFarmDialog,
    UpdateFarmLogoDialog,
  },
})
export default class ManageFarms extends Vue {

  readonly store = useFarmManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name', width: 260, sortable: true },
    { text: 'Features', value: 'enabledFeatures', sortable: false },
    { text: 'Benutzeranzahl', value: 'numberOfUsers', align: 'end', sortable: true },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 190, sortable: false },
  ];

  form: Form<Controls> | null = null;

  dataTableOptions: DataOptions | null = null;

  get search(): string | null {
    if (!this.form) {
      return null;
    }

    const formValues = getFormValues(this.form);

    return formValues.search;
  }

  get isLoadMoreDisabled(): boolean {
    return this.store.totalCount === null
      || this.store.farms.length >= this.store.totalCount;
  }

  mounted(): void {
    this.form = this.buildForm();

    this.store.getFarms()
      .catch((error) => showErrorResponse(error));

    watch(() => this.dataTableOptions, (dataTableOptions) => {
      if (!dataTableOptions
        || dataTableOptions.sortBy.length === 0
        || dataTableOptions.sortDesc.length === 0
      ) {
        return;
      }

      // name and createdAt are the only sortable fields
      const sortBy = dataTableOptions.sortBy[0] === 'name'
        ? GetFarmsQuerySortBy.NAME
        : GetFarmsQuerySortBy.NUMBER_OF_USERS;

      this.store.updateSorting(sortBy, sortDirection(dataTableOptions.sortDesc[0]))
        .catch((error) => showErrorResponse(error));

    }, { deep: true });
  }

  destroyed(): void {
    this.store.resetFilters()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
          afterTransformationAndValidation: (value) => {
            this.store.updateSearch(value)
              .catch((error) => showErrorResponse(error));
          },
        },
        features: {
          label: 'Features',
          value: null,
          afterTransformationAndValidation: (value) => {
            this.store.updateSelectedFeatures(value)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  translateEnabledFeatures(farm: Farm) {
    return farm.enabledFeatures
      .map((feature) => featureTranslation[feature])
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  }

  onLoadMoreClicked(): void {
    this.store.increaseLimit()
      .catch((error) => showErrorResponse(error));
  }

}
