
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActionStatus } from '@/application/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { RidingLessonId, FarmManagerPermission } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { formatTime } from '@/helpers';
import { useRidingLessonManagementStore } from '../store';
import { AssignHorseToRidingLessonAsManagerCommand, DeleteRidingLessonAsManagerCommand, Horse, RemoveHorseFromRidingLessonAsManagerCommand, RidingLesson } from '../types';
import { midnightSevenDaysAgo } from '../helper';
import RegisterPersonForRidingLessonDialog from './register-person-for-riding-lesson-dialog.vue';
import UpdateRidingInstructorOfRidingLessonDialog from './update-riding-instructor-of-riding-lesson-dialog.vue';
import UpdateMaxAmountOfParticipantsOfRidingLessonDialog from './update-max-amount-of-participants-of-riding-lesson-dialog.vue';
import UpdateTimeFrameOfRidingLessonDialog from './update-time-frame-of-riding-lesson-dialog.vue';
import RidingLessonRegistrationCard from './riding-lesson-registration-card.vue';
import UpdateFacilityAndRidingLessonTypeOfRidingLessonDialog from './update-facility-and-riding-lesson-type-of-riding-lesson-dialog.vue';
import UpdateLastRidingLessonAtOfRidingLessonSeriesDialog from './update-last-riding-lesson-at-of-riding-lesson-series-dialog.vue';
import UpdateRidingInstructorOfRidingLessonSeriesDialog from './update-riding-instructor-of-riding-lesson-series-dialog.vue';
import UpdateMaxAmountOfParticipantsOfRidingLessonSeriesDialog from './update-max-amount-of-participants-of-riding-lesson-series-dialog.vue';
import UpdateLastRegistrationAtOfRidingLessonSeriesDialog from './update-last-registration-at-of-riding-lesson-series-dialog.vue';
import UpdateWeekdayAndTimeFrameOfRidingLessonSeriesDialog from './update-weekday-and-time-frame-of-riding-lesson-series-dialog.vue';
import RegisterPersonForRidingLessonSeriesDialog from './register-person-for-riding-lesson-series-dialog.vue';

@Component({
  components: {
    RidingLessonRegistrationCard,
    RegisterPersonForRidingLessonSeriesDialog,
    RegisterPersonForRidingLessonDialog,
    UpdateRidingInstructorOfRidingLessonDialog,
    UpdateMaxAmountOfParticipantsOfRidingLessonDialog,
    UpdateTimeFrameOfRidingLessonDialog,
    UpdateFacilityAndRidingLessonTypeOfRidingLessonDialog,
    UpdateLastRidingLessonAtOfRidingLessonSeriesDialog,
    UpdateRidingInstructorOfRidingLessonSeriesDialog,
    UpdateMaxAmountOfParticipantsOfRidingLessonSeriesDialog,
    UpdateLastRegistrationAtOfRidingLessonSeriesDialog,
    UpdateWeekdayAndTimeFrameOfRidingLessonSeriesDialog,
  },
})
export default class RidingLessonCard extends Vue {

  readonly store = useRidingLessonManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly ridingLesson!: RidingLesson;

  get isHorseSelected(): boolean {
    return !!this.store.idOfSelectedHorse;
  }

  get isLoading(): boolean {
    return this.store.isGetRidingLessonsProcessing
      || this.store.isGetConfigurationProcessing;
  }

  get isActionDisabled(): boolean {
    return this.isLoading
      || this.store.isCreateRidingLessonProcessing;
  }

  get noDataText(): string {
    return this.store.getRidingLessonsStatus === ActionStatus.Failed
      ? 'Die Unterrichtsstunden konnten nicht geladen werden.'
      : 'Du hast noch keine Unterrichtsstunde für diesen Tag erstellt.';
  }

  get isHorseWithoutInteractionVisible(): boolean {
    return this.isHorseSelected
      || this.ridingLesson.from.isBefore(midnightSevenDaysAgo())
      || !this.authenticationStore.doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_ASSIGN_HORSES);
  }

  get ridingLessonContentVisible(): boolean {
    return this.store.idOfOpenRidingLesson === this.ridingLesson.ridingLessonId;
  }

  get doesRidingLessonHaveAtLeastOneHardConflict(): boolean {
    return this.doesRidingLessonHaveAConflictWithRidingInstructor
      || this.doesRidingLessonHaveAConflictWithHorse
      || this.doesRidingLessonHaveAnInternalConflictWithHorse
      || this.doesRidingLessonHaveRegistrationsOnWaitingList;
  }

  get doesRidingLessonHaveAtLeastOneFacilityConflict(): boolean {
    return this.doesRidingLessonHaveAConflictWithFacility;
  }

  get doesRidingLessonHaveAConflictWithFacility(): boolean {
    return this.ridingLesson.facilityOverlappingWithOtherRidingLessons.length > 0;
  }

  get doesRidingLessonHaveAConflictWithRidingInstructor(): boolean {
    return this.ridingLesson.ridingInstructorOverlappingWithOtherRidingLessons.length > 0;
  }

  get doesRidingLessonHaveAConflictWithHorse(): boolean {
    return this.ridingLesson.horsesOverlappingWithOtherRidingLessonsMap !== null;
  }

  get doesRidingLessonHaveAnInternalConflictWithHorse(): boolean {
    return this.ridingLesson.horsesWithDuplicateAssignments.length > 0;
  }

  get doesRidingLessonHaveRegistrationsOnWaitingList(): boolean {
    return this.ridingLesson.maxAmountOfParticipants !== null
      && this.ridingLesson.registrations.length > this.ridingLesson.maxAmountOfParticipants;
  }

  get overlappingRidingLessonsWithRidingInstructor(): RidingLesson[] {
    return this.ridingLesson.ridingInstructorOverlappingWithOtherRidingLessons
      .map((ridingLessonId) => this.ridingLessonById(ridingLessonId));
  }

  get overlappingRidingLessonsWithFacility(): RidingLesson[] {
    return this.ridingLesson.facilityOverlappingWithOtherRidingLessons
      .map((ridingLessonId) => this.ridingLessonById(ridingLessonId));
  }

  get areActionsVisible(): boolean {
    return this.isRegisterPersonForRidingLessonVisible
      || this.isUpdateRidingLessonVisible
      || this.isDeleteRidingLessonVisible;
  }

  get isDeleteRidingLessonVisible(): boolean {
    return this.ridingLesson.from.isSameOrAfter(midnightSevenDaysAgo())
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_DELETE);
  }

  get isUpdateRidingLessonVisible(): boolean {
    return this.ridingLesson.from.isSameOrAfter(midnightSevenDaysAgo())
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE);
  }

  get isUpdateRidingLessonSeriesVisible(): boolean {
    return this.ridingLesson.from.isSameOrAfter(midnightSevenDaysAgo())
      && !!this.ridingLesson.ridingLessonSeries
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE);
  }

  get isRegisterPersonForRidingLessonVisible(): boolean {
    return !this.ridingLesson.ridingLessonSeries
      && this.ridingLesson.from.isSameOrAfter(midnightSevenDaysAgo())
      && (
        this.ridingLesson.maxAmountOfParticipants === null
        || this.ridingLesson.maxAmountOfParticipants > this.ridingLesson.registrations.length
      )
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE);
  }

  get isRegisterPersonForRidingLessonSeriesVisible(): boolean {
    return !!this.ridingLesson.ridingLessonSeries
      && this.ridingLesson.from.isSameOrAfter(midnightSevenDaysAgo())
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE);
  }

  get ridingLessonTitle(): string {
    const maxParticipantsTitle = this.ridingLesson.maxAmountOfParticipants === null
      ? '(unbegrenzt)'
      : `(max. ${this.ridingLesson.maxAmountOfParticipants})`;

    const ridingInstructor = this.store.farmManagers
      .find((user) => user.userId === this.ridingLesson.ridingInstructorId) ?? null;
    const ridingInstructorTitle = ridingInstructor
      ? ridingInstructor.name
      : 'Kein Reitlehrer';

    const seriesTitle = this.ridingLesson.ridingLessonSeries
      ? ' (Serie)'
      : '';

    // eslint-disable-next-line max-len
    return `<strong>${formatTime(this.ridingLesson.from)} - ${formatTime(this.ridingLesson.to)} Uhr - ${this.ridingLesson.ridingLessonType.name}</strong> - ${this.ridingLesson.facility.name} - ${this.ridingLesson.registrations.length} Teilnehmer ${maxParticipantsTitle} - ${ridingInstructorTitle}${seriesTitle}`;
  }

  get overlappingRidingLessonsWithHorse(): string {
    if (!this.ridingLesson.horsesOverlappingWithOtherRidingLessonsMap
      || !this.store.configurationForRidingLessons
    ) {
      return '';
    }

    let html = '';
    // eslint-disable-next-line guard-for-in
    for (const horseId in this.ridingLesson.horsesOverlappingWithOtherRidingLessonsMap) {
      const ridingLessonId = this.ridingLesson.horsesOverlappingWithOtherRidingLessonsMap![horseId];
      const overlappingRidingLesson = this.ridingLessonById(ridingLessonId);
      const horse = this.store.horses.find((horse) => horse.horseId === horseId)!;

      // eslint-disable-next-line max-len
      html += `<span class="d-block">Pferd ${horse.name} ist zeitgleich der Unterrichtsstunde <strong>${formatTime(overlappingRidingLesson.from)} - ${formatTime(overlappingRidingLesson.to)} Uhr - ${overlappingRidingLesson.ridingLessonType.name}</strong> zugewiesen.</span>`;
    }

    return html;
  }

  get internalOverlappingWithHorse(): string {
    if (this.ridingLesson.horsesWithDuplicateAssignments.length === 0
      || !this.store.configurationForRidingLessons
    ) {
      return '';
    }

    let html = '';
    // eslint-disable-next-line guard-for-in
    for (const horseId of this.ridingLesson.horsesWithDuplicateAssignments) {
      const horse = this.store.horses.find((horse) => horse.horseId === horseId)!;

      // eslint-disable-next-line max-len
      html += `<span class="d-block">Pferd <strong>${horse.name}</strong> ist mehrfach in dieser Unterrichtsstunde zugewiesen.</span>`;
    }

    return html;
  }

  get descriptionForRegistrationsOnWaitingList(): string {
    if (this.ridingLesson.maxAmountOfParticipants === null) {
      return '';
    }

    const amountOfRegistrationsOnWaitingList = this.ridingLesson.registrations.length - this.ridingLesson.maxAmountOfParticipants;

    return amountOfRegistrationsOnWaitingList === 1
      ? `<strong>1 Anmeldung</strong> ist auf der Warteliste.`
      : `<strong>${amountOfRegistrationsOnWaitingList} Anmeldungen</strong> sind auf der Warteliste.`;
  }

  get doesRidingLessonHaveDuplicateHorse(): boolean {
    return this.ridingLesson.horses
      .some((horse) => this.ridingLesson.horsesWithDuplicateAssignments.includes(horse.horseId));
  }

  deleteRidingLessonAction(): Promise<void> {
    const command: DeleteRidingLessonAsManagerCommand = {
      ridingLessonId: this.ridingLesson.ridingLessonId,
    };

    return this.store.deleteRidingLesson(command)
      .then(() => showSuccessMessage('Unterrichtsstunde wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  assignHorseToRidingLessonConfirmed(): void {
    if (!this.store.idOfSelectedHorse) {
      throw new Error('No horse selected');
    }

    const command: AssignHorseToRidingLessonAsManagerCommand = {
      ridingLessonId: this.ridingLesson.ridingLessonId,
      horseId: this.store.idOfSelectedHorse,
    };
    this.store.assignHorseToRidingLesson(command)
      .catch((error) => showErrorResponse(error));

    this.store.idOfSelectedHorse = null;
  }

  removeHorseFromRidingLessonAction(horse: Horse): Promise<void> {
    const command: RemoveHorseFromRidingLessonAsManagerCommand = {
      ridingLessonId: this.ridingLesson.ridingLessonId,
      horseId: horse.horseId,
    };

    return this.store.removeHorseFromRidingLesson(command)
      .catch((error) => showErrorResponse(error));
  }

  ridingLessonClicked(): void {
    this.store.openRidingLesson(this.ridingLesson.ridingLessonId)
      .catch((error) => showErrorResponse(error));
  }

  ridingLessonById(ridingLessonId: RidingLessonId): RidingLesson {
    return this.store.ridingLessons
      .find((ridingLesson) => ridingLessonId === ridingLesson.ridingLessonId)!;
  }

}
