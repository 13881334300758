
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { Moment } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

export interface MomentIdentification {
  moment: Moment;
  label: string;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
      && typeof item === 'object'
      && Object.hasOwn(item, 'moment')
      && Object.hasOwn(item, 'label'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class MomentDateSelectFormControl extends Vue implements FormControlComponent<Moment> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Moment>;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly items!: MomentIdentification[];

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: Moment|null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get selectItems(): VuetifySelectItem<Moment>[] {
    return this.items.map((item) => ({
      text: item.label,
      value: item.moment,
    }));
  }

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<Moment> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
