
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { usePenManagementStore } from '../store';
import ConfigurationView from './configuration-view.vue';

@Component({
  components: {
    ConfigurationView,
  },
})
export default class ConfigurationLoadingWrapper extends Vue {

  readonly store = usePenManagementStore();

  mounted(): void {
    this.store.getConfiguration()
      .catch((error) => showErrorResponse(error));
  }

}
