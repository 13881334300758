import { render, staticRenderFns } from "./update-booking-notice-of-facility-dialog.vue?vue&type=template&id=5be3349e&"
import script from "./update-booking-notice-of-facility-dialog.vue?vue&type=script&lang=ts&"
export * from "./update-booking-notice-of-facility-dialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports