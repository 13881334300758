
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { moment } from '@/helpers';
import { DeleteHorseTrainingSessionCommand, TrainingDiaryEntry, TrainingType } from '../types';
import { useMyStableStore } from '../store';
import CreateHorseTrainingSessionDialog from './create-horse-training-session-dialog.vue';
import CreateHorseTrainingSessionForFacilityReservationDialog from './create-horse-training-session-for-facility-reservation-dialog.vue';
import UpdateHorseTrainingSessionDialog from './update-horse-training-session-dialog.vue';

@Component({
  components: {
    CreateHorseTrainingSessionDialog,
    CreateHorseTrainingSessionForFacilityReservationDialog,
    UpdateHorseTrainingSessionDialog,
  },
})
export default class TrainingDiary extends Vue {

  readonly store = useMyStableStore();
  readonly appStore = useAppStore();

  get isProcessIndicatorVisible(): boolean {
    return this.store.isGetTrainingDiaryProcessing
      && this.store.trainingDiary === null;
  }

  get isTrainingDiaryDescriptionVisible(): boolean {
    return !this.store.isGetTrainingDiaryProcessing
      && this.store.trainingDiary !== null;
  }

  get trainingDescription(): string {
    if (!this.store.trainingDiary) {
      return '';
    }

    const trainingCount = this.store.trainingDiary.length;
    if (trainingCount > 0) {
      return this.store.numberOfWeeksForTrainingDiary === 1
        ? `${this.store.currentHorse!.name} wurde laut des Tagebuchs in der letzten <span class="text-no-wrap">Woche</span> <span class="text-no-wrap">${trainingCount} Mal</span> trainiert.`
        : `${this.store.currentHorse!.name} wurde laut des Tagebuchs in den letzten <span class="text-no-wrap">${this.store.numberOfWeeksForTrainingDiary} Wochen</span> <span class="text-no-wrap">${trainingCount} Mal</span> trainiert.`;
    }

    return this.store.numberOfWeeksForTrainingDiary === 1
      ? `${this.store.currentHorse!.name} wurde laut des Tagebuchs in der letzten <span class="text-no-wrap">Woche</span> nicht trainiert.`
      : `${this.store.currentHorse!.name} wurde laut des Tagebuchs in den letzten <span class="text-no-wrap">${this.store.numberOfWeeksForTrainingDiary} Wochen</span> nicht trainiert.`;
  }

  mounted(): void {
    this.store.getTrainingDiary()
      .catch((error) => showErrorResponse(error));

    this.appStore.updateTitle(`${this.store.currentHorse!.name} - Trainingstagebuch`)
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetMovementHistory();
  }

  timelineItemClass(trainingDiaryEntry: TrainingDiaryEntry, index: number): Record<string, boolean> {
    if (!this.store.trainingDiary) {
      return {};
    }

    const classes: Record<string, boolean> = {
      'mb-2': index < this.store.trainingDiary.length - 1,
    };
    if (trainingDiaryEntry.strainOnHorse)  {
      classes[trainingDiaryEntry.strainOnHorse.toLowerCase()] = true;
    } else {
      classes['facility_reservation'] = true;
    }

    return classes;
  }

  duration(trainingDiaryEntry: TrainingDiaryEntry): string {
    const durationInMinutes = trainingDiaryEntry.trainedTo.diff(trainingDiaryEntry.trainedFrom, 'minutes');

    const durationInHours = Math.floor(durationInMinutes / 60);
    const hoursText = durationInHours === 0
      ? null
      : durationInHours === 1
        ? '1 Stunde'
        : `${durationInHours} Stunden`;

    const overlappingMinutes = durationInMinutes % 60;
    const minutesText: string | null = overlappingMinutes === 0
      ? null
      : `${overlappingMinutes} min.`;

    return hoursText !== null && minutesText !== null
      ? `${hoursText} ${minutesText}`
      : hoursText
        ? hoursText
        : minutesText!;
  }

  isCreateHorseTrainingSessionForFacilityReservationVisible(trainingDiaryEntry: TrainingDiaryEntry): boolean {
    return trainingDiaryEntry.type === TrainingType.FACILITY_RESERVATION
      && (
        this.store.isCurrentHorseOwn
        || moment()
          .startOf('day')
          .subtract(7, 'days')
          .isSameOrBefore(trainingDiaryEntry.trainedFrom)
      );
  }

  isUpdateHorseTrainingSessionVisible(trainingDiaryEntry: TrainingDiaryEntry): boolean {
    return trainingDiaryEntry.type === TrainingType.HORSE_TRAINING
      && (
        this.store.isCurrentHorseOwn
        || moment()
          .startOf('day')
          .subtract(7, 'days')
          .isSameOrBefore(trainingDiaryEntry.trainedFrom)
      );
  }

  isDeleteHorseTrainingSessionVisible(trainingDiaryEntry: TrainingDiaryEntry): boolean {
    return trainingDiaryEntry.type === TrainingType.HORSE_TRAINING
      && (
        this.store.isCurrentHorseOwn
        || moment()
          .startOf('day')
          .subtract(7, 'days')
          .isSameOrBefore(trainingDiaryEntry.trainedFrom)
      );
  }

  loadMoreClicked(): void {
    this.store.increaseNumberOfWeeksForTrainingDiary()
      .catch((error) => showErrorResponse(error));
  }

  deleteHorseTrainingSession(trainingDiaryEntry: TrainingDiaryEntry): void {
    const command: DeleteHorseTrainingSessionCommand = {
      horseTrainingSessionId: trainingDiaryEntry.id,
    };
    this.store.deleteHorseTrainingSession(command)
      .then(() => showSuccessMessage('Das Training wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  openExternalLink(externalLink: string): void {
    window.open(externalLink, '_blank');
  }

}
