
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Name, Address, InvoiceAddressForPerson, Salutation } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useMyStableStore } from '../store';
import { UpdateInvoiceAddressCommand } from '../types';

interface Controls extends FormControls {
  company: FormControl<string>;
  salutation: FormControl<Salutation>;
  name: FormControl<Name>;
  address: FormControl<Address>;
}

@Component
export default class UpdateInvoiceAddressDialog extends Vue {

  readonly store = useMyStableStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly invoiceAddress!: InvoiceAddressForPerson;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        company: {
          label: 'Firmennamen',
          value: this.invoiceAddress.company,
          rules: [
            maxLengthRule(255),
          ],
        },
        salutation: {
          label: 'Anrede',
          value: this.invoiceAddress.salutation,
        },
        name: {
          label: 'Name',
          value: this.invoiceAddress.name,
          isRequired: true,
        },
        address: {
          label: 'Adresse',
          value: this.invoiceAddress.address,
          isRequired: true,
        },
      },
    });
  }


  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateInvoiceAddressCommand = {
      personId: this.authenticationStore.user!.personId,
      invoiceAddress: {
        company: formValues.company,
        salutation: formValues.salutation,
        name: formValues.name!,
        address: formValues.address!,
      },
    };

    this.store.updateInvoiceAddress(command)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
