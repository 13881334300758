
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { Date } from '@/types';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { useShowFarmChangesForDayStore } from '../store';

interface Controls extends FormControls {
  date: FormControl<Date>;
}

@Component
export default class ShowFarmChangesForDay extends Vue {

  readonly store = useShowFarmChangesForDayStore();

  readonly typeMap = {
    feeding: 'Fütterung',
    'concentrated-feeding': 'Kraftfutter',
    'bedding-subscription': 'Sägespäne zum Einstreu',
    'bedding-subscription-ended': 'Keine Sägespäne zum Einstreu',
  };

  readonly farmServiceTypesMap: any = {
    'paddock-guidance': `Koppelführdienst`,
    gaiters: `Gamaschenservice`,
    'cover-week': `Deckendienst von Mo. bis Fr.`,
    'cover-weekend': `Deckendienst am Sa. und So.`,
    'horse-walker-week': `Führanlage nach Koppel oder Paddock von Mo. bis Fr.`,
    'horse-walker-weekend': `Führanlage nach Koppel oder Paddock am Sa. und So.`,
  };

  readonly farmServiceTypesEndingMap: any = {
    'paddock-guidance': `Keinen Koppelführdienst`,
    gaiters: `Keinen Gamaschenservice`,
    'cover-week': `Keinen Deckendienst von Mo. bis Fr.`,
    'cover-weekend': `Keinen Deckendienst am Sa. und So.`,
    'horse-walker-week': `Keine Führanlage nach Koppel oder Paddock von Mo. bis Fr.`,
    'horse-walker-weekend': `Keine Führanlage nach Koppel oder Paddock am Sa. und So.`,
  };

  form: Form<Controls> | null = null;

  mounted(): void {
    const initialDate = Date.today();

    this.form = this.buildForm(initialDate);

    this.store.updateDateForFarmChanges(initialDate)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(initialDate: Date): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        date: {
          label: 'Datum',
          value: initialDate,
          afterTransformationAndValidation: (executionDate) => {
            this.store.updateDateForFarmChanges(executionDate!)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

}
