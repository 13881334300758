
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FarmTaskId, FarmManagerPermission } from '@/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { showErrorResponse } from '@/application/snackbar/service';
import { useFarmTaskManagementStore } from '../store';
import { RecentTasks, TaskForGroupedByHorse } from '../types';
import { taskNoteForRecentOrOutDatedTask } from '../helper';
import DuplicateTaskDialog from './duplicate-task-dialog.vue';
import UpdateTitleAndDetailsOfTaskDialog from './update-title-and-details-of-task-dialog.vue';
import UpdateLastRepetitionOfTaskDialog from './update-last-repetition-of-task-dialog.vue';
import DeleteTaskDialog from './delete-task-dialog.vue';
import AssignTaskToUserDialog from './assign-task-to-user-dialog.vue';
import UpdateExecutionDateOfTaskDialog from './update-execution-date-of-task-dialog.vue';
import UpdateTimeConfigurationOfTaskDialog from './update-time-configuration-of-task-dialog.vue';

@Component({
  components: {
    DeleteTaskDialog,
    UpdateLastRepetitionOfTaskDialog,
    UpdateTitleAndDetailsOfTaskDialog,
    DuplicateTaskDialog,
    AssignTaskToUserDialog,
    UpdateExecutionDateOfTaskDialog,
    UpdateTimeConfigurationOfTaskDialog,
  },
  methods: {
    taskNoteForRecentOrOutDatedTask,
  },
})
export default class RecentTasksList extends Vue {

  readonly store = useFarmTaskManagementStore();

  @Prop({ type: Object, required: true })
  readonly recentTasks!: RecentTasks;

  readonly taskMenuList: Record<FarmTaskId, boolean> = {};

  get isUpdateTitleAndDetailsOfTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isUpdateTimeConfigurationOfTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isDuplicateTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isAssignTaskToUserVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isDeleteTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.recentTasks.tasks.length >= this.recentTasks.totalCount;
  }

  isUpdateTaskVisible(task: TaskForGroupedByHorse): boolean {
    return this.isDuplicateTaskVisible
      || this.isAssignTaskToUserVisible
      || this.isUpdateTitleAndDetailsOfTaskVisible
      || this.isUpdateTimeConfigurationOfTaskVisible
      || this.isDeleteTaskVisible
      || this.isUpdateLastRepetitionOfTaskVisible(task)
      || this.isUpdateExecutionDateOfTaskIsVisible(task);
  }

  isUpdateLastRepetitionOfTaskVisible(task: TaskForGroupedByHorse): boolean {
    return !!task.repetition
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  isUpdateExecutionDateOfTaskIsVisible(task: TaskForGroupedByHorse): boolean {
    return !task.repetition
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  getTasks(): void {
    this.store.getRecentTasks()
      .catch((error) => showErrorResponse(error));
  }

  onLoadMoreClicked(): void {
    this.store.increaseLimitForRecentTasks()
      .catch((error) => showErrorResponse(error));
  }

}
