
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { Moment, FarmManagerPermission } from '@/types';
import { useFreeJumpingFreeRunningManagementStore } from '../store';
import CreateFreeJumpingFreeRunningEventsDialog from './create-free-jumping-free-running-events-dialog.vue';
import DeleteFreeJumpingFreeRunningEventDialog from './delete-free-jumping-free-running-event-dialog.vue';

function sortByDate(a: Moment, b: Moment): number {
  return a.diff(b);
}

function sortByLength(a: unknown[], b: unknown[]): number {
  return a.length - b.length;
}

@Component({
  components: {
    CreateFreeJumpingFreeRunningEventsDialog,
    DeleteFreeJumpingFreeRunningEventDialog,
  },
})
export default class FreeJumpingFreeRunningEvents extends Vue {

  readonly store = useFreeJumpingFreeRunningManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Datum', value: 'date', sort: sortByDate },
    { text: 'Anmeldungen für Freispringen', value: 'freeJumpingRegistrations', sort: sortByLength },
    { text: 'Anmeldungen für Freilaufen', value: 'freeRunningRegistrations', sort: sortByLength },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  get isCreateEventsVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_WRITE);
  }

  get isDeleteEventVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_WRITE);
  }

  mounted(): void {
    this.store.getEvents()
      .catch((error) => showErrorResponse(error));
  }

}
