
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { ActionStatus } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { isMobileSafari } from '@/helpers/detection-helpers';
import { downloadCSVFile } from '@/helpers/file-download-helper';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { FacilityBlockerId, Date, FarmManagerPermission } from '@/types';
import { moment } from '@/helpers';
import CreateSingleFacilityBlockerDialog from './create-single-facility-blocker-dialog.vue';
import CreateMultipleFacilityBlockersDialog from './create-multiple-facility-blockers-dialog.vue';
import UpdateFacilityBlockerReasonDialog from './update-facility-blocker-reason-dialog.vue';
import UpdateFacilityBlockerTimeRangeDialog from './update-facility-blocker-time-range-dialog.vue';
import DeleteFacilityBlockerDialog from './delete-facility-blocker-dialog.vue';
import { useFacilityManagementStore } from '../store';
import { DeleteFacilityBlockersAsManagerCommand, FacilityBlocker } from '../types';

interface Controls extends FormControls {
  date: FormControl<Date>;
  search: FormControl<string>;
}

@Component({
  methods: { isMobileSafari },
  components: {
    CreateSingleFacilityBlockerDialog,
    CreateMultipleFacilityBlockersDialog,
    UpdateFacilityBlockerReasonDialog,
    UpdateFacilityBlockerTimeRangeDialog,
    DeleteFacilityBlockerDialog,
  },
})
export default class FacilityBlockers extends Vue {

  readonly store = useFacilityManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Datum', value: 'date', width: 160 },
    { text: 'Zeitraum', value: 'timeRange', width: 130 },
    { text: 'Blockiert Plätze', value: 'blocksSpaces' },
    { text: 'Grund', value: 'reason' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  form: Form<Controls> | null = null;

  isCreateMenuVisible = false;
  facilityBlockerMenuList: Record<FacilityBlockerId, boolean> = {};

  get noDataText(): string {
    if (!this.form) {
      return '';
    }

    if (this.store.getFacilityBlockersForFacilityStatus === ActionStatus.Failed) {
      return 'Die Sperrzeiten konnten nicht geladen werden.';
    }

    const formValues = getFormValues(this.form);

    return formValues.date === null
      && formValues.search === null
      ? 'Es wurden noch keine Sperrzeiten angelegt.'
      : 'Es gibt keine Sperrzeiten für diese Filtereinstellungen.';
  }

  get selectedRows(): FacilityBlocker[] {
    return this.store.facilityBlockers
      .filter((facilityBlocker) => this.store.idsOfSelectedFacilityBlockers.includes(facilityBlocker.facilityBlockerId));
  }

  get selectableFacilityBlockers(): FacilityBlocker[] {
    return this.store.facilityBlockers
      .filter((facilityBlocker) => !this.isFacilityBlockerEndInThePast(facilityBlocker));
  }

  get areAllSelectableFacilityBlockersSelected(): boolean {
    return this.selectableFacilityBlockers.length > 0
      && this.selectableFacilityBlockers.length === this.store.idsOfSelectedFacilityBlockers.length;
  }

  get areSomeSelectableFacilityBlockersSelected(): boolean {
    return this.store.idsOfSelectedFacilityBlockers.length > 0
      && !this.areAllSelectableFacilityBlockersSelected;
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.store.isGetFacilityBlockersForFacilityProcessing
      || this.store.limitForFacilityBlockers >= this.store.totalCountForFacilityBlockers;
  }

  get isMultiSelectFacilityBlockerVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  get isCreateFacilityBlockerVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  get isUpdateFacilityBlockerVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  get isDeleteFacilityBlockerVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  get isExportFacilityBlockersVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_READ);
  }

  mounted(): void {
    this.form = this.buildForm();

    this.store.getFacilityBlockersForFacility()
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetFacilityBlockers();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        date: {
          label: 'Datum',
          value: null,
          afterTransformationAndValidation: (date) => {
            this.store.updateSelectedDateForFacilityBlockers(date)
              .catch((error) => showErrorResponse(error));
          },
        },
        search: {
          label: 'Suche',
          value: null,
          afterTransformationAndValidation: (search) => {
            this.store.updateSearchForFacilityBlocker(search)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  selectAllToggleClicked(): void {
    const idsOfSelectedFacilityBlockers = this.store.idsOfSelectedFacilityBlockers.length === this.selectableFacilityBlockers.length
      ? []
      : this.selectableFacilityBlockers.map((facilityBlocker) => facilityBlocker.facilityBlockerId);

    this.store.updateIdsOfSelectedFacilityBlockers(idsOfSelectedFacilityBlockers)
      .catch((error) => showErrorResponse(error));
  }

  selectFacilityBlocker(facilityBlocker: FacilityBlocker, wasPreviouslySelected: boolean): void {
    const idsOfSelectedFacilityBlockers = wasPreviouslySelected
      ? this.store.idsOfSelectedFacilityBlockers.filter((facilityBlockerId) => facilityBlockerId !== facilityBlocker.facilityBlockerId)
      : [...this.store.idsOfSelectedFacilityBlockers, facilityBlocker.facilityBlockerId];

    this.store.updateIdsOfSelectedFacilityBlockers(idsOfSelectedFacilityBlockers)
      .catch((error) => showErrorResponse(error));
  }

  onLoadMoreClicked(): void {
    this.store.increaseFacilityBlockersLimit()
      .catch((error) => showErrorResponse(error));
  }

  async deleteSelectedFacilityBlockersAction(): Promise<void> {
    const command: DeleteFacilityBlockersAsManagerCommand = {
      facilityBlockerIds: this.store.idsOfSelectedFacilityBlockers,
    };

    return this.store.deleteFacilityBlockers(command)
      .then(() => showSuccessMessage('Die Sperrzeiten wurden gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  isFacilityBlockerEndInThePast(facilityBlocker: FacilityBlocker): boolean {
    return moment().isAfter(facilityBlocker.to);
  }

  isUpdateDisabled(facilityBlocker: FacilityBlocker): boolean {
    return this.store.idsOfSelectedFacilityBlockers.length > 0
      || this.isFacilityBlockerEndInThePast(facilityBlocker);
  }

  isDeleteDisabled(facilityBlocker: FacilityBlocker): boolean {
    return this.store.idsOfSelectedFacilityBlockers.length > 0
      || this.isFacilityBlockerEndInThePast(facilityBlocker);
  }

  updateDisabledTooltip(facilityBlocker: FacilityBlocker): string {
    if (this.store.idsOfSelectedFacilityBlockers.length > 0) {
      return 'Die Sperrzeit kann nicht bearbeitet werden, da die Mehrfachauswahl aktiv ist.';
    }

    if (this.isFacilityBlockerEndInThePast(facilityBlocker)) {
      return 'Die Sperrzeit kann nicht bearbeitet werden, da sie bereits abgelaufen ist.';
    }

    // Triggered on load
    return '';
  }

  deleteDisabledTooltip(facilityBlocker: FacilityBlocker): string {
    if (this.store.idsOfSelectedFacilityBlockers.length > 0) {
      return 'Die Sperrzeit kann nicht gelöscht werden, da die Mehrfachauswahl aktiv ist.';
    }

    if (this.isFacilityBlockerEndInThePast(facilityBlocker)) {
      return 'Die Sperrzeit kann nicht gelöscht werden, da sie bereits abgelaufen ist.';
    }

    // Triggered on load
    return '';
  }

  spacesDescription(facilityBlocker: FacilityBlocker): string {
    return facilityBlocker.blocksSpaces
      ? `${facilityBlocker.blocksSpaces} von ${this.store.currentFacility?.spaces}`
      : 'Alle';
  }

  hideCreateMenu(): void {
    this.isCreateMenuVisible = false;
  }

  hideFacilityBlockerMenu(facilityBlocker: FacilityBlocker): void {
    this.facilityBlockerMenuList[facilityBlocker.facilityBlockerId] = false;
  }

  exportFacilityBlockers(): void {
    const fileName = `Sperrzeiten-${Date.today().format('YYYY-MM-DD')}.csv`;
    this.store.getFacilityBlockersForFacilityAsCsv()
      .then((csv) => downloadCSVFile(csv, fileName))
      .catch((error) => showErrorResponse(error));
  }

}
