
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission, isFeatureEnabled } from '@/application/authentication/helper';
import { ArchivedStatus, Date, FarmManagerPermission, Feature, OrganizationId } from '@/types';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { formatDate } from '@/helpers';
import { navigate } from '@/helpers/navigation-helpers';
import { formatName } from '@/helpers';
import { downloadCSVFile } from '@/helpers/file-download-helper';
import { useCustomerMasterDataManagementStore } from '../store';
import { OrganizationForList } from '../types';
import CreateOrganizationDialog from './create-organization-dialog.vue';
import UpdateNameOfOrganizationDialog from './update-name-of-organization-dialog.vue';
import UpdateAddressOfOrganizationDialog from './update-address-of-organization-dialog.vue';
import UpdateInvoiceAddressOfOrganizationDialog from './update-invoice-address-of-organization-dialog.vue';
import UpdateAdditionalMasterDataOfOrganizationDialog from './update-additional-master-data-of-organization-dialog.vue';
import UpdateAssignedPersonsOfOrganizationDialog from './update-assigned-persons-of-organization-dialog.vue';
import FilterOrganizationsDialog from './filter-organizations-dialog.vue';
import ArchiveOrganizationDialog from './archive-organization-dialog.vue';
import ArchivePersonDialog from '@/private/management/customer-master-data/components/archive-person-dialog.vue';

interface Controls extends FormControls {
  search: FormControl<string>;
}

@Component({
  components: {
    ArchivePersonDialog,
    FilterOrganizationsDialog,
    CreateOrganizationDialog,
    UpdateNameOfOrganizationDialog,
    UpdateAddressOfOrganizationDialog,
    UpdateInvoiceAddressOfOrganizationDialog,
    UpdateAdditionalMasterDataOfOrganizationDialog,
    UpdateAssignedPersonsOfOrganizationDialog,
    ArchiveOrganizationDialog,
  },
})
export default class OrganizationList extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly updateOrganisationMenuList: Record<OrganizationId, boolean> = {};

  get isCreateOrganizationVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  get isUpdateInvoiceAddressVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE)
      && isFeatureEnabled(Feature.LEDGER_INVOICING);
  }

  get isLoadMoreButtonVisible(): boolean {
    return this.store.totalCountOfOrganizations !== null;
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.store.totalCountOfOrganizations === null
      || this.store.organizations.length >= this.store.totalCountOfOrganizations;
  }

  get areOrganizationsFiltered(): boolean {
    return this.store.filterForOrganizations.addressCountry !== null
      || this.store.filterForOrganizations.invoiceAddressCountry !== null
      || this.store.filterForOrganizations.language !== null
      || this.store.filterForOrganizations.customFields !== null
      || this.store.filterForOrganizations.archivedStatus !== ArchivedStatus.NOT_ARCHIVED;
  }

  form: Form<Controls> | null = null;

  mounted(): void {
    this.form = this.buildForm();

    this.store.getOrganizations()
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetLimitForOrganizations()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
          afterTransformationAndValidation: (search) => this.store.updateSearchForOrganizations(search),
        },
      },
    });
  }

  note(organization: OrganizationForList): string {
    const historyEntry = organization.changeHistory.length > 1
      ? organization.changeHistory[organization.changeHistory.length - 1]
      : organization.changeHistory[0];

    return organization.changeHistory.length > 1
      ? `Angepasst am ${formatDate(historyEntry.changedAt)} von ${formatName(historyEntry.nameOfUser)}`
      : `Erstellt am ${formatDate(historyEntry.changedAt)} von ${formatName(historyEntry.nameOfUser)}`;
  }

  onLoadMoreClicked(): void {
    this.store.increaseLimitForOrganizations()
      .catch((error) => showErrorResponse(error));
  }

  organizationClicked(organization: OrganizationForList): void {
    navigate({ name: 'customer-master-data-organization-details', params: { organizationId: organization.organizationId }});
  }

  organizationUpdated(): void {
    this.store.getOrganizations()
      .catch((error) => showErrorResponse(error));
  }

  exportOrganizations(): void {
    const fileName = `Organisationsexport-${Date.today().format('YYYY-MM-DD')}.csv`;
    this.store.getOrganizationsAsCSV()
      .then((csv) => downloadCSVFile(csv, fileName))
      .catch((error) => showErrorResponse(error));
  }

  isUpdateOrganizationVisible(organization: OrganizationForList): boolean {
    return !organization.isArchived
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

}
