
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import CreatePaddockDialog from './create-paddock-dialog.vue';
import UpdatePaddockDialog from './update-paddock-dialog.vue';
import { DeletePaddockAsManagerCommand, PaddockPlanPaddock } from '../types';
import { usePaddockPlanManagementStore } from '../store';

@Component({
  components: {
    CreatePaddockDialog,
    UpdatePaddockDialog,
  },
})
export default class ManagePaddockPlanPaddocks extends Vue {

  readonly store = usePaddockPlanManagementStore();

  get isCreatePaddockVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PADDOCK_PLAN_WRITE);
  }

  get isUpdatePaddockVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PADDOCK_PLAN_WRITE);
  }

  get isDeletePaddockVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PADDOCK_PLAN_WRITE);
  }

  deletePaddockAction(paddock: PaddockPlanPaddock): Promise<void> {
    const command: DeletePaddockAsManagerCommand = {
      paddockPlanPaddockId: paddock.paddockPlanPaddockId,
    };

    return this.store.deletePaddock(command)
      .then(() => showSuccessMessage('Koppel wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
