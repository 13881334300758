
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { formatDate, formatTime } from '@/helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { isMobileSafari, isNativeApplication } from '@/helpers/detection-helpers';
import { downloadCSVFile } from '@/helpers/file-download-helper';
import SettlementDetailsDialog from './settlement-details-dialog.vue';
import { Month, NameOrderType, PersonId } from '@/types';
import { moment } from '@/helpers';
import { useLedgerStore } from '../store';
import { Booking, GetSettlementsForMonthAsCSVAsManagerQuery, PersonWithSettlement, Settlement } from '../types';
import { categoriesFromSettlement } from '../helper';

interface Controls extends FormControls {
  selectedMonth: FormControl<Month>;
  selectedPerson: FormControl<PersonId>;
  nameOrderType: FormControl<NameOrderType>;
}

@Component({
  components: {
    SettlementDetailsDialog,
  },
})
export default class SettlementsAdminComponent extends Vue {

  readonly store = useLedgerStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Person', value: 'person' },
    { text: 'Überweisung', value: 'bankTransfer' },
    { text: 'Lastschrift', value: 'directDebit' },
    { text: 'Kategorien', value: 'categories' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  readonly isNativeApplication = isNativeApplication;
  readonly categoriesFromSettlement = categoriesFromSettlement;

  form: Form<Controls> | null = null;

  get isCSVExportVisible(): boolean {
    return !isNativeApplication() && !isMobileSafari();
  }

  get isUpdatedAtVisible(): boolean {
    return this.store.settlementsForMonth !== null && this.form !== null;
  }

  get persons(): PersonWithSettlement[] {
    return this.store.settlementsForMonth !== null
      ? this.store.settlementsForMonth.persons
      : [];
  }

  get translatedSelectedMonth(): string {
    if (!this.form) {
      return '';
    }

    const formValues = getFormValues(this.form);
    if (!formValues.selectedMonth) {
      return '';
    }

    return moment(formValues.selectedMonth).format('MMMM');
  }

  get updatedAtHTML(): string {
    if (this.store.settlementsForMonth === null
      || !this.form
    ) {
      return '';
    }

    const formValues = getFormValues(this.form);
    if (!formValues.selectedMonth) {
      return '';
    }

    // eslint-disable-next-line max-len
    const updatedAtHTML = `Die Abrechnung für ${this.translatedSelectedMonth} wurde zuletzt <strong>am ${formatDate(this.store.settlementsForMonth!.lastUpdatedAt)} um ${formatTime(this.store.settlementsForMonth!.lastUpdatedAt)} Uhr</strong> aktualisiert.`;

    return moment()
      .startOf('month')
      .isSame(formValues.selectedMonth, 'month')
      ? `${updatedAtHTML} Die Daten werden ungefähr alle 15 Minuten aktualisiert.`
      : updatedAtHTML;
  }

  mounted(): void {
    const initialSelectedMonth = moment().format('YYYY-MM') as Month;

    this.form = this.buildForm(initialSelectedMonth);

    this.store.updateSelectedMonthForSettlements(initialSelectedMonth)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(initialSelectedMonth: Month): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        selectedMonth: {
          label: 'Monat',
          value: initialSelectedMonth,
          afterTransformationAndValidation: (value) => {
            if (!value) {
              return;
            }

            this.store.updateSelectedMonthForSettlements(value)
              .catch((error) => showErrorResponse(error));
          },
        },
        selectedPerson: {
          label: 'Person',
          value: this.store.selectedPersonForSettlements,
          afterTransformationAndValidation: (value) => this.store.updateSelectedPersonForSettlements(value)
            .catch((error) => showErrorResponse(error)),
        },
        nameOrderType: {
          label: 'Namensreihenfolge und -darstellung',
          value: this.store.selectedNameOrderType,
          afterTransformationAndValidation: (nameOrderType) => this.store.updateSelectedNameOrderType(nameOrderType!)
            .catch((error) => showErrorResponse(error)),
        },
      },
    });
  }

  isMonthAllowed(month: Month): boolean {
    const startOfCurrentMonth = moment().startOf('month');

    return moment(month).isSameOrBefore(startOfCurrentMonth);
  }

  exportSettlement(): void {
    const formValues = getFormValues(this.form!);

    const query: GetSettlementsForMonthAsCSVAsManagerQuery = {
      month: formValues.selectedMonth!,
    };

    const fileName = `Abrechnung-${formValues.selectedMonth!}.csv`;

    this.store.getSettlementsForMonthAsCSV(query)
      .then((csv) => downloadCSVFile(csv, fileName))
      .then(() => showSuccessMessage('Die Abrechnung wurden exportiert.'))
      .catch((error) => showErrorResponse(error));
  }

  calculateBankTransferCosts(settlement: Settlement): number {
    return this.getBookings(settlement)
      .filter((booking) => booking.paymentMethod === 'bank-transfer')
      .reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0);
  }

  calculateDirectDebitCosts(settlement: Settlement): number {
    return this.getBookings(settlement)
      .filter((booking) => booking.paymentMethod === 'direct-debit')
      .reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0);
  }

  getBookings(settlement: Settlement): Booking[] {
    let bookings: Booking[] = [];
    bookings = bookings.concat(
      settlement.boxPlan ? settlement.boxPlan.bookings : [],
      settlement.feeding ? settlement.feeding.bookings : [],
      settlement.horseWalker ? settlement.horseWalker.bookings : [],
      settlement.bedding ? settlement.bedding.bookings : [],
      settlement.customBooking ? settlement.customBooking.bookings : [],
      settlement.creditNote ? settlement.creditNote.bookings : [],
      settlement.farmServiceSubscription ? settlement.farmServiceSubscription.bookings : [],
      settlement.farmService ? settlement.farmService.bookings : []
    );

    return bookings;
  }
}
