
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useRiderTasksStore } from '../store';
import { Task, WithdrawTaskCommand } from '../types';

@Component
export default class WithdrawTaskDialog extends Vue {

  readonly store = useRiderTasksStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly task!: Task;

  isDialogVisible = false;

  withdrawTaskConfirmed(): void {
    const command: WithdrawTaskCommand = {
      riderTaskId: this.task.riderTaskId,
    };

    this.store.withdrawTask(command)
      .then(() => showSuccessMessage('Die Aufgabe wurde zurückgezogen'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
