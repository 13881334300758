
import { Vue, Component } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { NewsEntry } from '@/application/authentication/types';
import { DialogWidth } from '@/helpers/data';
import { usePinboardStore } from '../store';
import { MarkNewsEntryAsSeenCommand } from '../types';

@Component
export default class ImportantNewsDialog extends Vue {

  readonly store = usePinboardStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  newsEntry: NewsEntry | null = null;

  show(): void {
    this.loadNextImportantUnreadNews();
  }

  loadNextImportantUnreadNews(): void {
    if (this.authenticationStore.importantUnreadNews.length === 0) {
      this.closeDialog();
      return;
    }

    this.newsEntry = this.authenticationStore.importantUnreadNews[0];

    this.isDialogVisible = true;
  }

  markNewsEntryAsSeenClicked(): void {
    const command: MarkNewsEntryAsSeenCommand = {
      newsEntryId: this.newsEntry!.newsEntryId,
    };

    this.store.markNewsEntryAsSeen(command)
      .then(() => this.authenticationStore.getAuthentication())
      .then(() => this.loadNextImportantUnreadNews())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
