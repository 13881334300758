
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { RidingLessonIdList, RidingLessonRegistrationType, UserId } from '@/types';
import { useRidingLessonManagementStore } from '../store';
import { GetRidingLessonsForSeriesRegistrationAsManagerQuery, RegisterPersonForRidingLessonSeriesAsManagerCommand, RidingLessonSeries } from '../types';

interface Controls extends FormControls {
  type: FormControl<RidingLessonRegistrationType>;
  user: FormControl<UserId>;
  ridingLessons: FormControl<RidingLessonIdList>;
  nameOfAnotherPerson: FormControl<string>;
  comment: FormControl<string>;
}

@Component
export default class RegisterPersonForRidingLessonSeriesDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly ridingLessonSeries!: RidingLessonSeries;

  readonly ridingLessonRegistrationTypeTranslations: Record<RidingLessonRegistrationType, string> = {
    USER: 'einen Benutzer anmelden',
    IN_NAME_OF_ANOTHER_PERSON: 'eine andere Person anmelden',
  };

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isUserVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.type === RidingLessonRegistrationType.USER;
  }

  get isNameOfAnotherPersonVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.type === RidingLessonRegistrationType.IN_NAME_OF_ANOTHER_PERSON;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        const query: GetRidingLessonsForSeriesRegistrationAsManagerQuery = {
          ridingLessonSeriesId: this.ridingLessonSeries.ridingLessonSeriesId,
        };
        Promise.all([
          this.store.getUsersForRidingLessonRegistration(),
          this.store.getRidingLessonsForSeriesRegistration(query),
        ])
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => {
            showErrorResponse(error);
            this.closeDialog();
          });
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        type: {
          label: 'Ich möchte',
          value: RidingLessonRegistrationType.USER,
          isRequired: true,
          validateFormControlsOnInput: [
            'user',
            'nameOfAnotherPerson',
          ],
        },
        user: {
          label: 'Benutzer',
          value: null,
          rules: [],
        },
        nameOfAnotherPerson: {
          label: 'Name der Person',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        ridingLessons: {
          label: 'Unterrichtsstunden',
          value: null,
          isRequired: true,
          rules: [],
        },
        comment: {
          label: 'Kommentar',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });

    // Conditional required
    form.controls.nameOfAnotherPerson.rules!.push(
      () => form.controls.type.value === RidingLessonRegistrationType.USER
        || form.controls.nameOfAnotherPerson.value !== null
        || 'Bitte gebe den Namen der Person an, für den du dich anmelden möchtest.'
    );
    form.controls.user.rules!.push(
      () => form.controls.type.value === RidingLessonRegistrationType.IN_NAME_OF_ANOTHER_PERSON
        || form.controls.user.value !== null
        || 'Bitte wähle den Benutzer aus der angemeldet werden soll.'
    );

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: RegisterPersonForRidingLessonSeriesAsManagerCommand = {
      ridingLessonSeriesId: this.ridingLessonSeries.ridingLessonSeriesId,
      idsOfRidingLessons: formValues.ridingLessons!,
      type: formValues.type!,
      idOfTargetUser: formValues.type === RidingLessonRegistrationType.USER
        ? formValues.user
        : null,
      nameOfAnotherPerson: formValues.type === RidingLessonRegistrationType.IN_NAME_OF_ANOTHER_PERSON
        ? formValues.nameOfAnotherPerson
        : null,
      comment: formValues.comment,
    };
    this.store.registerPersonForRidingLessonSeries(command)
      .then(() => showSuccessMessage('Die Person wurde angemeldet.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
