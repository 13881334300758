
import { Component, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifySelectItem, VuetifyValidatable } from '@/application/types';
import { generateTimeRangeSelectItems } from '@/helpers/form-helpers';
import { DialogWidth } from '@/helpers/data';
import { useFacilityReservationsManagementStore } from '../store';
import { UpdateMaxUsageOfFacilityAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    maxIntervalsForHorseAndDay: FormField<number>;
  };
}

@Component
export default class UpdateMaxUsageOfFacilityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Ref()
  readonly formElement?: VuetifyValidatable;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  maxIntervalsForHorseAndDaySelectItems: VuetifySelectItem<number>[] = [];

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.maxIntervalsForHorseAndDaySelectItems = generateTimeRangeSelectItems(
        this.store.currentFacility!.openingHours.timeFrom,
        this.store.currentFacility!.openingHours.timeTo,
        this.store.currentFacility!.facilityReservationConfiguration.timeOptionInterval
      );

      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (this.formElement) {
        this.formElement.resetValidation();
      }
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        maxIntervalsForHorseAndDay: {
          value: this.store.currentFacility?.facilityReservationConfiguration.maxIntervalsForHorseAndDay || 1,
          rules: [
            requiredRule(),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateMaxUsageOfFacilityAsManagerCommand = {
      facilityId: this.store.currentFacility!.facilityId,
      facilityMaxIntervalsForHorseAndDay: this.form!.fields.maxIntervalsForHorseAndDay.value,
    };
    this.store.updateMaxUsageOfFacility(command)
      .then(() => showSuccessMessage('Die Maximale Benutzung wurden angepasst'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
