import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { createEventRegistration, deleteEventRegistration, getEventsAndConfiguration } from './service';
import { Configuration, CreateEventRegistrationCommand, DeleteEventRegistrationCommand, Event } from './types';

interface FreeJumpingFreeRunningState {
  events: Event[];
  configuration: Configuration | null;
  currentEventId: string | null;

  getEventsAndConfigurationStatus: ActionStatus;
  createEventRegistrationStatus: ActionStatus;
  deleteEventRegistrationStatus: ActionStatus;
}

function initialState(): FreeJumpingFreeRunningState {
  return {
    events: [],
    configuration: null,
    currentEventId: null,

    getEventsAndConfigurationStatus: ActionStatus.None,
    createEventRegistrationStatus: ActionStatus.None,
    deleteEventRegistrationStatus: ActionStatus.None,
  };
}

export const useFreeJumpingFreeRunningStore = defineStore('freeJumpingFreeRunning', {
  state: (): FreeJumpingFreeRunningState => initialState(),
  getters: {
    isGetEventsAndConfigurationProcessing: (state: FreeJumpingFreeRunningState): boolean =>
      state.getEventsAndConfigurationStatus === ActionStatus.InProgress,
    isCreateEventRegistrationProcessing: (state: FreeJumpingFreeRunningState): boolean =>
      state.createEventRegistrationStatus === ActionStatus.InProgress,
    isDeleteEventRegistrationProcessing: (state: FreeJumpingFreeRunningState): boolean =>
      state.deleteEventRegistrationStatus === ActionStatus.InProgress,

    currentEvent: (state: FreeJumpingFreeRunningState): Event | null =>
      state.currentEventId === null
        ? null
        : state.events.find((event) => event.id === state.currentEventId) ?? null,
  },
  actions: {

    // -- State management

    updateCurrentEvent(eventId: string): Promise<void> {
      this.currentEventId = eventId;

      return this.getEventsAndConfiguration();
    },

    // -- Queries

    getEventsAndConfiguration(): Promise<void> {
      const { getEventsAndConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getEventsAndConfigurationStatus,
        () => getEventsAndConfiguration(attachFarmAndUserProperties({}))
          .then((eventsAndConfiguration) => {
            this.events = eventsAndConfiguration.events;
            this.configuration = eventsAndConfiguration.configuration;
          })
      );
    },

    // -- Commands

    createEventRegistration(command: CreateEventRegistrationCommand): Promise<void> {
      const { createEventRegistrationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createEventRegistrationStatus,
        () => createEventRegistration(attachFarmAndUserProperties(command))
          .then(() => this.getEventsAndConfiguration())
      );
    },

    deleteEventRegistration(command: DeleteEventRegistrationCommand): Promise<void> {
      const { deleteEventRegistrationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteEventRegistrationStatus,
        () => deleteEventRegistration(attachFarmAndUserProperties(command))
          .then(() => this.getEventsAndConfiguration())
      );
    },

  },
});
