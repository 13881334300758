
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { TimeFrame, Weekday } from '@/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useRidingLessonManagementStore } from '../store';
import { Facility, RidingLesson, UpdateWeekdayAndTimeFrameOfRidingLessonSeriesAsManagerCommand } from '../types';

interface Controls extends FormControls {
  weekday: FormControl<Weekday>;
  timeFrame: FormControl<TimeFrame>;
}

@Component
export default class UpdateWeekdayAndTimeFrameOfRidingLessonSeriesDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly ridingLesson!: RidingLesson;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get selectedFacility(): Facility | null {
    return this.store.facilities
      .find((facility) => facility.facilityId === this.ridingLesson.facility.facilityId) ?? null;
  }

  get timeFrameForFrom(): TimeFrame | null {
    return this.selectedFacility === null
      ? null
      : this.selectedFacility.openingHours;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        weekday: {
          label: 'Wochentag',
          value: this.ridingLesson.ridingLessonSeries!.weekday,
          isRequired: true,
        },
        timeFrame: {
          label: 'Zeitfenster',
          value: this.ridingLesson.ridingLessonSeries!.timeFrame,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateWeekdayAndTimeFrameOfRidingLessonSeriesAsManagerCommand = {
      ridingLessonId: this.ridingLesson.ridingLessonId,
      ridingLessonSeriesId: this.ridingLesson.ridingLessonSeries!.ridingLessonSeriesId,
      weekday: formValues.weekday!,
      timeFrame: formValues.timeFrame!,
    };

    this.store.updateWeekdayAndTimeFrameOfRidingLessonSeries(command)
      .then(() => showSuccessMessage('Wochentag und Uhrzeit wurden angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
