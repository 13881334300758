
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, maxLengthRule, minNumberRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { Color } from '@/types';
import { Station, UpdateStationAsManagerCommand } from '../types';
import { useFeedProtocolManagementStore } from '../store';

interface Controls extends FormControls {
  number: FormControl<number>;
  name: FormControl<string>;
  color: FormControl<Color>;
}

@Component
export default class UpdateStationDialog extends Vue {

  readonly store = useFeedProtocolManagementStore();

  @Prop({ type: Object, required: true })
  readonly station!: Station;

  readonly size = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        number: {
          label: 'Nummer (Stationsnummer in QANTO)',
          value: this.station.number,
          isRequired: true,
          rules: [
            minNumberRule(1),
            maxDecimalsNumberRule(0),
          ],
        },
        name: {
          label: 'Bezeichnung',
          value: this.station.name,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        color: {
          label: 'Farbe',
          value: this.station.color,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateStationAsManagerCommand = {
      feedProtocolStationId: this.station.feedProtocolStationId,
      number: formValues.number!,
      name: formValues.name!,
      color: formValues.color!,
    };

    this.store.updateStation(command)
      .then(() => showSuccessMessage('Die Station wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
