
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useAuthenticationStore } from '@/application/authentication/store';
import { Name, Address, Salutation } from '@/types';
import { useMyStableStore } from '../store';
import { DefineInvoiceAddressCommand } from '../types';

interface Controls extends FormControls {
  company: FormControl<string>;
  salutation: FormControl<Salutation>;
  name: FormControl<Name>;
  address: FormControl<Address>;
}

@Component
export default class DefineInvoiceAddressDialog extends Vue {

  readonly store = useMyStableStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        company: {
          label: 'Firmennamen',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        salutation: {
          label: 'Anrede',
          value: null,
        },
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
        },
        address: {
          label: 'Adresse',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: DefineInvoiceAddressCommand = {
      personId: this.authenticationStore.user!.personId,
      invoiceAddress: {
        company: formValues.company,
        salutation: formValues.salutation,
        name: formValues.name!,
        address: formValues.address!,
      },
    };

    this.store.defineInvoiceAddress(command)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
