
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { UpdateVaccinationNoticeCommand, Vaccination } from '../types';
import { useMyStableStore } from '../store';

interface Form extends StrictFormDefinition {
  fields: {
    notice: FormField<string|null>;
  },
}

@Component
export default class UpdateVaccinationNoticeDialog extends Vue {

  readonly store = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly vaccination!: Vaccination;

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  get isDialogDisabled(): boolean {
    return this.store.vaccinationCertificate === null;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        notice: {
          value: this.vaccination.notice,
          rules: [],
        },
      },
    };
  }

  formSubmitted(): void {
    const command: UpdateVaccinationNoticeCommand = {
      vaccinationId: this.vaccination.vaccinationId,
      notice: this.form!.fields.notice.value,
    };

    this.store.updateVaccinationNotice(command)
      .then(() => showSuccessMessage('Notiz wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
