
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { constructForm, FormControl, Form, getFormValues, maxLengthRule, FormControls } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { RidingLessonRegistration, UpdateCommentOfRidingLessonRegistrationAsManagerCommand } from '../types';
import { useRidingLessonManagementStore } from '../store';

interface Controls extends FormControls {
  comment: FormControl<string>;
}

@Component
export default class UpdateCommentOfRidingLessonRegistrationDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly ridingLessonRegistration!: RidingLessonRegistration;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        comment: {
          label: 'Kommentar',
          value: this.ridingLessonRegistration.comment,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateCommentOfRidingLessonRegistrationAsManagerCommand = {
      ridingLessonRegistrationId: this.ridingLessonRegistration.ridingLessonRegistrationId,
      comment: formValues.comment,
    };
    this.store.updateCommentOfRidingLessonRegistration(command)
      .then(() => showSuccessMessage('Das Kommentar wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
