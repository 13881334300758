
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalStringFormControlRule } from '@/components/form';
import { useGlobalUserManagementStore } from '../store';
import { UpdateUserPasswordAsAdminCommand, User } from '../types';

interface Controls extends FormControls {
  password: FormControl<string>;
  repeatPassword: FormControl<string>;
}

@Component
export default class UpdateUserPasswordDialog extends Vue {

  readonly store = useGlobalUserManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly user!: User;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        password: {
          label: 'Passwort',
          value: null,
          rules: [],
          isRequired: true,
          validateFormControlsOnInput: [
            'repeatPassword',
          ],
        },
        repeatPassword: {
          label: 'Passwort wiederholen',
          value: null,
          rules: [],
          isRequired: true,
          validateFormControlsOnInput: [
            'password',
          ],
        },
      }
    });

    form.controls.password.rules!.push(identicalStringFormControlRule(form.controls.repeatPassword, 'Passwörter müssen übereinstimmen'));
    form.controls.repeatPassword.rules!.push(identicalStringFormControlRule(form.controls.password, 'Passwörter müssen übereinstimmen'));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateUserPasswordAsAdminCommand = {
      farmId: this.user.farm.farmId,
      targetUserId: this.user.userId,
      password: formValues.password!,
    };

    this.store.updateUserPassword(command)
      .then(() => showSuccessMessage('Passwort wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
