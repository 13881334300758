
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { DataListItem } from '@/components/design-system';
import { useLedgerStore } from '../store';
import { InvoiceInformation } from '../types';
import DefineInvoiceInformationDialog from './define-invoice-information-dialog.vue';
import UpdateFarmAddressDialog from './update-farm-address-dialog.vue';
import UpdateFarmLegalInformationDialog from './update-farm-legal-information-dialog.vue';
import DefineFarmBankInformationDialog from './define-farm-bank-information-dialog.vue';
import UpdateFarmBankInformationDialog from './update-farm-bank-information-dialog.vue';
import DefineFarmTermsOfPaymentDialog from './define-farm-terms-of-payment-dialog.vue';
import UpdateFarmTermsOfPaymentDialog from './update-farm-terms-of-payment-dialog.vue';
import { formatFarmAddress } from '@/helpers';

@Component({
  components: {
    DefineInvoiceInformationDialog,
    UpdateFarmAddressDialog,
    UpdateFarmLegalInformationDialog,
    DefineFarmBankInformationDialog,
    UpdateFarmBankInformationDialog,
    DefineFarmTermsOfPaymentDialog,
    UpdateFarmTermsOfPaymentDialog,
  },
})
export default class ConfigurationForInvoices extends Vue {

  readonly store = useLedgerStore();

  @Prop({ type: Object, required: true })
  readonly invoiceInformation!: InvoiceInformation;

  isUpdateFarmBankInformationMenuVisible = false;
  isUpdateFarmTermsOfPaymentMenuVisible = false;

  get isDefineInvoiceInformationVisible(): boolean {
    return !this.invoiceInformation.isInvoiceInformationDefined
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_WRITE);
  }

  get isUpdateFarmAddressVisible(): boolean {
    return !!this.invoiceInformation.farmAddress
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_WRITE);
  }

  get isDefineFarmBankInformationVisible(): boolean {
    return !this.invoiceInformation.farmBankInformation
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_WRITE);
  }

  get isUpdateFarmBankInformationVisible(): boolean {
    return !!this.invoiceInformation.farmBankInformation
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_WRITE);
  }

  get isRemoveFarmBankInformationVisible(): boolean {
    return !!this.invoiceInformation.farmBankInformation
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_WRITE);
  }

  get isUpdateFarmLegalInformationVisible(): boolean {
    return !!this.invoiceInformation.farmLegalInformation
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_WRITE);
  }

  get isDefineFarmTermsOfPaymentVisible(): boolean {
    return !this.invoiceInformation.farmTermsOfPayment
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_WRITE);
  }

  get isUpdateFarmTermsOfPaymentVisible(): boolean {
    return !!this.invoiceInformation.farmTermsOfPayment
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_WRITE);
  }

  get isRemoveFarmTermsOfPaymentVisible(): boolean {
    return !!this.invoiceInformation.farmTermsOfPayment
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_WRITE);
  }

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'farmAddress',
        label: 'Adresse des Hofs',
        value: this.invoiceInformation.farmAddress
          ? formatFarmAddress(this.invoiceInformation.farmAddress)
          : '-',
      }, {
        key: 'farmBankInformation',
        label: 'Bankverbindung',
        value: this.invoiceInformation.farmBankInformation
          // eslint-disable-next-line max-len
          ? `${this.invoiceInformation.farmBankInformation.accountHolder}<br />${this.invoiceInformation.farmBankInformation.iban}<br />${this.invoiceInformation.farmBankInformation.bankName}`
          : '-',
      }, {
        key: 'farmLegalInformation',
        label: 'Rechtliche Informationen',
        value: this.invoiceInformation.farmLegalInformation
          // eslint-disable-next-line max-len
          ? `Steuernummer: ${this.invoiceInformation.farmLegalInformation.taxNumber ? this.invoiceInformation.farmLegalInformation.taxNumber : '-'}<br />Umsatzsteuer ID: ${this.invoiceInformation.farmLegalInformation.vatNumber ? this.invoiceInformation.farmLegalInformation.vatNumber : '-'}<br />Registernummer: ${this.invoiceInformation.farmLegalInformation.registerNumber ? this.invoiceInformation.farmLegalInformation.registerNumber : '-'}<br />Registergericht: ${this.invoiceInformation.farmLegalInformation.registrationCourt ? this.invoiceInformation.farmLegalInformation.registrationCourt : '-'}`
          : '-',
      }, {
        key: 'farmTermsOfPayment',
        label: 'Zahlungsbedingungen',
        value: this.invoiceInformation.farmTermsOfPayment
          ? this.invoiceInformation.farmTermsOfPayment
          : '-',
      },
    ];
  }

  async removeFarmBankInformationAction(): Promise<void> {
    return this.store.removeFarmBankInformation()
      .catch((error) => showErrorResponse(error));
  }

  async removeFarmTermsOfPaymentAction(): Promise<void> {
    return this.store.removeFarmTermsOfPayment()
      .catch((error) => showErrorResponse(error));
  }

}
