
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { formatDate } from '@/helpers';
import { useAppStore } from '@/application/app/store';
import { usePinboardStore } from '../store';
import { MarkNewsEntryAsSeenCommand, NewsEntry } from '../types';

@Component
export default class NewsDetails extends Vue {

  readonly store = usePinboardStore();
  readonly appStore = useAppStore();

  @Prop({ type: Object, required: true })
  readonly newsEntry!: NewsEntry;

  mounted(): void {
    const formattedDate = formatDate(this.newsEntry.date);
    this.appStore.updateTitle(formattedDate)
      .catch((error) => showErrorResponse(error));

    const command: MarkNewsEntryAsSeenCommand = {
      newsEntryId: this.newsEntry.newsEntryId,
    };
    this.store.markNewsEntryAsSeen(command)
      .catch((error) => showErrorResponse(error));
  }

}
