
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { CustomerType, CustomFieldType, Options } from '@/types';
import { CreateCustomFieldAsManagerCommand } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  label: FormControl<string>;
  type: FormControl<CustomFieldType>;
  options: FormControl<Options>;
}

@Component
export default class CreateDynamicFieldDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: String, required: true })
  readonly customerType!: CustomerType;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isOptionsFormControlVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.type === CustomFieldType.SELECT
      || formValues.type === CustomFieldType.MULTI_SELECT;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        label: {
          label: 'Bezeichnung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(50),
          ],
        },
        type: {
          label: 'Typ',
          value: CustomFieldType.TEXT,
          isRequired: true,
          validateFormControlsOnInput: [
            'options',
          ],
        },
        options: {
          label: 'Optionen',
          value: null,
          rules: [],
        },
      },
    });

    form.controls.options.rules!.push((value) => value !== null
      || (form.controls.type.value !== CustomFieldType.SELECT
        && form.controls.type.value !== CustomFieldType.MULTI_SELECT
      ) ? true
      : 'Mindestens eine Option muss eingegeben werden'
    );

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateCustomFieldAsManagerCommand = {
      customerType: this.customerType,
      label: formValues.label!,
      type: formValues.type!,
      options: formValues.type === CustomFieldType.SELECT
        || formValues.type === CustomFieldType.MULTI_SELECT
        ? formValues.options!
        : null,
    };

    this.store.createCustomField(command)
      .then(() => showSuccessMessage('Das benutzerdefinierte Feld wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
