
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FarmManagerPermission } from '@/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { usePenManagementStore } from '../store';
import CreatePenStatusEntryDialog from './create-pen-status-entry-dialog.vue';
import UpdatePenStatusEntryDialog from './update-pen-status-entry-dialog.vue';
import { DeletePenStatusEntryAsManagerCommand, PenStatusEntry } from '@/private/management/pen/types';

@Component({
  components: {
    CreatePenStatusEntryDialog,
    UpdatePenStatusEntryDialog,
  },
})
export default class PaddockStatusHistoryTable extends Vue {

  readonly store = usePenManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Datum', value: 'date' },
    { text: 'Sind die Pferde heute auf der Koppel?', value: 'status' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  get isCreatePenStatusEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PEN_WRITE);
  }

  get isUpdatePenStatusEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PEN_WRITE);
  }

  get isDeletePenStatusEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PEN_WRITE);
  }

  mounted(): void {
    this.store.getPenStatusEntries()
      .catch((error) => showErrorResponse(error));
  }

  async deletePenStatusEntryAction(penStatusEntry: PenStatusEntry): Promise<void> {
    const command: DeletePenStatusEntryAsManagerCommand = {
      penStatusEntryId: penStatusEntry.penStatusEntryId,
    };

    return this.store.deletePenStatusEntry(command)
      .then(() => showSuccessMessage('Der Koppelstatus wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
