
import { Component, Vue } from 'vue-property-decorator';
import { useFacilityManagementStore } from '../store';
import { OverlappingFacilityBlocker, FacilityReservationToDelete, OverlappingRidingLesson } from '../types';

@Component
export default class OverlappingFacilityEntries extends Vue {

  readonly store = useFacilityManagementStore();

  get overlappingFacilityBlockers(): OverlappingFacilityBlocker[] {
    return !!this.store.overlappingFacilityEntries
      ? this.store.overlappingFacilityEntries.facilityBlockers
      : [];
  }

  get overlappingRidingLessons(): OverlappingRidingLesson[] {
    return !!this.store.overlappingFacilityEntries
      ? this.store.overlappingFacilityEntries.ridingLessons
      : [];
  }

  get facilityReservationsToDelete(): FacilityReservationToDelete[] {
    return !!this.store.overlappingFacilityEntries
      ? this.store.overlappingFacilityEntries.facilityReservationsToDelete
      : [];
  }

}
