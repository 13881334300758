
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { navigate } from '@/helpers/navigation-helpers';
import OrganizationDetails from './organization-details.vue';
import { useCustomerMasterDataManagementStore } from '../store';

@Component({
  components: {
    OrganizationDetails,
  },
})
export default class OrganizationDetailsLoadingWrapper extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  mounted(): void {
    watch(() => this.$route, () => {
      this.store.updateCurrentOrganization(this.$route.params.organizationId)
        .catch((error) => {
          showErrorResponse(error);
          navigate({ name: 'customer-master-data-organization-list' });
        });

      if (!this.store.customFields) {
        this.store.getCustomFields()
          .catch((error) => showErrorResponse(error));
      }
    }, { immediate: true });
  }

  destroyed(): void {
    this.store.resetCurrentOrganization()
      .catch((error) => showErrorResponse(error));
  }

}
