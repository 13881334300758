
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isNativeApplication } from '@/helpers/detection-helpers';

/**
 * The event `close-icon-menu` must be send from the list item to the $root to close the menu when it's itself a slot.
 *
 * A full example might look like this:
 *
 * <icon-menu small :icon="['far', 'pencil']">
 *   <edit-module-title-dialog :module="module">
 *     <v-list-item @click="$root.$emit('close-icon-menu')">
 *       <v-list-item-title>Bezeichnung anpassen</v-list-item-title>
 *     </v-list-item>
 *   </edit-module-title-dialog>
 *   <v-list-item :to="{ name: 'manage-modules/content-management', params: { id: module.id }}">
 *     <v-list-item-title>Inhalte bearbeiten</v-list-item-title>
 *   </v-list-item>
 * </icon-menu>
 */

@Component
export default class IconMenu extends Vue {

  @Prop({ type: Array, default: () => ['far', 'exclamation-circle'] })
  readonly icon!: string[];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String })
  readonly tooltip!: string;

  @Prop({ type: String, default: '' })
  readonly disabledTooltip!: string;

  @Prop({ type: String })
  readonly text!: string;

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly statusIcon!: boolean;

  isMenuOpen = false;

  get iconSize(): null|string {
    return this.small ? null : 'lg';
  }

  mounted(): void {
    this.$root.$on('close-icon-menu', () => {
      this.isMenuOpen = false;
    });
  }

  get isTooltipDisabled(): boolean {
    return isNativeApplication() || (!this.tooltip && !this.$slots.tooltip && !this.disabledTooltip && !this.$slots.disabledTooltip);
  }

}
