
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { FormField, StrictFormDefinition } from '@/application/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useLaborServicesManagementStore } from '../store';
import { MarkTimeEntryAsApprovedAsManagerCommand, TimeEntry } from '../types';
import RejectTimeEntryDialog from './reject-time-entry-dialog.vue';
import { sortUser } from '../helper';

interface Form extends StrictFormDefinition {
  fields: {
    search: FormField<string|null>;
  }
}

@Component({
  components: {
    RejectTimeEntryDialog,
  },
})
export default class UnhandledTimeEntries extends Vue {

  readonly store = useLaborServicesManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    {
      text: 'Benutzer',
      value: 'user',
      sortable: true,
      sort: sortUser,
    }, {
      text: 'Datum',
      value: 'date',
      sortable: true,
    }, {
      text: 'Stundenanzahl',
      value: 'numberOfHours',
      sortable: false,
    }, {
      text: 'Beschreibung',
      value: 'description',
      sortable: false,
    }, {
      text: 'Aktionen',
      value: 'actions',
      width: 95,
      align: 'end',
      sortable: false,
    },
  ];

  form: Form | null = null;

  get isApproveTimeEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LABOR_SERVICE_WRITE);
  }

  get isRejectTimeEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LABOR_SERVICE_WRITE);
  }

  mounted(): void {
    this.form = this.buildForm();

    this.store.getTimeRecordings()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        search: {
          value: null,
          rules: [],
        },
      },
    };
  }

  approveTimeEntryAction(timeEntry: TimeEntry): Promise<void> {
    const command: MarkTimeEntryAsApprovedAsManagerCommand = {
      laborServiceTimeEntryId: timeEntry.laborServiceTimeEntryId,
    };

    return this.store.markTimeEntryAsApproved(command)
      .then(() => showSuccessMessage('Der verrichtete Arbeitsdienst wurde abgezeichnet.'))
      .catch((error) => showErrorResponse(error));
  }

  filterTimeEntries(value: any, search: string | null, timeEntry: TimeEntry): boolean {
    if (search === null) {
      return true;
    }

    const searchLowerCase = search.toLocaleLowerCase();

    if (timeEntry.user.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (timeEntry.description && timeEntry.description.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    return false;
  }

}
