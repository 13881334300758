
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { vaccinationStatusTranslation } from '@/helpers/translations';
import { useMedicalRecordsManagementStore } from '../store';

@Component
export default class MissingVaccinations extends Vue {

  readonly store = useMedicalRecordsManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Pferd', value: 'name', width: '40%' },
    { text: 'Status', value: 'status', sortable: true, width: '60%' },
  ];

  readonly vaccinationStatusTranslation = vaccinationStatusTranslation;

  mounted(): void {
    this.store.getHorsesWithMissingVaccinations()
      .catch((error) => showErrorResponse(error));
  }

}
