
import { Vue, Component } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { IllnessId } from '@/types';
import { CreateVaccineAsAdminCommand } from '../types';
import { useVaccineManagementStore } from '../store';

interface Controls extends FormControls {
  illness: FormControl<IllnessId>;
  name: FormControl<string>;
}

@Component
export default class CreateVaccineDialog extends Vue {

  readonly store = useVaccineManagementStore();

  readonly size = DialogWidth.large;

  form: Form<Controls> | null = null;

  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        illness: {
          label: 'Krankheit',
          value: null,
          isRequired: true,
        },
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateVaccineAsAdminCommand = {
      illnessId: formValues.illness!,
      name: formValues.name!,
    };

    this.store.createVaccine(command)
      .then(() => showSuccessMessage('Der Impfstoff wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
