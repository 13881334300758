
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { FacilityId, RidingLessonDuration, RidingLessonTypeId, UserId, Time, TimeFrame, Weekday, weekdayFromMoment, isoDayOfWeek, Date, Moment } from '@/types';
import { moment } from '@/helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, minNumberRule, ValuesOfFormControls } from '@/components/form';
import { useRidingLessonManagementStore } from '../store';
import { CreateRidingLessonSeriesAsManagerCommand, Facility, RidingLessonType } from '../types';
import { midnightSevenDaysAgo } from '../helper';

interface Controls extends FormControls {
  facility: FormControl<FacilityId>;
  ridingLessonType: FormControl<RidingLessonTypeId>;
  weekday: FormControl<Weekday>;
  from: FormControl<Moment>;
  duration: FormControl<RidingLessonDuration>;
  to: FormControl<Time>;
  lastRegistrationAt: FormControl<Moment>;
  lastRidingLessonAt: FormControl<Moment>;
  ridingInstructor: FormControl<UserId>;
  maxAmountOfParticipants: FormControl<number>;
}

@Component
export default class CreateRidingLessonSeriesDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get selectedFacility(): Facility | null {
    if (!this.form) {
      return null;
    }

    const formValues = getFormValues(this.form!);
    if (!formValues.facility) {
      return null;
    }

    return this.store.facilities
      .find((facility) => facility.facilityId === formValues.facility)!;
  }

  get timeFrameForFrom(): TimeFrame | null {
    return this.selectedFacility === null
      ? null
      : this.selectedFacility.openingHours;
  }

  get facilitiesWithActiveRidingLessonTypes(): Facility[] {
    return this.store.facilities
      .filter((facility) => facility.ridingLessonTypes
        .filter((ridingLessonType) => ridingLessonType.isEnabled)
        .length > 0);
  }

  get activeRidingLessonTypesForSelectedFacility(): RidingLessonType[] {
    return this.selectedFacility === null
      ? []
      : this.selectedFacility.ridingLessonTypes
        .filter((ridingLessonType) => ridingLessonType.isEnabled);
  }

  get selectedRidingLessonType(): RidingLessonType | null {
    if (this.selectedFacility === null) {
      return null;
    }

    const formValues = getFormValues(this.form!);

    return this.selectedFacility.ridingLessonTypes
      .find((horseActivity) => horseActivity.ridingLessonTypeId === formValues.ridingLessonType)!;
  }

  get isLastRegistrationInThePast(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form!);
    return !!formValues.lastRegistrationAt
      && formValues.lastRegistrationAt!.isBefore(moment());
  }

  get lastRegistrationAtForFollowingRidingLessonsNote(): string {
    if (!this.form) {
      return '';
    }

    const formValues = getFormValues(this.form!);

    const dayOfRidingLesson = formValues.from!.startOf('day');
    const dayOfLastRegistrationAt = formValues.lastRegistrationAt!.startOf('day');

    if (dayOfRidingLesson.isSame(dayOfLastRegistrationAt, 'day')) {
      return `Unterrichtsstunden werden jeweils mit einem Anmeldeschluss am selben Tag um ${formValues.lastRegistrationAt!.format('HH:mm')} Uhr angelegt.`;
    }

    const dayDiff = dayOfRidingLesson.diff(dayOfLastRegistrationAt, 'day');
    if (dayDiff === 1) {
      return `Unterrichtsstunden werden jeweils mit einem Anmeldeschluss 1 Tag im Voraus um ${formValues.lastRegistrationAt!.format('HH:mm')} Uhr angelegt.`;
    }

    return `Unterrichtsstunden werden jeweils mit einem Anmeldeschluss ${dayDiff} Tage im Voraus um ${formValues.lastRegistrationAt!.format('HH:mm')} Uhr angelegt.`;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const initialFacility = this.facilitiesWithActiveRidingLessonTypes.length > 0
      ? this.facilitiesWithActiveRidingLessonTypes[0]
      : null;
    const initialDate = this.store.currentDateForRidingLessons!.isAfterOrEqualTo(Date.today().subtract(7))
      ? this.store.currentDateForRidingLessons!
      : Date.today();
    const initialFrom = initialFacility === null
      ? initialDate
        .atMidnight()
        .hour(9)
        .minute(0)
        .second(0)
      : initialDate
        .atMidnight()
        .hour(initialFacility.openingHours.timeFrom.hour)
        .minute(initialFacility.openingHours.timeFrom.minute)
        .second(initialFacility.openingHours.timeFrom.second);

    const activeRidingLessonTypesForInitialFacility = initialFacility !== null
      ? initialFacility.ridingLessonTypes
        .filter((ridingLessonType) => ridingLessonType.isEnabled)
      : [];
    const initialRidingLessonType = activeRidingLessonTypesForInitialFacility.length > 0
      ? activeRidingLessonTypesForInitialFacility[0]
      : null;

    const initialLength = initialRidingLessonType !== null
      ? initialRidingLessonType.defaultDuration
      : 45;
    const initialTo = Time.fromDate(initialFrom.add(initialLength, 'minutes'));
    const initialLastRidingLessonAt = initialFrom
      .startOf('day')
      .add(7, 'days');

    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        facility: {
          label: 'Anlage',
          value: initialFacility?.facilityId || null,
          isRequired: true,
          transformValuesOnInput: (values) => {
            const transformedValues: Partial<ValuesOfFormControls<Controls>> = {};

            // Select first riding lesson type of facility.
            const ridingLessonType = this.activeRidingLessonTypesForSelectedFacility.length > 0
              ? this.activeRidingLessonTypesForSelectedFacility[0]
              : null;
            transformedValues.ridingLessonType = ridingLessonType?.ridingLessonTypeId ?? null;
            transformedValues.duration = ridingLessonType?.defaultDuration || 45;
            transformedValues.to = Time.fromDate(
              values.from!
                .add(values.duration!, 'minutes')
            );

            // Reset to the earliest time if the already selected time is outside the allowed time frame for the facility.
            if (Time.fromDate(values.from!).isNotWithinTimeFrame(this.selectedFacility!.openingHours)) {
              const earliestTime = this.selectedFacility!.openingHours.timeFrom;
              transformedValues.from = values.from!
                .hour(earliestTime.hour)
                .minute(earliestTime.minute)
                .second(earliestTime.second);

              transformedValues.lastRegistrationAt = values.from;

              transformedValues.to = Time.fromDate(
                values.from!
                  .add(values.duration!, 'minutes')
              );
            }

            return transformedValues;
          }
        },
        ridingLessonType: {
          label: 'Unterrichtsart',
          value: initialRidingLessonType !== null
            ? initialRidingLessonType.ridingLessonTypeId
            : null,
          isRequired: true,
          transformValuesOnInput: (values) => {
            const transformedValues: Partial<ValuesOfFormControls<Controls>> = {};

            transformedValues.duration = this.selectedRidingLessonType?.defaultDuration || 45;
            transformedValues.to = Time.fromDate(
              values.from!
                .add(values.duration!, 'minutes')
            );

            return transformedValues;
          }
        },
        weekday: {
          label: 'Wochentag',
          value: weekdayFromMoment(initialFrom),
          isRequired: true,
          transformValuesOnInput: (values) => {
            const transformedValues: Partial<ValuesOfFormControls<Controls>> = {};

            const fromAtWeekday = values.from!.isoWeekday(isoDayOfWeek(values.weekday!));
            const newFrom = fromAtWeekday.isBefore(values.from!, 'day')
              ? fromAtWeekday.add(7, 'days')
              : fromAtWeekday;

            transformedValues.from = newFrom;
            transformedValues.lastRegistrationAt = newFrom;
            transformedValues.lastRidingLessonAt = newFrom
              .startOf('day')
              .add(7, 'days');

            return transformedValues;
          }
        },
        from: {
          label: 'Beginn',
          value: initialFrom,
          isRequired: true,
          rules: [
            this.dateTimeAfterMidnight7DaysAgoRule(),
          ],
          transformValuesOnInput: (values) => {
            const transformedValues: Partial<ValuesOfFormControls<Controls>> = {};
            if (values.from) {
              transformedValues.to = Time.fromDate(
                values.from
                  .add(values.duration!, 'minutes')
              );

              transformedValues.lastRegistrationAt = values.from;
              transformedValues.lastRidingLessonAt = values.from
                .startOf('day')
                .add(7, 'days');
            }

            return transformedValues;
          }
        },
        duration: {
          label: 'Dauer',
          value: initialLength,
          isRequired: true,
          transformValuesOnInput: (values) => {
            const transformedValues: Partial<ValuesOfFormControls<Controls>> = {};
            if (this.form!.controls.from.value) {
              transformedValues.to = Time.fromDate(
                values.from!
                  .add(values.duration!, 'minutes')
              );
            }

            return transformedValues;
          }
        },
        to: {
          label: 'Bis',
          value: initialTo,
        },
        lastRegistrationAt: {
          label: 'Anmeldeschluss',
          value: initialFrom,
          isRequired: true,
          rules: [
            this.dateTimeAfterMidnight7DaysAgoRule(),
          ],
        },
        lastRidingLessonAt: {
          label: 'Letzte Unterrichtsstunde am',
          value: initialLastRidingLessonAt,
          isRequired: true,
        },
        ridingInstructor: {
          label: 'Reitlehrer',
          value: null,
        },
        maxAmountOfParticipants: {
          label: 'Maximale Teilnehmerzahl',
          value: null,
          rules: [
            minNumberRule(1, 'Muss mindestens 1 sein, wenn angegeben'),
            maxDecimalsNumberRule(0),
          ],
        },
      },
    });

    form.controls.lastRegistrationAt.rules!.push(this.dateTimeBeforeRule(form.controls.from));

    return form;
  }

  dateTimeAfterMidnight7DaysAgoRule(): (value: Moment|null) => true|string {
    return (value: Moment|null) => value === null
      || value.isAfter(
        moment()
          .startOf('day')
          .subtract(7, 'days')
      )
      || 'Der Zeitpunkt darf maximal 7 Tage in der Vergangenheit liegen';
  }

  dateTimeBeforeRule(formControl: FormControl<Moment>): (value: Moment|null) => true|string {
    return (value: Moment|null) => value === null
      || formControl.value === null
      || value.isSameOrBefore(formControl.value)
      || 'Der Anmeldeschluss darf nicht nach dem Beginn der Unterrichtsstunde liegen';
  }

  isFromDateAllowed(date: Moment): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form!);

    return date.isSameOrAfter(midnightSevenDaysAgo())
      && weekdayFromMoment(date) === formValues.weekday;
  }

  isLastRegistrationAtAllowed(date: Moment): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form!);
    const dayOfRidingLesson = formValues.from!.startOf('day');

    return date.isSameOrAfter(midnightSevenDaysAgo(), 'day')
      && date.isSameOrBefore(dayOfRidingLesson, 'day');
  }

  endDateAllowed(date: Moment): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form!);
    const dayOfRidingLesson = formValues.from!.startOf('day');

    return date.isSameOrAfter(dayOfRidingLesson, 'day')
      && weekdayFromMoment(date) === formValues.weekday;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateRidingLessonSeriesAsManagerCommand = {
      facilityId: formValues.facility!,
      ridingLessonTypeId: formValues.ridingLessonType!,
      weekday: formValues.weekday!,
      from: formValues.from!,
      duration: formValues.duration!,
      to: formValues.from!
        .add(formValues.duration!, 'minutes'),
      lastRegistrationAt: formValues.lastRegistrationAt!,
      lastRidingLessonAt: formValues.lastRidingLessonAt!,
      ridingInstructorId: formValues.ridingInstructor,
      maxAmountOfParticipants: formValues.maxAmountOfParticipants,
    };

    this.store.createRidingLessonSeries(command)
      .then(() => showSuccessMessage('Serie wurde angelegt.'))
      .then(() => this.$emit('riding-lesson-series-created'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
