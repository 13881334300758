
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { constructForm, FormControl, Form, getFormValues, FormControls, minNumberRule, maxDecimalsNumberRule } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useRidingLessonManagementStore } from '../store';
import { RidingLesson, UpdateMaxAmountOfParticipantsOfRidingLessonAsManagerCommand } from '../types';

interface Controls extends FormControls {
  maxAmountOfParticipants: FormControl<number>;
}

@Component
export default class UpdateMaxAmountOfParticipantsOfRidingLessonDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly ridingLesson!: RidingLesson;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        maxAmountOfParticipants: {
          label: 'Maximale Teilnehmerzahl',
          value: this.ridingLesson.maxAmountOfParticipants,
          rules: [
            minNumberRule(1),
            maxDecimalsNumberRule(0),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateMaxAmountOfParticipantsOfRidingLessonAsManagerCommand = {
      ridingLessonId: this.ridingLesson.ridingLessonId,
      maxAmountOfParticipants: formValues.maxAmountOfParticipants,
    };
    this.store.updateMaxAmountOfParticipantsOfRidingLesson(command)
      .then(() => showSuccessMessage('Der Teilnehmerzahl wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
