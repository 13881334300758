
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { momentTimeFrame } from '@/helpers/date-helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { Time, TimeFrame } from '@/types';
import { useFacilityManagementStore } from '../store';
import { Facility, FacilityBlocker, GetOverlappingFacilityEntriesAsManagerQuery, UpdateTimeRangeOfFacilityBlockerAsManagerCommand } from '../types';
import OverlappingFacilityEntries from './overlapping-facility-entries.vue';

interface Controls extends FormControls {
  timeFrame: FormControl<TimeFrame>;
}

@Component({
  components: {
    OverlappingFacilityEntries,
  },
})
export default class UpdateFacilityBlockerTimeRangeDialog extends Vue {

  readonly store = useFacilityManagementStore();

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  @Prop({ type: Object, required: true })
  readonly facilityBlocker!: FacilityBlocker;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid
      || this.store.isGetOverlappingFacilityEntriesProcessing
      || this.store.hasOverlappingFacilityBlockers
      || this.store.hasOverlappingRidingLessons;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (isDialogVisible) {
        this.updateOverlappingFacilityEntries();
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        timeFrame: {
          label: 'Zeitraum',
          value: {
            timeFrom: Time.fromDate(this.facilityBlocker.from),
            timeTo: Time.fromDate(this.facilityBlocker.to),
          },
          isRequired: true,
          afterTransformationAndValidation: () => this.updateOverlappingFacilityEntries(),
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateTimeRangeOfFacilityBlockerAsManagerCommand = {
      facilityBlockerId: this.facilityBlocker.facilityBlockerId,
      timeFrame: formValues.timeFrame!,
    };

    this.store.updateFacilityBlockerTimeRange(command)
      .then(() => showSuccessMessage('Der Zeitraum der Sperrzeit wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  updateOverlappingFacilityEntries(): void {
    const formValues = getFormValues(this.form!);

    // Can happen when the selected time frame is invalid
    if (!formValues.timeFrame) {
      return;
    }

    const query: GetOverlappingFacilityEntriesAsManagerQuery = {
      facilityId: this.facility.facilityId,
      timeFrames: [
        momentTimeFrame(this.facilityBlocker.from!, formValues.timeFrame!),
      ],
      blocksSpaces: this.facilityBlocker.blocksSpaces ?? this.facility.spaces,
      idOfIgnoredFacilityBlocker: this.facilityBlocker.facilityBlockerId,
    };
    this.store.getOverlappingFacilityEntries(query)
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
