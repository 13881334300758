
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { downloadFile } from '@/helpers/file-download-helper';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { Month } from '@/types';
import { moment } from '@/helpers';
import { useLedgerStore } from '../store';
import { GetInvoicePDFAsManagerQuery, Invoice } from '../types';

interface Controls extends FormControls {
  selectedMonth: FormControl<Month>;
}

@Component
export default class Invoices extends Vue {

  readonly store = useLedgerStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Rechnungsnummer', value: 'invoiceNumber' },
    { text: 'Person', value: 'person' },
    { text: 'Gesamtbetrag', value: 'grossAmount', align: 'end' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  form: Form<Controls> | null = null;

  get isDownloadAllInvoicesDisabled(): boolean {
    return this.store.invoices.length === 0;
  }

  get isInvoiceInformationNotConfiguredYet(): boolean {
    return this.store.invoiceInformation !== null
      && !this.store.invoiceInformation.isInvoiceInformationDefined;
  }

  mounted(): void {
    const initialMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM') as Month;

    this.form = this.buildForm(initialMonth);

    this.store.updateSelectedMonthForInvoices(initialMonth)
      .catch((error) => showErrorResponse(error));

    this.store.getInvoiceInformation()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(initialMonth: Month): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        selectedMonth: {
          label: 'Monat',
          value: initialMonth,
          isRequired: true,
          afterTransformationAndValidation: (value) => {
            this.store.updateSelectedMonthForInvoices(value!)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  downloadAllInvoicesClicked(): void {
    const zipFileName = `Rechnungen-${this.store.selectedMonthForInvoices!}.zip`;

    this.store.getInvoicesAsZIPForMonth()
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, zipFileName))
      .catch((error) => showErrorResponse(error));
  }

  downloadInvoiceClicked(invoice: Invoice): void {
    const query: GetInvoicePDFAsManagerQuery = {
      invoiceId: invoice.invoiceId,
    };

    const invoiceFileName = `Rechnung-${invoice.invoiceNumber}.pdf`;

    this.store.getInvoicePDFAsFarmManager(query)
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, invoiceFileName))
      .catch((error) => showErrorResponse(error));
  }
}
