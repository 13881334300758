export interface Command {}
export interface Query {}

export interface CommandWithFiles {
  body: object;
  files: Partial<Record<string, Blob | null>>;
}

export type Action<T extends Command | Query | void, R = void> = (payload: T) => Promise<R>;
export type ActionWithTrialRun<T extends Command | Query | void>
  = (payload: { command: T, isTrialRun: boolean }) => Promise<void>;

export enum ActionStatus {
  None,
  InProgress,
  Successful,
  Failed,
}

export interface FileResponse {
  data: File;
  contentType: string;
}

// Stricter than InputValidationRule from vuetify/types
export type FormValidationRule<T> = (value: T) => boolean | string;

export interface FormField<T> {
  value: T;
  rules: FormValidationRule<T>[];
}

export interface OptionalValueFormField<T> {
  value?: T;
  rules?: FormValidationRule<T>[];
}

export type StrictFormFieldType<T> =
  | FormField<T>
  | FormField<T>[]
  | OptionalValueFormField<T>
  | Record<string, FormField<T>[]>
  | Record<string, FormField<T>>[];

export interface StrictFormDefinition {
  valid: boolean;
  fields: Record<string, StrictFormFieldType<any>>;
}

// Stand-in for Validatable from vuetify/lib/mixins/validatable
export interface VuetifyValidatable {
  errorCount: number;
  errorBucket: string[];
  validate(forceValidate?: boolean): () => boolean;
  resetValidation(): () => void;
}

// Structure expected by VSelect when not providing callbacks
export interface VuetifySelectItem<T> {
  text: string;
  value: T;
}
