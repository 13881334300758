
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { UpdateVisibilityOfFacilityAsManagerCommand } from '../types';
import { useFacilityManagementStore } from '../store';

@Component
export default class UpdateVisibilityOfFacilityDialog extends Vue {

  readonly store = useFacilityManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  get title(): string {
    return this.store.currentFacility?.isHidden
      ? `Anlage ${this.store.currentFacility?.name} einblenden`
      : `Anlage ${this.store.currentFacility?.name} ausblenden`;
  }

  get description(): string {
    return this.store.currentFacility?.isHidden
      ? `Willst du die Anlage ${this.store.currentFacility?.name} wirklich einblenden?`
      : `Willst du die Anlage ${this.store.currentFacility?.name} wirklich ausblenden?`;
  }

  get buttonText(): string {
    return this.store.currentFacility?.isHidden
      ? 'Einblenden'
      : 'Ausblenden';
  }

  editFacilityVisibilityConfirmed(): void {
    const command: UpdateVisibilityOfFacilityAsManagerCommand = {
      facilityId: this.store.currentFacility!.facilityId,
      isHidden: !this.store.currentFacility!.isHidden,
    };

    this.store.updateVisibilityOfFacility(command)
      .then(() => showSuccessMessage(this.store.currentFacility!.isHidden
        ? 'Die Anlage wurde ausgeblendet.'
        : 'Die Anlage wurde eingeblendet.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
