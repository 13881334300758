
import { Component, Vue } from 'vue-property-decorator';
import { Feature, FarmManagerPermission } from '@/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission, isFeatureEnabled } from '@/application/authentication/helper';
import { useLedgerStore } from '../store';

@Component
export default class LedgerAdminComponent extends Vue {

  readonly store = useLedgerStore();

  get areInvoicesVisible(): boolean {
    return isFeatureEnabled(Feature.LEDGER_INVOICING)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_READ);
  }

  mounted(): void {
    this.store.getPersons()
      .catch((error) => showErrorResponse(error));
  }

}
