
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { UpdateAnnualNumbersOfHoursAsManagerCommand } from '../types';
import { useLaborServicesManagementStore } from '../store';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, minNumberRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';

interface Controls extends FormControls {
  annualNumberOfHours: FormControl<number>;
}

@Component
export default class UpdateLaborServiceSettingsDialog extends Vue {

  readonly store = useLaborServicesManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Number, default: null })
  readonly annualNumberOfHours!: number | null;

  form: Form<Controls> | null = null;

  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.onSubmit,
      controls: {
        annualNumberOfHours: {
          label: 'Jährliche Stundenanzahl pro Reiter',
          value: this.annualNumberOfHours,
          isRequired: true,
          rules: [
            minNumberRule(0),
            maxDecimalsNumberRule(2),
          ],
        },
      }
    });
  }

  onSubmit(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateAnnualNumbersOfHoursAsManagerCommand = {
      annualNumberOfHours: formValues.annualNumberOfHours!,
    };

    this.store.updateAnnualNumbersOfHours(command)
      .then(() => showSuccessMessage('Die Konfiguration wurde gespeichert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
