
import { Component, Ref, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { requiredRule, passwordRule, passwordMustBeIdenticalToTextInFieldRule } from '@/helpers/form-rules';
import { navigate } from '@/helpers/navigation-helpers';
import { FormField, StrictFormDefinition, VuetifyValidatable } from '@/application/types';
import { ResetPasswordCommand } from '../../../application/authentication/types';

interface Form extends StrictFormDefinition {
  fields: {
    password: FormField<string|null>;
    passwordRepeat: FormField<string|null>;
  }
}

@Component
export default class ResetPassword extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  @Ref()
  readonly passwordInput!: VuetifyValidatable;

  @Ref()
  readonly passwordRepeatInput!: VuetifyValidatable;

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form {
    const form: Form = {
      valid: false,
      fields: {
        password: {
          value: null,
          rules: [
            requiredRule(),
            passwordRule(),
          ],
        },
        passwordRepeat: {
          value: null,
          rules: [
            requiredRule(),
            passwordRule(),
          ],
        },
      },
    };

    form.fields.password.rules!.push(passwordMustBeIdenticalToTextInFieldRule(form.fields.passwordRepeat));
    form.fields.passwordRepeat.rules!.push(passwordMustBeIdenticalToTextInFieldRule(form.fields.password));

    return form;
  }

  passwordChanged(): void {
    this.passwordRepeatInput.validate(true);
  }

  passwordRepeatChanged(): void {
    this.passwordInput.validate(true);
  }

  submitted(): void {
    const command: ResetPasswordCommand = {
      token: this.$route.params.token,
      password: this.form!.fields.password.value!,
    };

    this.authenticationStore.resetPassword(command)
      .then(() => navigate({ name: 'root' }))
      .catch((error) => showErrorResponse(error));
  }

}
