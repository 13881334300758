import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties, UserProperty } from '@/helpers/default-parameter-helper';
import { CSV } from '@/types';
import { CreateGroupAsManagerCommand, DisableUserAsManagerCommand, EnableUserAsManagerCommand, GetUserForImpersonationAsManagerQuery, Group, InviteUserAsManagerCommand, RegistrationConfiguration, RemoveUserFromFarmAsManagerWorkflow, RequestImpersonationAsManagerCommand, UpdateGroupNameAsManagerCommand, UpdateGroupsForUserAsManagerCommand, UpdateGroupUsersAsManagerCommand, UpdateRegistrationConfigurationAsManagerCommand, UpdateUserPasswordAsManagerCommand, UpdateEnabledPermissionsOfUserAsManagerWorkflow, UpdateUserRoleAsManagerWorkflow, User, UpdateVisibilityOfHorsesViewAsManagerCommand } from './types';

// -- Queries

export function getUsers(query: FarmAndUserProperties): Promise<User[]> {
  const url = `${apiUrl}/api/user-management/get-users-as-manager-query`;
  return performApiRequest<User[]>({ url, method: 'POST', data: query });
}

export function getGroups(query: FarmAndUserProperties): Promise<Group[]> {
  const url = `${apiUrl}/api/user-management/get-groups-as-manager-query`;
  return performApiRequest<Group[]>({ url, method: 'POST', data: query });
}

export function getRegistrationConfiguration(query: FarmAndUserProperties): Promise<RegistrationConfiguration> {
  const url = `${apiUrl}/api/user-management/get-registration-configuration-as-manager-query`;
  return performApiRequest<RegistrationConfiguration>({ url, method: 'POST', data: query });
}

export function getUserForImpersonation(query: GetUserForImpersonationAsManagerQuery & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/get-user-for-impersonation-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: query });
}

export function getUsersInCSVFormat(query: FarmAndUserProperties): Promise<CSV> {
  const url = `${apiUrl}/api/user-management/get-users-in-csv-format-as-manager-query`;
  return performApiRequest<CSV>({ url, method: 'POST', data: query });
}

// -- Commands

export function enableUser(command: EnableUserAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/user-management/enable-user-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function disableUser(command: DisableUserAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/user-management/disable-user-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function removeUserFromFarm(command: RemoveUserFromFarmAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/user-management/remove-user-from-farm-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function requestImpersonation(command: RequestImpersonationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/user-management/request-impersonation-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createGroup(command: CreateGroupAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/user-management/create-group-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateGroupName(command: UpdateGroupNameAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-group-name-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateGroupUsers(command: UpdateGroupUsersAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-group-users-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateGroupsForUser(command: UpdateGroupsForUserAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-groups-for-user-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateRegistrationConfiguration(
  command: UpdateRegistrationConfigurationAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-registration-configuration-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateUserPassword(command: UpdateUserPasswordAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-user-password-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function inviteUser(command: InviteUserAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/user-management/invite-user-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateUserRole(command: UpdateUserRoleAsManagerWorkflow & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-user-role-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateEnabledPermissionsOfUser(command: UpdateEnabledPermissionsOfUserAsManagerWorkflow & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-enabled-permissions-of-user-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateVisibilityOfHorsesViewOfUser(command: UpdateVisibilityOfHorsesViewAsManagerCommand & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-visibility-of-horses-view-of-user-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
