
import { Vue, Component } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, requiredWhenCheckboxIsCheckedRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { Feature, UserRole } from '@/types';
import { useAdminNotificationManagementStore } from '../store';
import { CreateAdminNotificationAsAdminCommand } from '../types';

interface Controls extends FormControls {
  message: FormControl<string>;
  isVisibilityRestrictedThroughEnabledFeatures: FormControl<boolean>;
  visibleForFarmsWithEnabledFeatures: FormControl<Feature[]>;
  isVisibilityRestrictedThroughRole: FormControl<boolean>;
  visibleForRole: FormControl<UserRole>;
}

@Component
export default class CreateAdminNotificationDialog extends Vue {

  readonly store = useAdminNotificationManagementStore();

  readonly size = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isVisibleForFarmsWithEnabledFeaturesVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isVisibilityRestrictedThroughEnabledFeatures!;
  }

  get isVisibleForRoleVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isVisibilityRestrictedThroughRole!;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        message: {
          label: 'Nachricht',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        isVisibilityRestrictedThroughEnabledFeatures: {
          label: 'Sichtbarkeit anhand Features einschränken',
          value: false,
          validateFormControlsOnInput: [
            'visibleForFarmsWithEnabledFeatures',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'visibleForFarmsWithEnabledFeatures',
          ],
        },
        visibleForFarmsWithEnabledFeatures: {
          label: 'Sichtbar für Betriebe mit folgenden Features',
          value: null,
          rules: [],
        },
        isVisibilityRestrictedThroughRole: {
          label: 'Sichtbarkeit anhand der Rolle einschränken',
          value: false,
          validateFormControlsOnInput: [
            'visibleForRole',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'visibleForRole',
          ],
        },
        visibleForRole: {
          label: 'Rolle',
          value: UserRole.ROLE_USER,
          rules: [],
          isRequired: true,
        },
      },
    });

    form.controls.visibleForFarmsWithEnabledFeatures.rules!.push(requiredWhenCheckboxIsCheckedRule(
      form.controls.isVisibilityRestrictedThroughEnabledFeatures,
      'Mindestens ein Feature muss ausgewählt werden',
    ));
    form.controls.visibleForRole.rules!.push(requiredWhenCheckboxIsCheckedRule(
      form.controls.isVisibilityRestrictedThroughRole,
      'Die Rolle muss ausgewählt werden',
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateAdminNotificationAsAdminCommand = {
      message: formValues.message!,
      visibleForFarmsWithEnabledFeatures: formValues.isVisibilityRestrictedThroughEnabledFeatures
        ? formValues.visibleForFarmsWithEnabledFeatures
        : null,
      visibleForRole: formValues.isVisibilityRestrictedThroughRole
        ? formValues.visibleForRole
        : null,
    };

    this.store.createAdminNotification(command)
      .then(() => showSuccessMessage('Die Admin Benachrichtigung wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
