
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse } from '@/application/snackbar/service';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { HorseId } from '@/types';
import { usePinboardStore } from '../store';
import { RegisterForEventCommand, Event } from '../types';
import { Horse } from '@/private/rider/my-stable/types';

interface Controls extends FormControls {
  horse: FormControl<HorseId>;
  comment: FormControl<string>;
}

@Component
export default class RegisterForEventDialog extends Vue {

  readonly store = usePinboardStore();
  readonly myStableStore = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly event!: Event;

  @Prop({ type: Boolean, required: true })
  readonly isDisabled!: boolean;

  readonly size = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isHorseVisible(): boolean {
    return this.event.isHorseRequiredOnRegistration;
  }

  get availableHorses(): Horse[] {
    return this.myStableStore.ownAndSharedHorses
      .filter((horse) => !this.event.ownRegistrations.some((registration) => registration.horseId === horse.horseId));
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        horse: {
          label: 'Pferd',
          value: this.availableHorses[0]?.horseId ?? null,
          isRequired: this.event.isHorseRequiredOnRegistration,
        },
        comment: {
          label: 'Kommentar',
          value: null,
          isRequired: this.event.isCommentRequiredOnRegistration,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: RegisterForEventCommand = {
      eventId: this.event.eventId,
      horseId: this.event.isHorseRequiredOnRegistration
        ? formValues.horse
        : null,
      comment: formValues.comment,
    };

    this.store.registerForEvent(command)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
