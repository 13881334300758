
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useNewsManagementStore } from '../store';
import { DeleteNewsEntryAsMangerCommand, NewsEntry } from '../types';

@Component
export default class DeleteNewsEntryDialog extends Vue {

  readonly store = useNewsManagementStore();

  @Prop({ type: Object, required: true })
  readonly newsEntry!: NewsEntry;

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  deleteNewsEntryConfirmed(): void {
    const command: DeleteNewsEntryAsMangerCommand = {
      newsEntryId: this.newsEntry.newsEntryId,
    };

    this.store.deleteNewsEntry(command)
      .then(() => showSuccessMessage('Die Neuigkeit wurde gelöscht.'))
      .then(() => this.closeDialog())
      .catch(() => {});
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
