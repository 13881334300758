
import { Component, Vue } from 'vue-property-decorator';
import { doesAuthenticatedUserHavePermission, isFeatureEnabled, isFeatureVisibleForRiders } from '@/application/authentication/helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { Feature, FeatureVisibleForRiders, FarmManagerPermission } from '@/types';
import { ActionStatus } from '@/application/types';
import TrackTodaysPenStatus from '@/private/management/pen/components/track-todays-paddock-status.vue';
import NewsTable from '@/private/management/news/components/news-table.vue';
import ShowFarmChangesForDayComponent from '@/private/management/farm-changes/components/show-farm-changes-for-day.vue';
import NewTasks from '@/private/management/rider-tasks/components/new-tasks.vue';
import AssignedTasksForToday from '@/private/management/rider-tasks/components/assigned-tasks-for-today.vue';
import LatestFeedingUpdates from '@/private/management/feeding/components/latest-feeding-updates.vue';
import OverdueTasks from '@/private/management/rider-tasks/components/overdue-tasks.vue';
import OverdueFarmTasks from '@/private/management/farm-tasks/components/overdue-tasks.vue';
import { useRiderTaskManagementStore } from '@/private/management/rider-tasks/store';
import { showErrorResponse } from '@/application/snackbar/service';
import MyTasks from '@/private/management/farm-tasks/components/my-tasks.vue';
import OurTasks from '@/private/management/farm-tasks/components/our-tasks.vue';
import PenExemptions from '@/private/management/pen/components/pen-exemptions.vue';
import { useFarmTaskManagementStore } from '@/private/management/farm-tasks/store';

@Component({
  components: {
    TrackTodaysPenStatus,
    NewsTable,
    ShowFarmChangesForDayComponent,
    NewTasks,
    AssignedTasksForToday,
    LatestFeedingUpdates,
    OverdueTasks,
    MyTasks,
    OurTasks,
    OverdueFarmTasks,
    PenExemptions,
  },
})
export default class ControlPanel extends Vue {

  readonly authenticationStore = useAuthenticationStore();
  readonly riderTaskManagementStore = useRiderTaskManagementStore();
  readonly farmTaskManagementStore = useFarmTaskManagementStore();

  get areNoComponentsVisible(): boolean {
    return !this.isPaddockStatusVisible
      && !this.areFarmChangesVisible
      && !this.isOverdueFarmTasksAccessible
      && !this.isMyTasksVisible
      && !this.isOurTasksVisible
      && !this.isNewTasksVisible
      && !this.isAssignedTasksForTodayVisible
      && !this.areLastFeedingUpdatesVisible
      && !this.areLatestNewsVisible;
  }

  get isPaddockStatusVisible(): boolean {
    return isFeatureVisibleForRiders(FeatureVisibleForRiders.PEN_STATUS)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.PEN_READ);
  }

  get areFarmChangesVisible(): boolean {
    return isFeatureEnabled(Feature.LEDGER)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_CHANGES_VIEW)
      && (
        isFeatureVisibleForRiders(FeatureVisibleForRiders.GERBLHOF_FEEDING)
        || isFeatureVisibleForRiders(FeatureVisibleForRiders.FARM_SERVICES)
        || isFeatureVisibleForRiders(FeatureVisibleForRiders.PEN_EXEMPTIONS)
      );
  }

  get isOverdueFarmTasksAccessible(): boolean {
    return isFeatureEnabled(Feature.FARM_TASKS)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_READ);
  }

  get isOverdueFarmTasksVisible(): boolean {
    return !!this.farmTaskManagementStore.overdueTasks
      && this.farmTaskManagementStore.overdueTasks.tasks.length > 0;
  }

  get isMyTasksVisible(): boolean {
    return isFeatureEnabled(Feature.FARM_TASKS)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_READ);
  }

  get isOurTasksVisible(): boolean {
    return isFeatureEnabled(Feature.FARM_TASKS)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_READ);
  }

  get isNewTasksAccessible(): boolean {
    return isFeatureEnabled(Feature.RIDER_TASKS)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_READ);
  }

  get isNewTasksVisible(): boolean {
    return this.riderTaskManagementStore.newTasks.length > 0;
  }

  get isOverdueTasksAccessible(): boolean {
    return isFeatureEnabled(Feature.RIDER_TASKS)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_READ);
  }

  get isOverdueTasksVisible(): boolean {
    return this.riderTaskManagementStore.overdueTasks.length > 0;
  }

  get isAssignedTasksForTodayAccessible(): boolean {
    return isFeatureEnabled(Feature.RIDER_TASKS)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_READ)
      && (this.riderTaskManagementStore.getAssignedTasksForTodayStatus === ActionStatus.None
        || this.riderTaskManagementStore.assignedTasksForToday.length > 0
      );
  }

  get isAssignedTasksForTodayVisible(): boolean {
    return this.riderTaskManagementStore.assignedTasksForToday.length > 0;
  }

  get isPenExemptionsForTodayVisible(): boolean {
    return isFeatureEnabled(Feature.PEN)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.PEN_READ)
      && isFeatureVisibleForRiders(FeatureVisibleForRiders.PEN_EXEMPTIONS);
  }

  get areLastFeedingUpdatesVisible(): boolean {
    return isFeatureVisibleForRiders(FeatureVisibleForRiders.FEEDING)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEEDING_READ);
  }

  get areLatestNewsVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_READ);
  }

  get farmChangesTitel(): string {
    return isFeatureVisibleForRiders(FeatureVisibleForRiders.GERBLHOF_FEEDING)
      ? 'Änderungen'
      : 'Hofdienste';
  }

  destroyed(): void {
    this.riderTaskManagementStore.$reset();
  }

  taskAssignedInNewTasks(): void {
    this.riderTaskManagementStore.getOverdueTasks()
      .catch((error) => showErrorResponse(error));
    this.riderTaskManagementStore.getAssignedTasksForToday()
      .catch((error) => showErrorResponse(error));
  }

  taskCompletedInNewTasks(): void {
    this.riderTaskManagementStore.getOverdueTasks()
      .catch((error) => showErrorResponse(error));
  }

  taskRejectedInNewTasks(): void {
    this.riderTaskManagementStore.getOverdueTasks()
      .catch((error) => showErrorResponse(error));
  }

}
