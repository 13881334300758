
import { Component, Vue } from 'vue-property-decorator';
import { useAnalyticsStore } from '../store';

@Component
export default class NewsOverviewChart extends Vue {

  readonly store = useAnalyticsStore();

  get newsCountLabel(): string {
    return this.store.analytics!.newsOverview.newsCount === 1
      ? 'Neuigkeit'
      : 'Neuigkeiten';
  }

  get percentage(): number {
    const data = this.store.analytics!.newsOverview;
    return Math.round(data.readingRatio * 100);
  }

}
