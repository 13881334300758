
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { afterMomentRule, beforeMomentRule, constructForm, Form, FormControl, FormControls, getFormValues, maxDistanceRule } from '@/components/form';
import { FacilityReservationActivityId, HorseId, Moment, MomentTimeFrame, UserId } from '@/types';
import { moment } from '@/helpers';
import { ActionStatus } from '@/application/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { useFacilityReservationsManagementStore } from '../store';
import { UserWithCountOfFacilityReservations } from '../types';

interface Controls extends FormControls {
  from: FormControl<Moment>;
  to: FormControl<Moment>;
  horseOwner: FormControl<UserId>;
  horse: FormControl<HorseId>;
  facilityReservationActivities: FormControl<FacilityReservationActivityId[]>;
}

@Component
export default class FacilityReservationEvaluationByHorseOwner extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  readonly tableHeadersForUsers: DataTableHeader[] = [
    { text: 'Einsteller', value: 'name' },
    { text: 'Pferde', value: 'horses' },
    { text: 'Anzahl Reservierungen', value: 'countOfFacilityReservations', align: 'end' },
  ];

  readonly tableHeadersForFacilityReservations: DataTableHeader[] = [
    { text: 'Datum / Uhrzeit', value: 'timeFrame' },
    { text: 'Einsteller', value: 'user' },
    { text: 'Pferd', value: 'horse' },
    { text: 'Aktivität', value: 'facilityReservationActivity' },
  ];

  form: Form<Controls> | null = null;

  get noDataTextForUsers(): string {
    return this.store.loadFacilitiesStatus === ActionStatus.Failed
      ? 'Die Einsteller konnten nicht geladen werden.'
      : 'Es existieren keine Einsteller mit Reservierungen für diese Filtereinstellungen.';
  }

  get noDataTextForReservations(): string {
    return this.store.loadFacilitiesStatus === ActionStatus.Failed
      ? 'Die Reservierungen konnten nicht geladen werden.'
      : 'Es existieren keine Reservierungen für diese Filtereinstellungen.';
  }

  mounted(): void {
    const initialTimeFrame: MomentTimeFrame = {
      momentFrom: moment()
        .startOf('day')
        .subtract(7, 'days'),
      momentTo: moment()
        .startOf('day'),
    };
    this.store.setTimeFrameForEvaluationForHorseOwner(initialTimeFrame)
      .catch((error) => showErrorResponse(error));

    this.store.getEvaluationFilterData()
      .then(() => {
        this.form = this.buildForm(initialTimeFrame);
      })
      .catch((error) => showErrorResponse(error));
  }

  buildForm(initialTimeFrame: MomentTimeFrame): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: () => {},
      controls: {
        from: {
          label: 'Von',
          value: initialTimeFrame.momentFrom,
          rules: [],
          validateFormControlsOnInput: [
            'to',
          ],
          afterTransformationAndValidation: () => {
            if (this.form!.isValid) {
              const formValues = getFormValues(this.form!);
              const timeFrame: MomentTimeFrame = {
                momentFrom: formValues.from!,
                momentTo: formValues.to!,
              };
              this.store.setTimeFrameForEvaluationForHorseOwner(timeFrame)
                .catch((error) => showErrorResponse(error));
            }
          },
        },
        to: {
          label: 'Bis',
          value: initialTimeFrame.momentTo,
          rules: [],
          validateFormControlsOnInput: [
            'from',
          ],
          afterTransformationAndValidation: () => {
            if (this.form!.isValid) {
              const formValues = getFormValues(this.form!);
              const timeFrame: MomentTimeFrame = {
                momentFrom: formValues.from!,
                momentTo: formValues.to!,
              };
              this.store.setTimeFrameForEvaluationForHorseOwner(timeFrame)
                .catch((error) => showErrorResponse(error));
            }
          },
        },
        horseOwner: {
          label: 'Einsteller',
          value: null,
          afterTransformationAndValidation: (value) => {
            this.store.setHorseOwnerForEvaluationByHorseOwner(value)
              .catch((error) => showErrorResponse(error));
          },
        },
        horse: {
          label: 'Pferd',
          value: null,
          afterTransformationAndValidation: (value) => {
            this.store.setHorseForEvaluationByHorseOwner(value)
              .catch((error) => showErrorResponse(error));
          },
        },
        facilityReservationActivities: {
          label: 'Aktivitäten',
          value: [],
          afterTransformationAndValidation: (value) => {
            this.store.setFacilityReservationActivitiesForEvaluationByHorseOwner(value ?? [])
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });

    form.controls.from.rules!.push(beforeMomentRule(form.controls.to));
    form.controls.from.rules!.push(maxDistanceRule(form.controls.to, 0, 'year', 'Das Zeitfenster darf maximal 1 Jahre lang sein.'));

    form.controls.to.rules!.push(afterMomentRule(form.controls.from));
    form.controls.to.rules!.push(maxDistanceRule(form.controls.from, 0, 'year', 'Das Zeitfenster darf maximal 1 Jahre lang sein.'));

    return form;
  }

  horses(user: UserWithCountOfFacilityReservations): string {
    const horseCountLabel = user.horses.length === 1
      ? '1 Pferd'
      : `${user.horses.length} Pferde`;

    const horseList = user.horses
      .map((horse) => `${horse.name} (${horse.countOfFacilityReservations})`)
      .join(', ');

    return `${horseCountLabel}: ${horseList}`;
  }

}
