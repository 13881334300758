import { RouteConfig, RouteMeta } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import FacilityList from './components/facility-list.vue';
import FacilityDetailsTabs from './components/facility-details-tabs.vue';
import FacilityReservationActivities from './components/facility-reservation-activities.vue';
import FacilityReservations from './components/facility-reservations.vue';
import FacilityConfiguration from './components/facility-configuration.vue';
import FacilityReservationTabs from './components/facility-reservation-tabs.vue';
import FacilityReservationEvaluationByUser from './components/facility-reservation-evaluation-by-user.vue';
import FacilityReservationEvaluationByHorseOwner from './components/facility-reservation-evaluation-by-horse-owner.vue';

const meta: RouteMeta = {
  title: 'Anlagenreservierungen',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresFeature: Feature.FACILITY_RESERVATIONS,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresPermission: FarmManagerPermission.FACILITY_RESERVATIONS_READ,
};

export const facilityReservationManagementRoutes: RouteConfig[] = [
  {
    path: 'anlagenreservierungen',
    component: FacilityReservationTabs,
    meta: {
      ...meta,
      title: 'Anlagenreservierungen',
    },
    children: [
      {
        path: '',
        redirect: 'anlagen',
      },
      {
        name: 'facility-reservations-management-facility-list',
        path: 'anlagen',
        component: FacilityList,
        meta: {
          ...meta,
          title: 'Anlagen',
        },
      },
      {
        name: 'facility-reservations-management-facility-reservation-evaluation-by-user',
        path: 'auswertung-nach-reservierenden',
        component: FacilityReservationEvaluationByUser,
        meta: {
          ...meta,
          title: 'Auswertung nach Reservierenden',
        },
      },
      {
        name: 'facility-reservations-management-facility-reservation-evaluation-by-horse-owner',
        path: 'auswertung-nach-einsteller',
        component: FacilityReservationEvaluationByHorseOwner,
        meta: {
          ...meta,
          title: 'Auswertung nach Einsteller',
        },
      },
    ],
  },
  {
    path: 'anlagenreservierungen/:id',
    component: FacilityDetailsTabs,
    children: [
      {
        path: '',
        redirect: 'reservierungen',
      },
      {
        name: 'facility-reservations-management-facility-details-reservations',
        path: 'reservierungen',
        component: FacilityReservations,
        meta: {
          ...meta,
          title: 'Reservierungen',
          routeNameForBackButton: 'facility-reservations-management-facility-list',
        },
      },
      {
        name: 'facility-reservations-management-facility-details-activities',
        path: 'aktivitaeten',
        component: FacilityReservationActivities,
        meta: {
          ...meta,
          title: 'Aktivitäten',
          routeNameForBackButton: 'facility-reservations-management-facility-list',
        },
      },
      {
        name: 'facility-reservations-management-facility-details-configuration',
        path: 'konfiguration',
        component: FacilityConfiguration,
        meta: {
          ...meta,
          title: 'Konfiguration',
          routeNameForBackButton: 'facility-reservations-management-facility-list',
        },
      },
    ],
  },
];
