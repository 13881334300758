
import { Component, Vue } from 'vue-property-decorator';
import { FarmManagerPermission } from '@/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { timeConfigurationTranslation } from '@/helpers/translations';
import { useFarmTaskManagementStore } from '../store';
import { MarkTaskAsCompletedAsManagerCommand, OverdueTask } from '../types';

@Component
export default class OverdueTasks extends Vue {

  readonly store = useFarmTaskManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  mounted(): void {
    this.store.getOverdueTasks()
      .catch((error) => showErrorResponse(error));
  }

  isTaskClickable(task: OverdueTask): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE)
      || task.idOfAssignedUser === this.authenticationStore.user!.id;
  }

  taskClicked(task: OverdueTask): void {
    const command: MarkTaskAsCompletedAsManagerCommand = {
      farmTaskId: task.farmTaskId,
      executionDate: task.executionDate,
    };

    this.store.markTaskAsCompleted(command)
      .then(() => this.store.getOverdueTasks())
      .then(() => showSuccessMessage('Die Aufgabe wurde als erledigt markiert.'))
      .catch((error) => showErrorResponse(error));
  }

  taskNote(task: OverdueTask): string {
    const dateAndTime = timeConfigurationTranslation(task.timeConfiguration, true)
      ? `${task.executionDate.formattedLabel('Am')} ${timeConfigurationTranslation(task.timeConfiguration, true)}`
      : `${task.executionDate.formattedLabel('Am')}`;

    return !!task.nameOfAssignedUser
      ? `${dateAndTime}, zugewiesen an ${task.nameOfAssignedUser}`
      : dateAndTime;
  }

}
