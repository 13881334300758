
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { SortableEvent } from 'sortablejs';
import { sortArray } from '@/directives/vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { Color, Strain, FarmManagerPermission } from '@/types';
import { ridingLessonDurationTranslation, strainTranslation } from '@/helpers/translations';
import CreateRidingLessonTypeDialog from './create-riding-lesson-type-dialog.vue';
import UpdateNameOfRidingLessonTypeDialog from './update-name-of-riding-lesson-type-dialog.vue';
import UpdateStrainOnHorseOfRidingLessonTypeDialog from './update-strain-on-horse-of-riding-lesson-type-dialog.vue';
import UpdateRequiredSpacesOfRidingLessonTypeDialog from './update-required-spaces-of-riding-lesson-type-dialog.vue';
import UpdateRegistrationInAdvanceOfRidingLessonTypeDialog from './update-registration-in-advance-of-riding-lesson-type-dialog.vue';
import UpdateDefaultDurationOfRidingLessonTypeDialog from './update-default-duration-of-riding-lesson-type-dialog.vue';
import UpdateHorsesForAssignmentOfRidingLessonTypeDialog from './update-horses-for-assignment-of-riding-lesson-type-dialog.vue';
import { useRidingLessonManagementStore } from '../store';
import { DisableRidingLessonTypeAsManagerCommand, EnableRidingLessonTypeAsManagerCommand, Facility, RidingLessonType, UpdateOrderOfRidingLessonTypesOfFacilityAsManagerCommand } from '../types';

@Component({
  components: {
    UpdateNameOfRidingLessonTypeDialog,
    UpdateStrainOnHorseOfRidingLessonTypeDialog,
    UpdateRequiredSpacesOfRidingLessonTypeDialog,
    CreateRidingLessonTypeDialog,
    UpdateRegistrationInAdvanceOfRidingLessonTypeDialog,
    UpdateDefaultDurationOfRidingLessonTypeDialog,
    UpdateHorsesForAssignmentOfRidingLessonTypeDialog,
  },
})
export default class RidingLessonTypes extends Vue {

  readonly store = useRidingLessonManagementStore();

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  readonly strainTranslation = strainTranslation;

  readonly colorMap: Record<Strain, Color> = {
    MINIMAL_STRAIN: Color['green-5'],
    MODERATE_STRAIN: Color['yellow-5'],
    EXTENSIVE_STRAIN: Color['red-5'],
  };

  ridingLessonTypes: RidingLessonType[] = [];

  get tableHeaders(): DataTableHeader[] {
    const tableHeaders: DataTableHeader[] = [
      { text: 'Name', value: 'name' },
      { text: 'Belastung für\'s Pferd', value: 'color' },
      { text: 'Platzbedarf', value: 'requiredSpaces' },
      { text: 'Standarddauer', value: 'defaultDuration' },
      { text: 'Max. Vorlaufszeit', value: 'registrationInAdvanceInDays' },
    ];

    if (this.store.configurationForRidingLessons?.areHorsesFilteredByRidingLessonType) {
      tableHeaders.push({ text: 'Pferde', value: 'horses' });
    }

    tableHeaders.push({ text: 'Aktionen', value: 'actions', align: 'end', width: 130 });

    return tableHeaders;
  }

  get isCreateRidingLessonTypeVisible(): boolean {
    return !!this.store.configurationForRidingLessons
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION);
  }

  get isUpdateRidingLessonTypeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION);
  }

  get isUpdateHorsesForAssignmentOfRidingLessonTypeVisible(): boolean {
    return (this.store.configurationForRidingLessons?.areHorsesFilteredByRidingLessonType ?? false)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION);
  }

  get isMoveRidingLessonTypeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION);
  }

  isEnableRidingLessonTypeVisible(ridingLessonType: RidingLessonType): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION)
      && !ridingLessonType.isEnabled;
  }

  isDisableRidingLessonTypeVisible(ridingLessonType: RidingLessonType): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION)
      && ridingLessonType.isEnabled;
  }

  mounted(): void {
    watch(() => this.facility, () => {
      this.ridingLessonTypes = this.facility.ridingLessonTypes;
    }, { deep: true, immediate: true });
  }

  defaultDurationText(ridingLessonType: RidingLessonType): string {
    return ridingLessonDurationTranslation(ridingLessonType.defaultDuration);
  }

  getItemClass(item: RidingLessonType): string {
    return item.isEnabled
      ? ''
      : 'disabled';
  }

  sortedForFacility(event: SortableEvent): void {
    sortArray(this.ridingLessonTypes, event);

    const sortedIdsOfRidingLessonTypes = this.ridingLessonTypes
      .map((ridingLessonType) => ridingLessonType.ridingLessonTypeId);
    const command: UpdateOrderOfRidingLessonTypesOfFacilityAsManagerCommand = {
      facilityId: this.facility.facilityId,
      sortedIdsOfRidingLessonTypes,
    };
    this.store.updateOrderOfRidingLessonTypesOfFacility(command)
      .then(() => showSuccessMessage('Die Reihenfolge wurde aktualisiert.'))
      .catch((error) => showErrorResponse(error));
  }

  async enableRidingLessonTypeAction(ridingLessonType: RidingLessonType): Promise<void> {
    const command: EnableRidingLessonTypeAsManagerCommand = {
      ridingLessonTypeId: ridingLessonType.ridingLessonTypeId,
    };

    return this.store.enableRidingLessonType(command)
      .then(() => showSuccessMessage('Die Unterrichtsart wurde aktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

  async disableRidingLessonTypeAction(ridingLessonType: RidingLessonType): Promise<void> {
    const command: DisableRidingLessonTypeAsManagerCommand = {
      ridingLessonTypeId: ridingLessonType.ridingLessonTypeId,
    };

    return this.store.disableRidingLessonType(command)
      .then(() => showSuccessMessage('Die Unterrichtsart wurde deaktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

  horsesAvailableForAssignmentCount(ridingLessonType: RidingLessonType): number {
    return ridingLessonType.idsOfHorsesAvailableForAssignment?.length ?? 0;
  }

}
