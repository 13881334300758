
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useFarmServiceOverviewStore } from '../store';
import { FarmServiceUsage } from '../types';

interface Controls extends FormControls {
  search: FormControl<string>;
}

@Component
export default class HorseTable extends Vue {

  readonly store = useFarmServiceOverviewStore();

  @Prop({ type: Array, required: true })
  readonly usages!: FarmServiceUsage[];

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Pferd', value: 'horse' },
    { text: 'Kommentar', value: 'comment' },
  ];

  form: Form<Controls> | null = null;

  get searchValue(): string | null {
    if (!this.form) {
      return null;
    }

    return getFormValues(this.form).search;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
        },
      },
    });
  }

  filterUsages(value: any, search: string | null, farmServiceUsage: FarmServiceUsage) {
    if (search === null) {
      return true;
    }

    const searchLowerCase = search.toLocaleLowerCase();

    if (farmServiceUsage.horse.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (farmServiceUsage.comment?.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    return false;
  }

}
