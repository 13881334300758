
import { Component, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useMyStableStore } from '../store';
import { DeleteHorseWeightEntryCommand, HorseWeightEntry } from '../types';

@Component
export default class HorseWeightHistory extends Vue {

  readonly store = useMyStableStore();

  get isDeleteHorseWeightEntryVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

  backClicked(): void {
    navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
  }

  async deleteHorseWeightEntryAction(horseWeight: HorseWeightEntry): Promise<void> {
    const command: DeleteHorseWeightEntryCommand = {
      horseWeightEntryId: horseWeight.horseWeightEntryId,
    };

    return this.store.deleteHorseWeightEntry(command)
      .then(() => showSuccessMessage('Eintrag wurde gelöscht.'))
      .then(() => {
        if (this.store.horseWeight!.history.length === 0) {
          navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
        }
      })
      .catch((error) => showErrorResponse(error));
  }

}
