
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Color, NumberRange, Time } from '@/types';
import { useFacilityReservationsManagementStore } from '../store';
import { CreateFacilityReservationActivityAsManagerCommand, Facility } from '../types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, minNumberRule, notCheckedWhenValueIsSetRule } from '@/components/form';

interface Controls extends FormControls {
  name: FormControl<string>;
  timeOptionIntervals: FormControl<NumberRange>;
  color: FormControl<Color>;
  requiredSpaces: FormControl<number>;
  reservationOnPreviousDayNotLaterThan: FormControl<Time>;
  isRiderAbleToUpdateStartedReservations: FormControl<boolean>;
  isRiderAbleToCancelStartedReservations: FormControl<boolean>;
  isRiderAbleToReserveMultipleParallelActivities: FormControl<boolean>;
}

@Component
export default class CreateFacilityReservationActivityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  readonly dialogMaxWidth = DialogWidth.large;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name der Aktivität',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        timeOptionIntervals: {
          label: 'Reservierungszeit',
          value: {
            from: 1,
            to: 2,
          },
          isRequired: true,
        },
        color: {
          label: 'Farbe zur Darstellung der Reservierungen',
          value: Color['green-5'],
          isRequired: true,
        },
        requiredSpaces: {
          label: 'Platzbedarf der Aktivität',
          value: 1,
          isRequired: true,
          rules: [
            minNumberRule(0),
          ],
        },
        reservationOnPreviousDayNotLaterThan: {
          label: 'Späteste Uhrzeit zur Reservierung am Folgetag',
          value: null,
          validateFormControlsOnInput: [
            'isRiderAbleToUpdateStartedReservations',
            'isRiderAbleToCancelStartedReservations',
          ],
        },
        isRiderAbleToUpdateStartedReservations: {
          label: 'Ein Reiter kann eine bereits gestartete Reservierung noch anpassen',
          value: false,
          rules: [],
        },
        isRiderAbleToCancelStartedReservations: {
          label: 'Ein Reiter kann eine bereits gestartete Reservierung noch stornieren',
          value: false,
          rules: [],
        },
        isRiderAbleToReserveMultipleParallelActivities: {
          label: 'Ein Reiter kann die Anlage mit dieser Aktivität parallel mehrfach reservieren',
          value: false,
        },
      },
    });

    form.controls.isRiderAbleToUpdateStartedReservations.rules!.push(notCheckedWhenValueIsSetRule(
      form.controls.reservationOnPreviousDayNotLaterThan,
      'Die späteste Uhrzeit zur Reservierung am Folgetag darf nicht gesetzt sein, wenn ein Reiter eine bereits gestartete Reservierung noch anpassen können soll'
    ));

    form.controls.isRiderAbleToCancelStartedReservations.rules!.push(notCheckedWhenValueIsSetRule(
      form.controls.reservationOnPreviousDayNotLaterThan,
      'Die späteste Uhrzeit zur Reservierung am Folgetag darf nicht gesetzt sein, wenn ein Reiter eine bereits gestartete Reservierung noch storniert können soll'
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateFacilityReservationActivityAsManagerCommand = {
      facilityId: this.facility.facilityId,
      name: formValues.name!,
      minTimeOptionIntervals: formValues.timeOptionIntervals!.from,
      maxTimeOptionIntervals: formValues.timeOptionIntervals!.to,
      requiredSpaces: formValues.requiredSpaces!,
      color: formValues.color!,
      reservationOnPreviousDayNotLaterThan: formValues.reservationOnPreviousDayNotLaterThan,
      isRiderAbleToUpdateStartedReservations: formValues.isRiderAbleToUpdateStartedReservations!,
      isRiderAbleToCancelStartedReservations: formValues.isRiderAbleToCancelStartedReservations!,
      isRiderAbleToReserveMultipleParallelActivities: formValues.isRiderAbleToReserveMultipleParallelActivities!,
    };

    this.store.createFacilityReservationActivity(command)
      .then(() => showSuccessMessage('Neue Aktivität wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
