
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth, arePermissionsNotEqual } from '@/helpers/data';
import { FarmManagerPermissions, UserRole } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { constructForm, Form, FormControl, FormControls, getFormValues, requiredWhenUserRoleIsSelectedRule, ValuesOfFormControls } from '@/components/form';
import { useUserManagementStore } from '../store';
import { UpdateEnabledPermissionsOfUserAsManagerWorkflow, UpdateUserRoleAsManagerWorkflow, User } from '../types';

interface Controls extends FormControls {
  role: FormControl<UserRole>;
  enabledPermissions: FormControl<FarmManagerPermissions>;
}

@Component
export default class UpdateUserRoleAndPermissionsDialog extends Vue {

  readonly store = useUserManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly user!: User;

  readonly dialogMaxWidth = DialogWidth.small;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get arePermissionsVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form!);

    return formValues.role === UserRole.ROLE_FARM_MANAGER;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        role: {
          label: 'Rolle',
          value: this.user.role,
          isRequired: true,
          transformValuesOnInput: (values: ValuesOfFormControls<Controls>): Partial<ValuesOfFormControls<Controls>> => {
            const transformedValues: Partial<ValuesOfFormControls<Controls>> = {};
            if (values.role === UserRole.ROLE_USER) {
              transformedValues.enabledPermissions = null;
            } else if (values.role === UserRole.ROLE_FARM_MANAGER
              && !this.user.enabledPermissions
            ) {
              transformedValues.enabledPermissions = {
                areAllPermissionsEnabled: true,
                permissions: [],
              };
            }

            return transformedValues;
          },
          validateFormControlsOnInput: [
            'enabledPermissions',
          ],
        },
        enabledPermissions: {
          label: 'Berechtigungen',
          value: this.user.enabledPermissions,
          rules: [],
        },
      },
    });

    form.controls.enabledPermissions.rules!.push(requiredWhenUserRoleIsSelectedRule(
      form.controls.role,
      UserRole.ROLE_FARM_MANAGER,
      'Mindestens eine Berechtigung muss ausgewählt werden.'
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    if (this.user.role !== formValues.role) {
      const command: UpdateUserRoleAsManagerWorkflow = {
        targetUserId: this.user.userId,
        role: formValues.role!,
        enabledPermissions: formValues.role === UserRole.ROLE_FARM_MANAGER
          ? formValues.enabledPermissions!
          : null,
      };

      this.store.updateUserRole(command)
        .then(() => showSuccessMessage('Die Rolle wurde aktualisiert.'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));

      return;
    }

    if (formValues.role === UserRole.ROLE_FARM_MANAGER
      && arePermissionsNotEqual(this.user.enabledPermissions, formValues.enabledPermissions)
    ) {
      const command: UpdateEnabledPermissionsOfUserAsManagerWorkflow = {
        targetUserId: this.user.userId,
        enabledPermissions: formValues.enabledPermissions!,
      };

      this.store.updateEnabledPermissionsOfUser(command)
        .then(() => showSuccessMessage('Die Berechtigungen wurden aktualisiert.'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));

      return;
    }

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
