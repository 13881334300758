
import { Component, Vue } from 'vue-property-decorator';
import { useAnalyticsStore } from '../store';

@Component
export default class OwnerActivityChart extends Vue {

  readonly store = useAnalyticsStore();

}
