
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { DataListItem } from '@/components/design-system';
import { useFreeJumpingFreeRunningManagementStore } from '../store';
import UpdateFreeJumpingFreeRunningConfigurationDialog from './update-free-jumping-free-running-configuration-dialog.vue';

@Component({
  components: {
    UpdateFreeJumpingFreeRunningConfigurationDialog,
  },
})
export default class FreeJumpingFreeRunningSettingsAdminComponent extends Vue {

  readonly store = useFreeJumpingFreeRunningManagementStore();

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'registrationInformation',
        label: 'Informationen für die Registrierung',
        value: this.store.configuration!.registrationInformation ?? '-',
      },
    ];
  }

  get isUpdateVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_WRITE);
  }

  mounted(): void {
    this.store.getConfiguration()
      .catch((error) => showErrorResponse(error));
  }

}
