
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { csvCompliantRule, maxLengthRule, requiredRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { useLedgerStore } from '../store';
import { FarmService, UpdateFarmServicePresentationAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    name: FormField<string|null>;
    description: FormField<string|null>;
  },
}

@Component
export default class UpdatePresentationOfFarmServiceDialog extends Vue {

  readonly store = useLedgerStore();

  @Prop({ type: Object, required: true })
  readonly farmService!: FarmService;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        name: {
          value: this.farmService.name,
          rules: [
            requiredRule(),
            maxLengthRule(255),
            csvCompliantRule(),
          ],
        },
        description: {
          value: this.farmService.description,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    };
  }

  formSubmitted(): void {
    const command: UpdateFarmServicePresentationAsManagerCommand = {
      farmServiceId: this.farmService.id,
      name: this.form!.fields.name.value!,
      description: this.form!.fields.description.value,
    };

    this.store.updateFarmServicePresentation(command)
      .then(() => showSuccessMessage('Die Darstellung wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
