
import { Component, Vue } from 'vue-property-decorator';
import { useLaborServicesManagementStore } from '../store';

@Component
export default class LaborServicesTabs extends Vue {

  readonly store = useLaborServicesManagementStore();

  destroyed(): void {
    this.store.$reset();
  }

}
