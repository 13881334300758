
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { moment } from '@/helpers';
import { useFreeJumpingFreeRunningManagementStore } from '../store';
import { CreateEventsAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    dates: FormField<string[]>;
  }
}

@Component
export default class CreateFreeJumpingFreeRunningEventsDialog extends Vue {

  readonly store = useFreeJumpingFreeRunningManagementStore();

  readonly size = DialogWidth.large;

  isDialogVisible = false;

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid
      || this.form.fields.dates.value.length === 0;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        dates: {
          value: [],
          rules: [],
        },
      },
    };
  }

  formatTitleDate(): string {
    const dateCount = this.form!.fields.dates.value.length;
    if (dateCount === 0) {
      return 'Kein Termin ausgewählt';
    }

    if (dateCount === 1) {
      return '1 Termin ausgewählt';
    }

    return `${this.form!.fields.dates.value.length} Termine ausgewählt`;
  }

  dateHasEvent(dateString: string): boolean {
    const relevantDay = moment(dateString);
    return this.store.events.some((event) => event.date.isSame(relevantDay, 'day'));
  }

  dateIsAllowed(dateString: string): boolean {
    return moment().isBefore(dateString)
      && !this.dateHasEvent(dateString);
  }

  getFormattedDate(date: string): string {
    return moment(date).format('ddd DD.MM.YYYY');
  }

  onSubmit(): void {
    const command: CreateEventsAsManagerCommand = {
      dates: this.form!.fields.dates.value.map((date) => moment(date)),
    };

    this.store.createEvents(command)
      .then(() => showSuccessMessage('Neue Termine wurde angelegt'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
