
import { Component, Vue } from 'vue-property-decorator';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { reservationDisplayNameTypeTranslation } from '@/helpers/translations';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DataListItem } from '@/components/design-system';
import { formatBoolean, formatTime } from '@/helpers';
import UpdateTimeOptionIntervalOfFacilityDialog from './update-time-option-interval-of-facility-dialog.vue';
import UpdateBookingInAdvanceOfFacilityDialog from './update-booking-in-advance-of-facility-dialog.vue';
import UpdateMaxUsageOfFacilityDialog from './update-max-usage-of-facility-dialog.vue';
import UpdateReservationDisplayNameTypeOfFacilityDialog from './update-reservation-display-name-type-of-facility-dialog.vue';
import UpdateBookingNoticeOfFacilityDialog from './update-booking-notice-of-facility-dialog.vue';
import UpdateMinDistanceToOwnBookingsWithDifferentHorsesOfFacilityDialog from './update-min-distance-to-own-bookings-with-different-horse-of-facility-dialog.vue';
import { useFacilityReservationsManagementStore } from '../store';
import { AllowParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand, ForbidParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand } from '../types';

@Component({
  components: {
    UpdateTimeOptionIntervalOfFacilityDialog,
    UpdateBookingInAdvanceOfFacilityDialog,
    UpdateMaxUsageOfFacilityDialog,
    UpdateMinDistanceToOwnBookingsWithDifferentHorsesOfFacilityDialog,
    UpdateReservationDisplayNameTypeOfFacilityDialog,
    UpdateBookingNoticeOfFacilityDialog,
  },
})
export default class FacilityConfiguration extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'timeOptionsInterval',
        label: 'Buchungstakt',
        value: `${this.store.currentFacility!.facilityReservationConfiguration.timeOptionInterval} Minuten`,
      },
      {
        key: 'bookingInAdvance',
        label: 'Max. Tage zur Reservierung im Voraus',
        value: `${this.store.currentFacility!.facilityReservationConfiguration.bookingInAdvance} ${this.store.currentFacility!.facilityReservationConfiguration.bookingInAdvance === 1 ? 'Tag' : 'Tage'}<br />Neuer Tag wird um ${formatTime(this.store.currentFacility!.facilityReservationConfiguration.timeToGrantAccessForNewDayInAdvance)} Uhr freigeschalten.`,
      },
      {
        key: 'maxTimeForHorseAndDay',
        label: 'Maximale tägliche Zeit pro Pferd',
        value: `${this.store.currentFacility!.facilityReservationConfiguration.maxIntervalsForHorseAndDay * this.store.currentFacility!.facilityReservationConfiguration.timeOptionInterval} Minuten`,
      },
      {
        key: 'minDistanceToOwnBookingsWithDifferentHorsesIntervals',
        label: 'Mindestabstand zwischen zwei Reservierungen des selben Reiters mit unterschiedlichen Pferden',
        value: `${this.store.currentFacility!.facilityReservationConfiguration.minDistanceToOwnBookingsWithDifferentHorsesIntervals
        * this.store.currentFacility!.facilityReservationConfiguration.timeOptionInterval} Minuten`,
      },
      {
        key: 'areParallelReservationsInOtherFacilitiesAllowed',
        label: 'Parallele Reservierung in einer anderen Anlage',
        value: formatBoolean(this.store.currentFacility!.facilityReservationConfiguration.areParallelReservationsInOtherFacilitiesAllowed),
      },
      {
        key: 'reservationDisplayNameType',
        label: 'Darstellung der Reservierungen',
        value: reservationDisplayNameTypeTranslation[this.store.currentFacility!.facilityReservationConfiguration.reservationDisplayNameType],
      },
      {
        key: 'bookingNotice',
        label: 'Buchungshinweis über dem Kalender',
        value: this.store.currentFacility!.facilityReservationConfiguration.bookingNotice ?? '-',
      },
    ];
  }

  get isUpdateFacilityTimeOptionIntervalVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get isUpdateFacilityBookingInAdvanceVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get isUpdateFacilityMaxUsageVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get isUpdateFacilityMinDistanceToOwnBookingsWithDifferentHorsesVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get isUpdateAreParallelReservationsInOtherFacilitiesAllowedVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get isUpdateFacilityReservationDisplayNameTypeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get isUpdateFacilityBookingNoticeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  async allowParallelReserverationInOtherFacilitiesForFacilityAction(): Promise<void> {
    const command: AllowParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand = {
      facilityId: this.store.currentFacilityId!,
    };

    return this.store.allowParallelReserverationInOtherFacilitiesForFacility(command)
      .then(() => showSuccessMessage('Parallele Reservierungen in anderen Anlagen sind nun erlaubt.'))
      .catch((error) => showErrorResponse(error));
  }

  async forbidParallelReserverationInOtherFacilitiesForFacilityAction(): Promise<void> {
    const command: ForbidParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand = {
      facilityId: this.store.currentFacilityId!,
    };

    return this.store.forbidParallelReserverationInOtherFacilitiesForFacility(command)
      .then(() => showSuccessMessage('Parallele Reservierungen in anderen Anlagen sind nun verboten.'))
      .catch((error) => showErrorResponse(error));
  }

}
