
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { RidingLessonId, Date } from '@/types';
import { constructForm, FormControl, Form, getFormValues, FormControls, ValuesOfFormControls } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { MoveRidingLessonRegistrationToDifferentRidingLessonAsManagerCommand, RidingLesson, RidingLessonRegistration, RidingLessonIdentification } from '../types';
import { useRidingLessonManagementStore } from '../store';

interface Controls extends FormControls {
  date: FormControl<Date>;
  ridingLesson: FormControl<RidingLessonId>;
}

@Component
export default class MoveRidingLessonRegistrationToDifferentRidingLessonDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly ridingLessonRegistration!: RidingLessonRegistration;

  @Prop({ type: Object, required: true })
  readonly currentRidingLesson!: RidingLesson;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get ridingLessonsWithoutCurrent(): RidingLessonIdentification[] {
    return this.store.ridingLessonsForSelection
      .filter((ridingLesson) => ridingLesson.ridingLessonId !== this.currentRidingLesson.ridingLessonId);
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      this.store.updateCurrentDateForRidingLessonsForSelection(this.store.currentDateForRidingLessons!)
        .catch((error) => showErrorResponse(error));
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        date: {
          label: 'Datum',
          value: Date.fromMoment(this.currentRidingLesson.from),
          isRequired: true,
          transformValuesOnInput: (): Partial<ValuesOfFormControls<Controls>> => ({
            ridingLesson: null,
          }),
          afterTransformationAndValidation: (date): void => {
            this.store.updateCurrentDateForRidingLessonsForSelection(date!)
              .catch((error) => showErrorResponse(error));
          },
        },
        ridingLesson: {
          label: 'Unterrichtsstunde',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: MoveRidingLessonRegistrationToDifferentRidingLessonAsManagerCommand = {
      ridingLessonRegistrationId: this.ridingLessonRegistration.ridingLessonRegistrationId,
      ridingLessonId: formValues.ridingLesson!,
    };
    this.store.moveRidingLessonRegistrationToDifferentRidingLesson(command)
      .then(() => showSuccessMessage('Die Anmeldung wurde verschoben.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  isDateAllowed(date: Date): boolean {
    return date.isAfterOrEqualTo(Date.today().subtract(7));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
