
import { Vue, Component } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { Date } from '@/types';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useRiderTasksStore } from '../store';
import { CreateTaskCommand } from '../types';

interface Controls extends FormControls {
  executionDate: FormControl<Date>;
  title: FormControl<string>;
  details: FormControl<string>;
}

@Component
export default class CreateTaskDialog extends Vue {

  readonly store = useRiderTasksStore();

  readonly dialogMaxWidth = DialogWidth.small;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  isDateAllowed(date: Date): boolean {
    return date.isAfterOrEqualTo(Date.today());
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateTaskCommand = {
      title: formValues.title!,
      executionDate: formValues.executionDate!,
      details: formValues.details,
    };

    this.store.createTask(command)
      .then(() => showSuccessMessage('Die Aufgabe wurde angelegt und die Hofverwalter wurden informiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        executionDate: {
          label: 'Gewünschtes Bearbeitungsdatum',
          value: Date.today(),
          isRequired: true,
        },
        title: {
          label: 'Titel',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        details: {
          label: 'Details',
          value: null,
        },
      },
    });
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
