
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { requiredRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { useLedgerStore } from '../store';
import { DefineFarmBankInformationAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    accountHolder: FormField<string|null>;
    iban: FormField<string|null>;
    bankName: FormField<string|null>;
  },
}

@Component
export default class DefineFarmBankInformationDialog extends Vue {

  readonly store = useLedgerStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        accountHolder: {
          value: null,
          rules: [
            requiredRule(),
          ],
        },
        iban: {
          value: null,
          rules: [
            requiredRule(),
          ],
        },
        bankName: {
          value: null,
          rules: [
            requiredRule(),
          ],
        },
      },
    };
  }

  formSubmitted(): void {
    const command: DefineFarmBankInformationAsManagerCommand = {
      farmBankInformation: {
        accountHolder: this.form!.fields.accountHolder.value!,
        iban: this.form!.fields.iban.value!,
        bankName: this.form!.fields.bankName.value!,
      },
    };

    this.store.defineFarmBankInformation(command)
      .then(() => showSuccessMessage('Die Bankverbindung wurde definiert'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
