
import { Component, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useMyStableStore } from '../store';
import { DeleteHorseVeterinarianAppointmentCommand, HorseVeterinarianAppointment } from '../types';
import ShowHorseVeterinarianAppointmentImageDialog from './show-horse-veterinarian-appointment-image-dialog.vue';

@Component({
  components: {
    ShowHorseVeterinarianAppointmentImageDialog,
  },
})
export default class HorseVeterinarianAppointmentsHistory extends Vue {

  readonly store = useMyStableStore();

  get isDeleteHorseVeterinarianAppointmentVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

  backClicked(): void {
    navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
  }

  async deleteHorseVeterinarianAppointmentAction(horseVeterinarianAppointment: HorseVeterinarianAppointment): Promise<void> {
    const command: DeleteHorseVeterinarianAppointmentCommand = {
      horseVeterinarianAppointmentId: horseVeterinarianAppointment.horseVeterinarianAppointmentId,
    };

    return this.store.deleteHorseVeterinarianAppointment(command)
      .then(() => showSuccessMessage('Tierarzt-Termin wurde gelöscht.'))
      .then(() => {
        if (this.store.horseVeterinarianAppointments!.history.length === 0) {
          navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
        }
      })
      .catch((error) => showErrorResponse(error));
  }

}
