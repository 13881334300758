import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { FacilityId, Moment, Time, TimeFrame } from '@/types';
import { moment } from '@/helpers';
import { useAuthenticationStore } from '@/application/authentication/store';
import { cancelFacilityReservation, cancelRidingLessonRegistration, dismissBookingNotice, getCalendarEventsForFacility, getFacilitiesOverview, getPreferredIntervals, getRidingLessonsAsCalendarEvents, registerForRidingLesson, reserveFacility, updateFacilityReservation, updatePreferredCalendarView } from './service';
import { CalendarEvent, CalendarRangeType, CancelFacilityReservationCommand, CancelRidingLessonRegistrationCommand, DismissBookingNoticeCommand, Facility, FacilityReservationActivity, GetCalendarEventsForFacilityQuery, GetRidingLessonsAsCalendarEventsQuery, PreferredIntervalsForFacilityReservationActivity, RegisterForRidingLessonCommand, ReserveFacilityCommand, RidingLessonCalendarEvent, UpdateFacilityReservationCommand, UpdatePreferredCalendarViewCommand } from './types';

interface BookingCalendarState {
  currentFacilityId: string | null;
  currentRangeFrom: Moment | null;
  currentRangeTo: Moment | null;
  currentCalendarRangeType: CalendarRangeType | null;
  facilities: Facility[];
  calendarEvents: {
    [id: FacilityId]: CalendarEvent[]
  };
  hasReserveFacilityTrialRunSucceeded: boolean | null;
  reserveFacilityTrailRunErrorMessage: string | null;
  hasUpdateFacilityReservationTrialRunSucceeded: boolean | null;
  updateFacilityReservationTrailRunErrorMessage: string | null;
  ridingLessonTimeFrame: TimeFrame | null;
  ridingLessonPlanEvents: RidingLessonCalendarEvent[];
  preferredIntervals: PreferredIntervalsForFacilityReservationActivity[] | null;

  getFacilitiesOverviewStatus: ActionStatus;
  getCalendarEventsForFacilityStatus: ActionStatus;
  getRidingLessonsAsCalendarEventsStatus: ActionStatus;
  reserveFacilityStatus: ActionStatus;
  updateFacilityReservationStatus: ActionStatus;
  cancelFacilityReservationStatus: ActionStatus;
  dismissBookingNoticeStatus: ActionStatus;
  registerForRidingLessonStatus: ActionStatus;
  cancelRidingLessonRegistrationStatus: ActionStatus;
  updatePreferredCalendarViewStatus: ActionStatus;
  getPreferredIntervalsStatus: ActionStatus;
}

function initialState(): BookingCalendarState {
  return {
    currentFacilityId: null,
    currentRangeFrom: null,
    currentRangeTo: null,
    currentCalendarRangeType: null,
    facilities: [],
    calendarEvents: {},
    hasReserveFacilityTrialRunSucceeded: null,
    reserveFacilityTrailRunErrorMessage: null,
    hasUpdateFacilityReservationTrialRunSucceeded: null,
    updateFacilityReservationTrailRunErrorMessage: null,
    ridingLessonTimeFrame: null,
    ridingLessonPlanEvents: [],
    preferredIntervals: null,

    getFacilitiesOverviewStatus: ActionStatus.None,
    getCalendarEventsForFacilityStatus: ActionStatus.None,
    getRidingLessonsAsCalendarEventsStatus: ActionStatus.None,
    reserveFacilityStatus: ActionStatus.None,
    updateFacilityReservationStatus: ActionStatus.None,
    cancelFacilityReservationStatus: ActionStatus.None,
    dismissBookingNoticeStatus: ActionStatus.None,
    registerForRidingLessonStatus: ActionStatus.None,
    cancelRidingLessonRegistrationStatus: ActionStatus.None,
    updatePreferredCalendarViewStatus: ActionStatus.None,
    getPreferredIntervalsStatus: ActionStatus.None,
  };
}

export const useBookingCalendarStore = defineStore('bookingCalendar', {
  state: (): BookingCalendarState => initialState(),
  getters: {
    currentFacility: (state: BookingCalendarState): Facility | null => state.currentFacilityId
      ? state.facilities.find((facility) => facility.facilityId === state.currentFacilityId) || null
      : null,
    facilityReservationActivitiesForCurrentFacility: (state: BookingCalendarState): FacilityReservationActivity[] => state.currentFacilityId
      ? state.facilities
        .find((facility) => facility.facilityId === state.currentFacilityId)
        ?.facilityReservationActivities ?? []
      : [],
    calendarEventsByFacility: (state: BookingCalendarState): CalendarEvent[] => state.currentFacilityId
      && state.calendarEvents[state.currentFacilityId]
      || [],
    calendarEventsByFacilityWithFixedEnd: (state: BookingCalendarState): CalendarEvent[] => state.currentFacilityId
      && state.calendarEvents[state.currentFacilityId]
      ? state.calendarEvents[state.currentFacilityId]
        .map((calendarEvent) => Time.fromDate(calendarEvent.to).isMidnight
          ? { ...calendarEvent, end: calendarEvent.to.subtract(1, 'minute').format('YYYY-MM-DD HH:mm') }
          : calendarEvent)
      : [],
    ridingLessonPlanEventsWithFixedEnd: (state: BookingCalendarState): CalendarEvent[] => state.ridingLessonPlanEvents
      .map((calendarEvent) => Time.fromDate(calendarEvent.to).isMidnight
        ? { ...calendarEvent, end: calendarEvent.to.subtract(1, 'minute').format('YYYY-MM-DD HH:mm') }
        : calendarEvent),
    isGetFacilitiesOverviewProcessing: (state: BookingCalendarState): boolean =>
      state.getFacilitiesOverviewStatus === ActionStatus.InProgress,
    isGetRidingLessonsAsCalendarEventsProcessing: (state: BookingCalendarState): boolean =>
      state.getRidingLessonsAsCalendarEventsStatus === ActionStatus.InProgress,
    isReserveFacilityProcessing: (state: BookingCalendarState): boolean =>
      state.reserveFacilityStatus === ActionStatus.InProgress,
    isDeleteFacilityReservationProcessing: (state: BookingCalendarState): boolean =>
      state.cancelFacilityReservationStatus === ActionStatus.InProgress,
    isDismissBookingNoticeProcessing: (state: BookingCalendarState): boolean =>
      state.dismissBookingNoticeStatus === ActionStatus.InProgress,
    isGetCalendarEventsForFacilityProcessing: (state: BookingCalendarState): boolean =>
      state.getCalendarEventsForFacilityStatus === ActionStatus.InProgress,
    isRegisterForRidingLessonProcessing: (state: BookingCalendarState): boolean =>
      state.registerForRidingLessonStatus === ActionStatus.InProgress,
    isCancelRidingLessonRegistrationProcessing: (state: BookingCalendarState): boolean =>
      state.cancelRidingLessonRegistrationStatus === ActionStatus.InProgress,
    isUpdatePreferredCalendarViewProcessing: (state: BookingCalendarState): boolean =>
      state.updatePreferredCalendarViewStatus === ActionStatus.InProgress,
    isUpdateFacilityReservationProcessing: (state: BookingCalendarState): boolean =>
      state.updateFacilityReservationStatus === ActionStatus.InProgress,
    isGetPreferredIntervalsProcessing: (state: BookingCalendarState): boolean =>
      state.getPreferredIntervalsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentFacility(facilityId: string): Promise<void> {
      this.currentFacilityId = facilityId;
      return Promise.resolve();
    },

    initializeTimeRangeAndCalendarTypeForFacility(rangeFrom: Moment, rangeTo: Moment, calendarRangeType: CalendarRangeType): Promise<void> {
      this.currentRangeFrom = rangeFrom;
      this.currentRangeTo = rangeTo;
      this.currentCalendarRangeType = calendarRangeType;

      return this.getCalendarEventsForFacility();
    },

    updateCurrentRangeForFacility(rangeFrom: Moment, rangeTo: Moment): Promise<void> {
      this.currentRangeFrom = rangeFrom;
      this.currentRangeTo = rangeTo;

      return this.getCalendarEventsForFacility();
    },

    /**
     * When type is changed to week, the range is simply updated to the week of the current range day.
     * When type is changed to day, the range is updated to ether today (if it's the current week) or monday of the range week.
     */
    updateCalendarTypeForFacility(calendarType: CalendarRangeType): Promise<void> {
      this.currentCalendarRangeType = calendarType;
      if (calendarType === CalendarRangeType.day) {
        const now = moment();
        const rangeFrom = now.isBetween(this.currentRangeFrom!, this.currentRangeTo!)
          ? now.startOf('day')
          : this.currentRangeFrom!.isoWeekday(1);

        this.currentRangeFrom = rangeFrom;
        this.currentRangeTo = rangeFrom.add(1, 'day');
      } else {
        const rangeFrom = this.currentRangeFrom!.isoWeekday(1);
        this.currentRangeFrom = rangeFrom;
        this.currentRangeTo = rangeFrom.add(7, 'days');
      }

      return this.getCalendarEventsForFacility();
    },

    moveCalendarRangeForwardForFacility(): Promise<void> {
      const rangeFrom = this.currentCalendarRangeType === CalendarRangeType.day
        ? this.currentRangeFrom!.add(1, 'day')
        : this.currentRangeFrom!.add(7, 'days');
      const rangeTo = this.currentCalendarRangeType === CalendarRangeType.day
        ? rangeFrom.add(1, 'day')
        : rangeFrom.add(7, 'days');

      this.currentRangeFrom = rangeFrom;
      this.currentRangeTo = rangeTo;

      return this.getCalendarEventsForFacility();
    },

    moveCalendarRangeBackwardsForFacility(): Promise<void> {
      const rangeFrom = this.currentCalendarRangeType === CalendarRangeType.day
        ? this.currentRangeFrom!.subtract(1, 'day')
        : this.currentRangeFrom!.subtract(7, 'days');
      const rangeTo = this.currentCalendarRangeType === CalendarRangeType.day
        ? rangeFrom.add(1, 'day')
        : rangeFrom.add(7, 'days');

      this.currentRangeFrom = rangeFrom;
      this.currentRangeTo = rangeTo;

      return this.getCalendarEventsForFacility();
    },

    initializeTimeRangeAndCalendarTypeForRidingLessons(
      rangeFrom: Moment,
      rangeTo: Moment,
      calendarRangeType: CalendarRangeType
    ): Promise<void> {
      this.currentRangeFrom = rangeFrom;
      this.currentRangeTo = rangeTo;
      this.currentCalendarRangeType = calendarRangeType;

      return this.getRidingLessonsAsCalendarEvents();
    },

    updateCurrentRangeForRidingLesson(rangeFrom: Moment, rangeTo: Moment): Promise<void> {
      this.currentRangeFrom = rangeFrom;
      this.currentRangeTo = rangeTo;

      return this.getRidingLessonsAsCalendarEvents();
    },

    /**
     * When type is changed to week, the range is simply updated to the week of the current range day.
     * When type is changed to day, the range is updated to ether today (if it's the current week) or monday of the range week.
     */
    updateCalendarTypeForRidingLesson(calendarType: CalendarRangeType): Promise<void> {
      this.currentCalendarRangeType = calendarType;
      if (calendarType === CalendarRangeType.day) {
        const now = moment();
        const rangeFrom = now.isBetween(this.currentRangeFrom!, this.currentRangeTo!)
          ? now.startOf('day')
          : this.currentRangeFrom!.isoWeekday(1);

        this.currentRangeFrom = rangeFrom;
        this.currentRangeTo = rangeFrom.add(1, 'day');
      } else {
        const rangeFrom = this.currentRangeFrom!.isoWeekday(1);
        this.currentRangeFrom = rangeFrom;
        this.currentRangeTo = rangeFrom.add(7, 'days');
      }

      return this.getRidingLessonsAsCalendarEvents();
    },

    moveCalendarRangeForwardForRidingLessons(): Promise<void> {
      const rangeFrom = this.currentCalendarRangeType === CalendarRangeType.day
        ? this.currentRangeFrom!.add(1, 'day')
        : this.currentRangeFrom!.add(7, 'days');
      const rangeTo = this.currentCalendarRangeType === CalendarRangeType.day
        ? rangeFrom.add(1, 'day')
        : rangeFrom.add(7, 'days');

      this.currentRangeFrom = rangeFrom;
      this.currentRangeTo = rangeTo;

      return this.getRidingLessonsAsCalendarEvents();
    },

    moveCalendarRangeBackwardsForRidingLessons(): Promise<void> {
      const rangeFrom = this.currentCalendarRangeType === CalendarRangeType.day
        ? this.currentRangeFrom!.subtract(1, 'day')
        : this.currentRangeFrom!.subtract(7, 'days');
      const rangeTo = this.currentCalendarRangeType === CalendarRangeType.day
        ? rangeFrom.add(1, 'day')
        : rangeFrom.add(7, 'days');

      this.currentRangeFrom = rangeFrom;
      this.currentRangeTo = rangeTo;

      return this.getRidingLessonsAsCalendarEvents();
    },

    resetReserveFacilityTrialRun(): Promise<void> {
      this.hasReserveFacilityTrialRunSucceeded = null;
      this.reserveFacilityTrailRunErrorMessage = null;

      return Promise.resolve();
    },

    resetUpdateFacilityReservationTrialRun(): Promise<void> {
      this.hasUpdateFacilityReservationTrialRunSucceeded = null;
      this.updateFacilityReservationTrailRunErrorMessage = null;

      return Promise.resolve();
    },

    // -- Queries

    getFacilitiesOverview(): Promise<void> {
      const { getFacilitiesOverviewStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFacilitiesOverviewStatus,
        () => getFacilitiesOverview(attachFarmAndUserProperties({}))
          .then((facilities) => {
            this.facilities = facilities;
          })
      );
    },

    getCalendarEventsForFacility(): Promise<void> {
      const query: GetCalendarEventsForFacilityQuery = {
        facilityId: this.currentFacilityId!,
        rangeFrom: this.currentRangeFrom!,
        rangeTo: this.currentRangeTo!,
      };
      const { getCalendarEventsForFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCalendarEventsForFacilityStatus,
        () => getCalendarEventsForFacility(attachFarmAndUserProperties(query))
          .then((calendarEvents) => {
            this.calendarEvents = {
              ...this.calendarEvents,
              [query.facilityId]: calendarEvents,
            };
          })
      );
    },

    getRidingLessonsAsCalendarEvents(): Promise<void> {
      const query: GetRidingLessonsAsCalendarEventsQuery = {
        rangeFrom: this.currentRangeFrom!,
        rangeTo: this.currentRangeTo!,
      };
      const { getRidingLessonsAsCalendarEventsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getRidingLessonsAsCalendarEventsStatus,
        () => getRidingLessonsAsCalendarEvents(attachFarmAndUserProperties(query))
          .then(async (response) => {
            this.ridingLessonTimeFrame = response.timeFrame;
            this.ridingLessonPlanEvents = response.calendarEvents;
          })
      );
    },

    getPreferredIntervals(): Promise<void> {
      const { getPreferredIntervalsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPreferredIntervalsStatus,
        () => getPreferredIntervals(attachFarmAndUserProperties({}))
          .then((preferredIntervals) => {
            this.preferredIntervals = preferredIntervals;
          })
      );
    },

    // -- Commands

    reserveFacility(command: ReserveFacilityCommand, isTrialRun: boolean): Promise<void> {
      const { reserveFacilityStatus } = storeToRefs(this);
      this.hasReserveFacilityTrialRunSucceeded = null;
      return wrapActionWithProgress(
        reserveFacilityStatus,
        () => reserveFacility(attachFarmAndUserProperties(command), isTrialRun)
          .then(() => {
            this.reserveFacilityTrailRunErrorMessage = null;
            if (isTrialRun) {
              this.hasReserveFacilityTrialRunSucceeded = true;
              return Promise.resolve();
            } else {
              return this.getCalendarEventsForFacility();
            }
          })
          .catch((error) => {
            if (isTrialRun) {
              this.hasReserveFacilityTrialRunSucceeded = false;
              this.reserveFacilityTrailRunErrorMessage = error?.response?.data?.message ?? 'Die Anlage kann nicht reserviert werden';
            }
            return Promise.reject(error);
          })
      );
    },

    updateFacilityReservation(command: UpdateFacilityReservationCommand, isTrialRun: boolean): Promise<void> {
      const { updateFacilityReservationStatus } = storeToRefs(this);
      this.hasUpdateFacilityReservationTrialRunSucceeded = null;
      return wrapActionWithProgress(
        updateFacilityReservationStatus,
        () => updateFacilityReservation(attachFarmAndUserProperties(command), isTrialRun)
          .then(() => {
            this.updateFacilityReservationTrailRunErrorMessage = null;
            if (isTrialRun) {
              this.hasUpdateFacilityReservationTrialRunSucceeded = true;
              return Promise.resolve();
            } else {
              return this.getCalendarEventsForFacility();
            }
          })
          .catch((error) => {
            if (isTrialRun) {
              this.hasUpdateFacilityReservationTrialRunSucceeded = false;
              this.updateFacilityReservationTrailRunErrorMessage = error?.response?.data?.message
                ?? 'Die Reservierung kann nicht angepasst werden';
            }
            return Promise.reject(error);
          })
      );
    },

    cancelFacilityReservation(command: CancelFacilityReservationCommand): Promise<void> {
      const { cancelFacilityReservationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        cancelFacilityReservationStatus,
        () => cancelFacilityReservation(attachFarmAndUserProperties(command))
      );
    },

    dismissBookingNotice(command: DismissBookingNoticeCommand): Promise<void> {
      const { dismissBookingNoticeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        dismissBookingNoticeStatus,
        () => dismissBookingNotice(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    registerForRidingLesson(command: RegisterForRidingLessonCommand): Promise<void> {
      const { registerForRidingLessonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        registerForRidingLessonStatus,
        () => registerForRidingLesson(attachFarmAndUserProperties(command))
      );
    },

    cancelRidingLessonRegistration(command: CancelRidingLessonRegistrationCommand): Promise<void> {
      const { cancelRidingLessonRegistrationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        cancelRidingLessonRegistrationStatus,
        () => cancelRidingLessonRegistration(attachFarmAndUserProperties((command)))
      );
    },

    updatePreferredCalendarView(command: UpdatePreferredCalendarViewCommand): Promise<void> {
      const { updatePreferredCalendarViewStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePreferredCalendarViewStatus,
        () => updatePreferredCalendarView(attachFarmAndUserProperties((command)))
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

  },
});
