
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActionStatus } from '@/application/types';
import { uuid } from '@/helpers';

@Component
export default class List extends Vue {

  @Prop({ type: Array, required: true })
  readonly items!: any[];

  @Prop({ default: null })
  readonly itemKey!: ((item: any) => string) | string | null;

  @Prop({ type: Number, default: null })
  readonly loadingStatus!: ActionStatus | null;

  @Prop({ type: String, default: 'Keine Elemente vorhanden' })
  readonly noItemsText!: String;

  @Prop({ type: Boolean, default: false })
  readonly isDescriptionShownWithoutTruncation!: boolean;

  @Prop({ type: Function, default: null })
  readonly isItemClickable!: ((item: any) => boolean) | null;

  @Prop({ type: Function, default: null })
  readonly isItemHiddenOnPrint!: ((item: any) => boolean) | null;

  @Prop({ type: Boolean, default: false })
  readonly isWithoutIcon!: boolean;

  readonly listId = uuid();

  get isLoading(): boolean {
    return this.loadingStatus === ActionStatus.InProgress;
  }

  get isNoItemsVisible(): boolean {
    return (this.loadingStatus === null
        || this.loadingStatus === ActionStatus.Successful
      ) && this.items.length === 0;
  }

  get hasItemClickedHandler(): boolean {
    return this.$listeners
      && !!this.$listeners['item-clicked'];
  }

  formattedKey(item: any, index: number): string | number {
    if (this.itemKey === null) {
      return index;
    }

    return typeof this.itemKey === 'string'
      ? item[this.itemKey]
      : this.itemKey(item);
  }

  listItemKey(item: any, index: number): string {
    return `${this.listId}-item-${this.formattedKey(item, index)}`;
  }

  dividerItemKey(item: any, index: number): string {
    return `${this.listId}-divider-${this.formattedKey(item, index)}`;
  }

  itemClicked(item: any): void {
    if (this.isItemClickable === null
      || this.isItemClickable(item)
    ) {
      this.$emit('item-clicked', item);
    }
  }

  isItemStyledAsClickable(item: any): boolean {
    return this.hasItemClickedHandler
      && (this.isItemClickable === null
        || this.isItemClickable(item)
      );
  }

}
