
import { Component, Vue } from 'vue-property-decorator';
import { useMyStableStore } from '../store';
import HorseWeightCard from './horse-weight-card.vue';
import HorseDrugsCard from './horse-drugs-card.vue';
import HorseFarrierAppointmentsCard from './horse-farrier-appointments-card.vue';
import HorseVeterinarianAppointmentsCard from './horse-veterinarian-appointments-card.vue';
import VaccinationCertificate from './vaccination-certificate-view.vue';
import HorseWormingTreatmentsCard from './horse-worming-treatments-card.vue';

@Component({
  components: {
    HorseWeightCard,
    HorseDrugsCard,
    HorseFarrierAppointmentsCard,
    HorseVeterinarianAppointmentsCard,
    HorseWormingTreatmentsCard,
    VaccinationCertificate,
  },
})
export default class MedicalRecord extends Vue {

  readonly store = useMyStableStore();

  get isProcessing(): boolean {
    return this.store.isGetWeightForHorseProcessing
      || this.store.isGetDrugsForHorseProcessing
      || this.store.isGetFarrierAppointmentsForHorseProcessing
      || this.store.isGetWormingTreatmentsForHorseProcessing;
  }

}
