
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useDashboardStore } from '../store';
import { isFeatureVisibleForRiders } from '@/application/authentication/helper';
import { FeatureVisibleForRiders } from '@/types';
import NextAppointments from './next-appointments.vue';
import HorseShareInvites from './horse-share-invites.vue';
import Notifications from './notifications.vue';
import PenStatus from './pen-status.vue';

@Component({
  components: {
    PenStatus,
    Notifications,
    NextAppointments,
    HorseShareInvites,
  },
})
export default class Dashboard extends Vue {

  readonly store = useDashboardStore();

  get isPenStatusVisible(): boolean {
    return isFeatureVisibleForRiders(FeatureVisibleForRiders.PEN_STATUS);
  }

  mounted(): void {
    this.store.getHorseShareInvites()
      .catch((error) => showErrorResponse(error));
  }

}
