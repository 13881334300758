import { useAuthenticationStore } from '@/application/authentication/store';

export function formatCurrency(cents: any, dashForZero?: boolean): string {
  if (typeof cents !== 'number') {
    return cents;
  }

  if (dashForZero && cents === 0) {
    return '–';
  }

  const farm = useAuthenticationStore().user!.farm!;

  const formatter = new Intl.NumberFormat(farm.locale, {
    style: 'currency',
    currency: farm.currency,
    minimumFractionDigits: 2,
  });

  return formatter.format(cents / 100);
}

export function formatNumber(number: any, withThousandsSeparators = true): string {
  if (typeof number !== 'number') {
    return number;
  }

  const locale = useAuthenticationStore().user?.farm?.locale || 'de-DE';

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: withThousandsSeparators,
  });

  return formatter.format(number);
}
