
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { UserId, FarmManagerPermission } from '@/types';
import { constructForm, Form, FormControl, FormControls, FormControlValue } from '@/components/form';
import AssignTaskDialog from './assign-task-dialog.vue';
import CompleteTaskDialog from './complete-task-dialog.vue';
import RejectTaskDialog from './reject-task-dialog.vue';
import { useRiderTaskManagementStore } from '../store';

interface Controls extends FormControls {
  user: FormControl<UserId>;
}

@Component({
  components: {
    AssignTaskDialog,
    CompleteTaskDialog,
    RejectTaskDialog,
  },
})
export default class NewTasks extends Vue {

  readonly store = useRiderTaskManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Bearbeitungsdatum', value: 'executionDate' },
    { text: 'Auftraggeber', value: 'user' },
    { text: 'Beschreibung', value: 'description' },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 130 },
  ];

  form: Form<Controls> | null = null;

  get isAssignTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE);
  }

  get isCompleteTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE);
  }

  get isRejectTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_WRITE);
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.store.newTasksTotalCount === null
      || this.store.newTasks.length >= this.store.newTasksTotalCount;
  }

  get noDataText(): string {
    return this.store.newTasksFilteredByUser
      ? 'Für diesen Benutzer wurden alle Aufgaben bearbeitet.'
      : 'Alle Aufgaben wurden bearbeitet.';
  }

  mounted(): void {
    this.store.getNewTasks()
      .catch((error) => showErrorResponse(error));

    this.store.getUsersWithTasks()
      .catch((error) => showErrorResponse(error));

    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        user: {
          label: 'Benutzer',
          value: null,
          afterTransformationAndValidation: (value: FormControlValue<UserId>) => {
            this.store.filterNewTasksByUser(value)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  onLoadMoreClicked(): void {
    this.store.increaseNewTasksLimit()
      .catch((error) => showErrorResponse(error));
  }

  taskAssigned(): void {
    this.$emit('task-assigned');
    this.store.getNewTasks()
      .catch((error) => showErrorResponse(error));
  }

  taskCompleted(): void {
    this.$emit('task-completed');
    this.store.getNewTasks()
      .catch((error) => showErrorResponse(error));
  }

  taskRejected(): void {
    this.$emit('task-rejected');
    // The new tasks are already loaded through the store
  }

}
