
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { uuid } from '@/helpers';
import { isFeatureEnabled } from '@/application/authentication/helper';
import { constructForm, Form, FormControl, FormControls, getFormValues, requiredWhenControlIsFilledRule } from '@/components/form';
import { Feature, Language, Name, PhoneNumber, Address, Link, OrganizationName, PersonIdList, CustomFieldValues, Salutation } from '@/types';
import { CreateOrganizationAsManagerWorkflow } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  name: FormControl<OrganizationName>;
  idsOfAssignedPersons: FormControl<PersonIdList>;
  address: FormControl<Address>;
  companyForInvoiceAddress: FormControl<string>;
  salutationOfContactPersonForInvoiceAddress: FormControl<Salutation>;
  nameOfContactPersonForInvoiceAddress: FormControl<Name>;
  addressForInvoiceAddress: FormControl<Address>;
  landlinePhoneNumber: FormControl<PhoneNumber>;
  mobilePhoneNumber: FormControl<PhoneNumber>;
  language: FormControl<Language>;
  website: FormControl<Link>;
  customFields: FormControl<CustomFieldValues>;
  comment: FormControl<string>;
}

@Component
export default class CreateOrganizationDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  activePanel = [0, 1, 2, 3, 4];

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isInvoiceAddressVisible(): boolean {
    return isFeatureEnabled(Feature.LEDGER_INVOICING);
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.activePanel = [0, 1, 2, 3, 4];
        Promise.all([
          this.store.getPersonsForSelection(),
          this.store.getCustomFields(),
        ])
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => {
            showErrorResponse(error);
            this.closeDialog();
          });
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
        },
        idsOfAssignedPersons: {
          label: 'Zugeordnete Personen',
          value: null,
          isRequired: true,
        },
        address: {
          label: 'Adresse',
          value: null,
        },
        companyForInvoiceAddress: {
          label: 'Firmenname',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'addressForInvoiceAddress',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'addressForInvoiceAddress',
          ],
        },
        salutationOfContactPersonForInvoiceAddress: {
          label: 'Anrede des Ansprechpartners',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'companyForInvoiceAddress',
            'addressForInvoiceAddress',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'companyForInvoiceAddress',
            'addressForInvoiceAddress',
          ],
        },
        nameOfContactPersonForInvoiceAddress: {
          label: 'Name des Ansprechpartners',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'companyForInvoiceAddress',
            'addressForInvoiceAddress',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'companyForInvoiceAddress',
            'addressForInvoiceAddress',
          ],
        },
        addressForInvoiceAddress: {
          label: 'Adresse',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'companyForInvoiceAddress',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'companyForInvoiceAddress',
          ],
        },
        landlinePhoneNumber: {
          label: 'Festnetz-Telefonnummer',
          value: null,
        },
        mobilePhoneNumber: {
          label: 'Mobil-Telefonnummer',
          value: null,
        },
        language: {
          label: 'Sprache',
          value: Language.de,
          isRequired: true,
        },
        website: {
          label: 'Website',
          value: null,
        },
        customFields: {
          label: 'Spezifische Daten',
          value: null,
        },
        comment: {
          label: 'Kommentar',
          value: null,
        },
      },
    });

    form.controls.companyForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.salutationOfContactPersonForInvoiceAddress,
      'Die Firma muss angegeben werden, wenn die Anrede gefüllt ist.',
    ));
    form.controls.companyForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.nameOfContactPersonForInvoiceAddress,
      'Die Firma muss angegeben werden, wenn der Name des Ansprechpartners gefüllt ist.',
    ));
    form.controls.companyForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.addressForInvoiceAddress,
      'Die Firma muss angegeben werden, wenn die Adresse gefüllt ist.',
    ));

    form.controls.addressForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.salutationOfContactPersonForInvoiceAddress,
      'Die Adresse muss angegeben werden, wenn die Anrede gefüllt ist.',
    ));
    form.controls.addressForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.companyForInvoiceAddress,
      'Die Adresse muss angegeben werden, wenn die Firma gefüllt ist.',
    ));
    form.controls.addressForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.nameOfContactPersonForInvoiceAddress,
      'Die Adresse muss angegeben werden, wenn der Name des Ansprechpartners gefüllt ist.',
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateOrganizationAsManagerWorkflow = {
      organizationId: uuid(),
      name: formValues.name!,
      idsOfAssignedPersons: formValues.idsOfAssignedPersons!,
      address: formValues.address,
      invoiceAddress: formValues.companyForInvoiceAddress
        && formValues.addressForInvoiceAddress
        ? {
          company: formValues.companyForInvoiceAddress,
          salutationOfContactPerson: formValues.salutationOfContactPersonForInvoiceAddress,
          nameOfContactPerson: formValues.nameOfContactPersonForInvoiceAddress,
          address: formValues.addressForInvoiceAddress,
        }
        : null,
      landlinePhoneNumber: formValues.landlinePhoneNumber,
      mobilePhoneNumber: formValues.mobilePhoneNumber,
      language: formValues.language!,
      website: formValues.website,
      customFields: formValues.customFields,
      comment: formValues.comment,
    };

    this.store.createOrganization(command)
      .then(() => showSuccessMessage('Die Organisation wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
