
import { Component, Vue } from 'vue-property-decorator';
import HorseDetails from './horse-details.vue';
import { useMyStableStore } from '../store';

@Component({
  components: {
    HorseDetails,
  },
})
export default class HorseDetailsLoadingWrapper extends Vue {

  readonly store = useMyStableStore();

}
