
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { formatMinWeekday } from '@/helpers';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { FacilityReservationActivityId, HorseId, Moment, Time, TimeFrame } from '@/types';
import { momentWithTime } from '@/helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useBookingCalendarStore } from '../store';
import { FacilityReservationActivity, PreferredIntervalsForFacilityReservationActivity, ReserveFacilityCommand } from '../types';

interface Controls extends FormControls {
  facilityReservationActivity: FormControl<FacilityReservationActivityId>;
  timeFrame: FormControl<TimeFrame>;
  horseId: FormControl<HorseId>;
  comment: FormControl<string>;
}

@Component
export default class ReserveFacilityDialog extends Vue {

  readonly store = useBookingCalendarStore();
  readonly myStableStore = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  openedWithTimestamp: Moment | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid
      || this.store.hasReserveFacilityTrialRunSucceeded !== true;
  }

  get availableSpaces(): number {
    return this.store.currentFacility!.spaces || 0;
  }

  get isSpacesInformationShown(): boolean {
    return this.availableSpaces > 1 || false;
  }

  get translatedShortWeekday(): string {
    return this.openedWithTimestamp
      ? formatMinWeekday(this.openedWithTimestamp)
      : '';
  }

  get selectedFacilityReservationActivity(): FacilityReservationActivity | null {
    if (!this.form) {
      return null;
    }

    const formValues = getFormValues(this.form);

    return this.store.facilityReservationActivitiesForCurrentFacility
      .find((activity) => activity.facilityReservationActivityId === formValues.facilityReservationActivity) ?? null;
  }

  get preferredIntervals(): PreferredIntervalsForFacilityReservationActivity | null {
    if (!this.form
      || this.store.preferredIntervals === null
    ) {
      return null;
    }

    const formValues = getFormValues(this.form);
    return this.store.preferredIntervals
      .find((activity) => activity.facilityReservationActivityId === formValues.facilityReservationActivity) ?? null;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (!isDialogVisible) {
        this.form = null;
        this.store.resetReserveFacilityTrialRun();
      }
    });
  }

  // noinspection JSUnusedGlobalSymbols Method looks unused, but is called from the calendar
  public showDialogWith(openedWithTimestamp: Moment): void {
    this.openedWithTimestamp = openedWithTimestamp;

    this.form = this.buildForm(openedWithTimestamp);
    this.isDialogVisible = true;

    this.validateReservation();
  }

  buildForm(openedWithTimestamp: Moment): Form<Controls> {
    const initialFacilityReservationActivity = this.store.facilityReservationActivitiesForCurrentFacility[0];
    const initialFacilityReservationActivityId = initialFacilityReservationActivity.facilityReservationActivityId;

    const preferredIntervalsForActivity = this.store.preferredIntervals
      ? this.store.preferredIntervals
        .find((activity) => activity.facilityReservationActivityId === initialFacilityReservationActivityId) ?? null
      : null;

    const preferredIntervals = preferredIntervalsForActivity?.preferredIntervalsForUser ?? preferredIntervalsForActivity?.preferredIntervalsForFarm ?? 1;
    const preferredMinutes = (this.store.currentFacility?.facilityReservationConfiguration?.timeOptionInterval || 0)
      * preferredIntervals;

    const initialTimeFrame = this.initialTimeFrameFromOpeningTime(openedWithTimestamp, preferredMinutes);

    const initialHorseId = this.myStableStore.ownAndSharedHorses[0].horseId;

    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        facilityReservationActivity: {
          label: 'Aktivität',
          value: initialFacilityReservationActivityId,
          isRequired: true,
          afterTransformationAndValidation: () => {
            if (this.form?.isValid) {
              this.validateReservation();
            }
          },
        },
        timeFrame: {
          label: 'Zeitraum',
          value: initialTimeFrame,
          isRequired: true,
          afterTransformationAndValidation: () => {
            if (this.form?.isValid) {
              this.validateReservation();
            }
          },
        },
        horseId: {
          label: 'Pferd',
          value: initialHorseId,
          isRequired: true,
          afterTransformationAndValidation: () => {
            if (this.form?.isValid) {
              this.validateReservation();
            }
          },
        },
        comment: {
          label: 'Kommentar',
          value: null,
        },
      },
    });
  }

  initialTimeFrameFromOpeningTime(
    openedWithTimestamp: Moment,
    preferredMinutes: number,
  ): TimeFrame {
    const selectedTime = new Time(openedWithTimestamp.hour(), openedWithTimestamp.minute(), 0);
    const closestTimeOfDay = selectedTime.closestPointInTimeFrameAndInterval(
      this.store.currentFacility!.openingHours,
      this.store.currentFacility!.facilityReservationConfiguration!.timeOptionInterval,
    );

    const closestMoment = closestTimeOfDay.isMidnight
      ? momentWithTime(this.openedWithTimestamp!, closestTimeOfDay)
        .add(1, 'day')
      : momentWithTime(this.openedWithTimestamp!, closestTimeOfDay);

    const lastPossibleMoment = this.store.currentFacility!.openingHours.timeTo.isMidnight
      ? momentWithTime(this.openedWithTimestamp!, this.store.currentFacility!.openingHours.timeTo)
        .add(1, 'day')
      : momentWithTime(this.openedWithTimestamp!, this.store.currentFacility!.openingHours.timeTo);

    return closestMoment
      .add(preferredMinutes, 'minutes')
      .isAfter(lastPossibleMoment)
      ? {
        timeFrom: Time.fromDate(lastPossibleMoment.subtract(preferredMinutes, 'minutes')),
        timeTo: Time.fromDate(lastPossibleMoment),
      }
      : {
        timeFrom: closestTimeOfDay,
        timeTo: closestTimeOfDay.add(preferredMinutes, 'minutes'),
      };
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const reservationFrom = momentWithTime(this.openedWithTimestamp!, formValues.timeFrame!.timeFrom);

    const reservationTo = formValues.timeFrame!.timeTo.isMidnight
      ? momentWithTime(this.openedWithTimestamp!, formValues.timeFrame!.timeTo)
        .add(1, 'day')
      : momentWithTime(this.openedWithTimestamp!, formValues.timeFrame!.timeTo)

    const command: ReserveFacilityCommand = {
      facilityId: this.store.currentFacility!.facilityId,
      facilityReservationActivityId: formValues.facilityReservationActivity!,
      horseId: formValues.horseId!,
      from: reservationFrom,
      to: reservationTo,
      comment: formValues.comment,
    };

    this.store.reserveFacility(command, false)
      .then(() => showSuccessMessage('Die Anlage wurde reserviert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error, 6000));
  }

  validateReservation(): void {
    const formValues = getFormValues(this.form!);

    const reservationFrom = momentWithTime(this.openedWithTimestamp!, formValues.timeFrame!.timeFrom);

    const reservationTo = formValues.timeFrame!.timeTo.isMidnight
      ? momentWithTime(this.openedWithTimestamp!, formValues.timeFrame!.timeTo)
        .add(1, 'day')
      : momentWithTime(this.openedWithTimestamp!, formValues.timeFrame!.timeTo);

    const command: ReserveFacilityCommand = {
      facilityId: this.store.currentFacility!.facilityId,
      facilityReservationActivityId: formValues.facilityReservationActivity!,
      horseId: formValues.horseId!,
      from: reservationFrom,
      to: reservationTo,
      comment: formValues.comment,
    };

    this.store.reserveFacility(command, true)
      .catch(() => {});
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
