
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { notEmptyArrayRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { moment } from '@/helpers';
import { useFreeJumpingFreeRunningStore } from '../store';
import { CreateEventRegistrationCommand, FreeJumpingFreeRunningActivity, Event } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    horseIds: FormField<string[]>;
    activity: FormField<FreeJumpingFreeRunningActivity>;
  }
}

@Component
export default class CreateFreeJumpingFreeRunningEventRegistrationDialog extends Vue {

  readonly store = useFreeJumpingFreeRunningStore();
  readonly myStableStore = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly event!: Event;

  readonly activities: { text: string, value: FreeJumpingFreeRunningActivity }[] = [
    { text: 'Freispringen', value: 'free-jumping' },
    { text: 'Freilaufen', value: 'free-running' },
  ];

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  get creationIsAllowed(): boolean {
    const today = moment().startOf('day');
    return today.isSameOrBefore(this.event.date.clone().subtract(1, 'day'));
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        horseIds: {
          value: [],
          rules: [
            notEmptyArrayRule('Es muss mindestens ein Pferd ausgewählt sein'),
          ],
        },
        activity: {
          value: 'free-jumping',
          rules: [],
        },
      },
    };
  }

  onSubmit(): void {
    const command: CreateEventRegistrationCommand = {
      eventId: this.event.id,
      horseIds: this.form!.fields.horseIds.value,
      activity: this.form!.fields.activity.value,
    };

    this.store.createEventRegistration(command)
      .then(() => showSuccessMessage('Du bist zu diesem Termin angemeldet'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
