
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission, UserGroupId } from '@/types';
import { showErrorResponse } from '@/application/snackbar/service';
import CreateGroupDialog from './create-group-dialog.vue';
import UpdateGroupNameDialog from './update-group-name-dialog.vue';
import UpdateGroupUsersDialog from './update-group-users-dialog.vue';
import { useUserManagementStore } from '../store';

@Component({
  components: {
    CreateGroupDialog,
    UpdateGroupNameDialog,
    UpdateGroupUsersDialog,
  },
})
export default class UsersAdminComponent extends Vue {

  readonly store = useUserManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    {
      text: 'Name',
      value: 'name',
      sortable: true,
    }, {
      text: 'Anzahl Benutzer',
      value: 'users',
      sortable: true,
    }, {
      text: 'Aktionen',
      value: 'actions',
      align: 'end',
      sortable: false,
    },
  ];

  readonly updateMenuList: Record<UserGroupId, boolean> = {};

  mounted(): void {
    this.store.getGroups()
      .catch((error) => showErrorResponse(error));
  }

  get isCreateUserGroupVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  get isUpdateMenuVisible(): boolean {
    return this.isUpdateGroupNameVisible
      || this.isUpdateGroupUsersVisible;
  }

  get isUpdateGroupNameVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  get isUpdateGroupUsersVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

}
