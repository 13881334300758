
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormField } from '@/application/types';

@Component
export default class NumberInput extends Vue {

  @Prop({ type: Object, required: true })
  readonly formField!: FormField<number | null>;

}
