
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { moment } from '@/helpers';
import { DeleteEventAsManagerCommand, Event } from '../types';
import { useFreeJumpingFreeRunningManagementStore } from '../store';

@Component
export default class DeleteFreeJumpingFreeRunningEventDialog extends Vue {

  readonly store = useFreeJumpingFreeRunningManagementStore();

  @Prop({ type: Object, required: true })
  readonly event!: Event;

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  get isDeleteAlertShown(): boolean {
    return this.event.date.isSameOrAfter(moment().startOf('day'), 'day');
  }

  deleteEventConfirmed(): void {
    const command: DeleteEventAsManagerCommand = {
      id: this.event.id,
    };

    this.store.deleteEvent(command)
      .then(() => showSuccessMessage('Termin wurde gelöscht'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
