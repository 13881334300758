
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useAppStore } from '@/application/app/store';
import { showErrorResponse } from '@/application/snackbar/service';
import { FarmManagerPermission, Feature, PersonChangeHistory, PersonChangeHistoryEntry } from '@/types';
import { formatAddress, formatBoolean, formatCustomFieldValue, formatInvoiceAddressForPerson, formatName } from '@/helpers';
import { languageTranslation, personChangeTypeTranslations } from '@/helpers/translations';
import { doesAuthenticatedUserHavePermission, isFeatureEnabled } from '@/application/authentication/helper';
import { formatDate, formatTime } from '@/helpers';
import { DataListItem } from '@/components/design-system';
import CreatePersonDialog from './create-person-dialog.vue';
import UpdateSalutationOfPersonDialog from './update-salutation-of-person-dialog.vue';
import UpdateNameOfPersonDialog from './update-name-of-person-dialog.vue';
import UpdateEmailAddressOfPersonDialog from './update-email-address-of-person-dialog.vue';
import UpdateAddressOfPersonDialog from './update-address-of-person-dialog.vue';
import UpdateInvoiceAddressOfPersonDialog from './update-invoice-address-of-person-dialog.vue';
import UpdateAdditionalMasterDataOfPersonDialog from './update-additional-master-data-of-person-dialog.vue';
import PersonServiceList from './person-service-list.vue';
import PersonInvoiceList from './person-invoice-list.vue';
import { CustomField, Person } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

@Component({
  components: {
    CreatePersonDialog,
    UpdateSalutationOfPersonDialog,
    UpdateNameOfPersonDialog,
    UpdateEmailAddressOfPersonDialog,
    UpdateAddressOfPersonDialog,
    UpdateInvoiceAddressOfPersonDialog,
    UpdateAdditionalMasterDataOfPersonDialog,
    PersonServiceList,
    PersonInvoiceList,
  },
})
export default class PersonDetails extends Vue {

  readonly store = useCustomerMasterDataManagementStore();
  readonly appStore = useAppStore();

  @Prop({ type: Object, required: true })
  readonly person!: Person;

  @Prop({ type: Array, required: true })
  readonly customFieldsForPerson!: CustomField[];

  get dataItems(): DataListItem[] {
    let items: DataListItem[] = [
      {
        key: 'salutation',
        label: 'Anrede',
        value: this.person.salutation ?? '-',
      },
      {
        key: 'name',
        label: 'Name',
        value: this.person.fullName,
      },
      {
        key: 'emailAddress',
        label: 'E-Mail-Adresse',
        value: this.person.emailAddress ?? '-',
      },
    ];

    if (this.person.userEmailAddress) {
      items.push({
        key: 'userEmailAddress',
        label: 'E-Mail-Adresse (Benutzer)',
        value: this.person.userEmailAddress,
      });
    }

    items.push({
      key: 'address',
      label: 'Adresse',
      value: this.person.address
        ? formatAddress(this.person.address)
        : '-',
    });

    if (isFeatureEnabled(Feature.LEDGER_INVOICING)) {
      items.push({
        key: 'invoiceAddress',
        label: 'Rechnungsadresse',
        value: this.person.invoiceAddress
          ? formatInvoiceAddressForPerson(this.person.invoiceAddress)
          : '-',
      });
    }

    const customFieldItems = this.customFieldsForPerson.map((customField) => {
      const customFieldValue = this.person.customFields
        ? this.person.customFields.find((customFieldValue) => customFieldValue.customFieldId === customField.customFieldId) ?? null
        : null;

      return customFieldValue
        ? {
          key: customField.customFieldId,
          label: customField.label,
          value: formatCustomFieldValue(customFieldValue, customField.options),
        }
        : {
          key: customField.customFieldId,
          label: customField.label,
          value: '-',
        };
    });

    const archivedItems = this.person.isArchived
      ? [
        {
          key: 'isArchived',
          label: 'Archiviert',
          value: formatBoolean(this.person.isArchived),
        },
        {
          key: 'reasonForArchiving',
          label: 'Begründung für Archivierung',
          value: this.person.reasonForArchiving ?? '-',
        },
      ]
      : [];

    items = [
      ...items,
      {
        key: 'assignedOrganizations',
        label: 'Zugewiesen zu Organisationen',
        value: '',
        customTemplateValue: this.person.assignedOrganizations,
      },
      {
        key: 'landlinePhoneNumber',
        label: 'Festnetz-Telefonnummer',
        value: this.person.landlinePhoneNumber ?? '-',
      },
      {
        key: 'mobilePhoneNumber',
        label: 'Mobil-Telefonnummer',
        value: this.person.mobilePhoneNumber ?? '-',
      },
      {
        key: 'language',
        label: 'Sprache',
        value: languageTranslation[this.person.language],
      },
      ...customFieldItems,
      {
        key: 'comment',
        label: 'Kommentar',
        value: this.person.comment ?? '-',
      },
      ...archivedItems,
    ];

    items.map((item: DataListItem) => item.meta = this.person);

    return items;
  }

  get reversedChangeHistory(): PersonChangeHistory {
    return this.person.changeHistory.reverse();
  }

  get areInvoicesVisible(): boolean {
    return isFeatureEnabled(Feature.LEDGER_INVOICING)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_READ);
  }

  get areServicesVisible(): boolean {
    return isFeatureEnabled(Feature.LEDGER)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_READ);
  }

  mounted(): void {
    watch(() => this.person, (person) => {
      const title = `Kundenstammdaten - ${person.fullName}`
      this.appStore.updateTitle(title)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });
  }

  changeHistoryTitle(changeHistoryEntry: PersonChangeHistoryEntry): string {
    return personChangeTypeTranslations[changeHistoryEntry.type];
  }

  changeHistoryDescription(changeHistoryEntry: PersonChangeHistoryEntry): string {
    return `am ${formatDate(changeHistoryEntry.changedAt)} um ${formatTime(changeHistoryEntry.changedAt)} Uhr von ${formatName(changeHistoryEntry.nameOfUser)}`;
  }

  isUpdateSalutationOfPersonVisible(key: string): boolean {
    return key === 'salutation'
      && !this.person.isArchived
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  isUpdateNameOfPersonVisible(key: string): boolean {
    return key === 'name'
      && !this.person.isArchived
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  isUpdateEmailAddressOfPersonVisible(key: string): boolean {
    return key === 'emailAddress'
      && !this.person.isArchived
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  isUpdateAddressOfPersonVisible(key: string): boolean {
    return key === 'address'
      && !this.person.isArchived
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  isUpdateInvoiceAddressOfPersonVisible(key: string): boolean {
    return key === 'invoiceAddress'
      && !this.person.isArchived
      && isFeatureEnabled(Feature.LEDGER_INVOICING)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  isUpdateAdditionalMasterDataOfPersonVisible(key: string): boolean {
    if (!doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE)
      || this.person.isArchived
    ) {
      return false;
    }

    const additionalMasterDataKeys = [
      'landlinePhoneNumber',
      'mobilePhoneNumber',
      'language',
      'comment',
    ];
    this.customFieldsForPerson.forEach((customField) => additionalMasterDataKeys.push(customField.customFieldId));

    return additionalMasterDataKeys.includes(key);
  }

  personUpdated(): void {
    this.store.getPerson()
      .catch((error) => showErrorResponse(error));
  }

}
