
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { User } from '../types';
import { DataListItem } from '@/components/design-system';
import { formatBoolean, formatDateTime } from '@/helpers';

@Component
export default class UserDetailsDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly user!: User;

  isDialogVisible = false;

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'phoneNumber',
        label: 'Telefonnummer',
        value: this.user.phoneNumber ?? '-',
      },
      {
        key: 'emergencyContact',
        label: 'Notfallkontakt',
        value: this.emergencyContact,
      },
      {
        key: 'hasAcceptedNewsletter',
        label: 'Für Newsletter angemeldet',
        value: formatBoolean(this.user.hasAcceptedNewsletter),
      },
      {
        key: 'createdAt',
        label: 'Erstellt',
        value: `${formatDateTime(this.user.createdAt)} Uhr`,
      },
    ];
  }

  get emergencyContact(): string {
    if (this.user.emergencyContactName === null
      && this.user.emergencyContactPhoneNumber === null
    ) {
      return '-';
    }

    if (this.user.emergencyContactName !== null
      && this.user.emergencyContactPhoneNumber === null
    ) {
      return this.user.emergencyContactName;
    }

    return `${this.user.emergencyContactName} (${this.user.emergencyContactPhoneNumber})`;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
