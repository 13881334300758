
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { formatHours, moment } from '@/helpers';
import { DataListItem } from '@/components/design-system';
import CreateTimeEntryDialog from './create-time-entry-dialog.vue';
import { useLaborServiceStore } from '../store';
import { Summary, TimeEntry } from '../types';

interface Controls extends FormControls {
  year: FormControl<number>;
}

@Component({
  components: {
    CreateTimeEntryDialog,
  },
})
export default class LaborServices extends Vue {

  readonly store = useLaborServiceStore();
  readonly appStore = useAppStore();

  @Prop({ type: Object, required: true })
  readonly summary!: Summary;

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Datum', value: 'date' },
    { text: 'Stundenanzahl', value: 'numberOfHours' },
    { text: 'Beschreibung', value: 'description' },
    { text: 'Status', align: 'center', value: 'status' },
  ];

  form: Form<Controls> | null = null;

  get currentYear(): number {
    return moment().year();
  }

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'numberOfApprovedHours',
        label: 'Abgezeichnete Stunden',
        value: `${formatHours(this.summary.numberOfApprovedHours)} Stunden von ${formatHours(this.summary.numberOfNecessaryHours)} Stunden`,
      },
      {
        key: 'numberOfUnhandledHours',
        label: 'Nicht bewertete Stunden',
        value: `${formatHours(this.summary.numberOfUnhandledHours)} Stunden`,
      },
      {
        key: 'numberOfHours',
        label: 'Eingetragene Stunden',
        value: `${formatHours(this.summary.numberOfHours)} Stunden`,
      },
      {
        key: 'numberOfRejectedHours',
        label: 'Abgelehnte Stunden',
        value: `${formatHours(this.summary.numberOfRejectedHours)} Stunden`,
      },
    ];
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        year: {
          label: 'Jahr',
          value: this.store.currentSummaryYear,
          afterTransformationAndValidation: (value) => {
            this.store.updateSummaryYear(value!)
              .then(() => this.appStore.updateTitle(`Arbeitsdienst für ${value}`))
              .catch((error) => showErrorResponse(error));
          },
        },
      }
    });
  }

  isOutstandingVisible(timeEntry: TimeEntry): boolean {
    return !timeEntry.approvedAt
      && !timeEntry.rejectedAt;
  }

  isApprovedVisible(timeEntry: TimeEntry): boolean {
    return !!timeEntry.approvedAt
      && !timeEntry.rejectedAt;
  }

  isRejectedVisible(timeEntry: TimeEntry): boolean {
    return !!timeEntry.rejectedAt;
  }

}
