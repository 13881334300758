
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Date, RiderTaskId, UserId } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useRiderTaskManagementStore } from '../store';
import { AssignTaskToUserAsManagerCommand } from '../types';

interface Controls extends FormControls {
  executionDate: FormControl<Date>;
  user: FormControl<UserId>;
  commentToRider: FormControl<string>;
}

@Component
export default class AssignTaskDialog extends Vue {

  readonly store = useRiderTaskManagementStore();

  @Prop({ type: String, required: true })
  readonly riderTaskId!: RiderTaskId;

  @Prop({ type: String, required: false, default: null })
  readonly idOfAssignedUser!: UserId | null;

  @Prop({ type: Object, required: true })
  readonly currentExecutionDate!: Date;

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.store.getFarmManagersForAssignment()
          .then(() => {
            this.form = this.buildForm();
          })
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.onSubmit,
      controls: {
        executionDate: {
          label: 'Bearbeitungsdatum',
          value: this.currentExecutionDate,
          isRequired: true,
        },
        user: {
          label: 'Hofverwalter',
          value: this.idOfAssignedUser,
          isRequired: true,
        },
        commentToRider: {
          label: 'Kommentar an den Reiter',
          value: null,
        },
      },
    });
  }

  onSubmit(): void {
    const formValues = getFormValues(this.form!);

    const command: AssignTaskToUserAsManagerCommand = {
      riderTaskId: this.riderTaskId,
      executionDate: formValues.executionDate!,
      idOfAssignedUser: formValues.user!,
      commentToRider: formValues.commentToRider,
    };

    this.store.assignTaskToUser(command)
      .then(() => showSuccessMessage('Die Aufgabe wurde zugewiesen.'))
      .then(() => this.$emit('task-assigned'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  isDateAllowed(date: Date): boolean {
    return date.isAfterOrEqualTo(Date.today());
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
