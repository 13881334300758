
import { Vue, Component } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { MimeType } from '@/types';
import { useFarmDocumentsManagementStore } from '../store';
import { CreateDocumentAsManagerCommand } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  description: FormControl<string>;
  document: FormControl<File>;
}

@Component
export default class CreateFarmDocumentDialog extends Vue {

  readonly store = useFarmDocumentsManagementStore();

  readonly size = DialogWidth.large;

  readonly MimeType = MimeType;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Was ist das Dokument?',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ]
        },
        description: {
          label: 'Beschreibung',
          value: null,
        },
        document: {
          label: 'Dokument',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateDocumentAsManagerCommand = {
      body: {
        title: formValues.title!,
        description: formValues.description,
      },
      files: {
        document: formValues.document!,
      },
    };

    this.store.createDocument(command)
      .then(() => showSuccessMessage('Das Dokument wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
