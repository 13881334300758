
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { ActionStatus } from '@/application/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { Date, FarmManagerPermission } from '@/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useRidingLessonManagementStore } from '../store';
import CreateRidingLessonDialog from './create-riding-lesson-dialog.vue';
import CreateRidingLessonSeriesDialog from './create-riding-lesson-series-dialog.vue';
import HorseSelection from './horse-selection.vue';
import RidingLessonCard from './riding-lesson-card.vue';
import UpdateRidingInstructorOfRidingLessonDialog from './update-riding-instructor-of-riding-lesson-dialog.vue';

interface Controls extends FormControls {
  date: FormControl<Date>,
}

@Component({
  components: {
    UpdateRidingInstructorOfRidingLessonDialog,
    HorseSelection,
    RidingLessonCard,
    CreateRidingLessonDialog,
    CreateRidingLessonSeriesDialog,
  },
})
export default class RidingLessonList extends Vue {

  readonly store = useRidingLessonManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  form: Form<Controls> | null = null;

  get isLoading(): boolean {
    return this.store.isGetRidingLessonsProcessing
      || this.store.isGetConfigurationProcessing;
  }

  get isCreateRidingLessonVisible(): boolean {
    return this.isBasicConfigurationCompleted
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_CREATE);
  }

  get noDataText(): string {
    return this.store.getRidingLessonsStatus === ActionStatus.Failed
      ? 'Die Unterrichtsstunden konnten nicht geladen werden.'
      : 'Du hast noch keine Unterrichtsstunde für diesen Tag erstellt.';
  }

  get isBasicConfigurationCompleted(): boolean {
    return !!this.store.configurationForRidingLessons
      && this.store.facilities
        .some((facility) => (facility.ridingLessonTypes
          .filter((ridingLessonType) => ridingLessonType.isEnabled)
        ).length > 0);
  }

  get isProgressBarVisible(): boolean {
    return !this.store.configurationForRidingLessons
      && this.store.isGetConfigurationProcessing;
  }

  get isNoFacilitiesAlertVisible(): boolean {
    return this.store.getConfigurationStatus === ActionStatus.Successful
      && !this.isBasicConfigurationCompleted;
  }

  get isNoRidingLessonAvailableVisible(): boolean {
    return this.store.ridingLessons.length === 0
      && !this.store.isGetRidingLessonsProcessing;
  }

  get isHorseSelectionVisible(): boolean {
    return this.store.ridingLessons.length > 0
      && this.authenticationStore.doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_ASSIGN_HORSES);
  }

  mounted(): void {
    this.form = this.buildForm();

    const formValues = getFormValues(this.form);

    this.store.updateCurrentDateForRidingLessons(formValues.date!)
      .catch((error) => showErrorResponse(error));

    this.store.getConfiguration()
      .catch((error) => showErrorResponse(error));

    watch(() => this.store.currentDateForRidingLessons, (currentDateForRidingLessons) => {
      if (this.form
        && (!this.form.controls.date.value
        || !this.form.controls.date.value.isEqualTo(currentDateForRidingLessons!)
        )
      ) {
        this.form.controls.date.value = currentDateForRidingLessons;
      }
    });
  }

  ridingLessonCreated(): void {
    if (!this.store.idOfOpenRidingLesson
      && this.store.ridingLessons.length > 0
    ) {
      this.store.openRidingLesson(this.store.ridingLessons[0].ridingLessonId)
        .catch((error) => showErrorResponse(error));
    }
  }

  ridingLessonSeriesCreated(): void {
    if (!this.store.idOfOpenRidingLesson
      && this.store.ridingLessons.length > 0
    ) {
      this.store.openRidingLesson(this.store.ridingLessons[0].ridingLessonId)
        .catch((error) => showErrorResponse(error));
    }
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        date: {
          label: 'Datum',
          value: Date.today(),
          afterTransformationAndValidation: (date) => {
            this.store.updateCurrentDateForRidingLessons(date!)
              .then(() => {
                if (this.store.ridingLessons.length > 0) {
                  return this.store.openRidingLesson(this.store.ridingLessons[0].ridingLessonId)
                }
              })
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

}
