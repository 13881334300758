import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import FreeJumpingFreeRunningEvents from './components/free-jumping-free-running-events.vue';
import ManageFreeJumpingFreeRunningEventComponent from './components/free-jumping-free-running-event.vue';
import FreeJumpingFreeRunningConfigurationComponent from './components/free-jumping-free-running-configuration.vue';
import FreeJumpingFreeRunningTabs from './components/free-jumping-free-running-tabs.vue';

export const freeJumpingFreeRunningManagementRoutes: RouteConfig[] = [
  {
    path: 'freispringen-freilaufen',
    component: FreeJumpingFreeRunningTabs,
    children: [
      {
        name: 'admin-free-jumping-free-running-events',
        path: '',
        component: FreeJumpingFreeRunningEvents,
        meta: {
          title: 'Freispringen/-laufen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.FREE_JUMPING_FREE_RUNNING,
          requiresPermission: FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_READ,
        },
      },
      {
        name: 'admin-free-jumping-free-running-event',
        path: 'termin/:id',
        component: ManageFreeJumpingFreeRunningEventComponent,
        meta: {
          title: 'Freispringen/-laufen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.FREE_JUMPING_FREE_RUNNING,
          requiresPermission: FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_READ,
        },
      },
      {
        name: 'admin-free-jumping-free-running-configuration',
        path: 'konfiguration',
        component: FreeJumpingFreeRunningConfigurationComponent,
        meta: {
          title: 'Freispringen/-laufen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.FREE_JUMPING_FREE_RUNNING,
          requiresPermission: FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_READ,
        },
      },
    ],
  },
];
