
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifyValidatable } from '@/application/types';
import { maxLengthRule, requiredWhenTextFieldIsFilled } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { useFacilityManagementStore } from '../store';
import { FacilityBlocker, UpdateReasonOfFacilityBlockerAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    reason: FormField<string|null>;
    description: FormField<string|null>;
  };
}

@Component
export default class UpdateFacilityBlockerReasonDialog extends Vue {

  readonly store = useFacilityManagementStore();

  @Ref()
  readonly reasonInput?: VuetifyValidatable;

  @Prop()
  readonly facilityBlocker!: FacilityBlocker;

  readonly dialogMaxWidth = DialogWidth.small;

  form: Form | null = null;

  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    const form: Form = {
      valid: false,
      fields: {
        reason: {
          value: this.facilityBlocker.reason,
          rules: [
            maxLengthRule(100),
          ],
        },
        description: {
          value: this.facilityBlocker.description,
          rules: [],
        },
      },
    };

    form.fields.reason.rules.push(requiredWhenTextFieldIsFilled(
      form.fields.description,
      'Der Grund muss ausgefüllt werden, wenn eine Beschreibung ausgefüllt wurde.'
    ));

    return form;
  }

  onSubmit(): void {
    const command: UpdateReasonOfFacilityBlockerAsManagerCommand = {
      facilityBlockerId: this.facilityBlocker.facilityBlockerId,
      reason: this.form!.fields.reason.value,
      description: this.form!.fields.description.value,
    };

    this.store.updateFacilityBlockerReason(command)
      .then(() => showSuccessMessage('Der Grund und die Beschreibung der Sperrzeit wurden aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

  descriptionChanged(): void {
    this.reasonInput!.validate(true);
  }

}
