
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import SettlementDetails from './settlement-details.vue';
import { Settlement } from '../types';

@Component({
  components: {
    SettlementDetails,
  },
})
export default class SettlementDetailsDialog extends Vue {

  @Prop({ type: String, required: true })
  readonly name!: string;

  @Prop({ type: Object, required: true })
  readonly settlement!: Settlement;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
