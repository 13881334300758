
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, FormControl, Form, getFormValues, FormControls, requiredWhenCheckboxIsCheckedRule } from '@/components/form';
import { HorseId } from '@/types';
import { useRidingLessonManagementStore } from '../store';
import { CreateRidingLessonConfigurationAsManagerCommand } from '../types';

interface Controls extends FormControls {
  isRidingLessonPlanVisible: FormControl<boolean>;
  idsOfHorsesAvailableForAssignment: FormControl<HorseId[]>;
  areHorsesFilteredByRidingLessonType: FormControl<boolean>;
  isRidingInstructorShownOnPlan: FormControl<boolean>;
  isWaitingListAvailable: FormControl<boolean>;
  waitingListNotice: FormControl<string>;
}

@Component
export default class CreateRidingLessonConfigurationDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly dialogMaxWidth = DialogWidth.medium;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        isRidingLessonPlanVisible: {
          label: 'Ein extra Plan mit allen Unterrichtsstunden bei den Buchungskalendern anzeigen',
          value: false,
          rules: [],
        },
        idsOfHorsesAvailableForAssignment: {
          label: 'Pferde die zu Unterrichtsstunden zugewiesen werden können',
          value: null,
          rules: [],
        },
        areHorsesFilteredByRidingLessonType: {
          label: 'Pferde können nur bestimmten Aktivitäten zugeordnet werden',
          value: false,
          rules: [],
          validateFormControlsOnInput: [
            'idsOfHorsesAvailableForAssignment',
          ],
        },
        isRidingInstructorShownOnPlan: {
          label: 'Der Reitlehrer wird im Buchungskalender angezeigt',
          value: false,
          rules: [],
        },
        isWaitingListAvailable: {
          label: 'Warteliste anbieten, sobald die Teilnehmergrenze erreicht ist',
          value: false,
          rules: [],
          validateFormControlsOnInput: [
            'waitingListNotice',
          ],
        },
        waitingListNotice: {
          label: 'Hinweis der angezeigt werden soll, sobald die Teilnehmergrenze erreicht ist',
          value: null,
          rules: [],
        },
      },
    });

    form.controls.idsOfHorsesAvailableForAssignment.rules!.push(requiredWhenCheckboxIsCheckedRule(
      form.controls.areHorsesFilteredByRidingLessonType,
      'Pferde müssen ausgewählt werden wenn die Pferde nach Aktivitäten gefiltert werden'
    ));
    form.controls.waitingListNotice.rules!.push(requiredWhenCheckboxIsCheckedRule(
      form.controls.isWaitingListAvailable,
      'Wenn die Warteliste angeboten werden soll, muss ein Hinweis definiert werden'
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateRidingLessonConfigurationAsManagerCommand = {
      isRidingLessonPlanVisible: formValues.isRidingLessonPlanVisible!,
      idsOfHorsesAvailableForAssignment: formValues.idsOfHorsesAvailableForAssignment ?? [],
      areHorsesFilteredByRidingLessonType: formValues.areHorsesFilteredByRidingLessonType!,
      isRidingInstructorShownOnPlan: formValues.isRidingInstructorShownOnPlan!,
      isWaitingListAvailable: formValues.isWaitingListAvailable!,
      waitingListNotice: formValues.isWaitingListAvailable
        ? formValues.waitingListNotice
        : null,
    };

    this.store.createRidingLessonConfiguration(command)
      .then(() => showSuccessMessage('Der Reitunterricht wurde konfiguriert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
