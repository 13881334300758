
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { PersonId, Salutation } from '@/types';
import { UpdateSalutationOfPersonAsManagerCommand } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  salutation: FormControl<Salutation>;
}

@Component
export default class UpdateSalutationOfPersonDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: String, required: true })
  readonly personId!: PersonId;

  @Prop({ type: String, default: null })
  readonly salutation!: string | null;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        salutation: {
          label: 'Anrede',
          value: this.salutation,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateSalutationOfPersonAsManagerCommand = {
      personId: this.personId,
      salutation: formValues.salutation,
    };

    this.store.updateSalutationOfPerson(command)
      .then(() => showSuccessMessage('Die Anrede wurde angepasst.'))
      .then(() => this.$emit('person-updated'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
