
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { constructForm, FormControl, Form, getFormValues, FormControls } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { Moment, TimeFrame } from '@/types';
import { moment } from '@/helpers';
import { useRidingLessonManagementStore } from '../store';
import { Facility, RidingLesson, UpdateLastRegistrationAtOfRidingLessonSeriesAsManagerCommand } from '../types';
import { midnightSevenDaysAgo } from '../helper';

interface Controls extends FormControls {
  lastRegistrationAt: FormControl<Moment>;
}

@Component
export default class UpdateLastRegistrationAtOfRidingLessonSeriesDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly size = DialogWidth.medium;

  @Prop({ type: Object, required: true })
  readonly ridingLesson!: RidingLesson;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get lastRegistrationAtForFollowingRidingLessonsNote(): string {
    if (!this.form) {
      return '';
    }

    const disclaimer = '<br />Durch eine vorherige Anpassung ist es möglich, dass die aktuelle Unterrichts&shy;stunde eine abweichenden Anmeldeschluss von der Serie hat.';

    const formValues = getFormValues(this.form!);

    const dayOfRidingLesson = this.ridingLesson.from.startOf('day');
    const dayOfLastRegistrationAt = formValues.lastRegistrationAt!.startOf('day');

    if (dayOfRidingLesson.isSame(dayOfLastRegistrationAt, 'day')) {
      return `Der Anmeldeschluss wird für diese Serie, die ausgewählte Unterrichts&shy;stunde und alle darauf folgenden Unterrichts&shy;stunden auf den selben Tag um ${formValues.lastRegistrationAt!.format('HH:mm')} Uhr angepasst. ${disclaimer}`;
    }

    const dayDiff = dayOfRidingLesson.diff(dayOfLastRegistrationAt, 'day');
    if (dayDiff === 1) {
      return `Der Anmeldeschluss wird für diese Serie, die ausgewählte Unterrichts&shy;stunde und alle darauf folgenden Unterrichts&shy;stunden auf 1 Tag im Voraus um ${formValues.lastRegistrationAt!.format('HH:mm')} Uhr angepasst. ${disclaimer}`;
    }

    return `Der Anmeldeschluss wird für diese Serie, die ausgewählte Unterrichts&shy;stunde und alle darauf folgenden Unterrichts&shy;stunden auf ${dayDiff} Tage im Voraus um ${formValues.lastRegistrationAt!.format('HH:mm')} Uhr angepasst. ${disclaimer}`;
  }

  get selectedFacility(): Facility | null {
    return this.store.facilities
      .find((facility) => facility.facilityId === this.ridingLesson.facility.facilityId) ?? null;
  }

  get timeFrameForFrom(): TimeFrame | null {
    return this.selectedFacility === null
      ? null
      : this.selectedFacility.openingHours;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const lastRegistrationAt = this.ridingLesson.from
      .subtract(this.ridingLesson.ridingLessonSeries!.daysBeforeStartForLastRegistrationAt, 'days')
      .hour(this.ridingLesson.ridingLessonSeries!.timeForLastRegistrationAt.hour)
      .minute(this.ridingLesson.ridingLessonSeries!.timeForLastRegistrationAt.minute)
      .second(this.ridingLesson.ridingLessonSeries!.timeForLastRegistrationAt.second);

    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        lastRegistrationAt: {
          label: 'Anmeldeschluss',
          value: lastRegistrationAt,
          isRequired: true,
          rules: [
            this.dateTimeAfterMidnight7DaysAgoRule(),
            this.dateTimeBeforeRidingLessonFromRule(),
          ],
        },
      },
    });
  }

  dateTimeAfterMidnight7DaysAgoRule(): (value: Moment|null) => true|string {
    return (value: Moment|null) => value === null
      || value.isAfter(
        moment()
          .startOf('day')
          .subtract(7, 'days')
      )
      || 'Der Zeitpunkt darf maximal 7 Tage in der Vergangenheit liegen';
  }

  dateTimeBeforeRidingLessonFromRule(): (value: Moment|null) => true|string {
    return (value: Moment|null) => value === null
      || value.isSameOrBefore(this.ridingLesson.from)
      || 'Der Anmeldeschluss darf nicht nach dem Beginn der Unterrichtsstunde liegen';
  }

  isLastRegistrationAtAllowed(date: Moment): boolean {
    if (!this.form) {
      return false;
    }

    const dayOfRidingLesson = this.ridingLesson.from.startOf('day');

    return date.isSameOrAfter(midnightSevenDaysAgo(), 'day')
      && date.isSameOrBefore(dayOfRidingLesson, 'day');
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateLastRegistrationAtOfRidingLessonSeriesAsManagerCommand = {
      ridingLessonSeriesId: this.ridingLesson.ridingLessonSeries!.ridingLessonSeriesId,
      ridingLessonId: this.ridingLesson.ridingLessonId,
      lastRegistrationAt: formValues.lastRegistrationAt!,
    };
    this.store.updateLastRegistrationAtOfRidingLessonSeries(command)
      .then(() => showSuccessMessage('Der Teilnehmerzahl wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
