import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { Month, PersonId } from '@/types';
import { moment } from '@/helpers';
import { GetSettlementForecastAsManagerQuery, SettlementForecast } from './types';
import { getSettlementForecast } from './service';

interface SettlementForecastState {
  selectedPerson: PersonId | null;
  settlementForecast: SettlementForecast | null;

  getSettlementForecastStatus: ActionStatus;
}

function initialState(): SettlementForecastState {
  return {
    selectedPerson: null,
    settlementForecast: null,
    getSettlementForecastStatus: ActionStatus.None,
  };
}

export const useSettlementForecastStore = defineStore('settlementForecast', {
  state: (): SettlementForecastState => initialState(),
  getters: {
    isGetSettlementForecastProcessing: (state: SettlementForecastState): boolean =>
      state.getSettlementForecastStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSelectedPerson(selectedPerson: PersonId | null): Promise<void> {
      this.selectedPerson = selectedPerson;

      return this.getSettlementForecast();
    },

    // -- Queries

    getSettlementForecast(): Promise<void> {
      const query: GetSettlementForecastAsManagerQuery = {
        month: moment()
          .startOf('month')
          .add(1, 'month')
          .format('YYYY-MM') as Month,
        filteredForPerson: this.selectedPerson,
      };
      const { getSettlementForecastStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSettlementForecastStatus,
        () => getSettlementForecast(attachFarmAndUserProperties(query))
          .then(async (settlementForecast) => {
            this.settlementForecast = settlementForecast;
          })
      );
    },

  },
});
