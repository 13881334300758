
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, minCountRule, requiredWhenControlIsFilledRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { momentTimeFrameFromDateAndTimeFrame } from '@/helpers/date-helpers';
import { TimeFrame, Weekday, Date } from '@/types';
import { useFacilityManagementStore } from '../store';
import { CreateFacilityBlockersAsManagerCommand, Facility, GetOverlappingFacilityEntriesAsManagerQuery } from '../types';
import OverlappingFacilityEntries from './overlapping-facility-entries.vue';

interface Controls extends FormControls {
  dateFrom: FormControl<Date>;
  dateTo: FormControl<Date>;
  restrictedWeekdays: FormControl<Weekday[]>;
  selectedDates: FormControl<Date[]>;
  timeFrame: FormControl<TimeFrame>;
  blocksSpaces: FormControl<number>;
  reason: FormControl<string>;
  description: FormControl<string>;
}

@Component({
  components: {
    OverlappingFacilityEntries,
  },
})
export default class CreateMultipleFacilityBlockersDialog extends Vue {

  readonly store = useFacilityManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid
      || this.store.isGetOverlappingFacilityEntriesProcessing
      || this.store.hasOverlappingFacilityBlockers
      || this.store.hasOverlappingRidingLessons;
  }

  get dateRangeFrom(): Date {
    if (!this.form) {
      return Date.today();
    }
    const formValues = getFormValues(this.form);

    return formValues.dateFrom!;
  }

  get dateRangeTo(): Date {
    if (!this.form) {
      return Date.today();
    }
    const formValues = getFormValues(this.form);

    return formValues.dateTo!;
  }

  get restrictedWeekdays(): Weekday[] | null {
    if (!this.form) {
      return null;
    }
    const formValues = getFormValues(this.form);

    return formValues.restrictedWeekdays;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        const today = Date.today();
        const initialDateFrom = this.store.selectedDateForFacilityBlockers !== null
        && this.store.selectedDateForFacilityBlockers.isAfterOrEqualTo(today)
          ? this.store.selectedDateForFacilityBlockers
          : today;

        this.form = this.buildForm(initialDateFrom);
        this.updateOverlappingFacilityEntries();
      } else {
        this.form = null;
      }
    });
  }

  buildForm(initialDateFrom: Date): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        dateFrom: {
          label: 'Von',
          value: initialDateFrom,
          isRequired: true,
          afterTransformationAndValidation: () => this.updateOverlappingFacilityEntries(),
        },
        dateTo: {
          label: 'Bis',
          value: initialDateFrom.add(7),
          isRequired: true,
          afterTransformationAndValidation: () => this.updateOverlappingFacilityEntries(),
        },
        restrictedWeekdays: {
          label: 'Wochentage einschränken',
          value: null,
          afterTransformationAndValidation: () => this.updateOverlappingFacilityEntries(),
        },
        selectedDates: {
          label: 'Ausgewählte Tage',
          value: null,
          isRequired: true,
          rules: [
            minCountRule(1, 'Es muss mindestens ein Tag ausgewählt werden.'),
          ],
          afterTransformationAndValidation: () => this.updateOverlappingFacilityEntries(),
        },
        timeFrame: {
          label: 'Zeitraum',
          value: {
            timeFrom: this.facility.openingHours.timeFrom,
            timeTo: this.facility.openingHours.timeFrom.add(30, 'minutes'),
          },
          isRequired: true,
          afterTransformationAndValidation: () => this.updateOverlappingFacilityEntries(),
        },
        blocksSpaces: {
          label: 'Blockierte Anzahl der Plätze',
          value: this.facility.spaces,
          afterTransformationAndValidation: () => this.updateOverlappingFacilityEntries(),
        },
        reason: {
          label: 'Grund',
          value: null,
          rules: [
            maxLengthRule(100),
          ],
        },
        description: {
          label: 'Beschreibung',
          value: null,
          validateFormControlsOnInput: [
            'reason',
          ],
        },
      },
    });

    form.controls.reason.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.description,
      'Der Grund muss ausgefüllt werden, wenn eine Beschreibung ausgefüllt wurde.'
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateFacilityBlockersAsManagerCommand = {
      facilityId: this.facility.facilityId,
      timeRanges: formValues.selectedDates!.map((date) => momentTimeFrameFromDateAndTimeFrame(date, formValues.timeFrame!)),
      blocksSpaces: formValues.blocksSpaces !== null
        && formValues.blocksSpaces !== this.facility.spaces
        ? formValues.blocksSpaces
        : null,
      reason: formValues.reason,
      description: formValues.description,
    };

    this.store.createFacilityBlockers(command)
      .then(() => showSuccessMessage('Neue Sperrzeiten wurden angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  updateOverlappingFacilityEntries(): void {
    const formValues = getFormValues(this.form!);

    // Can happen when the selected time frame is invalid
    if (!formValues.timeFrame) {
      return;
    }

    const query: GetOverlappingFacilityEntriesAsManagerQuery = {
      facilityId: this.facility.facilityId,
      timeFrames: formValues.selectedDates === null
        ? []
        : formValues.selectedDates!.map((date) => momentTimeFrameFromDateAndTimeFrame(date, formValues.timeFrame!)),
      blocksSpaces: formValues.blocksSpaces!,
      idOfIgnoredFacilityBlocker: null,
    };
    this.store.getOverlappingFacilityEntries(query)
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

  isDateFromAllowed(date: Date): boolean {
    return date.isAfterOrEqualTo(Date.today())
      && date.isBeforeOrEqualTo(Date.today().add(365 * 2))
  }

  isDateToAllowed(date: Date): boolean {
    const formValues = getFormValues(this.form!);

    return date.isAfterOrEqualTo(formValues.dateFrom!)
      && date.isBeforeOrEqualTo(Date.today().add(365 * 2));
  }

}
