
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { useNewsManagementStore } from '../store';
import { HideNewsEntryAsManagerCommand, NewsEntry } from '../types';

@Component
export default class HideNewsEntryDialog extends Vue {

  readonly store = useNewsManagementStore();

  @Prop({ type: Object, required: true })
  readonly newsEntry!: NewsEntry;

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  hideNewsEntryConfirmed(): void {
    const command: HideNewsEntryAsManagerCommand = {
      newsEntryId: this.newsEntry.newsEntryId,
    };

    this.store.hideNewsEntry(command)
      .then(() => this.closeDialog())
      .catch(() => {});
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
