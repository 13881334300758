
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useLedgerStore } from '../store';
import { FarmService, UpdateFarmServiceRestrictionsAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    areRestrictionsDefined: FormField<boolean>;
    enableWithFarmServiceBookings: FormField<string[]>;
    disableWithFarmServiceBookings: FormField<string[]>;
  },
}

@Component
export default class UpdateRestrictionsOfFarmServiceDialog extends Vue {

  readonly store = useLedgerStore();

  @Prop({ type: Object, required: true })
  readonly farmService!: FarmService;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  get farmServices(): FarmService[] {
    return this.store.configuration!.farmServices
      .filter((farmService) => farmService.bookingType === 'subscription');
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        areRestrictionsDefined: {
          value: this.farmService.enableWithFarmServiceBookings.length > 0 || this.farmService.disableWithFarmServiceBookings.length > 0,
          rules: [],
        },
        enableWithFarmServiceBookings: {
          value: this.farmService.enableWithFarmServiceBookings,
          rules: [
            this.enabledNotInDisabledFarmServiceRule,
          ],
        },
        disableWithFarmServiceBookings: {
          value: this.farmService.disableWithFarmServiceBookings,
          rules: [
            this.disabledNotInEnabledFarmServiceRule,
          ],
        },
      },
    };
  }

  enabledNotInDisabledFarmServiceRule(value: any): true|string {
    if (!value || !Array.isArray(value)) {
      return true;
    }
    const isNotSelectedInOtherList = value
      .every((valueItem) => this.form!.fields.disableWithFarmServiceBookings.value.every((item) => item !== valueItem));

    return isNotSelectedInOtherList || 'Dieser Hofdienst ist bereits in der anderen Liste ausgewählt';
  }

  disabledNotInEnabledFarmServiceRule(value: any): true|string {
    if (!value || !Array.isArray(value)) {
      return true;
    }
    const isNotSelectedInOtherList = value
      .every((valueItem) => this.form!.fields.enableWithFarmServiceBookings.value.every((item) => item !== valueItem));

    return isNotSelectedInOtherList || 'Dieser Hofdienst ist bereits in der anderen Liste ausgewählt';
  }

  areRestrictionsDefinedChanged(): void {
    if (!this.form!.fields.areRestrictionsDefined.value) {
      this.form!.fields.enableWithFarmServiceBookings.value = [];
      this.form!.fields.disableWithFarmServiceBookings.value = [];
    }
  }

  formSubmitted(): void {
    const command: UpdateFarmServiceRestrictionsAsManagerCommand = {
      farmServiceId: this.farmService.id,
      enableWithFarmServiceBookings: this.form!.fields.enableWithFarmServiceBookings.value,
      disableWithFarmServiceBookings: this.form!.fields.disableWithFarmServiceBookings.value,
    };

    this.store.updateFarmServiceRestrictions(command)
      .then(() => showSuccessMessage('Die Einschränkungen wurden angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
