
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { UserId, UserIdList } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useUserManagementStore } from '../store';
import { CreateGroupAsManagerCommand } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
  users: FormControl<UserIdList>;
}

@Component
export default class CreateGroupDialog extends Vue {

  readonly store = useUserManagementStore();

  readonly size = DialogWidth.small;

  form: Form<Controls> | null = null;

  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get users(): { userId: UserId, name: string }[] {
    return this.store.users.map((user) => ({
      userId: user.userId,
      name: user.fullName,
    }));
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.store.getUsers()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => showErrorResponse(error));
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name der Gruppe',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        users: {
          label: 'Benutzer',
          value: null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateGroupAsManagerCommand = {
      name: formValues.name!,
      userIds: formValues.users ?? [],
    };

    this.store.createGroup(command)
      .then(() => showSuccessMessage('Die Gruppe wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
