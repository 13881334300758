
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, oneOfCheckboxesRequiredRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { FeedType, UpdateOfferOfFeedTypeAsManagerCommand } from '../types';
import { useFeedingManagementStore } from '../store';

interface Controls extends FormControls {
  isOfferedInTheMorning: FormControl<boolean>;
  isOfferedAtNoon: FormControl<boolean>;
  isOfferedInTheEvening: FormControl<boolean>;
}

@Component
export default class UpdateOfferOfFeedTypeDialog extends Vue {

  readonly store = useFeedingManagementStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly feedType!: FeedType;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        isOfferedInTheMorning: {
          label: 'Futtertyp Morgens anbieten',
          value: this.feedType.isOfferedInTheMorning,
          rules: [],
          validateFormControlsOnInput: [
            'isOfferedAtNoon',
            'isOfferedInTheEvening',
          ],
        },
        isOfferedAtNoon: {
          label: 'Futtertyp Mittags anbieten',
          value: this.feedType.isOfferedAtNoon,
          rules: [],
          validateFormControlsOnInput: [
            'isOfferedInTheMorning',
            'isOfferedInTheEvening',
          ],
        },
        isOfferedInTheEvening: {
          label: 'Futtertyp Abends anbieten',
          value: this.feedType.isOfferedInTheEvening,
          rules: [],
          validateFormControlsOnInput: [
            'isOfferedInTheMorning',
            'isOfferedAtNoon',
          ],
        },
      },
    });

    form.controls.isOfferedInTheMorning.rules!.push(oneOfCheckboxesRequiredRule([
      form.controls.isOfferedInTheMorning,
      form.controls.isOfferedAtNoon,
      form.controls.isOfferedInTheEvening,
    ], 'Der Typ muss mindestens zu einer Tageszeit angeboten werden'));

    form.controls.isOfferedAtNoon.rules!.push(oneOfCheckboxesRequiredRule([
      form.controls.isOfferedInTheMorning,
      form.controls.isOfferedAtNoon,
      form.controls.isOfferedInTheEvening,
    ], 'Der Typ muss mindestens zu einer Tageszeit angeboten werden'));

    form.controls.isOfferedInTheEvening.rules!.push(oneOfCheckboxesRequiredRule([
      form.controls.isOfferedInTheMorning,
      form.controls.isOfferedAtNoon,
      form.controls.isOfferedInTheEvening,
    ], 'Der Typ muss mindestens zu einer Tageszeit angeboten werden'));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateOfferOfFeedTypeAsManagerCommand = {
      feedTypeId: this.feedType.feedTypeId,
      isOfferedInTheMorning: formValues.isOfferedInTheMorning!,
      isOfferedAtNoon: formValues.isOfferedAtNoon!,
      isOfferedInTheEvening: formValues.isOfferedInTheEvening!,
    };

    this.store.updateOfferOfFeedType(command)
      .then(() => showSuccessMessage('Das Angebot wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
