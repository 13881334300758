
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { UserId, Date } from '@/types';
import { useFarmTaskManagementStore } from '../store';
import { AssignTaskToUserAsManagerCommand, Task } from '../types';

interface Controls extends FormControls {
  assignedUser: FormControl<UserId>;
  isTaskEndedAndDuplicateCreatedInstead: FormControl<boolean>;
}

@Component
export default class AssignTaskToUserDialog extends Vue {

  readonly store = useFarmTaskManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly task!: Task;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isIsTaskEndedAndDuplicateCreatedInsteadVisible(): boolean {
    return !!this.task.repetition
      && Date.today().isAfter(this.task.executionDate);
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: AssignTaskToUserAsManagerCommand = {
      farmTaskId: this.task.farmTaskId,
      idOfAssignedUser: formValues.assignedUser,
      isTaskEndedAndDuplicateCreatedInstead: formValues.isTaskEndedAndDuplicateCreatedInstead!,
    };

    this.store.assignTaskToUser(command)
      .then(() => showSuccessMessage('Die Aufgabe wurde zugewiesen.'))
      .then(() => this.$emit('task-assigned'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        assignedUser: {
          label: 'Zugewiesener Verwalter',
          value: this.task.idOfAssignedUser,
        },
        isTaskEndedAndDuplicateCreatedInstead: {
          label: 'Statt dessen beenden und duplizieren',
          value: false,
        },
      },
    });
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
