
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { constructForm, Form, FormControl, FormControls, getFormValues, minCountWhenCheckboxIsCheckedRule } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Persona, UserGroupId } from '@/types';
import { useUserManagementStore } from '@/private/management/users/store';
import { useNewsManagementStore } from '../store';
import { NewsEntry, UpdateNewsEntryVisibilityAsManagerCommand } from '../types';

interface Controls extends FormControls {
  isVisibilityRestrictedForPersona: FormControl<boolean>;
  visibleForPersona: FormControl<Persona>;
  isVisibilityRestrictedForGroups: FormControl<boolean>;
  idsOfGroupsForWhichTheEntryIsVisible: FormControl<UserGroupId[]>;
}

@Component
export default class UpdateNewsEntryVisibilityDialog extends Vue {

  readonly store = useNewsManagementStore();
  readonly manageUsersStore = useUserManagementStore();

  @Prop({ type: Object, required: true })
  readonly newsEntry!: NewsEntry;

  readonly size = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isVisibleForPersonaVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isVisibilityRestrictedForPersona!;
  }

  get isIdsOfGroupsForWhichTheEntryIsVisibleVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isVisibilityRestrictedForGroups!;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.manageUsersStore.getGroups()
          .then(() => {
            this.form = this.buildForm()
          })
          .catch((error) => showErrorResponse(error));
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        isVisibilityRestrictedForPersona: {
          label: 'Soll die Neuigkeit nur einer bestimmten Persona angezeigt werden?',
          value: !!this.newsEntry.visibleForPersona,
        },
        visibleForPersona: {
          label: 'Sichtbarkeit für Persona',
          value: this.newsEntry.visibleForPersona ?? Persona.WITH_HORSE,
        },
        isVisibilityRestrictedForGroups: {
          label: 'Soll die Neuigkeit nur bestimmten Gruppen angezeigt werden?',
          value: this.newsEntry.idsOfGroupsForWhichTheEntryIsVisible.length > 0,
          validateFormControlsOnInput: [
            'idsOfGroupsForWhichTheEntryIsVisible',
          ],
        },
        idsOfGroupsForWhichTheEntryIsVisible: {
          label: 'Gruppen',
          value: this.newsEntry.idsOfGroupsForWhichTheEntryIsVisible,
          rules: [],
        },
      },
    });

    form.controls.idsOfGroupsForWhichTheEntryIsVisible.rules!.push(
      minCountWhenCheckboxIsCheckedRule(
        1,
        form.controls.isVisibilityRestrictedForGroups,
        'Es muss mindestens eine Gruppe ausgewählt werden'
      ),
    )

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateNewsEntryVisibilityAsManagerCommand = {
      newsEntryId: this.newsEntry.newsEntryId,
      visibleForPersona: formValues.isVisibilityRestrictedForPersona
        ? formValues.visibleForPersona!
        : null,
      isVisibilityRestrictedForGroups: formValues.isVisibilityRestrictedForGroups!,
      idsOfGroupsForWhichTheEntryIsVisible: formValues.isVisibilityRestrictedForGroups
        ? formValues.idsOfGroupsForWhichTheEntryIsVisible!
        : [],
    };

    this.store.updateNewsEntryVisibility(command)
      .then(() => showSuccessMessage('Die Sichtbarkeit wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
