
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { formatHours } from '@/helpers';
import { FarmManagerPermission } from '@/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { DataListItem } from '@/components/design-system';
import { useLaborServicesManagementStore } from '../store';
import UpdateLaborServiceSettingsDialog from './update-labor-service-settings-dialog.vue';
import CreateSettingsByUserGroupDialog from './create-settings-by-user-group-dialog.vue';
import UpdateSettingsByUserGroupDialog from './update-settings-by-user-group-dialog.vue';
import DeleteSettingsByUserGroupDialog from './delete-settings-by-user-group-dialog.vue';

@Component({
  components: {
    UpdateLaborServiceSettingsDialog,
    CreateSettingsByUserGroupDialog,
    UpdateSettingsByUserGroupDialog,
    DeleteSettingsByUserGroupDialog,
  },
})
export default class LaborServiceSettings extends Vue {

  readonly store = useLaborServicesManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly tableHeaders: DataTableHeader[] = [
    {
      text: 'Name der Gruppe',
      value: 'groupName',
      sortable: true,
    }, {
      text: 'Jährliche Stundenanzahl pro Reiter',
      value: 'annualNumberOfHours',
      sortable: false,
    }, {
      text: 'Aktionen',
      value: 'actions',
      align: 'end',
      sortable: false,
    },
  ];

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'annualNumberOfHours',
        label: 'Jährliche Stundenanzahl pro Reiter',
        value: this.annualNumberOfHoursFormatted,
      },
    ];
  }

  get annualNumberOfHoursFormatted(): string {
    return formatHours(this.authenticationStore.getLaborServiceAnnualNumberOfHours);
  }

  get isUpdateLaborServiceSettingsVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LABOR_SERVICE_WRITE);
  }

  get isCreateSettingsByUserGroupVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LABOR_SERVICE_WRITE);
  }

  get isUpdateSettingsByUserGroupVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LABOR_SERVICE_WRITE);
  }

  get isDeleteSettingsByUserGroupVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LABOR_SERVICE_WRITE);
  }

  mounted(): void {
    this.store.getSettingsByUserGroups()
      .catch((error) => showErrorResponse(error));
  }

}
