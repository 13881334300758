
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useMyStableStore } from '../store';
import { HorseDrugs } from '../types';
import CreateHorseDrugEntryDialog from './create-horse-drug-entry-dialog.vue';

@Component({
  components: {
    CreateHorseDrugEntryDialog,
  },
})
export default class HorseDrugsCard extends Vue {

  readonly store = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly horseDrugs!: HorseDrugs;

  get isCreateHorseDrugEntryVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

}
