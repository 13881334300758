
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { useLaborServicesManagementStore } from '../store';
import { TimeEntry } from '../types';
import { sortUser } from '../helper';

interface Form extends StrictFormDefinition {
  fields: {
    search: FormField<string|null>;
  }
}

@Component
export default class HandledTimeEntries extends Vue {

  readonly store = useLaborServicesManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    {
      text: 'Benutzer',
      value: 'user',
      sortable: true,
      sort: sortUser,
    }, {
      text: 'Datum',
      value: 'date',
      sortable: true,
    }, {
      text: 'Stundenanzahl',
      value: 'numberOfHours',
      sortable: false,
    }, {
      text: 'Beschreibung',
      value: 'description',
      sortable: false,
    }, {
      text: 'Bearbeiter',
      value: 'handledBy',
      sortable: false,
    }, {
      text: 'Status',
      align: 'center',
      value: 'status',
      sortable: false,
    },
  ];

  form: Form | null = null;

  mounted(): void {
    this.form = this.buildForm();

    this.store.getTimeRecordings()
     .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        search: {
          value: null,
          rules: [],
        },
      },
    };
  }

  filterTimeEntries(value: any, search: string | null, timeEntry: TimeEntry): boolean {
    if (search === null) {
      return true;
    }

    const searchLowerCase = search.toLocaleLowerCase();

    if (timeEntry.user.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (timeEntry.description && timeEntry.description.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (timeEntry.handledBy && timeEntry.handledBy.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    return false;
  }

}
