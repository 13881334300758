
import { Component, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useMyStableStore } from '../store';
import { DeleteHorseDrugEntryCommand, HorseDrugEntry } from '../types';

@Component
export default class HorseDrugsHistory extends Vue {

  readonly store = useMyStableStore();

  get isDeleteHorseDrugEntryVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

  backClicked(): void {
    navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
  }

  async deleteHorseDrugEntryAction(horseDrugEntry: HorseDrugEntry): Promise<void> {
    const command: DeleteHorseDrugEntryCommand = {
      horseDrugEntryId: horseDrugEntry.horseDrugEntryId,
    };

    return this.store.deleteHorseDrugEntry(command)
      .then(() => showSuccessMessage('Eintrag wurde gelöscht.'))
      .then(() => {
        if (this.store.horseDrugs!.history.length === 0) {
          navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
        }
      })
      .catch((error) => showErrorResponse(error));
  }

}
