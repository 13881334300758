
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { isFeatureEnabled } from '@/application/authentication/helper';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { ArchivedStatus, Country, CustomFieldType, CustomFieldValues, Feature, Language } from '@/types';
import { PersonsFilter } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  addressCountry: FormControl<Country>;
  invoiceAddressCountry: FormControl<Country>;
  language: FormControl<Language>;
  customFields: FormControl<CustomFieldValues>;
  archivedStatus: FormControl<ArchivedStatus>;
}

@Component
export default class FilterPersonsDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isInvoiceAddressCountryVisible(): boolean {
    return isFeatureEnabled(Feature.LEDGER_INVOICING);
  }

  get isCustomFieldsVisible(): boolean {
    return !!this.store.customFields
      && this.store.customFields.customFieldsForPerson
        .filter((customField) => customField.type !== CustomFieldType.TEXT
          && customField.type !== CustomFieldType.LONGTEXT)
        .length > 0;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.store.getCustomFields()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => {
            showErrorResponse(error);
            this.closeDialog();
          });
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        addressCountry: {
          label: 'Land in Adresse',
          value: this.store.filterForPersons.addressCountry,
        },
        invoiceAddressCountry: {
          label: 'Land in Rechnungsadresse',
          value: this.store.filterForPersons.invoiceAddressCountry,
        },
        language: {
          label: 'Sprache',
          value: this.store.filterForPersons.language,
        },
        customFields: {
          label: 'Spezifische Daten',
          value: this.store.filterForPersons.customFields,
        },
        archivedStatus: {
          label: 'Archivierte Personen',
          value: this.store.filterForPersons.archivedStatus,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const dto: PersonsFilter = {
        addressCountry: formValues.addressCountry,
        invoiceAddressCountry: formValues.invoiceAddressCountry,
        language: formValues.language,
        customFields: formValues.customFields,
        archivedStatus: formValues.archivedStatus,
      };

    this.store.updateFiltersForPersons(dto)
      .then(() => showSuccessMessage('Filter wurden angewendet.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  resetFilters(): void {
    this.store.resetFiltersForPersons()
      .then(() => showSuccessMessage('Filter wurden zurückgesetzt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
