
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { SurveyAnswerDTO, Survey } from '../types';
import { OptionId } from '@/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class SurveyAnswersForUserMultipleFormControl extends Vue implements FormControlComponent<SurveyAnswerDTO> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<SurveyAnswerDTO>;

  @Prop({ type: Object, required: true })
  readonly survey!: Survey;

  readonly formControlId = createFormControlId();

  readonly internalFieldRules: InternalValueRules = [
    (internalValue: SurveyAnswerDTO) => {
      return internalValue.optionIds!.length >= 1
        ? true
        : 'Mindestens eine Option muss ausgewählt werden'
    },
  ];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: SurveyAnswerDTO | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  areInternalRulesValid = false;

  mounted(): void {
    if (this.formControl.value === null) {
      this.addDefaultAnswerToInternalValue();
    }

    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  radioChanged(optionId: OptionId): void {
    this.focused();

    if (this.internalValue!.optionIds!.includes(optionId)) {
      this.internalValue!.optionIds = this.internalValue!.optionIds!.filter((id) => id !== optionId);
    } else {
      this.internalValue!.optionIds!.push(optionId);
    }

    this.blurred();

    internalValuesChanged(this);
  }

  addDefaultAnswerToInternalValue(): void {
    const firstOptionId = this.survey.options[0].optionId!;

    this.internalValue = {
      optionId: null,
      optionIds: [
        firstOptionId,
      ],
      horseId: null,
    };

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(
        this.internalFieldRules,
        this.internalValue,
      ),
    ];

    this.areInternalRulesValid = messages.length === 0;
    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  /**
   * Values are only updated when it's not null as otherwise the full list would be reset. We don't need the fallback as the defaults are
   * set on mounted.
   */
  updateInternalValues(): void {
    if (this.formControl.value !== null) {
      this.internalValue = this.formControl.value;
    }
  }

  formValueFromInternalValues(): FormControlValue<SurveyAnswerDTO> {
    return this.internalValue !== null
      && this.areInternalRulesValid
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
