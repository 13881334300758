
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Date, HorseId } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, minNumberRule } from '@/components/form';
import { CreateHorseWeightEntryAsManagerCommand } from '../types';
import { useMedicalRecordsManagementStore } from '../store';

interface Controls extends FormControls {
  weighedAt: FormControl<Date>;
  weight: FormControl<number>;
}

@Component
export default class CreateHorseWeightEntryDialog extends Vue {

  readonly store = useMedicalRecordsManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: String, required: true })
  readonly horseId!: HorseId;

  @Prop({ type: Number, default: null })
  readonly currentWeight!: number | null;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        weighedAt: {
          label: 'Gewogen am',
          value: Date.today(),
          isRequired: true,
        },
        weight: {
          label: 'Gewicht',
          value: this.currentWeight,
          isRequired: true,
          rules: [
            minNumberRule(1),
            maxDecimalsNumberRule(0),
          ],
        },
      },
    });
  }

  isDateAllowed(date: Date): boolean {
    return date.isBeforeOrEqualTo(Date.today());
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateHorseWeightEntryAsManagerCommand = {
      horseId: this.horseId,
      weighedAt: formValues.weighedAt!,
      weight: formValues.weight!,
    };

    this.store.createHorseWeightEntry(command)
      .then(() => showSuccessMessage('Das Gewicht wurde eingetragen.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
