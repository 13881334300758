
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { usePaddockPlanManagementStore } from '../store';

@Component
export default class PaddockPlanTabs extends Vue {

  readonly store = usePaddockPlanManagementStore();

  mounted(): void {
    this.store.getPaddockPlan()
      .catch((error) => showErrorResponse(error));
  }

}
