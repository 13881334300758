
import { Component, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { showErrorResponse } from '@/application/snackbar/service';
import { Date } from '@/types';
import { usePinboardStore } from '../store';
import { Event } from '../types';

function isEventBookedOut(event: Event): boolean {
  return event.maxNumberOfParticipants !== null
    && event.maxNumberOfParticipants <= event.numberOfParticipants;
}

function areLessThanSeatsLeftForEvent(event: Event, seats: number): boolean {
  return event.maxNumberOfParticipants !== null
    && (event.maxNumberOfParticipants - event.numberOfParticipants) < seats;
}

function isEventRegistrationExpired(event: Event): boolean {
  return event.registrationDeadline !== null
    && event.registrationDeadline.isBefore(Date.today());
}

@Component
export default class EventList extends Vue {

  readonly store = usePinboardStore();

  mounted(): void {
    this.store.getEvents()
      .catch((error) => showErrorResponse(error));
  }

  announcementNote(event: Event): string {
    const isRegistered = event.ownRegistrations.length > 0;
    const isBookedOut = isEventBookedOut(event);
    const areLessThanSeven = areLessThanSeatsLeftForEvent(event, 7);
    const areLessThanFour = areLessThanSeatsLeftForEvent(event, 4);
    const isRegistrationExpired = isEventRegistrationExpired(event);

    if (isRegistered) {
      return 'Für Veranstaltung angemeldet';
    } else if (isRegistrationExpired || isBookedOut) {
      return 'Keine Anmeldung mehr möglich';
    } else if (areLessThanFour) {
      return 'Drei oder weniger Plätze';
    } else if (areLessThanSeven) {
      return 'Sechs oder weniger Plätze frei';
    } else {
      return 'Ausreichend Plätze frei';
    }
  }

  getAnnouncementIcon(event: Event): string {
    return event.ownRegistrations.length > 0
      ? 'calendar-check'
      : 'calendar';
  }

  getAnnouncementColorClasses(event: Event) {
    let isRegistered;
    let isStatusGreen;
    let isStatusYellow;
    let isStatusRed;

    const isBookedOut = isEventBookedOut(event);
    const areLessThanSeven = areLessThanSeatsLeftForEvent(event, 7);
    const areLessThanFour = areLessThanSeatsLeftForEvent(event, 4);
    const isRegistrationExpired = isEventRegistrationExpired(event);

    isRegistered = (event).ownRegistrations.length > 0;
    isStatusGreen = !isRegistered && !isBookedOut && !isRegistrationExpired && !areLessThanSeven;
    isStatusYellow = !isRegistered && !isBookedOut && !isRegistrationExpired && areLessThanSeven && !areLessThanFour;
    isStatusRed = !isRegistered && !isBookedOut && !isRegistrationExpired && areLessThanSeven && areLessThanFour;

    return {
      'status-green': isStatusGreen,
      'status-yellow': isStatusYellow,
      'status-red': isStatusRed,
    };
  }

  showEvent(event: Event): void {
    navigate({ name: 'event-details', params: { eventId: event.eventId } });
  }

}
