import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestForFile } from '@/infrastructure/api-requests';
import { FileResponse } from '@/application/types';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CSV } from '@/types';
import { AddConcentratedFeedTypeAsManagerCommand, AddCreditNoteAsManagerCommand, AddCreditNotesAsManagerWorkflow, AddCustomBookingAsManagerCommand, AddCustomBookingsAsManagerWorkflow, AddFarmServiceAsManagerCommand, ArchiveFarmServiceAsManagerCommand, CreditNote, CustomBooking, DefineFarmBankInformationAsManagerCommand, DefineFarmTermsOfPaymentAsManagerCommand, DefineInvoiceInformationAsManagerCommand, DisableFarmServiceAsManagerCommand, EnableFarmServiceAsManagerCommand, GetCreditNotesAsManagerQuery, GetCustomBookingsAsManagerQuery, GetInvoicePDFAsManagerQuery, GetInvoicesAsZIPForMonthAsManagerQuery, GetInvoicesForMonthAsManagerQuery, GetSettlementsForMonthAsCSVAsManagerQuery, GetSettlementsForMonthAsManagerQuery, HorseOwner, Invoice, InvoiceInformation, LedgerConfiguration, PersonForFilter, RevokeCreditNoteAsManagerCommand, RevokeCustomBookingAsManagerCommand, SettlementsForMonth, UpdateCreditNotePaymentMethodAsManagerCommand, UpdateCustomBookingPaymentMethodAsManagerCommand, UpdateFarmAddressAsManagerCommand, UpdateFarmBankInformationAsManagerCommand, UpdateFarmLegalInformationAsManagerCommand, UpdateFarmServiceBookingStartAsManagerCommand, UpdateFarmServicePaymentMethodAsManagerCommand, UpdateFarmServicePresentationAsManagerCommand, UpdateFarmServicePriceAsManagerCommand, UpdateFarmServiceRestrictionsAsManagerCommand, UpdateFarmTermsOfPaymentAsManagerCommand } from './types';

// -- Queries

export function getConfiguration(query: FarmAndUserProperties): Promise<LedgerConfiguration> {
  const url = `${apiUrl}/api/ledger/get-configuration-as-manager-query`;
  return performApiRequest<LedgerConfiguration>({ url, method: 'POST', data: query });
}

export function getCustomBookings(query: GetCustomBookingsAsManagerQuery & FarmAndUserProperties): Promise<CustomBooking[]> {
  const url = `${apiUrl}/api/ledger/get-custom-bookings-as-manager-query`;
  return performApiRequest<CustomBooking[]>({ url, method: 'POST', data: query });
}

export function getCreditNotes(query: GetCreditNotesAsManagerQuery & FarmAndUserProperties): Promise<CreditNote[]> {
  const url = `${apiUrl}/api/ledger/get-credit-notes-as-manager-query`;
  return performApiRequest<CreditNote[]>({ url, method: 'POST', data: query });
}

export function getSettlementsForMonth(query: GetSettlementsForMonthAsManagerQuery & FarmAndUserProperties): Promise<SettlementsForMonth> {
  const url = `${apiUrl}/api/ledger/get-settlements-for-month-as-manager-query`;
  return performApiRequest<SettlementsForMonth>({ url, method: 'POST', data: query });
}

export function getSettlementsForMonthAsCSV(query: GetSettlementsForMonthAsCSVAsManagerQuery & FarmAndUserProperties): Promise<CSV> {
  const url = `${apiUrl}/api/ledger/get-settlements-for-month-as-csv-as-manager-query`;
  return performApiRequest<CSV>({ url, method: 'POST', data: query });
}

export function getHorseOwners(query: FarmAndUserProperties): Promise<HorseOwner[]> {
  const url = `${apiUrl}/api/ledger/get-horse-owners-as-manager-query`;
  return performApiRequest<HorseOwner[]>({ url, method: 'POST', data: query });
}

export function getInvoiceInformation(query: FarmAndUserProperties): Promise<InvoiceInformation> {
  const url = `${apiUrl}/api/ledger/get-invoice-information-as-manager-query`;
  return performApiRequest<InvoiceInformation>({ url, method: 'POST', data: query });
}

export function getInvoicesForMonth(query: GetInvoicesForMonthAsManagerQuery & FarmAndUserProperties): Promise<Invoice[]> {
  const url = `${apiUrl}/api/ledger/get-invoices-for-month-as-manager-query`;
  return performApiRequest<Invoice[]>({ url, method: 'POST', data: query });
}

export function getInvoicePDF(query: GetInvoicePDFAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/ledger/get-invoice-pdf-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getInvoicesAsZIPForMonth(query: GetInvoicesAsZIPForMonthAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/ledger/get-invoices-as-zip-for-month-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getPersons(query: FarmAndUserProperties): Promise<PersonForFilter[]> {
  const url = `${apiUrl}/api/ledger/get-persons-as-manager-query`;
  return performApiRequest<PersonForFilter[]>({ url, method: 'POST', data: query });
}

// -- Commands

// Custom bookings

export function addCustomBooking(command: AddCustomBookingAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/add-custom-booking-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function addCustomBookings(command: AddCustomBookingsAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/add-custom-bookings-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function revokeCustomBooking(command: RevokeCustomBookingAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/revoke-custom-booking-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateCustomBookingPaymentMethod(
  command: UpdateCustomBookingPaymentMethodAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-custom-booking-payment-method-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

// Credit notes

export function addCreditNote(command: AddCreditNoteAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/add-credit-note-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function addCreditNotes(command: AddCreditNotesAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/add-credit-notes-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function revokeCreditNote(command: RevokeCreditNoteAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/revoke-credit-note-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateCreditNotePaymentMethod(
  command: UpdateCreditNotePaymentMethodAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-credit-note-payment-method-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

// Farm services

export function addFarmService(command: AddFarmServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/add-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServicePresentation(
  command: UpdateFarmServicePresentationAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-farm-service-presentation-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServicePrice(command: UpdateFarmServicePriceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-farm-service-price-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServicePaymentMethod(
  command: UpdateFarmServicePaymentMethodAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-farm-service-payment-method-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServiceBookingStart(
  command: UpdateFarmServiceBookingStartAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-farm-service-booking-start-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmServiceRestrictions(
  command: UpdateFarmServiceRestrictionsAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-farm-service-restrictions-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function enableFarmService(command: EnableFarmServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/enable-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function disableFarmService(command: DisableFarmServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/disable-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function archiveFarmService(command: ArchiveFarmServiceAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/archive-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

// Gerblhof feeding

export function addConcentratedFeedType(command: AddConcentratedFeedTypeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/add-concentrated-feed-type-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

// Invoicing

export function defineInvoiceInformation(command: DefineInvoiceInformationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/define-invoice-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmAddress(command: UpdateFarmAddressAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-farm-address-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmLegalInformation(command: UpdateFarmLegalInformationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-farm-legal-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function defineFarmBankInformation(command: DefineFarmBankInformationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/define-farm-bank-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmBankInformation(command: UpdateFarmBankInformationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-farm-bank-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function removeFarmBankInformation(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/remove-farm-bank-information-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function defineFarmTermsOfPayment(command: DefineFarmTermsOfPaymentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/define-farm-terms-of-payment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateFarmTermsOfPayment(command: UpdateFarmTermsOfPaymentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-farm-terms-of-payment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function removeFarmTermsOfPayment(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/remove-farm-terms-of-payment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
