
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { HorseId, IllnessId, Date, VaccinationWaitPeriod, VaccineId } from '@/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, ValuesOfFormControls } from '@/components/form';
import { useMedicalRecordsManagementStore } from '../store';
import { CreateVaccinationAsManagerCommand, Horse, Illness, Vaccine } from '../types';

interface Controls extends FormControls {
  horse: FormControl<HorseId>;
  illness: FormControl<IllnessId>;
  vaccine: FormControl<VaccineId>;
  vaccinationDate: FormControl<Date>;
  nextVaccinationAfter: FormControl<VaccinationWaitPeriod>;
  notice: FormControl<string>;
}

@Component
export default class CreateVaccinationDialog extends Vue {

  readonly store = useMedicalRecordsManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get horses(): Horse[] {
    return this.store.dataForVaccinationCreation?.horses ?? [];
  }

  get illnesses(): Illness[] {
    return this.store.dataForVaccinationCreation?.illnesses ?? [];
  }

  get vaccines(): Vaccine[] {
    if (!this.store.dataForVaccinationCreation
      || !this.form
    ) {
      return [];
    }

    const formValues = getFormValues(this.form);
    if (!formValues.illness) {
      return [];
    }

    return this.store.dataForVaccinationCreation.illnesses
      .find((illness) => illness.illnessId === formValues.illness)!
      .vaccines;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (!isDialogVisible) {
        this.form = null;
        return;
      }

      if (this.store.dataForVaccinationCreation) {
        this.store.getDataForVaccinationCreation()
          .catch((error) => showErrorResponse(error));
        this.form = this.buildForm();
      } else {
        this.store.getDataForVaccinationCreation()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => showErrorResponse(error));
      }
    });
  }

  buildForm(): Form<Controls> {
    const initialIllness = this.store.dataForVaccinationCreation!.illnesses.length > 0
      ? this.store.dataForVaccinationCreation!.illnesses[0].illnessId
      : null;
    const initialVaccine = this.store.dataForVaccinationCreation!.illnesses.length > 0
      && this.store.dataForVaccinationCreation!.illnesses[0].vaccines.length > 0
      ? this.store.dataForVaccinationCreation!.illnesses[0].vaccines[0].vaccineId
      : null;

    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        horse: {
          label: 'Pferd',
          value: null,
          isRequired: true,
        },
        illness: {
          label: 'Krankheit',
          value: initialIllness,
          isRequired: true,
          transformValuesOnInput: (values: ValuesOfFormControls<Controls>) => {
            const vaccines = this.store.dataForVaccinationCreation!.illnesses
              .find((illness) => illness.illnessId === values.illness)!
              .vaccines;

            if (vaccines.length > 0
              && !vaccines.some((vaccine) => vaccine.vaccineId === values.vaccine)
            ) {
              return {
                vaccine: vaccines[0].vaccineId,
              };
            }

            return {};
          },
        },
        vaccine: {
          label: 'Impfstoff',
          value: initialVaccine,
          isRequired: true,
        },
        vaccinationDate: {
          label: 'Impfung durchgeführt am',
          value: Date.today(),
          isRequired: true,
        },
        nextVaccinationAfter: {
          label: 'Nächste Impfung',
          value: VaccinationWaitPeriod.FOUR_WEEKS,
          isRequired: true,
        },
        notice: {
          label: 'Notiz',
          value: null,
        },
      },
    });
  }

  isVaccinationDateAllowed(date: Date): boolean {
    const today = Date.today();

    return date.isBeforeOrEqualTo(today);
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateVaccinationAsManagerCommand = {
      horseId: formValues.horse!,
      illnessId: formValues.illness!,
      vaccineId: formValues.vaccine!,
      vaccinationDate: formValues.vaccinationDate!,
      nextVaccinationAfter: formValues.nextVaccinationAfter!,
      notice: formValues.notice,
    };

    this.store.createVaccination(command)
      .then(() => showSuccessMessage('Impfung wurde eingetragen.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
