
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useVaccineManagementStore } from '../store';
import CreateVaccineDialog from './create-vaccine-dialog.vue';

@Component({
  components: {
    CreateVaccineDialog,
  },
})
export default class ManageVaccines extends Vue {

  readonly store = useVaccineManagementStore();

  mounted(): void {
    this.store.getIllnesses()
      .catch((error) => showErrorResponse(error));

    this.store.getVaccines()
      .catch((error) => showErrorResponse(error));
  }

  illnessName(illnessId: string): string {
    return this.store.illnesses.find((illness) => illness.illnessId === illnessId)?.name || '-';
  }

}
