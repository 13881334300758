
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useFarmTaskManagementStore } from '../store';
import { DeleteTaskAsManagerCommand, Task } from '../types';

@Component
export default class DeleteTaskDialog extends Vue {

  readonly store = useFarmTaskManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly task!: Task;

  isDialogVisible = false;

  deleteTaskConfirmed(): void {
    const command: DeleteTaskAsManagerCommand = {
      farmTaskId: this.task.farmTaskId,
    };

    this.store.deleteTask(command)
      .then(() => showSuccessMessage('Die Aufgabe wurde gelöscht.'))
      .then(() => this.$emit('task-deleted'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
