
import { Component, Prop, Vue } from 'vue-property-decorator';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { Date, FarmTaskId, FarmManagerPermission } from '@/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { useFarmTaskManagementStore } from '../store';
import { HorseWithTasks, MarkTaskAsCompletedAsManagerCommand, TaskForGroupedByHorse } from '../types';
import { taskNoteForTaskGroupedByHorse } from '../helper';
import CreateTaskDialog from './create-task-dialog.vue';
import DuplicateTaskDialog from './duplicate-task-dialog.vue';
import UpdateTitleAndDetailsOfTaskDialog from './update-title-and-details-of-task-dialog.vue';
import UpdateLastRepetitionOfTaskDialog from './update-last-repetition-of-task-dialog.vue';
import DeleteTaskDialog from './delete-task-dialog.vue';
import AssignTaskToUserDialog from './assign-task-to-user-dialog.vue';
import UpdateExecutionDateOfTaskDialog from './update-execution-date-of-task-dialog.vue';
import UpdateTimeConfigurationOfTaskDialog from './update-time-configuration-of-task-dialog.vue';

@Component({
  components: {
    UpdateTimeConfigurationOfTaskDialog,
    UpdateLastRepetitionOfTaskDialog,
    UpdateTitleAndDetailsOfTaskDialog,
    DuplicateTaskDialog,
    CreateTaskDialog,
    DeleteTaskDialog,
    AssignTaskToUserDialog,
    UpdateExecutionDateOfTaskDialog,
  },
  methods: {
    taskNoteForTaskGroupedByHorse,
  },
})
export default class TasksGroupedByHorseLists extends Vue {

  readonly store = useFarmTaskManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Array, required: true })
  readonly tasksGroupedByHorse!: HorseWithTasks[];

  readonly taskMenuList: Record<FarmTaskId, boolean> = {};

  get isCreateTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isUpdateTitleAndDetailsOfTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isUpdateTimeConfigurationOfTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isDuplicateTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isAssignTaskToUserVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get isDeleteTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get initialDateForTaskCreation(): Date | null {
    return this.store.executionDateForGroupedByHorse
    && this.store.executionDateForGroupedByHorse.isAfterOrEqualTo(Date.today())
      ? this.store.executionDateForGroupedByHorse
      : Date.today();
  }

  getTasks(): void {
    this.store.getTasksGroupedByHorse()
      .catch((error) => showErrorResponse(error));
  }

  isUpdateTaskVisible(task: TaskForGroupedByHorse): boolean {
    return this.isUpdateTitleAndDetailsOfTaskVisible
      || this.isUpdateTimeConfigurationOfTaskVisible
      || this.isDuplicateTaskVisible
      || this.isAssignTaskToUserVisible
      || this.isDeleteTaskVisible
      || this.isMarkTaskAsCompletedVisible(task)
      || this.isUpdateLastRepetitionOfTaskVisible(task)
      || this.isUpdateExecutionDateOfTaskIsVisible(task);
  }

  isMarkTaskAsCompletedVisible(task: TaskForGroupedByHorse): boolean {
    return !task.isCompleted
      && this.store.executionDateForGroupedByHorse!.isBeforeOrEqualTo(Date.today())
      && (doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE)
        || task.idOfAssignedUser === this.authenticationStore.user!.id
      );
  }

  isUpdateLastRepetitionOfTaskVisible(task: TaskForGroupedByHorse): boolean {
    return !!task.repetition
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  isUpdateExecutionDateOfTaskIsVisible(task: TaskForGroupedByHorse): boolean {
    return !task.repetition
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  async markTaskAsCompletedAction(task: TaskForGroupedByHorse): Promise<void> {
    const command: MarkTaskAsCompletedAsManagerCommand = {
      farmTaskId: task.farmTaskId,
      executionDate: this.store.executionDateForGroupedByHorse!,
    };

    return this.store.markTaskAsCompleted(command)
      .then(() => this.store.getTasksGroupedByHorse())
      .then(() => showSuccessMessage('Die Aufgabe wurde als erledigt markiert.'))
      .catch((error) => showErrorResponse(error));
  }

}
