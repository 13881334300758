
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { maxNumberRule, positiveNumberRule, requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition } from '@/application/types';
import { DialogWidth } from '@/helpers/data';
import { Time } from '@/types';
import { Facility, UpdateBookingInAdvanceOfFacilityAsManagerCommand } from '../types';
import { useFacilityReservationsManagementStore } from '../store';

interface Form extends StrictFormDefinition {
  fields: {
    bookingInAdvance: FormField<number|null>;
    timeToGrantAccessForNewDayInAdvance: FormField<Time>;
  };
}

const defaultBookingInAdvance = 7;
const selectDefaultTimeToGrantAccessForNewDayInAdvance = new Time(18, 0, 0);

@Component
export default class UpdateBookingInAdvanceOfFacilityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form | null = null;

  get facilityName(): string | null {
    return this.store.currentFacility?.name ?? null;
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        bookingInAdvance: {
          value: this.facility.facilityReservationConfiguration.bookingInAdvance || defaultBookingInAdvance,
          rules: [
            requiredRule(),
            positiveNumberRule(0),
            maxNumberRule(365),
          ],
        },
        timeToGrantAccessForNewDayInAdvance: {
          value: this.facility.facilityReservationConfiguration.timeToGrantAccessForNewDayInAdvance
            || selectDefaultTimeToGrantAccessForNewDayInAdvance,
          rules: [
            requiredRule(),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateBookingInAdvanceOfFacilityAsManagerCommand = {
      facilityId: this.facility.facilityId,
      facilityBookingInAdvance: this.form!.fields.bookingInAdvance.value!,
      facilityTimeToGrantAccessForNewDayInAdvance: this.form!.fields.timeToGrantAccessForNewDayInAdvance.value,
    };
    this.store.updateBookingInAdvanceOfFacility(command)
      .then(() => showSuccessMessage('Die Konfiguration wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
