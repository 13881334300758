
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useAnalyticsStore } from '../store';

@Component
export default class LatestOwnersChart extends Vue {

  readonly store = useAnalyticsStore();

  @Prop({ type: Number, default: 10 })
  readonly maxUsers!: number;

}
