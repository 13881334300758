
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { Moment } from '@/types';
import { moment } from '@/helpers';
import { useFreeJumpingFreeRunningManagementStore } from '../store';

@Component
export default class FreeJumpingFreeRunningEventAdminComponent extends Vue {

  readonly store = useFreeJumpingFreeRunningManagementStore();
  readonly appStore = useAppStore();

  mounted(): void {
    this.store.updateCurrentEvent(this.$route.params.id)
      .catch((error) => showErrorResponse(error));

    watch(() => this.store.currentEvent, () => {
      const title = (this.store.currentEvent)
        ? this.store.currentEvent.date.format('DD.MM.YYYY')
        : '…';
      this.appStore.updateTitle(title)
        .catch((error) => showErrorResponse(error));
      }, { immediate: true }
    );
  }

  get isLoadingInitially(): boolean {
    return this.store.isGetEventsProcessing
      && !this.store.currentEvent;
  }

  get eventFound(): boolean {
    return !!this.store.currentEvent;
  }

  get eventNotFound(): boolean {
    return !this.store.isGetEventsProcessing
      && !this.store.currentEvent;
  }

  get registrationDeadline(): Moment | null {
    return this.store.currentEvent?.date.clone().subtract(1, 'day') ?? null;
  }

  get registrationDeadlineVerb(): string {
    return moment().isBefore(this.store.currentEvent?.date) ? 'ist' : 'war';
  }

  get hasFreeJumpingRegistrations(): boolean {
    return !!this.store.currentEvent
      && this.store.currentEvent.freeJumpingRegistrations.length > 0;
  }

  get hasFreeRunningRegistrations(): boolean {
    return !!this.store.currentEvent
      && this.store.currentEvent.freeRunningRegistrations.length > 0;
  }

}
