
import { Component, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifySelectItem, VuetifyValidatable } from '@/application/types';
import { generateTimeRangeSelectItems } from '@/helpers/form-helpers';
import { DialogWidth } from '@/helpers/data';
import { useFacilityReservationsManagementStore } from '../store';
import { UpdateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    minDistanceToOwnBookingsWithDifferentHorsesIntervals: FormField<number>;
  };
}

@Component
export default class UpdateMinDistanceToOwnBookingsWithDifferentHorseOfFacilityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Ref()
  readonly formElement?: VuetifyValidatable;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  minDistanceToOwnBookingsWithDifferentHorsesIntervalsSelectItems: VuetifySelectItem<number>[] = [];

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.minDistanceToOwnBookingsWithDifferentHorsesIntervalsSelectItems = generateTimeRangeSelectItems(
        this.store.currentFacility!.openingHours.timeFrom,
        this.store.currentFacility!.openingHours.timeTo,
        this.store.currentFacility!.facilityReservationConfiguration.timeOptionInterval,
        true
      );

      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (this.formElement) {
        this.formElement.resetValidation();
      }
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        minDistanceToOwnBookingsWithDifferentHorsesIntervals: {
          value: this.store.currentFacility?.facilityReservationConfiguration.minDistanceToOwnBookingsWithDifferentHorsesIntervals ?? 1,
          rules: [
            requiredRule(),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    if (!this.store.currentFacility) {
      return;
    }
    const command: UpdateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityAsManagerCommand = {
      facilityId: this.store.currentFacility.facilityId,
      facilityMinDistanceToOwnBookingsWithDifferentHorsesIntervals:
        this.form!.fields.minDistanceToOwnBookingsWithDifferentHorsesIntervals.value,
    };
    this.store.updateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacility(command)
      .then(() => showSuccessMessage('Der Mindestabstand wurden angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
