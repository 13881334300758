
import { Component, Prop, Vue } from 'vue-property-decorator';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { DataListItem } from '@/components/design-system';
import { paymentMethodTranslations } from '@/helpers/translations';
import { useLedgerStore } from '../store';
import { LedgerConfiguration } from '../types';
import UpdateCustomBookingPaymentMethodDialog from './update-custom-booking-payment-method-dialog.vue';
import UpdateCreditNotePaymentMethodDialog from './update-credit-note-payment-method-dialog.vue';

@Component({
  components: {
    UpdateCustomBookingPaymentMethodDialog,
    UpdateCreditNotePaymentMethodDialog,
  },
})
export default class ConfigurationForCustomBookingsAndCreditNotes extends Vue {

  readonly store = useLedgerStore();

  @Prop({ type: Object, required: true })
  readonly configuration!: LedgerConfiguration;

  get customBookingDataItems(): DataListItem[] {
    return [
      {
        key: 'paymentMethod',
        label: 'Bezahlmethode',
        value: paymentMethodTranslations[this.configuration.customBooking.paymentMethod],
      },
    ];
  }

  get creditNoteDataItems(): DataListItem[] {
    return [
      {
        key: 'paymentMethod',
        label: 'Bezahlmethode',
        value: paymentMethodTranslations[this.configuration.creditNote.paymentMethod],
      },
    ];
  }

  get isUpdateCustomBookingPaymentMethodVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  get isUpdateCreditNotePaymentMethodVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

}
