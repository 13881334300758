
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/authentication/store';
import { showErrorResponse } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { usePaddockPlanManagementStore } from '../store';
import { HorseIncludingOwner } from '../types';

interface Controls extends FormControls {
  search: FormControl<string>;
}

@Component
export default class HorseSelection extends Vue {

  readonly store = usePaddockPlanManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  form: Form<Controls> | null = null;

  get horsesFilteredBySearch(): HorseIncludingOwner[] {
    const horses: HorseIncludingOwner[] = this.store.paddockPlan?.horsesToAssign ?? [];

    if (!this.form
      || this.form.controls.search.value === null
    ) {
      return horses;
    }

    const searchTerm = this.form.controls.search.value.toLowerCase();

    return horses.filter((horse) => horse.name.toLowerCase().includes(searchTerm)
      || horse.owner.name.toLowerCase().includes(searchTerm));
  }

  get isNoHorsesFoundVisible(): boolean {
    return !!this.form
      && this.form.controls.search.value !== null
      && this.horsesFilteredBySearch.length === 0;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche nach Name',
          value: null,
        },
      },
    });
  }

  horseSelected(horse: HorseIncludingOwner): void {
    if (this.store.idOfSelectedHorse === horse.horseId) {
      this.store.revertHorseSelection()
        .catch((error) => showErrorResponse(error));
    } else {
      this.store.selectHorse(horse.horseId)
        .catch((error) => showErrorResponse(error));
    }
  }

}
