
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission, isFeatureEnabled } from '@/application/authentication/helper';
import { ArchivedStatus, Date, FarmManagerPermission, Feature, NameOrderType, PersonId } from '@/types';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { formatDate, formatName } from '@/helpers';
import { navigate } from '@/helpers/navigation-helpers';
import { downloadCSVFile } from '@/helpers/file-download-helper';
import { useCustomerMasterDataManagementStore } from '../store';
import { PersonForList } from '../types';
import CreatePersonDialog from './create-person-dialog.vue';
import FilterPersonsDialog from './filter-persons-dialog.vue';
import UpdateSalutationOfPersonDialog from './update-salutation-of-person-dialog.vue';
import UpdateNameOfPersonDialog from './update-name-of-person-dialog.vue';
import UpdateAddressOfPersonDialog from './update-address-of-person-dialog.vue';
import UpdateInvoiceAddressOfPersonDialog from './update-invoice-address-of-person-dialog.vue';
import UpdateAdditionalMasterDataOfPersonDialog from './update-additional-master-data-of-person-dialog.vue';
import UpdateEmailAddressOfPersonDialog from './update-email-address-of-person-dialog.vue';
import ArchivePersonDialog from './archive-person-dialog.vue';

interface Controls extends FormControls {
  search: FormControl<string>;
  nameOrderType: FormControl<NameOrderType>;
}

@Component({
  components: {
    UpdateEmailAddressOfPersonDialog,
    CreatePersonDialog,
    FilterPersonsDialog,
    UpdateSalutationOfPersonDialog,
    UpdateNameOfPersonDialog,
    UpdateAddressOfPersonDialog,
    UpdateInvoiceAddressOfPersonDialog,
    UpdateAdditionalMasterDataOfPersonDialog,
    ArchivePersonDialog,
  },
})
export default class PersonList extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly updatePersonMenuList: Record<PersonId, boolean> = {};

  get isCreatePersonVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  get isUpdateInvoiceAddressVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE)
      && isFeatureEnabled(Feature.LEDGER_INVOICING);
  }

  get isLoadMoreButtonVisible(): boolean {
    return this.store.totalCountOfPersons !== null;
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.store.totalCountOfPersons === null
      || this.store.persons.length >= this.store.totalCountOfPersons;
  }

  get arePersonsFiltered(): boolean {
    return this.store.filterForPersons.addressCountry !== null
      || this.store.filterForPersons.invoiceAddressCountry !== null
      || this.store.filterForPersons.language !== null
      || this.store.filterForPersons.customFields !== null
      || this.store.filterForPersons.archivedStatus !== ArchivedStatus.NOT_ARCHIVED;
  }

  form: Form<Controls> | null = null;

  mounted(): void {
    this.form = this.buildForm();

    this.store.getPersons()
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetLimitForPersons()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
          afterTransformationAndValidation: (search) => this.store.updateSearchForPersons(search)
            .catch((error) => showErrorResponse(error)),
        },
        nameOrderType: {
          label: 'Namensreihenfolge und -darstellung',
          value: this.store.nameOrderTypeForPersons,
          afterTransformationAndValidation: (nameOrderType) => this.store.updateNameOrderTypeForPersons(nameOrderType!)
            .catch((error) => showErrorResponse(error)),
        },
      },
    });
  }

  personIcon(person: PersonForList): string {
    return person.isConnectedToUser
      ? 'user'
      : 'user-slash';
  }

  note(person: PersonForList): string {
    const connectedToUserNote = !person.isConnectedToUser
      ? ', nicht mit Benutzer verknüpft'
      : '';

    const historyEntry = person.changeHistory.length > 1
      ? person.changeHistory[person.changeHistory.length - 1]
      : person.changeHistory[0];

    const changeHistoryNote = person.changeHistory.length > 1
      ? `Angepasst am ${formatDate(historyEntry.changedAt)} von ${formatName(historyEntry.nameOfUser)}`
      : `Erstellt am ${formatDate(historyEntry.changedAt)} von ${formatName(historyEntry.nameOfUser)}`;

    return `${changeHistoryNote}${connectedToUserNote}`;
  }

  personClicked(person: PersonForList): void {
    navigate({ name: 'customer-master-data-person-details', params: { personId: person.personId }});
  }

  onLoadMoreClicked(): void {
    this.store.increaseLimitForPersons()
      .catch((error) => showErrorResponse(error));
  }

  personUpdated(): void {
    this.store.getPersons()
      .catch((error) => showErrorResponse(error));
  }

  exportPersons(): void {
    const fileName = `Personenexport-${Date.today().format('YYYY-MM-DD')}.csv`;
    this.store.getPersonsAsCSV()
      .then((csv) => downloadCSVFile(csv, fileName))
      .catch((error) => showErrorResponse(error));
  }

  isUpdatePersonVisible(person: PersonForList): boolean {
    return !person.isArchived
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

}
