
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { generateTimesSelectItems } from '@/helpers/form-helpers';
import { notMidnightRule, requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifySelectItem, VuetifyValidatable } from '@/application/types';
import { DialogWidth } from '@/helpers/data';
import { Time } from '@/types';
import { usePaddockPlanManagementStore } from '../store';
import { PaddockPlanTimeRange, UpdateTimeRangeAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    timeFrom: FormField<Time>;
    timeTo: FormField<Time>;
  },
}

@Component
export default class UpdatePaddockPlanTimeRangeDialog extends Vue {

  readonly store = usePaddockPlanManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly timeRange!: PaddockPlanTimeRange;

  @Ref()
  readonly timeFromSelect?: VuetifyValidatable;

  @Ref()
  readonly timeToSelect?: VuetifyValidatable;

  form: Form | null = null;
  isDialogVisible = false;

  get timesSelectItems(): VuetifySelectItem<Time>[] {
    return generateTimesSelectItems(
      new Time(5, 0, 0),
      new Time(0, 0, 0),
      15
    );
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  startBeforeEnd(timeFromFormField: FormField<Time>, timeToFormField: FormField<Time>) {
    return () => timeToFormField.value.isMidnight
      || timeFromFormField.value.isBefore(timeToFormField.value)
      || 'Startzeit muss vor der Endzeit liegen';
  }

  endAfterStart(timeFromFormField: FormField<Time>, timeToFormField: FormField<Time>) {
    return () => timeToFormField.value.isMidnight
      || timeToFormField.value.isAfter(timeFromFormField.value)
      || 'Endzeit muss nach der Startzeit liegen';
  }

  buildForm(): Form {
    const form: Form = {
      valid: false,
      fields: {
        timeFrom: {
          value: this.timeRange.timeFrame.timeFrom,
          rules: [
            requiredRule(),
            notMidnightRule('Die Von Zeit darf nicht um Mitternacht sein'),
          ],
        },
        timeTo: {
          value: this.timeRange.timeFrame.timeTo,
          rules: [
            requiredRule(),
          ],
        },
      },
    };

    form.fields.timeFrom.rules.push(this.startBeforeEnd(form.fields.timeFrom, form.fields.timeTo));
    form.fields.timeTo.rules.push(this.endAfterStart(form.fields.timeFrom, form.fields.timeTo));

    return form;
  }

  timeFromChanged(): void {
    if (!this.form!.fields.timeFrom) {
      return;
    }

    const timeOptionIndex = this.timesSelectItems
      .findIndex((option) => option.value.isSame(this.form!.fields.timeFrom.value));

    this.form!.fields.timeTo.value = this.timesSelectItems[timeOptionIndex + 4]?.value || new Time(0, 0, 0);

    // Guarantee element since form obviously exists.
    this.timeToSelect!.validate();
  }

  timeToChanged(): void {
    // Guarantee element since form obviously exists.
    this.timeFromSelect!.validate();
  }

  onSubmit(): void {
    const command: UpdateTimeRangeAsManagerCommand = {
      paddockPlanTimeRangeId: this.timeRange.paddockPlanTimeRangeId,
      timeRange: {
        timeFrom: this.form!.fields.timeFrom.value,
        timeTo: this.form!.fields.timeTo.value,
      },
    };

    this.store.updateTimeRange(command)
      .then(() => showSuccessMessage('Das Zeitfenster wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
