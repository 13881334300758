
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { useRiderTaskManagementStore } from '../store';
import { constructForm, Form, FormControl, FormControls, FormControlValue } from '@/components/form';
import { UserId } from '@/types';

interface Controls extends FormControls {
  user: FormControl<UserId>;
}

@Component
export default class WithdrawnTasks extends Vue {

  readonly store = useRiderTaskManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Bearbeitungsdatum', value: 'executionDate' },
    { text: 'Auftraggeber', value: 'user' },
    { text: 'Beschreibung', value: 'description' },
    { text: 'Zurückgezogen am', value: 'withdrawnAt' },
  ];

  form: Form<Controls> | null = null;

  get isLoadMoreButtonDisabled(): boolean {
    return this.store.withdrawnTasksTotalCount === null
      || this.store.withdrawnTasks.length >= this.store.withdrawnTasksTotalCount;
  }

  get noDataText(): string {
    return this.store.withdrawnTasksFilteredByUser
      ? 'Dieser Benutzer hat noch keine Aufgaben zurückgezogen.'
      : 'Es gibt keine zurückgezogene Aufgaben.';
  }

  mounted(): void {
    this.store.getWithdrawnTasks()
      .catch((error) => showErrorResponse(error));

    this.store.getUsersWithTasks()
      .catch((error) => showErrorResponse(error));

    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        user: {
          label: 'Benutzer',
          value: null,
          afterTransformationAndValidation: (value: FormControlValue<UserId>) => {
            this.store.filterWithdrawnTasksByUser(value)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  onLoadMoreClicked(): void {
    this.store.increaseWithdrawnTasksLimit()
      .catch((error) => showErrorResponse(error));
  }

}
