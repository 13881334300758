
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { formatDate, formatTime } from '@/helpers';
import { PersonId } from '@/types';
import { moment } from '@/helpers';
import { Booking, Settlement } from '@/private/management/ledger/ledger-configuration/types';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { useLedgerStore } from '@/private/management/ledger/ledger-configuration/store';
import SettlementDetailsDialog from '../../ledger-configuration/components/settlement-details-dialog.vue';
import { useSettlementForecastStore } from '../store';

interface Controls extends FormControls {
  selectedPerson: FormControl<PersonId>;
}

@Component({
  components: {
    SettlementDetailsDialog,
  },
})
export default class SettlementForecastTable extends Vue {

  readonly store = useSettlementForecastStore();
  readonly ledgerStore = useLedgerStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Person', value: 'person' },
    { text: 'Überweisung', value: 'bankTransfer' },
    { text: 'Lastschrift', value: 'directDebit' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  form: Form<Controls> | null = null;

  get translatedMonth(): string {
    return moment()
      .startOf('month')
      .add(1, 'month')
      .format('MMMM');
  }

  get updatedAtHTML(): string {
    if (this.store.settlementForecast === null) {
      return '';
    }

    // eslint-disable-next-line max-len
    return `Die Prognose für ${this.translatedMonth} wurde zuletzt <strong>am ${formatDate(this.store.settlementForecast!.lastUpdatedAt)} um ${formatTime(this.store.settlementForecast!.lastUpdatedAt)} Uhr</strong> aktualisiert. Die Daten werden jede Nacht aktualisiert.`;
  }

  mounted(): void {
    this.form = this.buildForm();

    this.store.getSettlementForecast()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        selectedPerson: {
          label: 'Person',
          value: null,
          afterTransformationAndValidation: (value) => {
            this.store.updateSelectedPerson(value)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  calculateBankTransferCosts(settlement: Settlement): number {
    return this.getBookings(settlement)
      .filter((booking) => booking.paymentMethod === 'bank-transfer')
      .reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0);
  }

  calculateDirectDebitCosts(settlement: Settlement): number {
    return this.getBookings(settlement)
      .filter((booking) => booking.paymentMethod === 'direct-debit')
      .reduce((sum: number, b: Booking) => sum + b.grossSellingPrice, 0);
  }

  getBookings(settlement: Settlement): Booking[] {
    let bookings: Booking[] = [];
    bookings = bookings.concat(
      settlement.boxPlan ? settlement.boxPlan.bookings : [],
      settlement.feeding ? settlement.feeding.bookings : [],
      settlement.horseWalker ? settlement.horseWalker.bookings : [],
      settlement.bedding ? settlement.bedding.bookings : [],
      settlement.customBooking ? settlement.customBooking.bookings : [],
      settlement.creditNote ? settlement.creditNote.bookings : [],
      settlement.farmServiceSubscription ? settlement.farmServiceSubscription.bookings : [],
      settlement.farmService ? settlement.farmService.bookings : []
    );

    return bookings;
  }

}
