
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DeleteSettingsByUserGroupAsManagerCommand, LaborServiceSettingsByUserGroup } from '../types';
import { useLaborServicesManagementStore } from '../store';

@Component
export default class DeleteSettingsByUserGroupDialog extends Vue {

  readonly store = useLaborServicesManagementStore();

  @Prop({ type: Object, required: true })
  readonly laborServiceSettingsByUserGroup!: LaborServiceSettingsByUserGroup;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  deleteSettingsByUserGroupConfirmed(): void {
    const command: DeleteSettingsByUserGroupAsManagerCommand = {
      userGroupId: this.laborServiceSettingsByUserGroup.userGroupId,
    };

    this.store.deleteSettingsByUserGroup(command)
      .then(() => showSuccessMessage('Einstellungen wurden gelöscht'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
