
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifySelectItem, VuetifyValidatable } from '@/application/types';
import { DialogWidth } from '@/helpers/data';
import { useFacilityReservationsManagementStore } from '../store';
import { Facility, UpdateTimeOptionIntervalOfFacilityAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    timeOptionInterval: FormField<number>;
  };
}

// Creates an array of numbers from 5 to 60 with an interval of 5
const intervalOptions = Array.from({ length: 12 }, (v, i) => (i + 1) * 5);

@Component
export default class UpdateTimeOptionIntervalOfFacilityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  @Ref()
  readonly formElement?: VuetifyValidatable;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form | null = null;

  readonly timeOptionIntervalSelectItems: VuetifySelectItem<number>[] = intervalOptions
    .map((option) => ({ text: `${option} Minuten`, value: option }));

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (this.formElement) {
        this.formElement.resetValidation();
      }
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        timeOptionInterval: {
          value: this.facility.facilityReservationConfiguration.timeOptionInterval || 30,
          rules: [
            requiredRule(),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateTimeOptionIntervalOfFacilityAsManagerCommand = {
      facilityId: this.facility.facilityId,
      timeOptionInterval: this.form!.fields.timeOptionInterval.value,
    };
    this.store.updateTimeOptionIntervalOfFacility(command)
      .then(() => showSuccessMessage('Der Buchungstakt wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
