
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { isNativeApplication } from '@/helpers/detection-helpers';
import AssignHorsesToBoxPlan from './assign-horses-to-box-plan.vue';
import BoxPlanConfigurationComponent from './configure-box-plan.vue';
import BoxPlanOverviewComponent from './show-box-plan-overview.vue';
import BoxPlanSettlement from './box-plan-settlement.vue';
import DiscardChangesDialog from './discard-changes-dialog.vue';
import { useBoxPlanManagementStore } from '../store';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    AssignHorsesToBoxPlan,
    BoxPlanConfigurationComponent,
    BoxPlanOverviewComponent,
    BoxPlanSettlement,
    DiscardChangesDialog,
  },
})
export default class BoxPlanTabs extends Vue {

  readonly store = useBoxPlanManagementStore();

  currentTab = '';

  get isAssignDisabled(): boolean {
    return isNativeApplication();
  }

  get isManageDisabled(): boolean {
    return isNativeApplication();
  }

  mounted(): void {
    this.store.getBoxPlan()
      .catch((error) => showErrorResponse(error));
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.store.hasPendingConfigurationChanges) {
      // @ts-ignore
      this.$refs.discardChangesConfirmationDialog.show();
    }
    next();
  }

  tabChanged(tab: string) {
    if (['uebersicht', 'zuweisung'].includes(tab)
      && this.store.hasPendingConfigurationChanges
    ) {
      // @ts-ignore
      this.$refs.discardChangesConfirmationDialog.show();
    }
  }

  discardChanges() {
    this.store.getBoxPlan()
      .catch((error) => showErrorResponse(error));
  }

  backToConfiguration() {
    this.currentTab = 'tab-verwaltung';
  }

}
