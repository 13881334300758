
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, forbiddenCheckboxRule, forbiddenCheckboxWhenCheckboxIsCheckedRule, Form, FormControl, FormControls, getFormValues, minCountWhenCheckboxIsCheckedRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { MimeType, Moment, Persona, UserGroupId } from '@/types';
import { moment } from '@/helpers';
import { useNewsManagementStore } from '../store';
import { CreateNewsEntryAsManagerCommand } from '../types';

interface Controls extends FormControls {
  date: FormControl<Moment>;
  title: FormControl<string>;
  content: FormControl<string>;
  isImportant: FormControl<boolean>;
  isHidden: FormControl<boolean>;
  hasEmailNotificationTriggerOnRelease: FormControl<boolean>;
  isVisibilityRestrictedForPersona: FormControl<boolean>;
  visibleForPersona: FormControl<Persona>;
  isVisibilityRestrictedForGroups: FormControl<boolean>;
  idsOfGroupsForWhichTheEntryIsVisible: FormControl<UserGroupId[]>;
  image: FormControl<Blob>;
  attachment: FormControl<File>;
}

@Component
export default class CreateNewsEntryDialog extends Vue {

  readonly store = useNewsManagementStore();

  readonly size = DialogWidth.large;

  readonly MimeType = MimeType;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isEmailDispatchWarningVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.hasEmailNotificationTriggerOnRelease!;
  }

  get isVisibleForPersonaVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isVisibilityRestrictedForPersona!;
  }

  get isIdsOfGroupsForWhichTheEntryIsVisibleVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isVisibilityRestrictedForGroups!;
  }

  get isLoading(): boolean {
    return this.store.isGetUserGroupsProcessing
      || this.store.isGetConfigurationProcessing;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        Promise.all([
          this.store.getUserGroups(),
          this.store.getConfiguration(),
        ])
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => showErrorResponse(error));
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        date: {
          label: 'Datum zur Sortierung der Neuigkeiten',
          value: moment().startOf('day'),
          isRequired: true,
        },
        title: {
          label: 'Was ist die Neuigkeit?',
          value: null,
          isRequired: true,
        },
        content: {
          label: 'Beschreibung',
          value: null,
          isRequired: true,
        },
        isImportant: {
          label: 'Soll die Neuigkeit bei nächster Anmeldung eines Reiters angezeigt werden?',
          value: false,
          rules: [],
        },
        isHidden: {
          label: 'Soll die Neuigkeit versteckt werden?',
          value: false,
          validateFormControlsOnInput: [
            'hasEmailNotificationTriggerOnRelease',
          ],
        },
        hasEmailNotificationTriggerOnRelease: {
          label: 'Soll die Neuigkeit bei Veröffentlichung per E-Mail verschickt werden?',
          value: false,
          rules: [],
        },
        isVisibilityRestrictedForPersona: {
          label: 'Soll die Neuigkeit nur einer bestimmten Persona angezeigt werden?',
          value: false,
        },
        visibleForPersona: {
          label: 'Sichtbarkeit für Persona',
          value: Persona.WITH_HORSE,
        },
        isVisibilityRestrictedForGroups: {
          label: 'Soll die Neuigkeit nur bestimmten Gruppen angezeigt werden?',
          value: false,
          validateFormControlsOnInput: [
            'idsOfGroupsForWhichTheEntryIsVisible',
          ],
        },
        idsOfGroupsForWhichTheEntryIsVisible: {
          label: 'Gruppen',
          value: [],
          rules: [],
        },
        image: {
          label: 'Bild',
          value: null,
        },
        attachment: {
          label: 'Anhang',
          value: null,
        },
      },
    });

    form.controls.hasEmailNotificationTriggerOnRelease.rules!.push(
      forbiddenCheckboxWhenCheckboxIsCheckedRule(form.controls.isHidden, 'Die Neuigkeit kann nicht verschickt werden, wenn sie versteckt ist'),
    );

    form.controls.idsOfGroupsForWhichTheEntryIsVisible.rules!.push(
      minCountWhenCheckboxIsCheckedRule(
        1,
        form.controls.isVisibilityRestrictedForGroups,
        'Es muss mindestens eine Gruppe ausgewählt werden'
      ),
    );

    if (!this.store.configuration!.areNewsVisibleForRiders) {
      form.controls.isImportant.rules!.push(
        forbiddenCheckboxRule('Neuigkeiten sind aktuell für Reiter nicht sichtbar und können daher nicht bei der nächsten Anmeldung angezeigt werden'),
      );
      form.controls.hasEmailNotificationTriggerOnRelease.rules!.push(
        forbiddenCheckboxRule('Neuigkeiten sind aktuell für Reiter nicht sichtbar und können daher nicht verschickt werden'),
      );
    }

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateNewsEntryAsManagerCommand = {
      body: {
        date: formValues.date!,
        title: formValues.title!,
        content: formValues.content!,
        isImportant: formValues.isImportant!,
        isHidden: formValues.isHidden!,
        visibleForPersona: formValues.isVisibilityRestrictedForPersona
          ? formValues.visibleForPersona!
          : null,
        hasEmailNotificationTriggerOnRelease: formValues.hasEmailNotificationTriggerOnRelease!,
        isVisibilityRestrictedForGroups: formValues.isVisibilityRestrictedForGroups!,
        idsOfGroupsForWhichTheEntryIsVisible: formValues.isVisibilityRestrictedForGroups
          ? formValues.idsOfGroupsForWhichTheEntryIsVisible!
          : [],
      },
      files: {
        attachment: formValues.attachment,
        image: formValues.image,
      },
    };

    this.store.createEntry(command)
      .then(() => showSuccessMessage('Die Neuigkeit wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
