
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { NewsEntry, ReleaseNewsEntryAsManagerCommand } from '../types';
import { useNewsManagementStore } from '../store';
import { constructForm, Form, FormControl, FormControls, getFormValues, forbiddenCheckboxRule } from '@/components/form';
import { showErrorResponse } from '@/application/snackbar/service';

interface Controls extends FormControls {
  isImportant: FormControl<boolean>;
  hasEmailNotificationTriggerOnRelease: FormControl<boolean>;
}

@Component
export default class ReleaseNewsEntryDialog extends Vue {

  readonly store = useNewsManagementStore();

  @Prop({ type: Object, required: true })
  readonly newsEntry!: NewsEntry;

  readonly size = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.store.getConfiguration()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => showErrorResponse(error));
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        isImportant: {
          label: 'Soll die Neuigkeit den Benutzern bei der nächsten Anmeldung angezeigt werden?',
          value: this.newsEntry.isImportant,
          rules: [],
        },
        hasEmailNotificationTriggerOnRelease: {
          label: 'Soll die Neuigkeit den Benutzern bei Veröffentlichung per E-Mail geschickt werden?',
          value: this.newsEntry.hasEmailNotificationTriggerOnRelease,
          rules: [],
        },
      }
    });

    if (!this.store.configuration!.areNewsVisibleForRiders) {
      form.controls.isImportant.rules!.push(
        forbiddenCheckboxRule('Neuigkeiten sind aktuell für Reiter nicht sichtbar und können daher nicht angezeigt werden'),
      );
      form.controls.hasEmailNotificationTriggerOnRelease.rules!.push(
        forbiddenCheckboxRule('Neuigkeiten sind aktuell für Reiter nicht sichtbar und können daher nicht verschickt werden'),
      );
    }

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: ReleaseNewsEntryAsManagerCommand = {
      newsEntryId: this.newsEntry.newsEntryId,
      isImportant: formValues.isImportant!,
      hasEmailNotificationTriggerOnRelease: !this.newsEntry.emailNotificationHasBeenTriggeredAt
        && formValues.hasEmailNotificationTriggerOnRelease!,
    };

    this.store.releaseNewsEntry(command)
      .then(() => this.closeDialog())
      .catch(() => {});
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
