
import { Component, Vue } from 'vue-property-decorator';
import { showErrorMessage, showErrorResponse } from '@/application/snackbar/service';
import { Date, SurveyId } from '@/types';
import { formatDate } from '@/helpers';
import { usePinboardStore } from '../store';
import { Survey } from '../types';
import AnswerSurveyDialog from './answer-survey-dialog.vue';
import ShowSurveyAnswersDialog from './show-survey-answers-dialog.vue';

@Component({
  components: {
    AnswerSurveyDialog,
    ShowSurveyAnswersDialog,
  },
})
export default class SurveyList extends Vue {

  readonly store = usePinboardStore();

  readonly updateMenuList: Record<SurveyId, boolean> = {};

  mounted(): void {
    this.store.getSurveys()
      .then(() => {
        const surveyId = this.$route.params.surveyId;
        if (surveyId) {
          const currentSurvey = this.store.surveys.find((survey) => survey.surveyId === surveyId);
          if (!currentSurvey) {
            showErrorMessage('Die Umfrage existiert nicht mehr.');
            return;
          }

          if (!currentSurvey.isSurveyShownOnAppStart) {
            this.$nextTick(() => {
              this.openAnswerSurveyDialog(surveyId);
            })
          }
        }
      })
      .catch((error) => showErrorResponse(error));
  }

  surveyClicked(survey: Survey): void {
    if (survey.answers.length > 0) {
      this.openShowSurveyAnswersDialog(survey.surveyId);
    } else {
      this.openAnswerSurveyDialog(survey.surveyId);
    }
  }

  openAnswerSurveyDialog(surveyId: SurveyId): void {
    (this.$refs[`answerSurveyDialog-${surveyId}`] as AnswerSurveyDialog)!.show();
  }

  openShowSurveyAnswersDialog(surveyId: SurveyId): void {
    (this.$refs[`showSurveyAnswersDialog-${surveyId}`] as ShowSurveyAnswersDialog)!.show();
  }

  isSurveyClickable(survey: Survey): boolean {
    return survey.lastDateToAnswerSurvey.isAfterOrEqualTo(Date.today())
      || survey.answers.length > 0;
  }

  title(survey: Survey): string {
    return survey.date
      ? `${survey.title} am ${formatDate(survey.date)}`
      : survey.title;
  }

  description(survey: Survey): string {
    return survey.answers.length > 0
      ? 'Beantwortet'
      : Date.today().isAfter(survey.lastDateToAnswerSurvey)
        ? 'Nicht beantwortet'
        : 'Noch nicht beantwortet';
  }

  note(survey: Survey): string {
    return `Letzte Antwortmöglichkeit am ${formatDate(survey.lastDateToAnswerSurvey)}.`;
  }

  isUpdateVisible(survey: Survey): boolean {
    return survey.answers.length > 0
      && Date.today().isBeforeOrEqualTo(survey.lastDateToAnswerSurvey);
  }

  surveyAnswered(): void {
    this.store.getSurveys()
      .catch((error) => showErrorResponse(error));
  }

}
