
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FarmManagerPermission } from '@/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { DataListItem } from '@/components/design-system';
import { formatBoolean } from '@/helpers';
import { useNewsManagementStore } from '../store';

@Component
export default class NewsConfiguration extends Vue {

  readonly store = useNewsManagementStore();

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'areNewsVisibleForRiders',
        label: 'Neuigkeiten werden den Reitern angezeigt',
        value: formatBoolean(this.store.configuration!.areNewsVisibleForRiders),
      }
    ];
  }

  get isMarkNewsAsVisibleForRidersVisible(): boolean {
    return !this.store.configuration!.areNewsVisibleForRiders
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  get isMarkNewsAsHiddenForRidersVisible(): boolean {
    return this.store.configuration!.areNewsVisibleForRiders
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  mounted(): void {
    this.store.getConfiguration()
      .catch((error) => showErrorResponse(error));
  }

  async markNewsAsVisibleForRidersAction(): Promise<void> {
    return this.store.markNewsAsVisibleForRiders()
      .then(() => showSuccessMessage('Die Neuigkeiten wurden eingeblendet.'))
      .catch((error) => showErrorResponse(error));
  }

  async markNewsAsHiddenForRidersAction(): Promise<void> {
    return this.store.markNewsAsHiddenForRiders()
      .then(() => showSuccessMessage(('Die Neuigkeiten wurden ausgeblendet.')))
      .catch((error) => showErrorResponse(error));
  }

}
