
import { Component, Vue } from 'vue-property-decorator';
import { ActionStatus } from '@/application/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { Color, Strain } from '@/types';
import { useRidingLessonManagementStore } from '../store';
import { midnightSevenDaysAgo } from '../helper';
import { Horse, HorseWithUtilization, RidingLesson, UtilizationRange } from '../types';

interface Controls extends FormControls {
  search: FormControl<string>;
  isThreeDayUtilizationVisible: FormControl<boolean>;
}

@Component
export default class HorseSelection extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly colorMap: Record<Strain, Color> = {
    MINIMAL_STRAIN: Color['green-5'],
    MODERATE_STRAIN: Color['yellow-5'],
    EXTENSIVE_STRAIN: Color['red-5'],
  };

  form: Form<Controls> | null = null;

  get noDataText(): string {
    return this.store.getRidingLessonsStatus === ActionStatus.Failed
      ? 'Die Unterrichtsstunden konnten nicht geladen werden.'
      : 'Du hast noch keine Unterrichtsstunde für diesen Tag erstellt.';
  }

  get openRidingLesson(): RidingLesson|null {
    return this.store.ridingLessons.find((ridingLesson) => ridingLesson.ridingLessonId === this.store.idOfOpenRidingLesson) ?? null;
  }

  get horsesAvailableForHorseActivityOfOpenRidingLesson(): HorseWithUtilization[] {
    if (!this.openRidingLesson) {
      return [];
    }

    const idsOfAvailableHorses = (this.store.configurationForRidingLessons?.idsOfHorsesAvailableForAssignmentForRidingLessonTypeMap ?? {})[
      this.openRidingLesson.ridingLessonType.ridingLessonTypeId
    ] ?? [];

    return (this.store.horsesForAssignment ?? [])
      .filter((horse) => idsOfAvailableHorses.includes(horse.horseId));
  }

  get horsesFilteredBySearchAndActivityOfOpenRidingLesson(): HorseWithUtilization[] {
    const horsesForAssignment: HorseWithUtilization[] = this.store.configurationForRidingLessons?.areHorsesFilteredByRidingLessonType
      ? this.horsesAvailableForHorseActivityOfOpenRidingLesson
      : this.store.horsesForAssignment;

    return !!this.form
      && this.form.controls.search.value !== null
      ? horsesForAssignment.filter((horse) => horse.name
        .toLowerCase()
        .includes(this.form!.controls.search.value!.toLowerCase()))
      : horsesForAssignment;
  }

  get isNoHorsesForAssignmentConfiguredVisible(): boolean {
    return this.store.configurationForRidingLessons?.idsOfHorsesAvailableForAssignment.length === 0;
  }

  get isNoHorsesForAssignmentForActivityVisible(): boolean {
    return (this.store.configurationForRidingLessons?.areHorsesFilteredByRidingLessonType ?? false)
      && !this.isNoHorsesForAssignmentConfiguredVisible
      && this.horsesAvailableForHorseActivityOfOpenRidingLesson.length === 0;
  }

  get isNoHorsesFoundVisible(): boolean {
    return !!this.form
      && this.form.controls.search.value !== null
      && this.horsesFilteredBySearchAndActivityOfOpenRidingLesson.length === 0;
  }

  get isSelectionDisabled(): boolean {
    return !this.openRidingLesson
      || this.openRidingLesson.from.isBefore(midnightSevenDaysAgo());
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche nach Name',
          value: null,
        },
        isThreeDayUtilizationVisible: {
          label: 'Auslastung für 3 Tage',
          value: this.store.currentUtilizationRange === UtilizationRange.THREE_DAYS,
        },
      },
    });
  }

  horseSelected(horse: Horse): void {
    if (this.store.idOfSelectedHorse === horse.horseId) {
      this.store.revertHorseSelection()
        .catch((error) => showErrorResponse(error));
    } else {
      this.store.selectHorse(horse.horseId)
        .catch((error) => showErrorResponse(error));
    }
  }

  utilizationRangeChanged(): void {
    const formValues = getFormValues(this.form!);

    const utilizationRange = formValues.isThreeDayUtilizationVisible
      ? UtilizationRange.THREE_DAYS
      : UtilizationRange.ONE_DAY;

    this.store.updateCurrentUtilizationRange(utilizationRange)
      .catch((error) => showErrorResponse(error));
  }

}
