
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse } from '@/application/snackbar/service';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { formatDate } from '@/helpers';
import { SurveyAnswerPer } from '@/types';
import { Horse } from '@/private/rider/my-stable/types';
import { usePinboardStore } from '../store';
import { AnswerSurveyCommand, Survey, SurveyAnswerDTO } from '../types';

interface Controls extends FormControls {
  answersForHorse: FormControl<SurveyAnswerDTO[]>;
  answersForHorseMultiple: FormControl<SurveyAnswerDTO[]>;
  answersForUser: FormControl<SurveyAnswerDTO>;
  answersForUserMultiple: FormControl<SurveyAnswerDTO>;
}

@Component
export default class AnswerSurveyDialog extends Vue {

  readonly store = usePinboardStore();
  readonly myStableStore = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly survey!: Survey;

  readonly size = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get title(): string {
    return this.survey.date
      ? `${this.survey.title} am ${formatDate(this.survey.date)}`
      : this.survey.title;
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isPersistent(): boolean {
    return this.store.isAnswerSurveyProcessing
      || this.survey.isAnswerMandatory === true;
  }

  get isCancelVisible(): boolean {
    return this.survey.isAnswerMandatory !== true;
  }

  get isSingleAnswersForHorseVisible(): boolean {
    return this.survey.surveyAnswerPer === SurveyAnswerPer.HORSE
      && !this.survey.isUserAbleToSelectMultipleOptions;
  }

  get isSingleAnswersForUserVisible(): boolean {
    return this.survey.surveyAnswerPer === SurveyAnswerPer.USER
      && !this.survey.isUserAbleToSelectMultipleOptions;
  }

  get isMultipleAnswersForHorseVisible(): boolean {
    return this.survey.surveyAnswerPer === SurveyAnswerPer.HORSE
      && this.survey.isUserAbleToSelectMultipleOptions;
  }

  get isMultipleAnswersForUserVisible(): boolean {
    return this.survey.surveyAnswerPer === SurveyAnswerPer.USER
      && this.survey.isUserAbleToSelectMultipleOptions;
  }

  get availableHorses(): Horse[] {
    return this.survey.isAnswerAllowedAsRidingShare === true
      ? this.myStableStore.ownAndSharedHorses
      : this.myStableStore.ownHorses;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        answersForHorse: {
          label: 'Optionen',
          value: this.survey.answers.length > 0
            ? this.survey.answers
            : null,
        },
        answersForHorseMultiple: {
          label: 'Optionen',
          value: this.survey.answers.length > 0
            ? this.survey.answers
            : null,
        },
        answersForUser: {
          label: 'Optionen',
          value: this.survey.answers.length > 0
            ? this.survey.answers[0]
            : null,
        },
        answersForUserMultiple: {
          label: 'Optionen',
          value: this.survey.answers.length > 0
            ? this.survey.answers[0]
            : null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: AnswerSurveyCommand = {
      surveyId: this.survey.surveyId,
      answers: this.survey.surveyAnswerPer === SurveyAnswerPer.USER
        ? this.survey.isUserAbleToSelectMultipleOptions
          ? [formValues.answersForUserMultiple!]
          : [formValues.answersForUser!]
        : this.survey.isUserAbleToSelectMultipleOptions
          ? formValues.answersForHorseMultiple!
          : formValues.answersForHorse!,
    };

    this.store.answerSurvey(command)
      .then(() => this.$emit('survey-answered'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  show(): void {
    this.isDialogVisible = true;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
