
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { VaccinationStatus } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useMedicalRecordsManagementStore } from '../store';
import { HorseWithStatuses, Illness } from '../types';
import CreateVaccinationDialog from './create-vaccination-dialog.vue';

interface Controls extends FormControls {
  search: FormControl<string>;
}

@Component({
  components: { CreateVaccinationDialog }
})
export default class VaccinationStatuses extends Vue {

  readonly store = useMedicalRecordsManagementStore();

  readonly iconMap: Record<VaccinationStatus, string[]> = {
    VACCINATED: ['fas', 'check'],
    EXPIRES_SOON: ['fas', 'exclamation-triangle'],
    EXPIRED: ['far', 'times'],
    NOT_VACCINATED: ['far', 'times'],
  };

  form: Form<Controls> | null = null;

  get horses(): HorseWithStatuses[] {
    return this.store.vaccinationStatuses?.horses ?? [];
  }

  get illnesses(): Illness[] {
    return this.store.vaccinationStatuses?.illnesses ?? [];
  }

  get tableHeaders(): DataTableHeader[] {
    const headers = [
      { text: 'Pferd', value: 'horse', width: '20%' },
    ] as DataTableHeader[];

    if (this.store.vaccinationStatuses === null) {
      return headers;
    }

    this.illnesses.forEach((illness) => {
      headers.push(({
        text: illness.name,
        value: illness.illnessId,
        width: `${80 / (this.store.vaccinationStatuses!.illnesses.length)}%`,
      }));
    });

    return headers;
  }

  get searchValue(): string | null {
    if (!this.form) {
      return null;
    }

    return getFormValues(this.form).search;
  }

  mounted(): void {
    this.form = this.buildForm();

    this.store.getVaccinationStatuses()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
        },
      },
    });
  }

  filterHorses(value: any, search: string | null, horse: HorseWithStatuses) {
    if (search === null) {
      return true;
    }

    const searchLowerCase = search.toLocaleLowerCase();

    if (horse.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    return false;
  }

  iconClass(status: VaccinationStatus): string {
    return status.toLowerCase();
  }

}
