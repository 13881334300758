
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FarmManagerPermission } from '@/types';
import { DataListItem } from '@/components/design-system';
import { formatBoolean } from '@/helpers';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { useEventManagementStore } from '../store';

@Component
export default class EventConfiguration extends Vue {

  readonly store = useEventManagementStore();

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'areEventsVisibleForRiders',
        label: 'Veranstaltungen werden den Reitern angezeigt',
        value: formatBoolean(this.store.configuration!.areEventsVisibleForRiders),
      }
    ];
  }

  get isMarkEventsAsVisibleForRidersVisible(): boolean {
    return !this.store.configuration!.areEventsVisibleForRiders
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  get isMarkEventsAsHiddenForRidersVisible(): boolean {
    return this.store.configuration!.areEventsVisibleForRiders
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_WRITE);
  }

  mounted(): void {
    this.store.getConfiguration()
      .catch((error) => showErrorResponse(error));
  }

  async markEventsAsVisibleForRidersAction(): Promise<void> {
    return this.store.markEventsAsVisibleForRiders()
      .then(() => showSuccessMessage('Die Veranstaltungen wurden eingeblendet.'))
      .catch((error) => showErrorResponse(error));
  }

  async markEventsAsHiddenForRidersAction(): Promise<void> {
    return this.store.markEventsAsHiddenForRiders()
      .then(() => showSuccessMessage(('Die Veranstaltungen wurden ausgeblendet.')))
      .catch((error) => showErrorResponse(error));
  }

}
