
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useDashboardStore } from '../store';

@Component
export default class PenStatus extends Vue {

  readonly store = useDashboardStore();

  mounted(): void {
    this.store.getCurrentPenStatusEntry()
      .catch((error) => showErrorResponse(error));
  }

}
