
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { HorseDrugEntry } from '../types';

@Component
export default class HorseDrugHistoryDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Array, required: true })
  readonly history!: HorseDrugEntry[];

  isDialogVisible = false;

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
