
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, minCountRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { Date, Options, Persona, SurveyAnswerPer, SurveyId } from '@/types';
import { useSurveyManagementStore } from '../store';
import { CreateSurveyAsManagerCommand, GetSurveyForDuplicationAsManagerQuery, SurveyForDuplication } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  date: FormControl<Date>;
  lastDateToAnswerSurvey: FormControl<Date>;
  description: FormControl<string>;
  options: FormControl<Options>;
  surveyAnswerPer: FormControl<SurveyAnswerPer>;
  isAnswerAllowedAsRidingShare: FormControl<boolean>;
  isSurveyShownOnAppStart: FormControl<boolean>;
  isAnswerMandatory: FormControl<boolean>;
  isUserAbleToSelectMultipleOptions: FormControl<boolean>;
  isVisibilityRestrictedForPersona: FormControl<boolean>;
  visibleForPersona: FormControl<Persona>;
}

@Component
export default class DuplicateSurveyDialog extends Vue {

  readonly store = useSurveyManagementStore();

  @Prop({ type: String, required: true })
  readonly surveyId!: SurveyId;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isIsAnswerMandatoryVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isSurveyShownOnAppStart!;
  }

  get isVisibleForPersonaVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isVisibilityRestrictedForPersona!;
  }

  get isIsAnswerAllowedAsRidingShareVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.surveyAnswerPer === SurveyAnswerPer.HORSE;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        const query: GetSurveyForDuplicationAsManagerQuery = {
          surveyId: this.surveyId,
        };

        this.store.getSurveyForDuplication(query)
          .then(() => {
            this.form = this.buildForm(this.store.surveyForDuplication!);
          })
      } else {
        this.form = null;
      }
    });
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

  isDateAllowed(date: Date): boolean {
    return date.isAfter(Date.today());
  }

  isLastDateToAnswerSurveyAllowed(date: Date): boolean {
    return date.isAfterOrEqualTo(Date.today());
  }

  buildForm(survey: SurveyForDuplication): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Titel',
          value: survey.title,
          isRequired: true,
          rules: [
            maxLengthRule(50),
          ],
        },
        date: {
          label: 'Datum',
          value: null,
          validateFormControlsOnInput: [
            'lastDateToAnswerSurvey',
          ],
        },
        lastDateToAnswerSurvey: {
          label: 'Deadline für Beantwortung',
          value: Date.today().add(7),
          isRequired: true,
          rules: [],
        },
        description: {
          label: 'Beschreibung',
          value: survey.description,
          rules: [
            maxLengthRule(1_000),
          ],
        },
        options: {
          label: 'Optionen',
          value: survey.options,
          isRequired: true,
          rules: [
            minCountRule(2, 'Es muss mindestens 2 Optionen geben'),
          ],
        },
        surveyAnswerPer: {
          label: 'Eine Antwort für',
          value: survey.surveyAnswerPer,
        },
        isAnswerAllowedAsRidingShare: {
          label: 'Reitbeteiligungen können für die mit ihnen geteilten Pferde antworten',
          value: survey.isAnswerAllowedAsRidingShare ?? false,
        },
        isSurveyShownOnAppStart: {
          label: 'Die Umfrage wird beim nächsten Start der App angezeigt',
          value: survey.isSurveyShownOnAppStart,
        },
        isAnswerMandatory: {
          label: 'Der Benutzer muss die Umfrage ausfüllen',
          value: survey.isAnswerMandatory ?? false,
        },
        isUserAbleToSelectMultipleOptions: {
          label: 'Der Benutzer kann mehrere Optionen auswählen',
          value: survey.isUserAbleToSelectMultipleOptions,
        },
        isVisibilityRestrictedForPersona: {
          label: 'Die Umfrage soll nur einer bestimmten Persona angezeigt werden',
          value: survey.visibleForPersona !== null,
        },
        visibleForPersona: {
          label: 'Sichtbarkeit für Persona',
          value: survey.visibleForPersona ?? Persona.WITH_HORSE,
        },
      },
    });

    form.controls.lastDateToAnswerSurvey.rules!.push((date) => {
      if (!date
        || !form.controls.date.value
      ) {
        return true;
      }

      if (date.isBeforeOrEqualTo(form.controls.date.value)) {
        return true;
      }

      return 'Die Deadline darf nicht vor dem Datum liegen.';
    });

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateSurveyAsManagerCommand = {
      title: formValues.title!,
      date: formValues.date,
      lastDateToAnswerSurvey: formValues.lastDateToAnswerSurvey!,
      description: formValues.description,
      options: formValues.options!,
      surveyAnswerPer: formValues.surveyAnswerPer!,
      isAnswerAllowedAsRidingShare: formValues.surveyAnswerPer === SurveyAnswerPer.HORSE
        ? formValues.isAnswerAllowedAsRidingShare!
        : null,
      isSurveyShownOnAppStart: formValues.isSurveyShownOnAppStart!,
      isAnswerMandatory: formValues.isSurveyShownOnAppStart!
        ? formValues.isAnswerMandatory!
        : null,
      isUserAbleToSelectMultipleOptions: formValues.isUserAbleToSelectMultipleOptions!,
      visibleForPersona: formValues.isVisibilityRestrictedForPersona
        ? formValues.visibleForPersona!
        : null,
    };

    this.store.createSurvey(command)
      .then(() => showSuccessMessage('Umfrage wurde erstellt und die Reiter informiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

}
