
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { RiderTaskId, RiderTaskStatus } from '@/types';
import { formatDate } from '@/helpers';
import CreateTaskDialog from './create-task-dialog.vue';
import TaskDetailsDialog from './task-details-dialog.vue';
import WithdrawTaskDialog from './withdraw-task-dialog.vue';
import { useRiderTasksStore } from '../store';
import { Task } from '../types';

@Component({
  components: {
    CreateTaskDialog,
    TaskDetailsDialog,
    WithdrawTaskDialog,
  },
})
export default class TaskList extends Vue {

  readonly store = useRiderTasksStore();

  readonly taskMenuList: Record<RiderTaskId, boolean> = {};

  get isInitialLoadingVisible(): boolean {
    return this.store.totalCount === null
      && this.store.isGetTasksForUserProcessing;
  }

  get isLoadMoreButtonVisible(): boolean {
    return this.store.totalCount !== null;
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.store.totalCount === null
      || this.store.tasks.length >= this.store.totalCount;
  }

  mounted(): void {
    this.store.getTasksForUser()
      .catch((error) => showErrorResponse(error));
  }

  taskIcon(status: RiderTaskStatus): string[] {
    if (status === RiderTaskStatus.NEW) {
      return ['far', 'sparkles'];
    }
    if (status === RiderTaskStatus.ASSIGNED) {
      return ['far', 'user'];
    }
    if (status === RiderTaskStatus.COMPLETED) {
      return ['far', 'check'];
    }
    if (status === RiderTaskStatus.REJECTED) {
      return ['far', 'times'];
    }
    if (status === RiderTaskStatus.WITHDRAWN) {
      return ['far', 'ban'];
    }

    throw new Error('Invalid task status');
  }

  isDetailsIconVisible(task: Task): boolean {
    return !!task.details;
  }

  isCommentIconVisible(task: Task): boolean {
    return task.taskHistory.some((taskHistoryEntry) => !!taskHistoryEntry.comment);
  }

  lastUpdated(task: Task): string {
    const createdAt = task.taskHistory[task.taskHistory.length - 1].createdAt;
    if (task.status === RiderTaskStatus.NEW) {
      return `Erstellt am ${formatDate(createdAt) }`;
    }
    if (task.status === RiderTaskStatus.ASSIGNED) {
      return `Zugewiesen am ${formatDate(createdAt) }`;
    }
    if (task.status === RiderTaskStatus.REJECTED) {
      return `Abgelehnt am ${formatDate(createdAt) }`;
    }
    if (task.status === RiderTaskStatus.COMPLETED) {
      return `Abgeschlossen am ${formatDate(createdAt)}`;
    }
    if (task.status === RiderTaskStatus.WITHDRAWN) {
      return `Zurückgezogen am ${formatDate(createdAt) }`;
    }

    throw new Error('Invalid task status');
  }

  isWithdrawTaskVisible(task: Task): boolean {
    return task.status === RiderTaskStatus.NEW
      || task.status === RiderTaskStatus.ASSIGNED;
  }

  onLoadMoreClicked(): void {
    this.store.increaseLimit()
      .catch((error) => showErrorResponse(error));
  }

  taskClicked(task: Task): void {
    (this.$refs[`taskDetailsDialog-${task.riderTaskId}`] as TaskDetailsDialog).show();
  }

}
