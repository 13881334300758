
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, maxLengthRule, maxNumberRule, minNumberRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { ReservationDisplayNameType, Time } from '@/types';
import { useFacilityReservationsManagementStore } from '../store';
import { Facility, SetUpFacilityReservationsForFacilityAsManagerCommand } from '../types';

interface Controls extends FormControls {
  timeOptionInterval: FormControl<number>;
  bookingInAdvance: FormControl<number>;
  timeToGrantAccessForNewDayInAdvance: FormControl<Time>;
  maxIntervalsForHorseAndDay: FormControl<number>;
  minDistanceToOwnBookingsWithDifferentHorsesIntervals: FormControl<number>;
  areParallelReservationsInOtherFacilitiesAllowed: FormControl<boolean>;
  reservationDisplayNameType: FormControl<ReservationDisplayNameType>;
  bookingNotice: FormControl<string>;
}

@Component
export default class SetUpFacilityReservationsForFacilityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  readonly dialogMaxWidth = DialogWidth.large;

  activePanel = 0;
  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get selectedInterval(): number {
    if (!this.form) {
      return 5;
    }

    const formValues = getFormValues(this.form);

    return formValues.timeOptionInterval ?? 5;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.activePanel = 0;
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        timeOptionInterval: {
          label: 'Taktung einer Reservierung',
          value: 30,
          isRequired: true,
        },
        bookingInAdvance: {
          label: 'Max. Tage zur Reservierung im Voraus',
          value: 7,
          isRequired: true,
          rules: [
            minNumberRule(0),
            maxDecimalsNumberRule(0),
            maxNumberRule(365),
          ],
        },
        timeToGrantAccessForNewDayInAdvance: {
          label: 'Uhrzeit für Freischaltung des neuen Reservierungstakts',
          value: new Time(18, 0, 0),
          isRequired: true,
        },
        maxIntervalsForHorseAndDay: {
          label: 'Maximale tägliche Zeit pro Pferd',
          value: 1,
          isRequired: true,
        },
        minDistanceToOwnBookingsWithDifferentHorsesIntervals: {
          label: 'Mindestabstand',
          value: 1,
          isRequired: true,
        },
        areParallelReservationsInOtherFacilitiesAllowed: {
          label: 'Kann eine andere Anlage vom selben Reiter parallel genutzt werden?',
          value: false,
          rules: [],
        },
        reservationDisplayNameType: {
          label: 'Name auf einer Reservierung',
          value: ReservationDisplayNameType.HORSE,
          isRequired: true,
        },
        bookingNotice: {
          label: 'Hinweis',
          value: null,
          rules: [
            maxLengthRule(255, 'Der Hinweis darf maximal 255 Zeichen lang sein'),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: SetUpFacilityReservationsForFacilityAsManagerCommand = {
      facilityId: this.facility.facilityId,
      timeOptionInterval: formValues.timeOptionInterval!,
      bookingInAdvance: formValues.bookingInAdvance!,
      timeToGrantAccessForNewDayInAdvance: formValues.timeToGrantAccessForNewDayInAdvance!,
      maxIntervalsForHorseAndDay: formValues.maxIntervalsForHorseAndDay!,
      minDistanceToOwnBookingsWithDifferentHorsesIntervals: formValues.minDistanceToOwnBookingsWithDifferentHorsesIntervals!,
      areParallelReservationsInOtherFacilitiesAllowed: formValues.areParallelReservationsInOtherFacilitiesAllowed!,
      reservationDisplayNameType: formValues.reservationDisplayNameType!,
      bookingNotice: formValues.bookingNotice,
    };

    this.store.setUpFacilityReservationsForFacility(command)
      .then(() => showSuccessMessage('Die Anlage wurde konfiguriert.'))
      .then(() => this.$router.push({
        name: 'facility-reservations-management-facility-details-activities',
        params: { id: this.facility.facilityId },
      }))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
