
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { moment } from '@/helpers';
import { useFacilityReservationsManagementStore } from '../store';
import { DeleteFacilityReservationAsManagerCommand, FacilityReservation } from '../types';

@Component
export default class DeleteFacilityReservationDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly facilityReservation!: FacilityReservation;

  isDialogVisible = false;

  cancelHorseActivityBookingConfirmed(): void {
    const command: DeleteFacilityReservationAsManagerCommand = {
      facilityReservationId: this.facilityReservation.facilityReservationId,
    };

    this.store.cancelFacilityReservation(command)
      .then(() => showSuccessMessage('Die Buchung wurde storniert'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

  isDeletable(booking: FacilityReservation): boolean {
    return booking.from.isSameOrAfter(moment());
  }

}
