
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { ChartData, ChartDataset, ChartOptions, LegendItem } from 'chart.js';
import { Bar } from 'vue-chartjs/legacy';
import { colors, counterColors } from '@/helpers/data';
import { Color } from '@/types';
import { HorseStationUtilization } from '../types';
import { useFeedProtocolManagementStore } from '../store';

@Component({
  components: {
    bar: Bar,
  },
})
export default class HorseStationUtilizationChart extends Vue {

  readonly store = useFeedProtocolManagementStore();

  @Prop({ type: Object, required: true })
  readonly horse!: HorseStationUtilization;

  readonly chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        stacked: true,
        max: this.max,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        display: false,
        grid: {
          display: false,
        },
      },
    },
    indexAxis: 'y',
    plugins: {
      legend: {
        display: true,
        align: 'start',
        labels: {
          padding: 0,
          font: {
            family: 'Roboto',
          },
          generateLabels: (): LegendItem[] => this.relevantStationIds.length > 0
            ? this.relevantStationIds
              .map((stationId) => ({
                // Extra spaces to simulate padding on the right, which isn't configurable
                text: `${this.nameForStationId(stationId)}   `,
                fillStyle: colors[this.colorForStationId(stationId)],
                strokeStyle: colors[this.colorForStationId(stationId)],
              }))
            : [{
              text: 'Keine Station besucht',
              fillStyle: colors['grey-8'],
              strokeStyle: colors['grey-8'],
            }],
        },
      },
      title: {
        display: true,
        text: this.horse.horseName,
        align: 'start',
        padding: {
          top: 0,
          bottom: 6,
        },
        font: {
          size: 14,
          weight: 'normal',
          family: 'Roboto',
        },
      },
    },
  };

  chartData: ChartData<'bar', number[]>|null = null;

  get max(): number {
    const max = Object.values(this.horse.stationUtilizationMap)
      .reduce((previous, current) => previous + current);

    // Max value has to be at least 1 for the bar to be rendered for empty entries
    return max > 0
      ? max
      : 1;
  }

  get relevantStationIds(): string[] {
    return Object.keys(this.horse.stationUtilizationMap)
      .filter((stationId) => this.horse.stationUtilizationMap[stationId] > 0);
  }

  mounted(): void {
    watch(
      () => this.horse,
      () => this.updateChartData(),
      { deep: true, immediate: true }
    );
  }

  colorForStationId(stationId: string): Color {
    return this.store.stationUtilization!.stations
      .find((station) => station.feedProtocolStationId === stationId)!
      .color;
  }

  nameForStationId(stationId: string): string {
    return this.store.stationUtilization!.stations
      .find((station) => station.feedProtocolStationId === stationId)!
      .name;
  }

  updateChartData(): void {
    const datasets: ChartDataset<'bar', number[]>[] = this.relevantStationIds
      .map((stationId) => ({
        data: [this.horse.stationUtilizationMap[stationId]],
        categoryPercentage: 1,
        barPercentage: 1,
        datalabels: {
          color: counterColors[this.colorForStationId(stationId)],
          font: {
            size: 14,
            family: 'Roboto',
          },
          clamp: true,
          offset: 25,
        },
        backgroundColor: [colors[this.colorForStationId(stationId)]],
      }));

    // Value has to be at least 1 for the bar to be rendered for empty entries
    if (datasets.length === 0) {
      datasets.push({
        data: [1],
        categoryPercentage: 1,
        barPercentage: 1,
        datalabels: {
          color: counterColors['grey-8'],
          font: {
            size: 14,
            family: 'Roboto',
          },
          formatter() {
            return '0';
          },
          clamp: true,
          offset: 25,
        },
        backgroundColor: colors['grey-8'],
      });
    }

    this.chartData = {
      labels: [''],
      datasets,
    };
  }
}
