
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition } from '@/application/types';
import { DialogWidth } from '@/helpers/data';
import { useFreeJumpingFreeRunningManagementStore } from '../store';
import { UpdateConfigurationAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    registrationInformation: FormField<string|null>;
  }
}

@Component
export default class UpdateFreeJumpingFreeRunningConfigurationDialog extends Vue {

  readonly store = useFreeJumpingFreeRunningManagementStore();

  readonly size = DialogWidth.large;

  isDialogVisible = false;

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        registrationInformation: {
          value: this.store.configuration!.registrationInformation,
          rules: [],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateConfigurationAsManagerCommand = {
      registrationInformation: this.form!.fields.registrationInformation.value,
    };

    this.store.updateConfiguration(command)
      .then(() => showSuccessMessage('Konfiguration wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
