
import {Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { ChartData, ChartOptions } from 'chart.js';
import { Pie } from 'vue-chartjs/legacy';
import { colors, counterColors } from '@/helpers/data';
import { StationUtilization } from '../types';

@Component({
  components: {
    pie: Pie,
  },
})
export default class StationUtilizationChart extends Vue {

  @Prop({ type: Object, required: true })
  readonly stationUtilization!: StationUtilization;

  readonly chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'left',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 14,
          },
        },
      },
    },
  };

  chartData: ChartData<'pie', number[]>|null = null;

  mounted(): void {
    watch(() => this.stationUtilization,
      (stationUtilization) => this.updateChartData(stationUtilization),
      { immediate: true, deep: true }
    );
  }

  updateChartData(stationUtilization: StationUtilization): void {
    const labels = Object.keys(stationUtilization.stationUtilizationMap).map(
      (stationId) => stationUtilization.stations
        .find((station) => station.feedProtocolStationId === stationId)!
        .name
    );
    const data = Object.values(stationUtilization.stationUtilizationMap);

    const stationColors = Object.keys(stationUtilization.stationUtilizationMap)
      .map((stationId) => stationUtilization.stations
        .find((station) => station.feedProtocolStationId === stationId)!
        .color);

    const fontColors = stationColors.map((color) => counterColors[color]);
    const backgroundColors = stationColors.map((color) => colors[color]);

    this.chartData = {
      labels,
      datasets: [
        {
          data,
          datalabels: {
            color: fontColors,
            font: {
              size: 14,
            },
            anchor: 'end',
            clamp: true,
            align: 'start',
            offset: 25,
          },
          backgroundColor: backgroundColors,
        },
      ],
    };
  }
}
