
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FarmManagerPermission } from '@/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { feedUnitTranslations } from '@/helpers/translations';
import CreateFeedTypeDialog from './create-feed-type-dialog.vue';
import UpdateNameOfFeedTypeDialog from './update-name-of-feed-type-dialog.vue';
import UpdateOfferOfFeedTypeDialog from './update-offer-of-feed-type-dialog.vue';
import { DisableFeedTypeAsManagerCommand, FeedType, HideFeedTypeOnBoxSignAsManagerCommand, ShowFeedTypeOnBoxSignAsManagerCommand } from '../types';
import { useFeedingManagementStore } from '../store';

@Component({
  components: {
    CreateFeedTypeDialog,
    UpdateNameOfFeedTypeDialog,
    UpdateOfferOfFeedTypeDialog,
  },
})
export default class FeedTypes extends Vue {

  readonly store = useFeedingManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name' },
    { text: 'Angeboten (Morgens, Mittags, Abends)', value: 'offered' },
    { text: 'Menge durch Reiter anpassbar', value: 'isUserAbleToDefineAmount' },
    { text: 'Auf Boxenschild angezeigt', value: 'isVisibleOnBoxSign' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  readonly feedUnitTranslations = feedUnitTranslations;

  readonly updateMenuList: Record<string, boolean> = {};

  get isCreateFeedTypeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FEEDING_WRITE);
  }

  get isUpdateFeedTypeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FEEDING_WRITE);
  }

  mounted(): void {
    this.store.getFeedTypes()
      .catch((error) => showErrorResponse(error));
  }

  async disableFeedTypeAction(feedType: FeedType): Promise<void> {
    const command: DisableFeedTypeAsManagerCommand = {
      feedTypeId: feedType.feedTypeId,
    };

    return this.store.disableFeedType(command)
      .then(() => showSuccessMessage('Der Futtertyp wurde deaktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

  isDeleteFeedTypeVisible(feedType: FeedType): boolean {
    return feedType.isEnabled
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEEDING_WRITE);
  }

  hideMenu(feedType: FeedType): void {
    this.updateMenuList[feedType.feedTypeId] = false;
  }

  async hideFeedTypeOnBoxSignAction(feedType: FeedType): Promise<void> {
    const command: HideFeedTypeOnBoxSignAsManagerCommand = {
      feedTypeId: feedType.feedTypeId,
    };
    return this.store.hideFeedTypeOnBoxSign(command)
      .then(() => showSuccessMessage('Der Futtertyp wird auf dem Boxenschild ausgeblendet.'))
      .catch((error) => showErrorResponse(error));
  }

  async showFeedTypeOnBoxSignAction(feedType: FeedType): Promise<void> {
    const command: ShowFeedTypeOnBoxSignAsManagerCommand = {
      feedTypeId: feedType.feedTypeId,
    };
    return this.store.showFeedTypeOnBoxSign(command)
      .then(() => showSuccessMessage('Der Futtertyp wird auf dem Boxenschild eingeblendet.'))
      .catch((error) => showErrorResponse(error));
  }

}
