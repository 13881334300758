
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Date, HorseId } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, notAfterDateRule, notBeforeDateRule } from '@/components/form';
import { CreateHorseDrugEntryAsManagerCommand } from '../types';
import { useMedicalRecordsManagementStore } from '../store';

interface Controls extends FormControls {
  startingAt: FormControl<Date>;
  endingAt: FormControl<Date>;
  drugName: FormControl<string>;
  comment: FormControl<string>;
}

@Component
export default class CreateHorseDrugEntryDialog extends Vue {

  readonly store = useMedicalRecordsManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: String, required: true })
  readonly horseId!: HorseId;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        startingAt: {
          label: 'Startdatum',
          value: Date.today(),
          isRequired: true,
          rules: [],
          validateFormControlsOnInput: [
            'endingAt',
          ],
        },
        endingAt: {
          label: 'Enddatum',
          value: Date.today(),
          isRequired: true,
          rules: [],
          validateFormControlsOnInput: [
            'startingAt',
          ],
        },
        drugName: {
          label: 'Bezeichnung des Medikaments',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        comment: {
          label: 'Kommentar',
          value: null,
        },
      },
    });

    form.controls.startingAt.rules!.push(notAfterDateRule(form.controls.endingAt));
    form.controls.endingAt.rules!.push(notBeforeDateRule(form.controls.startingAt));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateHorseDrugEntryAsManagerCommand = {
      horseId: this.horseId,
      startingAt: formValues.startingAt!,
      endingAt: formValues.endingAt!,
      drugName: formValues.drugName!,
      comment: formValues.comment,
    };

    this.store.createHorseDrugEntry(command)
      .then(() => showSuccessMessage('Das Medikament wurde eingetragen.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
