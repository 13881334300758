import { render, staticRenderFns } from "./task-details-dialog.vue?vue&type=template&id=ed9333fe&scoped=true&"
import script from "./task-details-dialog.vue?vue&type=script&lang=ts&"
export * from "./task-details-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./task-details-dialog.vue?vue&type=style&index=0&id=ed9333fe&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed9333fe",
  null
  
)

export default component.exports