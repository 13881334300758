
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { DataListItem } from '@/components/design-system';
import { formatBoolean, formatTime } from '@/helpers';
import { usePenManagementStore } from '../store';
import EnablePenExemptionsDialog from './enable-pen-exemptions-dialog.vue';
import { Configuration, UpdatePenStatusVisibilityAsManagerCommand } from '../types';

@Component({
  components: {
    EnablePenExemptionsDialog,
  },
})
export default class ConfigurationView extends Vue {

  readonly store = usePenManagementStore();

  @Prop({ type: Object, required: true })
  readonly configuration!: Configuration;

  get penHistoryDataItems(): DataListItem[] {
    return [
      {
        key: 'isPenStatusVisible',
        label: 'Koppelstatus sichtbar',
        value: formatBoolean(this.configuration.isPenStatusVisible),
      }
    ]
  }

  get penExceptionsDataItems(): DataListItem[] {
    return [
      {
        key: 'arePenExemptionsEnabled',
        label: 'Koppelausnahmen aktiviert',
        value: this.configuration.arePenExemptionsEnabled
          ? `Ja<br />Späteste Uhrzeit für Einträge am Folgetag: ${formatTime(this.configuration.creationAvailableUntil!)} Uhr`
          : `Nein<br /><span class="text-small">Wenn du Koppelausnahmen aktivierst, können deine Reiter in ihrem Profil einen Zeitraum definieren, wann eines ihrer Pferde <strong>nicht</strong> auf die Koppel darf (beispielsweise aufgrund einer Verletzung). Die von den Reitern eingetragenen Koppelausnahmen werden im Kontrollzentrum angezeigt.</span>`,
      }
    ]
  }

  get isShowPenStatusVisible(): boolean {
    return !this.configuration.isPenStatusVisible
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.PEN_WRITE);
  }

  get isHidePenStatusVisible(): boolean {
    return this.configuration.isPenStatusVisible
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.PEN_WRITE);
  }

  get isEnablePenExemptionVisible(): boolean {
    return !this.configuration.arePenExemptionsEnabled
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  get isDisablePenExemptionVisible(): boolean {
    return this.configuration.arePenExemptionsEnabled
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  mounted(): void {
    this.store.getConfiguration()
      .catch((error) => showErrorResponse(error));
  }

  async showPenStatus(): Promise<void> {
    const command: UpdatePenStatusVisibilityAsManagerCommand = {
      isPenStatusVisible: true,
    };

    return this.store.updatePenStatusVisibility(command)
      .then(() => showSuccessMessage('Koppelstatus wurde eingeblendet.'))
      .catch((error) => showErrorResponse(error));
  }

  async hidePenStatusAction(): Promise<void> {
    const command: UpdatePenStatusVisibilityAsManagerCommand = {
      isPenStatusVisible: false,
    };

    return this.store.updatePenStatusVisibility(command)
      .then(() => showSuccessMessage('Koppelstatus wurde ausgeblendet.'))
      .catch((error) => showErrorResponse(error));
  }

  async disablePenExemptionsAction(): Promise<void> {
    return this.store.disablePenExemptions()
      .then(() => showSuccessMessage('Koppelausnahmen wurden deaktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

}
