
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { useFarmDocumentsManagementStore } from '../store';
import CreateFarmDocumentDialog from './create-farm-document-dialog.vue';
import UpdateFarmDocumentDescriptionDialog from './update-farm-document-description-dialog.vue';
import UpdateFarmDocumentFileDialog from './update-farm-document-file-dialog.vue';
import DeleteFarmDocumentDialog from './delete-farm-document-dialog.vue';

@Component({
  components: {
    CreateFarmDocumentDialog,
    UpdateFarmDocumentDescriptionDialog,
    UpdateFarmDocumentFileDialog,
    DeleteFarmDocumentDialog,
  },
})
export default class ManageFarmDocuments extends Vue {

  readonly store = useFarmDocumentsManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Dateiname', value: 'documentFileName' },
    { text: 'Titel', value: 'title' },
    { text: 'Beschreibung', value: 'description' },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 190 },
  ];

  get isCreateFarmDocumentVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_DOCUMENTS_WRITE);
  }

  get isUpdateMenuVisible(): boolean {
    return this.isUpdateFarmDocumentDescriptionVisible
      || this.isUpdateFarmDocumentFileVisible;
  }

  get isUpdateFarmDocumentDescriptionVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_DOCUMENTS_WRITE);
  }

  get isUpdateFarmDocumentFileVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_DOCUMENTS_WRITE);
  }

  get isDeleteFarmDocumentVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_DOCUMENTS_WRITE);
  }

  mounted(): void {
    this.store.getDocuments()
      .catch((error) => showErrorResponse(error));
  }

}
