
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useFacilityReservationsManagementStore } from '../store';
import { EnableFacilityReservationActivityAsManagerCommand, FacilityReservationActivity } from '../types';

@Component
export default class EnableFacilityReservationActivityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Prop({ type: Object, required: true })
  readonly facilityReservationActivity!: FacilityReservationActivity;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  enableFacilityReservationActivityConfirmed(): void {
    const command: EnableFacilityReservationActivityAsManagerCommand = {
      facilityReservationActivityId: this.facilityReservationActivity.facilityReservationActivityId,
    };

    this.store.enableFacilityReservationActivity(command)
      .then(() => showSuccessMessage('Die Aktivität wurde aktiviert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
