
import { Component, Prop, Vue } from 'vue-property-decorator';

interface CostItem {
  grossSellingPrice: number;
  netSellingPrice: number | null;
  taxRate: number | null;
  taxAmount: number | null;
}

@Component
export default class SettlementCostColumn extends Vue {

  @Prop({ type: Object, required: true })
  readonly item!: CostItem;

}
