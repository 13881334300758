
import { Component, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import {
  isEventCanceledNotification, isEventPublishedNotification, isFacilityReservationCanceledDueToDisabledActivityNotification, isFacilityReservationCanceledDueToFacilityBlockerNotification, isFacilityReservationCanceledDueToReducedOpeningHoursNotification, isFacilityReservationCanceledDueToRidingLessonNotification, isFeedProtocolMissingSignalNotification, isFreeJumpingFreeRidingEventCanceledNotification, isImpersonationConfirmedNotification, isImpersonationRequestedNotification, isInvitedAsRideShareNotification, isLaborServiceEntryApprovedNotification, isLaborServiceEntryCreatedNotification, isLaborServiceEntryRejectedNotification, isNewsEntryPublishedNotification, isRidingLessonRescheduledNotification, isRidingLessonTypeUpdatedNotification, isRiderTaskAssignedNotification, isRiderTaskAssignedToYouNotification, isRiderTaskAssignedWithExecutionDateUpdateNotification, isRiderTaskCompletedNotification, isRiderTaskCreatedAssignedNotification, isRiderTaskCreatedNotification, isRiderTaskRejectedNotification, isRiderTaskWithdrawnNotification, isUserDeletedOwnAccountNotification, isVaccinationExpiresSoonNotification, NotificationConfiguration, NotificationType, RiderTaskStatus, isFarmTaskCreatedNotification, isFarmTaskAssignedToYouNotification, isFarmTaskYouHaveBeenRemovedFromTaskNotification, isFarmTaskExecutionDateUpdatedNotification, isFarmTaskDeletedNotification, isSurveyCreatedNotification, isSurveyAnswerDeletedNotification
} from '@/types';
import { navigate } from '@/helpers/navigation-helpers';
import { useDashboardStore } from '../store';
import { MarkNotificationAsSeenCommand, UnseenNotification } from '../types';

@Component
export default class Notifications extends Vue {

  readonly store = useDashboardStore();

  // Filtered as it's possible that new notification types are added and the user hasn't updated the app yet (as it's the first page)
  get knownNotifications(): UnseenNotification[] {
    return this.store.unseenNotifications.filter((notification) => isNewsEntryPublishedNotification(notification.configuration)
      || isEventPublishedNotification(notification.configuration)
      || isEventCanceledNotification(notification.configuration)
      || isRiderTaskCreatedNotification(notification.configuration)
      || isRiderTaskAssignedNotification(notification.configuration)
      || isRiderTaskAssignedWithExecutionDateUpdateNotification(notification.configuration)
      || isRiderTaskAssignedToYouNotification(notification.configuration)
      || isRiderTaskRejectedNotification(notification.configuration)
      || isRiderTaskCompletedNotification(notification.configuration)
      || isRiderTaskWithdrawnNotification(notification.configuration)
      || isRiderTaskCreatedAssignedNotification(notification.configuration)
      || isFarmTaskCreatedNotification(notification.configuration)
      || isFarmTaskAssignedToYouNotification(notification.configuration)
      || isFarmTaskYouHaveBeenRemovedFromTaskNotification(notification.configuration)
      || isFarmTaskExecutionDateUpdatedNotification(notification.configuration)
      || isFarmTaskDeletedNotification(notification.configuration)
      || isImpersonationRequestedNotification(notification.configuration)
      || isImpersonationConfirmedNotification(notification.configuration)
      || isVaccinationExpiresSoonNotification(notification.configuration)
      || isFacilityReservationCanceledDueToFacilityBlockerNotification(notification.configuration)
      || isFacilityReservationCanceledDueToReducedOpeningHoursNotification(notification.configuration)
      || isFacilityReservationCanceledDueToDisabledActivityNotification(notification.configuration)
      || isFacilityReservationCanceledDueToRidingLessonNotification(notification.configuration)
      || isFreeJumpingFreeRidingEventCanceledNotification(notification.configuration)
      || isRidingLessonRescheduledNotification(notification.configuration)
      || isRidingLessonTypeUpdatedNotification(notification.configuration)
      || isInvitedAsRideShareNotification(notification.configuration)
      || isLaborServiceEntryCreatedNotification(notification.configuration)
      || isLaborServiceEntryApprovedNotification(notification.configuration)
      || isLaborServiceEntryRejectedNotification(notification.configuration)
      || isUserDeletedOwnAccountNotification(notification.configuration)
      || isFeedProtocolMissingSignalNotification(notification.configuration)
      || isSurveyCreatedNotification(notification.configuration)
      || isSurveyAnswerDeletedNotification(notification.configuration)
    );
  }

  mounted(): void {
    this.store.getUnseenNotifications()
      .catch((error) => showErrorResponse(error));
  }

  notificationDescription(configuration: NotificationConfiguration): string {
    if (isNewsEntryPublishedNotification(configuration)) {
      return `Eine Neuigkeit wurde veröffentlicht`;
    }
    if (isEventPublishedNotification(configuration)) {
      return `Eine Veranstaltung wurde angekündigt`;
    }
    if (isEventCanceledNotification(configuration)) {
      return `Die Veranstaltung wurde abgesagt`;
    }
    if (isRiderTaskCreatedNotification(configuration)) {
      return `Neue Reiteraufgabe wurde erstellt`;
    }
    if (isRiderTaskAssignedNotification(configuration)) {
      return `Deine Aufgabe wurde zugewiesen`;
    }
    if (isRiderTaskAssignedWithExecutionDateUpdateNotification(configuration)) {
      return `Deine Aufgabe wurde mit einem angepassten Bearbeitungsdatum zugewiesen`;
    }
    if (isRiderTaskAssignedToYouNotification(configuration)) {
      return `Dir wurde eine Reiteraufgabe zugewiesen`;
    }
    if (isRiderTaskRejectedNotification(configuration)) {
      return `Deine Aufgabe wurde abgelehnt`;
    }
    if (isRiderTaskCompletedNotification(configuration)) {
      return `Deine Aufgabe wurde erledigt`;
    }
    if (isRiderTaskWithdrawnNotification(configuration)) {
      return `Eine Aufgabe wurde zurückgezogen`;
    }
    if (isRiderTaskCreatedAssignedNotification(configuration)) {
      return `Für dich wurde eine Reiteraufgabe eingestellt`;
    }
    if (isFarmTaskCreatedNotification(configuration)) {
      return `Für dich wurde eine Hofaufgabe angelegt`;
    }
    if (isFarmTaskAssignedToYouNotification(configuration)) {
      return `Dir wurde eine Hofaufgabe zugewiesen`;
    }
    if (isFarmTaskYouHaveBeenRemovedFromTaskNotification(configuration)) {
      return `Du wurdest von einer Hofaufgabe entfernt`;
    }
    if (isFarmTaskExecutionDateUpdatedNotification(configuration)) {
      return `Das Bearbeitungsdatum, einer dir zugewiesenen Hofaufgabe, wurde angepasst`;
    }
    if (isFarmTaskDeletedNotification(configuration)) {
      return `Eine dir zugewiesene Hofaufgabe wurde gelöscht`;
    }
    if (isImpersonationRequestedNotification(configuration)) {
      return `Ein Hofverwalter möchte dein Profil temporär übernehmen`;
    }
    if (isImpersonationConfirmedNotification(configuration)) {
      return `Profilübernahme wurde zugestimmt`;
    }
    if (isVaccinationExpiresSoonNotification(configuration)) {
      return `Eine Impfung läuft bald ab`;
    }
    if (isFacilityReservationCanceledDueToFacilityBlockerNotification(configuration)) {
      return `Deine Anlagenreservierung wurde wegen einer Sperrzeit storniert`;
    }
    if (isFacilityReservationCanceledDueToReducedOpeningHoursNotification(configuration)) {
      return `Deine Anlagenreservierung wurde durch verringerte Öffnungszeiten storniert`;
    }
    if (isFacilityReservationCanceledDueToDisabledActivityNotification(configuration)) {
      return `Deine Anlagenreservierung wurde wegen einer deaktivierten Aktivität storniert`;
    }
    if (isFacilityReservationCanceledDueToRidingLessonNotification(configuration)) {
      return `Deine Anlagenreservierung wurde wegen einer Unterrichtsstunde storniert`;
    }
    if (isFreeJumpingFreeRidingEventCanceledNotification(configuration)) {
      return `Freispringen/-laufen Veranstaltung wurde abgesagt`;
    }
    if (isRidingLessonRescheduledNotification(configuration)) {
      return `Unterrichtsstunde wurde verschoben`;
    }
    if (isRidingLessonTypeUpdatedNotification(configuration)) {
      return `Unterrichtsart und / oder Anlage wurde angepasst`;
    }
    if (isInvitedAsRideShareNotification(configuration)) {
      return `Du wurdest als Reitbeteiligung eingeladen`;
    }
    if (isLaborServiceEntryCreatedNotification(configuration)) {
      return `Neuer Arbeitsdienst eingetragen`;
    }
    if (isLaborServiceEntryApprovedNotification(configuration)) {
      return `Dein Arbeitsdienst wurde abgezeichnet`;
    }
    if (isLaborServiceEntryRejectedNotification(configuration)) {
      return `Dein Arbeitsdienst wurde abgelehnt`;
    }
    if (isUserDeletedOwnAccountNotification(configuration)) {
      return `Ein Benutzer hat sein Konto gelöscht`;
    }
    if (isFeedProtocolMissingSignalNotification(configuration)) {
      return `Seit 6 Stunden keine Fressdaten erhalten`;
    }
    if (isSurveyCreatedNotification(configuration)) {
      return `Neue Umfrage`;
    }
    if (isSurveyAnswerDeletedNotification(configuration)) {
      return `Antwort auf eine Umfrage wurde gelöscht`;
    }

    throw new Error('Unknown notification type');
  }

  notificationIcon(configuration: NotificationConfiguration): string[] {
    if (isNewsEntryPublishedNotification(configuration)) {
      return ['far', 'newspaper'];
    }
    if (isEventPublishedNotification(configuration)) {
      return ['far', 'calendar'];
    }
    if (isEventCanceledNotification(configuration)) {
      return ['far', 'calendar'];
    }
    if (isRiderTaskCreatedNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isRiderTaskAssignedNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isRiderTaskAssignedWithExecutionDateUpdateNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isRiderTaskAssignedToYouNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isRiderTaskRejectedNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isRiderTaskCompletedNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isRiderTaskWithdrawnNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isRiderTaskCreatedAssignedNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isFarmTaskCreatedNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isFarmTaskAssignedToYouNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isFarmTaskYouHaveBeenRemovedFromTaskNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isFarmTaskExecutionDateUpdatedNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isFarmTaskDeletedNotification(configuration)) {
      return ['far', 'tasks'];
    }
    if (isImpersonationRequestedNotification(configuration)) {
      return ['fas', 'user-secret'];
    }
    if (isImpersonationConfirmedNotification(configuration)) {
      return ['fas', 'user-secret'];
    }
    if (isVaccinationExpiresSoonNotification(configuration)) {
      return ['far', 'clock'];
    }
    if (isFacilityReservationCanceledDueToFacilityBlockerNotification(configuration)) {
      return ['far', 'calendar-week'];
    }
    if (isFacilityReservationCanceledDueToReducedOpeningHoursNotification(configuration)) {
      return ['far', 'calendar-week'];
    }
    if (isFacilityReservationCanceledDueToDisabledActivityNotification(configuration)) {
      return ['far', 'calendar-week'];
    }
    if (isFacilityReservationCanceledDueToRidingLessonNotification(configuration)) {
      return ['far', 'calendar-week'];
    }
    if (isFreeJumpingFreeRidingEventCanceledNotification(configuration)) {
      return ['far', 'calendar-week'];
    }
    if (isRidingLessonRescheduledNotification(configuration)) {
      return ['far', 'calendar-week'];
    }
    if (isRidingLessonTypeUpdatedNotification(configuration)) {
      return ['far', 'calendar-week'];
    }
    if (isInvitedAsRideShareNotification(configuration)) {
      return ['far', 'horse-head'];
    }
    if (isLaborServiceEntryCreatedNotification(configuration)) {
      return ['far', 'digging'];
    }
    if (isLaborServiceEntryApprovedNotification(configuration)) {
      return ['far', 'digging'];
    }
    if (isLaborServiceEntryRejectedNotification(configuration)) {
      return ['far', 'digging'];
    }
    if (isUserDeletedOwnAccountNotification(configuration)) {
      return ['far', 'ban'];
    }
    if (isFeedProtocolMissingSignalNotification(configuration)) {
      return ['far', 'horse-head'];
    }
    if (isSurveyCreatedNotification(configuration)) {
      return ['far', 'clipboard-list'];
    }
    if (isSurveyAnswerDeletedNotification(configuration)) {
      return ['far', 'clipboard-list'];
    }

    throw new Error('Unknown notification type');
  }

  notificationClicked(notification: UnseenNotification): void {
    const command: MarkNotificationAsSeenCommand = {
      notificationId: notification.notificationId,
    };

    this.store.markNotificationAsSeen(command)
      .then(() => this.store.getUnseenNotifications())
      .catch((error) => showErrorResponse(error));


    if (isEventCanceledNotification(notification.configuration)) {
      return showSuccessMessage('Benachrichtigung als gelesen markiert.');
    }

    const location = this.locationForNotification(notification);
    if (location) {
      navigate(location);
    }
  }

  locationForNotification(notification: UnseenNotification): Location | null {
    if (isNewsEntryPublishedNotification(notification.configuration)) {
      return { name: 'news-details', params: { newsEntryId: notification.configuration.payload.newsEntryId } };
    }
    if (isEventPublishedNotification(notification.configuration)) {
      return { name: 'event-details', params: { eventId: notification.configuration.payload.eventId } };
    }
    if (isEventCanceledNotification(notification.configuration)) {
      return null;
    }
    if (isRiderTaskCreatedNotification(notification.configuration)) {
      if (notification.update?.currentTaskStatus === RiderTaskStatus.ASSIGNED) {
        return { name: 'rider-task-management-assigned-tasks' };
      }
      if (notification.update?.currentTaskStatus === RiderTaskStatus.COMPLETED) {
        return { name: 'rider-task-management-completed-tasks' };
      }
      if (notification.update?.currentTaskStatus === RiderTaskStatus.REJECTED) {
        return { name: 'rider-task-management-rejected-tasks' };
      }
      if (notification.update?.currentTaskStatus === RiderTaskStatus.WITHDRAWN) {
        return { name: 'rider-task-management-withdrawn-tasks' };
      }
      return { name: 'rider-task-management-new-tasks' };
    }
    if (isRiderTaskAssignedNotification(notification.configuration)) {
      return { name: 'task-list' };
    }
    if (isRiderTaskAssignedWithExecutionDateUpdateNotification(notification.configuration)) {
      return { name: 'task-list' };
    }
    if (isRiderTaskAssignedToYouNotification(notification.configuration)) {
      if (notification.update?.currentTaskStatus === RiderTaskStatus.COMPLETED) {
        return { name: 'rider-task-management-completed-tasks' };
      }
      if (notification.update?.currentTaskStatus === RiderTaskStatus.REJECTED) {
        return { name: 'rider-task-management-rejected-tasks' };
      }
      if (notification.update?.currentTaskStatus === RiderTaskStatus.WITHDRAWN) {
        return { name: 'rider-task-management-withdrawn-tasks' };
      }
      return { name: 'control-panel' };
    }
    if (isRiderTaskRejectedNotification(notification.configuration)) {
      return { name: 'task-list' };
    }
    if (isRiderTaskCompletedNotification(notification.configuration)) {
      return { name: 'task-list' };
    }
    if (isRiderTaskWithdrawnNotification(notification.configuration)) {
      return { name: 'rider-task-management-withdrawn-tasks' };
    }
    if (isRiderTaskCreatedAssignedNotification(notification.configuration)) {
      if (notification.update?.currentTaskStatus === RiderTaskStatus.COMPLETED) {
        return { name: 'rider-task-management-completed-tasks' };
      }
      if (notification.update?.currentTaskStatus === RiderTaskStatus.REJECTED) {
        return { name: 'rider-task-management-rejected-tasks' };
      }
      if (notification.update?.currentTaskStatus === RiderTaskStatus.WITHDRAWN) {
        return { name: 'rider-task-management-withdrawn-tasks' };
      }
      return { name: 'control-panel' };
    }
    if (isFarmTaskCreatedNotification(notification.configuration)) {
      return { name: 'farm-task-tasks-grouped-by-user' };
    }
    if (isFarmTaskAssignedToYouNotification(notification.configuration)) {
      return { name: 'farm-task-tasks-grouped-by-user' };
    }
    if (isFarmTaskYouHaveBeenRemovedFromTaskNotification(notification.configuration)) {
      return { name: 'farm-task-tasks-grouped-by-user' };
    }
    if (isFarmTaskExecutionDateUpdatedNotification(notification.configuration)) {
      return { name: 'farm-task-tasks-grouped-by-user' };
    }
    if (isFarmTaskDeletedNotification(notification.configuration)) {
      return { name: 'farm-task-tasks-grouped-by-user' };
    }
    if (isImpersonationRequestedNotification(notification.configuration)) {
      return { name: 'allow-impersonation', params: { token: notification.configuration.payload.token} };
    }
    if (isImpersonationConfirmedNotification(notification.configuration)) {
      return { name: 'user-management/manage-users/user-table' };
    }
    if (isVaccinationExpiresSoonNotification(notification.configuration)) {
      return { name: 'horse-vaccination-certificate', params: { id: notification.configuration.payload.horseId } };
    }
    if (isFacilityReservationCanceledDueToFacilityBlockerNotification(notification.configuration)) {
      return { name: 'booking-calendars/facility-calendar', params: { id: notification.configuration.payload.facilityId} };
    }
    if (isFacilityReservationCanceledDueToReducedOpeningHoursNotification(notification.configuration)) {
      return { name: 'booking-calendars/facility-calendar', params: { id: notification.configuration.payload.facilityId} };
    }
    if (isFacilityReservationCanceledDueToDisabledActivityNotification(notification.configuration)) {
      return { name: 'booking-calendars/facility-calendar', params: { id: notification.configuration.payload.facilityId} };
    }
    if (isFacilityReservationCanceledDueToRidingLessonNotification(notification.configuration)) {
      return { name: 'booking-calendars/facility-calendar', params: { id: notification.configuration.payload.facilityId} };
    }
    if (isFreeJumpingFreeRidingEventCanceledNotification(notification.configuration)) {
      return { name: 'booking-calendars/free-jumping-free-running' };
    }
    if (isRidingLessonRescheduledNotification(notification.configuration)) {
      return { name: 'booking-calendars/facility-calendar', params: { id: notification.configuration.payload.facilityId} };
    }
    if (isRidingLessonTypeUpdatedNotification(notification.configuration)) {
      return { name: 'booking-calendars/facility-calendar', params: { id: notification.configuration.payload.facilityId} };
    }
    if (isInvitedAsRideShareNotification(notification.configuration)) {
      return { name: 'my-stable/horses' };
    }
    if (isLaborServiceEntryCreatedNotification(notification.configuration)) {
      return { name: 'labor-services-management-unhandled-time-entries' };
    }
    if (isLaborServiceEntryApprovedNotification(notification.configuration)) {
      return { name: 'labor-service' };
    }
    if (isLaborServiceEntryRejectedNotification(notification.configuration)) {
      return { name: 'labor-service' };
    }
    if (isUserDeletedOwnAccountNotification(notification.configuration)) {
      return { name: 'user-management/manage-users/user-table' };
    }
    if (isFeedProtocolMissingSignalNotification(notification.configuration)) {
      return { name: 'feed-protocol-overview' };
    }
    if (isSurveyCreatedNotification(notification.configuration)) {
      return { name: 'survey-list', params: { surveyId: notification.configuration.payload.surveyId} };
    }
    if (isSurveyAnswerDeletedNotification(notification.configuration)) {
      return { name: 'survey-list', params: { surveyId: notification.configuration.payload.surveyId} };
    }

    return null;
  }

  notificationUpdateDescription(notification: UnseenNotification): string {
    if (notification.update!.currentTaskStatus === RiderTaskStatus.ASSIGNED) {
      return 'Die Aufgabe wurde inzwischen zugewiesen.';
    }
    if (notification.update!.currentTaskStatus === RiderTaskStatus.REJECTED) {
      return 'Die Aufgabe wurde inzwischen abgelehnt.';
    }
    if (notification.update!.currentTaskStatus === RiderTaskStatus.COMPLETED) {
      return 'Die Aufgabe wurde inzwischen abgeschlossen.';
    }
    if (notification.update!.currentTaskStatus === RiderTaskStatus.WITHDRAWN) {
      return 'Die Aufgabe wurde inzwischen zurückgezogen.';
    }

    return '---';
  }

  isNotificationUpdateVisible(notification: UnseenNotification): boolean {
    if (!notification.update) {
      return false;
    }

    return (
      notification.configuration.type === NotificationType.RIDER_TASK_CREATED
        && notification.update.currentTaskStatus !== RiderTaskStatus.NEW
      ) || (
      notification.configuration.type === NotificationType.RIDER_TASK_CREATED_ASSIGNED
        && notification.update.currentTaskStatus !== RiderTaskStatus.NEW
      ) || (
        notification.configuration.type === NotificationType.RIDER_TASK_ASSIGNED_TO_YOU
        && notification.update.currentTaskStatus !== RiderTaskStatus.ASSIGNED
      ) || (
      notification.configuration.type === NotificationType.RIDER_TASK_ASSIGNED
        && notification.update.currentTaskStatus !== RiderTaskStatus.ASSIGNED
      ) || (
      notification.configuration.type === NotificationType.RIDER_TASK_ASSIGNED_WITH_EXECUTION_DATE_UPDATE
      && notification.update.currentTaskStatus !== RiderTaskStatus.ASSIGNED
    );
  }

  markAllNotificationsAsSeen(): void {
    this.store.markAllNotificationAsSeen()
      .then(() => showSuccessMessage('Alle Benachrichtigungen wurden als gesehen markiert.'))
      .catch((error) => showErrorResponse(error));
  }

}
