
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import UpdateFarmServiceOverviewConfigurationDialog from './update-farm-service-overview-configuration-dialog.vue';
import { useFarmServiceOverviewStore } from '../store';

@Component({
  components: { UpdateFarmServiceOverviewConfigurationDialog }
})
export default class FarmServiceOverviewTabs extends Vue {

  readonly store = useFarmServiceOverviewStore();

  mounted(): void {
    this.store.getFarmServiceOverviewFilterData()
      .catch((error) => showErrorResponse(error));
  }

  get isHiddenFarmServicesAlertVisible(): boolean {
    return !!this.store.farmServiceOverviewFilterData
      && this.store.farmServiceOverviewFilterData.idsOfHiddenFarmServices.length > 0;
  }

}
