import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { FarmServiceUsagesForDay, FarmServiceUsagesForMonth, GetFarmServiceUsagesForDayAsManagerQuery, GetFarmServiceUsagesForMonthAsManagerQuery, FarmServiceOverviewFilterData, UpdateHiddenFarmServicesAsManagerCommand } from './types';

// -- Queries

export function getFarmServiceOverviewFilterDataAsManager(query: FarmAndUserProperties): Promise<FarmServiceOverviewFilterData> {
  const url = `${apiUrl}/api/ledger/get-farm-service-overview-filter-data-as-manager-query`;
  return performApiRequest<FarmServiceOverviewFilterData>({ url, method: 'POST', data: query });
}

export function getFarmServiceUsagesForDay(
  query: GetFarmServiceUsagesForDayAsManagerQuery & FarmAndUserProperties
): Promise<FarmServiceUsagesForDay> {
  const url = `${apiUrl}/api/ledger/get-farm-service-usages-for-day-as-manager-query`;
  return performApiRequest<FarmServiceUsagesForDay>({ url, method: 'POST', data: query });
}

export function getFarmServiceUsagesForMonth(
  query: GetFarmServiceUsagesForMonthAsManagerQuery & FarmAndUserProperties
): Promise<FarmServiceUsagesForMonth> {
  const url = `${apiUrl}/api/ledger/get-farm-service-usages-for-month-as-manager-query`;
  return performApiRequest<FarmServiceUsagesForMonth>({ url, method: 'POST', data: query });
}

// -- Commands

export function updateHiddenFarmServices(command: UpdateHiddenFarmServicesAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-hidden-farm-services-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
