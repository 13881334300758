import { render, staticRenderFns } from "./horse-multi-select-form-control.vue?vue&type=template&id=aae89c18&scoped=true&"
import script from "./horse-multi-select-form-control.vue?vue&type=script&lang=ts&"
export * from "./horse-multi-select-form-control.vue?vue&type=script&lang=ts&"
import style0 from "./horse-multi-select-form-control.vue?vue&type=style&index=0&id=aae89c18&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aae89c18",
  null
  
)

export default component.exports