
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { useLedgerStore } from '../store';
import { UpdateFarmTermsOfPaymentAsManagerCommand } from '../types';

interface Controls extends FormControls {
  termsOfPayment: FormControl<string>;
}

@Component
export default class UpdateFarmTermsOfPaymentDialog extends Vue {

  readonly store = useLedgerStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        termsOfPayment: {
          label: 'Zahlungsbedingungen',
          value: this.store.invoiceInformation!.farmTermsOfPayment!,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFarmTermsOfPaymentAsManagerCommand = {
      farmTermsOfPayment: formValues.termsOfPayment!,
    };

    this.store.updateFarmTermsOfPayment(command)
      .then(() => showSuccessMessage('Die Zahlungsbedingungen wurden angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
