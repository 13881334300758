import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreateEventsAsManagerCommand, Event, DeleteEventAsManagerCommand, UpdateConfigurationAsManagerCommand, Configuration } from './types';

// -- Queries

export function getEventsAsFarmManager(query: FarmAndUserProperties): Promise<Event[]> {
  const url = `${apiUrl}/api/free-jumping-free-running/get-events-as-manager-query`;
  return performApiRequest<Event[]>({ url, method: 'POST', data: query });
}

export function getConfigurationAsFarmManager(query: FarmAndUserProperties): Promise<Configuration> {
  const url = `${apiUrl}/api/free-jumping-free-running/get-configuration-as-manager-query`;
  return performApiRequest<Configuration>({ url, method: 'POST', data: query });
}

// -- Commands

export function createEvents(command: CreateEventsAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/free-jumping-free-running/create-events-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteEvent(command: DeleteEventAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/free-jumping-free-running/delete-event-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateConfiguration(command: UpdateConfigurationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/free-jumping-free-running/update-configuration-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
