
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Time, TimeFrame } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { usePaddockPlanManagementStore } from '../store';
import { CreateTimeRangeAsManagerCommand } from '../types';

interface Controls extends FormControls {
  timeFrame: FormControl<TimeFrame>;
}

@Component
export default class CreatePaddockPlanTimeRangeDialog extends Vue {

  readonly store = usePaddockPlanManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        timeFrame: {
          label: 'Zeitfenster',
          value: {
            timeFrom: new Time(6, 0, 0),
            timeTo: new Time(22, 0, 0),
          },
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateTimeRangeAsManagerCommand = {
      timeRange: formValues.timeFrame!,
    };

    this.store.createTimeRange(command)
      .then(() => showSuccessMessage('Das Zeitfenster wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
