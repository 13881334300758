
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { VaccineId } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

export interface Vaccine {
  vaccineId: VaccineId;
  name: string;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
      && typeof item === 'object'
      && Object.hasOwn(item, 'vaccineId')
      && Object.hasOwn(item, 'name'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class VaccineFormControl extends Vue implements FormControlComponent<VaccineId> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<VaccineId>;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly items!: Vaccine[];

  @Prop({ type: Boolean, default: false })
  readonly isExplicitNullOptionAvailable!: boolean;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: VaccineId | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get selectItems(): VuetifySelectItem<VaccineId | null>[] {
    const selectItems: VuetifySelectItem<VaccineId | null>[] = this.items.map((item) => ({
      text: item.name,
      value: item.vaccineId,
    }));

    if (this.isExplicitNullOptionAvailable) {
      selectItems.push(({
        value: null,
        text: 'Impfstoff nicht aufgeführt?',
      }));
    }

    return selectItems;
  }

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<VaccineId> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
