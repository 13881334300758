
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { uuid } from '@/helpers';
import { useBoxPlanManagementStore } from '../store';
import { CreateBoxDTO } from '../types';

interface Controls extends FormControls {
  number: FormControl<string>;
}

@Component
export default class CreateBoxDialog extends Vue {

  readonly store = useBoxPlanManagementStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Number, required: true })
  readonly stableIndex!: number;

  @Prop({ type: Number, required: true })
  readonly boxRowIndex!: number;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        number: {
          label: 'Nummer',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(30),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const dto: CreateBoxDTO = {
      stableIndex: this.stableIndex,
      boxRowIndex: this.boxRowIndex,
      box: {
        boxId: uuid(),
        number: formValues.number!,
        horse: null,
      },
    };

    this.store.createBox(dto)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
