
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import CreatePaddockPlanTimeRangeDialog from './create-paddock-plan-time-range-dialog.vue';
import UpdatePaddockPlanTimeRangeDialog from './update-paddock-plan-time-range-dialog.vue';
import { usePaddockPlanManagementStore } from '../store';
import { DeleteTimeRangeAsManagerCommand, PaddockPlanTimeRange } from '../types';

@Component({
  components: {
    CreatePaddockPlanTimeRangeDialog,
    UpdatePaddockPlanTimeRangeDialog,
  },
})
export default class ManagePaddockPlanTimeRanges extends Vue {

  readonly store = usePaddockPlanManagementStore();

  get isCreatePaddockPlanTimeRangeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PADDOCK_PLAN_WRITE);
  }

  get isUpdatePaddockPlanTimeRangeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PADDOCK_PLAN_WRITE);
  }

  get isDeletePaddockPlanTimeRangeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.PADDOCK_PLAN_WRITE);
  }

  deleteTimeRangeAction(timeRange: PaddockPlanTimeRange): Promise<void> {
    const command: DeleteTimeRangeAsManagerCommand = {
      paddockPlanTimeRangeId: timeRange.paddockPlanTimeRangeId,
    };

    return this.store.deleteTimeRange(command)
      .then(() => showSuccessMessage('Das Zeitfenster wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
