
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition } from '@/application/types';
import { alphanumericRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { UpdateEquineNumberAsManagerCommand } from '../types';
import { useFeedProtocolManagementStore } from '../store';

interface Form extends StrictFormDefinition {
  fields: {
    equineNumber: FormField<string|null>;
  };
}

@Component
export default class UpdateEquineNumberDialog extends Vue {

  readonly store = useFeedProtocolManagementStore();

  @Prop({ type: String, required: true })
  readonly horseId!: string;

  @Prop({ type: String, default: null })
  readonly equineNumber!: string | null;

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        equineNumber: {
          value: this.equineNumber || null,
          rules: [
            alphanumericRule(),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateEquineNumberAsManagerCommand = {
      horseId: this.horseId,
      equineNumber: this.form!.fields.equineNumber.value,
    };

    this.store.updateEquineNumber(command)
      .then(() => showSuccessMessage('Die Lebensnummer wurde angepasst.'))
      .then(() => this.$emit('equine-number-updated'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
