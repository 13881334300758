
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { Persona, FarmManagerPermission } from '@/types';
import { useNewsManagementStore } from '../store';
import { DeleteNewsEntryAttachmentAsManagerCommand, DeleteNewsEntryImageAsManagerCommand, NewsEntry } from '../types';
import CreateNewsEntryDialog from './create-news-entry-dialog.vue';
import UpdateNewsEntryDialog from './update-news-entry-dialog.vue';
import DeleteNewsEntryDialog from './delete-news-entry-dialog.vue';
import HideNewsEntryDialog from './hide-news-entry-dialog.vue';
import ReleaseNewsEntryDialog from './release-news-entry-dialog.vue';
import UpdateNewsEntryVisibilityDialog from './update-news-entry-visibility-dialog.vue';

@Component({
  components: {
    CreateNewsEntryDialog,
    UpdateNewsEntryDialog,
    DeleteNewsEntryDialog,
    HideNewsEntryDialog,
    ReleaseNewsEntryDialog,
    UpdateNewsEntryVisibilityDialog,
  },
})
export default class NewsTable extends Vue {

  readonly store = useNewsManagementStore();

  @Prop({ type: Number, default: null })
  readonly maxAmount!: number | null;

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Datum', value: 'date' },
    { text: 'Titel', value: 'title' },
    { text: 'Erstellt am', value: 'createdAt', width: 100 },
    { text: 'Persona', value: 'visibleForPersonas' },
    { text: 'Gruppen', value: 'visibleForGroups', width: 110 },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 190 },
  ];

  get entriesByMaxAmount(): NewsEntry[] {
    if (this.maxAmount === null) {
      return this.store.entries;
    }

    return this.store.entries.filter((_, index) => (index + 1) <= this.maxAmount!);
  }

  get isCreateNewsEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  get isUpdateMenuVisible(): boolean {
    return this.isUpdateNewsEntryVisible
      || this.isUpdateNewsEntryVisibilityVisible
      || this.isDeleteNewsEntryAttachmentVisible
      || this.isDeleteNewsEntryImageVisible;
  }

  get isUpdateNewsEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  get isUpdateNewsEntryVisibilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  get isDeleteNewsEntryVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  get isDeleteNewsEntryAttachmentVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  get isDeleteNewsEntryImageVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  mounted(): void {
    this.store.getNewsEntries()
      .catch((error) => showErrorResponse(error));
  }

  isReleaseNewsEntryVisible(newsEntry: NewsEntry): boolean {
    return newsEntry.isHidden
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  isHideNewsEntryVisible(newsEntry: NewsEntry): boolean {
    return !newsEntry.isHidden
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_WRITE);
  }

  getVisibleForPersona(newsEntry: NewsEntry): string {
    if (!newsEntry.visibleForPersona) {
      return 'Alle';
    }

    if (newsEntry.visibleForPersona === Persona.WITH_HORSE) {
      return 'Einsteller';
    }

    if (newsEntry.visibleForPersona === Persona.RIDING_PARTICIPATION) {
      return 'Reitbeteiligung';
    }

    return '';
  }

  getVisibleForGroups(newsEntry: NewsEntry): string {
    if (!newsEntry.isVisibilityRestrictedForGroups) {
      return 'Alle';
    }

    return newsEntry.namesOfGroupsForWhichTheEntryIsVisible.join(', ');
  }

  isShowNewsImageDisabled(newsEntry: NewsEntry): boolean {
    return !newsEntry.image;
  }

  isNewsEntryAttachmentDeleteDisabled(newsEntry: NewsEntry): boolean {
    return newsEntry.attachmentUrl === null;
  }

  async deleteAttachmentAction(newsEntry: NewsEntry): Promise<void> {
    const command: DeleteNewsEntryAttachmentAsManagerCommand = {
      newsEntryId: newsEntry.newsEntryId,
    };

    return this.store.deleteNewsEntryAttachment(command)
      .then(() => showSuccessMessage('Anhang gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  isNewsEntryImageDeleteDisabled(newsEntry: NewsEntry): boolean {
    return !newsEntry.image;
  }

  async deleteImageConfirmed(newsEntry: NewsEntry): Promise<void> {
    const command: DeleteNewsEntryImageAsManagerCommand = {
      newsEntryId: newsEntry.newsEntryId,
    };

    return this.store.deleteNewsEntryImage(command)
      .then(() => showSuccessMessage('Bild gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
