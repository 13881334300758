
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { SurveyAnswerDTO, Survey } from '../types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class SurveyAnswersForUserFormControl extends Vue implements FormControlComponent<SurveyAnswerDTO> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<SurveyAnswerDTO>;

  @Prop({ type: Object, required: true })
  readonly survey!: Survey;

  readonly formControlId = createFormControlId();

  readonly internalFieldRules: InternalValueRules = [];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: SurveyAnswerDTO | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  areInternalRulesValid = false;

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  radioChanged(): void {
    internalValuesChanged(this);
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(
        this.internalFieldRules,
        this.internalValue,
      ),
    ];

    this.areInternalRulesValid = messages.length === 0;
    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  updateInternalValues(): void {
    const firstOptionId = this.survey.options[0].optionId!;
    const defaultAnswer: SurveyAnswerDTO = {
      optionId: firstOptionId,
      optionIds: null,
      horseId: null,
    };

    this.internalValue = this.formControl.value === null
      ? defaultAnswer
      : this.formControl.value;

    internalValuesChanged(this);
  }

  formValueFromInternalValues(): FormControlValue<SurveyAnswerDTO> {
    return this.internalValue !== null
      && this.areInternalRulesValid
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
