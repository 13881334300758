
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { moment } from '@/helpers';
import { useLaborServiceStore } from '../store';
import LaborServices from './labor-services.vue';

@Component({
  components: {
    LaborServices,
  },
})
export default class LaborServicesLoadingWrapper extends Vue {

  readonly store = useLaborServiceStore();
  readonly appStore = useAppStore();

  mounted(): void {
    const currentYear = moment().year();
    this.store.updateSummaryYear(currentYear)
      .then(() => this.appStore.updateTitle(`Arbeitsdienst für ${currentYear}`))
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.$reset();
  }

}
