
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, identicalStringRule } from '@/components/form';
import { useFarmManagementStore } from '../store';
import { Farm, DeleteFarmAsAdminCommand } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
}

@Component
export default class DeleteFarmDialog extends Vue {

  readonly store = useFarmManagementStore();

  @Prop({ type: Object, required: true })
  readonly farm!: Farm;

  readonly size = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name des Hofs',
          value: null,
          isRequired: true,
          rules: [
            identicalStringRule(this.farm.name, 'Stimmt nicht mit dem Namen des Hofs überein'),
          ],
        },
      },
    });
  }

  submitted(): void {
    const command: DeleteFarmAsAdminCommand = {
      targetFarmId: this.farm.farmId,
    };

    this.store.deleteFarm(command)
      .then(() => showSuccessMessage('Der Hof wurde gelöscht.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
