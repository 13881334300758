
import { Component, Prop, Vue } from 'vue-property-decorator';
import debounce from 'lodash-es/debounce';
import { FormValidationRule, VuetifyValidatable } from '@/application/types';
import { uuid } from '@/helpers';

@Component
export default class TextField extends Vue {

  @Prop({ type: String, default: null })
  readonly value!: string | null;

  @Prop({ type: String, required: true })
  readonly label!: string;

  @Prop({ type: [Array, Function], default: () => [] })
  readonly rules!: FormValidationRule<string|null>[] | (() => FormValidationRule<string|null>[]);

  @Prop({ type: Number, default: 0 })
  readonly debounceInterval!: number;

  readonly nativeFieldRefId = uuid();

  localValue = '';

  debounceLocalValue = debounce(this.textChanged, this.debounceInterval);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  formFieldValueWatcher = () => {};

  get textField(): VuetifyValidatable | undefined {
    return this.$refs[this.nativeFieldRefId] as VuetifyValidatable | undefined;
  }

  // Merge listeners explicitly here to overwrite @input
  get listeners(): any {
    return {
      ...this.$listeners,
      input: this.debounceLocalValue,
    };
  }

  get decoratedRules(): FormValidationRule<any>[] {
    const wrappedRules = typeof this.rules === 'function'
      ? this.rules()
        .map((rule) => () => rule(this.value))
      : this.rules
        .map((rule) => () => rule(this.value));

    return [...wrappedRules];
  }

  mounted(): void {
    this.updateLocalValue();
    this.watchFormFieldValue();
  }

  watchFormFieldValue(): void {
    this.formFieldValueWatcher = this.$watch('value', this.updateLocalValue);
  }

  unwatchFormFieldValue(): void {
    this.formFieldValueWatcher();
  }

  updateLocalValue(): void {
    this.localValue = this.value === null
      ? ''
      : this.value.trim();
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  textChanged(): void {
    if (this.localValue === null) {
      this.localValue = '';
    }

    const value = this.localValue.trim().length > 0
      ? this.localValue.trim()
      : null;

    this.unwatchFormFieldValue();
    this.$emit('input', value);

    Vue.nextTick(() => {
      this.watchFormFieldValue();
      // Validation is done explicitly as it can only be triggered after the value has been updated, therefore it's triggered twice.
      this.validate();
    });
  }

  validate(): void {
    if (this.textField) {
      this.textField.validate(true);
    }
  }

}
