
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataListItem } from '@/components/design-system';
import { formatBoolean, formatName } from '@/helpers';
import { useProfileStore } from '../store';
import { Profile } from '../types';
import DeleteOwnAccountDialog from './delete-own-account-dialog.vue';
import UpdateUserNameDialog from './update-user-name-dialog.vue';
import UpdatePasswordOfUserDialog from './update-password-of-user-dialog.vue';
import UpdateUserEmailAddressDialog from './update-user-email-address-dialog.vue';
import UpdatePhoneNumberOfUserDialog from './update-phone-number-of-user-dialog.vue';
import UpdateEmergencyContactOfUserDialog from './update-emergency-contact-of-user-dialog.vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';

@Component({
  components: {
    UpdateUserNameDialog,
    UpdateUserEmailAddressDialog,
    UpdatePasswordOfUserDialog,
    UpdatePhoneNumberOfUserDialog,
    UpdateEmergencyContactOfUserDialog,
    DeleteOwnAccountDialog,
  },
})
export default class ProfileView extends Vue {

  readonly store = useProfileStore();

  @Prop({ type: Object, required: true })
  readonly profile!: Profile;

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'name',
        label: 'Name',
        value: formatName(this.profile.name),
        meta: this.profile,
      },
      {
        key: 'emailAddress',
        label: 'E-Mail-Adresse',
        value: this.profile.emailAddress,
        meta: this.profile,
      },
      {
        key: 'password',
        label: 'Passwort',
        value: '********',
      },
      {
        key: 'phoneNumber',
        label: 'Telefonnummer',
        value: this.profile.phoneNumber ?? '-',
        meta: this.profile,
      },
      {
        key: 'emergencyContact',
        label: 'Notfallkontakt',
        value: this.emergencyContact,
        meta: this.profile,
      },
      {
        key: 'hasAcceptedNewsletter',
        label: 'Für Newsletter angemeldet',
        value: formatBoolean(this.profile.hasAcceptedNewsletter),
        meta: this.profile,
      },
    ];
  }

  get emergencyContact(): string {
    if (!this.profile.emergencyContactName && !this.profile.emergencyContactPhoneNumber) {
      return '-';
    }

    const boxSign = this.profile.isPhoneNumberVisibleOnBoxSign
      ? '<br />Wird auf dem Boxenschild angezeigt.'
      : '<br />Wird nicht auf dem Boxenschild angezeigt.';

    if (this.profile.emergencyContactName && this.profile.emergencyContactPhoneNumber) {
      return `${this.profile.emergencyContactName}<br />${this.profile.emergencyContactPhoneNumber}${boxSign}`;
    }

    if (this.profile.emergencyContactName) {
      return `${this.profile.emergencyContactName}${boxSign}`;
    }

    return `${this.profile.emergencyContactPhoneNumber!}${boxSign}`;
  }

  async acceptNewsletterAction(): Promise<void> {
    return this.store.acceptNewsletter()
      .then(() => showSuccessMessage('Newsletter wurde erfolgreich abonniert.'))
      .catch((error) => showErrorResponse(error));
  }

  async rejectNewsletterAction(): Promise<void> {
    return this.store.rejectNewsletter()
      .then(() => showSuccessMessage('Newsletter wurde erfolgreich abgemeldet.'))
      .catch((error) => showErrorResponse(error));
  }

}
