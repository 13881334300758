
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition, VuetifySelectItem } from '@/application/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { requiredRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { moment } from '@/helpers';
import { useLaborServicesManagementStore } from '../store';

interface Form extends StrictFormDefinition {
  fields: {
    summaryYear: FormField<number>;
  }
}

@Component
export default class ChangeSummaryYearDialog extends Vue {

  readonly store = useLaborServicesManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  get yearOptions(): VuetifySelectItem<number>[] {
    const currentYear = moment().year();
    const selectItems: VuetifySelectItem<number>[] = [];

    let loopYear = 2020;
    do {
      selectItems.push({ value: loopYear, text: loopYear.toString() });
      loopYear++;
    } while (loopYear <= currentYear);

    return selectItems;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        summaryYear: {
          value: this.store.summaryYear,
          rules: [
            requiredRule(),
          ],
        },
      },
    };
  }

  formSubmitted(): void {
    this.store.updateSummaryYear(this.form!.fields.summaryYear.value)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
