
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { isMobileSafari, isNativeApplication } from '@/helpers/detection-helpers';
import { downloadCSVFile } from '@/helpers/file-download-helper';
import { FormField, StrictFormDefinition } from '@/application/types';
import { moment } from '@/helpers';
import { useLaborServicesManagementStore } from '../store';
import { TimeEntry } from '../types';
import ChangeSummaryYearDialog from './change-summary-year-dialog.vue';

interface Form extends StrictFormDefinition {
  fields: {
    search: FormField<string|null>;
  }
}

@Component({
  components: {
    ChangeSummaryYearDialog,
  },
})
export default class LaborServiceSummary extends Vue {

  readonly store = useLaborServicesManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Benutzer', value: 'user' },
    { text: 'Stundenanzahl', value: 'numberOfHoursSummary' },
  ];

  readonly isMobileSafari = isMobileSafari;
  readonly isNativeApplication = isNativeApplication;

  form: Form | null = null;

  mounted(): void {
    this.form = this.buildForm();

    this.store.getTimeRecordings()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        search: {
          value: null,
          rules: [],
        },
      },
    };
  }

  filterTimeEntries(value: any, search: string | null, timeEntry: TimeEntry) {
    if (search === null) {
      return true;
    }

    const searchLowerCase = search.toLocaleLowerCase();

    if (timeEntry.user.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (timeEntry.description && timeEntry.description.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (timeEntry.handledBy && timeEntry.handledBy.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    return false;
  }

  exportSummaryEntriesAsCsvClicked(): void {
    const fileName = `Zusammenfassung-${this.store.summaryYear}-Arbeitsdienst-${moment().format('YYYY-MM-DD')}.csv`;
    this.store.exportSummaryEntriesAsCsv()
      .then((csv) => downloadCSVFile(csv, fileName))
      .catch((error) => showErrorResponse(error));
  }

}
