
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { useFreeJumpingFreeRunningStore } from '../store';
import FreeJumpingFreeRunningEvent from './free-jumping-free-running-event.vue';

@Component({
  components: {
    FreeJumpingFreeRunningEvent,
  },
})
export default class FreeJumpingFreeRunningEventLoadingWrapper extends Vue {

  readonly store = useFreeJumpingFreeRunningStore();
  readonly appStore = useAppStore();

  mounted(): void {
    watch(() => this.store.currentEvent, (currentEvent) => {
      if (currentEvent) {
        this.appStore.updateTitle(currentEvent.date.format('DD.MM.YYYY'))
          .catch((error) => showErrorResponse(error));
      }
    });

    this.store.updateCurrentEvent(this.$route.params.id)
      .catch((error) => showErrorResponse(error));
  }

}
