
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormField, VuetifyValidatable } from '@/application/types';
import { Time } from '@/types';
import { uuid } from '@/helpers';
import TimeField from './time-field.vue';

@Component({
  components: {
    'a-time-field': TimeField,
  },
})
export default class TimeInput extends Vue {

  @Prop({ type: Object, required: true })
  readonly formField!: FormField<Time|null>;

  @Prop({ type: String, required: true })
  readonly label!: string;

  readonly timeFieldRefId = uuid();

  get timeField(): VuetifyValidatable | undefined {
    return this.$refs[this.timeFieldRefId] as VuetifyValidatable | undefined;
  }

  validate(): void {
    if (this.timeField) {
      this.timeField.validate(true);
    }
  }

}
