
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useUserManagementStore } from '../store';
import { Group, UpdateGroupNameAsManagerCommand } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
}

@Component
export default class UpdateGroupNameDialog extends Vue {

  readonly store = useUserManagementStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly group!: Group;

  form: Form<Controls> | null = null;

  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name der Gruppe',
          value: this.group.name,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateGroupNameAsManagerCommand = {
      userGroupId: this.group.userGroupId,
      name: formValues.name!,
    };

    this.store.updateGroupName(command)
      .then(() => showSuccessMessage('Der Name wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
