
import { Component, Vue } from 'vue-property-decorator';
import { showErrorMessage, showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { usePinboardStore } from '../store';
import NewsDetails from './news-details.vue';

@Component({
  components: {
    NewsDetails,
  },
})
export default class NewsDetailsLoadingWrapper extends Vue {

  readonly store = usePinboardStore();
  readonly appStore = useAppStore();

  get isLoadingBarVisible(): boolean {
    return this.store.newsEntries.length === 0
      && this.store.isGetNewsEntriesProcessing;
  }

  mounted(): void {
    this.store.updateCurrentNewsEntry(this.$route.params.newsEntryId)
      .then(() => {
        if (!this.store.currentNewsEntry) {
          showErrorMessage('Die Neuigkeit existiert nicht mehr. Ggf. wurde sie in der Zwischenzeit gelöscht.');
          this.$router.push({ name: 'news-list' });
        }
      })
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetCurrentNewsEntry()
      .catch((error) => showErrorResponse(error));
  }

}
