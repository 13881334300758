
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { FeedUnitNumerus, feedUnitNumerusTranslations } from '@/helpers/data';
import { FeedUnit } from '@/types';
import { useFeedingManagementStore } from '../store';

@Component
export default class FeedingOverview extends Vue {

  readonly store = useFeedingManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Pferd', value: 'horse' },
    { text: 'Morgens', value: 'morning' },
    { text: 'Mittags', value: 'noon' },
    { text: 'Abends', value: 'evening' },
  ];

  mounted(): void {
    this.store.getFeedingOverview()
      .catch((error) => showErrorResponse(error));
  }

  feedUnitTranslation(feedUnit?: FeedUnit, amount?: number): string {
    if (!feedUnit || !amount) {
      return '';
    }

    const feedUnitNumerus: FeedUnitNumerus = `${feedUnit}-${amount > 1 ? 'plural' : 'singular'}`;

    return feedUnitNumerusTranslations[feedUnitNumerus];
  }

}
