
import { Component, Vue } from 'vue-property-decorator';
import { DataListItem } from '@/components/design-system';
import { formatCurrency } from '@/helpers/stateful-format';
import { formatKilogram } from '@/helpers';
import { useLedgerStore } from '../store';
import AddConcentratedFeedingTypeDialog from './add-concentrated-feeding-type-dialog.vue';

@Component({
  components: {
    AddConcentratedFeedingTypeDialog,
  },
})
export default class GerblhofFeeding extends Vue {

  readonly store = useLedgerStore();

  get dataItems(): DataListItem[] {
    if (!this.store.configuration
      || !this.store.configuration.feeding
    ) {
      return [];
    }

    return [
      {
        key: 'defaultAmountHay',
        label: 'Standardmenge Heu',
        value: `Morgens: ${formatKilogram(this.store.configuration.feeding.defaultAmountHayMorning)}<br />Abends: ${formatKilogram(this.store.configuration.feeding.defaultAmountHayEvening)}`,
      },
      {
        key: 'defaultAmountHalfHay',
        label: 'Standardmenge Halbheu',
        value: `Morgens: ${formatKilogram(this.store.configuration.feeding.defaultAmountHalfHayMorning)}<br />Abends: ${formatKilogram(this.store.configuration.feeding.defaultAmountHalfHayEvening)}`,
      },
      {
        key: 'feedingSwitchMorningPrice',
        label: 'Gebühren bei Abweichung des Futtertyps morgens',
        value: formatCurrency(this.store.configuration.feeding.feedingSwitchMorningPrice),
      },
      {
        key: 'feedingSwitchEveningPrice',
        label: 'Gebühren bei Abweichung des Futtertyps abends',
        value: formatCurrency(this.store.configuration.feeding.feedingSwitchEveningPrice),
      },
      {
        key: 'includedHayAmount',
        label: 'Inklusivmenge Heu pro Tag',
        value: formatKilogram(this.store.configuration.feeding.includedHayAmount),
      },
      {
        key: 'includedHalfHayAmount',
        label: 'Inklusivmenge Halbheu pro Tag',
        value: formatKilogram(this.store.configuration.feeding.includedHalfHayAmount),
      },
      {
        key: 'maxHayAmountPerFeeding',
        label: 'Maximalmenge Heu pro Fütterung',
        value: formatKilogram(this.store.configuration.feeding.maxHayAmountPerFeeding),
      },
      {
        key: 'maxHalfHayAmountPerFeeding',
        label: 'Maximalmenge Halbheu pro Fütterung',
        value: formatKilogram(this.store.configuration.feeding.maxHalfHayAmountPerFeeding),
      },
      {
        key: 'hayUnitPrice',
        label: 'Monatliche Kosten pro zusätzlichem Kilogramm Heu',
        value: formatCurrency(this.store.configuration.feeding.hayUnitPrice),
      },
      {
        key: 'halfHayUnitPrice',
        label: 'Monatliche Kosten pro zusätzlichem Kilogramm Halbheu',
        value: formatCurrency(this.store.configuration.feeding.halfHayUnitPrice),
      },
      {
        key: 'specialFeedingEnabled',
        label: 'Sonderfütterung mittags',
        value: this.store.configuration.feeding.specialFeedingEnabled
          ? 'Aktiviert'
          : 'Deaktiviert',
      },
      {
        key: 'specialFeedingPrice',
        label: 'Monatliche Kosten für Sonderfütterung mittags',
        value: formatCurrency(this.store.configuration.feeding.specialFeedingPrice),
      },
      {
        key: 'concentratedFeedTypes',
        label: 'Kraftfutter-Typen',
        value: `<ul>${this.store.configuration.concentratedFeedTypes
          .map((concentratedFeedingType) => `<li>${concentratedFeedingType.name}</li>`)
          .join('')}</ul>`,
      },
    ];
  }

}
