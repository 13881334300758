
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { positiveNumberRule, requiredRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { FarmServiceBookingType, SubscriptionSchedule } from '@/types';
import { useLedgerStore } from '../store';
import { FarmService, UpdateFarmServiceBookingStartAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    bookingType: FormField<FarmServiceBookingType>;
    subscriptionSchedule: FormField<SubscriptionSchedule>;
    isBookingInCurrentMonthAllowed: FormField<boolean>;
    bookingInAdvance: FormField<number|null>;
  },
}

@Component
export default class UpdateBookingStartOfFarmServiceDialog extends Vue {

  readonly store = useLedgerStore();

  @Prop({ type: Object, required: true })
  readonly farmService!: FarmService;

  readonly dialogMaxWidth = DialogWidth.large;

  readonly bookingTypeOptions: { text: string, value: FarmServiceBookingType }[] = [
    { text: 'Abo', value: FarmServiceBookingType.subscription },
    { text: 'Einmalbuchung', value: FarmServiceBookingType['one-off'] },
  ];
  readonly subscriptionScheduleOptions: { text: string; value: SubscriptionSchedule }[] = [
    { text: '1 Monat', value: SubscriptionSchedule['one-month'] },
    { text: '3 Monate', value: SubscriptionSchedule['three-months'] },
    { text: '6 Monate', value: SubscriptionSchedule['six-months'] },
    { text: '1 Jahr', value: SubscriptionSchedule['one-year'] },
  ];

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  get isBookingInAdvanceVisible(): boolean {
    return this.form!.fields.bookingType.value === FarmServiceBookingType['one-off'] || !this.form!.fields.isBookingInCurrentMonthAllowed.value;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        bookingType: {
          value: this.farmService.bookingType,
          rules: [
            requiredRule(),
          ],
        },
        subscriptionSchedule: {
          value: this.farmService.subscriptionSchedule || SubscriptionSchedule['one-month'],
          rules: [
            requiredRule(),
          ],
        },
        isBookingInCurrentMonthAllowed: {
          value: this.farmService.isBookingInCurrentMonthAllowed || false,
          rules: [],
        },
        bookingInAdvance: {
          value: this.farmService.bookingInAdvance !== null
            ? this.farmService.bookingInAdvance
            : 0,
          rules: [
            requiredRule(),
            positiveNumberRule(),
          ],
        },
      },
    };
  }

  formSubmitted(): void {
    const command: UpdateFarmServiceBookingStartAsManagerCommand = {
      farmServiceId: this.farmService.id,
      isBookingInCurrentMonthAllowed: this.farmService.bookingType === 'subscription'
        ? this.form!.fields.isBookingInCurrentMonthAllowed.value
        : null,
      bookingInAdvance: !this.form!.fields.isBookingInCurrentMonthAllowed.value
        ? this.form!.fields.bookingInAdvance.value!
        : null,
    };

    this.store.updateFarmServiceBookingStart(command)
      .then(() => showSuccessMessage('Die Vorlaufzeit wurden angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
