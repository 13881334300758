
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, requiredWhenControlIsFilledRule } from '@/components/form';
import { Address, InvoiceAddressForOrganization, Name, OrganizationId, Salutation } from '@/types';
import { DefineInvoiceAddressOfOrganizationAsManagerWorkflow, RemoveInvoiceAddressOfOrganizationAsManagerWorkflow, UpdateInvoiceAddressOfOrganizationAsManagerWorkflow } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  company: FormControl<string>;
  salutationOfContactPerson: FormControl<Salutation>;
  nameOfContactPerson: FormControl<Name>;
  address: FormControl<Address>;
}

@Component
export default class UpdateInvoiceAddressOfPersonDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: String, required: true })
  readonly organizationId!: OrganizationId;

  @Prop({ type: Object, default: null })
  readonly invoiceAddress!: InvoiceAddressForOrganization | null;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isProcessing(): boolean {
    return this.store.isDefineInvoiceAddressOfOrganizationProcessing
      || this.store.isUpdateInvoiceAddressOfOrganizationProcessing
      || this.store.isRemoveInvoiceAddressOfOrganizationProcessing;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        company: {
          label: 'Firmenname',
          value: this.invoiceAddress?.company ?? null,
          rules: [],
          validateFormControlsOnInput: [
            'address',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'address',
          ],
        },
        salutationOfContactPerson: {
          label: 'Anrede des Ansprechpartners',
          value: this.invoiceAddress?.salutationOfContactPerson ?? null,
          validateFormControlsOnInput: [
            'company',
            'address',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'company',
            'address',
          ],
        },
        nameOfContactPerson: {
          label: 'Name des Ansprechpartners',
          value: this.invoiceAddress?.nameOfContactPerson ?? null,
          validateFormControlsOnInput: [
            'company',
            'address',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'company',
            'address',
          ],
        },
        address: {
          label: 'Adresse',
          value: this.invoiceAddress?.address ?? null,
          rules: [],
          validateFormControlsOnInput: [
            'company',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'company',
          ],
        },
      },
    });

    form.controls.company.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.salutationOfContactPerson,
      'Die Firma muss angegeben werden, wenn die Anrede des Ansprechpartners gefüllt ist.',
    ));
    form.controls.company.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.nameOfContactPerson,
      'Die Firma muss angegeben werden, wenn der Name des Ansprechpartners gefüllt ist.',
    ));
    form.controls.company.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.address,
      'Die Firma muss angegeben werden, wenn die Adresse gefüllt ist.',
    ));

    form.controls.address.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.salutationOfContactPerson,
      'Die Adresse muss angegeben werden, wenn die Anrede gefüllt ist.',
    ));
    form.controls.address.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.company,
      'Die Adresse muss angegeben werden, wenn die Firma gefüllt ist.',
    ));
    form.controls.address.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.nameOfContactPerson,
      'Die Adresse muss angegeben werden, wenn der Name des Ansprechpartners gefüllt ist.',
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    if (this.invoiceAddress === null
      && formValues.company !== null
      && formValues.address !== null
    ) {
      const command: DefineInvoiceAddressOfOrganizationAsManagerWorkflow = {
        organizationId: this.organizationId,
        invoiceAddress: {
          company: formValues.company,
          salutationOfContactPerson: formValues.salutationOfContactPerson,
          nameOfContactPerson: formValues.nameOfContactPerson,
          address: formValues.address!,
        }
      };

      this.store.defineInvoiceAddressOfOrganization(command)
        .then(() => showSuccessMessage('Die Rechnungsadresse wurde angepasst.'))
        .then(() => this.$emit('organization-updated'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));
    } else if (this.invoiceAddress !== null
      && formValues.company !== null
      && formValues.address !== null
    ) {
      const command: UpdateInvoiceAddressOfOrganizationAsManagerWorkflow = {
        organizationId: this.organizationId,
        invoiceAddress: {
          company: formValues.company,
          salutationOfContactPerson: formValues.salutationOfContactPerson,
          nameOfContactPerson: formValues.nameOfContactPerson,
          address: formValues.address,
        }
      };

      this.store.updateInvoiceAddressOfOrganization(command)
        .then(() => showSuccessMessage('Die Rechnungsadresse wurde angepasst.'))
        .then(() => this.$emit('organization-updated'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));
    } else {
      const command: RemoveInvoiceAddressOfOrganizationAsManagerWorkflow = {
        organizationId: this.organizationId,
      };

      this.store.removeInvoiceAddressOfOrganization(command)
        .then(() => showSuccessMessage('Die Rechnungsadresse wurde angepasst.'))
        .then(() => this.$emit('organization-updated'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));
    }
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
