
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, oneOfCheckboxesRequiredRule, requiredWhenCheckboxIsCheckedRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { FeedUnit } from '@/types';
import { CreateFeedTypeAsManagerCommand } from '../types';
import { useFeedingManagementStore } from '../store';

interface Controls extends FormControls {
  name: FormControl<string>;
  isOfferedInTheMorning: FormControl<boolean>;
  isOfferedAtNoon: FormControl<boolean>;
  isOfferedInTheEvening: FormControl<boolean>;
  isUserAbleToDefineAmount: FormControl<boolean>;
  feedUnit: FormControl<FeedUnit>;
  isVisibleOnBoxSign: FormControl<boolean>;
}

@Component
export default class CreateFeedTypeDialog extends Vue {

  readonly store = useFeedingManagementStore();

  readonly size = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isFeedUnitVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isUserAbleToDefineAmount!;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        isOfferedInTheMorning: {
          label: 'Futtertyp Morgens anbieten',
          value: false,
          rules: [],
          validateFormControlsOnInput: [
            'isOfferedAtNoon',
            'isOfferedInTheEvening',
          ],
        },
        isOfferedAtNoon: {
          label: 'Futtertyp Mittags anbieten',
          value: false,
          rules: [],
          validateFormControlsOnInput: [
            'isOfferedInTheMorning',
            'isOfferedInTheEvening',
          ],
        },
        isOfferedInTheEvening: {
          label: 'Futtertyp Abends anbieten',
          value: false,
          rules: [],
          validateFormControlsOnInput: [
            'isOfferedInTheMorning',
            'isOfferedAtNoon',
          ],
        },
        isUserAbleToDefineAmount: {
          label: 'Reiter bestimmt die Menge',
          value: false,
          rules: [],
        },
        feedUnit: {
          label: 'Futter-Einheit',
          value: FeedUnit.GRAM,
          rules: [],
        },
        isVisibleOnBoxSign: {
          label: 'Futtertyp wird auf dem Boxenschild angezeigt',
          value: true,
        },
      },
    });

    form.controls.isOfferedInTheMorning.rules!.push(oneOfCheckboxesRequiredRule([
      form.controls.isOfferedInTheMorning,
      form.controls.isOfferedAtNoon,
      form.controls.isOfferedInTheEvening,
    ], 'Der Typ muss mindestens zu einer Tageszeit angeboten werden'));

    form.controls.isOfferedAtNoon.rules!.push(oneOfCheckboxesRequiredRule([
      form.controls.isOfferedInTheMorning,
      form.controls.isOfferedAtNoon,
      form.controls.isOfferedInTheEvening,
    ], 'Der Typ muss mindestens zu einer Tageszeit angeboten werden'));

    form.controls.isOfferedInTheEvening.rules!.push(oneOfCheckboxesRequiredRule([
      form.controls.isOfferedInTheMorning,
      form.controls.isOfferedAtNoon,
      form.controls.isOfferedInTheEvening,
    ], 'Der Typ muss mindestens zu einer Tageszeit angeboten werden'));

    form.controls.feedUnit.rules!.push(requiredWhenCheckboxIsCheckedRule(
      form.controls.isUserAbleToDefineAmount,
      'Die Futter-Einheit muss angegeben werden, wenn der Reiter die Menge bestimmt'
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateFeedTypeAsManagerCommand = {
      name: formValues.name!,
      isOfferedInTheMorning: formValues.isOfferedInTheMorning!,
      isOfferedAtNoon: formValues.isOfferedAtNoon!,
      isOfferedInTheEvening: formValues.isOfferedInTheEvening!,
      isUserAbleToDefineAmount: formValues.isUserAbleToDefineAmount!,
      feedUnit: formValues.isUserAbleToDefineAmount!
        ? formValues.feedUnit!
        : null,
      isVisibleOnBoxSign: formValues.isVisibleOnBoxSign!,
    };

    this.store.createFeedType(command)
      .then(() => showSuccessMessage('Der Futtertyp wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
