
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { formatDate } from '@/helpers';
import { formatCurrency } from '@/helpers/stateful-format';
import { Service, TimeFrameType } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

@Component
export default class PersonServiceList extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  mounted(): void {
    this.store.getServicesForPerson()
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetServicesForCurrentPerson()
      .catch((error) => showErrorResponse(error));
  }

  description(service: Service): string {
    if (service.grossSellingPrice === 0) {
      return formatCurrency(0);
    }

    return service.price
      ? `${formatCurrency(service.price.grossSellingPrice)} (${formatCurrency(service.price.netSellingPrice)} + ${formatCurrency(service.price.taxAmount)})`
      : `${formatCurrency(service.grossSellingPrice)}`;
  }

  note(service: Service): string {
    if (service.timeFrameType === TimeFrameType.ONE_DAY) {
      return `Am ${formatDate(service.startingAt)}`;
    }

    return service.endingAt
      ? `Von ${formatDate(service.startingAt)} bis ${formatDate(service.endingAt)}`
      : `Seit ${formatDate(service.startingAt)}`;
  }

}
