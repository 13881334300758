
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalStringRule } from '@/components/form';
import { OrganizationId, OrganizationName } from '@/types';
import { formatName } from '@/helpers';
import { ArchiveOrganizationAsManagerWorkflow } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  name: FormControl<string>;
  reason: FormControl<string>;
}

@Component({
  methods: { formatName }
})
export default class ArchiveOrganizationDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: String, required: true })
  readonly organizationId!: OrganizationId;

  @Prop({ type: String, required: true })
  readonly name!: OrganizationName;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name der Organisation',
          value: null,
          isRequired: true,
          rules: [
            identicalStringRule(this.name, 'Muss mit dem Namen der Organisation übereinstimmen'),
          ],
        },
        reason: {
          label: 'Begründung',
          value: null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: ArchiveOrganizationAsManagerWorkflow = {
      organizationId: this.organizationId,
      organizationName: formValues.name!,
      reason: formValues.reason,
    };

    this.store.archiveOrganization(command)
      .then(() => showSuccessMessage('Die Organisation wurde archiviert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
