
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useMyStableStore } from '../store';
import { HorseVeterinarianAppointments } from '../types';
import CreateHorseVeterinarianAppointmentDialog from './create-horse-veterinarian-appointment-dialog.vue';

@Component({
  components: {
    CreateHorseVeterinarianAppointmentDialog,
  },
})
export default class HorseVeterinarianAppointmentsCard extends Vue {

  readonly store = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly horseVeterinarianAppointments!: HorseVeterinarianAppointments;

  get isCreateHorseVeterinarianAppointmentVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

}
