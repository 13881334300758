
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DataTableHeader } from 'vuetify';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission, isFeatureEnabled } from '@/application/authentication/helper';
import { BoxSignGenerationProcessForAllHorsesStatus, Feature, FarmManagerPermission } from '@/types';
import { downloadFile } from '@/helpers/file-download-helper';
import { boxSignGenerationProcessForAllHorsesStatusTranslation } from '@/helpers/translations';
import { useHorseManagementStore } from '../store';
import { BoxSignGenerationProcessForAllHorses, DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand, GetBoxSignAsManagerQuery, GetBoxSignsForAllHorsesAsManagerQuery, Horse } from '../types';
import UpdateEquineNumberDialog from '@/private/management/feed-protocol/components/update-equine-number-dialog.vue';
import ActiveHorseInformationDialog from './active-horse-information-dialog.vue';
import UpdateHorseNameDialog from './update-horse-name-dialog.vue';
import DeleteHorseDialog from './delete-horse-dialog.vue';

interface Controls extends FormControls {
  search: FormControl<string>;
}

@Component({
  components: {
    ActiveHorseInformationDialog,
    UpdateEquineNumberDialog,
    UpdateHorseNameDialog,
    DeleteHorseDialog,
  },
})
export default class HorseTable extends Vue {

  readonly store = useHorseManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name' },
    { text: 'Einsteller', value: 'owner' },
    { text: 'Reitbeteiligungen', value: 'horseShareUsers' },
    { text: 'Hufschmied', value: 'farrier' },
    { text: 'Tierarzt', value: 'veterinarian' },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 160 },
  ];

  readonly boxSignGenerationProcessesForAllHorsesTableHeaders: DataTableHeader[] = [
    { text: 'Erstellt am', value: 'createdAt' },
    { text: 'Status', value: 'status' },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 80 },
  ];

  readonly boxSignGenerationProcessForAllHorsesStatusTranslation = boxSignGenerationProcessForAllHorsesStatusTranslation;

  isCheckingForNewProcesses = false;

  form: Form<Controls> | null = null;

  get isUpdateMenuVisible(): boolean {
    return this.isUpdateHorseNameVisible
      || this.isUpdateEquineNumberVisible;
  }

  get isUpdateHorseNameVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.HORSES_WRITE);
  }

  get isUpdateEquineNumberVisible(): boolean {
    return isFeatureEnabled(Feature.FEED_PROTOCOL)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_WRITE);
  }

  get isDeleteHorseVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.HORSES_WRITE);
  }

  get search(): string | null {
    if (!this.form) {
      return null;
    }

    const formValues = getFormValues(this.form);

    return formValues.search;
  }

  mounted(): void {
    this.form = this.buildForm();

    this.store.getHorses()
      .catch((error) => showErrorResponse(error));

    setInterval(() => {
      if (this.isCheckingForNewProcesses) {
        this.store.getBoxSignGenerationProcessesForAllHorses()
          .catch((error) => showErrorResponse(error));
      }
    }, 15 * 1000);

    watch(() => this.store.boxSignGenerationProcessesForAllHorses, (boxSignGenerationProcessesForAllHorses) => {
      this.isCheckingForNewProcesses = boxSignGenerationProcessesForAllHorses
        .some((boxSignGenerationProcessForAllHorses) => boxSignGenerationProcessForAllHorses.status === BoxSignGenerationProcessForAllHorsesStatus.PLANNED
        || boxSignGenerationProcessForAllHorses.status === BoxSignGenerationProcessForAllHorsesStatus.IN_PROGRESS);
    }, { deep: true });

    this.store.getBoxSignGenerationProcessesForAllHorses()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
        },
      },
    });
  }

  filterHorses(value: any, search: string | null, horse: Horse): boolean {
    if (search === null) {
      return true;
    }

    const searchLowerCase = search.toLocaleLowerCase();

    if (horse.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }
    if (horse.nickName
      && horse.nickName.toLocaleLowerCase().includes(searchLowerCase)
    ) {
      return true;
    }
    if (horse.owner.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    return false;
  }

  equineNumberUpdated(): void {
    this.store.getHorses()
      .catch((error) => showErrorResponse(error));
  }

  generateBoxSign(horse: Horse): void {
    const fileName = `Boxenschild-${horse.name}.pdf`;

    const query: GetBoxSignAsManagerQuery = {
      horseId: horse.horseId,
    };
    this.store.getBoxSign(query)
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, fileName))
      .catch((error) => showErrorResponse(error));
  }

  async generationOfBoxSignsConfirmed(): Promise<void> {
    return this.store.generateBoxSignsForAllHorses()
      .then(() => showSuccessMessage('Boxenschilder werden generiert.'))
      .catch((error) => showErrorResponse(error));
  }

  downloadBoxSignsForAllHorses(boxSignGenerationProcessForAllHorses: BoxSignGenerationProcessForAllHorses): void {
    const query: GetBoxSignsForAllHorsesAsManagerQuery = {
      boxSignGenerationProcessForAllHorsesId: boxSignGenerationProcessForAllHorses.boxSignGenerationProcessForAllHorsesId,
    };
    this.store.getBoxSignsForAllHorses(query)
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, 'Boxenschilder.zip'))
      .catch((error) => showErrorResponse(error));
  }

  async deleteBoxSignGenerationProcessForAllHorsesConfirmed(
    boxSignGenerationProcessForAllHorses: BoxSignGenerationProcessForAllHorses
  ): Promise<void> {
    const command: DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand = {
      boxSignGenerationProcessForAllHorsesId: boxSignGenerationProcessForAllHorses.boxSignGenerationProcessForAllHorsesId,
    };
    return this.store.deleteBoxSignGenerationProcessForAllHorses(command)
      .then(() => showSuccessMessage('Boxenschilder wurden gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  isDownloadVisible(boxSignGenerationProcessForAllHorses: BoxSignGenerationProcessForAllHorses): boolean {
    return boxSignGenerationProcessForAllHorses.status === BoxSignGenerationProcessForAllHorsesStatus.COMPLETED;
  }

  isDeleteDisabled(boxSignGenerationProcessForAllHorses: BoxSignGenerationProcessForAllHorses): boolean {
    return boxSignGenerationProcessForAllHorses.status === BoxSignGenerationProcessForAllHorsesStatus.IN_PROGRESS;
  }

}
