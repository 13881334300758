
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, FormControl, Form, getFormValues, maxLengthRule, FormControls } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { useFacilityManagementStore } from '../store';
import { Facility, UpdateNameOfFacilityAsManagerCommand } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
}

@Component
export default class UpdateNameOfFacilityDialog extends Vue {

  readonly store = useFacilityManagementStore();

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: this.facility.name,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateNameOfFacilityAsManagerCommand = {
      facilityId: this.facility.facilityId,
      name: formValues.name!,
    };

    this.store.updateNameOfFacility(command)
      .then(() => showSuccessMessage('Der Name wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
