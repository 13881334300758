
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { ActionStatus } from '@/application/types';
import { DataListItem } from '@/components/design-system';
import { formatBoolean } from '@/helpers';
import { useRidingLessonManagementStore } from '../store';
import { Facility } from '../types';
import SetUpRidingLessonsForFacilityDialog from './set-up-riding-lessons-for-facility-dialog.vue';
import UpdateRidingLessonRegistrationRestrictionsDialog from './update-riding-lesson-registration-restrictions-for-facility-dialog.vue';
import RidingLessonTypes from './riding-lesson-types.vue';
import CreateRidingLessonConfigurationDialog from './create-riding-lesson-configuration-dialog.vue';
import UpdateRidingLessonConfigurationDialog from './update-riding-lesson-configuration-dialog.vue';

@Component({
  components: {
    SetUpRidingLessonsForFacilityDialog,
    UpdateRidingLessonRegistrationRestrictionsDialog,
    RidingLessonTypes,
    CreateRidingLessonConfigurationDialog,
    UpdateRidingLessonConfigurationDialog,
  },
})
export default class RidingLessonConfiguration extends Vue {

  readonly store = useRidingLessonManagementStore();

  get configurationForRidingLessonsDataItems(): DataListItem[] {
    return [
      {
        key: 'isRidingLessonPlanVisible',
        label: 'Extra Kalender für Reitunterricht anzeigen',
        value: formatBoolean(this.store.configurationForRidingLessons!.isRidingLessonPlanVisible),
      },
      {
        key: 'idsOfHorsesAvailableForAssignment',
        label: 'Anzahl der aktivierten Pferde für die Zuordnung',
        value: this.store.configurationForRidingLessons!.idsOfHorsesAvailableForAssignment.length.toString(),
      },
      {
        key: 'areHorsesFilteredByRidingLessonType',
        label: 'Pferde nur bestimmten Unterrichtsarten zuordnen',
        value: formatBoolean(this.store.configurationForRidingLessons!.areHorsesFilteredByRidingLessonType),
      },
      {
        key: 'isRidingInstructorShownOnPlan',
        label: 'Reitlehrer wird auf dem Kalender angezeigt',
        value: formatBoolean(this.store.configurationForRidingLessons!.isRidingInstructorShownOnPlan),
      },
      {
        key: 'isWaitingListAvailable',
        label: 'Eine Warteliste wird angeboten',
        value: formatBoolean(this.store.configurationForRidingLessons!.isWaitingListAvailable),
      },
      {
        key: 'waitingListNotice',
        label: 'Hinweis bei Erreichen der Teilnehmergrenze',
        value: this.store.configurationForRidingLessons!.waitingListNotice ?? '-',
      },
    ];
  }

  get isNoConfigurationVisible(): boolean {
    return this.store.getConfigurationStatus === ActionStatus.Successful
      && this.store.configurationForRidingLessons === null;
  }

  get isCreateRidingLessonConfigurationVisible(): boolean {
    return this.store.configurationForRidingLessons === null
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION);
  }

  get isUpdateRidingLessonConfigurationVisible(): boolean {
    return this.store.configurationForRidingLessons !== null
      && this.store.facilities.length > 0
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION);
  }

  get isSetUpRidingLessonsForFacilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION);
  }

  get isUpdateRidingLessonRegistrationRestrictionsForFacilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_UPDATE_CONFIGURATION);
  }

  mounted(): void {
    this.store.getConfiguration()
      .catch((error) => showErrorResponse(error));
  }

  ridingLessonConfiguration(facility: Facility): DataListItem[] {
    return [
      {
        key: 'isRegistrationForOneselfAllowed',
        label: 'Anmeldung für sich selbst möglich',
        value: formatBoolean(facility.ridingLessonConfiguration!.isRegistrationForOneselfAllowed),
      },
      {
        key: 'isRegistrationOnBehalfOfAnotherPersonAllowed',
        label: 'Anmeldung im Namen einer anderen Person möglich',
        value: formatBoolean(facility.ridingLessonConfiguration!.isRegistrationOnBehalfOfAnotherPersonAllowed),
      },
      {
        key: 'explanationForCommentFieldForRegistration',
        label: 'Erklärung für das Kommentarfeld bei der Anmeldung',
        value: facility.ridingLessonConfiguration!.explanationForCommentFieldForRegistration ?? '-',
      },
    ];
  }

}
