
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { Location } from 'vue-router';
import { Feature } from '@/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { isFeatureEnabled } from '@/application/authentication/helper';
import { VuetifySelectItem } from '@/application/types';
import { useBookingCalendarStore } from '@/private/rider/booking-calendars/store';

@Component
export default class BookingCalendars extends Vue {

  readonly store = useBookingCalendarStore();
  readonly authenticationStore = useAuthenticationStore();

  get areFacilityCalendarsVisible(): boolean {
    return (
      isFeatureEnabled(Feature.FACILITY_RESERVATIONS)
      || isFeatureEnabled(Feature.RIDING_LESSONS)
    ) && this.authenticationStore.facilities.length > 0;
  }

  get isRidingLessonPlanVisible(): boolean {
    return isFeatureEnabled(Feature.RIDING_LESSONS)
      && (this.authenticationStore.user?.farm?.isRidingLessonPlanVisible ?? false);
  }

  get isFreeJumpingFreeRunningVisible(): boolean {
    return isFeatureEnabled(Feature.FREE_JUMPING_FREE_RUNNING);
  }

  get isNavigationVisible(): boolean {
    return this.navigationItems.length > 1;
  }

  get navigationItems(): (VuetifySelectItem<Location> & { key: string })[] {
    const items: (VuetifySelectItem<Location> & { key: string })[] = [];
    if (this.areFacilityCalendarsVisible) {
      items.push(...this.authenticationStore.facilities.map((facility) => ({
        text: facility.name,
        value: {
          name: 'booking-calendars/facility-calendar',
          params: { id: facility.facilityId },
        },
        key: facility.facilityId,
      })));
    }

    if (this.isRidingLessonPlanVisible) {
      items.push({
        text: 'Reitunterricht',
        value: { name: 'booking-calendars/riding-lesson-plan' },
        key: 'booking-calendars/riding-lesson-plan',
      });
    }

    if (this.isFreeJumpingFreeRunningVisible) {
      items.push({
        text: 'Freispringen / Freilaufen',
        value: { name: 'booking-calendars/free-jumping-free-running' },
        key: 'booking-calendars/free-jumping-free-running',
      });
    }

    return items;
  }

  mounted(): void {
    watch(() => this.$route, (route) => {
      if (route.name === 'booking-calendars/facility-calendar'
        || route.name === 'booking-calendars/facility-calendar-riding-lesson'
      ) {
        this.store.updateCurrentFacility(this.$route.params.id)
          .catch((error) => showErrorResponse(error));
      }
    }, { immediate: true, deep: true });
  }

}
