
import { Component, Vue } from 'vue-property-decorator';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { formatBoolean, formatTimeFrame } from '@/helpers';
import { showErrorResponse } from '@/application/snackbar/service';
import { DataListItem } from '@/components/design-system';
import { useFacilityManagementStore } from '../store';
import { DeleteFacilityAsManagerCommand } from '../types';
import UpdateNameOfFacilityDialog from './update-name-of-facility-dialog.vue';
import UpdateSpacesOfFacilityDialog from './update-spaces-of-facility-dialog.vue';
import UpdateOpeningHoursOfFacilityDialog from './update-opening-hours-of-facility-dialog.vue';
import UpdateVisibilityOfFacilityDialog from './update-visibility-of-facility-dialog.vue';

@Component({
  components: {
    UpdateNameOfFacilityDialog,
    UpdateSpacesOfFacilityDialog,
    UpdateOpeningHoursOfFacilityDialog,
    UpdateVisibilityOfFacilityDialog,
  },
})
export default class FacilityConfiguration extends Vue {

  readonly store = useFacilityManagementStore();

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'name',
        label: 'Name',
        value: this.store.currentFacility!.name,
      },
      {
        key: 'openingHours',
        label: 'Öffnungszeiten',
        value: formatTimeFrame(this.store.currentFacility!.openingHours),
      },
      {
        key: 'spaces',
        label: 'Plätze',
        value: this.store.currentFacility!.spaces.toString(),
      },
      {
        key: 'isHidden',
        label: 'Ausgeblendet',
        value: formatBoolean(this.store.currentFacility!.isHidden),
      },
    ];
  }

  get isUpdateNameOfFacilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  get isUpdateFacilityBookingTimeRangeVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  get isUpdateSpacesOfFacilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  get isUpdateFacilityVisibilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get isDeleteFacilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  async deleteFacilityAction(): Promise<any> {
    const command: DeleteFacilityAsManagerCommand = {
      facilityId: this.store.currentFacilityId!,
    };

    return this.store.deleteFacility(command)
      .then(() => this.$router.push({ name: 'facility-management' }))
      .catch((error) => showErrorResponse(error));
  }

}
