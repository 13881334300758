
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorMessage, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useEventManagementStore } from '../store';
import { EventEntry } from '../types';

@Component
export default class CopyExternalEventLinkDialog extends Vue {

  readonly store = useEventManagementStore();

  readonly size = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly event!: EventEntry;

  isDialogVisible = false;

  copyLink(): void {
    navigator.clipboard.writeText(this.event.externalLink!)
      .then(() => showSuccessMessage('Link wurde in die Zwischenablage kopiert.'))
      .then(() => this.closeDialog())
      .catch(() => showErrorMessage('Link konnte auf deinem Gerät nicht in die Zwischenablage kopiert werden. Bitte kopiere den Link manuell.'));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
