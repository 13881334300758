
import { Component, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { showErrorResponse } from '@/application/snackbar/service';
import { usePinboardStore } from '../store';
import { NewsEntry } from '../types';

@Component
export default class NewsList extends Vue {

  readonly store = usePinboardStore();

  mounted(): void {
    this.store.getNewsEntries()
      .catch((error) => showErrorResponse(error));
  }

  showNewsEntry(newsEntry: NewsEntry): void {
    navigate({ name: 'news-details', params: { newsEntryId: newsEntry.newsEntryId } });
  }

}
