
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { Location } from 'vue-router';
import { useAppStore } from '@/application/app/store';
import { showErrorResponse } from '@/application/snackbar/service';
import { useFacilityManagementStore } from '../store';

@Component
export default class CurrentFacilityTabs extends Vue {

  readonly store = useFacilityManagementStore();
  readonly appStore = useAppStore();

  get facilityBlockersRoute(): Location {
    return { name: 'facility-management-current-facility-blockers', params: { id: this.$route.params.id } };
  }

  get configurationRoute(): Location {
    return { name: 'facility-management-current-facility-configuration', params: { id: this.$route.params.id } };
  }

  async beforeMount(): Promise<void> {
    await this.store.updateCurrentFacility(this.$route.params.id)
      .catch((error) => showErrorResponse(error));
  }

  mounted(): void {
    watch(() => this.store.currentFacility, () => {
      this.appStore.updateTitle(this.store.currentFacility?.name || null)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });

    watch(() => this.$route.path, () => {
      this.appStore.updateTitle(this.store.currentFacility?.name || null)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });
  }

}
