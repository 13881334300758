
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { navigate } from '@/helpers/navigation-helpers';
import { clearDomainStates } from '@/helpers/store-reset-helper';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { redirectRouteDependingOnAuthentication } from '@/application/authentication/helper';
import { useDashboardStore } from '@/private/rider/dashboard/store';
import { constructForm, Form, FormControl, FormControls, FormControlValue } from '@/components/form';
import { FarmId, sortDirection, UserRole } from '@/types';
import { userRoleTranslation } from '@/helpers/translations';
import { GetUserForImpersonationAsAdminQuery, SortBy, User } from '../types';
import { useGlobalUserManagementStore } from '../store';
import UpdateUserPasswordDialog from './update-user-password-dialog.vue';
import UpdateUserRoleAndPermissionsDialog from './update-user-role-and-permissions-dialog.vue';

interface Controls extends FormControls {
  filterByFarm: FormControl<FarmId>;
  search: FormControl<string>;
}

@Component({
  components: {
    UpdateUserPasswordDialog,
    UpdateUserRoleAndPermissionsDialog,
  },
})
export default class GlobalUserManagement extends Vue {

  readonly store = useGlobalUserManagementStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly myStableStore = useMyStableStore();
  readonly dashboardStore = useDashboardStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Hof', value: 'farm', sortable: false },
    { text: 'Rolle', value: 'role', sortable: true },
    { text: 'Name', value: 'name', width: 200, sortable: true },
    { text: 'E-Mail-Adresse', value: 'emailAddress', sortable: false },
    { text: 'Erstellt am', value: 'createdAt', sortable: true },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 190, sortable: false },
  ];

  readonly userRoleTranslation = userRoleTranslation;

  form: Form<Controls> | null = null;

  dataTableOptions: DataOptions | null = null;

  get isLoadMoreDisabled(): boolean {
    return this.store.totalCount === null
      || this.store.users.length >= this.store.totalCount;
  }

  mounted(): void {
    const initialFarmId = this.$route.params.farmId ?? null;

    if (initialFarmId) {
      this.store.updateFarmFilter(initialFarmId)
        .catch((error) => showErrorResponse(error));
    } else {
      this.store.getGlobalUsers()
        .catch((error) => showErrorResponse(error));
    }

    this.form = this.buildForm(initialFarmId);

    this.store.getFarms()
      .catch((error) => showErrorResponse(error));

    watch(() => this.dataTableOptions, (dataTableOptions) => {
      if (!dataTableOptions
        || dataTableOptions.sortBy.length === 0
        || dataTableOptions.sortDesc.length === 0
      ) {
        return;
      }

      // name and createdAt are the only sortable fields
      let sortBy = SortBy.ROLE;
      switch (dataTableOptions.sortBy[0]) {
        case 'role':
          sortBy = SortBy.ROLE;
          break;
        case 'name':
          sortBy = SortBy.NAME;
          break;
        case 'createdAt':
          sortBy = SortBy.CREATED_AT;
          break;
      }

      this.store.updateSorting(sortBy, sortDirection(dataTableOptions.sortDesc[0]))
        .catch((error) => showErrorResponse(error));

    }, { deep: true });
  }

  destroyed(): void {
    this.store.resetFilters()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(initialFarmId: FarmId | null): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        filterByFarm: {
          label: 'Hof',
          value: initialFarmId,
          afterTransformationAndValidation: (value: FormControlValue<FarmId>) => {
            this.store.updateFarmFilter(value)
              .catch((error) => showErrorResponse(error));
          },
        },
        search: {
          label: 'Suche',
          value: null,
          afterTransformationAndValidation: (value: FormControlValue<string>) => {
            this.store.updateSearchTerm(value)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  isImpersonationDisabled(user: User): boolean {
    return !user.isEnabled;
  }

  async impersonateUserAction(user: User): Promise<void> {
    const query: GetUserForImpersonationAsAdminQuery = {
      targetUserId: user.userId,
    };

    return this.store.getUserForImpersonationAsAdmin(query)
      .then(() => clearDomainStates())
      .then(() => this.authenticationStore.getAuthentication())
      .then(() => this.myStableStore.getAvailableHorses())
      .then(() => this.dashboardStore.getNotificationStatus())
      .then(() => showSuccessMessage('Das Profil wurde temporär übernommen.'))
      .then(() => this.redirectToHome())
      .catch((error) => showErrorResponse(error));
  }

  redirectToHome(): void {
    navigate(redirectRouteDependingOnAuthentication());
  }

  roleIcon(user: User): string {
    return user.role === UserRole.ROLE_FARM_MANAGER
      ? 'farm'
      : 'user';
  }

  onLoadMoreClicked(): void {
    this.store.increaseLimit()
      .catch((error) => showErrorResponse(error));
  }

}
