
import { Component, Prop, Vue } from 'vue-property-decorator';
import CancelFacilityReservationDialog from '@/private/rider/booking-calendars/components/cancel-facility-reservation-dialog.vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { navigate } from '@/helpers/navigation-helpers';
import { Moment } from '@/types';
import { moment } from '@/helpers';
import { useDashboardStore } from '../store';
import { FacilityReservation } from '../types';

@Component({
  components: {
    CancelFacilityReservationDialog,
  },
})
export default class FacilityReservationCard extends Vue {

  readonly store = useDashboardStore();

  @Prop({ type: Object, required: true })
  readonly facilityReservation!: FacilityReservation;

  isUpdateMenuVisible = false;

  facilityReservationCanceled(): void {
    this.store.getNextAppointments()
      .catch((error) => showErrorResponse(error));
  }

  dayLabel(from: Moment): string {
    const now = moment();
    if (now.isSame(from, 'day')) {
      return 'Heute';
    }

    if (now.add(1, 'day').isSame(from, 'day')) {
      return 'Morgen';
    }

    if (now.add(2, 'day').isSame(from, 'day')) {
      return 'Übermorgen';
    }

    return from.format('DD.MM');
  }

  showInCalendarClicked(facilityReservation: FacilityReservation): void {
    navigate({
      name: 'booking-calendars/facility-calendar',
      params: {
        id: facilityReservation.facilityId,
        date: facilityReservation.timeFrame.momentFrom.format('YYYY-MM-DD'),
        facilityReservationId: facilityReservation.facilityReservationId,
        action: 'view',
      },
    });
  }

  updateInCalendarClicked(facilityReservation: FacilityReservation): void {
    navigate({
      name: 'booking-calendars/facility-calendar',
      params: {
        id: facilityReservation.facilityId,
        date: facilityReservation.timeFrame.momentFrom.format('YYYY-MM-DD'),
        facilityReservationId: facilityReservation.facilityReservationId,
        action: 'update',
      },
    });
  }

  hideFacilityReservationMenu(): void {
    this.isUpdateMenuVisible = false;
  }

}
