
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { HorseId, UserId, FarmManagerPermission } from '@/types';
import RecentTasksList from './recent-tasks-list.vue';
import OutdatedTasksList from './outdated-tasks-list.vue';
import CreateTaskDialog from './create-task-dialog.vue';
import { useFarmTaskManagementStore } from '../store';

interface RecentControls extends FormControls {
  user: FormControl<UserId>;
  horse: FormControl<HorseId>;
  search: FormControl<string>;
}

interface OutdatedControls extends FormControls {
  user: FormControl<UserId>;
  horse: FormControl<HorseId>;
  search: FormControl<string>;
}

@Component({
  components: {
    CreateTaskDialog,
    RecentTasksList,
    OutdatedTasksList,
  },
})
export default class TasksGroupedByRecencyTab extends Vue {

  readonly store = useFarmTaskManagementStore();

  get isCreateTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  recentForm: Form<RecentControls> | null = null;
  outdatedForm: Form<OutdatedControls> | null = null;

  mounted(): void {
    this.store.getRecentTasks()
      .catch((error) => showErrorResponse(error));

    this.store.getOutdatedTasks()
      .catch((error) => showErrorResponse(error));

    this.recentForm = this.buildRecentForm();
    this.outdatedForm = this.buildOutdatedForm();
  }

  buildRecentForm(): Form<RecentControls> {
    return constructForm<RecentControls>({
      submitted: () => {},
      controls: {
        user: {
          label: 'Zugewiesener Verwalter',
          value: null,
          afterTransformationAndValidation: (horse) => {
            this.store.updateUserForRecentTasks(horse)
              .catch((error) => showErrorResponse(error));
          },
        },
        horse: {
          label: 'Pferd',
          value: null,
          afterTransformationAndValidation: (horse) => {
            this.store.updateHorseForRecentTasks(horse)
              .catch((error) => showErrorResponse(error));
          },
        },
        search: {
          label: 'Suche',
          value: null,
          afterTransformationAndValidation: (search) => {
            this.store.updateSearchForRecentTasks(search!)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  buildOutdatedForm(): Form<OutdatedControls> {
    return constructForm<OutdatedControls>({
      submitted: () => {},
      controls: {
        user: {
          label: 'Zugewiesener Verwalter',
          value: null,
          afterTransformationAndValidation: (horse) => {
            this.store.updateUserForOutdatedTasks(horse)
              .catch((error) => showErrorResponse(error));
          },
        },
        horse: {
          label: 'Pferd',
          value: null,
          afterTransformationAndValidation: (horse) => {
            this.store.updateHorseForOutdatedTasks(horse)
              .catch((error) => showErrorResponse(error));
          },
        },
        search: {
          label: 'Suche',
          value: null,
          afterTransformationAndValidation: (search) => {
            this.store.updateSearchForOutdatedTasks(search!)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  taskCreated(): void {
    this.store.getRecentTasks()
      .catch((error) => showErrorResponse(error));
  }

}
