
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, requiredWhenControlIsFilledRule } from '@/components/form';
import { Address, InvoiceAddressForPerson, Name, PersonId, Salutation } from '@/types';
import { DefineInvoiceAddressOfPersonAsManagerWorkflow, RemoveInvoiceAddressOfPersonAsManagerWorkflow, UpdateInvoiceAddressOfPersonAsManagerWorkflow } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  company: FormControl<string>;
  salutation: FormControl<Salutation>;
  name: FormControl<Name>;
  address: FormControl<Address>;
}

@Component
export default class UpdateInvoiceAddressOfPersonDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: String, required: true })
  readonly personId!: PersonId;

  @Prop({ type: Object, default: null })
  readonly invoiceAddress!: InvoiceAddressForPerson | null;

  @Prop({ type: Boolean, required: true })
  readonly isConnectedToUser!: boolean;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        company: {
          label: 'Firmenname',
          value: this.invoiceAddress?.company ?? null,
          validateFormControlsOnInput: [
            'name',
            'address',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'name',
            'address',
          ],
        },
        salutation: {
          label: 'Anrede',
          value: this.invoiceAddress?.salutation ?? null,
          rules: [],
          validateFormControlsOnInput: [
            'name',
            'address',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'name',
            'address',
          ],
        },
        name: {
          label: 'Name',
          value: this.invoiceAddress?.name ?? null,
          rules: [],
          validateFormControlsOnInput: [
            'address',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'address',
          ],
        },
        address: {
          label: 'Adresse',
          value: this.invoiceAddress?.address ?? null,
          rules: [],
          validateFormControlsOnInput: [
            'name',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'name',
          ],
        },
      },
    });

    form.controls.name.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.company,
      'Der Name muss angegeben werden, wenn die Firma gefüllt ist.',
    ));
    form.controls.name.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.salutation,
      'Der Name muss angegeben werden, wenn die Anrede gefüllt ist.',
    ));
    form.controls.name.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.address,
      'Der Name muss angegeben werden, wenn die Adresse gefüllt ist.',
    ));

    form.controls.address.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.company,
      'Die Adresse muss angegeben werden, wenn die Firma gefüllt ist.',
    ));
    form.controls.address.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.salutation,
      'Die Adresse muss angegeben werden, wenn die Anrede gefüllt ist.',
    ));
    form.controls.address.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.name,
      'Die Adresse muss angegeben werden, wenn der Name gefüllt ist.',
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    if (this.invoiceAddress === null
      && formValues.name !== null
      && formValues.address !== null
    ) {
      const command: DefineInvoiceAddressOfPersonAsManagerWorkflow = {
        personId: this.personId,
        invoiceAddress: {
          company: formValues.company,
          salutation: formValues.salutation,
          name: formValues.name,
          address: formValues.address,
        }
      };

      this.store.defineInvoiceAddressOfPerson(command)
        .then(() => showSuccessMessage('Die Rechnungsadresse wurde angepasst.'))
        .then(() => this.$emit('person-updated'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));
    } else if (this.invoiceAddress !== null
      && formValues.name !== null
      && formValues.address !== null
    ) {
      const command: UpdateInvoiceAddressOfPersonAsManagerWorkflow = {
        personId: this.personId,
        invoiceAddress: {
          company: formValues.company,
          salutation: formValues.salutation,
          name: formValues.name,
          address: formValues.address,
        }
      };

      this.store.updateInvoiceAddressOfPerson(command)
        .then(() => showSuccessMessage('Die Rechnungsadresse wurde angepasst.'))
        .then(() => this.$emit('person-updated'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));
    } else {
      const command: RemoveInvoiceAddressOfPersonAsManagerWorkflow = {
        personId: this.personId,
      };

      this.store.removeInvoiceAddressOfPerson(command)
        .then(() => showSuccessMessage('Die Rechnungsadresse wurde angepasst.'))
        .then(() => this.$emit('person-updated'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));
    }
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
