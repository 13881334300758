import range from 'lodash-es/range';
import { FormField } from '@/application/types';
import { Time } from '@/types';
import { formatNumber } from '@/helpers/stateful-format';

export function requiredRule(message = 'Dieses Feld muss gefüllt sein'): (value: any) => true|string {
  return (value) => (
    // As soon as all input elements are refactored, we can simply check for value !== null and remove the rest
    value !== null
    && (
      typeof value === 'number'
      || (
        typeof value !== 'string'
        && !!value
      )
      || (
        typeof value === 'string'
        && !!value.trim()
      )
    )
  ) || message;
}

export function requiredWhenTextFieldIsFilled(
  field: FormField<string|null>,
  message = 'Dieses Feld muss gefüllt sein'
): (value: string|null) => true|string {
  return (value) => ((
    field.value === null
    || value !== null
  )) || message;
}

export function passwordRule(message = 'Das Passwort muss mindestens 8 Zeichen lang sein'): (value: string|null) => true|string {
  return (value) => (value === null || value.length >= 8) || message;
}

export function numberStringRule(message = 'Der Wert muss eine gültige Zahl sein'): (value: any) => true|string {
  return (value) => {
    if (!value) {
      return true;
    }

    const regex = /^-?(\d)+([.\d])*(,\d*)?$/;

    if (regex.test(value)) {
      return true;
    }

    return message;
  };
}

export function positiveNumberRule(decimalPlaces = 0, customMessage?: string): (value: any) => true|string {
  let regex = /^\d+$/;
  let message = customMessage && customMessage || 'Die Wert darf keine Nachkommastellen enthalten';

  if (decimalPlaces > 0) {
    // eslint-disable-next-line prefer-regex-literals
    regex = new RegExp(`^\\d+(\\.\\d{${range(1, decimalPlaces + 1).join(',')}})?$`);
    message = customMessage && customMessage || `Die Wert darf maximal ${decimalPlaces} Nachkommastellen enthalten`;
  }

  return (value) => (!value || regex.test(parseFloat(value).toString())) || message;
}

export function alphanumericRule(customMessage?: string): (value: any) => true|string {
  const message = customMessage || `Der Wert darf nur Buchstaben und Zahlen enthalten`;
  const regex = /^[a-zA-Z\d]*$/;
  return (value) => (!value || regex.test(value)) || message;
}

export function minNumberRule(min: number, customMessage?: string): (value: number|null) => true|string {
  const message = customMessage || `Die Zahl darf nicht kleiner als ${formatNumber(min)} sein`;
  return (value) => (value === null || value >= min) || message;
}

export function maxNumberRule(max: number, customMessage?: string): (value: any) => true|string {
  const message = customMessage && customMessage || `Die Zahl darf nicht größer als ${max} sein`;
  return (value) => (!value || parseFloat(value) <= max) || message;
}

export function maxLengthRule(max: number, customMessage?: string): (value: any) => true|string {
  const message = customMessage && customMessage || `Der Wert darf aus maximal ${max} Zeichen bestehen`;
  return (value) => (!value || value.length <= max) || message;
}

export function notEmptyArrayRule(message = 'Es muss mindestens ein Element ausgewählt sein'): (value: any) => true|string {
  return (value) => (!value || Array.isArray(value) && value.length > 0) || message;
}

export function passwordMustBeIdenticalToTextInFieldRule(
  formField: FormField<string|null>,
  message = 'Die Passwörter sind nicht identisch'
): (value: string|null) => true|string {
  return (value) => (formField.value === value) || message;
}

export function textToCompareFieldRule(text: string, message = 'Der Text müssen identisch sein'): (value: string|null) => true|string {
  return (value) => (value === null || value === text) || message;
}

export function csvCompliantRule(message = 'Darf keines der folgenden Zeichen enthalten: \' " , ;'): (value: any) => true|string {
  return (value) => (!value || !value.match('[,;"\']')) || message;
}

export function notMidnightRule(message: string = 'Mitternacht ist nicht erlaubt'): (value: Time) => true|string {
  return (value) => !value.isMidnight || message;
}
