
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActionStatus } from '@/application/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { RidingLessonRegistrationType, FarmManagerPermission } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { useRidingLessonManagementStore } from '../store';
import { midnightSevenDaysAgo } from '../helper';
import { AssignHorseToRidingLessonRegistrationAsManagerCommand, DeleteRidingLessonRegistrationAsManagerCommand, RemoveHorseFromRidingLessonRegistrationAsManagerCommand, RidingLesson, RidingLessonRegistration } from '../types';
import UpdateNameOfAnotherPersonOfRidingLessonRegistrationDialog from './update-name-of-another-person-of-riding-lesson-registration-dialog.vue';
import UpdateCommentOfRidingLessonRegistrationDialog from './update-comment-of-riding-lesson-registration-dialog.vue';
import MoveRidingLessonRegistrationToDifferentRidingLessonDialog from './move-riding-lesson-registration-to-different-riding-lesson-dialog.vue';

@Component({
  components: {
    UpdateNameOfAnotherPersonOfRidingLessonRegistrationDialog,
    UpdateCommentOfRidingLessonRegistrationDialog,
    MoveRidingLessonRegistrationToDifferentRidingLessonDialog,
  },
})
export default class RidingLessonRegistrationCard extends Vue {

  readonly store = useRidingLessonManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly ridingLesson!: RidingLesson;

  @Prop({ type: Object, required: true })
  readonly ridingLessonRegistration!: RidingLessonRegistration;

  @Prop({ type: Number, required: true })
  readonly ridingLessonRegistrationIndex!: number;

  get isHorseSelected(): boolean {
    return !!this.store.idOfSelectedHorse;
  }

  get noDataText(): string {
    return this.store.getRidingLessonsStatus === ActionStatus.Failed
      ? 'Die Unterrichtsstunden konnten nicht geladen werden.'
      : 'Du hast noch keine Unterrichtsstunde für diesen Tag erstellt.';
  }

  get isHorseWithoutInteractionVisible(): boolean {
    return this.isHorseSelected
      || this.ridingLesson.from.isBefore(midnightSevenDaysAgo())
      || !this.authenticationStore.doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_ASSIGN_HORSES);
  }

  get nameOnRidingLessonRegistration(): string {
    return this.ridingLessonRegistration.type === RidingLessonRegistrationType.USER
      ? this.ridingLessonRegistration.user!.name
      : this.ridingLessonRegistration.nameOfAnotherPerson!;
  }

  get areRegistrationActionsVisible(): boolean {
    return this.isUpdateNameOfAnotherPersonOfRidingLessonRegistrationVisible
      || this.isMoveRidingLessonRegistrationToDifferentRidingLessonVisible
      || this.isDeleteRidingLessonRegistrationVisible;
  }

  get isUpdateNameOfAnotherPersonOfRidingLessonRegistrationVisible(): boolean {
    return !this.isHorseSelected
      && this.ridingLessonRegistration.type === RidingLessonRegistrationType.IN_NAME_OF_ANOTHER_PERSON
      && this.ridingLesson.from.isSameOrAfter(midnightSevenDaysAgo())
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE);
  }

  get isUpdateCommentOfRidingLessonRegistrationVisible(): boolean {
    return !this.isHorseSelected
      && this.ridingLesson.from.isSameOrAfter(midnightSevenDaysAgo())
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE);
  }

  get isMoveRidingLessonRegistrationToDifferentRidingLessonVisible(): boolean {
    return !this.isHorseSelected
      && this.ridingLesson.from.isSameOrAfter(midnightSevenDaysAgo())
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE);
  }

  get isDeleteRidingLessonRegistrationVisible(): boolean {
    return !this.isHorseSelected
      && this.ridingLesson.from.isSameOrAfter(midnightSevenDaysAgo())
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE);
  }

  get areActionsVisible(): boolean {
    return this.isHorseSelected
      || this.areRegistrationActionsVisible;
  }

  get isRegistrationOnWaitingList(): boolean {
    return this.ridingLesson.maxAmountOfParticipants !== null
      && this.ridingLessonRegistrationIndex + 1 > this.ridingLesson.maxAmountOfParticipants;
  }

  get doesRegistrationHaveDuplicateHorse(): boolean {
    return !!this.ridingLessonRegistration.horse
      && this.ridingLesson.horsesWithDuplicateAssignments.includes(this.ridingLessonRegistration.horse.horseId);
  }

  async deleteRidingLessonRegistrationAction(): Promise<void> {
    const command: DeleteRidingLessonRegistrationAsManagerCommand = {
      ridingLessonRegistrationId: this.ridingLessonRegistration.ridingLessonRegistrationId,
    };

    return this.store.deleteRidingLessonRegistration(command)
      .then(() => showSuccessMessage('Anmeldung wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

  assignHorseToRidingLessonRegistrationConfirmed(): void {
    if (!this.store.idOfSelectedHorse) {
      throw new Error('No horse selected');
    }

    const command: AssignHorseToRidingLessonRegistrationAsManagerCommand = {
      ridingLessonRegistrationId: this.ridingLessonRegistration.ridingLessonRegistrationId,
      horseId: this.store.idOfSelectedHorse,
    };
    this.store.assignHorseToRidingLessonRegistration(command)
      .catch((error) => showErrorResponse(error));

    this.store.idOfSelectedHorse = null;
  }

  removeHorseFromRidingLessonRegistrationAction(): Promise<void> {
    const command: RemoveHorseFromRidingLessonRegistrationAsManagerCommand = {
      ridingLessonRegistrationId: this.ridingLessonRegistration.ridingLessonRegistrationId,
    };

    return this.store.removeHorseFromRidingLessonRegistration(command)
      .catch((error) => showErrorResponse(error));
  }

}
