
import { Component, Vue } from 'vue-property-decorator';
import safeAreaInsets from 'safe-area-insets';
import { MANAGEMENT_ROUTE_PREFIX } from '@/router/routes';
import { LEGAL_ROUTE_PREFIX } from '@/application/legal/routes';
import { MY_STABLE_ROUTE_PREFIX } from '@/private/rider/my-stable/routes';
import { useAuthenticationStore } from '@/application/authentication/store';
import { doesAuthenticatedUserHavePermission, isBookingCalendarsVisible, isFeatureEnabled, isFeatureVisibleForRiders, isPinboardVisible } from '@/application/authentication/helper';
import { useAppStore } from '@/application/app/store';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { apiUrl } from '@/infrastructure/api-url-service';
import { getMobileAppVersion } from '@/application/app-device-info/device-information';
import { Feature, FeatureVisibleForRiders, FarmManagerPermission } from '@/types';
import { useDashboardStore } from '@/private/rider/dashboard/store';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import AppTitle from './app-title.vue';
import { ManagementRoute } from '../types';

@Component({
  components: {
    AppTitle,
  },
})
export default class AppHeader extends Vue {

  readonly dashboardStore = useDashboardStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly myStableStore = useMyStableStore();
  readonly appStore = useAppStore();

  readonly isNativeApplication = isNativeApplication;

  toolbarHeight = 60;
  navigationDrawerWidth = 230;
  isNavigationDrawerVisible = false;

  get logoPath(): string {
    if (!this.authenticationStore.user?.farm?.logoHash) {
      const defaultLogoVersion = 1;
      return `${apiUrl}/api/unauthorized/farm-management/get-default-farm-logo-query?${defaultLogoVersion}`;
    }

    // eslint-disable-next-line max-len
    return `${apiUrl}/api/unauthorized/farm-management/get-farm-logo-query/${this.authenticationStore.user.farm.alias}?${this.authenticationStore.user.farm.logoHash}`;
  }

  get farmAlias(): string {
    return this.authenticationStore.user?.farm?.alias || '';
  }

  get isMenuDrawerDisabled(): boolean {
    return !this.authenticationStore.wasInitialAuthenticationAttempted;
  }

  get isAdminMenuVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isAdmin;
  }

  get isLoginVisible(): boolean {
    return !this.authenticationStore.isAuthenticated;
  }

  get isUserAndFarmManagerNavigationVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin;
  }

  get isControlPanelVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager;
  }

  get isAnalyticsVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.ANALYTICS_VIEW);
  }

  get isFeedingPlanVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && isFeatureVisibleForRiders(FeatureVisibleForRiders.GERBLHOF_FEEDING);
  }

  get isFarmServiceOverviewVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && isFeatureVisibleForRiders(FeatureVisibleForRiders.FARM_SERVICES)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_SERVICES_VIEW_OVERVIEW);
  }

  get isDashboardVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin;
  }

  get isPinboardVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin
      && isPinboardVisible();
  }

  get isBookingCalendarsVisible(): boolean {
    return isBookingCalendarsVisible();
  }

  get isMyStableVisible(): boolean {
    return this.isHorsesVisible
      || this.isTaskListVisible
      || this.isLaborServiceVisible
      || this.isDocumentsVisible
      || this.isSettlementVisible
      || this.isInvoicingVisible;
  }

  get isHorsesVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin
      && this.authenticationStore.user!.isHorsesViewVisible;
  }

  get isSettlementVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.myStableStore.isHorseOwner
      && isFeatureEnabled(Feature.LEDGER);
  }

  get isInvoicingVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.myStableStore.isHorseOwner
      && isFeatureEnabled(Feature.LEDGER_INVOICING);
  }

  get isTaskListVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin
      && isFeatureEnabled(Feature.RIDER_TASKS);
  }

  get isLaborServiceVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin
      && isFeatureEnabled(Feature.LABOR_SERVICE);
  }

  get isDocumentsVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin
      && isFeatureVisibleForRiders(FeatureVisibleForRiders.FARM_DOCUMENTS);
  }

  get isManagementVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager;
  }

  get isLedgerVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && isFeatureEnabled(Feature.LEDGER)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_READ);
  }

  get isManageFacilitiesVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_READ);
  }

  get isManageRiderTasksVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDER_TASKS_READ)
      && isFeatureEnabled(Feature.RIDER_TASKS);
  }

  get isManageFarmTasksVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_READ)
      && isFeatureEnabled(Feature.FARM_TASKS);
  }

  get isManageLaborServiceVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LABOR_SERVICE_READ)
      && isFeatureEnabled(Feature.LABOR_SERVICE);
  }

  get isManageFacilityReservationsVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_READ)
      && isFeatureEnabled(Feature.FACILITY_RESERVATIONS);
  }

  get isManageRidingLessonsVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_READ)
      && isFeatureEnabled(Feature.RIDING_LESSONS);
  }

  get isManageUsersVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_READ);
  }

  get isManageBoxPlanVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.BOX_PLAN_READ)
      && isFeatureEnabled(Feature.BOX_PLAN);
  }

  get isManageDocumentsVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_DOCUMENTS_READ);
  }

  get isManageFeedingVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEEDING_READ)
      && isFeatureEnabled(Feature.FEEDING);
  }

  get isManageFreeJumpingFreeRunningVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FREE_JUMPING_FREE_RUNNING_READ)
      && isFeatureEnabled(Feature.FREE_JUMPING_FREE_RUNNING);
  }

  get isManageHealthRecordsVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.MEDICAL_RECORDS_READ);
  }

  get isManagePenVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.PEN_READ)
      && isFeatureEnabled(Feature.PEN);
  }

  get isManagePaddockPlanVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.PADDOCK_PLAN_READ)
      && isFeatureEnabled(Feature.PADDOCK_PLAN);
  }

  get isManageCustomerMasterDataVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_READ)
      && isFeatureEnabled(Feature.CUSTOMER_MASTER_DATA);
  }

  get isManageNewsVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.NEWS_READ);
  }

  get isManageHorsesVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.HORSES_READ);
  }

  get isManageEventsVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.EVENTS_READ)
      && isFeatureEnabled(Feature.EVENTS);
  }

  get isManageWormingTreatmentsVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.SURVEYS_READ)
      && isFeatureEnabled(Feature.SURVEYS);
  }

  get isFeedProtocolVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFarmManager
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.FEED_PROTOCOL_READ)
      && isFeatureEnabled(Feature.FEED_PROTOCOL);
  }

  get currentMobileAppVersion(): string {
    return getMobileAppVersion() || '';
  }

  get isMobileAppVersionVisible(): boolean {
    return this.appStore.appVersionInformation !== null;
  }

  get isNewVersionAvailable(): boolean {
    if (!this.appStore.appVersionInformation || this.currentMobileAppVersion.length === 0) {
      return false;
    }
    return this.isVersionNewer(this.currentMobileAppVersion, this.appStore.appVersionInformation.latestAppVersion);
  }

  get isProfileVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && !this.authenticationStore.isAdmin;
  }

  get isLogoutVisible(): boolean {
    return this.authenticationStore.isAuthenticated;
  }

  get isManagementActivatorHighlighted(): boolean {
    return this.$route.path.startsWith(MANAGEMENT_ROUTE_PREFIX);
  }

  get isMyStableActivatorHighlighted(): boolean {
    return this.$route.path.startsWith(MY_STABLE_ROUTE_PREFIX);
  }

  get isLegalActivatorHighlighted(): boolean {
    return this.$route.path.startsWith(LEGAL_ROUTE_PREFIX);
  }

  get isDashboardNotificationBadgeVisible(): boolean {
    return this.dashboardStore.hasUnhandledNotifications;
  }

  get managementRoutes(): ManagementRoute[] {
    const routes: ManagementRoute[] = [];

    if (this.isLedgerVisible) {
      routes.push({
        key: 'ledger',
        title: 'Abrechnung',
        to: { name: 'admin-ledger' },
      });
    }

    if (this.isAnalyticsVisible) {
      routes.push({
        key: 'analytics',
        title: 'Analytics',
        to: { name: 'analytics' },
      });
    }

    if (this.isManageFacilitiesVisible) {
      routes.push({
        key: 'facility-management',
        title: 'Anlagen',
        to: { name: 'facility-management' },
      });
    }

    if (this.isManageFacilityReservationsVisible) {
      routes.push({
        key: 'facility-reservations-management',
        title: 'Anlagenreservierungen',
        to: { name: 'facility-reservations-management-facility-list' },
      });
    }

    if (this.isManageLaborServiceVisible) {
      routes.push({
        key: 'labor-services-management',
        title: 'Arbeitsdienst',
        to: { name: 'labor-services-management-unhandled-time-entries' },
      });
    }

    if (this.isManageUsersVisible) {
      routes.push({
        key: 'user-management',
        title: 'Benutzer',
        to: { name: 'user-management/manage-users/user-table' },
      });
    }

    if (this.isManageBoxPlanVisible) {
      routes.push({
        key: 'box-plan-management',
        title: 'Boxenplan',
        to: { name: 'box-plan-management' },
      });
    }

    if (this.isManageDocumentsVisible) {
      routes.push({
        key: 'manage-farm-documents',
        title: 'Dokumente',
        to: { name: 'admin-manage-farm-documents' },
      });
    }

    if (this.isManageFeedingVisible) {
      routes.push({
        key: 'manage-feeding',
        title: 'Fütterung',
        to: { name: 'manage-feeding-feeding-overview' },
      });
    }

    if (this.isFeedingPlanVisible) {
      routes.push({
        key: 'feeding-plan',
        title: 'Fütterungsplan',
        to: { name: 'ledger/feeding-plan' },
      });
    }

    if (this.isFeedProtocolVisible) {
      routes.push({
        key: 'feed-protocol-management',
        title: 'Fütterungstechnik',
        to: { name: 'feed-protocol-overview' },
      });
    }

    if (this.isManageFreeJumpingFreeRunningVisible) {
      routes.push({
        key: 'jumping-free-running-events-management',
        title: 'Freispringen/-laufen',
        to: { name: 'admin-free-jumping-free-running-events' },
      });
    }

    if (this.isManageHealthRecordsVisible) {
      routes.push({
        key: 'medical-records-management',
        title: 'Gesundheitsakten',
        to: { name: 'medical-records-management-medical-records' },
      });
    }

    if (this.isManageFarmTasksVisible) {
      routes.push({
        key: 'farm-task-management',
        title: 'Hofaufgaben',
        to: { name: 'farm-task-tasks-grouped-by-recency' },
      });
    }

    if (this.isFarmServiceOverviewVisible) {
      routes.push({
        key: 'farm-service-usages-management',
        title: 'Hofdienste',
        to: { name: 'ledger/farm-service-usages-for-day' },
      });
    }

    if (this.isManagePenVisible) {
      routes.push({
        key: 'pen-management',
        title: 'Koppel',
        to: { name: 'manage-pen-paddock-status-history' },
      });
    }

    if (this.isManagePaddockPlanVisible) {
      routes.push({
        key: 'paddock-plan-management',
        title: 'Koppelplan',
        to: { name: 'paddock-plan-paddock-plan' },
      });
    }

    if (this.isManageCustomerMasterDataVisible) {
      routes.push({
        key: 'customer-master-data-management',
        title: 'Kundenstammdaten',
        to: { name: 'customer-master-data-person-list' },
      });
    }

    if (this.isManageNewsVisible) {
      routes.push({
        key: 'news-management',
        title: 'Neuigkeiten',
        to: { name: 'news-management' },
      });
    }

    if (this.isManageHorsesVisible) {
      routes.push({
        key: 'horse-management',
        title: 'Pferde',
        to: { name: 'horse-management-horse-table' },
      });
    }

    if (this.isManageRiderTasksVisible) {
      routes.push({
        key: 'rider-task-management',
        title: 'Reiteraufgaben',
        to: { name: 'rider-task-management-task-tabs' },
      });
    }

    if (this.isManageRidingLessonsVisible) {
      routes.push({
        key: 'riding-lesson-management',
        title: 'Reitunterricht',
        to: { name: 'riding-lesson-management-riding-lesson-tabs' },
      });
    }

    if (this.isManageWormingTreatmentsVisible) {
      routes.push({
        key: 'survey-management',
        title: 'Umfragen',
        to: { name: 'survey-management-survey-list' },
      });
    }

    if (this.isManageEventsVisible) {
      routes.push({
        key: 'event-management',
        title: 'Veranstaltungen',
        to: { name: 'manage-events' },
      });
    }

    return routes;
  }

  beforeMount(): void {
    window.addEventListener('resize', this.handleResize);
  }

  mounted(): void {
    this.calculateSafeAreas();

    safeAreaInsets.onChange(() => this.calculateSafeAreas());
  }

  destroyed(): void {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(): void {
    if (window.innerWidth >= 960) {
      this.isNavigationDrawerVisible = false;
    }
  }

  calculateSafeAreas(): void {
    this.toolbarHeight = 60 + safeAreaInsets.top;
    this.navigationDrawerWidth = 230 + safeAreaInsets.left;
  }

  mobileVersionClicked(): void {
    window.open(this.appStore.appVersionInformation!.storeLink, '_blank');
  }

  isVersionNewer(currentVersion: string, potentiallyNewVersion: string): boolean {
    const currentVersionParts = currentVersion.split('.');
    const potentiallyNewVersionParts = potentiallyNewVersion.split('.');

    let isVersionNewer = false;
    for (let i = 0; i < currentVersionParts.length; i++) {
      const potentiallyNewVersionPart = Number(potentiallyNewVersionParts[i]);
      const currentVersionPart = Number(currentVersionParts[i]);

      if (potentiallyNewVersionPart > currentVersionPart) {
        isVersionNewer = true;
        break;
      }

      // If the version is lower it must exit the process, otherwise 1.16.1 would be greater than 1.17.0
      if (potentiallyNewVersionPart !== currentVersionPart) {
        break;
      }
    }

    return isVersionNewer;
  }

  navBarIconClicked(): void {
    this.isNavigationDrawerVisible = !this.isNavigationDrawerVisible;
  }

}
