
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { Month } from '@/types';
import { usePaymentReceiptStore } from '../store';
import { UpdatePaymentReceiptNoteForMonthAsManagerCommand, PersonWithPaymentReceipts } from '../types';

interface Controls extends FormControls {
  note: FormControl<string>;
}

@Component
export default class UpdatePaymentReceiptNoteDialog extends Vue {

  readonly store = usePaymentReceiptStore();

  @Prop({ type: Object, required: true })
  readonly userWithPaymentReceipts!: PersonWithPaymentReceipts;

  @Prop({ type: String, required: true })
  readonly month!: Month;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        note: {
          label: 'Notiz',
          value: this.userWithPaymentReceipts.note,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdatePaymentReceiptNoteForMonthAsManagerCommand = {
      personId: this.userWithPaymentReceipts.person.personId,
      month: this.month,
      note: formValues.note,
    };

    this.store.updatePaymentReceiptNoteForMonth(command)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
