
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifySelectItem, VuetifyValidatable } from '@/application/types';
import { DialogWidth } from '@/helpers/data';
import { useRidingLessonManagementStore } from '../store';
import { Facility, RidingLessonType, UpdateRequiredSpacesOfRidingLessonTypeAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    requiredSpaces: FormField<number|null>;
  };
}

@Component
export default class UpdateRequiredSpacesOfRidingLessonTypeDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  @Prop({ type: Object, required: true })
  readonly ridingLessonType!: RidingLessonType;

  @Ref()
  readonly formElement?: VuetifyValidatable;

  readonly dialogMaxWidth = DialogWidth.small;

  form: Form | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  get requiredSpacesSelectItems(): VuetifySelectItem<number>[] {
    return Array.from({ length: this.facility.spaces }, (v, i) => ({
      text: `${i + 1}`,
      value: i + 1,
    }));
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (this.formElement) {
        this.formElement.resetValidation();
      }
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        requiredSpaces: {
          value: this.ridingLessonType.requiredSpaces,
          rules: [
            requiredRule(),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateRequiredSpacesOfRidingLessonTypeAsManagerCommand = {
      ridingLessonTypeId: this.ridingLessonType.ridingLessonTypeId,
      requiredSpaces: this.form!.fields.requiredSpaces.value!,
    };
    this.store.updateRequiredSpacesOfRidingLessonType(command)
      .then(() => showSuccessMessage('Der Platzbedarf wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
