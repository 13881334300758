
import { Component, Vue } from 'vue-property-decorator';
import { CustomerType, CustomFieldId, CustomFieldType, FarmManagerPermission } from '@/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { showErrorResponse } from '@/application/snackbar/service';
import { customFieldTypeTranslations } from '@/helpers/translations';
import { CustomField } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';
import CreateCustomFieldDialog from './create-custom-field-dialog.vue';
import UpdateLabelOfCustomFieldDialog from './update-label-of-custom-field.vue';
import UpdateOptionsOfCustomFieldDialog from './update-options-of-custom-field.vue';

@Component({
  components: {
    CreateCustomFieldDialog,
    UpdateLabelOfCustomFieldDialog,
    UpdateOptionsOfCustomFieldDialog,
  },
})
export default class CustomerMasterDataConfiguration extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly CustomerType = CustomerType;

  readonly updateMenuList: Record<CustomFieldId, boolean> = {};

  get isCreateCustomFieldVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  get isUpdateVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  get isUpdateLabelVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE);
  }

  mounted(): void {
    this.store.getCustomFields()
      .catch((error) => showErrorResponse(error));
  }

  icon(customField: CustomField): string {
    switch (customField.type) {
      case CustomFieldType.TEXT:
        return 'text';
      case CustomFieldType.LONGTEXT:
        return 'text';
      case CustomFieldType.YES_NO:
        return 'check-square';
      case CustomFieldType.SELECT:
        return 'list';
      case CustomFieldType.MULTI_SELECT:
        return 'list';
      default: throw new Error(`Invalid custom field type: ${customField.type}`);
    }
  }

  translateType(customField: CustomField): string {
    return customFieldTypeTranslations[customField.type];
  }

  isUpdateOptionsVisible(customField: CustomField): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.CUSTOMER_MASTER_DATA_WRITE)
      && (
        customField.type === CustomFieldType.SELECT
        || customField.type === CustomFieldType.MULTI_SELECT
      );
  }

}
