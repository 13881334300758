
import { Component, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { useAppStore } from '@/application/app/store';
import { navigate } from '@/helpers/navigation-helpers';
import { VuetifySelectItem } from '@/application/types';
import { useMyStableStore } from '../store';
import { navigationItems } from '../helpers';

@Component
export default class HorseTabs extends Vue {

  readonly store = useMyStableStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly appStore = useAppStore();

  get navigationItems(): (VuetifySelectItem<Location> & { key: string })[] {
    return this.store.currentHorse
      ? navigationItems(this.store.currentHorse, this.store.isCurrentHorseOwn)
      : [];
  }

  beforeMount(): void {
    const horseId = this.$route.params.id;
    if (!horseId) {
      navigate({ name: 'my-stable/horses' });
      return;
    }

    this.store.updateCurrentHorse(horseId)
      .catch((error) => showErrorResponse(error));
  }

  mounted(): void {
    this.store.getAvailableHorses()
      .catch((error) => showErrorResponse(error));
  }

}
