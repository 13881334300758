
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatDate, formatTimeFrame } from '@/helpers';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { Date } from '@/types';
import { DeleteEventRegistrationCommand, MarkEventAsSeenCommand, Event, OwnRegistration } from '../types';
import { usePinboardStore } from '../store';
import RegisterForEventDialog from './register-for-event-dialog.vue';

@Component({
  components: {
    RegisterForEventDialog,
  },
})
export default class EventDetails extends Vue {

  readonly store = usePinboardStore();
  readonly appStore = useAppStore();
  readonly myStableStore = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly event!: Event;

  get participantsDescription(): string {
    return this.event.maxNumberOfParticipants !== null
      // eslint-disable-next-line max-len
      ? `${this.event.numberOfParticipants} von ${this.event.maxNumberOfParticipants} ${this.event.maxNumberOfParticipants === 1 ? 'Platz ist' : 'Plätzen sind'} bereits belegt.`
      // eslint-disable-next-line max-len
      : `${this.event.numberOfParticipants} ${this.event.numberOfParticipants === 1 ? 'Platz ist' : 'Plätze sind'} bereits belegt.`;
  }

  get isCostVisible(): boolean {
    return this.event.cost !== null;
  }

  get registrationAndCancellationDescription(): string {
    const registrationDeadline = this.event.registrationDeadline
      ? this.event.registrationDeadline
      : this.event.date;
    const cancellationDeadline = this.event.cancellationDeadline
      ? this.event.cancellationDeadline
      : this.event.date;

    // eslint-disable-next-line max-len
    return `Eine Anmeldung ist bis einschließlich ${formatDate(registrationDeadline!)} und eine Stornierung der Anmeldung ist bis einschließlich ${formatDate(cancellationDeadline!)} möglich.`;
  }

  get isEventInThePast(): boolean {
    return Date.today().isAfter(this.event.date);
  }

  get isRegistrationLimitReached(): boolean {
    return this.event.maxNumberOfParticipants !== null
      && this.event.numberOfParticipants >= this.event.maxNumberOfParticipants;
  }

  get isRegistrationButtonDisabled(): boolean {
    return this.isEventInThePast
      || (
        !!this.event.registrationDeadline
        && Date.today().isAfter(this.event.registrationDeadline)
      )
      || this.isRegistrationLimitReached
      || (
        this.event.areMultipleRegistrationsWithDifferentHorsesAllowed
          ? this.myStableStore.ownAndSharedHorses
            .every((horse) => this.event.ownRegistrations.some((registration) => registration.horseId === horse.horseId))
          : this.event.ownRegistrations.length > 0
      );
  }

  get isDeleteRegistrationButtonDisabled(): boolean {
    return this.isEventInThePast
      || (!!this.event.cancellationDeadline
        && Date.today().isAfter(this.event.cancellationDeadline)
      );
  }

  get hasRegistrations(): boolean {
    return this.event.eventRegistrations.length > 0;
  }

  get areRegistrationsVisible(): boolean {
    return this.hasRegistrations
      && (this.event.areRegistrationsPublic
        || this.event.areCommentsPublic
      );
  }

  mounted(): void {
    const appTitle = this.event.timeFrame
      ? `${formatDate(this.event.date)} - ${formatTimeFrame(this.event.timeFrame)}`
      : formatDate(this.event.date);

    this.appStore.updateTitle(appTitle)
      .catch((error) => showErrorResponse(error));

    const command: MarkEventAsSeenCommand = {
      eventId: this.event.eventId,
    };
    this.store.markEventAsSeen(command)
      .catch((error) => showErrorResponse(error));
  }

  async deleteRegistration(ownRegistration: OwnRegistration): Promise<void> {
    const command: DeleteEventRegistrationCommand = {
      eventId: this.event.eventId,
      eventRegistrationId: ownRegistration.eventRegistrationId,
    };

    return this.store.deleteEventRegistration(command)
      .catch((error) => showErrorResponse(error));
  }

}
