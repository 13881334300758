
import { Component, Vue } from 'vue-property-decorator';
import { Date, FarmManagerPermission } from '@/types';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { showErrorResponse } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import CreateTaskDialog from './create-task-dialog.vue';
import TasksGroupedByUserLists from './tasks-grouped-by-user-lists.vue';
import { useFarmTaskManagementStore } from '../store';

interface Controls extends FormControls {
  executionDate: FormControl<Date>;
  search: FormControl<string>;
}

@Component({
  components: {
    CreateTaskDialog,
    TasksGroupedByUserLists,
  },
})
export default class TasksGroupedByUserTab extends Vue {

  readonly store = useFarmTaskManagementStore();

  get isCreateTaskVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FARM_TASKS_WRITE);
  }

  get initialDateForTaskCreation(): Date | null {
    return this.store.executionDateForGroupedByUser
      && this.store.executionDateForGroupedByUser.isAfterOrEqualTo(Date.today())
      ? this.store.executionDateForGroupedByUser
      : Date.today();
  }

  form: Form<Controls> | null = null;

  mounted(): void {
    const initialExecutionDate = this.store.executionDateForGroupedByUser ?? Date.today();
    this.store.updateExecutionDateForGroupedByUser(initialExecutionDate)
      .catch((error) => showErrorResponse(error));

    this.form = this.buildForm(initialExecutionDate);
  }

  buildForm(initialExecutionDate: Date): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        executionDate: {
          label: 'Bearbeitungsdatum',
          value: initialExecutionDate,
          afterTransformationAndValidation: (executionDate) => {
            this.store.updateExecutionDateForGroupedByUser(executionDate!)
              .catch((error) => showErrorResponse(error));
          },
        },
        search: {
          label: 'Suche',
          value: null,
          afterTransformationAndValidation: (search) => {
            this.store.updateSearchForGroupedByUser(search!)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  taskCreated(): void {
    this.store.getTasksGroupedByUser()
      .catch((error) => showErrorResponse(error));
  }

}
