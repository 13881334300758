
import { Component, Prop, Vue } from 'vue-property-decorator';
import { moveHorseToTranslations } from '@/helpers/translations';
import { formatDate } from '@/helpers';
import { DataListItem } from '@/components/design-system';
import { PenExemption } from '../types';

@Component
export default class ActivePenExemption extends Vue {

  @Prop({ type: Object, required: true })
  readonly penExemption!: PenExemption;

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'timeFrame',
        label: 'Zeitraum',
        value: `${formatDate(this.penExemption.startingAt)} - ${formatDate(this.penExemption.endingAt)}`,
      },
      {
        key: 'moveHorseTo',
        label: 'Pferd stellen',
        value: moveHorseToTranslations[this.penExemption.moveHorseTo],
      },
      {
        key: 'comment',
        label: 'Kommentar',
        value: this.penExemption.comment ?? '-',
      },
    ];
  }

}
