
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import cloneDeep from 'lodash-es/cloneDeep';
import { showErrorResponse } from '@/application/snackbar/service';
import { moment } from '@/helpers';
import { useFeedProtocolManagementStore } from '../store';
import { HorseStationUtilization } from '../types';
import StationUtilizationChart from './station-utilization-chart.vue';
import HorseStationUtilizationChart from './horse-station-utilization-chart.vue';

@Component({
  components: {
    StationUtilizationChart,
    HorseStationUtilizationChart,
  },
})
export default class StationUtilization extends Vue {

  readonly store = useFeedProtocolManagementStore();

  horsesToRender: HorseStationUtilization[] = [];
  unrenderedHorses: HorseStationUtilization[] = [];
  intervalId: number | null = null;

  get isStationInfoVisible(): boolean {
    return this.store.stationUtilization !== null
      && this.store.stationUtilization.stations.length === 0;
  }

  get isNoEntriesAvailableVisible(): boolean {
    return this.store.stationUtilization !== null
      && this.store.stationUtilization!.stations.length > 0
      && !this.isThereAtLeastOneEntry;
  }

  get isUtilizationVisible(): boolean {
    return this.store.stationUtilization !== null
      && this.store.stationUtilization!.stations.length > 0
      && this.isThereAtLeastOneEntry;
  }

  get isThereAtLeastOneEntry(): boolean {
    if (this.store.stationUtilization === null) {
      return false;
    }

    return this.store.stationUtilization!.horses
      .some((horse) => Object.values(horse.stationUtilizationMap)
        .some((amount) => amount > 0));
  }

  get formattedTimeFrameForHorseUtilization(): string {
    const midnight = moment().startOf('day');
    const startDate = midnight.subtract(3, 'days');
    const endDate = midnight.subtract(1, 'day');

    return `${startDate.format('DD.MM')} bis ${endDate.format('DD.MM')}`;
  }

  mounted(): void {
    this.store.getStationUtilization()
      .catch((error) => showErrorResponse(error));

    watch(
      () => this.store.stationUtilization,
      () => this.horsesUpdated(),
      { immediate: true }
    );
  }

  horsesUpdated(): void {
    if (this.store.stationUtilization === null) {
      return;
    }

    this.horsesToRender = [];
    this.unrenderedHorses = cloneDeep(this.store.stationUtilization!.horses);
    this.intervalId = setInterval(() => this.renderHorse(), 100);
  }

  renderHorse(): void {
    if (this.unrenderedHorses.length === 0) {
      clearInterval(this.intervalId!);
      return;
    }

    this.horsesToRender.push(this.unrenderedHorses.shift()!);
  }

}
