
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { maxLengthRule, minNumberRule, positiveNumberRule, requiredRule } from '@/helpers/form-rules';
import { isSelectFieldFilled, convertDecimalToCurrencyValue, parsePercentageWithOneDecimalPlaceForField } from '@/helpers/form-helpers';
import { formatCurrency } from '@/helpers/stateful-format';
import { DialogWidth } from '@/helpers/data';
import { calculateTaxAmount } from '@/helpers/money-helper';
import { Moment, Month } from '@/types';
import { moment, uuid } from '@/helpers';
import { useLedgerStore } from '../store';
import { AddCustomBookingAsManagerCommand, Horse } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    settlingOnUserId: FormField<string|null>;
    settlingOnHorseId: FormField<string|null>;
    grossSellingPrice: FormField<number|null>;
    taxRate: FormField<number|null>;
    settlingAt: FormField<Month>;
    description: FormField<string|null>;
  },
  state: {
    horsesFromSelectedOwner: Horse[];
  },
}

@Component
export default class AddCustomBookingDialog extends Vue {

  readonly store = useLedgerStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  get isHorseDisabled(): boolean {
    return !isSelectFieldFilled(this.form!.fields.settlingOnUserId) || this.form!.state.horsesFromSelectedOwner.length === 0;
  }

  get isPricingAlertVisible(): boolean {
    return this.arePricingFieldsFilled;
  }

  get arePricingFieldsFilled(): boolean {
    return this.form!.fields.grossSellingPrice.value !== null && this.form!.fields.taxRate.value !== null;
  }

  get netSellingPrice(): number {
    if (!this.arePricingFieldsFilled) {
      return 0;
    }

    return convertDecimalToCurrencyValue(this.form!.fields.grossSellingPrice) - this.taxAmount;
  }

  get taxAmount(): number {
    if (!this.arePricingFieldsFilled) {
      return 0;
    }

    const grossSellingPriceInCents = convertDecimalToCurrencyValue(this.form!.fields.grossSellingPrice);
    const taxRatePerThousand = parsePercentageWithOneDecimalPlaceForField(this.form!.fields.taxRate);

    return calculateTaxAmount(grossSellingPriceInCents, taxRatePerThousand);
  }

  get pricingAlertText(): string {
    // eslint-disable-next-line max-len
    return `Der Verkaufspreis enthält ${formatCurrency(this.taxAmount)} Steuern auf den Nettopreis von ${formatCurrency(this.netSellingPrice)}`;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (!isDialogVisible) {
        this.form = null;
        return;
      }

      this.store.getHorseOwners()
        .then(() => {
          this.form = this.buildForm();
        })
        .catch((error) => showErrorResponse(error));
    });
  }

  buildForm(): Form {
    const settlingAtValue = this.store.selectedMonthForCustomBookings
      && moment(this.store.selectedMonthForCustomBookings!, 'YYYY-MM', 'Europe/Berlin').isSameOrAfter(moment(), 'month')
      ? this.store.selectedMonthForCustomBookings
      : moment().format('YYYY-MM') as Month;

    return {
      valid: false,
      fields: {
        settlingOnUserId: {
          value: null,
          rules: [
            requiredRule(),
          ],
        },
        settlingOnHorseId: {
          value: null,
          rules: [],
        },
        grossSellingPrice: {
          value: null,
          rules: [
            requiredRule(),
            minNumberRule(0.01),
            positiveNumberRule(2),
          ],
        },
        taxRate: {
          value: null,
          rules: [
            requiredRule(),
            minNumberRule(0),
            positiveNumberRule(1),
          ],
        },
        settlingAt: {
          value: settlingAtValue,
          rules: [
            requiredRule(),
          ],
        },
        description: {
          value: null,
          rules: [
            requiredRule(),
            maxLengthRule(255),
          ],
        },
      },
      state: {
        horsesFromSelectedOwner: [],
      },
    };
  }

  isSettlingAtAllowed(date: Moment): boolean {
    const startOfThisMonth = moment().startOf('month');

    return date.isSameOrAfter(startOfThisMonth);
  }

  userChanged() {
    const selectedUser = this.store.horseOwners.find((owner) => owner.userId === this.form!.fields.settlingOnUserId.value);

    if (!selectedUser) {
      return;
    }

    this.form!.fields.settlingOnHorseId.value = null;
    this.form!.state.horsesFromSelectedOwner = selectedUser.horses;
  }

  formSubmitted(): void {
    const command: AddCustomBookingAsManagerCommand = {
      customBookingId: uuid(),
      settlingOnUserId: this.form!.fields.settlingOnUserId.value!,
      description: this.form!.fields.description.value!,
      grossSellingPrice: convertDecimalToCurrencyValue(this.form!.fields.grossSellingPrice),
      netSellingPrice: this.netSellingPrice,
      taxRate: parsePercentageWithOneDecimalPlaceForField(this.form!.fields.taxRate),
      taxAmount: this.taxAmount,
      settlingAt: this.form!.fields.settlingAt.value,
      settlingOnHorseId: isSelectFieldFilled(this.form!.fields.settlingOnHorseId)
        ? this.form!.fields.settlingOnHorseId.value!
        : null,
    };

    this.store.addCustomBooking(command)
      .then(() => showSuccessMessage('Die Sonderkosten wurden erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
