import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { createEvents, deleteEvent, getConfigurationAsFarmManager, getEventsAsFarmManager, updateConfiguration } from './service';
import { Configuration, CreateEventsAsManagerCommand, DeleteEventAsManagerCommand, Event, UpdateConfigurationAsManagerCommand } from './types';

interface FreeJumpingFreeRunningManagementState {
  events: Event[];
  detailsEventId: string | null;
  configuration: Configuration | null;

  getEventsStatus: ActionStatus;
  getConfigurationStatus: ActionStatus;
  createEventsStatus: ActionStatus;
  deleteEventStatus: ActionStatus;
  updateConfigurationStatus: ActionStatus;
}

function initialState(): FreeJumpingFreeRunningManagementState {
  return {
    events: [],
    detailsEventId: null,
    configuration: null,

    getEventsStatus: ActionStatus.None,
    getConfigurationStatus: ActionStatus.None,
    createEventsStatus: ActionStatus.None,
    deleteEventStatus: ActionStatus.None,
    updateConfigurationStatus: ActionStatus.None,
  };
}

export const useFreeJumpingFreeRunningManagementStore = defineStore('freeJumpingFreeRunningManagement', {
  state: (): FreeJumpingFreeRunningManagementState => initialState(),
  getters: {
    currentEvent: (state: FreeJumpingFreeRunningManagementState): Event | null =>
      state.detailsEventId === null
        ? null
        : state.events.find((event) => event.id === state.detailsEventId) ?? null,

    isGetEventsProcessing: (state: FreeJumpingFreeRunningManagementState): boolean =>
      state.getEventsStatus === ActionStatus.InProgress,
    isGetConfigurationProcessing: (state: FreeJumpingFreeRunningManagementState): boolean =>
      state.getConfigurationStatus === ActionStatus.InProgress,
    isCreateEventsProcessing: (state: FreeJumpingFreeRunningManagementState): boolean =>
      state.createEventsStatus === ActionStatus.InProgress,
    isDeleteEventProcessing: (state: FreeJumpingFreeRunningManagementState): boolean =>
      state.deleteEventStatus === ActionStatus.InProgress,
    isUpdateConfigurationProcessing: (state: FreeJumpingFreeRunningManagementState): boolean =>
      state.updateConfigurationStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentEvent(eventId: string): Promise<void> {
      this.detailsEventId = eventId;

      return this.getEvents();
    },

    // -- Queries

    getEvents(): Promise<void> {
      const { getEventsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getEventsStatus,
        () => getEventsAsFarmManager(attachFarmAndUserProperties({}))
          .then((events) => {
            this.events = events;
          })
      );
    },

    getConfiguration(): Promise<void> {
      const { getConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getConfigurationStatus,
        () => getConfigurationAsFarmManager(attachFarmAndUserProperties({}))
          .then((configuration) => {
            this.configuration = configuration;
          })
      );
    },

    // -- Commands

    createEvents(command: CreateEventsAsManagerCommand): Promise<void> {
      const { createEventsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createEventsStatus,
        () => createEvents(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    deleteEvent(command: DeleteEventAsManagerCommand): Promise<void> {
      const { deleteEventStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteEventStatus,
        () => deleteEvent(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    updateConfiguration(command: UpdateConfigurationAsManagerCommand): Promise<void> {
      const { updateConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateConfigurationStatus,
        () => updateConfiguration(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

  },
});
