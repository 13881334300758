
import { Component, Prop, Vue } from 'vue-property-decorator';

interface CostItem {
  grossSellingPrice: number;
}

@Component
export default class SettlementCostColumnForLegacyTypes extends Vue {

  @Prop({ type: Object, required: true })
  readonly item!: CostItem;

}
