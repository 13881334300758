
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormValidationRule, VuetifySelectItem, VuetifyValidatable } from '@/application/types';
import { generateTimesSelectItems } from '@/helpers/form-helpers';
import { Time, TimeFrame } from '@/types';
import { uuid } from '@/helpers';

@Component
export default class TimeField extends Vue {

  @Prop({ type: Object, default: null })
  readonly value!: Time | null;

  @Prop({ type: String, required: true })
  readonly label!: string;

  @Prop({ type: Number, default: 15 })
  readonly intervalMinutes!: number;

  @Prop({ type: Object, default: null })
  readonly timeFrame!: TimeFrame | null;

  @Prop({ type: [Array, Function], default: () => [] })
  readonly rules!: FormValidationRule<Time|null>[] | (() => FormValidationRule<Time|null>[]);

  readonly selectFieldRefId = uuid();

  localValue: Time | null = null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  formFieldValueWatcher = () => {};

  get selectField(): VuetifyValidatable | undefined {
    return this.$refs[this.selectFieldRefId] as VuetifyValidatable | undefined;
  }

  // Merge listeners explicitly here to overwrite @input
  get listeners(): any {
    return {
      ...this.$listeners,
      input: this.timeChanged,
    };
  }

  get decoratedRules(): FormValidationRule<any>[] {
    const wrappedRules = typeof this.rules === 'function'
      ? this.rules()
        .map((rule) => () => rule(this.value))
      : this.rules
        .map((rule) => () => rule(this.value));

    return [...wrappedRules];
  }

  get timesSelectItems(): VuetifySelectItem<Time>[] {
    const relevantTimeFrame = this.timeFrame !== null
      ? this.timeFrame
      : {
        timeFrom: new Time(5, 0, 0),
        timeTo: new Time(0, 0, 0),
      };

    return generateTimesSelectItems(relevantTimeFrame.timeFrom, relevantTimeFrame.timeTo, this.intervalMinutes);
  }

  mounted(): void {
    this.updateLocalValue();
    this.watchFormFieldValue();
  }

  watchFormFieldValue(): void {
    this.formFieldValueWatcher = this.$watch('value', this.updateLocalValue);
  }

  unwatchFormFieldValue(): void {
    this.formFieldValueWatcher();
  }

  updateLocalValue(): void {
    this.localValue = this.value;
  }

  timeChanged(): void {
    const value = this.localValue;

    this.unwatchFormFieldValue();
    this.$emit('input', value);

    Vue.nextTick(() => {
      this.watchFormFieldValue();
    });
  }

  validate(): void {
    if (this.selectField) {
      this.selectField.validate(true);
    }
  }

}
