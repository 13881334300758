
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { useSurveyManagementStore } from '../store';
import SurveyDetails from './survey-details.vue';

@Component({
  components: {
    SurveyDetails,
  },
})
export default class SurveyDetailsLoadingWrapper extends Vue {

  readonly store = useSurveyManagementStore();
  readonly appStore = useAppStore();

  mounted(): void {
    this.store.updateCurrentWormingTreatment(this.$route.params.id)
      .then(() => this.appStore.updateTitle(this.store.currentSurvey!.title))
      .catch((error) => showErrorResponse(error));
  }

}
