
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, requiredWhenTimeConfigurationTypeIsSelectedRule } from '@/components/form';
import { Date, Time, TimeConfigurationType, TimeFrame } from '@/types';
import { DialogWidth } from '@/helpers/data';
import { useFarmTaskManagementStore } from '../store';
import { Task, UpdateTimeConfigurationOfTaskAsManagerCommand } from '../types';

interface Controls extends FormControls {
  timeConfigurationType: FormControl<TimeConfigurationType>;
  time: FormControl<Time>;
  timeFrame: FormControl<TimeFrame>;
}

@Component
export default class UpdateTimeConfigurationOfTaskDialog extends Vue {

  readonly store = useFarmTaskManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly task!: Task;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isWarningVisible(): boolean {
    return !!this.task.repetition
      || this.task.executionDate.isBefore(Date.today());
  }

  get isTimeVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.timeConfigurationType === TimeConfigurationType.WITH_TIME;
  }

  get isTimeFrameVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.timeConfigurationType === TimeConfigurationType.WITH_TIME_FRAME;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateTimeConfigurationOfTaskAsManagerCommand = {
      farmTaskId: this.task.farmTaskId,
      timeConfiguration: {
        type: formValues.timeConfigurationType!,
        time: formValues.timeConfigurationType === TimeConfigurationType.WITH_TIME
          ? formValues.time!
          : null,
        timeFrame: formValues.timeConfigurationType === TimeConfigurationType.WITH_TIME_FRAME
          ? formValues.timeFrame!
          : null,
      },
    };

    this.store.updateTimeConfigurationOfTask(command)
      .then(() => showSuccessMessage('Uhrzeitabhängigkeit wurde angepasst.'))
      .then(() => this.$emit('task-updated'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        timeConfigurationType: {
          label: 'Uhrzeitabhängig',
          value: this.task.timeConfiguration.type,
          isRequired: true,
          validateFormControlsOnInput: [
            'time',
            'timeFrame',
          ],
        },
        time: {
          label: 'Uhrzeit',
          value: this.task.timeConfiguration.time ?? Time.fromString('09:00:00'),
          rules: [],
        },
        timeFrame: {
          label: 'Zeitraum',
          value: this.task.timeConfiguration.timeFrame ?? {
            timeFrom: Time.fromString('09:00:00'),
            timeTo: Time.fromString('18:00:00'),
          },
          rules: [],
        },
      },
    });

    form.controls.time.rules!.push(requiredWhenTimeConfigurationTypeIsSelectedRule(
      form.controls.timeConfigurationType,
      TimeConfigurationType.WITH_TIME,
    ));
    form.controls.timeFrame.rules!.push(requiredWhenTimeConfigurationTypeIsSelectedRule(
      form.controls.timeConfigurationType,
      TimeConfigurationType.WITH_TIME_FRAME,
    ));

    return form;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
