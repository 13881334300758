
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { useLedgerStore } from '../store';
import { ArchiveFarmServiceAsManagerCommand, DisableFarmServiceAsManagerCommand, EnableFarmServiceAsManagerCommand, FarmService } from '../types';
import CreateFarmServiceDialog from './create-farm-service-dialog.vue';
import UpdatePresentationOfFarmServiceDialog from './update-presentation-of-farm-service-dialog.vue';
import UpdatePriceOfFarmServiceDialog from './update-price-of-farm-service-dialog.vue';
import UpdatePaymentMethodOfFarmServiceDialog from './update-payment-method-of-farm-service-dialog.vue';
import UpdateBookingStartOfFarmServiceDialog from './update-booking-start-of-farm-service-dialog.vue';
import UpdateRestrictionsOfFarmServiceDialog from './update-restrictions-of-farm-service-dialog.vue';

@Component({
  components: {
    CreateFarmServiceDialog,
    UpdatePresentationOfFarmServiceDialog,
    UpdatePriceOfFarmServiceDialog,
    UpdatePaymentMethodOfFarmServiceDialog,
    UpdateBookingStartOfFarmServiceDialog,
    UpdateRestrictionsOfFarmServiceDialog,
  },
})
export default class FarmServices extends Vue {

  readonly store = useLedgerStore();

  readonly bookingTypeMap = {
    subscription: 'im Abo',
    'one-off': 'als Einmalbuchung',
  };

  get isCreateFarmServiceVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  get isUpdateFarmServiceVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  get isArchiveFarmServiceVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_WRITE);
  }

  archiveFarmServiceAction(farmService: FarmService): Promise<void> {
    const command: ArchiveFarmServiceAsManagerCommand = {
      farmServiceId: farmService.id,
    };

    return this.store.archiveFarmService(command)
      .then(() => showSuccessMessage('Der Hofdienst wurde archiviert.'))
      .catch((error) => showErrorResponse(error));
  }

  disableFarmServiceAction(farmService: FarmService): Promise<void> {
    const command: DisableFarmServiceAsManagerCommand = {
      farmServiceId: farmService.id,
    };

    return this.store.disableFarmService(command)
      .then(() => showSuccessMessage('Der Hofdienst wurde deaktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

  enableFarmServiceAction(farmService: FarmService): Promise<void> {
    const command: EnableFarmServiceAsManagerCommand = {
      farmServiceId: farmService.id,
    };

    return this.store.enableFarmService(command)
      .then(() => showSuccessMessage('Der Hofdienst wurde aktiviert.'))
      .catch((error) => showErrorResponse(error));
  }

}
