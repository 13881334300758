
import { Component, Vue } from 'vue-property-decorator';
import { AcceptHorseShareInviteCommand, HorseShareInvite, RejectHorseShareInviteCommand } from '../types';
import CancelFacilityReservationDialog from '@/private/rider/booking-calendars/components/cancel-facility-reservation-dialog.vue';
import { useDashboardStore } from '@/private/rider/dashboard/store';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';

@Component({
  components: {
    CancelFacilityReservationDialog,
  },
})
export default class HorseShareInvites extends Vue {

  readonly store = useDashboardStore();

  async acceptHorseShareInviteAction(horseShareInvite: HorseShareInvite): Promise<void> {
    const command: AcceptHorseShareInviteCommand = {
      horseShareInviteId: horseShareInvite.horseShareInviteId,
    };

    return this.store.acceptHorseShareInvite(command)
      .then(() => showSuccessMessage('Die Einladung wurde angenommen.'))
      .catch((error) => showErrorResponse(error));
  }

  async rejectHorseShareInviteAction(horseShareInvite: HorseShareInvite): Promise<void> {
    const command: RejectHorseShareInviteCommand = {
      horseShareInviteId: horseShareInvite.horseShareInviteId,
    };

    return this.store.rejectHorseShareInvite(command)
      .then(() => showSuccessMessage('Die Einladung wurde abgelehnt.'))
      .catch((error) => showErrorResponse(error));
  }

}
