
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { OrganizationId, PersonIdList } from '@/types';
import { UpdateAssignedPersonsOfOrganizationAsManagerWorkflow } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  assignedPersons: FormControl<PersonIdList>;
}

@Component
export default class UpdateAssignedPersonsOfOrganizationDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: String, required: true })
  readonly organizationId!: OrganizationId;

  @Prop({ type: Array, required: true })
  readonly idsOfAssignedPersons!: PersonIdList;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.store.getPersonsForSelection()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => {
            showErrorResponse(error);
            this.closeDialog();
          });
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        assignedPersons: {
          label: 'Zugewiesene Personen',
          value: this.idsOfAssignedPersons,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateAssignedPersonsOfOrganizationAsManagerWorkflow = {
      organizationId: this.organizationId,
      idsOfAssignedPersons: formValues.assignedPersons!,
    };

    this.store.updateAssignedPersonsOfOrganization(command)
      .then(() => showSuccessMessage('Die zugewiesenen Personen wurde angepasst.'))
      .then(() => this.$emit('organization-updated'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
