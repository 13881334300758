
import { Component, Vue } from 'vue-property-decorator';
import { isFeatureEnabled } from '@/application/authentication/helper';
import { Feature } from '@/types';
import { useBoxPlanManagementStore } from '../store';
import { Box } from '../types';

@Component
export default class ShowBoxPlanOverview extends Vue {

  readonly store = useBoxPlanManagementStore();

  isPaddockNameVisible(box: Box): boolean {
    return !!box.horse
      && box.horse.paddockAssignments.length > 0
      && isFeatureEnabled(Feature.PADDOCK_PLAN);
  }

}
