
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LoadingBar extends Vue {

  @Prop({ type: Boolean, required: true })
  readonly isProcessing!: boolean;

}
