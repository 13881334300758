import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreateEventRegistrationCommand, DeleteEventRegistrationCommand, EventsAndConfiguration } from './types';

// -- Queries

export function getEventsAndConfiguration(query: FarmAndUserProperties): Promise<EventsAndConfiguration> {
  const url = `${apiUrl}/api/free-jumping-free-running/get-events-and-configuration-query`;
  return performApiRequest<EventsAndConfiguration>({ url, method: 'POST', data: query });
}

// -- Commands

export function createEventRegistration(command: CreateEventRegistrationCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/free-jumping-free-running/create-event-registration-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteEventRegistration(command: DeleteEventRegistrationCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/free-jumping-free-running/delete-event-registration-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
