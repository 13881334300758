
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { RiderTaskHistoryEntryType } from '@/types';
import { useRiderTasksStore } from '../store';
import { Task, RiderTaskHistoryEntry } from '../types';

const taskHistoryEntryTypeTranslation: Record<RiderTaskHistoryEntryType, string> = {
  'CREATED': 'Aufgabe erstellt',
  'CREATED_ASSIGNED': 'Zugewiesene Aufgabe erstellt',
  'ASSIGNED': 'Aufgabe zugewiesen',
  'UPDATED_EXECUTION_DATE': 'Bearbeitungsdatum angepasst',
  'REJECTED': 'Aufgabe abgelehnt',
  'WITHDRAWN': 'Aufgabe zurückgezogen',
  'COMPLETED': 'Aufgabe erledigt',
};

@Component
export default class TaskDetailsDialog extends Vue {

  readonly store = useRiderTasksStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly task!: Task;

  isDialogVisible = false;

  taskHistoryEntryType(taskHistoryEntry: RiderTaskHistoryEntry): string {
    return taskHistoryEntryTypeTranslation[taskHistoryEntry.type];
  }

  isCommentVisible(taskHistoryEntry: RiderTaskHistoryEntry): boolean {
    return taskHistoryEntry.type === RiderTaskHistoryEntryType.ASSIGNED
      || taskHistoryEntry.type === RiderTaskHistoryEntryType.UPDATED_EXECUTION_DATE
      || taskHistoryEntry.type === RiderTaskHistoryEntryType.COMPLETED
      || taskHistoryEntry.type === RiderTaskHistoryEntryType.REJECTED;
  }

  show(): void {
    this.isDialogVisible = true;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
