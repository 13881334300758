
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useHorseManagementStore } from '../store';
import { DeleteHorseAsManagerWorkflow, Horse } from '../types';

@Component
export default class DeleteHorseDialog extends Vue {

  readonly store = useHorseManagementStore();

  @Prop({ type: Object, required: true })
  readonly horse!: Horse;

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  deleteHorseConfirmed(): void {
    const command: DeleteHorseAsManagerWorkflow = {
      horseId: this.horse.horseId,
    };

    this.store.deleteHorse(command)
      .then(() => showSuccessMessage('Das Pferd wurde herausgenommen.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
