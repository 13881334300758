
import { Component, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifyValidatable } from '@/application/types';
import { maxLengthRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { UpdateBookingNoticeOfFacilityAsManagerCommand } from '../types';
import { useFacilityReservationsManagementStore } from '../store';

interface Form extends StrictFormDefinition {
  fields: {
    bookingNotice: FormField<string|null>;
  };
}

@Component
export default class UpdateBookingNoticeOfFacilityDialog extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  @Ref()
  readonly formElement?: VuetifyValidatable;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (this.formElement) {
        this.formElement.resetValidation();
      }
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        bookingNotice: {
          value: this.store.currentFacility?.facilityReservationConfiguration.bookingNotice || null,
          rules: [
            maxLengthRule(255, 'Der Hinweis darf maximal 255 Zeichen lang sein'),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateBookingNoticeOfFacilityAsManagerCommand = {
      facilityId: this.store.currentFacility!.facilityId,
      bookingNotice: this.form!.fields.bookingNotice.value,
    };

    this.store.updateBookingNoticeOfFacility(command)
      .then(() => showSuccessMessage('Der Hinweis wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
