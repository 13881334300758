
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { downloadFile } from '@/helpers/file-download-helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { formatInvoiceAddressForPerson } from '@/helpers';
import { useMyStableStore } from '../store';
import { GetInvoicePDFAsRiderQuery, Invoice, RemoveInvoiceAddressCommand } from '../types';
import DefineInvoiceAddressDialog from './define-invoice-address-dialog.vue';
import UpdateInvoiceAddressDialog from './update-invoice-address-dialog.vue';

@Component({
  components: {
    DefineInvoiceAddressDialog,
    UpdateInvoiceAddressDialog,
  },
})
export default class Invoices extends Vue {

  readonly store = useMyStableStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Rechnungsnummer', value: 'invoiceNumber' },
    { text: 'Rechnungsdatum', value: 'invoiceDate' },
    { text: 'Gesamtbetrag', value: 'grossAmount', align: 'end' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  get isDefineInvoiceAddressVisible(): boolean {
    return !this.store.invoiceAddressConfiguration?.hasInvoiceAddressDefined;
  }

  get formattedInvoiceAddress(): string {
    if (!this.store.invoiceAddressConfiguration?.hasInvoiceAddressDefined) {
      return '';
    }

    return formatInvoiceAddressForPerson(this.store.invoiceAddressConfiguration.invoiceAddress!);
  }

  get isEnableInvoiceEmailDispatchVisible(): boolean {
    return !this.store.invoiceDispatchConfiguration?.isInvoiceEmailDispatchEnabled;
  }

  get isDisableInvoiceEmailDispatchVisible(): boolean {
    return this.store.invoiceDispatchConfiguration?.isInvoiceEmailDispatchEnabled ?? false;
  }

  mounted(): void {
    this.store.getInvoicesForUser()
      .catch((error) => showErrorResponse(error));

    this.store.getInvoiceAddressConfiguration()
      .catch((error) => showErrorResponse(error));

    this.store.getInvoiceDispatchConfiguration()
      .catch((error) => showErrorResponse(error));
  }

  downloadInvoiceClicked(invoice: Invoice): void {
    const query: GetInvoicePDFAsRiderQuery = {
      invoiceId: invoice.invoiceId,
    };

    const invoiceFileName = `Rechnung-${invoice.invoiceNumber}.pdf`;

    this.store.getInvoicePDFAsRider(query)
      .then((fileResponse) => downloadFile(fileResponse.data, fileResponse.contentType, invoiceFileName))
      .catch((error) => showErrorResponse(error));
  }

  async removeInvoiceAddressAction(): Promise<void> {
    const command: RemoveInvoiceAddressCommand = {
      personId: this.authenticationStore.user!.personId,
    };
    return this.store.removeInvoiceAddress(command)
      .catch((error) => showErrorResponse(error));
  }

  async enableInvoiceEmailDispatchAction(): Promise<void> {
    return this.store.enableInvoiceEmailDispatch({})
      .catch((error) => showErrorResponse(error));
  }

  async disableInvoiceEmailDispatchAction(): Promise<void> {
    return this.store.disableInvoiceEmailDispatch({})
      .catch((error) => showErrorResponse(error));
  }
}
