
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { useEventManagementStore } from '../store';
import { EventEntry, MarkEventAsVisibleAsManagerCommand } from '../types';

@Component
export default class MarkEventAsVisibleDialog extends Vue {

  readonly store = useEventManagementStore();

  @Prop({ type: Object, required: true })
  readonly eventEntry!: EventEntry;

  readonly size = DialogWidth.large;

  isDialogVisible = false;

  get isNoPushNotificationsAlertVisible(): boolean {
    return !this.store.configuration?.areEventsVisibleForRiders ?? false;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.store.getConfiguration()
          .catch((error) => showErrorResponse(error));
      }
    });
  }

  markEventAsVisibleAsManager(): void {
    const command: MarkEventAsVisibleAsManagerCommand = {
      eventId: this.eventEntry.eventId,
    };

    this.store.markEventAsVisible(command)
      .then(() => showSuccessMessage('Die Veranstaltung wurde veröffentlicht.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
