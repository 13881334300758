
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useProfileStore } from '../store';
import ProfileView from './profile-view.vue';

@Component({
  components: {
    ProfileView,
  },
})
export default class ProfileLoadingWrapper extends Vue {

  readonly store = useProfileStore();

  mounted(): void {
    this.store.getProfile()
      .catch((error) => showErrorResponse(error));
  }

}
