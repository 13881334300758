
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SubmitActionButton extends Vue {

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isProcessing!: boolean;

  get isInternalButtonDisabled(): boolean {
    return this.isDisabled
      || this.isProcessing;
  }

  get isInternalButtonLoading(): boolean {
    return this.isProcessing;
  }

}
