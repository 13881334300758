
import { Component, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/authentication/store';
import { showErrorResponse } from '@/application/snackbar/service';
import { moveHorseToTranslations } from '@/helpers/translations';
import { Date } from '@/types';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { usePenManagementStore } from '../store';

interface Controls extends FormControls {
  date: FormControl<Date>;
}

@Component
export default class PenExemptions extends Vue {

  readonly store = usePenManagementStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly moveHorseToTranslations = moveHorseToTranslations;

  form: Form<Controls> | null = null;

  mounted(): void {const initialDate = Date.today();

    this.form = this.buildForm(initialDate);

    this.store.updateDateForPenExemptions(initialDate)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(initialDate: Date): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        date: {
          label: 'Datum',
          value: initialDate,
          afterTransformationAndValidation: (executionDate) => {
            this.store.updateDateForPenExemptions(executionDate!)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

}
