
import { Vue, Component } from 'vue-property-decorator';
import { watch } from 'vue';
import { requiredRule } from '@/helpers/form-rules';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { moment } from '@/helpers';
import { DialogWidth } from '@/helpers/data';
import { Moment } from '@/types';
import { UpdateFeedingCommand } from '../types';
import { useMyStableStore } from '../store';

interface Form extends StrictFormDefinition {
  fields: {
    startingAt: FormField<string>;
    feedingTypeMorning: FormField<string>;
    amountMorning: FormField<number|null>;
    specialFeedingEnabled: FormField<boolean>;
    feedingTypeNoon: FormField<string>;
    amountNoon: FormField<number|null>;
    feedingTypeEvening: FormField<string>;
    amountEvening: FormField<number|null>;
  },
}

@Component
export default class UpdateGerblhofFeedingDialog extends Vue {

  readonly store = useMyStableStore();

  includedHayAmount = 0;
  includedHalfHayAmount = 0;
  additionalCosts = 0;
  additionalHay = 0;
  additionalHayCosts = 0;
  additionalHalfHay = 0;
  additionalHalfHayCosts = 0;
  feedingSwitchMorningCosts = 0;
  feedingSwitchEveningCosts = 0;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form | null = null;

  startingAtOptions: string[] = [];

  get maxAmountMorning(): number {
    if (!this.store.gerblhofFeeding!.feedingPricing) {
      return 0;
    }

    return this.form!.fields.feedingTypeMorning.value === 'hay'
      ? this.store.gerblhofFeeding!.feedingPricing.maxHayAmountPerFeeding / 1000
      : this.store.gerblhofFeeding!.feedingPricing.maxHalfHayAmountPerFeeding / 1000;
  }

  get maxAmountNoon(): number {
    if (!this.store.gerblhofFeeding!.feedingPricing) {
      return 0;
    }

    return this.form!.fields.feedingTypeNoon.value === 'hay'
      ? this.store.gerblhofFeeding!.feedingPricing.maxHayAmountPerFeeding / 1000
      : this.store.gerblhofFeeding!.feedingPricing.maxHalfHayAmountPerFeeding / 1000;
  }

  get maxAmountEvening(): number {
    if (!this.store.gerblhofFeeding!.feedingPricing) {
      return 0;
    }

    return this.form!.fields.feedingTypeEvening.value === 'hay'
      ? this.store.gerblhofFeeding!.feedingPricing.maxHayAmountPerFeeding / 1000
      : this.store.gerblhofFeeding!.feedingPricing.maxHalfHayAmountPerFeeding / 1000;
  }

  get specialFeedingAmountEmpty(): boolean {
    return this.form?.fields.specialFeedingEnabled.value
      && this.form?.fields.amountNoon.value === 0
      || false;
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid
      || this.specialFeedingAmountEmpty;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    this.fillStartingAtOptions();

    const form: Form = {
      valid: false,
      fields: {
        startingAt: {
          value: '',
          rules: [
            requiredRule(),
          ],
        },
        feedingTypeMorning: {
          value: '',
          rules: [
            requiredRule(),
          ],
        },
        amountMorning: {
          value: null,
          rules: [
            requiredRule(),
          ],
        },
        specialFeedingEnabled: {
          value: false,
          rules: [],
        },
        feedingTypeNoon: {
          value: '',
          rules: [],
        },
        amountNoon: {
          value: null,
          rules: [],
        },
        feedingTypeEvening: {
          value: '',
          rules: [
            requiredRule(),
          ],
        },
        amountEvening: {
          value: null,
          rules: [
            requiredRule(),
          ],
        },
      },
    };

    if (!this.store.gerblhofFeeding!.activeFeeding) {
      return form;
    }

    form.fields.specialFeedingEnabled.value = this.store.gerblhofFeeding!.activeFeeding.specialFeedingEnabled;
    form.fields.startingAt.value = this.startingAtOptions[0];

    if (this.store.gerblhofFeeding!.activeFeeding.hayAmountMorning === 0) {
      form.fields.feedingTypeMorning.value = 'half-hay';
      form.fields.amountMorning.value = this.store.gerblhofFeeding!.activeFeeding.halfHayAmountMorning / 1000;
    } else {
      form.fields.feedingTypeMorning.value = 'hay';
      form.fields.amountMorning.value = this.store.gerblhofFeeding!.activeFeeding.hayAmountMorning / 1000;
    }

    if (this.store.gerblhofFeeding!.activeFeeding.specialFeedingEnabled) {
      if (this.store.gerblhofFeeding!.activeFeeding.hayAmountNoon === 0) {
        form.fields.feedingTypeNoon.value = 'half-hay';
        form.fields.amountNoon.value = ((this.store.gerblhofFeeding!.activeFeeding.halfHayAmountNoon || 0) / 1000);
      } else {
        form.fields.feedingTypeNoon.value = 'hay';
        form.fields.amountNoon.value = ((this.store.gerblhofFeeding!.activeFeeding.hayAmountNoon || 0) / 1000);
      }
    }

    if (this.store.gerblhofFeeding!.activeFeeding.hayAmountEvening === 0) {
      form.fields.feedingTypeEvening.value = 'half-hay';
      form.fields.amountEvening.value = this.store.gerblhofFeeding!.activeFeeding.halfHayAmountEvening / 1000;
    } else {
      form.fields.feedingTypeEvening.value = 'hay';
      form.fields.amountEvening.value = this.store.gerblhofFeeding!.activeFeeding.hayAmountEvening / 1000;
    }

    return form;
  }

  specialFeedingChanged(): void {
    if (!this.form!.fields.specialFeedingEnabled.value) {
      this.form!.fields.amountNoon.value = null;
      this.form!.fields.feedingTypeNoon.rules = [requiredRule()];
      this.form!.fields.amountNoon.rules = [requiredRule()];
    } else {
      this.form!.fields.feedingTypeNoon.value = 'hay';
      this.form!.fields.feedingTypeNoon.rules = [];
      this.form!.fields.amountNoon.rules = [];
    }

    this.formValuesChanged();
  }

  formValuesChanged(): void {
    const pricingFeeding = this.store.gerblhofFeeding!.feedingPricing;
    this.includedHayAmount = pricingFeeding.includedHayAmount;
    this.includedHalfHayAmount = pricingFeeding.includedHalfHayAmount;
    let totalHayAmount = 0;
    let totalHalfHayAmount = 0;

    this.additionalHay = 0;
    this.additionalHalfHay = 0;

    if (this.form!.fields.feedingTypeMorning.value === 'hay' && this.form!.fields.feedingTypeEvening.value === 'hay') {
      this.includedHayAmount += this.includedHayAmount;
    } else if (this.form!.fields.feedingTypeMorning.value === 'half-hay' && this.form!.fields.feedingTypeEvening.value === 'half-hay') {
      this.includedHalfHayAmount += this.includedHalfHayAmount;
    }

    if (this.form!.fields.feedingTypeMorning.value === 'hay') {
      totalHayAmount += this.form!.fields.amountMorning.value! * 1000;
    } else {
      totalHalfHayAmount += this.form!.fields.amountMorning.value! * 1000;
    }

    if (this.form!.fields.specialFeedingEnabled.value && this.form!.fields.feedingTypeNoon.value === 'hay') {
      totalHayAmount += this.form!.fields.amountNoon.value! * 1000;
    } else if (this.form!.fields.specialFeedingEnabled.value && this.form!.fields.feedingTypeNoon.value === 'half-hay') {
      totalHalfHayAmount += this.form!.fields.amountNoon.value! * 1000;
    }

    if (this.form!.fields.feedingTypeEvening.value === 'hay') {
      totalHayAmount += this.form!.fields.amountEvening.value! * 1000;
    } else {
      totalHalfHayAmount += this.form!.fields.amountEvening.value! * 1000;
    }

    if ((totalHayAmount - this.includedHayAmount) > 0) {
      this.additionalHay = totalHayAmount - this.includedHayAmount;
    }

    if ((totalHalfHayAmount - this.includedHalfHayAmount) > 0) {
      this.additionalHalfHay = totalHalfHayAmount - this.includedHalfHayAmount;
    }

    this.additionalHayCosts = ((this.additionalHay / 1000) * pricingFeeding.hayUnitPrice);
    this.additionalHalfHayCosts = ((this.additionalHalfHay / 1000) * pricingFeeding.halfHayUnitPrice);

    this.feedingSwitchMorningCosts = ((pricingFeeding.defaultAmountHayMorning === 0 && this.form!.fields.feedingTypeMorning.value === 'hay')
      || (pricingFeeding.defaultAmountHalfHayMorning === 0 && this.form!.fields.feedingTypeMorning.value === 'half-hay'))
      ? pricingFeeding.feedingSwitchMorningPrice : 0;

    this.feedingSwitchEveningCosts = ((pricingFeeding.defaultAmountHayEvening === 0 && this.form!.fields.feedingTypeEvening.value === 'hay')
      || (pricingFeeding.defaultAmountHalfHayEvening === 0 && this.form!.fields.feedingTypeEvening.value === 'half-hay'))
      ? pricingFeeding.feedingSwitchEveningPrice : 0;

    this.additionalCosts = this.additionalHayCosts
      + this.additionalHalfHayCosts
      + (this.form!.fields.specialFeedingEnabled.value && pricingFeeding.specialFeedingPrice || 0)
      + this.feedingSwitchMorningCosts
      + this.feedingSwitchEveningCosts;
  }

  onSubmit(): void {
    const command: UpdateFeedingCommand = {
      horseId: this.store.currentHorseId!,
      specialFeedingEnabled: this.form!.fields.specialFeedingEnabled.value,
      // Fallback to make sure that the correct function variant is used
      startingAt: moment(this.form!.fields.startingAt.value, 'DD.MM.YYYY', 'Europe/Berlin').startOf('day'),
      feedingTypeMorning: this.form!.fields.feedingTypeMorning.value,
      amountMorning: this.form!.fields.amountMorning.value! * 1000,
      feedingTypeEvening: this.form!.fields.feedingTypeEvening.value,
      amountEvening: this.form!.fields.amountEvening.value! * 1000,
      feedingTypeNoon: this.form!.fields.specialFeedingEnabled.value
        ? this.form!.fields.feedingTypeNoon.value
        : null,
      amountNoon: this.form!.fields.specialFeedingEnabled.value
        ? this.form!.fields.amountNoon.value! * 1000
        : null,
    };

    this.store.updateFeeding(command)
      .then(() => showSuccessMessage('Fütterung angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  fillStartingAtOptions() {
    this.startingAtOptions = [];
    const today = parseInt(moment().format('DD'), 10);
    let nextOption: Moment;

    if (today >= 15) {
      nextOption = moment().startOf('day').add(1, 'month').startOf('month');
    } else {
      nextOption = moment().startOf('day').date(15);
    }

    /* eslint-disable no-loop-func */
    for (let i = 0; i < 24; i++) {
      const isDateBlocked = this.store.gerblhofFeeding!.feedingUpdates
        .some((feedingUpdate) => moment(feedingUpdate.startingAt).isSame(nextOption));

      if (!isDateBlocked) {
        this.startingAtOptions.push(nextOption.format('DD.MM.YYYY'));
      }

      if (nextOption.format('DD') === '15') {
        nextOption = nextOption.add(1, 'month').startOf('month');
      } else {
        nextOption = nextOption.date(15);
      }
    }
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
