
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useMyStableStore } from '../store';
import { HorseWormingTreatments } from '../types';
import CreateHorseWormingTreatmentDialog from './create-horse-worming-treatment-dialog.vue';

@Component({
  components: {
    CreateHorseWormingTreatmentDialog,
  },
})
export default class HorseWormingTreatmentsCard extends Vue {

  readonly store = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly horseWormingTreatments!: HorseWormingTreatments;

  get isCreateHorseWormingTreatmentVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

}
