
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { checkboxOnlyAllowedWhenCheckboxIsCheckedRule, constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, minCountWhenCheckboxIsCheckedRule, minNumberRule, onlyOneOfCheckboxesMustBeCheckedRule } from '@/components/form';
import { TimeFrame, Date, Persona, UserGroupIdList, MimeType } from '@/types';
import { useEventManagementStore } from '../store';
import { EventEntry, UpdateEventAsManagerCommand } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  content: FormControl<string>;
  date: FormControl<Date>;
  timeFrame: FormControl<TimeFrame>;
  registrationDeadline: FormControl<Date>;
  cancellationDeadline: FormControl<Date>;
  cost: FormControl<string>;
  maxNumberOfParticipants: FormControl<number>;
  isVisibilityRestrictedForPersona: FormControl<boolean>;
  visibilityRestrictedForPersona: FormControl<Persona>;
  isVisibilityRestrictedForGroups: FormControl<boolean>;
  idsOfGroupsForWhichTheEventIsVisible: FormControl<UserGroupIdList>;
  isHorseRequiredOnRegistration: FormControl<boolean>;
  areMultipleRegistrationsWithDifferentHorsesAllowed: FormControl<boolean>;
  isCommentRequiredOnRegistration: FormControl<boolean>;
  areRegistrationsPublic: FormControl<boolean>;
  areCommentsPublic: FormControl<boolean>;
  isAvailableForExternalGuests: FormControl<boolean>;
  image: FormControl<Blob>;
  attachment: FormControl<File>;
}

@Component
export default class UpdateEventDialog extends Vue {

  readonly store = useEventManagementStore();

  @Prop({ type: Object, required: true })
  readonly event!: EventEntry;

  @Prop({ type: Boolean, required: true })
  readonly isDisabled!: boolean;

  readonly size = DialogWidth.large;

  readonly MimeType = MimeType;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isVisibleForPersonaVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isVisibilityRestrictedForPersona!;
  }

  get isIdsOfGroupsForWhichTheEventIsVisibleVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isVisibilityRestrictedForGroups!;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        Promise.all([
          this.store.getConfiguration(),
          this.store.getUserGroups(),
        ])
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => showErrorResponse(error));
      } else {
        this.form = null;
      }
    });
  }

  public show(): void {
    this.isDialogVisible = true;
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Titel',
          value: this.event.title,
          isRequired: true,
        },
        content: {
          label: 'Beschreibung',
          value: this.event.content,
          isRequired: true,
        },
        date: {
          label: 'Datum',
          value: this.event.date,
          isRequired: true,
        },
        timeFrame: {
          label: 'Zeitraum',
          value: this.event.timeFrame,
        },
        registrationDeadline: {
          label: 'Anmeldung möglich bis einschließlich',
          value: this.event.registrationDeadline,
          rules: [],
        },
        cancellationDeadline: {
          label: 'Stornierung möglich bis einschließlich',
          value: this.event.cancellationDeadline,
          rules: [],
        },
        cost: {
          label: 'Gebühren',
          value: this.event.cost,
          rules: [],
        },
        maxNumberOfParticipants: {
          label: 'Maximale Teilnehmerzahl',
          value: this.event.maxNumberOfParticipants,
          rules: [
            minNumberRule(1),
            maxDecimalsNumberRule(0),
          ],
        },
        isVisibilityRestrictedForPersona: {
          label: 'Soll die Veranstaltung nur einer bestimmten Persona angezeigt werden?',
          value: this.event.visibilityRestrictedForPersona !== null,
        },
        visibilityRestrictedForPersona: {
          label: 'Sichtbarkeit für Persona',
          value: this.event.visibilityRestrictedForPersona,
        },
        isVisibilityRestrictedForGroups: {
          label: 'Soll die Veranstaltung nur bestimmten Gruppen angezeigt werden?',
          value: this.event.idsOfGroupsForWhichTheEventIsVisible !== null,
          validateFormControlsOnInput: [
            'idsOfGroupsForWhichTheEventIsVisible',
          ],
        },
        idsOfGroupsForWhichTheEventIsVisible: {
          label: 'Gruppen',
          value: this.event.idsOfGroupsForWhichTheEventIsVisible,
          rules: [],
        },
        isHorseRequiredOnRegistration: {
          label: 'Bei der Anmeldung muss ein Pferd ausgewählt werden.',
          value: this.event.isHorseRequiredOnRegistration,
          rules: [],
          validateFormControlsOnInput: [
            'isAvailableForExternalGuests',
            'areMultipleRegistrationsWithDifferentHorsesAllowed',
          ],
        },
        areMultipleRegistrationsWithDifferentHorsesAllowed: {
          label: 'Können Personen sich mehrfach mit unterschiedlichen Pferden anmelden?',
          value: this.event.areMultipleRegistrationsWithDifferentHorsesAllowed,
          rules: [],
          validateFormControlsOnInput: [
            'isHorseRequiredOnRegistration',
          ],
        },
        isCommentRequiredOnRegistration: {
          label: 'Bei der Anmeldung muss ein Kommentar eingetragen werden.',
          value: this.event.isCommentRequiredOnRegistration,
          rules: [],
        },
        areRegistrationsPublic: {
          label: 'Die Namen der angemeldeten Benutzer werden den Reitern angezeigt.',
          value: this.event.areRegistrationsPublic,
          rules: [],
        },
        areCommentsPublic: {
          label: 'Die Kommentare der Anmeldungen werden den Reitern angezeigt.',
          value: this.event.areCommentsPublic,
          rules: [],
        },
        isAvailableForExternalGuests: {
          label: 'Die Veranstaltung steht auch externen Gästen (über einen Link) zur Verfügung.',
          value: this.event.isAvailableForExternalGuests,
          rules: [],
          validateFormControlsOnInput: [
            'isHorseRequiredOnRegistration',
          ],
        },
        image: {
          label: 'Bild',
          value: null,
        },
        attachment: {
          label: 'Anhang',
          value: null,
        },
      },
    });

    form.controls.idsOfGroupsForWhichTheEventIsVisible.rules!.push(
      minCountWhenCheckboxIsCheckedRule(
        1,
        form.controls.isVisibilityRestrictedForGroups,
        'Es muss mindestens eine Gruppe ausgewählt werden'
      ),
    );

    form.controls.isHorseRequiredOnRegistration.rules!.push(onlyOneOfCheckboxesMustBeCheckedRule([
      form.controls.isHorseRequiredOnRegistration,
      form.controls.isAvailableForExternalGuests,
    ], 'Externe Gäste haben keine Pferde hinterlegt, daher können diese nicht verpflichtend sein'));

    form.controls.areMultipleRegistrationsWithDifferentHorsesAllowed.rules!.push(checkboxOnlyAllowedWhenCheckboxIsCheckedRule(
      form.controls.isHorseRequiredOnRegistration,
      'Mehrere Anmeldungen sind nur möglich, wenn ein Pferd bei der Anmeldung angegeben werden muss'));

    form.controls.isAvailableForExternalGuests.rules!.push(onlyOneOfCheckboxesMustBeCheckedRule([
      form.controls.isHorseRequiredOnRegistration,
      form.controls.isAvailableForExternalGuests,
    ], 'Externe Gäste haben keine Pferde hinterlegt, daher können diese nicht verpflichtend sein'));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateEventAsManagerCommand = {
      body: {
        eventId: this.event.eventId,
        title: formValues.title!,
        content: formValues.content!,
        date: formValues.date!,
        timeFrame: formValues.timeFrame,
        isHorseRequiredOnRegistration: formValues.isHorseRequiredOnRegistration!,
        areMultipleRegistrationsWithDifferentHorsesAllowed: formValues.areMultipleRegistrationsWithDifferentHorsesAllowed!,
        isCommentRequiredOnRegistration: formValues.isCommentRequiredOnRegistration!,
        areCommentsPublic: formValues.areCommentsPublic!,
        areRegistrationsPublic: formValues.areRegistrationsPublic!,
        registrationDeadline: formValues.registrationDeadline,
        cancellationDeadline: formValues.cancellationDeadline,
        visibilityRestrictedForPersona: formValues.isVisibilityRestrictedForPersona
          ? formValues.visibilityRestrictedForPersona!
          : null,
        idsOfGroupsForWhichTheEventIsVisible: formValues.isVisibilityRestrictedForGroups
          ? formValues.idsOfGroupsForWhichTheEventIsVisible!
          : null,
        cost: formValues.cost,
        maxNumberOfParticipants: formValues.maxNumberOfParticipants,
        isAvailableForExternalGuests: formValues.isAvailableForExternalGuests!,
      },
      files: {
        attachment: formValues.attachment,
        image: formValues.image,
      }
    };

    this.store.updateEvent(command)
      .then(() => showSuccessMessage('Die Veranstaltung wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  isDateAllowedForDate(date: Date): boolean {
    return date.isAfterOrEqualTo(Date.today());
  }

  isDateAllowedForRegistrationDeadline(date: Date): boolean {
    const today = Date.today();
    if (date.isBefore(today)) {
      return false;
    }

    const formValues = getFormValues(this.form!);

    return formValues.date!.isAfterOrEqualTo(date);
  }

  isDateAllowedForCancellationDeadline(date: Date): boolean {
    const today = Date.today();
    if (date.isBefore(today)) {
      return false;
    }

    const formValues = getFormValues(this.form!);

    return formValues.date!.isAfterOrEqualTo(date);
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
