
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { featureTranslation, userRoleTranslation } from '@/helpers/translations';
import { AdminNotification, DeleteAdminNotificationAsAdminCommand } from '../types';
import { useAdminNotificationManagementStore } from '../store';
import CreateAdminNotificationDialog from './create-admin-notification-dialog.vue';
import UpdateAdminNotificationDialog from './update-admin-notification-dialog.vue';

@Component({
  components: {
    CreateAdminNotificationDialog,
    UpdateAdminNotificationDialog,
  },
})
export default class ManageAdminNotifications extends Vue {

  readonly store = useAdminNotificationManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Nachricht', value: 'message' },
    { text: 'Eingeschränkte Features', value: 'visibleForFarmsWithEnabledFeatures', width: 180 },
    { text: 'Eingeschränkte Rolle', value: 'visibleForRole', width: 155 },
    { text: 'Gesehen von', value: 'amountOfUsersThatSeenTheAdminNotification', width: 130 },
    { text: 'Aktionen', value: 'actions', align: 'end', width: 100 },
  ];

  mounted(): void {
    this.store.getAllAdminNotifications()
      .catch((error) => showErrorResponse(error));
  }

  translateVisibleForRole(adminNotification: AdminNotification): string {
    return adminNotification.visibleForRole
      ? userRoleTranslation[adminNotification.visibleForRole]
      : 'Keine';
  }

  translateVisibleForFarmsWithEnabledFeatures(adminNotification: AdminNotification): string {
    if (!adminNotification.visibleForFarmsWithEnabledFeatures) {
      return 'Keine';
    }

    return adminNotification.visibleForFarmsWithEnabledFeatures!
      .map((feature) => featureTranslation[feature])
      .join(', ');
  }

  translateAmountOfUsersThatSeenTheAdminNotification(adminNotification: AdminNotification): string {
    return adminNotification.amountOfUsersThatSeenTheAdminNotification > 1
      ? `${adminNotification.amountOfUsersThatSeenTheAdminNotification} Benutzern`
      : `${adminNotification.amountOfUsersThatSeenTheAdminNotification} Benutzer`;
  }

  async deleteAdminNotificationAction(adminNotification: AdminNotification): Promise<void> {
    const command: DeleteAdminNotificationAsAdminCommand = {
      adminNotificationId: adminNotification.adminNotificationId,
    };
    return this.store.deleteAdminNotification(command)
      .then(() => showSuccessMessage('Admin Benachrichtigung wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
