
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition, VuetifyValidatable } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { requiredRule, passwordRule, passwordMustBeIdenticalToTextInFieldRule } from '@/helpers/form-rules';
import { DialogWidth } from '@/helpers/data';
import { useUserManagementStore } from '../store';
import { UpdateUserPasswordAsManagerCommand, User } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    password: FormField<string|null>;
    repeatPassword: FormField<string|null>;
  },
}

@Component
export default class UpdateUserPasswordDialog extends Vue {

  readonly store = useUserManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly user!: User;

  @Ref()
  readonly passwordInput!: VuetifyValidatable;

  @Ref()
  readonly passwordRepeatInput!: VuetifyValidatable;

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    const form: Form = {
      valid: false,
      fields: {
        password: {
          value: null,
          rules: [
            requiredRule(),
            passwordRule(),
          ],
        },
        repeatPassword: {
          value: null,
          rules: [
            requiredRule(),
            passwordRule(),
          ],
        },
      },
    };

    form.fields.password.rules!.push(passwordMustBeIdenticalToTextInFieldRule(form.fields.repeatPassword));
    form.fields.repeatPassword.rules!.push(passwordMustBeIdenticalToTextInFieldRule(form.fields.password));

    return form;
  }

  passwordChanged(): void {
    this.passwordRepeatInput.validate(true);
  }

  passwordRepeatChanged(): void {
    this.passwordInput.validate(true);
  }

  formSubmitted(): void {
    const command: UpdateUserPasswordAsManagerCommand = {
      targetUserId: this.user.userId,
      password: this.form!.fields.password.value!,
    };

    this.store.updateUserPassword(command)
      .then(() => showSuccessMessage('Passwort wurde geändert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
