
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { DataListItem } from '@/components/design-system';
import { formatBoolean } from '@/helpers';
import { FarmManagerPermission } from '@/types';
import UpdateRegistrationConfigurationDialog from './update-registration-configuration-dialog.vue';
import { useUserManagementStore } from '../store';

@Component({
  components: {
    UpdateRegistrationConfigurationDialog,
  },
})
export default class RegistrationConfiguration extends Vue {

  readonly store = useUserManagementStore();

  get isUpdateRegistrationConfigurationVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.USERS_WRITE);
  }

  get dataItems(): DataListItem[] {
    return [
      {
        key: 'isHorsesViewVisibleForNewRiders',
        label: 'Neue Reiter sehen die Pferdeansicht',
        value: formatBoolean(this.store.registrationConfiguration!.isHorsesViewVisibleForNewRiders),
      },
      {
        key: 'isHorsesViewVisibleForNewFarmManagers',
        label: 'Neue Hofverwalter sehen die Pferdeansicht',
        value: formatBoolean(this.store.registrationConfiguration!.isHorsesViewVisibleForNewFarmManagers),
      },
      {
        key: 'registrationCode',
        label: 'Registrierungscode',
        value: this.store.registrationConfiguration!.registrationCode,
      },
    ];
  }

  mounted(): void {
    this.store.getRegistrationConfiguration()
      .catch((error) => showErrorResponse(error));
  }

}
