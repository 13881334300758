
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { Moment } from '@/types';
import { moment } from '@/helpers';
import { constructForm, Form, FormControl, FormControls, FormControlValue } from '@/components/form';
import FeedingPlanTable from './feeding-plan-table.vue';
import { useFeedingPlanStore } from '../store';

interface Controls extends FormControls {
  selectedDate: FormControl<Moment>;
}

@Component({
  components: {
    FeedingPlanTable,
  },
})
export default class FeedingPlanTabs extends Vue {

  readonly store = useFeedingPlanStore();

  form: Form<Controls> | null = null;

  currentTab = '';

  mounted(): void {
    const selectedDate = moment().startOf('day');

    this.form = this.buildForm(selectedDate);

    this.store.updateFeedingPlanForDay(selectedDate)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(selectedDate: Moment): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        selectedDate: {
          label: 'Datum',
          value: selectedDate,
          rules: [],
          afterTransformationAndValidation: (value: FormControlValue<Moment>) => this.store.updateFeedingPlanForDay(value!)
            .catch((error) => showErrorResponse(error)),
        },
      },
    });
  }

  destroyed(): void {
    this.store.$reset();
  }

}
