
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControlRule, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { Country, Currency, Feature, Locale, Name, RegistrationCode } from '@/types';
import { uuid } from '@/helpers';
import { useFarmManagementStore } from '../store';
import { CreateFarmAsAdminWorkflow } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
  registrationCode: FormControl<RegistrationCode>;
  currency: FormControl<Currency>;
  locale: FormControl<Locale>;
  defaultCountryForAddresses: FormControl<Country>;
  enabledFeatures: FormControl<Feature[]>;
  isDemoDataGenerationRequested: FormControl<boolean>;
  farmManagerEmailAddress: FormControl<string>;
  farmManagerName: FormControl<Name>;
}

function requiredFeaturesForDemoDataEnabledRule(featuresFormControl: FormControl<Feature[]>): FormControlRule<boolean> {
  return (value) => value !== true
    || (
      featuresFormControl.value !== null
      && featuresFormControl.value.includes(Feature.FACILITY_RESERVATIONS)
      && featuresFormControl.value.includes(Feature.EVENTS)
    )
      ? true
      : 'Für die Generierung von Demo Daten müssen die Features "Anlagenreservierungen" und "Veranstaltungen" aktiviert sein.';
}

@Component
export default class CreateFarmDialog extends Vue {

  readonly store = useFarmManagementStore();

  readonly size = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        registrationCode: {
          label: 'Registrierungscode',
          value: null,
          isRequired: true,
        },
        currency: {
          label: 'Währung',
          value: Currency.EUR,
          isRequired: true,
        },
        locale: {
          label: 'Sprachumgebung',
          value: Locale['de-DE'],
          isRequired: true,
        },
        defaultCountryForAddresses: {
          label: 'Standardland für Adressen',
          value: Country.de,
          isRequired: true,
        },
        enabledFeatures: {
          label: 'Aktivierte Features',
          value: null,
          validateFormControlsOnInput: [
            'isDemoDataGenerationRequested',
          ],
        },
        isDemoDataGenerationRequested: {
          label: 'Sollen Demo Daten für den Hof generiert werden?',
          value: false,
          rules: [],
        },
        farmManagerEmailAddress: {
          label: 'E-Mail-Adresse des Hofbesitzers',
          value: null,
          isRequired: true,
        },
        farmManagerName: {
          label: 'Name des Hofbesitzers',
          value: null,
          isRequired: true,
        },
      },
    });

    form.controls.isDemoDataGenerationRequested.rules!.push(requiredFeaturesForDemoDataEnabledRule(form.controls.enabledFeatures));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateFarmAsAdminWorkflow = {
      name: formValues.name!,
      registrationCode: formValues.registrationCode!!,
      currency: formValues.currency!,
      locale: formValues.locale!,
      defaultCountryForAddresses: formValues.defaultCountryForAddresses!,
      enabledFeatures: formValues.enabledFeatures ?? [],
      isDemoDataGenerationRequested: formValues.isDemoDataGenerationRequested!,
      farmManagerUserId: uuid(),
      farmManagerEmailAddress: formValues.farmManagerEmailAddress!,
      farmManagerName: formValues.farmManagerName!,
    };

    this.store.createFarm(command)
      .then(() => showSuccessMessage('Der Hof wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
