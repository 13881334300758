
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { FormField, StrictFormDefinition } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { csvCompliantRule, maxLengthRule, minNumberRule, positiveNumberRule, requiredRule } from '@/helpers/form-rules';
import { convertDecimalToCurrencyValue, parsePercentageWithOneDecimalPlaceForField } from '@/helpers/form-helpers';
import { DialogWidth } from '@/helpers/data';
import { uuid } from '@/helpers';
import { formatCurrency } from '@/helpers/stateful-format';
import { calculateTaxAmount } from '@/helpers/money-helper';
import { FarmServiceBookingType, PaymentMethod, SubscriptionSchedule } from '@/types';
import { useLedgerStore } from '../store';
import { AddFarmServiceAsManagerCommand, FarmService } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    name: FormField<string|null>;
    description: FormField<string|null>;
    grossSellingPrice: FormField<number|null>;
    taxRate: FormField<number|null>;
    bookingType: FormField<FarmServiceBookingType>;
    paymentMethod: FormField<PaymentMethod>;
    subscriptionSchedule: FormField<SubscriptionSchedule>;
    isBookingInCurrentMonthAllowed: FormField<boolean>;
    bookingInAdvance: FormField<number|null>;
    areRestrictionsDefined: FormField<boolean>;
    enableWithFarmServiceBookings: FormField<string[]>;
    disableWithFarmServiceBookings: FormField<string[]>;
    isEnabled: FormField<boolean>;
  },
}

@Component
export default class CreateFarmServiceDialog extends Vue {

  readonly store = useLedgerStore();

  readonly dialogMaxWidth = DialogWidth.large;

  readonly bookingTypeOptions: { text: string, value: FarmServiceBookingType }[] = [
    { text: 'Abo', value: FarmServiceBookingType.subscription },
    { text: 'Einmalbuchung', value: FarmServiceBookingType['one-off'] },
  ];
  readonly paymentMethodOptions: { text: string; value: PaymentMethod }[] = [
    { text: 'Überweisung', value: PaymentMethod['bank-transfer'] },
    { text: 'Lastschrift', value: PaymentMethod['direct-debit'] },
  ];
  readonly subscriptionScheduleOptions: { text: string; value: SubscriptionSchedule }[] = [
    { text: '1 Monat', value: SubscriptionSchedule['one-month'] },
    { text: '3 Monate', value: SubscriptionSchedule['three-months'] },
    { text: '6 Monate', value: SubscriptionSchedule['six-months'] },
    { text: '1 Jahr', value: SubscriptionSchedule['one-year'] },
  ];

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  get isPricingAlertVisible(): boolean {
    return this.arePricingFieldsFilled;
  }

  get arePricingFieldsFilled(): boolean {
    return this.form!.fields.grossSellingPrice.value !== null && this.form!.fields.taxRate.value !== null;
  }

  get netSellingPrice(): number {
    if (!this.arePricingFieldsFilled) {
      return 0;
    }

    return convertDecimalToCurrencyValue(this.form!.fields.grossSellingPrice) - this.taxAmount;
  }

  get taxAmount(): number {
    if (!this.arePricingFieldsFilled) {
      return 0;
    }

    const grossSellingPriceInCents = convertDecimalToCurrencyValue(this.form!.fields.grossSellingPrice);
    const taxRatePerThousand = parsePercentageWithOneDecimalPlaceForField(this.form!.fields.taxRate);

    return calculateTaxAmount(grossSellingPriceInCents, taxRatePerThousand);
  }

  get pricingAlertText(): string {
    // eslint-disable-next-line max-len
    return `Der Verkaufspreis enthält ${formatCurrency(this.taxAmount)} Steuern auf den Nettopreis von ${formatCurrency(this.netSellingPrice)}`;
  }

  get farmServices(): FarmService[] {
    return this.store.configuration!.farmServices
      .filter((farmService) => farmService.bookingType === 'subscription');
  }

  get isBookingInAdvanceVisible(): boolean {
    return this.form!.fields.bookingType.value === 'one-off' || !this.form!.fields.isBookingInCurrentMonthAllowed.value;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        name: {
          value: null,
          rules: [
            requiredRule(),
            maxLengthRule(255),
            csvCompliantRule(),
          ],
        },
        description: {
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        grossSellingPrice: {
          value: null,
          rules: [
            requiredRule(),
            positiveNumberRule(2),
          ],
        },
        taxRate: {
          value: null,
          rules: [
            requiredRule(),
            minNumberRule(0),
            positiveNumberRule(2),
          ],
        },
        bookingType: {
          value: FarmServiceBookingType.subscription,
          rules: [
            requiredRule(),
          ],
        },
        paymentMethod: {
          value: PaymentMethod['bank-transfer'],
          rules: [
            requiredRule(),
          ],
        },
        subscriptionSchedule: {
          value: SubscriptionSchedule['one-month'],
          rules: [
            requiredRule(),
          ],
        },
        isBookingInCurrentMonthAllowed: {
          value: true,
          rules: [],
        },
        bookingInAdvance: {
          value: 0,
          rules: [
            requiredRule(),
            positiveNumberRule(),
          ],
        },
        areRestrictionsDefined: {
          value: false,
          rules: [],
        },
        enableWithFarmServiceBookings: {
          value: [],
          rules: [
            this.enabledNotInDisabledFarmServiceRule,
          ],
        },
        disableWithFarmServiceBookings: {
          value: [],
          rules: [
            this.disabledNotInEnabledFarmServiceRule,
          ],
        },
        isEnabled: {
          value: true,
          rules: [],
        },
      },
    };
  }

  enabledNotInDisabledFarmServiceRule(value: any): true|string {
    if (!value || !Array.isArray(value)) {
      return true;
    }
    const isNotSelectedInOtherList = value
      .every((valueItem) => this.form!.fields.disableWithFarmServiceBookings.value.every((item) => item !== valueItem));

    return isNotSelectedInOtherList || 'Dieser Hofdienst ist bereits in der anderen Liste ausgewählt';
  }

  disabledNotInEnabledFarmServiceRule(value: any): true|string {
    if (!value || !Array.isArray(value)) {
      return true;
    }
    const isNotSelectedInOtherList = value
      .every((valueItem) => this.form!.fields.enableWithFarmServiceBookings.value.every((item) => item !== valueItem));

    return isNotSelectedInOtherList || 'Dieser Hofdienst ist bereits in der anderen Liste ausgewählt';
  }

  areRestrictionsDefinedChanged(): void {
    if (!this.form!.fields.areRestrictionsDefined.value) {
      this.form!.fields.enableWithFarmServiceBookings.value = [];
      this.form!.fields.disableWithFarmServiceBookings.value = [];
    }
  }

  formSubmitted(): void {
    const command: AddFarmServiceAsManagerCommand = {
      farmServiceId: uuid(),
      name: this.form!.fields.name.value!,
      description: this.form!.fields.description.value,
      grossSellingPrice: convertDecimalToCurrencyValue(this.form!.fields.grossSellingPrice),
      netSellingPrice: this.netSellingPrice,
      taxRate: parsePercentageWithOneDecimalPlaceForField(this.form!.fields.taxRate),
      taxAmount: this.taxAmount,
      bookingType: this.form!.fields.bookingType.value,
      paymentMethod: this.form!.fields.paymentMethod.value,
      enableWithFarmServiceBookings: this.form!.fields.enableWithFarmServiceBookings.value,
      disableWithFarmServiceBookings: this.form!.fields.disableWithFarmServiceBookings.value,
      isEnabled: this.form!.fields.isEnabled.value,
      subscriptionSchedule: this.form!.fields.bookingType.value === 'subscription'
        ? this.form!.fields.subscriptionSchedule.value
        : null,
      isBookingInCurrentMonthAllowed: this.form!.fields.bookingType.value === 'subscription'
        ? this.form!.fields.isBookingInCurrentMonthAllowed.value
        : null,
      bookingInAdvance: (this.form!.fields.bookingType.value === 'subscription'
        && !this.form!.fields.isBookingInCurrentMonthAllowed.value)
        || this.form!.fields.bookingType.value === 'one-off'
        ? this.form!.fields.bookingInAdvance.value!
        : null,
    };

    this.store.addFarmService(command)
      .then(() => showSuccessMessage('Der Hofdienst wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
