
import { Component, Vue } from 'vue-property-decorator';
import { Date } from '@/types';
import { moment } from '@/helpers';
import { useAnalyticsStore } from '../store';

@Component
export default class PaddockChart extends Vue {

  readonly store = useAnalyticsStore();

  readonly weekdays = [0, 1, 2, 3, 4, 5, 6];

  getDayClass(date: string): string {
    const data = this.store.analytics!.paddock;

    return data.usedInCurrentMonth.some((used) => used.isEqualTo(new Date(date)))
        ? 'used'
        : '';
  }

  getFormattedDate(date: string): string {
    return moment(date).format('DD.MM');
  }

}
