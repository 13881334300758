import { Location } from 'vue-router';
import { VuetifySelectItem } from '@/application/types';
import { isFeatureVisibleForRiders } from '@/application/authentication/helper';
import { FeatureVisibleForRiders } from '@/types';
import { Horse } from './types';

export function navigationItems(horse: Horse, isOwnHorse: boolean): (VuetifySelectItem<Location> & { key: string })[] {
  const items: (VuetifySelectItem<Location> & { key: string })[] = [];

  items.push({
    text: 'Trainingstagebuch',
    value: { name: 'training-diary', params: { id: horse.horseId } },
    key: 'training-diary',
  });

  if (isFeatureVisibleForRiders(FeatureVisibleForRiders.FARM_SERVICES)) {
    items.push({
      text: 'Hofdienste',
      value: { name: 'horse-bookings-farm-services', params: { id: horse.horseId } },
      key: 'horse-bookings-farm-services',
    });
  }

  if (isFeatureVisibleForRiders(FeatureVisibleForRiders.PEN_EXEMPTIONS)) {
    items.push({
      text: 'Koppelausnahmen',
      value: { name: 'horse-pen-exemptions', params: { id: horse.horseId } },
      key: 'horse-pen-exemptions',
    });
  }

  if (isFeatureVisibleForRiders(FeatureVisibleForRiders.GERBLHOF_FEEDING)) {
    items.push({
      text: 'Fütterung',
      value: { name: 'gerblhof-feeding', params: { id: horse.horseId } },
      key: 'gerblhof-feeding',
    });
  }

  if (isFeatureVisibleForRiders(FeatureVisibleForRiders.FEEDING)) {
    items.push({
      text: 'Fütterung',
      value: { name: 'feeding', params: { id: horse.horseId } },
      key: 'feeding',
    });
  }

  items.push({
    text: 'Gesundheitsakte',
    value: { name: 'horse-medical-record', params: { id: horse.horseId } },
    key: 'horse-medical-record',
  });

  if (isOwnHorse) {
    items.push({
      text: 'Reitbeteiligungen',
      value: { name: 'horse-shares', params: { id: horse.horseId } },
      key: 'horse-shares',
    });
  }

  items.push({
    text: 'Details',
    value: { name: 'horse-details', params: { id: horse.horseId } },
    key: 'horse-details',
  });

  return items;
}
