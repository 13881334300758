
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission, isFeatureEnabled } from '@/application/authentication/helper';
import { Feature, FarmManagerPermission } from '@/types';
import { useLedgerStore } from '../store';
import FarmServices from './farm-services.vue';
import GerblhofFeeding from './gerblhof-feeding.vue';
import ConfigurationForCustomBookingsAndCreditNotes from './configuration-for-custom-bookings-and-credit-notes.vue';
import ConfigurationForInvoices from './configuration-for-invoices.vue';

@Component({
  components: {
    FarmServices,
    GerblhofFeeding,
    ConfigurationForCustomBookingsAndCreditNotes,
    ConfigurationForInvoices,
  },
})
export default class LedgerConfiguration extends Vue {

  readonly store = useLedgerStore();

  get isInvoiceInformationVisible(): boolean {
    return isFeatureEnabled(Feature.LEDGER_INVOICING)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_READ)
      && !!this.store.invoiceInformation;
  }

  mounted(): void {
    this.store.getConfiguration()
      .catch((error) => showErrorResponse(error));

    if (isFeatureEnabled(Feature.LEDGER_INVOICING)
      && doesAuthenticatedUserHavePermission(FarmManagerPermission.LEDGER_INVOICING_READ)
    ) {
      this.store.getInvoiceInformation()
        .catch((error) => showErrorResponse(error));
    }
  }

}
