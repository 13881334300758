
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { minNumberRule, positiveNumberRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, FormControl, FormControls, Form, getFormValues, maxLengthRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { Moment } from '@/types';
import { moment } from '@/helpers';
import { useLaborServiceStore } from '../store';
import { CreateTimeEntryCommand } from '../types';

interface Controls extends FormControls {
  date: FormControl<Moment>;
  numberOfHours: FormControl<number>;
  description: FormControl<string>;
}

@Component
export default class CreateTimeEntryDialog extends Vue {

  readonly store = useLaborServiceStore();

  readonly size = DialogWidth.medium;

  form: Form<Controls> | null = null;

  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

  isDateAllowed(date: Moment): boolean {
    return moment().isSame(date, 'day')
      || moment().diff(date, 'days') > 0;
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        date: {
          label: 'Datum',
          value: moment().startOf('day'),
          isRequired: true,
        },
        numberOfHours: {
          label: 'Stundenanzahl',
          value: null,
          isRequired: true,
          rules: [
            positiveNumberRule(2),
            minNumberRule(0.25),
          ],
        },
        description: {
          label: 'Beschreibung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateTimeEntryCommand = {
      date: formValues.date!,
      numberOfHours: formValues.numberOfHours!,
      description: formValues.description!,
    };

    this.store.createTimeEntry(command)
      .then(() => showSuccessMessage('Der verrichtete Arbeitsdienst wurde eingetragen und die Hofverwalter wurden informiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }
}
