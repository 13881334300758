
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { Date, RiderTaskId } from '@/types';
import { useRiderTaskManagementStore } from '../store';
import { UpdateExecutionDateOfTaskAsManagerCommand } from '../types';

interface Controls extends FormControls {
  executionDate: FormControl<Date>;
  comment: FormControl<string>;
}

@Component
export default class UpdateExecutionDateOfTaskDialog extends Vue {

  readonly store = useRiderTaskManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: String, required: true })
  readonly riderTaskId!: RiderTaskId;

  @Prop({ type: Object, required: true })
  readonly currentExecutionDate!: Date;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  isDateAllowed(date: Date): boolean {
    return date.isAfterOrEqualTo(Date.today());
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateExecutionDateOfTaskAsManagerCommand = {
      riderTaskId: this.riderTaskId,
      executionDate: formValues.executionDate!,
      comment: formValues.comment,
    };

    this.store.updateExecutionDateOfTask(command)
      .then(() => showSuccessMessage('Das Bearbeitungsdatum wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        executionDate: {
          label: 'Neues Bearbeitungsdatum',
          value: this.currentExecutionDate,
          isRequired: true,
        },
        comment: {
          label: 'Kommentar für den Reiter',
          value: null,
        },
      },
    });
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
