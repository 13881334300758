
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { Date, MoveHorseTo, Time } from '@/types';
import { moment } from '@/helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, ValuesOfFormControls } from '@/components/form';
import { useMyStableStore } from '../store';
import { CreatePenExemptionCommand, PenExemptions } from '../types';

interface Controls extends FormControls {
  startingAt: FormControl<Date>;
  endingAt: FormControl<Date>;
  moveHorseTo: FormControl<MoveHorseTo>;
  comment: FormControl<string>;
}

@Component
export default class CreatePenExemptionDialog extends Vue {

  readonly store = useMyStableStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly penExemptions!: PenExemptions;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreatePenExemptionCommand = {
      horseId: this.store.currentHorseId!,
      startingAt: formValues.startingAt!,
      endingAt: formValues.endingAt!,
      moveHorseTo: formValues.moveHorseTo!,
      comment: formValues.comment,
    };

    this.store.createPenExemption(command)
      .then(() => showSuccessMessage('Der Koppelausnahme wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  isAllowedStartingAtDate(date: Date): boolean {
    const dateTomorrow = Date.today().add(1);
    const hasDateAlreadyPenExemption = this.penExemptions.penExemptions
      .some((penExemption) => date.isAfterOrEqualTo(penExemption.startingAt)
        && date.isBeforeOrEqualTo(penExemption.endingAt));

    if (hasDateAlreadyPenExemption) {
      return false;
    } else if (dateTomorrow.isEqualTo(date)) {
      const currentTime = Time.fromDate(moment());

      return currentTime.isBefore(this.penExemptions.creationAvailableUntil);
    }

    return dateTomorrow.diff(date) <= 0;
  }

  isAllowedEndingAtDate(date: Date): boolean {
    const formValues = getFormValues(this.form!);

    const startingAt = formValues.startingAt!;
    const isDateBeforeStartingAt = date.isBefore(startingAt);
    if (isDateBeforeStartingAt) {
      return false;
    }

    if (this.isAllowedStartingAtDate(date)) {
      return true;
    }

    const isPenExemptionBetweenStartingAtAndDateToCheck = this.penExemptions.penExemptions
      .some((penExemption) => date.isAfter(startingAt)
        && penExemption.startingAt.isAfter(startingAt)
        && date.isAfter(penExemption.startingAt));

    return !isPenExemptionBetweenStartingAtAndDateToCheck;
  }

  findNextValidDate(date: Date): Date {
    const isAllowed = this.isAllowedStartingAtDate(date);

    if (isAllowed) {
      return date;
    }

    const nextPossibleDate = date.add(1);

    return this.findNextValidDate(nextPossibleDate);
  }

  buildForm(): Form<Controls> {
    const nextValidDate = this.findNextValidDate(Date.today());

    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        startingAt: {
          label: 'Beginnt am',
          value: nextValidDate,
          isRequired: true,
          transformValuesOnInput: (values: ValuesOfFormControls<Controls>): Partial<ValuesOfFormControls<Controls>> => {
            return {
              endingAt: values.startingAt,
            };
          },
        },
        endingAt: {
          label: 'Endet am',
          value: nextValidDate,
          isRequired: true,
        },
        moveHorseTo: {
          label: 'Pferd stellen',
          value: MoveHorseTo.PADDOCK,
          isRequired: true,
        },
        comment: {
          label: 'Kommentar',
          value: null,
        },
      },
    });
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
