
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { minNumberRule, requiredRule } from '@/helpers/form-rules';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { FormField, StrictFormDefinition, VuetifyValidatable } from '@/application/types';
import { DialogWidth } from '@/helpers/data';
import { useRidingLessonManagementStore } from '../store';
import { RidingLessonType, UpdateRegistrationInAdvanceOfRidingLessonTypeAsManagerCommand } from '../types';

interface Form extends StrictFormDefinition {
  fields: {
    registrationInAdvanceInDays: FormField<number|null>;
  };
}

@Component
export default class UpdateRegistrationInAdvanceOfRidingLessonTypeDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  @Prop({ type: Object, required: true })
  readonly ridingLessonType!: RidingLessonType;

  @Ref()
  readonly formElement?: VuetifyValidatable;

  readonly dialogMaxWidth = DialogWidth.large;

  form: Form | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (this.formElement) {
        this.formElement.resetValidation();
      }
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        registrationInAdvanceInDays: {
          value: this.ridingLessonType.registrationInAdvanceInDays,
          rules: [
            requiredRule(),
            minNumberRule(0),
          ],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateRegistrationInAdvanceOfRidingLessonTypeAsManagerCommand = {
      ridingLessonTypeId: this.ridingLessonType.ridingLessonTypeId,
      registrationInAdvanceInDays: this.form!.fields.registrationInAdvanceInDays.value!,
    };

    this.store.updateRegistrationInAdvanceOfRidingLessonType(command)
      .then(() => showSuccessMessage('Die Vorlaufszeit wurde bearbeitet.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
