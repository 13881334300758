
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { formatDate } from '@/helpers';
import { DataListItem } from '@/components/design-system';
import { SurveyAnswerPer } from '@/types';
import { Survey } from '../types';

@Component
export default class ShowSurveyAnswersDialog extends Vue {

  @Prop({ type: Object, required: true })
  readonly survey!: Survey;

  readonly size = DialogWidth.medium;

  isDialogVisible = false;

  get title(): string {
    return this.survey.date
      ? `${this.survey.title} am ${formatDate(this.survey.date)}`
      : this.survey.title;
  }

  get dataItems(): DataListItem[] {
    return this.survey.answers.map((answer) => ({
      key: answer.surveyAnswerId,
      label: this.survey.surveyAnswerPer === SurveyAnswerPer.USER
        ? answer.userName
        : answer.horseName!,
      value: this.survey.isUserAbleToSelectMultipleOptions
        ? answer.optionIds!.map((optionId) => this.survey.options
          .find((option) => option.optionId === optionId)!
          .label)
          .sort((a, b) => a.localeCompare(b, 'de'))
          .join('<br />')
        : this.survey.options
          .find((option) => option.optionId === answer.optionId)!
          .label,
    }));
  }

  show(): void {
    this.isDialogVisible = true;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
