
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { Moment, weekdayFromMoment } from '@/types';
import { showErrorMessage, showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useRidingLessonManagementStore } from '../store';
import { RidingLesson, ExtendRidingLessonSeriesAsManagerCommand, ShortenRidingLessonSeriesAsManagerCommand } from '../types';

interface Controls extends FormControls {
  lastRidingLessonAt: FormControl<Moment>;
}

@Component
export default class UpdateLastRidingLessonAtOfRidingLessonSeriesDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly dialogMaxWidth = DialogWidth.medium;

  @Prop({ type: Object, required: true })
  readonly ridingLesson!: RidingLesson;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isProcessing(): boolean {
    return this.store.isExtendRidingLessonSeriesProcessing
      || this.store.isShortenRidingLessonSeriesProcessing;
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        lastRidingLessonAt: {
          label: 'Letzte Unterrichtsstunde am',
          value: this.ridingLesson.ridingLessonSeries!.lastRidingLessonAt,
          isRequired: true,
        },
      },
    });
  }

  endDateAllowed(date: Moment): boolean {
    if (!this.form) {
      return false;
    }

    return date.isSameOrAfter(this.ridingLesson.from, 'day')
      && weekdayFromMoment(date) === this.ridingLesson.ridingLessonSeries!.weekday;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    if (this.ridingLesson.ridingLessonSeries!.lastRidingLessonAt.isSame(formValues.lastRidingLessonAt!)) {
      showErrorMessage('Das Datum wurde nicht angepasst.');
      return;
    }

    if (this.ridingLesson.ridingLessonSeries!.lastRidingLessonAt.isBefore(formValues.lastRidingLessonAt!)) {
      const command: ExtendRidingLessonSeriesAsManagerCommand = {
        ridingLessonSeriesId: this.ridingLesson.ridingLessonSeries!.ridingLessonSeriesId,
        ridingLessonId: this.ridingLesson.ridingLessonId,
        lastRidingLessonAt: formValues.lastRidingLessonAt!,
      };

      this.store.extendRidingLessonSeries(command)
        .then(() => showSuccessMessage('Das Datum der letzten Unterrichtsstunde wurde angepasst.'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));
    } else {
      const command: ShortenRidingLessonSeriesAsManagerCommand = {
        ridingLessonSeriesId: this.ridingLesson.ridingLessonSeries!.ridingLessonSeriesId,
        ridingLessonId: this.ridingLesson.ridingLessonId,
        lastRidingLessonAt: formValues.lastRidingLessonAt!,
      };

      this.store.shortenRidingLessonSeries(command)
        .then(() => showSuccessMessage('Das Datum der letzten Unterrichtsstunde wurde angepasst.'))
        .then(() => this.closeDialog())
        .catch((error) => showErrorResponse(error));
    }
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
