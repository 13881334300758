
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { useAppStore } from '@/application/app/store';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { useFacilityReservationsManagementStore } from '../store';
import SetUpFacilityReservationsForFacilityDialog from './set-up-facility-reservations-for-facility-dialog.vue';

@Component({
  components: {
    SetUpFacilityReservationsForFacilityDialog,
  },
})
export default class FacilityDetailsTabs extends Vue {

  readonly store = useFacilityReservationsManagementStore();
  readonly appStore = useAppStore();

  get isInitialConfigurationVisible(): boolean {
    return !this.store.currentFacility?.facilityReservationConfiguration;
  }

  get isSetUpFacilityReservationsForFacilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.RIDING_LESSONS_REGISTRATIONS_WRITE);
  }

  mounted(): void {
    this.store.getFacilitiesOverview()
      .catch((error) => showErrorResponse(error));

    this.store.updateCurrentFacility(this.$route.params.id)
      .catch((error) => showErrorResponse(error));

    watch(() => this.store.currentFacility, () => {
      this.appStore.updateTitle(this.store.currentFacility?.name ?? null)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });

    watch(() => this.$route.path, () => {
      this.appStore.updateTitle(this.store.currentFacility?.name ?? null)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });
  }

  destroyed(): void {
    this.store.resetCurrentFacility()
      .catch((error) => showErrorResponse(error));
  }

}
