
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { UserGroupId } from '@/types';
import { UpdateGroupsForUserAsManagerCommand, User } from '../types';
import { useUserManagementStore } from '../store';

interface Controls extends FormControls {
  groups: FormControl<UserGroupId[]>;
}

@Component
export default class UpdateGroupsForUserDialog extends Vue {

  readonly store = useUserManagementStore();

  @Prop({ type: Object, required: true })
  readonly user!: User;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.store.getGroups()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => showErrorResponse(error));
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        groups: {
          label: 'Zu welchen Gruppen soll der Benutzer zugewiesen werden?',
          value: this.user.groupIds,
          rules: [],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateGroupsForUserAsManagerCommand = {
      targetUserId: this.user.userId,
      userGroupIds: formValues.groups!,
    };

    this.store.updateGroupsForUser(command)
      .then(() => showSuccessMessage('Die Gruppen wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
