
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { HorseId } from '@/types';
import { constructForm, FormControl, Form, getFormValues, FormControls } from '@/components/form';
import { useRidingLessonManagementStore } from '../store';
import { Horse, RidingLessonType, UpdateHorsesForAssignmentOfRidingLessonTypeAsManagerCommand } from '../types';

interface Controls extends FormControls {
  idsOfHorsesAvailableForAssignment: FormControl<HorseId[]>;
}

@Component
export default class UpdateHorsesForAssignmentOfRidingLessonTypeDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  @Prop({ type: Object, required: true })
  readonly ridingLessonType!: RidingLessonType;

  readonly dialogMaxWidth = DialogWidth.small;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get horsesAvailableForAssignmentConfiguration(): Horse[] {
    if (!this.store.configurationForRidingLessons) {
      return [];
    }

    return this.store.horses
      .filter((horse) => this.store.configurationForRidingLessons!.idsOfHorsesAvailableForAssignment
        .includes(horse.horseId));
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        idsOfHorsesAvailableForAssignment: {
          label: 'Pferde die in Unterrichtsstunden zugewiesen werden können',
          value: this.ridingLessonType.idsOfHorsesAvailableForAssignment,
          rules: [],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateHorsesForAssignmentOfRidingLessonTypeAsManagerCommand = {
      ridingLessonTypeId: this.ridingLessonType.ridingLessonTypeId,
      idsOfHorsesAvailableForAssignment: formValues.idsOfHorsesAvailableForAssignment!,
    };

    this.store.updateHorsesForAssignmentOfRidingLessonType(command)
      .then(() => showSuccessMessage('Die Pferde wurden bearbeitet.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
