
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { ActionStatus } from '@/application/types';
import { showErrorResponse } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import DeleteFacilityReservationDialog from './delete-facility-reservation-dialog.vue';
import { useFacilityReservationsManagementStore } from '../store';
import { GetFacilityReservationsForFacilityAsManagerQuery, FacilityReservation } from '../types';

interface Controls extends FormControls {
  search: FormControl<string>;
}

@Component({
  components: {
    DeleteFacilityReservationDialog,
  },
})
export default class FacilityReservations extends Vue {

  readonly store = useFacilityReservationsManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Pferd', value: 'horse' },
    { text: 'Datum', value: 'date' },
    { text: 'Zeitraum', value: 'timeRange' },
    { text: 'Aktivität', value: 'activity' },
    { text: 'Kommentar', value: 'comment' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  form: Form<Controls> | null = null;

  get noDataText(): string {
    return this.store.getFacilityReservationsForFacilityStatus === ActionStatus.Failed
      ? 'Die Buchungen konnten nicht geladen werden.'
      : 'Es wurden noch keine Buchungen vorgenommen.';
  }

  get isLoadMoreButtonDisabled(): boolean {
    return this.store.isGetFacilityReservationsForFacilityProcessing
      || this.store.currentFacilityReservationsLimit >= this.store.currentFacilityReservationsTotalCount;
  }

  get isDeleteFacilityReservationVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITY_RESERVATIONS_WRITE);
  }

  get searchValue(): string | null {
    if (!this.form) {
      return null;
    }

    return getFormValues(this.form).search;
  }

  mounted(): void {
    this.form = this.buildForm();

    const query: GetFacilityReservationsForFacilityAsManagerQuery = {
      facilityId: this.store.currentFacilityId!,
      limit: this.store.currentFacilityReservationsLimit,
      offset: 0,
    };
    this.store.getFacilityReservationsForFacility(query)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
        },
      },
    });
  }

  onLoadMoreClicked(): void {
    this.store.increaseFacilityReservationsLimit()
      .catch((error) => showErrorResponse(error));
  }

  filterReservations(value: any, search: string | null, reservation: FacilityReservation) {
    if (search === null) {
      return true;
    }

    const searchLowerCase = search.toLocaleLowerCase();

    if (reservation.userName.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (reservation.horseName.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (reservation.facilityReservationActivityName.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (reservation.comment
      && reservation.comment.toLocaleLowerCase().includes(searchLowerCase)
    ) {
      return true;
    }

    return false;
  }

}
