
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { PhoneNumber } from '@/types';
import { useProfileStore } from '../store';
import { Profile, UpdateEmergencyContactOfUserCommand } from '../types';

interface Controls extends FormControls {
  emergencyContactName: FormControl<string>;
  emergencyContactPhoneNumber: FormControl<PhoneNumber>;
  isEmergencyContactVisibleOnBoxSign: FormControl<boolean>;
}

@Component
export default class UpdateEmergencyContactOfUserDialog extends Vue {

  readonly store = useProfileStore();

  @Prop({ type: Object, required: true })
  readonly profile!: Profile;

  readonly size = DialogWidth.medium;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        emergencyContactName: {
          label: 'Name des Notfallkontakts',
          value: this.profile.emergencyContactName,
          rules: [
            maxLengthRule(255),
          ],
        },
        emergencyContactPhoneNumber: {
          label: 'Telefonnummer des Notfallkontakts',
          value: this.profile.emergencyContactPhoneNumber,
        },
        isEmergencyContactVisibleOnBoxSign: {
          label: 'Notfallkontakt auf Boxenschild anzeigen',
          value: this.profile.isEmergencyContactVisibleOnBoxSign,
        },
      }
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateEmergencyContactOfUserCommand = {
      emergencyContactName: formValues.emergencyContactName,
      emergencyContactPhoneNumber: formValues.emergencyContactPhoneNumber,
      isEmergencyContactVisibleOnBoxSign: formValues.isEmergencyContactVisibleOnBoxSign!,
    };

    this.store.updateEmergencyContactOfUser(command)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  cancelled(): void {
    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
