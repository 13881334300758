
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormValidationRule } from '@/application/types';
import { numberStringRule } from '@/helpers/form-rules';
import { convertStringToNumber } from '@/helpers/form-helpers';
import { uuid } from '@/helpers';
import { formatNumber } from '@/helpers/stateful-format';

@Component
export default class NumberField extends Vue {

  @Prop({ type: Number, default: null })
  readonly value!: number | null;

  @Prop({ type: [Array, Function], default: () => [] })
  readonly rules!: FormValidationRule<number|null>[] | (() => FormValidationRule<number|null>[]);

  readonly refId = uuid();

  isInputFocused = false;

  plainValueAsString = '';

  formattedValueAsString = '';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  formFieldValueWatcher = () => {};

  // Merge listeners explicitly here to overwrite @input
  get listeners(): any {
    return {
      ...this.$listeners,
      input: this.valueAsStringChanged,
    };
  }

  get valueAsString(): string {
    return this.isInputFocused
      ? this.plainValueAsString
      : this.formattedValueAsString;
  }

  set valueAsString(updatedValue: string) {
    this.plainValueAsString = updatedValue;
  }

  get textField(): any | undefined {
    return this.$refs[this.refId] as any | undefined;
  }

  get decoratedRules(): FormValidationRule<any>[] {
    const wrappedRules = typeof this.rules === 'function'
      ? this.rules()
        .map((rule) => () => rule(this.value))
      : this.rules
        .map((rule) => () => rule(this.value));

    return [numberStringRule(), ...wrappedRules];
  }

  mounted(): void {
    this.updateValueAsStringByValue();
    this.watchFormFieldValue();
  }

  validate(): void {
    if (this.textField) {
      this.textField.validate(true);
    }
  }

  inputWasFocused(): void {
    this.isInputFocused = true;
  }

  inputWasBlurred(): void {
    this.formatValueAsString();
  }

  valueAsStringChanged(): void {
    const isValueInvalid = numberStringRule()(this.plainValueAsString) !== true;

    // Clears the form field value if value is empty
    if (!this.plainValueAsString) {
      this.$emit('input', null);
      return;
    }

    // If the value is invalid, the form field value stays the old one
    if (isValueInvalid) {
      return;
    }

    this.unwatchFormFieldValue();
    this.$emit('input', convertStringToNumber(this.plainValueAsString));
    this.watchFormFieldValue();
  }

  private watchFormFieldValue(): void {
    this.formFieldValueWatcher = this.$watch('value', this.updateValueAsStringByValue);
  }

  private unwatchFormFieldValue(): void {
    this.formFieldValueWatcher();
  }

  private updateValueAsStringByValue(): void {
    this.plainValueAsString = this.value !== null
      ? formatNumber(this.value, false)
      : '';

    this.formattedValueAsString = this.value !== null
      ? formatNumber(this.value)
      : '';
  }

  private formatValueAsString(): void {
    const isValueInvalid = numberStringRule()(this.plainValueAsString) !== true;

    if (this.isInputFocused) {
      this.isInputFocused = false;
    }

    // If the value is invalid or empty, the value won't get formatted
    if (!this.plainValueAsString || isValueInvalid) {
      this.formattedValueAsString = this.plainValueAsString;
      return;
    }

    this.formattedValueAsString = formatNumber(this.value);
    this.plainValueAsString = formatNumber(this.value, false);
  }

}
