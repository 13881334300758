
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { uuid } from '@/helpers';
import { isFeatureEnabled } from '@/application/authentication/helper';
import { constructForm, Form, FormControl, FormControls, getFormValues, requiredWhenControlIsFilledRule } from '@/components/form';
import { Feature, Language, Name, PhoneNumber, Address, CustomFieldValues, EmailAddress, Salutation } from '@/types';
import { CreatePersonAsManagerWorkflow } from '../types';
import { useCustomerMasterDataManagementStore } from '../store';

interface Controls extends FormControls {
  salutation: FormControl<Salutation>;
  name: FormControl<Name>;
  emailAddress: FormControl<EmailAddress>;
  address: FormControl<Address>;
  companyForInvoiceAddress: FormControl<string>;
  salutationForInvoiceAddress: FormControl<Salutation>;
  nameForInvoiceAddress: FormControl<Name>;
  addressForInvoiceAddress: FormControl<Address>;
  landlinePhoneNumber: FormControl<PhoneNumber>;
  mobilePhoneNumber: FormControl<PhoneNumber>;
  language: FormControl<Language>;
  customFields: FormControl<CustomFieldValues>;
  comment: FormControl<string>;
}

@Component
export default class CreatePersonDialog extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  activePanel = [0, 1, 2, 3, 4];

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isInvoiceAddressVisible(): boolean {
    return isFeatureEnabled(Feature.LEDGER_INVOICING);
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.activePanel = [0, 1, 2, 3, 4, 5];
      if (isDialogVisible) {
        this.store.getCustomFields()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => {
            showErrorResponse(error);
            this.closeDialog();
          });
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        salutation: {
          label: 'Anrede',
          value: null,
        },
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
        },
        emailAddress: {
          label: 'E-Mail-Adresse',
          value: null,
        },
        address: {
          label: 'Adresse',
          value: null,
        },
        companyForInvoiceAddress: {
          label: 'Firmenname',
          value: null,
          validateFormControlsOnInput: [
            'nameForInvoiceAddress',
            'addressForInvoiceAddress',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'nameForInvoiceAddress',
            'addressForInvoiceAddress',
          ],
        },
        salutationForInvoiceAddress: {
          label: 'Anrede',
          value: null,
          validateFormControlsOnInput: [
            'nameForInvoiceAddress',
            'addressForInvoiceAddress',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'nameForInvoiceAddress',
            'addressForInvoiceAddress',
          ],
        },
        nameForInvoiceAddress: {
          label: 'Name',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'addressForInvoiceAddress',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'addressForInvoiceAddress',
          ],
        },
        addressForInvoiceAddress: {
          label: 'Adresse',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'nameForInvoiceAddress',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'nameForInvoiceAddress',
          ],
        },
        landlinePhoneNumber: {
          label: 'Festnetz-Telefonnummer',
          value: null,
        },
        mobilePhoneNumber: {
          label: 'Mobil-Telefonnummer',
          value: null,
        },
        language: {
          label: 'Sprache',
          value: Language.de,
          isRequired: true,
        },
        customFields: {
          label: 'Spezifische Daten',
          value: null,
        },
        comment: {
          label: 'Kommentar',
          value: null,
        },
      },
    });

    form.controls.nameForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.companyForInvoiceAddress,
      'Der Name muss angegeben werden, wenn die Firma gefüllt ist.',
    ));
    form.controls.nameForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.salutationForInvoiceAddress,
      'Der Name muss angegeben werden, wenn die Anrede gefüllt ist.',
    ));
    form.controls.nameForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.addressForInvoiceAddress,
      'Der Name muss angegeben werden, wenn die Adresse gefüllt ist.',
    ));

    form.controls.addressForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.companyForInvoiceAddress,
      'Die Adresse muss angegeben werden, wenn die Firma gefüllt ist.',
    ));
    form.controls.addressForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.salutationForInvoiceAddress,
      'Die Adresse muss angegeben werden, wenn die Anrede gefüllt ist.',
    ));
    form.controls.addressForInvoiceAddress.rules!.push(requiredWhenControlIsFilledRule(
      form.controls.nameForInvoiceAddress,
      'Die Adresse muss angegeben werden, wenn der Name gefüllt ist.',
    ));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreatePersonAsManagerWorkflow = {
      personId: uuid(),
      salutation: formValues.salutation,
      name: formValues.name!,
      emailAddress: formValues.emailAddress,
      address: formValues.address,
      invoiceAddress: formValues.nameForInvoiceAddress
        && formValues.addressForInvoiceAddress
        ? {
          company: formValues.companyForInvoiceAddress,
          salutation: formValues.salutationForInvoiceAddress,
          name: formValues.nameForInvoiceAddress,
          address: formValues.addressForInvoiceAddress,
        }
        : null,
      landlinePhoneNumber: formValues.landlinePhoneNumber,
      mobilePhoneNumber: formValues.mobilePhoneNumber,
      language: formValues.language!,
      customFields: formValues.customFields,
      comment: formValues.comment,
    };

    this.store.createPerson(command)
      .then(() => showSuccessMessage('Die Person wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
