
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CancelActionButton extends Vue {

  @Prop({ type: Boolean, default: false })
  readonly isProcessing!: boolean;

}
