import { ReservationDisplayNameType, CalendarViewPreference, Currency, Feature, FeedUnit, Locale, Persona, RidingLessonDuration, Strain, UserRole, VaccinationStatus, VaccinationWaitPeriod, Weekday, FeedingSettingsHistoryEntryType, BoxSignGenerationProcessForAllHorsesStatus, Repetition, MoveHorseTo, Country, Language, TimeConfigurationType, TimeConfiguration, CustomFieldType, PersonChangeType, OrganizationChangeType, SubscriptionSchedule, PaymentMethod, NameOrderType, SurveyAnswerPer, ArchivedStatus } from '@/types';

export const strainTranslation: Record<Strain, string> = {
  [Strain.MINIMAL_STRAIN]: 'Geringe Belastung',
  [Strain.MODERATE_STRAIN]: 'Moderate Belastung',
  [Strain.EXTENSIVE_STRAIN]: 'Hohe Belastung',
};

export const personaTranslation: Record<Persona, string> = {
  [Persona.WITH_HORSE]: 'Einsteller',
  [Persona.RIDING_PARTICIPATION]: 'Reitbeteiligung',
  [Persona.NO_INFORMATION]: 'Keine Angabe',
};

export const calendarViewPreferenceTranslation: Record<CalendarViewPreference, string> = {
  [CalendarViewPreference.AUTOMATIC]: 'Automatisch',
  [CalendarViewPreference.DAY_FIRST]: 'Tagesansicht',
  [CalendarViewPreference.WEEK_FIRST]: 'Wochenansicht',
};

export const countryTranslation: Record<Country, string> = {
  [Country.de]: 'Deutschland',
  [Country.at]: 'Österreich',
  [Country.ch]: 'Schweiz',
  [Country.fr]: 'Frankreich',
  [Country.it]: 'Italien',
};

export const languageTranslation: Record<Language, string> = {
  [Language.de]: 'Deutsch',
  [Language.en]: 'Englisch',
  [Language.fr]: 'Französisch',
};

export function ridingLessonDurationTranslation(minutes: RidingLessonDuration): string {
  return translateMinutes(minutes);
}

export function translateMinutes(minutes: number): string {
  if (minutes === 0) {
    return '0 Minuten';
  }

  if (minutes % 60 === 0) {
    const hours = minutes / 60;

    return hours > 1
      ? `${hours} Stunden`
      : '1 Stunde';
  }

  const minutesAfterHours = minutes % 60;
  if (minutes < 60) {
    return `${minutesAfterHours} Minuten`;
  }

  const hours = Math.floor(minutes / 60);
  return hours > 1
    ? `${hours} Stunden ${minutesAfterHours} Minuten`
    : `1 Stunde ${minutesAfterHours} Minuten`;
}

export const featureTranslation: Record<Feature, string> = {
  [Feature.LEDGER]: 'Abrechnung',
  [Feature.LABOR_SERVICE]: 'Arbeitsdienst',
  [Feature.FACILITY_RESERVATIONS]: 'Anlagenreservierungen',
  [Feature.BOX_PLAN]: 'Boxenplan',
  [Feature.FREE_JUMPING_FREE_RUNNING]: 'Freispringen/-laufen',
  [Feature.RIDER_TASKS]: 'Reiteraufgaben',
  [Feature.FARM_TASKS]: 'Hofaufgaben',
  [Feature.PADDOCK_PLAN]: 'Koppelplan',
  [Feature.LEDGER_INVOICING]: 'Rechnungserstellung',
  [Feature.RIDING_LESSONS]: 'Reitunterricht',
  [Feature.EVENTS]: 'Veranstaltungen',
  [Feature.SURVEYS]: 'Umfragen',
  [Feature.FEED_PROTOCOL]: 'Fütterungstechnik',
  [Feature.FEEDING]: 'Fütterung',
  [Feature.PEN]: 'Koppel',
  [Feature.CUSTOMER_MASTER_DATA]: 'Kundenstammdaten',
};

export const userRoleTranslation: Record<UserRole, string> = {
  [UserRole.ROLE_USER]: 'Benutzer',
  [UserRole.ROLE_FARM_MANAGER]: 'Hofverwalter',
  [UserRole.ROLE_ADMIN]: 'Administrator',
};

export const reservationDisplayNameTypeTranslation: Record<ReservationDisplayNameType, string> = {
  [ReservationDisplayNameType.HORSE]: 'Name des Pferdes',
  [ReservationDisplayNameType.RESERVING_PERSON]: 'Name des Reservierenden',
};

export const vaccinationWaitPeriodTranslation: Record<VaccinationWaitPeriod, string> = {
  [VaccinationWaitPeriod.FOUR_WEEKS]: '4 Wochen nach Impfung',
  [VaccinationWaitPeriod.SIX_MONTHS]: '6 Monate nach Impfung',
  [VaccinationWaitPeriod.NINE_MONTHS]: '9 Monate nach Impfung',
  [VaccinationWaitPeriod.ONE_YEAR]: '1 Jahr nach Impfung',
  [VaccinationWaitPeriod.TWO_YEARS]: '2 Jahre nach Impfung',
  [VaccinationWaitPeriod.THREE_YEARS]: '3 Jahre nach Impfung',
};

export const vaccinationStatusTranslation: Record<VaccinationStatus, string> = {
  [VaccinationStatus.VACCINATED]: 'Geimpft',
  [VaccinationStatus.EXPIRES_SOON]: 'Läuft bald ab',
  [VaccinationStatus.EXPIRED]: 'Abgelaufen',
  [VaccinationStatus.NOT_VACCINATED]: 'Nicht geimpft',
};

export const feedUnitTranslations: Record<FeedUnit, string> = {
  [FeedUnit.GRAM]: 'Gramm',
  [FeedUnit.KILOGRAM]: 'Kilogramm',
  [FeedUnit.SHOVEL]: 'Schippe(n)',
  [FeedUnit.BUCKET]: 'Eimer',
  [FeedUnit.PITCHFORK]: 'Gabel(n)',
  [FeedUnit.LITER]: 'Liter',
  [FeedUnit.WHEELBARROW]: 'Schubkarre(n)',
  [FeedUnit.MILLILITER]: 'Milliliter',
  [FeedUnit.PIECE]: 'Stück',
  [FeedUnit.MEASURING_SPOON]: 'Messlöffel',
  [FeedUnit.MEASURING_CUP]: 'Messbecher',
  [FeedUnit.CAN]: 'Dose(n)',
  [FeedUnit.BOWL]: 'Schüssel(n)',
};

export const weekdayTranslations: Record<Weekday, string> = {
  [Weekday.MONDAY]: 'Montag',
  [Weekday.TUESDAY]: 'Dienstag',
  [Weekday.WEDNESDAY]: 'Mittwoch',
  [Weekday.THURSDAY]: 'Donnerstag',
  [Weekday.FRIDAY]: 'Freitag',
  [Weekday.SATURDAY]: 'Samstag',
  [Weekday.SUNDAY]: 'Sonntag',
};

export const currencyTranslation: Record<Currency, string> = {
  [Currency.EUR]: 'Euro',
  [Currency.CHF]: 'Schweizer Franken',
};

export const localeTranslation: Record<Locale, string> = {
  [Locale['de-DE']]: 'Deutsch - Deutschland',
  [Locale['de-CH']]: 'Deutsch - Schweiz',
  [Locale['de-IT']]: 'Deutsch - Italienisch',
};

export const feedingSettingsHistoryEntryTypeTranslation: Record<FeedingSettingsHistoryEntryType, string> = {
  [FeedingSettingsHistoryEntryType.USER_UPDATE]: 'Anpassung durch Benutzer',
  [FeedingSettingsHistoryEntryType.FEED_TYPE_DISABLING]: 'Mindestens ein Futtertyp wurde deaktiviert',
  [FeedingSettingsHistoryEntryType.FEED_TYPE_OFFER_REDUCED]: 'Die verfügbaren Tageszeiten mindestens eines Futtertyps wurden angepasst',
};

export const boxSignGenerationProcessForAllHorsesStatusTranslation: Record<BoxSignGenerationProcessForAllHorsesStatus, string> = {
  [BoxSignGenerationProcessForAllHorsesStatus.PLANNED]: 'Erstellung der Boxenschilder geplant',
  [BoxSignGenerationProcessForAllHorsesStatus.IN_PROGRESS]: 'Boxenschilder werden generiert ...',
  [BoxSignGenerationProcessForAllHorsesStatus.COMPLETED]: 'Generierung der Boxenschilder abgeschlossen',
};

export const repetitionTranslation: Record<Repetition, string> = {
  [Repetition.DAILY]: 'Täglich',
  [Repetition.WEEKLY]: 'Wöchentlich',
};

export const moveHorseToTranslations: Record<MoveHorseTo, string> = {
  [MoveHorseTo.PADDOCK]: 'auf Paddock',
  [MoveHorseTo.BOX]: 'in Box',
};

export const timeConfigurationTypeTranslations: Record<TimeConfigurationType, string> = {
  [TimeConfigurationType.WITHOUT_TIME]: 'Ohne Uhrzeit',
  [TimeConfigurationType.WITH_TIME]: 'Mit Uhrzeit',
  [TimeConfigurationType.WITH_TIME_FRAME]: 'Mit Zeitraum',
};

export function timeConfigurationTranslation(
  timeConfiguration: TimeConfiguration,
  isStartingLowercase: boolean = false
): string | null {
  if (timeConfiguration.type === TimeConfigurationType.WITHOUT_TIME) {
    return null;
  }

  if (timeConfiguration.type === TimeConfigurationType.WITH_TIME) {
    return isStartingLowercase
      ? `um ${timeConfiguration.time!.format('HH:mm')} Uhr`
      : `Um ${timeConfiguration.time!.format('HH:mm')} Uhr`;
  }

  if (timeConfiguration.type === TimeConfigurationType.WITH_TIME_FRAME) {
    return isStartingLowercase
      // eslint-disable-next-line max-len
      ? `zwischen ${timeConfiguration.timeFrame!.timeFrom.format('HH:mm')} Uhr und ${timeConfiguration.timeFrame!.timeTo.format('HH:mm')} Uhr`
      // eslint-disable-next-line max-len
      : `Zwischen ${timeConfiguration.timeFrame!.timeFrom.format('HH:mm')} Uhr und ${timeConfiguration.timeFrame!.timeTo.format('HH:mm')} Uhr`;
  }

  throw new Error('Invalid time configuration');
}

export const customFieldTypeTranslations: Record<CustomFieldType, string> = {
  [CustomFieldType.TEXT]: 'Text',
  [CustomFieldType.LONGTEXT]: 'Langtext',
  [CustomFieldType.YES_NO]: 'Ja / Nein',
  [CustomFieldType.SELECT]: 'Auswahl',
  [CustomFieldType.MULTI_SELECT]: 'Mehrfachauswahl',
};

export const personChangeTypeTranslations: Record<PersonChangeType, string> = {
  [PersonChangeType.PERSON_CREATED]: 'Person erstellt',
  [PersonChangeType.UPDATED_SALUTATION]: 'Anrede angepasst',
  [PersonChangeType.UPDATED_NAME]: 'Name angepasst',
  [PersonChangeType.UPDATED_EMAIL_ADDRESS]: 'E-Mail-Adresse angepasst',
  [PersonChangeType.UPDATED_ADDRESS]: 'Adresse angepasst',
  [PersonChangeType.UPDATED_INVOICE_ADDRESS]: 'Rechnungsadresse angepasst',
  [PersonChangeType.UPDATED_ADDITIONAL_MASTER_DATA]: 'Weitere Stammdaten angepasst',
  [PersonChangeType.REMOVED_CONNECTED_USER]: 'Verknüpfter Benutzer entfernt',
  [PersonChangeType.CONNECTED_USER_DELETED_OWN_ACCOUNT]: 'Verknüpfter Benutzer hat eigenes Konto gelöscht',
  [PersonChangeType.ARCHIVED_PERSON]: 'Person archiviert',
};

export const organizationChangeTypeTranslations: Record<OrganizationChangeType, string> = {
  [OrganizationChangeType.ORGANIZATION_CREATED]: 'Organisation erstellt',
  [OrganizationChangeType.UPDATED_NAME]: 'Name angepasst',
  [OrganizationChangeType.UPDATED_ADDRESS]: 'Adresse angepasst',
  [OrganizationChangeType.UPDATED_INVOICE_ADDRESS]: 'Rechnungsadresse angepasst',
  [OrganizationChangeType.UPDATED_ADDITIONAL_MASTER_DATA]: 'Weitere Stammdaten angepasst',
  [OrganizationChangeType.UPDATED_ASSIGNED_PERSONS]: 'Zugewiesene Personen angepasst',
  [OrganizationChangeType.ARCHIVED_ORGANIZATION]: 'Organisation archiviert',
};

export const subscriptionScheduleRuntimeTranslations: Record<SubscriptionSchedule, string> = {
  [SubscriptionSchedule['one-month']]: 'pro Monat',
  [SubscriptionSchedule['three-months']]: 'für eine Laufzeit von 3 Monaten',
  [SubscriptionSchedule['six-months']]: 'für eine Laufzeit von 6 Monaten',
  [SubscriptionSchedule['one-year']]: 'pro Jahr',
};

export const paymentMethodTranslations: Record<PaymentMethod, string> = {
  [PaymentMethod['bank-transfer']]: 'Überweisung',
  [PaymentMethod['direct-debit']]: 'Lastschrift',
};

export const nameOrderTypeTranslations: Record<NameOrderType, string> = {
  [NameOrderType.FIRST_NAME_FIRST]: 'Vorname Nachname',
  [NameOrderType.LAST_NAME_FIRST]: 'Nachname Vorname',
};

export const surveyAnswerPerTranslations: Record<SurveyAnswerPer, string> = {
  [SurveyAnswerPer.USER]: 'jeden Benutzer',
  [SurveyAnswerPer.HORSE]: 'jedes Pferd',
};

export const archivedStatusTranslations: Record<ArchivedStatus, string> = {
  [ArchivedStatus.ARCHIVED]: 'Nur Archivierte anzeigen',
  [ArchivedStatus.NOT_ARCHIVED]: 'Nur nicht Archivierte anzeigen',
};
