
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { Country, Currency, Feature, Locale } from '@/types';
import { useFarmManagementStore } from '../store';
import { Farm, UpdateFarmAsAdminCommand } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
  currency: FormControl<Currency>;
  locale: FormControl<Locale>;
  defaultCountryForAddresses: FormControl<Country>;
  enabledFeatures: FormControl<Feature[]>;
}

@Component
export default class UpdateFarmDialog extends Vue {

  readonly store = useFarmManagementStore();

  @Prop({ type: Object, required: true })
  readonly farm!: Farm;

  readonly size = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: this.farm.name,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        currency: {
          label: 'Währung',
          value: this.farm.currency,
          isRequired: true,
        },
        locale: {
          label: 'Sprachumgebung',
          value: this.farm.locale,
          isRequired: true,
        },
        defaultCountryForAddresses: {
          label: 'Standardland für Adressen',
          value: this.farm.defaultCountryForAddresses,
          isRequired: true,
        },
        enabledFeatures: {
          label: 'Aktivierte Features',
          value: this.farm.enabledFeatures,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFarmAsAdminCommand = {
      targetFarmId: this.farm.farmId,
      name: formValues.name!,
      currency: formValues.currency!,
      locale: formValues.locale!,
      defaultCountryForAddresses: formValues.defaultCountryForAddresses!,
      enabledFeatures: formValues.enabledFeatures ?? [],
    };

    this.store.updateFarm(command)
      .then(() => showSuccessMessage('Der Hof wurde aktualisiert.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
