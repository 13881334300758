
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { UpdateVisibilityOfHorsesViewAsManagerCommand, User } from '../types';
import { useUserManagementStore } from '../store';

@Component
export default class UpdateVisibilityOfHorsesViewOfUserDialog extends Vue {

  readonly store = useUserManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly user!: User;

  isDialogVisible = false;

  get title(): string {
    return this.user.isHorsesViewVisible
      ? `Pferdeansicht ausblenden`
      : `Pferdeansicht einblenden`;
  }

  get description(): string {
    return this.user.isHorsesViewVisible
      ? `Willst du die Pferdeansicht unterhalb von "Mein Stall" wirklich ausblenden?`
      : `Willst du die Pferdeansicht unterhalb von "Mein Stall" wirklich einblenden?`;
  }

  get buttonText(): string {
    return this.user.isHorsesViewVisible
      ? 'Ausblenden'
      : 'Einblenden';
  }

  updateHorsesViewVisibilityConfirmed(): void {
    const isHorsesViewVisible = !this.user.isHorsesViewVisible;

    const command: UpdateVisibilityOfHorsesViewAsManagerCommand = {
      idOfTargetUser: this.user.userId,
      isHorsesViewVisible: isHorsesViewVisible,
    };

    this.store.updateVisibilityOfHorsesViewOfUser(command)
      .then(() => showSuccessMessage(isHorsesViewVisible
        ? 'Die Pferdeansicht wurde eingeblendet.'
        : 'Die Pferdeansicht wurde ausgeblendet.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
