
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { FacilityReservationActivityId, Time, TimeFrame } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useBookingCalendarStore } from '../store';
import { FacilityReservationActivity, FacilityReservationCalendarEvent, UpdateFacilityReservationCommand } from '../types';

interface Controls extends FormControls {
  facilityReservationActivity: FormControl<FacilityReservationActivityId>;
  timeFrame: FormControl<TimeFrame>;
  comment: FormControl<string>;
}

@Component
export default class UpdateFacilityReservationDialog extends Vue {

  readonly store = useBookingCalendarStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly facilityReservation!: FacilityReservationCalendarEvent;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid
      || this.store.hasUpdateFacilityReservationTrialRunSucceeded !== true;
  }

  get availableSpaces(): number {
    return this.store.currentFacility!.spaces || 0;
  }

  get isSpacesInformationShown(): boolean {
    return this.availableSpaces > 1 || false;
  }

  get selectedFacilityReservationActivity(): FacilityReservationActivity | null {
    if (!this.form) {
      return null;
    }

    const formValues = getFormValues(this.form);

    return this.store.facilityReservationActivitiesForCurrentFacility
      .find((activity) => activity.facilityReservationActivityId === formValues.facilityReservationActivity) ?? null;
  }

  get preferredIntervals(): number {
    if (!this.store.currentFacility
      || !this.store.currentFacility.facilityReservationConfiguration
    ) {
      return 1;
    }
    const diff = this.facilityReservation.to.diff(this.facilityReservation.from, 'minutes');

    return Math.floor(diff / this.store.currentFacility.facilityReservationConfiguration.timeOptionInterval);
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;

      if (isDialogVisible) {
        this.validateReservation();
      } else {
        this.store.resetUpdateFacilityReservationTrialRun();
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        facilityReservationActivity: {
          label: 'Aktivität',
          value: this.facilityReservation.facilityReservationActivity.facilityReservationActivityId,
          isRequired: true,
          afterTransformationAndValidation: () => {
            if (this.form?.isValid) {
              this.validateReservation();
            }
          },
        },
        timeFrame: {
          label: 'Zeitraum',
          value: {
            timeFrom: Time.fromDate(this.facilityReservation.from),
            timeTo: Time.fromDate(this.facilityReservation.to),
          },
          isRequired: true,
          afterTransformationAndValidation: () => {
            if (this.form?.isValid) {
              this.validateReservation();
            }
          },
        },
        comment: {
          label: 'Kommentar',
          value: this.facilityReservation.comment,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const reservationFrom = this.facilityReservation.from
      .hour(formValues.timeFrame!.timeFrom.hour)
      .minute(formValues.timeFrame!.timeFrom.minute)
      .second(formValues.timeFrame!.timeFrom.second);

    const reservationTo = formValues.timeFrame!.timeTo.isMidnight
      ? this.facilityReservation.from
        .hour(formValues.timeFrame!.timeTo.hour)
        .minute(formValues.timeFrame!.timeTo.minute)
        .second(formValues.timeFrame!.timeTo.second)
        .add(1, 'day')
      : this.facilityReservation.from
        .hour(formValues.timeFrame!.timeTo.hour)
        .minute(formValues.timeFrame!.timeTo.minute)
        .second(formValues.timeFrame!.timeTo.second);

    const command: UpdateFacilityReservationCommand = {
      facilityReservationId: this.facilityReservation.id,
      facilityReservationActivityId: formValues.facilityReservationActivity!,
      from: reservationFrom,
      to: reservationTo,
      comment: formValues.comment,
    };

    this.store.updateFacilityReservation(command, false)
      .then(() => showSuccessMessage('Die Reservierung wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error, 6000));
  }

  validateReservation(): void {
    const formValues = getFormValues(this.form!);

    const reservationFrom = this.facilityReservation.from
      .hour(formValues.timeFrame!.timeFrom.hour)
      .minute(formValues.timeFrame!.timeFrom.minute)
      .second(formValues.timeFrame!.timeFrom.second);

    const reservationTo = formValues.timeFrame!.timeTo.isMidnight
      ? this.facilityReservation.from
        .hour(formValues.timeFrame!.timeTo.hour)
        .minute(formValues.timeFrame!.timeTo.minute)
        .second(formValues.timeFrame!.timeTo.second)
        .add(1, 'day')
      : this.facilityReservation.from
        .hour(formValues.timeFrame!.timeTo.hour)
        .minute(formValues.timeFrame!.timeTo.minute)
        .second(formValues.timeFrame!.timeTo.second);

    const command: UpdateFacilityReservationCommand = {
      facilityReservationId: this.facilityReservation.id,
      facilityReservationActivityId: formValues.facilityReservationActivity!,
      from: reservationFrom,
      to: reservationTo,
      comment: formValues.comment,
    };

    this.store.updateFacilityReservation(command, true)
      .catch(() => {});
  }

  // Used from facility calendar
  public show(): void {
    this.isDialogVisible = true;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
