
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useMyStableStore } from '../store';
import { HorseFarrierAppointments } from '../types';
import CreateHorseFarrierAppointmentDialog from './create-horse-farrier-appointment-dialog.vue';

@Component({
  components: {
    CreateHorseFarrierAppointmentDialog,
  },
})
export default class HorseFarrierAppointmentsCard extends Vue {

  readonly store = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly horseFarrierAppointments!: HorseFarrierAppointments;

  get isCreateHorseFarrierAppointmentVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

}
