
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { afterDateRule, beforeDateRule, constructForm, Form, FormControl, FormControls, getFormValues, maxDistanceDateRule } from '@/components/form';
import { Color, Strain, Date, DateTimeFrame } from '@/types';
import { useRidingLessonManagementStore } from '../store';
import { HorseWithUtilization, RidingLessonActivity } from '../types';

interface DynamicDaySlots {
  item: string;
  dayIndex: number;
}

interface Controls extends FormControls {
  dateFrom: FormControl<Date>,
  dateTo: FormControl<Date>,
}

@Component
export default class HorseUtilization extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly colorMap: Record<Strain, Color> = {
    MINIMAL_STRAIN: Color['green-5'],
    MODERATE_STRAIN: Color['yellow-5'],
    EXTENSIVE_STRAIN: Color['red-5'],
  };

  form: Form<Controls> | null = null;

  get tableHeaders(): DataTableHeader[] {
    const tableHeaders: DataTableHeader[] = [
      { text: 'Name', value: 'name' },
    ];

    this.store.horseUtilization?.days.forEach((day, index) => {
        tableHeaders.push({
          text: day.format('DD.MM'),
          value: `day-${index}`,
        });
    });

    return tableHeaders;
  }

  get dynamicDaySlots(): DynamicDaySlots[] {
    if (!this.store.horseUtilization
      || this.store.horseUtilization.days.length === 0
    ) {
      return [];
    }

    return this.store.horseUtilization.days.map((_, index) => (
      {
        item: `item.day-${index}`,
        dayIndex: index,
      }
    ));
  }

  mounted(): void {
    this.form = this.buildForm();

    const formValues = getFormValues(this.form);

    const dateTimeFrame: DateTimeFrame = {
      from: formValues.dateFrom!,
      to: formValues.dateTo!,
    };

    this.store.updateHorseUtilizationDateTimeFrame(dateTimeFrame)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: () => {},
      controls: {
        dateFrom: {
          label: 'Auslastung von',
          value: Date
            .today()
            .subtract(7),
          rules: [],
          validateFormControlsOnInput: [
            'dateTo',
          ],
          afterTransformationAndValidation: () => this.dateChanged(),
        },
        dateTo: {
          label: 'Auslastung bis',
          value: Date.today(),
          rules: [],
          validateFormControlsOnInput: [
            'dateFrom',
          ],
          afterTransformationAndValidation: () => this.dateChanged(),
        },
      },
    });

    form.controls.dateFrom.rules!.push(beforeDateRule(form.controls.dateTo));
    form.controls.dateTo.rules!.push(afterDateRule(form.controls.dateFrom));

    form.controls.dateFrom.rules!.push(maxDistanceDateRule(form.controls.dateTo, 31));
    form.controls.dateTo.rules!.push(maxDistanceDateRule(form.controls.dateFrom, 31));

    return form;
  }

  dateChanged(): void {
    if (!this.form!.isValid) {
      return;
    }
    const formValues = getFormValues(this.form!);

    const dateTimeFrame: DateTimeFrame = {
      from: formValues.dateFrom!,
      to: formValues.dateTo!,
    };

    this.store.updateHorseUtilizationDateTimeFrame(dateTimeFrame)
      .catch((error) => showErrorResponse(error));
  }

  amountOfActivitiesForHorse(horse: HorseWithUtilization): number {
    return horse.days.reduce(
      (sum, day) => sum + day.length,
      0
    );
  }

  horseActivitiesForDay(horse: HorseWithUtilization, dayIndex: number): RidingLessonActivity[] {
    return horse.days[dayIndex];
  }

  activitiesTooltipDescription(horse: HorseWithUtilization): string {
    const amountOfActivities = this.amountOfActivitiesForHorse(horse);

    return amountOfActivities === 1
      ? `${amountOfActivities} Aktivität im Zeitraum`
      : `${amountOfActivities} Aktivitäten im Zeitraum`;
  }

}
