import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CSV, Month, NameOrderType, PersonId } from '@/types';
import { addConcentratedFeedType, addCreditNote, addCustomBooking, addFarmService, archiveFarmService, defineFarmBankInformation, defineInvoiceInformation, disableFarmService, enableFarmService, getConfiguration, getCreditNotes, getCustomBookings, getHorseOwners, getInvoiceInformation, getInvoicePDF, getInvoicesAsZIPForMonth, getInvoicesForMonth, getSettlementsForMonth, getSettlementsForMonthAsCSV, removeFarmBankInformation, revokeCreditNote, revokeCustomBooking, updateCreditNotePaymentMethod, updateCustomBookingPaymentMethod, updateFarmAddress, updateFarmBankInformation, updateFarmLegalInformation, updateFarmServiceBookingStart, updateFarmServicePaymentMethod, updateFarmServicePresentation, updateFarmServicePrice, updateFarmServiceRestrictions, defineFarmTermsOfPayment, updateFarmTermsOfPayment, removeFarmTermsOfPayment, addCustomBookings, addCreditNotes, getPersons } from './service';
import { AddConcentratedFeedTypeAsManagerCommand, AddCreditNoteAsManagerCommand, AddCreditNotesAsManagerWorkflow, AddCustomBookingAsManagerCommand, AddCustomBookingsAsManagerWorkflow, AddFarmServiceAsManagerCommand, ArchiveFarmServiceAsManagerCommand, CreditNote, CustomBooking, DefineFarmBankInformationAsManagerCommand, DefineFarmTermsOfPaymentAsManagerCommand, DefineInvoiceInformationAsManagerCommand, DisableFarmServiceAsManagerCommand, EnableFarmServiceAsManagerCommand, GetCreditNotesAsManagerQuery, GetCustomBookingsAsManagerQuery, GetInvoicePDFAsManagerQuery, GetInvoicesAsZIPForMonthAsManagerQuery, GetInvoicesForMonthAsManagerQuery, GetSettlementsForMonthAsCSVAsManagerQuery, GetSettlementsForMonthAsManagerQuery, HorseOwner, Invoice, InvoiceInformation, LedgerConfiguration, PersonForFilter, RevokeCreditNoteAsManagerCommand, RevokeCustomBookingAsManagerCommand, SettlementsForMonth, UpdateCreditNotePaymentMethodAsManagerCommand, UpdateCustomBookingPaymentMethodAsManagerCommand, UpdateFarmAddressAsManagerCommand, UpdateFarmBankInformationAsManagerCommand, UpdateFarmLegalInformationAsManagerCommand, UpdateFarmServiceBookingStartAsManagerCommand, UpdateFarmServicePaymentMethodAsManagerCommand, UpdateFarmServicePresentationAsManagerCommand, UpdateFarmServicePriceAsManagerCommand, UpdateFarmServiceRestrictionsAsManagerCommand, UpdateFarmTermsOfPaymentAsManagerCommand } from './types';

interface LedgerState {
  settlementsForMonth: SettlementsForMonth | null;
  customBookings: CustomBooking[];
  creditNotes: CreditNote[];
  horseOwners: HorseOwner[];
  invoices: Invoice[];
  persons: PersonForFilter[];
  configuration: LedgerConfiguration | null;
  selectedMonthForSettlements: Month | null;
  selectedPersonForSettlements: PersonId | null;
  selectedNameOrderType: NameOrderType;
  selectedMonthForCustomBookings: Month | null;
  selectedMonthForCreditNotes: Month | null;
  selectedMonthForInvoices: Month | null;
  invoiceInformation: InvoiceInformation | null;

  getConfigurationStatus: ActionStatus;
  getCustomBookingsStatus: ActionStatus;
  getCreditNotesStatus: ActionStatus;
  updateCustomBookingPaymentMethodStatus: ActionStatus;
  updateCreditNotePaymentMethodStatus: ActionStatus;
  getHorseOwnersStatus: ActionStatus;
  addCustomBookingStatus: ActionStatus;
  addCustomBookingsStatus: ActionStatus;
  addCreditNoteStatus: ActionStatus;
  addCreditNotesStatus: ActionStatus;
  addFarmServiceStatus: ActionStatus;
  updateFarmServicePresentationStatus: ActionStatus;
  updateFarmServicePriceStatus: ActionStatus;
  updateFarmServicePaymentMethodStatus: ActionStatus;
  updateFarmServiceBookingStartStatus: ActionStatus;
  updateFarmServiceRestrictionsStatus: ActionStatus;
  enableFarmServiceStatus: ActionStatus;
  disableFarmServiceStatus: ActionStatus;
  archiveFarmServiceStatus: ActionStatus;
  revokeCustomBookingStatus: ActionStatus;
  revokeCreditNoteStatus: ActionStatus;
  addConcentratedFeedTypeStatus: ActionStatus;
  getSettlementsForMonthStatus: ActionStatus;
  getSettlementsForMonthAsCSVStatus: ActionStatus;
  defineInvoiceInformationStatus: ActionStatus;
  updateFarmAddressStatus: ActionStatus;
  updateFarmLegalInformationStatus: ActionStatus;
  defineFarmBankInformationStatus: ActionStatus;
  updateFarmBankInformationStatus: ActionStatus;
  removeFarmBankInformationStatus: ActionStatus;
  defineFarmTermsOfPaymentStatus: ActionStatus;
  updateFarmTermsOfPaymentStatus: ActionStatus;
  removeFarmTermsOfPaymentStatus: ActionStatus;
  getInvoiceInformationStatus: ActionStatus;
  getInvoicesForMonthStatus: ActionStatus;
  getInvoicePDFStatus: ActionStatus;
  getInvoicesAsZIPForMonthStatus: ActionStatus;
  getPersonsStatus: ActionStatus;
}

function initialState(): LedgerState {
  return {
    settlementsForMonth: null,
    customBookings: [],
    creditNotes: [],
    horseOwners: [],
    invoices: [],
    persons: [],
    configuration: null,
    selectedMonthForSettlements: null,
    selectedPersonForSettlements: null,
    selectedNameOrderType: NameOrderType.FIRST_NAME_FIRST,
    selectedMonthForCustomBookings: null,
    selectedMonthForCreditNotes: null,
    selectedMonthForInvoices: null,
    invoiceInformation: null,

    getConfigurationStatus: ActionStatus.None,
    getCustomBookingsStatus: ActionStatus.None,
    getCreditNotesStatus: ActionStatus.None,
    updateCustomBookingPaymentMethodStatus: ActionStatus.None,
    updateCreditNotePaymentMethodStatus: ActionStatus.None,
    getHorseOwnersStatus: ActionStatus.None,
    addCustomBookingStatus: ActionStatus.None,
    addCustomBookingsStatus: ActionStatus.None,
    addCreditNoteStatus: ActionStatus.None,
    addCreditNotesStatus: ActionStatus.None,
    addFarmServiceStatus: ActionStatus.None,
    updateFarmServicePresentationStatus: ActionStatus.None,
    updateFarmServicePriceStatus: ActionStatus.None,
    updateFarmServicePaymentMethodStatus: ActionStatus.None,
    updateFarmServiceBookingStartStatus: ActionStatus.None,
    updateFarmServiceRestrictionsStatus: ActionStatus.None,
    enableFarmServiceStatus: ActionStatus.None,
    disableFarmServiceStatus: ActionStatus.None,
    archiveFarmServiceStatus: ActionStatus.None,
    revokeCustomBookingStatus: ActionStatus.None,
    revokeCreditNoteStatus: ActionStatus.None,
    addConcentratedFeedTypeStatus: ActionStatus.None,
    getSettlementsForMonthStatus: ActionStatus.None,
    getSettlementsForMonthAsCSVStatus: ActionStatus.None,
    defineInvoiceInformationStatus: ActionStatus.None,
    updateFarmAddressStatus: ActionStatus.None,
    updateFarmLegalInformationStatus: ActionStatus.None,
    defineFarmBankInformationStatus: ActionStatus.None,
    updateFarmBankInformationStatus: ActionStatus.None,
    removeFarmBankInformationStatus: ActionStatus.None,
    defineFarmTermsOfPaymentStatus: ActionStatus.None,
    updateFarmTermsOfPaymentStatus: ActionStatus.None,
    removeFarmTermsOfPaymentStatus: ActionStatus.None,
    getInvoiceInformationStatus: ActionStatus.None,
    getInvoicesForMonthStatus: ActionStatus.None,
    getInvoicePDFStatus: ActionStatus.None,
    getInvoicesAsZIPForMonthStatus: ActionStatus.None,
    getPersonsStatus: ActionStatus.None,
  };
}

export const useLedgerStore = defineStore('ledger', {
  state: (): LedgerState => initialState(),
  getters: {
    isGetConfigurationProcessing: (state: LedgerState): boolean =>
      state.getConfigurationStatus === ActionStatus.InProgress,
    isGetCustomBookingsProcessing: (state: LedgerState): boolean =>
      state.getCustomBookingsStatus === ActionStatus.InProgress,
    isGetCreditNotesProcessing: (state: LedgerState): boolean =>
      state.getCreditNotesStatus === ActionStatus.InProgress,
    isUpdateCustomBookingPaymentMethodProcessing: (state: LedgerState): boolean =>
      state.updateCustomBookingPaymentMethodStatus === ActionStatus.InProgress,
    isUpdateCreditNotePaymentMethodProcessing: (state: LedgerState): boolean =>
      state.updateCreditNotePaymentMethodStatus === ActionStatus.InProgress,
    isGetHorseOwnersProcessing: (state: LedgerState): boolean =>
      state.getHorseOwnersStatus === ActionStatus.InProgress,
    isAddCustomBookingProcessing: (state: LedgerState): boolean =>
      state.addCustomBookingStatus === ActionStatus.InProgress,
    isAddCustomBookingsProcessing: (state: LedgerState): boolean =>
      state.addCustomBookingsStatus === ActionStatus.InProgress,
    isAddCreditNoteProcessing: (state: LedgerState): boolean =>
      state.addCreditNoteStatus === ActionStatus.InProgress,
    isAddCreditNotesProcessing: (state: LedgerState): boolean =>
      state.addCreditNotesStatus === ActionStatus.InProgress,
    isAddFarmServiceProcessing: (state: LedgerState): boolean =>
      state.addFarmServiceStatus === ActionStatus.InProgress,
    isUpdateFarmServicePresentationProcessing: (state: LedgerState): boolean =>
      state.updateFarmServicePresentationStatus === ActionStatus.InProgress,
    isUpdateFarmServicePriceProcessing: (state: LedgerState): boolean =>
      state.updateFarmServicePriceStatus === ActionStatus.InProgress,
    isUpdateFarmServicePaymentMethodProcessing: (state: LedgerState): boolean =>
      state.updateFarmServicePaymentMethodStatus === ActionStatus.InProgress,
    isUpdateFarmServiceBookingStartProcessing: (state: LedgerState): boolean =>
      state.updateFarmServiceBookingStartStatus === ActionStatus.InProgress,
    isUpdateFarmServiceRestrictionsProcessing: (state: LedgerState): boolean =>
      state.updateFarmServiceRestrictionsStatus === ActionStatus.InProgress,
    isEnableFarmServiceProcessing: (state: LedgerState): boolean =>
      state.enableFarmServiceStatus === ActionStatus.InProgress,
    isDisableFarmServiceProcessing: (state: LedgerState): boolean =>
      state.disableFarmServiceStatus === ActionStatus.InProgress,
    isArchiveFarmServiceProcessing: (state: LedgerState): boolean =>
      state.archiveFarmServiceStatus === ActionStatus.InProgress,
    isRevokeCustomBookingProcessing: (state: LedgerState): boolean =>
      state.revokeCustomBookingStatus === ActionStatus.InProgress,
    isRevokeCreditNoteProcessing: (state: LedgerState): boolean =>
      state.revokeCreditNoteStatus === ActionStatus.InProgress,
    isAddConcentratedFeedTypeProcessing: (state: LedgerState): boolean =>
      state.addConcentratedFeedTypeStatus === ActionStatus.InProgress,
    isGetSettlementsForMonthProcessing: (state: LedgerState): boolean =>
      state.getSettlementsForMonthStatus === ActionStatus.InProgress,
    isGetSettlementsForMonthAsCSVProcessing: (state: LedgerState): boolean =>
      state.getSettlementsForMonthAsCSVStatus === ActionStatus.InProgress,
    isDefineInvoiceInformationProcessing: (state: LedgerState): boolean =>
      state.defineInvoiceInformationStatus === ActionStatus.InProgress,
    isUpdateFarmAddressProcessing: (state: LedgerState): boolean =>
      state.updateFarmAddressStatus === ActionStatus.InProgress,
    isUpdateFarmLegalInformationProcessing: (state: LedgerState): boolean =>
      state.updateFarmLegalInformationStatus === ActionStatus.InProgress,
    isDefineFarmBankInformationProcessing: (state: LedgerState): boolean =>
      state.defineFarmBankInformationStatus === ActionStatus.InProgress,
    isUpdateFarmBankInformationProcessing: (state: LedgerState): boolean =>
      state.updateFarmBankInformationStatus === ActionStatus.InProgress,
    isRemoveFarmBankInformationProcessing: (state: LedgerState): boolean =>
      state.removeFarmBankInformationStatus === ActionStatus.InProgress,
    isDefineFarmTermsOfPaymentProcessing: (state: LedgerState): boolean =>
      state.defineFarmTermsOfPaymentStatus === ActionStatus.InProgress,
    isUpdateFarmTermsOfPaymentProcessing: (state: LedgerState): boolean =>
      state.updateFarmTermsOfPaymentStatus === ActionStatus.InProgress,
    isRemoveFarmTermsOfPaymentProcessing: (state: LedgerState): boolean =>
      state.removeFarmTermsOfPaymentStatus === ActionStatus.InProgress,
    isGetInvoiceInformationProcessing: (state: LedgerState): boolean =>
      state.getInvoiceInformationStatus === ActionStatus.InProgress,
    isGetInvoicesForMonthProcessing: (state: LedgerState): boolean =>
      state.getInvoicesForMonthStatus === ActionStatus.InProgress,
    isGetInvoicePDFProcessing: (state: LedgerState): boolean =>
      state.getInvoicePDFStatus === ActionStatus.InProgress,
    isGetInvoicesAsZIPForMonthProcessing: (state: LedgerState): boolean =>
      state.getInvoicesAsZIPForMonthStatus === ActionStatus.InProgress,
    isGetPersonsProcessing: (state: LedgerState): boolean =>
      state.getPersonsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSelectedMonthForSettlements(selectedMonth: Month): Promise<void> {
      this.selectedMonthForSettlements = selectedMonth;

      return this.getSettlementsForMonth();
    },

    updateSelectedPersonForSettlements(selectedPerson: PersonId | null): Promise<void> {
      this.selectedPersonForSettlements = selectedPerson;

      return this.getSettlementsForMonth();
    },

    updateSelectedNameOrderType(nameOrderType: NameOrderType): Promise<void> {
      this.selectedNameOrderType = nameOrderType;

      return this.getSettlementsForMonth();
    },

    updateSelectedMonthForCustomBookings(selectedMonth: Month): Promise<void> {
      this.selectedMonthForCustomBookings = selectedMonth;

      return this.getCustomBookings();
    },

    updateSelectedMonthForCreditNotes(selectedMonth: Month): Promise<void> {
      this.selectedMonthForCreditNotes = selectedMonth;

      return this.getCreditNotes();
    },

    updateSelectedMonthForInvoices(selectedMonth: Month): Promise<void> {
      this.selectedMonthForInvoices = selectedMonth;

      return this.getInvoicesForMonth();
    },

    // -- Queries

    getConfiguration(): Promise<void> {
      const { getConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getConfigurationStatus,
        () => getConfiguration(attachFarmAndUserProperties({}))
          .then((configuration) => {
            this.configuration = configuration;
          })
      );
    },

    getCustomBookings(): Promise<void> {
      const query: GetCustomBookingsAsManagerQuery = {
        month: this.selectedMonthForCustomBookings!,
      };
      const { getCustomBookingsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCustomBookingsStatus,
        () => getCustomBookings(attachFarmAndUserProperties(query))
          .then((customBookings) => {
            this.customBookings = customBookings;
          })
      );
    },

    getCreditNotes(): Promise<void> {
      const query: GetCreditNotesAsManagerQuery = {
        month: this.selectedMonthForCreditNotes!,
      };
      const { getCreditNotesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCreditNotesStatus,
        () => getCreditNotes(attachFarmAndUserProperties(query))
          .then((creditNotes) => {
            this.creditNotes = creditNotes;
          })
      );
    },

    getSettlementsForMonth(): Promise<void> {
      const query: GetSettlementsForMonthAsManagerQuery = {
        month: this.selectedMonthForSettlements!,
        filteredForPerson: this.selectedPersonForSettlements,
        nameOrderType: this.selectedNameOrderType,
      };
      const { getSettlementsForMonthStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSettlementsForMonthStatus,
        () => getSettlementsForMonth(attachFarmAndUserProperties(query))
          .then((settlementsForMonth) => {
            this.settlementsForMonth = settlementsForMonth;
          })
      );
    },

    getSettlementsForMonthAsCSV(query: GetSettlementsForMonthAsCSVAsManagerQuery): Promise<CSV> {
      const { getSettlementsForMonthAsCSVStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSettlementsForMonthAsCSVStatus,
        () => getSettlementsForMonthAsCSV(attachFarmAndUserProperties(query))
      );
    },

    getHorseOwners(): Promise<void> {
      const { getHorseOwnersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHorseOwnersStatus,
        () => getHorseOwners(attachFarmAndUserProperties({}))
          .then((horseOwners) => {
            this.horseOwners = horseOwners;
          })
      );
    },

    getInvoiceInformation(): Promise<void> {
      const { getInvoiceInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoiceInformationStatus,
        () => getInvoiceInformation(attachFarmAndUserProperties({}))
          .then((invoiceInformation) => {
            this.invoiceInformation = invoiceInformation;
          })
      );
    },

    getInvoicesForMonth(): Promise<void> {
      const query: GetInvoicesForMonthAsManagerQuery = {
        month: this.selectedMonthForInvoices!,
      };
      const { getInvoicesForMonthStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicesForMonthStatus,
        () => getInvoicesForMonth(attachFarmAndUserProperties(query))
          .then((invoices) => {
            this.invoices = invoices;
          })
      );
    },

    getInvoicePDFAsFarmManager(query: GetInvoicePDFAsManagerQuery): Promise<FileResponse> {
      const { getInvoicePDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicePDFStatus,
        () => getInvoicePDF(attachFarmAndUserProperties(query))
      );
    },

    getInvoicesAsZIPForMonth(): Promise<FileResponse> {
      const query: GetInvoicesAsZIPForMonthAsManagerQuery = {
        month: this.selectedMonthForInvoices!,
      };
      const { getInvoicesAsZIPForMonthStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicesAsZIPForMonthStatus,
        () => getInvoicesAsZIPForMonth(attachFarmAndUserProperties(query))
      );
    },

    getPersons(): Promise<void> {
      const { getPersonsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPersonsStatus,
        () => getPersons(attachFarmAndUserProperties({}))
          .then((persons) => {
            this.persons = persons;
          })
      );
    },

    // -- Commands

    addConcentratedFeedType(command: AddConcentratedFeedTypeAsManagerCommand): Promise<void> {
      const { addConcentratedFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addConcentratedFeedTypeStatus,
        () => addConcentratedFeedType(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    updateCustomBookingPaymentMethod(command: UpdateCustomBookingPaymentMethodAsManagerCommand): Promise<void> {
      const { updateCustomBookingPaymentMethodStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateCustomBookingPaymentMethodStatus,
        () => updateCustomBookingPaymentMethod(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    updateCreditNotePaymentMethod(command: UpdateCreditNotePaymentMethodAsManagerCommand): Promise<void> {
      const { updateCreditNotePaymentMethodStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateCreditNotePaymentMethodStatus,
        () => updateCreditNotePaymentMethod(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    addCustomBooking(command: AddCustomBookingAsManagerCommand): Promise<void> {
      const { addCustomBookingStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addCustomBookingStatus,
        () => addCustomBooking(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookings())
      );
    },

    addCustomBookings(command: AddCustomBookingsAsManagerWorkflow): Promise<void> {
      const { addCustomBookingsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addCustomBookingsStatus,
        () => addCustomBookings(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookings())
      );
    },

    addCreditNote(command: AddCreditNoteAsManagerCommand): Promise<void> {
      const { addCreditNoteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addCreditNoteStatus,
        () => addCreditNote(attachFarmAndUserProperties(command))
          .then(() => this.getCreditNotes())
      );
    },

    addCreditNotes(command: AddCreditNotesAsManagerWorkflow): Promise<void> {
      const { addCreditNotesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addCreditNotesStatus,
        () => addCreditNotes(attachFarmAndUserProperties(command))
          .then(() => this.getCreditNotes())
      );
    },

    addFarmService(command: AddFarmServiceAsManagerCommand): Promise<void> {
      const { addFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addFarmServiceStatus,
        () => addFarmService(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    updateFarmServicePresentation(command: UpdateFarmServicePresentationAsManagerCommand): Promise<void> {
      const { updateFarmServicePresentationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServicePresentationStatus,
        () => updateFarmServicePresentation(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    updateFarmServicePrice(command: UpdateFarmServicePriceAsManagerCommand): Promise<void> {
      const { updateFarmServicePriceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServicePriceStatus,
        () => updateFarmServicePrice(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    updateFarmServicePaymentMethod(command: UpdateFarmServicePaymentMethodAsManagerCommand): Promise<void> {
      const { updateFarmServicePaymentMethodStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServicePaymentMethodStatus,
        () => updateFarmServicePaymentMethod(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    updateFarmServiceBookingStart(command: UpdateFarmServiceBookingStartAsManagerCommand): Promise<void> {
      const { updateFarmServiceBookingStartStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServiceBookingStartStatus,
        () => updateFarmServiceBookingStart(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    updateFarmServiceRestrictions(command: UpdateFarmServiceRestrictionsAsManagerCommand): Promise<void> {
      const { updateFarmServiceRestrictionsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServiceRestrictionsStatus,
        () => updateFarmServiceRestrictions(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    enableFarmService(command: EnableFarmServiceAsManagerCommand): Promise<void> {
      const { enableFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableFarmServiceStatus,
        () => enableFarmService(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    disableFarmService(command: DisableFarmServiceAsManagerCommand): Promise<void> {
      const { disableFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableFarmServiceStatus,
        () => disableFarmService(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    archiveFarmService(command: ArchiveFarmServiceAsManagerCommand): Promise<void> {
      const { archiveFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        archiveFarmServiceStatus,
        () => archiveFarmService(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
          .then(() => useAuthenticationStore().getAuthentication()) // Farm services might become hidden for riders
      );
    },

    revokeCustomBooking(command: RevokeCustomBookingAsManagerCommand): Promise<void> {
      const { revokeCustomBookingStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        revokeCustomBookingStatus,
        () => revokeCustomBooking(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookings())
      );
    },

    revokeCreditNote(command: RevokeCreditNoteAsManagerCommand): Promise<void> {
      const { revokeCreditNoteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        revokeCreditNoteStatus,
        () => revokeCreditNote(attachFarmAndUserProperties(command))
          .then(() => this.getCreditNotes())
      );
    },

    defineInvoiceInformation(command: DefineInvoiceInformationAsManagerCommand): Promise<void> {
      const { defineInvoiceInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        defineInvoiceInformationStatus,
        () => defineInvoiceInformation(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceInformation())
      );
    },

    updateFarmAddress(command: UpdateFarmAddressAsManagerCommand): Promise<void> {
      const { updateFarmAddressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmAddressStatus,
        () => updateFarmAddress(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceInformation())
      );
    },

    updateFarmLegalInformation(command: UpdateFarmLegalInformationAsManagerCommand): Promise<void> {
      const { updateFarmLegalInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmLegalInformationStatus,
        () => updateFarmLegalInformation(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceInformation())
      );
    },

    defineFarmBankInformation(command: DefineFarmBankInformationAsManagerCommand): Promise<void> {
      const { defineFarmBankInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        defineFarmBankInformationStatus,
        () => defineFarmBankInformation(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceInformation())
      );
    },

    updateFarmBankInformation(command: UpdateFarmBankInformationAsManagerCommand): Promise<void> {
      const { updateFarmBankInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmBankInformationStatus,
        () => updateFarmBankInformation(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceInformation())
      );
    },

    removeFarmBankInformation(): Promise<void> {
      const { removeFarmBankInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeFarmBankInformationStatus,
        () => removeFarmBankInformation(attachFarmAndUserProperties({}))
          .then(() => this.getInvoiceInformation())
      );
    },

    defineFarmTermsOfPayment(command: DefineFarmTermsOfPaymentAsManagerCommand): Promise<void> {
      const { defineFarmTermsOfPaymentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        defineFarmTermsOfPaymentStatus,
        () => defineFarmTermsOfPayment(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceInformation())
      );
    },

    updateFarmTermsOfPayment(command: UpdateFarmTermsOfPaymentAsManagerCommand): Promise<void> {
      const { updateFarmTermsOfPaymentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmTermsOfPaymentStatus,
        () => updateFarmTermsOfPayment(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceInformation())
      );
    },

    removeFarmTermsOfPayment(): Promise<void> {
      const { removeFarmTermsOfPaymentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeFarmTermsOfPaymentStatus,
        () => removeFarmTermsOfPayment(attachFarmAndUserProperties({}))
          .then(() => this.getInvoiceInformation())
      );
    },

  },
});
