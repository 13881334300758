
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { navigate } from '@/helpers/navigation-helpers';
import PersonDetails from './person-details.vue';
import { useCustomerMasterDataManagementStore } from '../store';

@Component({
  components: {
    PersonDetails,
  },
})
export default class PersonDetailsLoadingWrapper extends Vue {

  readonly store = useCustomerMasterDataManagementStore();

  mounted(): void {
    watch(() => this.$route, () => {
      this.store.updateCurrentPerson(this.$route.params.personId)
        .catch((error) => {
          showErrorResponse(error);
          navigate({ name: 'customer-master-data-person-list' });
        });

      if (!this.store.customFields) {
        this.store.getCustomFields()
          .catch((error) => showErrorResponse(error));
      }
    }, { immediate: true });
  }

  destroyed(): void {
    this.store.resetCurrentPerson()
      .catch((error) => showErrorResponse(error));
  }

}
