
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { constructForm, Form, FormControl, FormControls } from '@/components/form';
import { FarmServiceId, HorseId, Month, UserId } from '@/types';
import { moment } from '@/helpers';
import { showErrorResponse } from '@/application/snackbar/service';
import { useFarmServiceOverviewStore } from '../store';
import { FarmService, Horse, OneOffFarmServiceUsage, SubscriptionFarmServiceUsage, User } from '../types';

interface Controls extends FormControls {
  month: FormControl<Month>;
  user: FormControl<UserId>;
  horse: FormControl<HorseId>;
  farmService: FormControl<FarmServiceId>;
}

@Component
export default class FarmServiceUsagesForMonth extends Vue {

  readonly store = useFarmServiceOverviewStore();

  readonly subscriptionTableHeaders: DataTableHeader[] = [
    { text: 'Benutzer', value: 'user' },
    { text: 'Pferd', value: 'horse' },
    { text: 'Hofdienst', value: 'farmService' },
  ];

  readonly oneOffTableHeaders: DataTableHeader[] = [
    { text: 'Datum', value: 'startingAt' },
    { text: 'Benutzer', value: 'user' },
    { text: 'Pferd', value: 'horse' },
    { text: 'Hofdienst', value: 'farmService' },
  ];

  form: Form<Controls> | null = null;

  get subscriptionFarmServiceUsages(): SubscriptionFarmServiceUsage[] {
    return this.store.farmServiceUsagesForMonth?.subscriptionFarmServiceUsages ?? [];
  }

  get oneOffFarmServiceUsages(): OneOffFarmServiceUsage[] {
    return this.store.farmServiceUsagesForMonth?.oneOffFarmServiceUsages ?? [];
  }

  get farmServices(): FarmService[] {
    return this.store.farmServiceOverviewFilterData?.farmServices ?? [];
  }

  get users(): User[] {
    return this.store.farmServiceOverviewFilterData?.users ?? [];
  }

  get horses(): Horse[] {
    return this.store.farmServiceOverviewFilterData?.horses ?? [];
  }

  mounted(): void {
    const selectedMonth = moment().startOf('month').format('YYYY-MM') as Month;

    this.form = this.buildForm(selectedMonth);

    this.store.updateSelectedMonth(selectedMonth)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(selectedMonth: Month): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        month: {
          label: 'Monat',
          value: selectedMonth,
          afterTransformationAndValidation: (month) => {
            this.store.updateSelectedMonth(month!)
              .catch((error) => showErrorResponse(error));
          },
        },
        user: {
          label: 'Benutzer',
          value: null,
          afterTransformationAndValidation: (userId) => {
            this.store.updateSelectedUser(userId)
              .catch((error) => showErrorResponse(error));
          },
        },
        horse: {
          label: 'Pferd',
          value: null,
          afterTransformationAndValidation: (horseId) => {
            this.store.updateSelectedHorse(horseId)
              .catch((error) => showErrorResponse(error));
          },
        },
        farmService: {
          label: 'Hofdienst',
          value: null,
          afterTransformationAndValidation: (farmServiceId) => {
            this.store.updateSelectedFarmService(farmServiceId)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

}
