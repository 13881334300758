
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DataTableHeader } from 'vuetify';
import { SortableEvent } from 'sortablejs';
import { ActionStatus } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { doesAuthenticatedUserHavePermission } from '@/application/authentication/helper';
import { FarmManagerPermission } from '@/types';
import { sortArray } from '@/directives/vuetify';
import { useFacilityManagementStore } from '../store';
import { Facility, UpdateOrderOfFacilitiesOfFarmAsManagerCommand } from '../types';
import CreateFacilityDialog from './create-facility-dialog.vue';

@Component({
  components: {
    CreateFacilityDialog,
  },
})
export default class FacilityList extends Vue {

  readonly store = useFacilityManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name' },
    { text: 'Aktionen', value: 'actions', align: 'end' },
  ];

  facilities: Facility[] = [];

  get isLinkInfoVisible(): boolean {
    return this.store.facilities.length > 0;
  }

  get noDataText(): string {
    return this.store.getFacilitiesStatus === ActionStatus.Failed
      ? 'Die Anlagen konnten nicht geladen werden.'
      : 'Du hast noch keine Anlagen erstellt.';
  }

  get isCreateFacilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  get isMoveFacilityVisible(): boolean {
    return doesAuthenticatedUserHavePermission(FarmManagerPermission.FACILITIES_WRITE);
  }

  mounted(): void {
    this.store.getFacilities()
      .catch((error) => showErrorResponse(error));

    watch(() => this.store.facilities, (facilities) => {
      this.facilities = facilities;
    }, { deep: true, immediate: true });
  }

  sorted(event: SortableEvent): void {
    sortArray(this.facilities, event);

    const sortedIdsOfFacilities = this.facilities.map((facility) => facility.facilityId);
    const command: UpdateOrderOfFacilitiesOfFarmAsManagerCommand = {
      sortedIdsOfFacilities,
    };
    this.store.updateOrderOfFacilitiesOfFarm(command)
      .then(() => showSuccessMessage('Die Reihenfolge wurde aktualisiert.'))
      .catch((error) => showErrorResponse(error));
  }

}
