
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { FormField, StrictFormDefinition } from '@/application/types';
import { FarmService, UpdateHiddenFarmServicesAsManagerCommand } from '../types';
import { useFarmServiceOverviewStore } from '../store';
import { FarmServiceId } from '@/types';

interface Form extends StrictFormDefinition {
  fields: {
    idsOfHiddenFarmServices: FormField<string[]>;
  }
}

@Component
export default class UpdateFarmServiceOverviewConfigurationDialog extends Vue {

  readonly store = useFarmServiceOverviewStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Array, required: true })
  readonly farmServices!: FarmService[];

  @Prop({ type: Array, required: true })
  readonly idsOfHiddenFarmServices!: FarmServiceId[];

  isDialogVisible = false;
  form: Form | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.valid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form {
    return {
      valid: false,
      fields: {
        idsOfHiddenFarmServices: {
          value: this.idsOfHiddenFarmServices,
          rules: [],
        },
      },
    };
  }

  onSubmit(): void {
    const command: UpdateHiddenFarmServicesAsManagerCommand = {
      idsOfHiddenFarmServices: this.form!.fields.idsOfHiddenFarmServices.value,
    };
    this.store.updateHiddenFarmServices(command)
      .then(() => this.closeDialog())
      .catch(() => {});
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
