
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { constructForm, Form, FormControl, FormControls, getFormValues, ValuesOfFormControls } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { FacilityId, RidingLessonTypeId } from '@/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useRidingLessonManagementStore } from '../store';
import { Facility, UpdateFacilityAndRidingLessonTypeOfRidingLessonAsManagerCommand, RidingLesson, RidingLessonType } from '../types';

interface Controls extends FormControls {
  facility: FormControl<FacilityId>;
  ridingLessonType: FormControl<RidingLessonTypeId>;
}

@Component
export default class UpdateFacilityAndRidingLessonTypeOfRidingLessonDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly ridingLesson!: RidingLesson;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get selectedFacility(): Facility | null {
    if (this.form === null) {
      return null;
    }

    const formValues = getFormValues(this.form);

    return this.store.facilities
      .find((facility) => facility.facilityId === formValues.facility) ?? null;
  }

  get facilitiesWithActiveRidingLessonTypes(): Facility[] {
    return this.store.facilities
      .filter(
        (facility) => facility.ridingLessonTypes
          .some((ridingLessonType) => ridingLessonType.isEnabled)
      );
  }

  get activeRidingLessonTypesForSelectedFacility(): RidingLessonType[] {
    if (this.selectedFacility === null) {
      return [];
    }

    return this.selectedFacility.ridingLessonTypes
      .filter((ridingLessonType) => ridingLessonType.isEnabled);
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        facility: {
          label: 'Anlage',
          value: this.ridingLesson.facility.facilityId,
          isRequired: true,
          transformValuesOnInput: (): Partial<ValuesOfFormControls<Controls>> => {
            const updatedValues: Partial<ValuesOfFormControls<Controls>> = {};
            // Select first riding lesson type of facility.
            const ridingLessonType = this.activeRidingLessonTypesForSelectedFacility.length > 0
              ? this.activeRidingLessonTypesForSelectedFacility[0]
              : null;
            updatedValues.ridingLessonType = ridingLessonType?.ridingLessonTypeId ?? null;

            return updatedValues;
          }
        },
        ridingLessonType: {
          label: 'Unterrichtsart',
          value: this.ridingLesson.ridingLessonType.ridingLessonTypeId,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFacilityAndRidingLessonTypeOfRidingLessonAsManagerCommand = {
      ridingLessonId: this.ridingLesson.ridingLessonId,
      facilityId: formValues.facility!,
      ridingLessonTypeId: formValues.ridingLessonType!,
    };

    this.store.updateFacilityAndRidingLessonTypeOfRidingLesson(command)
      .then(() => showSuccessMessage('Unterrichtsstunde wurde in die Anlage / Unterrichtsart verschoben.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
