
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControlRule, FormControls, getFormValues, maxLengthRule, requiredWhenCheckboxIsCheckedRule, requiredWhenTimeConfigurationTypeIsSelectedRule, ValuesOfFormControls } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { Date, FarmTaskRepetition, HorseId, Repetition, Time, TimeConfigurationType, TimeFrame, UserId, weekdayFromDate } from '@/types';
import { useFarmTaskManagementStore } from '../store';
import { CreateTaskAsManagerCommand, Task } from '../types';

interface Controls extends FormControls {
  executionDate: FormControl<Date>;
  timeConfigurationType: FormControl<TimeConfigurationType>;
  time: FormControl<Time>;
  timeFrame: FormControl<TimeFrame>;
  title: FormControl<string>;
  details: FormControl<string>;
  horse: FormControl<HorseId>;
  assignedUser: FormControl<UserId>;
  isTaskRepeating: FormControl<boolean>;
  repetition: FormControl<FarmTaskRepetition>;
}

function weekdaysIncludeExecutionDateRule(executionDateFormControl: FormControl<Date>): FormControlRule<FarmTaskRepetition> {
  return (value: FarmTaskRepetition | null): true | string => !value
  || !executionDateFormControl.value
  || value.repetition !== Repetition.WEEKLY
  || !value.weekdays
  || value.weekdays.includes(weekdayFromDate(executionDateFormControl.value))
    ? true
    : 'Der Wochentag des Bearbeitungsdatums muss enthalten sein.';
}

function lastRepetitionNotOnExecutionDate(executionDateFormControl: FormControl<Date>): FormControlRule<FarmTaskRepetition> {
  return (value: FarmTaskRepetition | null): true | string => !value
  || !executionDateFormControl.value
  || !value.lastRepetitionAt
  || value.lastRepetitionAt.isNotEqualTo(executionDateFormControl.value)
    ? true
    : 'Die letzte Wiederholung darf nicht am Bearbeitungsdatum sein.';
}

@Component
export default class DuplicateTaskDialog extends Vue {

  readonly store = useFarmTaskManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly task!: Task;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get executionDate(): Date | null {
    if (!this.form) {
      return null;
    }

    const formValues = getFormValues(this.form);

    return formValues.executionDate!;
  }

  get isRepetitionVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.isTaskRepeating!;
  }

  get isTimeVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.timeConfigurationType === TimeConfigurationType.WITH_TIME;
  }

  get isTimeFrameVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.timeConfigurationType === TimeConfigurationType.WITH_TIME_FRAME;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  isDateAllowed(date: Date): boolean {
    return date.isAfterOrEqualTo(Date.today());
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateTaskAsManagerCommand = {
      executionDate: formValues.executionDate!,
      timeConfiguration: {
        type: formValues.timeConfigurationType!,
        time: formValues.timeConfigurationType === TimeConfigurationType.WITH_TIME
          ? formValues.time!
          : null,
        timeFrame: formValues.timeConfigurationType === TimeConfigurationType.WITH_TIME_FRAME
          ? formValues.timeFrame!
          : null,
      },
      title: formValues.title!,
      details: formValues.details,
      horseId: formValues.horse,
      idOfAssignedUser: formValues.assignedUser,
      repetition: formValues.repetition,
    };

    this.store.createTask(command)
      .then(() => showSuccessMessage('Die Aufgabe wurde dupliziert.'))
      .then(() => this.$emit('task-duplicated'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        executionDate: {
          label: 'Gewünschtes Bearbeitungsdatum',
          value: Date.today(),
          isRequired: true,
        },
        timeConfigurationType: {
          label: 'Uhrzeitabhängig',
          value: this.task.timeConfiguration.type,
          isRequired: true,
          validateFormControlsOnInput: [
            'time',
            'timeFrame',
          ],
        },
        time: {
          label: 'Uhrzeit',
          value: this.task.timeConfiguration.time ?? Time.fromString('09:00:00'),
          rules: [],
        },
        timeFrame: {
          label: 'Zeitraum',
          value: this.task.timeConfiguration.timeFrame ?? {
            timeFrom: Time.fromString('09:00:00'),
            timeTo: Time.fromString('18:00:00'),
          },
          rules: [],
        },
        title: {
          label: 'Titel',
          value: this.task.title,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        details: {
          label: 'Details',
          value: this.task.details,
        },
        horse: {
          label: 'Pferd',
          value: this.task.horseId,
        },
        assignedUser: {
          label: 'Zugewiesener Verwalter',
          value: this.task.idOfAssignedUser,
        },
        isTaskRepeating: {
          label: 'Wiederholend',
          value: this.task.repetition !== null,
          transformValuesOnInput: (values: ValuesOfFormControls<Controls>): Partial<ValuesOfFormControls<Controls>> => {
            return {
              repetition: values.isTaskRepeating
                ? {
                  repetition: Repetition.DAILY,
                  lastRepetitionAt: null,
                  weekdays: null,
                }
                : null,
            };
          },
        },
        repetition: {
          label: 'Wiederholung',
          value: this.task.repetition,
          rules: [],
        },
      },
    });

    form.controls.time.rules!.push(requiredWhenTimeConfigurationTypeIsSelectedRule(
      form.controls.timeConfigurationType,
      TimeConfigurationType.WITH_TIME,
    ));
    form.controls.timeFrame.rules!.push(requiredWhenTimeConfigurationTypeIsSelectedRule(
      form.controls.timeConfigurationType,
      TimeConfigurationType.WITH_TIME_FRAME,
    ));

    form.controls.repetition.rules!.push(requiredWhenCheckboxIsCheckedRule(form.controls.isTaskRepeating));
    form.controls.repetition.rules!.push(weekdaysIncludeExecutionDateRule(form.controls.executionDate));
    form.controls.repetition.rules!.push(lastRepetitionNotOnExecutionDate(form.controls.executionDate));

    return form;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
