
import { Component, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useMyStableStore } from '../store';
import { DeleteHorseWormingTreatmentCommand, HorseWormingTreatment } from '../types';

@Component
export default class HorseWormingTreatmentsHistory extends Vue {

  readonly store = useMyStableStore();

  get isDeleteHorseWormingTreatmentVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

  backClicked(): void {
    navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
  }

  async deleteHorseWormingTreatmentAction(horseWormingTreatment: HorseWormingTreatment): Promise<void> {
    const command: DeleteHorseWormingTreatmentCommand = {
      horseWormingTreatmentId: horseWormingTreatment.horseWormingTreatmentId,
    };

    return this.store.deleteHorseWormingTreatment(command)
      .then(() => showSuccessMessage('Wurmkur wurde gelöscht.'))
      .then(() => {
        if (this.store.horseWormingTreatments!.history.length === 0) {
          navigate({ name: 'horse-medical-record', params: { horseId: this.store.currentHorseId! } });
        }
      })
      .catch((error) => showErrorResponse(error));
  }

}
