
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormField, VuetifyValidatable } from '@/application/types';
import { uuid } from '@/helpers';

@Component
export default class TextareaInput extends Vue {

  @Prop({ type: Object, required: true })
  readonly formField!: FormField<string | null>;

  readonly fieldRefId = uuid();

  get field(): VuetifyValidatable | undefined {
    return this.$refs[this.fieldRefId] as VuetifyValidatable | undefined;
  }

  validate(): void {
    if (this.field) {
      this.field.validate(true);
    }
  }

}
