
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { useMedicalRecordsManagementStore } from '../store';

@Component
export default class SoonExpiringVaccinations extends Vue {

  readonly store = useMedicalRecordsManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Pferd', value: 'name', width: '40%' },
    { text: 'Läuft ab am', value: 'expiresAt', width: '60%' },
  ];

  mounted(): void {
    this.store.getHorsesWithVaccinationsThatExpireSoon()
      .catch((error) => showErrorResponse(error));
  }

}
