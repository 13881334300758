
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { HorseId, RidingLessonDuration, Strain } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, minNumberRule } from '@/components/form';
import { useRidingLessonManagementStore } from '../store';
import { CreateRidingLessonTypeAsManagerCommand, Facility, Horse } from '../types';

interface Controls extends FormControls {
  name: FormControl<string>;
  strainOnHorse: FormControl<Strain>;
  requiredSpaces: FormControl<number>;
  defaultDuration: FormControl<RidingLessonDuration>;
  registrationInAdvanceInDays: FormControl<number>;
  idsOfHorsesAvailableForAssignment: FormControl<HorseId[]>;
}

@Component
export default class CreateRidingLessonTypeDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly facility!: Facility;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get horsesAvailableForAssignmentConfiguration(): Horse[] {
    if (!this.store.configurationForRidingLessons) {
      return [];
    }

    return this.store.horses
      .filter((horse) => this.store.configurationForRidingLessons!.idsOfHorsesAvailableForAssignment
        .includes(horse.horseId));
  }

  get isIdsOfHorsesAvailableForAssignmentFormControlVisible(): boolean {
    return this.store.configurationForRidingLessons?.areHorsesFilteredByRidingLessonType ?? false;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name der Unterrichtsart',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        strainOnHorse: {
          label: 'Belastung für das Pferd',
          value: Strain.MINIMAL_STRAIN,
          isRequired: true,
        },
        requiredSpaces: {
          label: 'Platzbedarf der Unterrichtsstunde',
          value: 1,
          isRequired: true,
        },
        defaultDuration: {
          label: 'Standarddauer der Unterrichtsstunde',
          value: 45,
          isRequired: true,
        },
        registrationInAdvanceInDays: {
          label: 'Maximaler Vorlauf zur Anmeldung zum Reitunterricht in Tagen',
          value: 14,
          isRequired: true,
          rules: [
            minNumberRule(0),
          ],
        },
        idsOfHorsesAvailableForAssignment: {
          label: 'Pferde die in Unterrichtsstunden zugewiesen werden können',
          value: null,
          rules: [],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateRidingLessonTypeAsManagerCommand = {
      facilityId: this.facility.facilityId,
      name: formValues.name!,
      requiredSpaces: formValues.requiredSpaces!,
      strainOnHorse: formValues.strainOnHorse!,
      defaultDuration: formValues.defaultDuration!,
      registrationInAdvanceInDays: formValues.registrationInAdvanceInDays!,
      idsOfHorsesAvailableForAssignment: formValues.idsOfHorsesAvailableForAssignment,
    };

    this.store.createRidingLessonType(command)
      .then(() => showSuccessMessage('Neue Unterrichtsart wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
